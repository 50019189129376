import React from 'react';
import { node, string } from 'prop-types';
import styled from 'styled-components';
import { Heading } from '@lalamove/karang';
import { black } from 'styles/colors';
import { maxPageWidth } from 'styles/dimensions';

export const TopControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.75em;
  margin: 1.75em 0 1em;
`;

export const MainHeading = styled.div`
  display: flex;
  align-items: center;
  margin: 1em 2em;

  @media (min-width: ${maxPageWidth}rem) {
    margin-right: 0;
    margin-left: 0;
  }

  & > h1 {
    margin: 0;
    color: ${black};
    font-size: 2rem;
    text-transform: capitalize;
  }
`;

export const Filler = styled.span`
  margin-right: auto;
`;

type Header = {
  topControls: React.ReactNode;
  title: string;
  tabs: React.ReactNode;
  headerEnd: React.ReactNode;
};

const PageHeader = ({
  topControls,
  title,
  tabs,
  headerEnd,
}: Header): JSX.Element => (
  <>
    <TopControls>{topControls}</TopControls>
    <MainHeading>
      <Heading size="large" htmlTag="h1">
        {title}
      </Heading>
      {tabs}
      <Filler />
      {headerEnd}
    </MainHeading>
  </>
);

PageHeader.defaultProps = {
  topControls: null,
  tabs: null,
  headerEnd: null,
};

PageHeader.propTypes = {
  topControls: node,
  title: string.isRequired,
  tabs: node,
  headerEnd: node,
};

export default PageHeader;
