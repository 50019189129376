import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Route, withRouter } from 'react-router-dom';
import { func, shape, bool, string } from 'prop-types';
import _some from 'lodash/some';
import { push, replace } from 'connected-react-router';

import { getCheckoutErrors, submitOrder } from 'store/modules/checkout';
import { getPricingError, fetchPrice } from 'store/modules/pricing';
import { createLoadingSelector } from 'store/modules/loading';
import { getRouteErrors } from 'store/modules/routing';
import { getCurrentCity } from 'store/modules/region/selectors';
import { getSelectedServiceAndSubServiceIds } from 'store/modules/services';
import {
  enableActiveFetchLocationSearchHistory,
  disableActiveFetchLocationSearchHistory,
} from 'store/modules/searchHistory';

import Alert from 'containers/Alert'; // eslint-disable-line import/no-named-as-default
import PlaceOrder from 'containers/PlaceOrder';
import Checkout from 'containers/Checkout';
import HtmlTitle from 'components/HtmlTitle';
import { PriceShape } from 'views/Records/propTypes';
import { HEADER_HEIGHT } from 'views/Header/style';
import { noop } from 'utils/helpers';

import RoutePreview from './components/RoutePreview';
import Footer from './components/Footer';

export const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: calc(100vh - ${HEADER_HEIGHT}rem);
  max-height: calc(100vh - ${HEADER_HEIGHT}rem);
`;

export const Left = styled.main`
  position: relative;
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  min-width: 36rem;
  max-width: 48rem;
  border-right: 1px solid #b4b4b4;
`;

export const LeftContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
`;

export const Right = styled.aside`
  flex: 1 1 auto;
`;

const matchPaths = ['/', '/confirmation'];

// eslint-disable-next-line react/prefer-stateless-function
class Home extends Component {
  static defaultProps = {
    t: noop,
    price: {},
    hasRoutingErrors: false,
    hasPricingErrors: false,
    hasCheckoutErrors: false,
    isSubmitting: false,
    // for tracking purposes:
    serviceIds: { serviceId: '', subServiceId: '' },
  };

  static propTypes = {
    t: func,
    submitOrder: func.isRequired,
    price: PriceShape,
    location: shape({}).isRequired,
    hasRoutingErrors: bool,
    hasPricingErrors: bool,
    hasCheckoutErrors: bool,
    isSubmitting: bool,
    currentCity: shape({ id: string }).isRequired,
    enableActiveFetchLocationSearchHistory: func.isRequired,
    disableActiveFetchLocationSearchHistory: func.isRequired,
    historyPush: func.isRequired,
    historyReplace: func.isRequired,
    // for tracking purposes:
    serviceIds: shape({ serviceId: string, subServiceId: string }),
  };

  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { enableActiveFetchLocationSearchHistory } = this.props;
    enableActiveFetchLocationSearchHistory();
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-shadow
    const { disableActiveFetchLocationSearchHistory } = this.props;
    disableActiveFetchLocationSearchHistory();
  }

  handleBack = () => {
    const { historyReplace } = this.props;
    // if user change location, proceed to checkout, then click back,
    // `goBack()` will re-trigger change location:
    // this.props.history.goBack();
    historyReplace('/');
  };

  handleNext = orderType => {
    const { historyPush } = this.props;
    const param = orderType === 'scheduled' ? `?scheduled=true` : '';
    historyPush(matchPaths[1] + param);
  };

  render() {
    const {
      t,
      price,
      location,
      hasRoutingErrors,
      hasPricingErrors,
      hasCheckoutErrors,
      isSubmitting,
      submitOrder, // eslint-disable-line no-shadow
      currentCity,
      serviceIds,
    } = this.props;
    if (!matchPaths.includes(location.pathname)) return null;
    return (
      <Container>
        <HtmlTitle>{t('Title.place_order')}</HtmlTitle>
        <Left>
          <Alert
            messageSelector={['FETCH_PRICE', 'IMPORT', 'PLACE_ORDER']}
            persist
          />
          <LeftContent>
            <Route exact path={matchPaths[0]} component={PlaceOrder} />
            <Route exact path={matchPaths[1]} component={Checkout} />
          </LeftContent>
          {(price.fee > 0 || price.services > 0) && (
            <Footer
              errors={{
                routing: hasRoutingErrors,
                pricing: hasPricingErrors,
                checkout: hasCheckoutErrors,
              }}
              price={price}
              onBack={this.handleBack}
              onNext={this.handleNext}
              onSubmit={submitOrder}
              isLoading={isSubmitting}
              serviceIds={serviceIds}
            />
          )}
        </Left>
        <Right>
          <RoutePreview currentCity={currentCity} showCountryPicker />
        </Right>
      </Container>
    );
  }
}

const loadingSelector = createLoadingSelector(['PLACE_ORDER']);

const mapState = state => ({
  price: state.pricing.price,
  hasRoutingErrors: _some(getRouteErrors(state), errorCollection =>
    _some(errorCollection)
  ),
  hasPricingErrors: !!getPricingError(state),
  hasCheckoutErrors: _some(getCheckoutErrors(state)),
  isSubmitting: loadingSelector(state),
  currentCity: getCurrentCity(state),
  // for tracking purposes:
  serviceIds: getSelectedServiceAndSubServiceIds(state),
});

export default compose(
  withRouter,
  connect(mapState, {
    fetchPrice,
    submitOrder,
    enableActiveFetchLocationSearchHistory,
    disableActiveFetchLocationSearchHistory,
    historyPush: push,
    historyReplace: replace,
  }),
  withTranslation()
)(Home);
