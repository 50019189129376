import { toLLMLower } from 'utils/locale';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import { UApiError } from 'utils/helpers';

export default async function updateAppeal(item) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    cityId,
    countryId,
    locale,
  } = await fetcher.getState();
  const { refId, appealItems, desc } = item;
  const params = {
    args: JSON.stringify({}),
    order_display_id: refId,
    desc,
    appeal_items: JSON.stringify(appealItems),
    city_id: cityId,
    token: accessToken,
    is_ep: profileType,
    os: 'web',
    hlang: toLLMLower(locale),
    hcountry: countryId,
  };

  const { data, ret, msg } = await fetcher.get(
    'index.php?_m=order_bill_appeal&_a=index',
    params
  );

  if (ret !== 0) throw new UApiError(msg, ret);

  return data;
}
