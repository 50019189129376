import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { shape, string, func, bool } from 'prop-types';
import styled from 'styled-components';
import { Button, Card, Input, Form, Checkbox } from '@lalamove/karang';

import { actions as RegisterActions } from 'store/modules/auth';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';
import {
  selectors as regionSelector,
  actions as regionAction,
} from 'store/modules/region';

import PhoneInput from 'components/PhoneInput';
import HtmlTitle from 'components/HtmlTitle';

import md5 from 'md5';
import i18n from 'utils/i18n';
import { fontSize } from 'styles/fonts';
import { black, silver, red } from 'styles/colors';

const FormItem = Form.Item;

const CardHeader = styled.h1`
  padding-bottom: 2rem;
  margin: 0;
  color: ${black};
  font-size: ${fontSize.large};
  text-align: center;
`;

const CardFooter = styled.div`
  padding-top: 2rem;
  color: ${silver};
  text-align: center;
`;

const FormInfo = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  color: ${silver};
  font-size: ${fontSize.regular};
  text-align: center;
`;

const StyledInput = styled(Input)`
  flex: 1;
`;

const ErrorMessage = styled.span`
  display: block;
  margin-left: 2em;
  color: ${red};
  font-size: ${fontSize.small};
`;

const StyledCheckbox = styled(Checkbox)`
  display: block;
  margin: 0.5em 0;
  font-size: ${fontSize.regular};
  & input {
    position: absolute;
  }
  & span:last-child {
    float: right;
  }
`;

StyledInput.displayName = 'StyledInput';

export class AccountVerification extends Component {
  static defaultProps = {
    socialLoginId: null,
    loading: false,
    apiError: null,
  };

  static propTypes = {
    t: func.isRequired,
    socialLoginId: string,
    firstName: string.isRequired,
    lastName: string.isRequired,
    email: string.isRequired,
    loading: bool,
    apiError: shape({ message: string }),
    registerSubmit: func.isRequired,
    currentCountry: shape({
      id: string,
      areaCode: string,
      isEmailRequiredForSignUp: bool,
    }).isRequired,
    countryDict: shape({}).isRequired,
    changeLocation: func.isRequired,
  };

  state = {
    email: this.props.email,
    mobile: '',
    terms: true,
    marketingOptIn: true,
    showError: false,
    showErrorTerms: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      socialLoginId,
      registerSubmit,
      currentCountry,
    } = this.props;
    const { email, mobile, terms, marketingOptIn } = this.state;

    this.setState({ showErrorTerms: false });
    if (!terms) {
      this.setState({ showErrorTerms: true });
      return;
    }

    this.setState({ showError: true });
    registerSubmit({
      firstName,
      lastName,
      email,
      country: currentCountry.id,
      countryCode: currentCountry.areaCode,
      mobile,
      password: md5(socialLoginId),
      lang: i18n.language,
      socialLoginId,
      marketingOptIn,
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value, showError: false });
  };

  handleBlur = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value.trim(),
    });
  };

  handleToggle = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleCountryChange = country => {
    const { currentCountry, changeLocation } = this.props;
    if (country === currentCountry.id) {
      return;
    }

    changeLocation({
      country,
    });
  };

  isFormReady() {
    const {
      currentCountry: { isEmailRequiredForSignUp },
    } = this.props;
    const { mobile, email } = this.state;
    return !mobile || (isEmailRequiredForSignUp && !email);
  }

  renderError(name) {
    const { apiError } = this.props;
    if (!apiError) return null;

    const errors = {
      email: [
        'ERROR_INVALID_EMAIL',
        'ERROR_EMAIL_ALREADY_EXISTS',
        'ERROR_SOCIAL_ALREADY_EXISTS',
      ],
      mobile: ['ERROR_INVALID_PHONE_NUMBER', 'ERROR_PHONE_ALREADY_EXISTS'],
    };

    return errors[name].find(msg => msg === apiError.message);
  }

  render() {
    const {
      loading,
      socialLoginId,
      t,
      currentCountry,
      countryDict,
    } = this.props;
    const {
      email,
      mobile,
      terms,
      marketingOptIn,
      showError,
      showErrorTerms,
    } = this.state;

    if (!socialLoginId) return <Redirect to="/register" />;

    return (
      <Fragment>
        <HtmlTitle>{t('Title.account_verification')}</HtmlTitle>
        <Card>
          <CardHeader>{t('Register.account_verification')}</CardHeader>
          <form onSubmit={this.handleSubmit} noValidate>
            <FormItem>
              <StyledInput
                type="email"
                label={t('Register.email')}
                name="email"
                value={email}
                error={
                  showError && !loading ? t(this.renderError('email')) : ''
                }
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </FormItem>
            <FormItem>
              <PhoneInput
                label={t('Register.mobile')}
                name="mobile"
                value={mobile}
                error={
                  showError && !loading ? t(this.renderError('mobile')) : ''
                }
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                currentCountry={currentCountry}
                countries={Object.values(countryDict)}
                onCountryChange={this.handleCountryChange}
                showAreaCode
              />
            </FormItem>
            <FormItem>
              <FormInfo>{t('Register.verification_sms')}</FormInfo>
            </FormItem>
            <FormItem>
              <Button
                type="submit"
                variant="primary"
                size="large"
                block
                solid
                disabled={loading || this.isFormReady()}
                isLoading={loading}
              >
                {t('Register.send_verification')}
              </Button>
            </FormItem>

            <StyledCheckbox
              name="terms"
              label={
                <Trans i18nKey="Register.checkbox_terms">
                  I have read, understood and accept the
                  <a
                    href={t('url:ETIQUTTE_URL')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </a>
                  and
                  <a
                    href={t('url:PRIVACY_URL')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Trans>
              }
              checked={terms}
              value={terms}
              onChange={this.handleToggle}
            />
            {showErrorTerms && (
              <ErrorMessage>{t('Register.checkbox_terms_error')}</ErrorMessage>
            )}

            <StyledCheckbox
              name="marketingOptIn"
              label={
                <Trans i18nKey="Register.checkbox_marketing">
                  I agree the use of my personal data for direct marketing in
                  accordance with the stated
                  <a
                    href={t('url:PRIVACY_URL')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Trans>
              }
              value={marketingOptIn}
              checked={marketingOptIn}
              onChange={this.handleToggle}
            />
          </form>

          <CardFooter>
            <Trans i18nKey="Register.login">
              Already have an account?
              <Link to="/login" replace>
                Log in
              </Link>
              .
            </Trans>
          </CardFooter>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: createLoadingSelector(['REGISTER'])(state),
  apiError: makeMessageSelector(['REGISTER'])(state),
  firstName: state.auth.register.firstName,
  lastName: state.auth.register.lastName,
  email: state.auth.register.email,
  socialLoginId: state.auth.register.socialLoginId,
  currentCountry: regionSelector.getCurrentCountry(state),
  countryDict: regionSelector.getCountryDict(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    registerSubmit: RegisterActions.registerSubmit,
    changeLocation: regionAction.changeLocation,
  })
)(AccountVerification);
