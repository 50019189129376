import {
  call,
  put,
  select,
  takeLatest,
  delay,
  take,
  fork,
  throttle,
} from 'redux-saga/effects';
import validator from 'utils/validator';
import { ERROR_BUSINESS_PROFILE_REMOVED_RET } from 'utils/apiHelper';
import {
  placeOrder,
  fetchOrderDetails,
  fetchUnpaidOrder,
  getDaylightSavingsTime,
} from 'api/uAPI';
import { push, replace } from 'connected-react-router';
import { openDialog, openModal, openPanel } from 'store/modules/ui';
import moment from 'moment';
import { channel } from 'redux-saga';
import {
  getCurrentCity,
  getCurrentCountry,
  getCurrentIsoCurrencyCode,
} from 'store/modules/region/selectors';
import {
  getSelectedService,
  getRevision,
  getSelectedServiceVehicleStd,
  getAllowRequireProofOfDelivery,
  getSelectedNonVehicleStdSpecialRequests,
} from 'interfaces/global/store/modules/services/selectors';
import { VEHICLE_STD_PREFIX } from 'interfaces/global/store/modules/services/helpers';
import {
  REQUEST_LOGOUT,
  FB_LOGIN_SUCCESS,
  GOOGLE_LOGIN_SUCCESS,
} from 'interfaces/global/store/modules/auth/actions';
import { getSelectedSpecialRequests } from 'interfaces/global/store/modules/pricing/saga';
import {
  getFilledWaypts,
  getDeliveryInfo,
} from 'interfaces/global/store/modules/routing/selectors';
import {
  getPrice,
  getPriceRaw,
  getSelectedCashCoupon,
  getSelectedOnlineCoupon,
} from 'interfaces/global/store/modules/pricing/selectors';

import {
  DUPLICATE,
  TRIPLICATE,
  loadSelectedInvoice,
  getCompleteInvoiceInfo,
} from 'interfaces/global/store/modules/checkout/uniformInvoice';
import {
  getIsFavDriverOnly,
  getUser,
} from 'interfaces/global/store/modules/auth/selectors';
import { LOGIN_SUCCESS } from 'store/modules/auth/actions';
import { DISMISS_MESSAGE } from 'store/modules/message';
import storage from 'utils/storage';
import {
  PaymentMethods,
  paymentMethodEventMap,
} from 'interfaces/global/store/modules/checkout/types';
import { saveRouteAddresses } from 'interfaces/global/store/modules/searchHistory/actions';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { branchLogEvent } from 'utils/helpers';
import {
  setCheckoutError,
  initOnlinePayment,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_REQUEST,
  FETCH_ONLINE_PAYMENT_STATUS_REQUEST,
  FETCH_ONLINE_PAYMENT_STATUS_SUCCESS,
  FETCH_ONLINE_PAYMENT_STATUS_FAILURE,
  FETCH_UNPAID_ORDER_SUCCESS,
  FETCH_DAYLIGHT_REQUEST,
  FETCH_DAYLIGHT_SUCCESS,
  PROCEED_ORDER,
} from './actions';
import {
  getCheckout,
  getIsFirstTimePlacingOrder,
  getOnlinePaymentInfo,
} from './selectors';
import { getImmediateDeliveryUnix, removeUserContact } from './helpers';
import refetchServicesAndCall from '../refetchServicesAndCall';

const FETCH_ORDER_PAYMENT_STATUS_RETRIES = 5;

const onlinePaymentChannel = channel();

function constructUniformInvoice(userFid) {
  const type = loadSelectedInvoice(userFid);
  const info = getCompleteInvoiceInfo(userFid, type);
  return { key: type, ...info };
}

function validateInvoiceInfo(uniformInvoice) {
  let allValid = true;
  if (uniformInvoice.key === DUPLICATE) {
    const { valid: emailValid } = validator.email(uniformInvoice.email);
    allValid = emailValid && uniformInvoice.name && uniformInvoice.address;
  }
  if (uniformInvoice.key === TRIPLICATE) {
    const { valid: numberValid } = validator.taxID(uniformInvoice.taxID);
    allValid = allValid && numberValid;
  }
  return allValid;
}

export function constructAddressInfo(
  waypoints,
  deliveryInfo,
  { cityId, cityName }
) {
  return waypoints.map(waypoint => ({
    contacts_phone_no: deliveryInfo[waypoint.id].phone,
    contacts_name: deliveryInfo[waypoint.id].name,
    house_number: deliveryInfo[waypoint.id].addressDetails,
    city_id: cityId,
    city_name: cityName,
    district_name: '',
    name: waypoint.name,
    addr: waypoint.address,
    lat_lon: {
      lon: waypoint.lng,
      lat: waypoint.lat,
    },
    poi_id: waypoint.placeId,
  }));
}

export function* onProceedOrder({ orderType }) {
  const waypoints = yield select(getFilledWaypts);

  const selectedVehicle = yield select(getSelectedService);

  const additionalServices = yield select(
    getSelectedNonVehicleStdSpecialRequests
  );

  const vehicleSpecs = yield select(getSelectedServiceVehicleStd);

  const { selectedPaymentMethodId } = yield select(getCheckout);
  const selectedCashCoupon = yield select(getSelectedCashCoupon);
  const selectedOnlineCoupon = yield select(getSelectedOnlineCoupon);
  let selectedCoupon;
  if (selectedPaymentMethodId === PaymentMethods.CASH.id) {
    selectedCoupon = selectedCashCoupon;
  }
  selectedCoupon = selectedOnlineCoupon;

  const isoCurrencyCode = yield select(getCurrentIsoCurrencyCode);

  const price = yield select(getPrice);

  const qs = orderType === 'scheduled' ? '?scheduled=true' : '';
  yield put(push(`/confirmation${qs}`));
  yield put(saveRouteAddresses());

  yield put(
    track('first_page_completed', {
      order_type: orderType,
      vehicle_type: selectedVehicle?.name,
      has_additional_services: Boolean(additionalServices.length > 0),
      has_vehicle_specs: Boolean(vehicleSpecs),
      has_coupon: Boolean(selectedCoupon),
      currency_code: isoCurrencyCode,
      order_amount: price.originalPrice.toNumber(),
      paid_order_amount: price.total.toNumber(),
      stop_total: waypoints.length,
    })
  );
}

export function* onSubmitOrder(args = {}) {
  const { retryCount = 0 } = args;
  try {
    // city
    const city = yield select(getCurrentCity);

    // data from services
    const selectedService = yield select(getSelectedService);
    if (!selectedService) return;
    const revision = yield select(getRevision);
    const selectedSpecialRequests = yield select(getSelectedSpecialRequests);
    const allowRequireProofOfDelivery = yield select(
      getAllowRequireProofOfDelivery
    );
    const { profile: userProfile, user_fid: userFid } = yield select(getUser);
    const isProofOfDeliveryRequired =
      allowRequireProofOfDelivery && userProfile.is_proof_of_delivery_required;

    const vehicleStd = yield select(getSelectedServiceVehicleStd);
    const vehicleStdNames = vehicleStd ? [vehicleStd.name] : [];
    const vehicleStdTagIds = vehicleStd ? [vehicleStd.stdTagId] : [];

    // remove VEHICLE_STD from the selected special requests
    const specialReqWithoutVehicleStd = selectedSpecialRequests.filter(
      item => !item.startsWith(VEHICLE_STD_PREFIX)
    );

    // Remove the vehicle service before the special request id
    const specialReq = specialReqWithoutVehicleStd.map(req =>
      req.slice(req.indexOf('-') + 1)
    );

    // data from checkout
    const checkout = yield select(getCheckout);
    const {
      selectedPaymentMethodId,
      note,
      preferFavorite,
      contact: { name, phone },
    } = checkout;

    // data from routing
    const wayPointData = yield select(getFilledWaypts);
    const deliveryInfo = yield select(getDeliveryInfo);
    const addressInfo = constructAddressInfo(wayPointData, deliveryInfo, city);

    // data from pricing
    const price = yield select(getPrice);
    const priceRaw = yield select(getPriceRaw);

    const selectedCashCoupon = yield select(getSelectedCashCoupon);
    const selectedOnlineCoupon = yield select(getSelectedOnlineCoupon);

    const getSelectedCouponBasedOnPayType = () => {
      if (selectedPaymentMethodId === PaymentMethods.CASH.id)
        return selectedCashCoupon;
      return selectedOnlineCoupon;
    };

    const selectedCoupon = getSelectedCouponBasedOnPayType();

    const immediateTimestamp = getImmediateDeliveryUnix();
    const timestamp = checkout.dirty.deliveryDatetime
      ? moment(checkout.deliveryDatetime).unix()
      : immediateTimestamp;

    const country = yield select(getCurrentCountry);

    const firstTimePlacingOrder = yield select(getIsFirstTimePlacingOrder);

    if (firstTimePlacingOrder) branchLogEvent('ADD_TO_CART');
    else branchLogEvent('PURCHASE');

    yield put(
      track('place_order_tapped', {
        order_type: checkout.dirty.deliveryDatetime ? 'scheduled' : 'immediate',
        vehicle_type: selectedService.name,
        pickup_time: new Date(timestamp * 1000).toISOString(),
        days_in_advance: Math.floor((timestamp - immediateTimestamp) / 86400), // 1 day = 86400 seconds
        has_additional_services: Boolean(specialReq.length),
        has_vehicle_specs: Boolean(vehicleStd),
        has_coupon: Boolean(selectedCoupon),
        currency_code: country.isoCurrencyCode,
        order_amount: price.originalPrice.toNumber(),
        paid_order_amount: price.total.toNumber(),
        stop_total: wayPointData.length,
        payment_method: paymentMethodEventMap[selectedPaymentMethodId],
        first_order: firstTimePlacingOrder,
      })
    );

    // uniform invoice data (TW)
    let uniformInvoice;

    if (country.id === 'TW') {
      uniformInvoice = constructUniformInvoice(userFid);
      if (!validateInvoiceInfo(uniformInvoice)) {
        yield put({ type: PLACE_ORDER_FAILURE });
        yield put(
          setCheckoutError(
            'uniformInvoice',
            'TW_Invoice.error_msg_incomplete_info'
          )
        );
        return;
      }
    }

    const isFavDriverOnly = yield select(getIsFavDriverOnly);
    const {
      hpay_cashier_url: hpayCashierUrl,
      order_uuid: orderUuid,
    } = yield call(placeOrder, {
      vehicleStdNames,
      vehicleStdTagIds,
      contactPhoneNo: phone,
      contactName: name,
      cityInfoRevision: revision,
      remark: note,
      addrInfo: addressInfo,
      selectedSpecReq: specialReq,
      totalPrice: price.total,
      priceItems: priceRaw,
      orderTime: timestamp,
      selectedPaymentMethodId,
      orderVehicleId: parseInt(selectedService.id, 10),
      uniformInvoice,
      isProofOfDeliveryRequired,
      selectedCouponId: selectedCoupon ? selectedCoupon.coupon_id : 0,
      preferFavorite,
      isFavDriverOnly,
    });

    // online payment
    if (selectedPaymentMethodId === PaymentMethods.ONLINE.id) {
      if (!hpayCashierUrl) {
        yield put({
          type: PLACE_ORDER_FAILURE,
          meta: {
            type: 'error',
            message: 'Online payment url does not exist',
          },
        });
        return;
      }
      yield put(
        initOnlinePayment({
          hpayCashierUrl,
          orderUuid,
        })
      );

      yield put(
        openModal('PAYMENT', { url: hpayCashierUrl, intent: 'PLACE_ORDER' })
      );
    } else {
      yield placeOrderSuccess(orderUuid, selectedPaymentMethodId);
    }
  } catch ({ message, errorCode }) {
    if (errorCode === 20001) {
      yield put({ type: PLACE_ORDER_FAILURE });
      yield put(openDialog('PLACE_ORDER_PRICE_UNMATCHED'));
      return;
    }

    if (errorCode === 10012 && retryCount < 1) {
      yield refetchServicesAndCall(onSubmitOrder, {
        retryCount: retryCount + 1,
      });
      return;
    }

    if (errorCode === ERROR_BUSINESS_PROFILE_REMOVED_RET) {
      yield put({ type: PLACE_ORDER_FAILURE });
      return;
    }

    yield put({
      type: PLACE_ORDER_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* placeOrderSuccess(orderUuid, paymentMethod) {
  yield put({ type: PLACE_ORDER_SUCCESS });
  yield put(replace('/orders'));
  yield put(openPanel('ORDER', { orderId: orderUuid }));
  if (paymentMethod === PaymentMethods.ONLINE.id) {
    yield put(track('online_payment_succeeded'));
  }
  yield put(
    track('order_paid_online', {
      payment_type: paymentMethodEventMap[paymentMethod],
    })
  );
}

export function* tryFetchOnlinePaymentPayStatus() {
  for (let count = 0; count < FETCH_ORDER_PAYMENT_STATUS_RETRIES; count += 1) {
    try {
      const { orderUuid } = yield select(getOnlinePaymentInfo);
      const { payStatus } = yield call(fetchOrderDetails, {
        orderUuid,
      });

      if (payStatus === 1) {
        return true;
      }

      throw new Error('online payment in progress');
    } catch (error) {
      yield delay(count * 1000);
    }
  }
  return false;
}

export function* onFetchOnlinePaymentStatus() {
  const isOnlinePaymentSuccess = yield call(tryFetchOnlinePaymentPayStatus);

  if (isOnlinePaymentSuccess) {
    const { orderUuid } = yield select(getOnlinePaymentInfo);
    yield put({ type: FETCH_ONLINE_PAYMENT_STATUS_SUCCESS });
    yield placeOrderSuccess(orderUuid, PaymentMethods.ONLINE.id);
  } else {
    yield put({ type: FETCH_ONLINE_PAYMENT_STATUS_FAILURE });
    yield put({ type: PLACE_ORDER_FAILURE });
    yield put(replace('/orders'));
  }
}

export function* watchOnlinePaymentChannel() {
  while (true) {
    const action = yield take(onlinePaymentChannel);
    yield put(action);
  }
}
export function* onFetchUnpaidOrder() {
  try {
    yield delay(1000);
    const data = yield call(fetchUnpaidOrder);
    if (data.allow_order_request === 2) {
      const title = data.msg || '';
      const orderId = data.order_unpay_num === 1 ? data.order_uuid : '';
      yield put({
        type: DISMISS_MESSAGE,
        id: 'IMPORT',
      });
      yield put({
        type: FETCH_UNPAID_ORDER_SUCCESS,
        meta: {
          type: 'error',
          title: '',
          message: 'Records.unpaid_tip',
        },
      });
      const unpaidOrderDialog = storage.getItem('unpaidOrderDialog');
      if (!unpaidOrderDialog || unpaidOrderDialog !== orderId) {
        yield put(openDialog('UNPAID_NOTICE', { title, orderId }));
        storage.setItem('unpaidOrderDialog', orderId);
      }
    } else {
      storage.removeItem('unpaidOrderDialog');
    }
  } catch (errorCode) {
    // eslint-disable-next-line no-console
    console.error('unpaidOrder', errorCode);
  }
}
export function* onGetDaylightSavings({ time }) {
  try {
    const data = yield call(getDaylightSavingsTime, { time });
    yield put({
      type: FETCH_DAYLIGHT_SUCCESS,
      daylightSavingsTimeType: data.daylight_type,
    });
  } catch (message) {
    // eslint-disable-next-line no-console
    console.error('onGetDaylightSavings', message);
  }
}

export function onLogout() {
  removeUserContact();
}

export default function* placeOrderSaga() {
  yield takeLatest(PROCEED_ORDER, onProceedOrder);
  yield takeLatest(PLACE_ORDER_REQUEST, onSubmitOrder);
  yield takeLatest(
    FETCH_ONLINE_PAYMENT_STATUS_REQUEST,
    onFetchOnlinePaymentStatus
  );
  yield takeLatest(REQUEST_LOGOUT, onLogout);
  yield takeLatest(FETCH_DAYLIGHT_REQUEST, onGetDaylightSavings);
  yield fork(watchOnlinePaymentChannel);
  yield throttle(
    500,
    [LOGIN_SUCCESS, FB_LOGIN_SUCCESS, GOOGLE_LOGIN_SUCCESS],
    onFetchUnpaidOrder
  );
}
