import { globalOrderStatusMap } from 'utils/helpers';
import { CANCEL_ORDER_SUCCESS, RATE_ORDER_SUCCESS } from './actions';

export const initState = {};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case CANCEL_ORDER_SUCCESS: {
      const { orderId } = action;
      return {
        ...state,
        [orderId]: {
          ...state[orderId],
          status: globalOrderStatusMap.CANCELLED,
        },
      };
    }
    case RATE_ORDER_SUCCESS: {
      const { orderId, rating } = action;
      return {
        ...state,
        [orderId]: {
          ...state[orderId],
          userRating: rating,
        },
      };
    }
    default: {
      return state;
    }
  }
}
