import { LalamoveApiError } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Request {
//   access_token: String;
//   client_id: Number | String;
//   country: String; // HK_HKG
//   ref_id: String;
//   target: 'servicer';
//   event: 'remove_favorite' | 'remove_ban';
// }
// interface Response {
//   success: Boolean;
//   footprint: String;
//   code?: String;
// }
/**
 * Removes favorite/banned driver
 *
 * @param {string} id - ref_id
 * @param {bool} favorite - true: remove favorite driver, false: remove banned driver
 * @return {Promise} statusObj - `{success: {bool}}`
 * @throws ERROR_INVALID_SESSION, ERROR_INVALID_PARAMETERS, ...
 */
export default async function removeDriver(id, favorite) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, city } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    country: city,
    ref_id: id,
    target: 'servicer',
    event: favorite ? 'remove_favorite' : 'remove_ban',
  };
  const res = await fetcher.post('vanmyfleet', params);
  const { success, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return res;
}
