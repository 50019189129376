import moment from 'moment';
import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import {
  getCurrentCountry,
  getCurrentCity,
  getCurrentLocale,
} from 'store/modules/region/selectors';
import { getUser } from 'store/modules/auth/selectors';
import UAPIFetcher from '../UAPIFetcher';
import FetcherFactory from '../FetcherFactory';

// 1613692800000 is the unix epoch timestamp for Friday, 19 February 2021 00:00:00
//
// As the backend Redis database does not have up to 90 days of rate order history
// immediately when we deploy this date range change, we need to gradually increase
// the number of days into the past that orders can be rated up until we get to 90
// days of history after 1613692800000
export const OLD_RATE_ORDER_API_DAYS_RANGE = 7;
export const NEW_RATE_ORDER_API_DAYS_RANGE = 90;
export const NEW_RATE_ORDER_DEPLOYMENT_TIMESTAMP = 1613692800000;

export enum RateDriverOptions {
  doNothing = 1,
  addToFavoriteList = 2,
  addToBanList = 3,
}

export default async function rateOrder(
  orderId: string,
  driverId: string,
  rating: number,
  comments: string,
  option: RateDriverOptions
): Promise<void> {
  const fetcher = FetcherFactory.make(UAPIFetcher);

  const state = await fetcher.getRootState();

  const { access_token: accessToken, profile_type: profileType } = getUser(
    state
  );
  const { cityId } = getCurrentCity(state);
  const { countryId } = getCurrentCountry(state);
  const locale = getCurrentLocale(state);

  const params = {
    args: JSON.stringify({
      comments,
      rating,
      op_to_driver: option,
      order_uuid: orderId,
      driver_fid: driverId,
    }),
    city_id: cityId,
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
    os: 'web',
    _t: moment().unix(),
  };

  const { ret, msg } = await fetcher.get('?_m=user_rating', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }
}
