import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import formatter from 'utils/formatter';
import { closeSideMenu, isSideMenuOpen } from 'store/modules/ui';
import { openPanel } from 'interfaces/global/store/modules/ui/actions';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { getCreditBalance } from 'interfaces/global/store/modules/wallet/selectors';
import { getUser } from 'interfaces/global/store/modules/auth/selectors';
import CitySelector from 'interfaces/global/containers/CitySelector';

import { enable, disable } from 'utils/scrollLock';

import DefaultAvatar from 'assets/svg/side-menu-avatar.svg';
import ParcelIcon from 'assets/svg/side-menu-parcel.svg';
import SettingsIcon from 'assets/svg/side-menu-settings.svg';
import HelpIcon from 'assets/svg/side-menu-help.svg';
import WalletIcon from 'assets/svg/side-menu-wallet.svg';

import { Dispatch } from 'redux';

import {
  Avatar,
  Container,
  Header,
  Heading,
  Menu,
  MenuItem,
  MenuItemContent,
  Icon,
  MenuItemTitle,
  MenuItemSubtitle,
  LineBreak,
} from './styles';

const SideMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(isSideMenuOpen);
  const {
    profile: { avatar, first_name: firstName, last_name: lastName },
  } = useSelector(getUser);
  const balance = useSelector(getCreditBalance);

  const onMenuItemClick = (action: (dispatch: Dispatch) => void) => {
    action(dispatch);
    closeSideMenuAndUnlockScroll();
  };

  const closeSideMenuAndUnlockScroll = () => {
    disable();
    dispatch(closeSideMenu());
  };

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={closeSideMenuAndUnlockScroll}
      closeTimeoutMS={400} // for closing animations
      onAfterOpen={enable}
    >
      <Header>
        <Avatar src={avatar || DefaultAvatar} />
        <Heading>
          {firstName} {lastName}
        </Heading>
      </Header>
      <Menu>
        {menuItems.map(item => (
          <MenuItem
            onClick={() => onMenuItemClick(item.action)}
            key={item.title}
          >
            <Icon src={item.icon} />
            <MenuItemContent>
              <MenuItemTitle>{t(item.title)}</MenuItemTitle>
            </MenuItemContent>
          </MenuItem>
        ))}
        {balance > 0 && (
          <MenuItem>
            <Icon src={WalletIcon} />
            <MenuItemContent>
              <MenuItemTitle>{formatter.currency(balance)}</MenuItemTitle>
              <MenuItemSubtitle>
                {t('SideMenu.wallet_balance')}
              </MenuItemSubtitle>
            </MenuItemContent>
          </MenuItem>
        )}
      </Menu>
      <LineBreak />
      <CitySelector onChangeLocation={closeSideMenuAndUnlockScroll} />
    </Container>
  );
};

const menuItems = [
  {
    icon: ParcelIcon,
    title: 'Header.orders',
    action: (dispatch: Dispatch) =>
      batch(() => {
        dispatch(push('/orders'));
        dispatch(track('orders_tapped'));
      }),
  },
  {
    icon: SettingsIcon,
    title: 'Header.settings',
    action: (dispatch: Dispatch) => dispatch(push('/settings')),
  },
  {
    icon: HelpIcon,
    title: 'Help.heading_help_center',
    action: (dispatch: Dispatch) => dispatch(openPanel('HELP')),
  },
];

export default SideMenu;
