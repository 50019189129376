import React, { Component, Fragment } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Input } from '@lalamove/karang';
import styled from 'styled-components';

import { compose, noop } from 'utils/helpers';

import { updatePassword } from 'store/modules/settings';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';

import { Form, CheckIcon } from '../style';

const TICK_TIMEOUT = 3000;

const StyledInput = styled(Input)`
  display: block;
  margin-bottom: 1rem;
`;

const ButtonGroup = styled.div`
  text-align: right;
`;

const CancelButton = styled(Button)`
  margin-right: 1rem;
`;

const initState = {
  isEditing: false,
  old: '',
  newPassword: '',
  confirm: '',
  mismatch: false,
  // error: null,
  isReady: false,
  isShownTick: false,
};

class Password extends Component {
  static defaultProps = {
    t: noop,
    updatePassword: noop,
    passwordExist: true,
    isSubmitting: false,
    apiError: null,
  };

  static propTypes = {
    t: func,
    updatePassword: func,
    passwordExist: bool,
    isSubmitting: bool,
    apiError: shape({ message: string }),
  };

  state = initState;

  componentDidUpdate({ isSubmitting, apiError }, { isShownTick }) {
    if (!isSubmitting && this.props.isSubmitting) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isShownTick: false });
    }
    if (isSubmitting && !this.props.isSubmitting) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isShownTick: !this.props.apiError });
    }
    if (!isShownTick && this.state.isShownTick) {
      const { isShownTick: _, ...remain } = initState; // eslint-disable-line no-unused-vars
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ...remain });
      this.timeout = setTimeout(
        () => this.setState({ isShownTick: false }),
        TICK_TIMEOUT
      );
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  toggleEditMode = () => {
    this.setState(({ isEditing }) => ({
      ...initState,
      isEditing: !isEditing,
    }));
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      const { old, newPassword, confirm } = this.state;
      if (this.props.passwordExist) {
        this.setState({ isReady: !!(old && newPassword && confirm) });
      } else {
        this.setState({ isReady: !!(newPassword && confirm) });
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { old, newPassword, confirm, isReady } = this.state;
    if (!isReady) return;
    this.setState({ mismatch: newPassword !== confirm }, async () => {
      const { mismatch } = this.state;
      if (mismatch) return;
      if (this.props.passwordExist) {
        this.props.updatePassword({ newPassword, oldPassword: old });
      } else {
        this.props.updatePassword({ newPassword });
      }
    });
  };

  render() {
    const { t, passwordExist, isSubmitting, apiError } = this.props;
    const {
      isEditing,
      old,
      newPassword,
      confirm,
      mismatch,
      isReady,
      isShownTick,
    } = this.state;
    return isEditing ? (
      <Form onSubmit={this.handleSubmit}>
        {passwordExist && (
          <StyledInput
            disabled={isSubmitting}
            name="old"
            label={t('Settings.input_label_password_current')}
            value={old}
            type="password"
            onChange={this.handleInputChange}
            error={
              apiError && apiError.message === 'ERROR_WRONG_PASSWORD'
                ? t(apiError.message)
                : null
            }
          />
        )}
        <StyledInput
          disabled={isSubmitting}
          name="newPassword"
          label={t('Settings.input_label_password_new')}
          value={newPassword}
          type="password"
          onChange={this.handleInputChange}
          error={
            apiError && apiError.message !== 'ERROR_WRONG_PASSWORD'
              ? t(apiError.message)
              : null
          }
        />
        <StyledInput
          disabled={isSubmitting}
          name="confirm"
          label={t('Settings.input_label_password_confirm')}
          value={confirm}
          type="password"
          onChange={this.handleInputChange}
          error={mismatch ? t('Settings.input_error_password_mismatch') : null}
        />
        <ButtonGroup>
          <CancelButton
            disabled={isSubmitting}
            type="reset"
            variant="link"
            onClick={this.toggleEditMode}
          >
            {t('Settings.button_cancel')}
          </CancelButton>
          <Button
            disabled={!isReady || isSubmitting}
            type="submit"
            variant="primary"
            solid
          >
            {t('Settings.button_save')}
          </Button>
        </ButtonGroup>
      </Form>
    ) : (
      <Fragment>
        <Button variant="link" onClick={this.toggleEditMode}>
          {passwordExist
            ? t('Settings.button_change_password')
            : t('Settings.button_set_password')}
        </Button>
        {isShownTick && <CheckIcon />}
      </Fragment>
    );
  }
}

const mapState = state => ({
  isSubmitting: createLoadingSelector(['PASSWORD_UPDATE'])(state),
  apiError: makeMessageSelector(['PASSWORD_UPDATE'])(state),
});

export default compose(
  withTranslation(),
  connect(mapState, { updatePassword })
)(Password);
