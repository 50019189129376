import { LalamoveApiError } from 'utils/helpers';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Request {
//   email_code: String;
//   new_password: String;
// }
// interface Response {
//   success: Boolean;
//   code: String;
// }
// set new password (email flow)
export default async function setNewPasswordByEmail(emailCode, password) {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  const params = {
    email_code: emailCode,
    new_password: password,
  };
  const res = await fetcher.post('setnewpasswordbysmsanduuid', params);
  const { success, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return res;
}
