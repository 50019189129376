import React, { Component } from 'react';
import { bool, string, func, shape } from 'prop-types';
import { userMark as UserIcon } from '@lalamove/karang/dist/components/Icon/icons';
// import validator from 'utils/validator';
import { noop } from 'utils/helpers';
import { withResponsiveMedia } from 'components/MediaQuery';
import { MOBILE_HEADER_HEIGHT } from 'components/MobileHeader/styles';
import OrderDatePicker from './OrderDatePicker';
import OrderTimePicker from './OrderTimePicker';
import {
  Form,
  FormGroup,
  DateContactFormItem,
  ScInput,
  CheckoutHeading,
} from '../style';

import { ICON_SIZE, ICON_COLOR } from '../config';

export class DateContact extends Component {
  static defaultProps = {
    isScheduled: false,
    deliveryDatetime: '',
    defaultDatetime: '',
    name: '',
    phone: '',
    errors: {},
    title: '',
    labelPhone: '',
    nowButtonText: '',
    placeholderTime: '',
    onContactChange: noop,
    onContactBlur: noop,
    onDatetimeChange: noop,
    onError: noop,
  };

  static propTypes = {
    isScheduled: bool,
    deliveryDatetime: string,
    defaultDatetime: string,
    name: string,
    phone: string,
    errors: shape({ name: string, phone: string }),
    title: string,
    labelPhone: string,
    nowButtonText: string,
    placeholderTime: string,
    onContactChange: func,
    onContactBlur: func,
    onDatetimeChange: func,
    onError: func,
    isMobile: bool.isRequired,
  };

  handleTextChange = ({ target: { name, value } }) => {
    this.props.onError(name, ''); // TODO: clean up
    this.props.onContactChange({
      [name]: value,
    });
  };

  handleBlur = ({ target: { name, value } }) => {
    this.props.onContactBlur({
      [name]: value.trim(),
    });
    this.validateContact(name);
  };

  validateContact = inputName => {
    const { name, phone } = this.props;
    // const { message, valid } = validator.phone(phone, true);
    const valid = phone.length >= 2 && phone.length <= 255;
    if (!valid) {
      // this.props.onError('phone', message);
      this.props.onError('phone', 'Checkout.error_msg_invalid_phone');
    }
    if (!name.trim()) {
      this.props.onError('name', 'Checkout.error_msg_name_required');
    }
  };

  handleInputFocus = () => {
    if (this.props.isMobile && this.input) {
      const { top } = this.input.getBoundingClientRect();
      window.scrollBy({
        top: top - MOBILE_HEADER_HEIGHT,
        behavior: 'smooth',
      });
    }
  };

  render() {
    const {
      title,
      isScheduled,
      deliveryDatetime,
      defaultDatetime,
      phone,
      errors,
      labelPhone,
      nowButtonText,
      placeholderTime,
      onDatetimeChange,
      isMobile,
    } = this.props;

    const OrderDatePickerItem = (
      <DateContactFormItem>
        {deliveryDatetime && (
          <OrderDatePicker
            defaultDatetime={defaultDatetime}
            selectedDate={deliveryDatetime}
            isScheduled={isScheduled}
            onChange={onDatetimeChange}
          />
        )}
      </DateContactFormItem>
    );
    const OrderTimePickerItem = (
      <DateContactFormItem>
        {deliveryDatetime && (
          <OrderTimePicker
            placeholder={placeholderTime}
            nowButtonText={nowButtonText}
            defaultDatetime={defaultDatetime}
            selectedTime={deliveryDatetime}
            isScheduled={isScheduled}
            onChange={onDatetimeChange}
          />
        )}
      </DateContactFormItem>
    );

    return (
      <Form>
        <CheckoutHeading text={title}>
          <UserIcon color={ICON_COLOR} size={ICON_SIZE} />
        </CheckoutHeading>
        {isMobile ? (
          <>
            <FormGroup>{OrderDatePickerItem}</FormGroup>
            <FormGroup>{OrderTimePickerItem}</FormGroup>
          </>
        ) : (
          <FormGroup>
            {OrderDatePickerItem}
            {OrderTimePickerItem}
          </FormGroup>
        )}
        <FormGroup
          ref={ref => {
            this.input = ref;
          }}
        >
          <DateContactFormItem>
            <ScInput
              label={labelPhone}
              name="phone"
              value={phone}
              error={errors.phone}
              onChange={this.handleTextChange}
              onBlur={this.handleBlur}
              onFocus={this.handleInputFocus}
              onClick={this.handleInputFocus}
            />
          </DateContactFormItem>
        </FormGroup>
      </Form>
    );
  }
}

export default withResponsiveMedia(DateContact);
