import { GET_USER_SESSION_SUCCESS } from 'redux-react-session/dist/actionTypes';
import { VERIFY_SUCCESS, REQUEST_LOGOUT } from 'store/modules/auth/actions';
import {
  PREFERENCES_UPDATE_SUCCESS,
  SET_MARKETING_OPT_IN_SUCCESS,
} from 'store/modules/settings/actionTypes';
import { getUser } from 'store/modules/auth/selectors';
import {
  getCurrentLocation,
  getCurrentCountryCode,
  getCurrentLocale,
} from 'store/modules/region/selectors';

const authTracking = (prevState, action, nextState) => {
  const { client_id: clientId } = getUser(prevState);

  switch (action.type) {
    // triggered when app open with session, after registration and after login
    case GET_USER_SESSION_SUCCESS: {
      const { user } = action;
      window.analytics.identify(user.client_id, {
        corp_code: user.corporate_code || '',
        email:
          user.subscriptions && user.subscriptions[0]
            ? user.subscriptions[0].contact_email
            : '',
        email_registered: user.email || '',
        firstName: user.name || '',
        lastName: user.last_name || '',
        phone: user.phone_number || '',
        ...(user.is_marketing_opt_in !== -1 &&
          user.is_marketing_opt_in !== null && {
            marketing_opt_in: +user.is_marketing_opt_in,
          }),
      });
      break;
    }
    case VERIFY_SUCCESS: {
      window.analytics.identify({
        source_registered: 'webapp',
      });
      break;
    }
    case REQUEST_LOGOUT: {
      window.analytics.reset();
      window.analytics.identify({
        city: getCurrentLocation(prevState),
        country: getCurrentCountryCode(prevState),
        language: getCurrentLocale(prevState).slice(0, 2),
      });
      break;
    }
    // TODO: Move to settings.js when there are a lot events
    case SET_MARKETING_OPT_IN_SUCCESS: {
      const { optIn } = action;
      window.analytics.identify(clientId, {
        marketing_opt_in: +optIn,
      });
      break;
    }
    case PREFERENCES_UPDATE_SUCCESS: {
      const { email } = action;
      if (email) {
        window.analytics.identify(clientId, {
          email,
        });
      }
      break;
    }
    default:
      break;
  }
};

export default authTracking;
