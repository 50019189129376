import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Route, withRouter } from 'react-router-dom';
import { func, shape, bool, string, noop } from 'prop-types';
import { push, replace } from 'connected-react-router';
import { withResponsiveMedia } from 'components/MediaQuery';
import {
  proceedOrder,
  submitOrder,
  setFirstTimePlacingOrder,
} from 'interfaces/global/store/modules/checkout/actions';
import { fetchPrice } from 'store/modules/pricing';
import { createLoadingSelector } from 'store/modules/loading';
import { getCurrentCity } from 'store/modules/region/selectors';
import { getSelectedService } from 'store/modules/services';
import {
  enableActiveFetchLocationSearchHistory,
  disableActiveFetchLocationSearchHistory,
} from 'store/modules/searchHistory';
import Alert from 'interfaces/global/containers/Alert';
import Checkout from 'interfaces/global/containers/Checkout';
import HtmlTitle from 'components/HtmlTitle';
import { PriceBreakDownShape } from 'interfaces/global/views/Records/propTypes';
import PlaceOrder from 'interfaces/global/containers/PlaceOrder';
import { saveRouteAddresses } from 'interfaces/global/store/modules/searchHistory/actions';
import { getPrice } from 'interfaces/global/store/modules/pricing/selectors';

import Footer from 'interfaces/global/containers/PlaceOrderFooter';
import { getUserPlaceOrderInfo } from 'api/uAPI';
import RoutePreview from './components/RoutePreview';

import { Container, Left, LeftContent, Right } from './styles.ts';

const matchPaths = ['/', '/confirmation'];

// eslint-disable-next-line react/prefer-stateless-function
export class Home extends Component {
  static defaultProps = {
    t: noop,
    price: {},
    isSubmitting: false,
    // for tracking purposes:
    saveRouteAddresses: noop,
    selectedService: {},
  };

  static propTypes = {
    t: func,
    proceedOrder: func.isRequired,
    submitOrder: func.isRequired,
    price: PriceBreakDownShape,
    location: shape({}).isRequired,
    isSubmitting: bool,
    currentCity: shape({ id: string }).isRequired,
    enableActiveFetchLocationSearchHistory: func.isRequired,
    disableActiveFetchLocationSearchHistory: func.isRequired,
    historyPush: func.isRequired,
    historyReplace: func.isRequired,
    saveRouteAddresses: func,
    // for tracking purposes:
    selectedService: shape({}),
    isMobile: bool.isRequired,
    isDesktop: bool.isRequired,
    hasCheckedIsFirstTimePlacingOrder: bool.isRequired,
    setFirstTimePlacingOrder: func.isRequired,
  };

  async componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { enableActiveFetchLocationSearchHistory } = this.props;
    enableActiveFetchLocationSearchHistory();

    await this.setIsFirstTimePlacingOrder();
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-shadow
    const { disableActiveFetchLocationSearchHistory } = this.props;
    disableActiveFetchLocationSearchHistory();
  }

  setIsFirstTimePlacingOrder = async () => {
    if (!this.props.hasCheckedIsFirstTimePlacingOrder) {
      try {
        const {
          has_place_order: hasPlacedOrder,
        } = await getUserPlaceOrderInfo();
        this.props.setFirstTimePlacingOrder(hasPlacedOrder);
      } catch (error) {
        // Ignore UAPI errors such as the login token expiring to prevent crashes
      }
    }
  };

  handleBack = () => {
    const { historyReplace } = this.props;
    // if user change location, proceed to checkout, then click back,
    // `goBack()` will re-trigger change location:
    // this.props.history.goBack();
    historyReplace('/');
  };

  handleNext = orderType => {
    // eslint-disable-next-line no-shadow, no-unused-vars
    const { historyPush, saveRouteAddresses, proceedOrder } = this.props;
    proceedOrder(orderType);
  };

  handleSubmit = () => {
    /* eslint-disable no-shadow */
    const { submitOrder } = this.props;
    submitOrder();
  };

  render() {
    const {
      t,
      price,
      location,
      isSubmitting,
      currentCity,
      selectedService,
      isMobile,
      isDesktop,
    } = this.props;
    if (!matchPaths.includes(location.pathname)) return null;

    const alert = (
      <Alert
        messageSelector={[
          'FETCH_PRICE',
          'IMPORT',
          'PLACE_ORDER',
          'VALIDATE_SERVICE_AREA',
          'FETCH_UNPAID_ORDER',
          'NO_VEHICLE_FOUND',
        ]}
        persist
        params={{ orderType: selectedService.name }}
      />
    );

    return (
      <Container>
        <HtmlTitle>{t('Title.place_order')}</HtmlTitle>
        {isMobile && (
          <>
            {alert}
            <Route exact path={matchPaths[0]} component={PlaceOrder} />
            <Route exact path={matchPaths[1]} component={Checkout} />
          </>
        )}
        {isDesktop && (
          <>
            <Left>
              {alert}
              <LeftContent>
                <Route exact path={matchPaths[0]} component={PlaceOrder} />
                <Route exact path={matchPaths[1]} component={Checkout} />
              </LeftContent>
              {(price.total > 0 || price.items.length > 0) && (
                <Footer
                  onBack={this.handleBack}
                  onNext={this.handleNext}
                  onSubmit={this.handleSubmit}
                  isLoading={isSubmitting}
                />
              )}
            </Left>
            <Right>
              <RoutePreview currentCity={currentCity} showCountryPicker />
            </Right>
          </>
        )}
      </Container>
    );
  }
}

const loadingSelector = createLoadingSelector([
  'PLACE_ORDER',
  'VALIDATE_SERVICE_AREA',
]);

const mapState = state => ({
  price: getPrice(state),
  isSubmitting: loadingSelector(state),
  currentCity: getCurrentCity(state),
  // for tracking purposes:
  selectedService: getSelectedService(state),
  hasCheckedIsFirstTimePlacingOrder:
    state.checkout.hasCheckedIsFirstTimePlacingOrder,
});

export default compose(
  withRouter,
  withResponsiveMedia,
  connect(mapState, {
    fetchPrice,
    proceedOrder,
    submitOrder,
    enableActiveFetchLocationSearchHistory,
    disableActiveFetchLocationSearchHistory,
    saveRouteAddresses,
    historyPush: push,
    historyReplace: replace,
    setFirstTimePlacingOrder,
  }),
  withTranslation()
)(Home);
