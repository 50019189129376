import { takeLatest, select } from 'redux-saga/effects';

import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { getDonationList } from 'store/modules/region/selectors';
import storage from 'utils/storage';

export const DUPLICATE = 'DUPLICATE';
export const TRIPLICATE = 'TRIPLICATE';
export const DONATION = 'DONATION';
export const DEFAULT_INVOICE_TYPE = DONATION;
export const DEFAULT_INVOICE_INFO = {
  name: '',
  email: '',
  address: '',
};

export function loadSelectedInvoice() {
  return storage.getItem('invoice_selected') || DEFAULT_INVOICE_TYPE;
}

export function saveSelectedInvoice(type) {
  storage.setItem('invoice_selected', type);
}

export function loadInvoiceInfo() {
  return {
    [DUPLICATE]:
      JSON.parse(storage.getItem(`invoice_${DUPLICATE}`)) ||
      DEFAULT_INVOICE_INFO,
    [TRIPLICATE]: JSON.parse(storage.getItem(`invoice_${TRIPLICATE}`)) || {
      ...DEFAULT_INVOICE_INFO,
      taxID: '',
    },
    [DONATION]: JSON.parse(storage.getItem(`invoice_${DONATION}`)) || {
      donationIndex: 0,
    },
  };
}

export function saveInvoiceInfo(type, info) {
  saveSelectedInvoice(type);
  storage.setItem(`invoice_${type}`, JSON.stringify(info));
}

export function* getCompleteInvoiceInfo(type) {
  const info = loadInvoiceInfo()[type];
  if (type === DONATION) {
    const index = info.donationIndex;
    const donationList = yield select(getDonationList);
    return donationList[index];
  }
  return info;
}

export function onLogout() {
  storage.removeItem('invoice_selected');
  storage.removeItem(`invoice_${DUPLICATE}`);
  storage.removeItem(`invoice_${TRIPLICATE}`);
  storage.removeItem(`invoice_${DONATION}`);
}

export function* uniformInvoiceSaga() {
  yield takeLatest(REQUEST_LOGOUT, onLogout);
}
