import md5 from 'md5';
import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import { createSu } from 'utils/apiHelper';

export default async function login(username, password, areaCode) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const { cityId, countryId, locale } = await fetcher.getState();

  const params = {
    // version: '6.0.2',
    // revision: '6400',
    _t: new Date().getTime() / 1000,
    user_md5: md5(username),
    hcountry: countryId,
    hlang: toLLMLower(locale),
    args: JSON.stringify({
      phone_no: `+${areaCode}${username}`,
      password,
      device_id: '0',
      device_type: 'web',
      city_id: cityId,
    }),
  };

  const { data, ret, msg } = await fetcher.post(
    `index.php?_m=login_by_pwd&_su=${createSu()}`,
    params
  );

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data;
}
