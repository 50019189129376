/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { bool, instanceOf, func, number, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DROPDOWN } from 'styles/zIndex';
import { message as MessageIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { orange, white } from 'styles/colors';
import { darken } from 'polished';
import { actions } from 'store/modules/genesys';
import {
  genesysConfig,
  formField,
  WEB_CHAT_AVAILABLE_LANGUAGES,
} from 'utils/genesys.config';
import './style.css';

const ChatCanvas = styled.div`
  position: fixed;
  z-index: ${DROPDOWN};
  overflow: hidden;
  width: 52px;
  height: 52px;
  transition: right 0.3s;
`;

const ChatButton = styled.button`
  position: absolute;
  bottom: ${props => (props.hide ? '-100%' : '0')};
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${orange};
  color: ${white};
  outline: none;
  transition: bottom 0.1s ease-in-out;

  &:hover {
    background-color: ${darken(0.1, orange)};
  }
`;

export class ChatWidget extends Component {
  static defaultProps = {
    orderId: '',
  };

  static propTypes = {
    openWidget: func.isRequired,
    closeWidget: func.isRequired,
    isScriptLoaded: bool.isRequired,
    isOpen: bool.isRequired,
    genesysEnable: bool.isRequired,
    user: instanceOf(Object).isRequired,
    region: instanceOf(Object).isRequired,
    advanceConfig: instanceOf(Object).isRequired,
    khakiMetaAPI: func.isRequired,
    orderId: string,
    offset: shape({
      x: number.isRequired,
      y: number.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.regionUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.region.currentLocation !== this.props.region.currentLocation ||
      prevProps.region.currentLocale !== this.props.region.currentLocale
    ) {
      this.regionUpdate();
    }
  }

  getCookie = name => {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(';');

    // Loop through the array elements
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split('=');

      /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
      if (name === cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if not found
    return null;
  };

  regionUpdate = () => {
    const { region, khakiMetaAPI } = this.props;
    const { currentCountry } = region;
    khakiMetaAPI(currentCountry);
    const { advanceConfig } = this.props;
    const paramsData = this.getParams();
    const {
      widgets: { main, webchat },
    } = genesysConfig;

    // local file JSON
    const langFile = `../chatTranslations/${paramsData.language}.json`;

    // eslint-disable-next-line no-underscore-dangle
    window._genesys = {
      widgets: {
        main: { ...main, lang: paramsData.language, i18n: langFile },
        webchat: { ...webchat, userData: paramsData },
      },
    };
    // eslint-disable-line no-underscore-dangle
    window._genesys.widgets.webchat.transport.interactionData.routing.targetAddress =
      paramsData.queueName || 'WebChat';
    this.loadCxbus();
    advanceConfig(this.getAdvancedConfig());
  };

  loadCxbus = () => {
    const existingScript = document.getElementById('cxbus');

    if (!existingScript) {
      const { REACT_APP_WEB_CHAT_DATA_APP_URL } = process.env;
      const widgetBaseUrl = `${REACT_APP_WEB_CHAT_DATA_APP_URL}/9.0/`;
      const script = document.createElement('script');
      script.src = `${widgetBaseUrl}cxbus.min.js`;
      script.id = 'cxbus';
      document.head.appendChild(script);

      script.onload = () => {
        window.CXBus.configure({
          debug: false,
          pluginsPath: `${widgetBaseUrl}plugins/`,
        });
      };
    }
  };

  registerWebChatOpen = () => {
    const { openWidget } = this.props;
    if (
      !this.getCookie(
        '_genesys.widgets.webchat.state.purecloud-v2-sockets.JWtoken'
      )
    ) {
      window.customPlugin
        .command('WebChat.open', this.getAdvancedConfig())
        .done(() => {
          openWidget();
        });
    } else {
      openWidget();
    }
  };

  openWebChat = () => {
    const { closeWidget, openWidget, isScriptLoaded } = this.props;
    if (!isScriptLoaded) {
      window.widgetLoad = window.CXBus.loadPlugin('widgets-core');
      window.widgetLoad.done(() => {
        window.customPlugin = window.CXBus.registerPlugin('Custom');
        this.registerWebChatOpen();

        // Web chat Service closed
        window.customPlugin.subscribe('WebChat.closed', e => {
          closeWidget();
        });
        window.customPlugin.subscribe('WebChat.hideChatButton', e => {
          openWidget();
        });
      });
    } else {
      // Open genesys chat with advance data
      window.widgetLoad.done(() => {
        window.customPlugin.command('WebChat.open', this.getAdvancedConfig());
        openWidget();
      });
    }
  };

  getParams = () => {
    const { user, region, orderId } = this.props;
    const {
      phone_number: phoneNumber, // eslint-disable-line react/prop-types
      email,
      name: firstName,
      last_name: lastName,
    } = user;
    const { currentLocation, currentLocale } = region;

    const orderInfo = orderId ?? null;
    // eslint-disable-next-line react/prop-types
    const language = this.languageResolver(
      currentLocale.split('_')[0].toUpperCase()
    );

    const queueName = this.queueNameResolver(
      `${currentLocation}_${language}_USER_CHAT`
    );

    return {
      firstName,
      lastName,
      email,
      queueName,
      phoneNumber,
      orderInfo,
      language,
    };
  };

  // Fallback language depending on the project environment
  languageResolver = language => {
    let resolvedLanguage = language;

    if (!WEB_CHAT_AVAILABLE_LANGUAGES.includes(language)) {
      resolvedLanguage = 'EN';
    }

    return resolvedLanguage;
  };

  queueNameResolver = queueName => {
    const { REACT_APP_WEB_CHAT_MODE } = process.env;
    const { region } = this.props;
    const { currentLocation } = region;

    let resolvedQueueName = queueName;
    if (queueName) {
      // Fallback system to IN_LEH one if not in production
      if (REACT_APP_WEB_CHAT_MODE !== 'production') {
        resolvedQueueName = resolvedQueueName.replace(
          `${currentLocation}`,
          'IN_LEH'
        );
      }
    }

    return resolvedQueueName;
  };

  getAdvancedConfig = () => {
    const paramsData = this.getParams();

    const { firstName, lastName, email, phoneNumber, orderLink } = paramsData;

    const { form } = formField;
    const formInputField = [
      {
        id: 'cx_webchat_form_firstname',
        name: 'firstname',
        maxlength: '100',
        placeholder: 'First Name',
        label: 'First Name',
        value: firstName,
        readonly: !!firstName,
      },
      {
        id: 'cx_webchat_form_lastname',
        name: 'lastname',
        maxlength: '100',
        placeholder: 'Last Name',
        label: 'Last Name',
        value: lastName,
        readonly: !!lastName,
      },
      {
        id: 'cx_webchat_form_email',
        name: 'email',
        maxlength: '100',
        placeholder: 'Optional',
        label: 'Email',
        value: email,
        readonly: !!email,
      },
      {
        id: 'cx_webchat_form_subject',
        name: 'subject',
        maxlength: '100',
        placeholder: 'Optional',
        label: 'Subject',
        value: 'Web Chat',
      },
      {
        id: 'custom_field_1',
        name: 'customField1',
        maxlength: '100',
        placeholder: 'Custom Data',
        label: 'custom_field_1',
        value: `Order ID: ${orderLink}`,
        type: 'hidden',
      },
    ];
    const formData = {
      ...form,
      firstname: firstName,
      lastname: lastName,
      email,
      customField1: `Order ID: ${orderLink}`,
      phoneNumber,
    };
    return {
      form: formData,
      formJSON: {
        wrapper: '<table></table>',
        inputs: formInputField,
      },
    };
  };

  render() {
    const { isOpen, genesysEnable, offset } = this.props;
    const { x: right, y: bottom } = offset;
    return (
      genesysEnable && (
        <ChatCanvas style={{ right, bottom }}>
          <ChatButton onClick={this.openWebChat} hide={isOpen}>
            <MessageIcon size={24} color={white} />
          </ChatButton>
        </ChatCanvas>
      )
    );
  }
}
const mapStateToProps = ({ region, session, genesys, zendesk }) => ({
  region: region || {},
  user: session.user,
  isOpen: genesys.isOpen,
  isScriptLoaded: genesys.isScriptLoaded,
  genesysEnable: genesys.genesysEnable,
  offset: zendesk.offset,
  orderId: zendesk.tags.orderId,
});

const mapDispatchToProps = {
  openWidget: actions.openWidget,
  closeWidget: actions.closeWidget,
  advanceConfig: actions.genesysAdvanceConfig,
  khakiMetaAPI: actions.khakiMetaAPI,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatWidget);
