import Big from 'big.js';
import {
  call,
  put,
  select,
  takeLatest,
  fork,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { createPrice } from 'interfaces/global/store/modules/pricing/saga';
import {
  cancelOrder,
  getCancelReasons,
  getOrderAppealList,
  updateAppeal,
  payAppeal,
  favoriteDriver,
  banDriver,
  addPriorityFee,
  getRecords,
  fetchOrderDetails,
  rateOrder,
} from 'api/uAPI';
import { channel } from 'redux-saga';
import { DEFAULT_CURRENCY_RATE } from 'interfaces/global/config';
import { trackingStatusMap, UApiError } from 'utils/helpers';
import {
  openDialog,
  closePanel,
  openModal,
  isPanelOpen,
} from 'store/modules/ui';
import { getCurrentCountry } from 'store/modules/region/selectors';
import {
  doImport,
  importDeliveryInfos,
  showPlaceOrderAgainImportErrorMessage,
} from 'interfaces/global/store/modules/routing/saga';
import {
  updateContact,
  updateNote,
  toggleFavDriver,
} from 'interfaces/global/store/modules/checkout/actions';
import { getServices } from 'interfaces/global/store/modules/services/selectors';
import { setService } from 'interfaces/global/store/modules/services/actions';
import { push } from 'connected-react-router';
import { importAdditionalServices } from 'interfaces/global/store/modules/services/sagas/additionalServices';
import { openPanel } from 'interfaces/global/store/modules/ui/actions';

import {
  PaymentMethods,
  ALL_PAYMENT_METHOD_IDS,
} from 'interfaces/global/store/modules/checkout/types';
import { getIsFavDriverOnly } from 'interfaces/global/store/modules/auth/selectors';
import {
  FETCH_RECORDS_REQUEST,
  FETCH_RECORDS_SUCCESS,
  FETCH_RECORDS_FAILURE,
  FETCH_RECORD_REQUEST,
  FETCH_RECORD_SUCCESS,
  FETCH_RECORD_FAILURE,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILURE,
  FETCH_CANCEL_REASONS_REQUEST,
  FETCH_CANCEL_REASONS_SUCCESS,
  FAVORITE_DRIVER_REQUEST,
  FAVORITE_DRIVER_SUCCESS,
  FAVORITE_DRIVER_FAILURE,
  BAN_DRIVER_REQUEST,
  BAN_DRIVER_SUCCESS,
  BAN_DRIVER_FAILURE,
  ORDERAPPEAL_UPDATE_REQUEST,
  ORDERAPPEAL_UPDATE_SUCCESS,
  ORDERAPPEAL_UPDATE_FAILURE,
  PAY_APPEAL_REQUEST,
  PAY_APPEAL_SUCCESS,
  CLONE_ORDER,
  UPDATE_PRIORITY_FEE_REQUEST,
  UPDATE_PRIORITY_FEE_SUCCESS,
  UPDATE_PRIORITY_FEE_FAILURE,
  RATE_ORDER_REQUEST,
  RATE_ORDER_SUCCESS,
  RATE_ORDER_FAILURE,
  fetchRecord,
  TRACK_HELP_CENTER_TAPPED,
} from './actions';
import {
  getOrders,
  getRouteByOrderId,
  getOrderDetails,
  getOrdersPaginationParams,
} from './selectors';
import { track } from '../tracking/actions';

const appealChannel = channel();

export function* onFetchRecords({ params }) {
  try {
    const { current, prepend, ...query } = params;
    const { lastId, orders } = yield call(getRecords, query);

    const { endPage } = yield select(getOrdersPaginationParams);
    const newEndPage = getNewEndPage(
      current,
      endPage,
      orders.length,
      query.max
    );

    const existingOrders = yield select(getOrders);

    const findInExistingOrders = ({ order }) =>
      !existingOrders.find(existingOrder => order.id === existingOrder.id);

    const newOrderIds = orders
      .filter(findInExistingOrders)
      .map(({ order }) => order.id);

    const { order: openedOrder } = yield select(getOrderDetails);
    const openedOrderFromApi =
      openedOrder &&
      orders.find(({ order }) => order.id === openedOrder.id)?.order;

    if (openedOrderFromApi) {
      const hasUpdates =
        openedOrder.orderStatus !== openedOrderFromApi.status.id;
      if (hasUpdates && (yield select(isPanelOpen('ORDER')))) {
        yield put(fetchRecord(openedOrder.id));
      }
    }

    yield put({
      type: FETCH_RECORDS_SUCCESS,
      prepend,
      lastId,
      newEndPage,
      ids: newOrderIds,
      orders,
    });
  } catch (e) {
    if (e instanceof UApiError) {
      yield put({ type: FETCH_RECORDS_FAILURE });
    } else throw e;
  }
}

/**
 * Try to determine which page is the end page with exisitng information, and the newly fetched orders.
 * @param {number} currentPage Current page number perceived by user
 * @param {number} currentEndPage The last page number that still receive orders with positive length. -1 if unknown.
 * @param {number} newOrdersCount The number of newly received orders
 * @param {number} pageSize The maximum number of orders per page
 * @returns {number} Returns -1 if still cannot be determined.
 */
export const getNewEndPage = (
  currentPage,
  currentEndPage,
  newOrdersCount,
  pageSize
) => {
  // We already discovered the end page, return directly
  if (currentEndPage !== -1) return currentEndPage;
  // initial fetch has doubled pageSize to prefetch the 2nd page
  if (currentPage === 1) {
    // number of received orders is containable in page 1
    if (newOrdersCount <= pageSize / 2) return 1;
    // number of received orders is > what page 1 can hold, but still containable in 2 pages
    if (newOrdersCount < pageSize) return 2;
  }
  // number of received orders for next page is 0, so we know current page is the end
  if (newOrdersCount === 0) return currentPage;
  // number of received orders for next page is less than a page size, we know next page will be the last one
  if (newOrdersCount < pageSize) return currentPage + 1;
  // end page is still unknown after this round of fetching (newOrdersCount === pageSize)
  return -1;
};

export function* onCloneOrder({ orderId }) {
  const {
    order: {
      creatorName,
      creatorPhone,
      noteToDriver,
      serviceTypeId,
      specialRequests,
      subRequests,
      preferFavorite,
      onlyFavorite,
    },
  } = yield select(getOrderDetails);

  const { waypoints, deliveryInfo } = yield select(getRouteByOrderId, orderId);

  const wayptsToImport = [...waypoints]; // create shadow copy
  const updatedWayptIds = yield call(doImport, wayptsToImport);
  yield call(importDeliveryInfos, deliveryInfo, updatedWayptIds);

  yield put(
    updateContact({
      name: creatorName,
      phone: creatorPhone,
    })
  );
  yield put(updateNote(noteToDriver));

  // First check if only matching with favorite drivers is enabled, then check to see
  // what the order details API says was selected for that previous order. Finally,
  // check if the previous order was for preferred favorite drivers
  const isFavDriverOnlyEnabled = yield select(getIsFavDriverOnly);
  const isFavDriverOnly = isFavDriverOnlyEnabled && onlyFavorite;
  const checkFavDriverCheckbox = isFavDriverOnly || preferFavorite;
  yield put(toggleFavDriver(checkFavDriverCheckbox));

  const services = yield select(getServices);
  const serviceIndex = services.findIndex(
    service => service.id === serviceTypeId
  );

  yield put(push('/'));

  if (serviceIndex > -1) {
    yield put(setService(serviceIndex));
    yield call(
      importAdditionalServices,
      serviceTypeId,
      specialRequests,
      subRequests
    );
  } else yield showPlaceOrderAgainImportErrorMessage();
}

export function* onCancelOrder({ orderId, reason, status, reasonId }) {
  try {
    yield call(cancelOrder, orderId, reason, status, reasonId);
    yield put({ type: CANCEL_ORDER_SUCCESS, orderId, reason });
    yield put(openPanel('ORDER', { orderId, currentType: null }));
  } catch ({ message, errorCode }) {
    if (errorCode === 10102) {
      yield put({ type: CANCEL_ORDER_FAILURE });
      yield put(openDialog('CANCEL_ORDER_FAILED', { orderId }));
    } else {
      yield put({
        type: CANCEL_ORDER_FAILURE,
        meta: {
          type: 'error',
          message,
        },
      });
    }
  }
}

const getPriorityFeeErrorMessage = error => {
  const { message, errorCode } = error;

  if (errorCode === 10009) return 'RecordPanel.order_status_changed_error';

  return message;
};

export function* onUpdatePriorityFee({ orderId, amount }) {
  try {
    yield call(addPriorityFee, orderId, amount);
    yield put({
      type: UPDATE_PRIORITY_FEE_SUCCESS,
    });
  } catch (error) {
    if (error instanceof UApiError) {
      yield put({
        type: UPDATE_PRIORITY_FEE_FAILURE,
        meta: {
          type: 'error',
          message: getPriorityFeeErrorMessage(error),
        },
      });
    } else throw error;
  } finally {
    yield put(closePanel());
  }
}

export function* onFetchCancelReasons() {
  try {
    const { reason } = yield call(getCancelReasons);
    yield put({ type: FETCH_CANCEL_REASONS_SUCCESS, reason });
  } catch ({ message }) {
    yield put({
      type: CANCEL_ORDER_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* onFetchRecord({ orderId }) {
  try {
    const order = yield call(fetchOrderDetails, {
      orderUuid: orderId,
    });
    const {
      currencyRate = DEFAULT_CURRENCY_RATE,
      isoCurrencyCode,
    } = yield select(getCurrentCountry);

    const collectionFen = order.order.collectionFen
      ? `${Big(order.order.collectionFen)
          .div(currencyRate)
          .toString()}${isoCurrencyCode}`
      : '';

    const allowAppealList =
      order.appeal.appealStatus === 2
        ? yield call(getOrderAppealList, {
            orderDisplayId: order.order.refId,
          })
        : [];

    const isPaymentMethodSupported = ALL_PAYMENT_METHOD_IDS.includes(
      order.order.paymentMethodId
    );

    yield put({
      type: FETCH_RECORD_SUCCESS,
      order: {
        ...order,
        appeal: { ...order.appeal, allowAppealList },
        order: {
          ...order.order,
          ...(!isPaymentMethodSupported && {
            paymentMethodId: PaymentMethods.ONLINE.id,
          }),
          collectionFen,
        },
        price: createPrice(order.priceInfo, currencyRate).price,
      },
    });
  } catch (e) {
    if (e instanceof UApiError) {
      yield put({ type: FETCH_RECORD_FAILURE });
    } else throw e;
  }
}

/**
 * Formats the string for submitting the user feedback as the reasons and suggestions separated by back ticks
 * @param {string[]} reasons Array of selected good or bad reasons
 * @param {string} suggestions Manually entered text in the suggestions text box
 */
export const formatCommentsString = (reasons, suggestions) => {
  if (reasons.length > 0) {
    const joinedReasons = reasons.join('、');

    return suggestions.trim().length > 0
      ? `${joinedReasons}、${suggestions}`
      : joinedReasons;
  }

  return suggestions;
};

export function* onRateOrder({
  orderId,
  driverId,
  rating,
  reasons,
  suggestions,
  option,
}) {
  const comments = formatCommentsString(reasons, suggestions);

  try {
    yield call(rateOrder, orderId, driverId, rating, comments, option);
    yield put({
      type: RATE_ORDER_SUCCESS,
      orderId,
      rating,
      driverId,
    });
  } catch (error) {
    if (error instanceof UApiError) {
      yield put({
        type: RATE_ORDER_FAILURE,
        meta: {
          type: 'error',
          message: error.message,
        },
      });
      yield put(closePanel());
    } else throw error;
  }
}

export function* onFavoriteDriver({ driverId }) {
  try {
    yield call(favoriteDriver, driverId);
    yield put({ type: FAVORITE_DRIVER_SUCCESS, driverId });
  } catch ({ message }) {
    yield put({
      type: FAVORITE_DRIVER_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* onBanDriver({ driverId }) {
  try {
    yield call(banDriver, driverId);
    yield put({ type: BAN_DRIVER_SUCCESS, driverId });
  } catch ({ message }) {
    yield put({
      type: BAN_DRIVER_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* onfetchUpdateAppeal({ refId, orderId, appealItems, desc }) {
  try {
    yield call(updateAppeal, {
      refId,
      appealItems,
      desc,
    });
    yield put({ type: ORDERAPPEAL_UPDATE_SUCCESS });
    yield call(onFetchRecord, {
      orderId,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('SAGA ORDER DETAIL FAIL', e);
    yield put({ type: ORDERAPPEAL_UPDATE_FAILURE });
  }
}

export function* onFetchPayAppeal({ orderId, amount }) {
  try {
    const data = yield call(payAppeal, {
      orderId,
      amount,
    });
    yield put({ type: PAY_APPEAL_SUCCESS });
    const payUrl = data.hpay_cashier_url;
    yield put(
      openModal('PAYMENT', {
        url: payUrl,
        intent: 'SETTLE_FEE',
        orderId,
        amount,
      })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('PAY APPEAL ERROR', e);
  }
}
export function* watchAppealChannel() {
  while (true) {
    const action = yield take(appealChannel);
    yield put(action);
  }
}

export function* onHelpCenterTapped() {
  const isOrderPanelOpen = yield select(isPanelOpen('ORDER'));
  if (isOrderPanelOpen) {
    const {
      order: { id, status },
    } = yield select(getOrderDetails);
    yield put(
      track('help_center_tapped', {
        order_id: id,
        order_status: trackingStatusMap[status],
      })
    );
  } else {
    yield put(track('help_center_tapped'));
  }
}

export default function* recordsSaga() {
  yield takeLatest(FETCH_RECORDS_REQUEST, onFetchRecords);
  yield takeLatest(CANCEL_ORDER_REQUEST, onCancelOrder);
  yield takeLatest(UPDATE_PRIORITY_FEE_REQUEST, onUpdatePriorityFee);
  yield takeLatest(FETCH_CANCEL_REASONS_REQUEST, onFetchCancelReasons);
  yield takeLatest(FETCH_RECORD_REQUEST, onFetchRecord);
  yield takeLatest(ORDERAPPEAL_UPDATE_REQUEST, onfetchUpdateAppeal);
  yield takeLatest(FAVORITE_DRIVER_REQUEST, onFavoriteDriver);
  yield takeLatest(BAN_DRIVER_REQUEST, onBanDriver);
  yield takeLatest(PAY_APPEAL_REQUEST, onFetchPayAppeal);
  yield takeLatest(CLONE_ORDER, onCloneOrder);
  yield takeLatest(RATE_ORDER_REQUEST, onRateOrder);
  yield takeEvery(TRACK_HELP_CENTER_TAPPED, onHelpCenterTapped);
  yield fork(watchAppealChannel);
}
