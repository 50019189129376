import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { register } from 'api/authAPI';

import validator from 'utils/validator';
import i18n from 'utils/i18n';
import storage from 'utils/storage';
import { handleSubmitPin, handleResendPin } from './verification';

// action-types
import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FB_USER_DATA_RECEIVED,
  FB_LOGIN_SUCCESS,
  FB_LOGIN_CANCEL,
  VERIFY_REQUEST,
  RESEND_REQUEST,
} from './actions';

// state
export const initState = {
  // for social media login (Facebook)
  firstName: '',
  lastName: '',
  email: '',
  socialLoginId: null,
};

// reducer
export default function registerReducer(state = initState, action) {
  switch (action.type) {
    case REGISTER_SUCCESS:
    case FB_LOGIN_CANCEL:
    case FB_LOGIN_SUCCESS:
      return initState;
    case FB_USER_DATA_RECEIVED: {
      const { fbUser } = action;
      return {
        ...state,
        firstName: fbUser.first_name,
        lastName: fbUser.last_name,
        email: fbUser.email,
        socialLoginId: fbUser.id,
      };
    }
    default:
      return state;
  }
}

// side effects
export function* handleSubmitRegister({
  firstName,
  lastName,
  email,
  country,
  countryCode,
  mobile,
  password,
  lang,
  socialLoginId,
  marketingOptIn,
}) {
  try {
    if (email) {
      const { valid, message } = validator.email(email);
      if (!valid) {
        throw new Error(message);
      }
    }

    const response = yield call(register, {
      firstName,
      lastName,
      email,
      country,
      countryCode,
      mobile,
      password,
      lang,
      socialLoginId,
      marketingOptIn,
    });

    yield put({ type: REGISTER_SUCCESS });
    yield put(replace('/verification'));

    let success = false;
    storage.setItem('marketing_optin', marketingOptIn);

    while (!success) {
      const action = yield take([VERIFY_REQUEST, RESEND_REQUEST]);
      if (action.type === VERIFY_REQUEST) {
        const { pin } = action;
        const res = yield call(handleSubmitPin, {
          pin,
          clientId: response.data.client_id,
          secret: response.data.secret,
        });
        if (socialLoginId && res) {
          yield put({ type: FB_LOGIN_SUCCESS });
        }
        success = res;
      } else if (action.type === RESEND_REQUEST) {
        const { blockDuration, method } = action;
        yield fork(handleResendPin, {
          secret: response.data.secret,
          lang: i18n.language,
          blockDuration,
          method,
        });
      }
    }
  } catch (err) {
    yield put({
      type: REGISTER_FAILURE,
      meta: {
        type: 'error',
        message: err.message,
        data: 'llm.register',
      },
    });
  }
}

export function* registerSaga() {
  yield takeLatest(REGISTER_REQUEST, handleSubmitRegister);
}
