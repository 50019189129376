import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import moment from 'moment';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import { createSu } from 'utils/apiHelper';

const getSocialAccountRegisterSMS = async ({
  phoneNumber,
  email,
  socialSource,
}) => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const { cityId, countryId, locale } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      phone_no: phoneNumber,
      email,
      social_source: socialSource,
    }),
    os: 'web',
    _t: moment().unix(),
  };

  const { ret, data, msg } = await fetcher.post(
    `?_m=social_register_send_sms&city_id=${cityId}&os_version=29&revision=6501&version=6.5.1&channel=dev&device_type=pc&device_id=0&_su=${createSu()}&hcountry=${countryId}&hlang=${toLLMLower(
      locale
    )}`,
    params
  );

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data;
};
export default getSocialAccountRegisterSMS;
