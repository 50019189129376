import { call, put, select, fork, takeLatest } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import moment from 'moment';

import { vanRequest } from 'api/mobileAPI';
import validator from 'utils/validator';
import { getFilledWaypts } from 'store/modules/routing';

import {
  FETCH_PRICE_SUCCESS,
  getSelectedService,
  getSelectedSpecialRequests,
  getPrice,
} from 'store/modules/pricing';
import { actions as regionAction } from 'store/modules/region';
import { openDialog } from 'store/modules/ui';
import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { getCurrentCountry } from 'store/modules/region/selectors';

import {
  DUPLICATE,
  TRIPLICATE,
  loadSelectedInvoice,
  getCompleteInvoiceInfo,
  uniformInvoiceSaga,
} from './uniformInvoice';

// moved setting defaultDatetime to another action:
export const DATE_INIT = 'DATE_INIT';
export const DATE_CHANGE = 'DATE_CHANGE';
export const CONTACT_UPDATE = 'CONTACT_UPDATE';
export const NOTE_UPDATE = 'NOTE_UPDATE';
export const FAVOURITE_DRIVER_TOGGLE = 'FAVOURITE_DRIVER_TOGGLE';
export const PAYMENT_METHOD_TOGGLE = 'PAYMENT_METHOD_TOGGLE';
export const PAYMENT_METHOD_CHANGE = 'PAYMENT_METHOD_CHANGE';
export const PROMO_CODE_APPLY = 'PROMO_CODE_APPLY';
export const PROMO_CODE_UPDATE = 'PROMO_CODE_UPDATE';
export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const CHECKOUT_ERROR_TOGGLE = 'CHECKOUT_ERROR_TOGGLE';
export const CHECKOUT_RESET = 'CHECKOUT_RESET';

export const initDate = defaultDate => ({
  type: DATE_INIT,
  defaultDate,
});

export const changeDate = (date, isDirty = true) => ({
  type: DATE_CHANGE,
  date,
  isDirty,
});

export const updateContact = (contact, isDirty = true) => ({
  type: CONTACT_UPDATE,
  contact,
  isDirty,
});

export const updateNote = text => ({
  type: NOTE_UPDATE,
  text,
});

export const toggleFavDriver = toggle => ({
  type: FAVOURITE_DRIVER_TOGGLE,
  toggle,
});

export const togglePayMethod = toggle => ({
  type: PAYMENT_METHOD_TOGGLE,
  toggle,
});

export const updatePaymentMethod = method => ({
  type: PAYMENT_METHOD_CHANGE,
  method,
});

export const updatePromoCode = code => ({
  type: PROMO_CODE_UPDATE,
  code,
});

export const applyPromoCode = code => ({
  type: PROMO_CODE_APPLY,
  code,
});

export const setCheckoutError = (name, message) => ({
  type: CHECKOUT_ERROR_TOGGLE,
  meta: { name, message },
});

export const resetCheckout = () => ({
  type: CHECKOUT_RESET,
});

export const submitOrder = () => ({
  type: PLACE_ORDER_REQUEST,
});

export const contactInitState = {
  name: '',
  phone: '',
};

export const dirtyInitState = {
  deliveryDatetime: false,
  name: false,
  phone: false,
  payment: false,
};

export const errorInitState = {
  name: '',
  phone: '',
  promoCode: '',
  uniformInvoice: '',
};

export const initState = {
  defaultDatetime: '',
  deliveryDatetime: '',
  contact: contactInitState,
  note: '',
  payment: 'cash',
  canUseWallet: true,
  preferFavorite: false,
  promoCode: '',
  dirty: dirtyInitState,
  error: errorInitState,
};

export const getCheckoutDirty = ({ checkout }) => checkout.dirty;

export const getDefaultDatetime = state => state.checkout.defaultDatetime;

export const getContact = state => state.checkout.contact;

export const getDeliveryDatetime = state => state.checkout.deliveryDatetime;

export const getDriverNote = state => state.checkout.note;

export function getPreferFavorite({ checkout }) {
  return checkout.preferFavorite;
}

export function getPromoCode({ checkout }) {
  return checkout.promoCode;
}

export function getPaymentMethod({ checkout }) {
  return checkout.payment;
}

export function getCheckout({ checkout }) {
  return checkout;
}

export function getDeliveryInfo({ routing }) {
  return routing.deliveryInfo;
}

export const getCheckoutErrors = state => state.checkout.error;

export function contactReducer(state = contactInitState, action) {
  switch (action.type) {
    case CONTACT_UPDATE: {
      return { ...state, ...action.contact };
    }
    default:
      return state;
  }
}

export function dirtyReducer(state = dirtyInitState, action) {
  switch (action.type) {
    case CONTACT_UPDATE: {
      const { isDirty, contact } = action;

      return {
        ...state,
        ...Object.keys(contact).reduce(
          (obj, key) => ({ ...obj, [key]: isDirty }),
          {}
        ),
      };
    }
    case DATE_CHANGE: {
      return { ...state, deliveryDatetime: action.isDirty };
    }
    case PAYMENT_METHOD_CHANGE: {
      return { ...state, payment: action.isDirty };
    }
    default:
      return state;
  }
}

export function errorReducer(state = errorInitState, action) {
  switch (action.type) {
    case FETCH_PRICE_SUCCESS: {
      return {
        ...state,
        promoCode: !action.promo.valid
          ? action.promo.error
          : errorInitState.promoCode,
      };
    }
    case CHECKOUT_ERROR_TOGGLE: {
      return { ...state, [action.meta.name]: action.meta.message };
    }
    default:
      return state;
  }
}

const PAYMENT_METHODS = ['cash', 'wallet'];

export default function reducer(state = initState, action) {
  switch (action.type) {
    case regionAction.CHANGE_LOCATION_REQUEST:
    case CHECKOUT_RESET:
    case REQUEST_LOGOUT:
    case PLACE_ORDER_SUCCESS:
      return { ...initState };
    case FETCH_PRICE_SUCCESS: {
      const { canUseWallet, balance } = action.wallet;
      const { dirty } = state;
      let payment;

      if (!dirty.payment) {
        payment = canUseWallet ? PAYMENT_METHODS[1] : PAYMENT_METHODS[0];
      }

      if (!canUseWallet && payment === PAYMENT_METHODS[1]) {
        payment = PAYMENT_METHODS[0]; // eslint-disable-line prefer-destructuring
      }

      return {
        ...state,
        payment,
        canUseWallet,
        balance,
        error: errorReducer(state.error, action),
        dirty: dirtyReducer(state.dirty, action),
      };
    }
    case PROMO_CODE_UPDATE: {
      return {
        ...state,
        promoCode: action.code,
      };
    }
    case PAYMENT_METHOD_CHANGE: {
      return {
        ...state,
        payment: action.method,
        dirty: dirtyReducer(state.dirty, { ...action, isDirty: true }),
      };
    }
    case DATE_INIT: {
      return {
        ...state,
        defaultDatetime: action.defaultDate,
      };
    }
    case DATE_CHANGE: {
      const { date } = action;
      return {
        ...state,
        deliveryDatetime: date,
        dirty: dirtyReducer(state.dirty, action),
      };
    }
    case NOTE_UPDATE: {
      return { ...state, note: action.text };
    }
    case FAVOURITE_DRIVER_TOGGLE: {
      return { ...state, preferFavorite: action.toggle };
    }
    default:
      return {
        ...state,
        dirty: dirtyReducer(state.dirty, action),
        contact: contactReducer(state.contact, action),
        error: errorReducer(state.error, action),
      };
  }
}

export function constructLatLngStr(waypoints) {
  let latlong = '';
  waypoints.forEach(item => {
    latlong += latlong ? ',' : '';
    latlong += `${item.lat}|${item.lng}`;
  });
  return latlong;
}

export function constructRoutingData(waypoints, deliveryInfo) {
  const latlong = waypoints.map(waypt => `${waypt.lat}|${waypt.lng}`).join(',');
  const addressStr = waypoints.map(waypt => waypt.name); // in array
  const addressDetails = waypoints.map(waypt =>
    deliveryInfo[waypt.id]
      ? {
          block: deliveryInfo[waypt.id].addressDetails,
          floor: '',
          room: '',
        }
      : null
  );
  const contactPersons = waypoints.map(waypt =>
    deliveryInfo[waypt.id]
      ? {
          name: deliveryInfo[waypt.id].name,
          phone: deliveryInfo[waypt.id].phone,
        }
      : null
  );
  // IMPORTANT: mobile api consume `null` string for the stop with no placeId
  const placeId = waypoints.map(waypt => waypt.placeId || 'null').join(',');
  return { latlong, addressStr, addressDetails, contactPersons, placeId };
}

export function constructCheckoutData(checkout) {
  return {
    note: checkout.note,
    vehicle: checkout.note.indexOf('#1234') > -1 ? 'VIP' : '',
    promoCode: !checkout.error.promoCode ? checkout.promoCode : '',
    myFleet: checkout.preferFavorite,
    name: checkout.contact.name.trim(),
    phone: checkout.contact.phone,
    isUsingPrepayment: checkout.payment === 'wallet',
  };
}

export function* constructUniformInvoice() {
  const type = loadSelectedInvoice();
  const info = yield getCompleteInvoiceInfo(type);
  return { key: type, ...info };
}

export function validateInvoiceInfo(uniformInvoice) {
  let allValid = true;
  if (uniformInvoice.key === DUPLICATE) {
    const { valid: emailValid } = validator.email(uniformInvoice.email);
    allValid = emailValid && uniformInvoice.name && uniformInvoice.address;
  }
  if (uniformInvoice.key === TRIPLICATE) {
    const { valid: numberValid } = validator.taxID(uniformInvoice.taxID);
    allValid = allValid && numberValid;
  }
  return allValid;
}

export function* onSubmitOrder() {
  try {
    // data from checkout page:
    const checkout = yield select(getCheckout);
    const checkoutData = constructCheckoutData(checkout);
    // uniform invoice data (TW):
    const country = yield select(getCurrentCountry);
    let uniformInvoice = null;
    if (country.id === 'TW' && !checkoutData.isUsingPrepayment) {
      uniformInvoice = yield constructUniformInvoice();
    }

    const { valid, message } = validator.phone(checkoutData.phone, true);
    if (!checkoutData.name) {
      yield put({ type: PLACE_ORDER_FAILURE });
      yield put(setCheckoutError('name', 'Checkout.error_msg_name_required'));
      return;
    }
    if (!valid) {
      yield put({ type: PLACE_ORDER_FAILURE });
      yield put(setCheckoutError('phone', message));
      return;
    }
    if (uniformInvoice && !validateInvoiceInfo(uniformInvoice)) {
      yield put({ type: PLACE_ORDER_FAILURE });
      yield put(
        setCheckoutError(
          'uniformInvoice',
          'TW_Invoice.error_msg_incomplete_info'
        )
      );
      return;
    }

    // routing info:
    const waypoints = yield select(getFilledWaypts);
    const deliveryInfo = yield select(getDeliveryInfo);
    const routing = constructRoutingData(waypoints, deliveryInfo);
    // selected services:
    const normalReq = yield select(getSelectedService);
    const specialReq = yield select(getSelectedSpecialRequests);
    const services = { normalReq, specialReq };
    // pricing info:
    const price = yield select(getPrice);
    const totalPrice = price.total - price.rewards;

    // timestamp (scheduled order):
    let timestamp = 0;
    if (checkout.dirty.deliveryDatetime) {
      timestamp = moment(checkout.deliveryDatetime).unix();
    }

    yield call(vanRequest, {
      routing,
      services,
      totalPrice,
      checkoutData,
      uniformInvoice,
      timestamp,
    });

    yield put({ type: PLACE_ORDER_SUCCESS });
    yield put(replace('/orders'));
  } catch ({ message }) {
    if (message === 'ERROR_PRICE_UNMATCHED') {
      yield put({ type: PLACE_ORDER_FAILURE });
      yield put(openDialog('PLACE_ORDER_PRICE_UNMATCHED'));
    } else {
      yield put({
        type: PLACE_ORDER_FAILURE,
        meta: {
          type: 'error',
          message,
        },
      });
    }
  }
}

export function* checkoutSaga() {
  yield fork(uniformInvoiceSaga);
  yield takeLatest(PLACE_ORDER_REQUEST, onSubmitOrder);
}
