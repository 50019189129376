/**
 * Handling display for 折 in chinese
 * @param offPercent
 * @param lng
 * @returns String
 */
export default function convertPercentDiscountDisplay(
  offPercent: number,
  lng: string
): string {
  const CHINESE = ['zh_HK', 'zh_TW'];

  // Special handling for Chinese
  if (CHINESE.includes(lng)) {
    const payPercent = 100 - offPercent;

    return String(
      payPercent % 10 === 0 || payPercent < 10
        ? // 40% off => 6折
          payPercent / 10
        : // 35% off => 65折
          payPercent
    );
  }

  // Add % for non Chinese
  return `${offPercent}%`;
}
