import Logger from '@lalamove/web-logger';

const {
  REACT_APP_LOG_API,
  REACT_APP_LOG_KEY,
  REACT_APP_HOST_ENV,
  REACT_APP_VERSION,
} = process.env;

const log = new Logger({
  url: REACT_APP_LOG_API,
  key: REACT_APP_LOG_KEY,
  release: REACT_APP_VERSION,
  locale: 'en', // defaultConfig
  location: 'HK_HKG', // defaultConfig
  environment: REACT_APP_HOST_ENV,
  platform: 'webapp',
  appType: 'user',
});

export default log;
