import styled, { css } from 'styled-components';
import { CloseButton } from 'components/Button';

import { UNDERGROUND, GROUND } from 'styles/zIndex';
import { primaryFonts } from 'styles/fonts';
import { orange, gray, white, red, nobel } from 'styles/colors';

import { ERROR_INVALID_ADDR } from 'store/modules/routing/errorTypes';

export const Input = styled.textarea.attrs(() => ({
  rows: 1,
}))`
  resize: none;
  appearance: none;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 30em;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  font-family: ${primaryFonts};
  line-height: 1.4;
  caret-color: ${orange};
  outline: 0;

  &::placeholder {
    color: ${nobel[500]};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const leftMargin = 28;
const stopBulletSize = 18;

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid ${nobel[100]};
  color: ${gray};
  line-height: 1.4;

  transition: border-bottom 0.2s;

  ${({ error, warning }) =>
    (error || warning) &&
    css`
      /* bg */
      &:after {
        content: '';
        position: absolute;
        z-index: ${UNDERGROUND};
        top: 0;
        right: -16px;
        bottom: 0;
        left: -16px;
      }
    `}

  ${({ warning }) =>
    warning &&
    css`
      /*color: gold;*/
      /* bg */
      &:after {
        background-color: lemonchiffon;
      }
    `}

  ${({ error }) => {
    if (error) {
      switch (error) {
        case ERROR_INVALID_ADDR:
          return css`
            color: ${red};
            &:after {
              background-color: mistyrose;
            }
          `;
        default:
          return css`
            &:after {
              background-color: mistyrose;
            }
          `;
      }
    }
    return '';
  }}

  ${({ shouldShowIndex, indexColor }) =>
    shouldShowIndex &&
    css`
      &:before {
        counter-increment: stop-item;
        content: counter(stop-item);
        position: absolute;
        z-index: ${GROUND + 1};
        top: 50%;
        width: ${stopBulletSize}px;
        height: ${stopBulletSize}px;
        background: ${indexColor};
        box-shadow: 0 0 0 2px #fff;
        border-radius: 50%;
        color: ${white};
        font-size: 0.714em;
        line-height: ${stopBulletSize}px;
        text-align: center;
        transform-origin: center;
        transform: translate(
          ${-leftMargin + 10 - stopBulletSize / 2}px,
          ${-stopBulletSize / 2}px
        );
      }
    `} /* prettier-ignore */
  &:focus-within {
    border-bottom-color: ${orange};
  }
`;

export const Content = styled.div`
  position: relative;
  flex: 1 1 auto;
  min-height: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};
`;

export const BtnRemove = styled(CloseButton)`
  flex: 0 0 auto;
  order: 9; /* make it always the last */
  margin: -7px 0;
`;
