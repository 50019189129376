import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { sessionService } from 'redux-react-session';

import { vanauthlogin } from 'api/mobileAPI';
import { verifyCode, resendVerificationCode } from 'api/authAPI';

// action-types
import {
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  RESEND_SUCCESS,
  RESEND_FAILURE,
  RESEND_LOCKING,
  RESEND_RELEASE,
  LOGIN_SUCCESS,
} from './actions';

// state
export const initState = {
  pin: '',
  isWaiting: false,
};

// reducer
export default function verificationReducer(state = initState, action) {
  switch (action.type) {
    case VERIFY_SUCCESS:
      return initState;
    case RESEND_SUCCESS:
    case RESEND_LOCKING:
      return {
        ...state,
        isWaiting: true,
      };
    case RESEND_RELEASE:
      return {
        ...state,
        isWaiting: false,
      };
    default:
      return state;
  }
}

// side effects
export function* handleSubmitPin({ pin, clientId, secret }) {
  try {
    const response = yield call(verifyCode, pin, clientId, secret);
    const { access_token: accessToken } = response.data;
    yield put({ type: VERIFY_SUCCESS });
    yield put({ type: LOGIN_SUCCESS });
    yield call(vanauthlogin, accessToken, clientId);
    yield call([sessionService, 'saveSession'], response.data.access_token);
    yield call([sessionService, 'saveUser'], response.data);
    yield put(replace('/'));
    return true;
  } catch ({ message }) {
    yield put({
      type: VERIFY_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
    return false;
  }
}

export function* handleResendPin({ secret, lang, blockDuration, method }) {
  try {
    const response = yield call(resendVerificationCode, secret, lang, method);
    yield put({ type: RESEND_SUCCESS, response });
    yield delay(blockDuration);
    yield put({ type: RESEND_RELEASE });
  } catch ({ message }) {
    yield put({
      type: RESEND_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* handleResendLock({ blockDuration }) {
  yield delay(blockDuration);
  yield put({ type: RESEND_RELEASE });
}

export function* verificationSaga() {
  yield takeEvery(RESEND_LOCKING, handleResendLock);
}
