import log from 'api/log';

// common schema:
import patterns from './schemas/common/patterns.schema.json';
// auth-service & mobile-api schemas:
import * as authServiceSchemas from './schemas/authService';
import * as mobileApiSchemas from './schemas/mobileApi';
import * as restApiSchemas from './schemas/restApi';

const Ajv = require('ajv');

const allSchemas = Object.values({
  patterns,
  ...authServiceSchemas,
  ...mobileApiSchemas,
  ...restApiSchemas,
});

const { REACT_APP_DEBUG } = process.env;

class Schema {
  constructor() {
    this.ajv = new Ajv();
    this.ajv.addSchema(allSchemas);
  }

  /**
   * Validates API response data
   * @param {string} endpoint API endpoint
   * @param {object} data API response data
   * @return {object} e.g. { valid: true, errors: null }
   */
  validate(api, endpoint, data) {
    try {
      // endpoint name is used as schema $id:
      const schemaKeyRef = `${endpoint}.schema.json`;
      const valid = this.ajv.validate(schemaKeyRef, data);
      if (!valid) {
        log.info('JSON validation returns invalid', {
          category: 'json-schema',
          endpoint: `${api}${endpoint}`,
          ...(data.footprint && { footprint: data.footprint }),
          response: `JSON: ${JSON.stringify(data)}`, // prevent log service parsing JSON string as object
          schemaError: this.ajv.errors,
        });
      }
      // if (REACT_APP_DEBUG) {
      //   /* eslint-disable no-console */
      //   console.log('\n🔹 ENDPOINT:', endpoint);
      //   console.log('🔹 RESPONSE:', data);
      //   console.log('🔹 VALIDITY:', valid ? '✅' : '❗');
      //   !valid && console.log('🔹   ERRORS:', this.ajv.errors);
      // }
      return {
        valid,
        ...(!valid && { errors: this.ajv.errors }),
      };
    } catch (e) {
      log.error('JSON validation failed with exception', {
        category: 'json-schema',
        endpoint: `${api}${endpoint}`,
        ...(data.footprint && { footprint: data.footprint }),
        response: `JSON: ${JSON.stringify(data)}`, // prevent log service parsing JSON string as object
        exception: e,
      });
      REACT_APP_DEBUG && console.log('🔹 VALIDITY:', `⚠️ ${e}`); // eslint-disable-line no-console
      return { valid: true };
    }
  }

  // basically the same as above but without logging (for test)
  test(endpoint, data) {
    try {
      // endpoint name is used as schema $id:
      const schemaKeyRef = `${endpoint}.schema.json`;
      const valid = this.ajv.validate(schemaKeyRef, data);
      if (!valid) console.log(this.ajv.errors); // eslint-disable-line no-console
      return {
        valid,
        ...(!valid && { errors: this.ajv.errors }),
      };
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
      return { valid: false };
    }
  }
}

export default new Schema();
