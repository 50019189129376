import React from 'react';
import { func } from 'prop-types';
import { Button } from '@lalamove/karang';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SpacedBox } from 'interfaces/global/containers/Settings/style';
import { getUser } from 'interfaces/global/store/modules/auth/selectors';
import { useSelector } from 'react-redux';
import { makeMessageSelector } from 'store/modules/message';
import { createLoadingSelector } from 'store/modules/loading';
import { red } from 'styles/colors';

const PhoneNo = styled.span`
  margin-right: 1rem;
`;

const SendSMS = ({ handleSendSMS }) => {
  const { t } = useTranslation();
  const phoneNo = useSelector(getUser).profile.phone_no;
  const isSending = useSelector(state =>
    createLoadingSelector(['RESEND_PIN'])(state)
  );
  const apiError = useSelector(state =>
    makeMessageSelector(['RESEND_PIN'])(state)
  );

  return (
    <>
      <SpacedBox>
        <PhoneNo>{`${t('Settings.send_sms')} ${phoneNo}`}</PhoneNo>
        <Button
          variant="primary"
          solid
          onClick={handleSendSMS}
          isLoading={isSending}
          disabled={isSending}
        >
          {t('Settings.send')}
        </Button>
      </SpacedBox>
      {apiError && <p style={{ color: red }}> {apiError.message}</p>}
    </>
  );
};

SendSMS.propTypes = {
  handleSendSMS: func.isRequired,
};

export default SendSMS;
