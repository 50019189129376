import React, { Fragment } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { mineShaft, nobel, primary, white } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { WaypointShape, DeliveryInfoShape } from 'views/Records/propTypes';
import { deliveryStatus, noop } from 'utils/helpers';
import ParcelList from '../ParcelList';
import POD from '../POD';

const {
  PICK_UP_DONE,
  PICK_UP_FAILED,
  DROP_OFF_DONE,
  DROP_OFF_FAILED,
} = deliveryStatus;

const parcelSequence = {
  [DROP_OFF_FAILED]: 0,
  [PICK_UP_FAILED]: 1,
  [DROP_OFF_DONE]: 2,
  [PICK_UP_DONE]: 3,
};

const List = styled.ul`
  position: relative;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  position: relative;
  padding-left: 28px;

  ${({ last }) =>
    !last &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 1.5em;
        bottom: -1.5em;
        left: 9px;
        border-left: 2px dotted ${nobel['500']};
      }
    `};
`;

const ItemContainer = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid ${nobel['200']};
  line-height: 1.4;

  &:before {
    content: '${({ index }) => (index === 0 ? '•' : index)}';
    position: absolute;
    top: 24px;
    width: 20px;
    height: 20px;
    background-color: ${({ index }) =>
      index === 0 ? primary.main : nobel['700']};
    color: ${white};
    font-size: 0.75em;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 0 2px ${white};
    transform: translate(-28px, -10px);
  }
`;

const StopInfo = styled.div`
  display: flex;
  color: ${mineShaft['700']};
  font-size: ${fontSize.small};

  > div:first-child {
    flex: 1;
  }
  > div:not(:first-child) {
    text-align: right;
  }
`;

const Row = styled.div`
  margin-bottom: 4px;
`;

const Name = styled.div`
  color: ${mineShaft['900']};
  font-size: ${fontSize.regular};
`;

// TODO: move some logic to reducer/selector when integrate with client-orders API
const renderAddress = (waypoint, deliveryInfo, index) => {
  const addressDetails =
    deliveryInfo && deliveryInfo[index]
      ? deliveryInfo[index].addressDetails
      : '';
  const { addressLanguage } = waypoint;
  if (addressLanguage && addressLanguage.startsWith('zh')) {
    return `${waypoint.name}${addressDetails}`;
  }
  return `${addressDetails}, ${waypoint.name}`;
};

const renderDatetime = (
  dateTime,
  dateFormat = 'DD MMM',
  timeFormat = 'H:mm'
) => (
  <div>
    <Row>{moment(dateTime).format(dateFormat)}</Row>
    <Row>{moment(dateTime).format(timeFormat)}</Row>
  </div>
);

const DeliveryRoute = ({
  waypoints,
  deliveryInfo: infos,
  deliveries,
  showDeliveryStatus,
  showPod,
  t,
}) => (
  <List>
    {waypoints.map((waypoint, i) => (
      <ListItem key={uuid()} last={i === waypoints.length - 1}>
        <ItemContainer index={i}>
          <StopInfo>
            <div>
              {infos && infos[i] && (
                <Fragment>
                  {infos[i].name && (
                    <Row>
                      <Name>{infos[i].name}</Name>
                    </Row>
                  )}
                  {infos[i].phone && <Row>{infos[i].phone}</Row>}
                </Fragment>
              )}
              {<Row>{renderAddress(waypoint, infos, i)}</Row>}
            </div>
            {showDeliveryStatus && deliveries && (
              <Fragment>
                {i === 0 &&
                  (deliveries[i].deliveryStatus === PICK_UP_DONE ||
                    deliveries[i].deliveryStatus === DROP_OFF_DONE) &&
                  renderDatetime(
                    deliveries[i].doneAt,
                    t('DateTime.date_month'),
                    t('DateTime.time_24')
                  )}
                {i > 0 &&
                  deliveries[i].deliveryStatus === DROP_OFF_DONE &&
                  renderDatetime(
                    deliveries[i].doneAt,
                    t('DateTime.date_month'),
                    t('DateTime.time_24')
                  )}
              </Fragment>
            )}
          </StopInfo>
          {showDeliveryStatus && deliveries && i > 0 && (
            <Fragment>
              {Object.entries(deliveries[i].parcels)
                .sort((a, b) => parcelSequence[a[0]] - parcelSequence[b[0]])
                .map(([key, value]) => (
                  <ParcelList key={key} status={key} list={value} />
                ))}
              {deliveries[i].isPodRequired && (
                <POD
                  deliveryId={deliveries[i].deliveryId}
                  deliveryStatus={deliveries[i].deliveryStatus}
                  receiverName={deliveries[i].receiverName}
                  signature={deliveries[i].signature}
                  showSignature={showPod}
                />
              )}
            </Fragment>
          )}
        </ItemContainer>
      </ListItem>
    ))}
  </List>
);

DeliveryRoute.propTypes = {
  waypoints: arrayOf(WaypointShape).isRequired,
  deliveryInfo: arrayOf(DeliveryInfoShape),
  deliveries: arrayOf(shape({})), // TODO..
  showDeliveryStatus: bool,
  showPod: bool,
  t: noop,
};

DeliveryRoute.defaultProps = {
  deliveryInfo: null,
  deliveries: null,
  showDeliveryStatus: false,
  showPod: false,
  t: func,
};

export default withTranslation()(DeliveryRoute);
