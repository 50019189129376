export function trackingSegmentRegistration(userId, properties = {}) {
  if (!window.analytics) return null;

  return window.analytics.identify(userId, {
    ...properties,
    llm_source: 'webapp',
    trigger_time: new Date().toISOString(),
  });
}

export default trackingSegmentRegistration;
