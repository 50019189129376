import styled from 'styled-components';
import { mineShaft } from 'styles/colors';
import { TabBar } from '@lalamove/karang';
import { fadeTransition } from 'styles/transitions';

// space at bottom for not overlay with genesys button
export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 73rem;
  max-width: 110rem;
  padding-bottom: 5em;
  margin-right: auto;
  margin-left: auto;

  ${fadeTransition}
`;

export const TabGroup = styled(TabBar)`
  margin: 0 auto 0 2em;
`;

export const SearchForm = styled.form`
  margin-left: 2em;

  @media (min-width: 110rem) {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const CouponsTitle = styled.h4`
  margin: 32px 0 14px 0;
  color: ${mineShaft[700]};
  font-size: 12px;

  & + div {
    margin-top: 14px;
  }
`;
