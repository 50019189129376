import styled from 'styled-components';
import { darkGray } from 'styles/colors';

/* eslint-disable import/prefer-default-export */
export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid ${darkGray};
  margin-bottom: 2rem;
`;
