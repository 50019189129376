import { REQUEST_LOGOUT } from 'store/modules/auth/actions';

// action
export const DIALOG_OPEN = 'DIALOG_OPEN';
export const DIALOG_CLOSE = 'DIALOG_CLOSE';
export const DIALOG_RESET = 'DIALOG_RESET';
export const PANEL_OPEN = 'PANEL_OPEN';
export const PANEL_CLOSE = 'PANEL_CLOSE';
export const SIDE_MENU_OPEN = 'SIDE_MENU_OPEN';
export const SIDE_MENU_CLOSE = 'SIDE_MENU_CLOSE';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export const openDialog = (id, props = {}) => ({
  type: DIALOG_OPEN,
  id,
  props,
});

export const closeDialog = () => ({
  type: DIALOG_CLOSE,
});

export const resetDialog = () => ({
  type: DIALOG_RESET,
});

export const openPanel = (id, props = {}) => ({
  type: PANEL_OPEN,
  id,
  props,
});

export const closePanel = () => ({
  type: PANEL_CLOSE,
});

export const openSideMenu = () => ({
  type: SIDE_MENU_OPEN,
});

export const closeSideMenu = () => ({
  type: SIDE_MENU_CLOSE,
});

export const openModal = (id, props = {}) => ({
  type: MODAL_OPEN,
  id,
  props,
});

export const closeModal = () => ({
  type: MODAL_CLOSE,
});

// state
export const initState = {
  dialog: {
    id: '',
    props: {},
    isOpen: false,
  },
  panel: {
    id: '',
    props: {},
    isOpen: false,
  },
  sideMenu: {
    isOpen: false,
  },
  modal: {
    id: '',
    props: {},
    isOpen: false,
  },
};

// selectors
export const getDialog = ({ ui }) => ui.dialog;
export const getPanel = ({ ui }) => ui.panel;
export const isPanelOpen = id => ({ ui }) =>
  ui.panel.isOpen && ui.panel.id === id;
export const isModalOpen = id => ({ ui }) =>
  ui.modal.isOpen && ui.modal.id === id;
export const isSideMenuOpen = ({ ui }) => ui.sideMenu.isOpen;
export const getModal = ({ ui }) => ui.modal;

// reducers
export default function reducer(state = initState, action) {
  switch (action.type) {
    case DIALOG_OPEN: {
      const { id, props } = action;
      return {
        ...state,
        dialog: {
          id,
          props,
          isOpen: true,
        },
      };
    }
    case DIALOG_CLOSE: {
      return {
        ...state,
        dialog: {
          ...state.dialog,
          isOpen: false,
        },
      };
    }
    case DIALOG_RESET: {
      return {
        ...state,
        dialog: initState.dialog,
      };
    }
    case PANEL_OPEN: {
      const { id, props } = action;
      return {
        ...state,
        panel: {
          id,
          props,
          isOpen: true,
        },
      };
    }
    case PANEL_CLOSE: {
      return {
        ...state,
        panel: {
          ...state.panel,
          isOpen: false,
        },
      };
    }
    case SIDE_MENU_OPEN:
      return {
        ...state,
        sideMenu: {
          ...state.sideMenu,
          isOpen: true,
        },
      };
    case SIDE_MENU_CLOSE:
      return {
        ...state,
        sideMenu: {
          ...state.sideMenu,
          isOpen: false,
        },
      };
    case MODAL_OPEN: {
      const { id, props } = action;
      return {
        ...state,
        modal: {
          id,
          props,
          isOpen: true,
        },
      };
    }
    case MODAL_CLOSE: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: false,
        },
      };
    }
    case REQUEST_LOGOUT:
      return initState;
    default:
      return state;
  }
}
