import React, { Component, Fragment } from 'react';
import Big from 'big.js';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { arrayOf, bool, func, number, string, instanceOf } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Heading, Button } from '@lalamove/karang';
import {
  add as AddIcon,
  edit as EditIcon,
} from '@lalamove/karang/dist/components/Icon/icons';
import { push } from 'connected-react-router';
import formatter from 'utils/formatter';
import { cloneOrder } from 'interfaces/global/store/modules/records/actions';
import { setCheckoutStep } from 'interfaces/global/store/modules/checkout/actions';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { getCurrentCity } from 'store/modules/region/selectors';
import { getPriorityFee } from 'interfaces/global/store/modules/records/selectors';
import {
  RouteShape,
  PriceBreakDownShape,
} from 'interfaces/global/views/Records/propTypes';
import SliderPanelFooter from 'components/SliderPanelFooter';
import { black, silver } from 'styles/colors';
import { MODAL } from 'styles/zIndex';
import { noop, globalOrderStatusMap as statusMap } from 'utils/helpers';
import {
  CheckoutSteps,
  PaymentMethods,
} from 'interfaces/global/store/modules/checkout/types';
import { withResponsiveMedia } from 'components/MediaQuery';
import { isSafari } from 'utils/userAgentHelper';
import Route from './components/Route';
import DeliveryRoute from './components/DeliveryRoute';
import Price from './components/Price';

// const bull = '\u2022';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: auto;
  color: ${black};
`;

const Overlay = styled.div`
  position: absolute;
  z-index: ${MODAL};
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
`;

const Content = styled.div`
  flex: 1 1 0;
  height: auto;
  padding: ${isSafari(navigator.userAgent)
    ? `0 1.25em 100px 2.25em`
    : `0 1.25em 0 2.25em`};
`;

const Row = styled.div`
  padding: 0.75em 0;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeadingButton = styled(Button)`
  padding: 0.25em;

  & > span {
    margin-left: 4px;
  }

  :disabled {
    color: ${silver};
  }
`;

// const Image = styled.img`
//   max-width: 88px;
//   max-height: 50px;
//   padding: 1em 0.5em;
//   border: 1px solid ${nobel['200']};
//   margin: 0.5em 0;
// `;

export class OrderDetails extends Component {
  static defaultProps = {
    status: null,
    editable: false,
    edited: false,
    noteToDriver: '',
    paymentMethodId: PaymentMethods.CASH.id,
    route: null,
    price: null,
    specialRequests: [],
    showOverlay: false,
    onClone: noop,
    setCheckoutStep: noop,
    onAddPriorityFee: noop,
    onCancel: noop,
    onClose: noop,
    t: noop,
    orderEditEnabled: false,
    historyPush: noop,
    collectionFen: '',
    podEnabled: false,
    showDeliveryStatus: false,
    currentNode: 0,
    maxTipsAllowed: Big(0),
    tipOptions: [],
    track: noop,
  };

  static propTypes = {
    id: string.isRequired,
    refId: string.isRequired,
    status: number,
    editable: bool,
    edited: bool,
    noteToDriver: string,
    paymentMethodId: number,
    route: RouteShape,
    price: PriceBreakDownShape,
    specialRequests: arrayOf(string),
    showOverlay: bool,
    onClone: func,
    setCheckoutStep: func,
    onAddPriorityFee: func,
    onCancel: func,
    onClose: func,
    t: func,
    orderEditEnabled: bool,
    historyPush: func,
    collectionFen: string,
    podEnabled: bool,
    showDeliveryStatus: bool,
    currentNode: number,
    maxTipsAllowed: instanceOf(Big),
    tipOptions: arrayOf(instanceOf(Big)),
    isMobile: bool.isRequired,
    track: func,
  };

  onCloneClick = () => {
    // eslint-disable-next-line no-shadow
    const { id, onClone, setCheckoutStep, track, refId } = this.props;
    onClone(id);
    setCheckoutStep(CheckoutSteps.SELECT_VEHICLE);

    track('order_cloned', {
      order_id: refId,
      source: 'order detail',
    });
  };

  onEditClick = () => {
    const { historyPush } = this.props;
    historyPush({
      pathname: `/orders/${this.props.id}/edit`,
      search: new URLSearchParams({
        referer: `/orders/${this.props.id}${window.location.search}`,
      }).toString(),
    });
  };

  renderEdit = () => {
    const {
      t,
      status,
      editable,
      edited,
      orderEditEnabled,
      showDeliveryStatus,
    } = this.props;

    // conditional logics:
    const isShown =
      ((status === statusMap.ON_GOING.id ||
        status === statusMap.LOADING.id ||
        status === statusMap.UNLOADING.id) &&
        editable) ||
      edited;
    const isDisabled = !editable && edited;

    if (!orderEditEnabled || !isShown) return null;
    return (
      <span
        data-tip={
          showDeliveryStatus ? t('Records.button_tooltip_contact_cs') : ''
        }
        data-place="top"
        data-effect="solid"
        data-for="global"
      >
        <HeadingButton
          size="small"
          variant="link"
          icon={<EditIcon size={12} />}
          iconPosition="before"
          disabled={isDisabled || showDeliveryStatus}
          onClick={this.onEditClick}
        >
          {t(`RecordPanel.button_edit_order${isDisabled ? '_edited' : ''}`)}
        </HeadingButton>
      </span>
    );
  };

  renderFooter = () => {
    const { t, status, onCancel, onClose, isMobile } = this.props;

    const {
      COMPLETED,
      CANCELLED,
      DRIVER_REJECTED,
      ORDER_TIMEOUT,
      MATCHING,
      ON_GOING,
      LOADING,
      UNLOADING,
    } = statusMap;

    const cloneOrderOptions = {
      primaryAction: this.onCloneClick,
      primaryButtonText: t('Records.option_clone_order'),
    };
    const cancelOption = {
      secondaryAction: onCancel,
      secondaryButtonText: t('RecordPanel.button_cancel_order'),
    };

    const shouldRenderCloneOrderButton = [
      COMPLETED.id,
      CANCELLED.id,
      DRIVER_REJECTED.id,
      ORDER_TIMEOUT.id,
    ].includes(status);

    const shouldRenderCancelOrderButton = [
      MATCHING.id,
      ON_GOING.id,
      LOADING.id,
      UNLOADING.id,
    ].includes(status);

    const shouldRenderOnMobile =
      shouldRenderCloneOrderButton || shouldRenderCancelOrderButton;
    const shouldRenderFooter = !isMobile || shouldRenderOnMobile;

    return (
      shouldRenderFooter && (
        <SliderPanelFooter
          {...(shouldRenderCloneOrderButton && cloneOrderOptions)}
          {...(shouldRenderCancelOrderButton && cancelOption)}
          closeAction={isMobile ? undefined : onClose}
          closeButtonText={
            isMobile ? undefined : t('RecordPanel.button_close_panel')
          }
          sticky={!shouldRenderCancelOrderButton}
        />
      )
    );
  };

  render() {
    const {
      status,
      noteToDriver,
      paymentMethodId,
      route: { waypoints, deliveryInfo },
      price,
      specialRequests,
      collectionFen,
      showOverlay,
      podEnabled,
      currentNode,
      onAddPriorityFee,
      t,
      maxTipsAllowed,
      tipOptions,
      isMobile,
    } = this.props;

    let additionalRequestString = '';

    if (specialRequests.length) {
      additionalRequestString += specialRequests.join(', ');
    }

    if (collectionFen) {
      additionalRequestString += ` (COD Amount ${collectionFen})`;
    }

    const RouteComp = podEnabled ? DeliveryRoute : Route;

    return (
      <Container>
        {showOverlay && <Overlay />}
        <Content isSafari={isSafari()}>
          <Row>
            <HeadingWrapper>
              <Heading htmlTag="h3">{t('RecordPanel.heading_route')}</Heading>
              {this.renderEdit()}
            </HeadingWrapper>
            <RouteComp
              waypoints={waypoints}
              deliveryInfo={deliveryInfo}
              status={status}
              currentNode={currentNode}
            />
          </Row>
          {additionalRequestString && (
            <Row>
              <Heading htmlTag="h3">
                {t('RecordPanel.heading_additional_services')}
              </Heading>
              <div>{additionalRequestString}</div>
            </Row>
          )}
          {noteToDriver && (
            <Row>
              <Heading htmlTag="h3">
                {t('RecordPanel.heading_notes_to_driver')}
              </Heading>
              <div>
                {noteToDriver.split('\n').map(text => (
                  <Fragment key={text}>
                    {text}
                    <br />
                  </Fragment>
                ))}
              </div>
            </Row>
          )}
          <Row>
            <Price
              price={price}
              paymentMethodId={paymentMethodId}
              hideZeroItems
            />
          </Row>
          {!isMobile &&
            status === statusMap.MATCHING.id &&
            tipOptions.length > 0 &&
            maxTipsAllowed.gt(Big(0)) && (
              <Row>
                <Heading htmlTag="h3">
                  {t('RecordPanel.heading_priority_fee')}
                </Heading>
                <div>
                  {t('RecordPanel.msg_add_priority_fee_global', {
                    maxAmount: formatter.currency(maxTipsAllowed),
                  })}
                </div>
                <div style={{ marginTop: '1em', textAlign: 'right' }}>
                  <Button onClick={onAddPriorityFee} icon={<AddIcon />}>
                    {t('RecordPanel.button_add_priority_fee')}
                  </Button>
                </div>
              </Row>
            )}
        </Content>
        {this.renderFooter()}
      </Container>
    );
  }
}

const mapState = state => ({
  orderEditEnabled: getCurrentCity(state).orderEditEnabled,
  maxTipsAllowed: getPriorityFee(state).max,
  tipOptions: getPriorityFee(state).options,
});

export default compose(
  withTranslation(),
  withResponsiveMedia,
  connect(mapState, {
    onClone: cloneOrder,
    historyPush: push,
    setCheckoutStep,
    track,
  })
)(OrderDetails);
