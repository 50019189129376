import {
  getCurrentLocation,
  getRestApiDomain,
} from 'store/modules/region/selectors';
import { getUser } from 'store/modules/auth/selectors';

import Fetcher from './Fetcher';

const { REACT_APP_REST_API, NODE_ENV } = process.env;

export const REST_API_VER = 'v3';

export default class RestAPIFetcher extends Fetcher {
  static uniqueName = 'RestAPIFetcher';

  prepareBaseUrl = state => {
    let restUrl = getRestApiDomain(state)
      ? `//${getRestApiDomain(state)}`
      : REACT_APP_REST_API;
    if (NODE_ENV === 'development') {
      restUrl = REACT_APP_REST_API;
    }
    return `${restUrl}/${REST_API_VER}`;
  };

  prepareHeaders = (method, state, options) => {
    const user = getUser(state) || {};
    const { access_token: accessToken = '' } = user;
    let headers = {
      Authorization: `Bearer user.${accessToken}`,
      'X-LLM-LOCATION': getCurrentLocation(state),
    };
    if (['POST', 'PATCH'].includes(method)) {
      headers = {
        ...headers,
        'Content-Type': 'application/vnd.api+json',
      };
    }
    return headers;
  };

  prepareQuery = params => params;

  prepareBody = params => JSON.stringify({ data: params });
}
