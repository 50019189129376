export const getCheckoutDirty = ({ checkout }) => checkout.dirty;
export const getDefaultDatetime = state => state.checkout.defaultDatetime;
export const getContact = state => state.checkout.contact;
export const getDeliveryDatetime = state => state.checkout.deliveryDatetime;
export const getDriverNote = state => state.checkout.note;
export const getHasFavDriver = ({ checkout }) => checkout.hasFavDriver;
export const getPreferFavorite = ({ checkout }) => checkout.preferFavorite;
export const getCheckoutStep = ({ checkout }) => checkout.step;
export const getSelectedPaymentMethodId = ({ checkout }) =>
  checkout.selectedPaymentMethodId;
export const getCheckout = ({ checkout }) => checkout;
export const getDeliveryInfo = ({ routing }) => routing.deliveryInfo;
export const getCheckoutErrors = state => state.checkout.error;
export const getOnlinePaymentInfo = state => state.checkout.onlinePaymentInfo;
export const getIsFirstTimePlacingOrder = state =>
  state.checkout.firstTimePlacingOrder;
