import React from 'react';
import { useDispatch, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { removeBusinessProfile } from 'interfaces/global/store/modules/auth/actions';

import { closeDialog } from 'store/modules/ui';

import Dialog from 'components/Dialog';
import { noop } from 'utils/helpersJS';

const BusinessRoleRemovedDialog: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClose = noop;

  const onClickSwitch = () => {
    batch(() => {
      dispatch(removeBusinessProfile());
      dispatch(closeDialog());
    });
  };

  return (
    <Dialog
      shouldCloseOnOverlayClick={false}
      title={t('Profile.dialog_title_business_role_removed')}
      subtitle={t('Profile.dialog_text_business_role_removed')}
      primaryButtonText={t('Profile.dialog_button_business_role_removed_ok')}
      primaryAction={onClickSwitch}
      onRequestClose={onClose}
      {...props}
    />
  );
};

export default BusinessRoleRemovedDialog;
