/* eslint-disable class-methods-use-this */
import {
  getCurrentLocation,
  getCurrentCountry,
  getCurrentCountryCode,
  getCurrentCity,
  getCurrentLocale,
  getAuthApiDomain,
  getMobileApiDomain,
  getRestApiDomain,
} from 'store/modules/region/selectors';
import { getUser } from 'store/modules/auth/selectors';
import log from 'api/log';
import FetcherLockService from 'api/FetcherLockService';

const { REACT_APP_DEBUG, REACT_APP_REGION_SEA } = process.env;

// interface State {
//   accessToken: string;
//   clientId: string;
//   country: string;
//   city: string;
//   locale: string; // LLM Upper
// }
export default class Fetcher {
  store;

  constructor(store) {
    this.store = store;
  }

  async getRootState() {
    const state = await this.store.getState();
    return state;
  }

  // TODO: shift all getState usage to getRootState
  async getState() {
    const state = await this.store.getState();
    const user = getUser(state);
    const countryCode = getCurrentCountryCode(state);
    const country = getCurrentCountry(state);
    const location = getCurrentLocation(state);
    const city = getCurrentCity(state);
    const {
      access_token: accessToken = '',
      client_id: clientId = '',
      is_ep: isEnterprise,
      profile_type: profileType,
    } = user || {};
    const config = {
      authApiDomain: getAuthApiDomain(state),
      mobileApiDomain: getMobileApiDomain(state),
      restApiDomain: getRestApiDomain(state),
    };

    return {
      accessToken,
      clientId,
      region: REACT_APP_REGION_SEA,
      country: countryCode,
      countryId: country.countryId,
      city: location,
      cityId: city && city.cityId, // removed city validity check may return undefined city
      config,
      locale: getCurrentLocale(state),
      isEnterprise,
      profileType,
      cityZone: city && city.cityZone,
      ...(country?.defaultTimezone && {
        timezone: country.defaultTimezone,
      }),
    };
  }

  prepareBaseUrl = (state = {}, options = {}) => '';

  // string, State, { location: string }
  prepareHeaders = (method, state, options) => ({
    'X-LLM-LOCATION': getCurrentLocation(state),
    ...options.headers,
  });

  prepareQuery = (params = {}) => params;

  prepareBody = (params = {}) => params;

  validate = (endpoint, response) => {};

  // string, string, Map<any>, { location: string }
  async request(method, endpoint, params = {}, options = {}) {
    // TODO: this should be endpoint specific whenever possible
    if (!options.skipStartUpLock)
      await FetcherLockService.applicationStartUpMutex.release();
    const state = await this.getRootState();
    try {
      // General: if request require authentication, should wait for auth session to refresh
      // refresh = login to the same country as application
      const [pureEndpoint, endpointQueryString] = endpoint.split('?');
      const reqParams = new URLSearchParams(
        endpointQueryString ? `?${endpointQueryString}` : undefined
      );
      Object.entries(this.prepareQuery(params)).forEach(([key, value]) => {
        // only support one layer primitive type array
        if (Array.isArray(value)) {
          return value.forEach(v => reqParams.append(`${key}[]`, v));
        }
        return reqParams.append(key, value);
      });
      const path = `${this.prepareBaseUrl(state, options)}/${pureEndpoint}`;
      const queryString = reqParams.toString();
      let url = path;
      if (method === 'GET') {
        url += queryString ? `?${queryString}` : '';
      }
      if (method === 'POST') {
        url += endpointQueryString ? `?${endpointQueryString}` : '';
      }

      let fetchOption = {
        headers: this.prepareHeaders(method, state, options),
        ...options.fetchOption,
      };
      if (['POST', 'PATCH'].includes(method)) {
        fetchOption = {
          ...fetchOption,
          method,
          body: this.prepareBody(params),
        };
      }
      const response = await fetch(url, fetchOption);
      // EXTEND: not all response are json
      const json = await response.json();

      this.validate(pureEndpoint, json);

      if (!response.ok) {
        log.error('API Response error', {
          category: 'backend',
          endpoint: path,
          response: `JSON: ${JSON.stringify(json)}`, // prevent log service parsing JSON string as object
          status_code: response.status,
          ...(method === 'GET' && {
            query_string: queryString.replace(
              /access_token=([^&]*)/g,
              'access_token=****'
            ),
          }),
          ...(json.footprint && { footprint: json.footprint }),
        });
      }
      return json;
    } catch (e) {
      REACT_APP_DEBUG && console.error(e); // eslint-disable-line no-console
      return Promise.reject(e);
    }
  }

  async get(endpoint, params = {}, options = {}) {
    return this.request('GET', endpoint, params, options);
  }

  async post(endpoint, params = {}, options = {}) {
    return this.request('POST', endpoint, params, options);
  }

  async patch(endpoint, params = {}, options = {}) {
    return this.request('PATCH', endpoint, params, options);
  }

  async delete() {
    throw new Error('delete method not implement yet');
  }
}
