import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SelectModal, {
  Value as SelectModalOption,
} from 'components/SelectModal';
import {
  getCountries,
  getCityDict,
  getCurrentCity,
  getCurrentCityNameTrans,
  getCurrentLocale,
} from 'store/modules/region/selectors';
import { changeLocation } from 'store/modules/region/actions';
import { matchLLMLocation } from 'utils/location';
import citySelectorOpen from 'assets/svg/city-selector-open.svg';
import createCountryPickerOptions from 'utils/createCountryPickerOptions';
import { Icon } from './styles';
import { MenuItem } from '../SideMenu/styles';
import { Region, Country, City } from './types';

export const REOPEN_MODAL_DELAY = 0;
const COUNTRY_ID_LENGTH = 2;

interface Props {
  onChangeLocation: () => void;
}

const getOptionId = (countries: Country[], currentCity: City) => {
  const currentCountry = countries.find(
    country => country.id === currentCity.id.substr(0, COUNTRY_ID_LENGTH)
  );

  return currentCountry?.cities.length === 1
    ? currentCountry?.cities[0]
    : currentCountry?.id;
};

const findOption = (options: SelectModalOption[], value?: string) =>
  options.find(option => option.value === value) || options[0];

const CitySelector: React.FC<Props> = ({ onChangeLocation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { countries, currentCity, cityDict, locale } = useSelector(
    state =>
      ({
        countries: getCountries(state),
        currentCity: getCurrentCity(state),
        cityDict: getCityDict(state),
        locale: getCurrentLocale(state),
      } as Region)
  );

  const [options, setOptions] = useState<SelectModalOption[]>(() =>
    createCountryPickerOptions(countries, cityDict, locale)
  );
  const [selectOption, setSelectOption] = useState<SelectModalOption>(() =>
    findOption(options, getOptionId(countries, currentCity))
  );
  const [isOpen, setOpen] = useState(false);
  const [isSelected, setSelected] = useState(false);

  const openSelector = () => setOpen(true);
  const closeSelector = () => setOpen(false);

  const handleLocationChange = (location: string) => {
    const matched = matchLLMLocation(location);
    if (matched) {
      const [country, code] = matched;
      if (code) {
        dispatch(changeLocation({ location, locale } as any));
      } else {
        dispatch(changeLocation({ country, locale } as any));
      }
    }
  };

  const onChange = (value: SelectModalOption) => {
    setSelected(true);
    setSelectOption(value);
    closeSelector();
  };

  const reset = () => {
    const countriesOptions = createCountryPickerOptions(
      countries,
      cityDict,
      locale
    );
    setOptions(countriesOptions);
    setSelectOption(
      findOption(countriesOptions, getOptionId(countries, currentCity))
    );
  };

  const onAfterClose = () => {
    if (isSelected) {
      // value selected, reset isSelected for next selection
      setSelected(false);

      if (selectOption.options) {
        // there is sub-options for this selection, update options and prepare open modal again
        setOptions(selectOption.options);
        setSelectOption(findOption(selectOption.options, currentCity.id));
        setTimeout(() => {
          openSelector();
        }, REOPEN_MODAL_DELAY);
      } else if (selectOption.value !== currentCity.id) {
        // only change location if selection is different from current location
        onChangeLocation();
        handleLocationChange(selectOption.value);
      } else {
        // user selected current location, no need to change location, just reset all values
        reset();
      }
    } else {
      // modal dismissed, reset all values
      reset();
    }
  };

  return (
    <>
      <SelectModal
        name="citySelector"
        isOpen={isOpen}
        onInput={onChange}
        options={options}
        value={selectOption}
        onRequestClose={closeSelector}
        onAfterClose={onAfterClose}
      />
      <MenuItem onClick={openSelector}>
        {t('Settings.city_selector_label', {
          location: getCurrentCityNameTrans(currentCity, locale, countries),
        })}
        <Icon src={citySelectorOpen} alt="" />
      </MenuItem>
    </>
  );
};

export default CitySelector;
