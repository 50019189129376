import { DRAFT_ORDER_ID } from 'interfaces/global/config';
import { getOrder } from '../records/selectors';
import { VEHICLE_STD_PREFIX } from './helpers';

export const getServiceNames = state => {
  const svc = state.services;
  const serviceNames = {};
  const keys = Object.keys(svc.services);
  if (keys.length > 0) {
    keys.forEach(key => {
      serviceNames[key] = svc.services[key].name;
    });
  }
  return serviceNames;
};

export const getServiceName = (state, serviceTypeId) => {
  const service = state.services.services[serviceTypeId];
  return service ? service.name : '';
};

export const getServiceIcon = (state, serviceTypeId, type) => {
  const service = state.services.services[serviceTypeId];
  return service ? service.imgUri[type] : '';
};

export const getServiceIconByOrderId = (state, serviceTypeId, type) => {
  const service = Object.values(state.services.services).find(
    nextService => nextService.name === serviceTypeId
  );
  return service?.imgUri[type];
};

export const getServices = state => {
  const svc = state.services;
  return svc.servicesOrder.map(service => svc.services[service]);
};

export const getSelectedService = state => {
  const { services, servicesOrder, selectedService } = state.services;
  return services[servicesOrder[selectedService]];
};

export const getServiceIdByOrderId = (state, orderId) => {
  if (orderId === DRAFT_ORDER_ID) {
    return getSelectedService(state).fullId;
  }
  const order = getOrder(state, orderId);
  return `SERVICE_${order.serviceTypeId}`;
};

export const getSelectedServiceAndSubServiceIds = state => {
  const selectedService = getSelectedService(state);
  let serviceId = '';
  let subServiceId = '';
  if (selectedService) {
    const { parentTrimId, trimId } = selectedService;
    serviceId = parentTrimId;
    subServiceId = parentTrimId !== trimId ? trimId : '';
  }
  return { serviceId, subServiceId };
};

export const getSelectedServiceVehicleStd = state => {
  const specialRequestSTD = state.services.selectedSpecialRequests.find(
    value => value.indexOf(VEHICLE_STD_PREFIX) > -1
  );

  if (specialRequestSTD) {
    const subSpecReqId = state.services.selectedSubRequests[specialRequestSTD];
    return getSubSpecialRequest(state, subSpecReqId);
  }

  return undefined;
};

export const getSelectedNonVehicleStdSpecialRequests = state =>
  state.services.selectedSpecialRequests
    .filter(value => !value.startsWith(VEHICLE_STD_PREFIX))
    .map(specialRequest =>
      specialRequest.slice(specialRequest.indexOf('-') + 1)
    );

export const getSelectedSubRequestIdByRequestId = (state, requestId) => {
  const subRequestId = state.services.selectedSubRequests[requestId];
  return subRequestId ? getSubSpecialRequest(state, subRequestId).trimId : '';
};

export const getSelectedSubSpecialRequests = state => {
  const selectedSubSpecialRequests = {};
  for (const [requestId, subRequestId] of Object.entries(
    state.services.selectedSubRequests
  )) {
    selectedSubSpecialRequests[requestId] =
      state.services.subSpecialRequests[subRequestId];
  }
  return selectedSubSpecialRequests;
};

export const getSpecialRequest = (state, id) =>
  state.services.specialRequests[id];

export const getSpecialRequests = (state, serviceId) => {
  const svc = state.services;
  return svc.services[serviceId] && svc.services[serviceId].specialRequests
    ? svc.services[serviceId].specialRequests.map(id => svc.specialRequests[id])
    : [];
};

export const getSubSpecialRequest = (state, id) =>
  state.services.subSpecialRequests[id];

export const getSubSpecialRequests = (state, specialRequestId) => {
  const svc = state.services;
  return svc.specialRequests[specialRequestId] &&
    svc.specialRequests[specialRequestId].subSpecialRequests
    ? svc.specialRequests[specialRequestId].subSpecialRequests.map(
        id => svc.subSpecialRequests[id]
      )
    : [];
};

export const getRevision = (state, id) => state.services.revision;

export function getAllowRequireProofOfDelivery(state) {
  return state.services.enablePOD;
}

export const getWelcomeMessage = state => state.services.welcomeMessage;

export const getSelectedServiceIndex = state => state.services.selectedService;
