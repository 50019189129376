import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Heading, Button } from '@lalamove/karang';
import {
  add as AddIcon,
  edit as EditIcon,
} from '@lalamove/karang/dist/components/Icon/icons';
import moment from 'moment';
import { push } from 'connected-react-router';

import { cloneOrder } from 'store/modules/records';
import { getCurrentCity } from 'store/modules/region/selectors';
import {
  getSpecialRequest,
  getSubSpecialRequest,
} from 'store/modules/services';

import { RouteShape, PriceShape } from 'views/Records/propTypes';
import SliderPanelFooter from 'components/SliderPanelFooter';
import { black, nobel, silver } from 'styles/colors';
import { MODAL } from 'styles/zIndex';
import { noop, statusMap } from 'utils/helpers';
import Route from './components/Route';
import DeliveryRoute from './components/DeliveryRoute';
import Price from './components/Price';

const { REACT_APP_ENABLE_DELIVERY } = process.env;
const cancelled = [statusMap.CANCELLED, statusMap.REJECTED, statusMap.EXPIRED];
const bull = '\u2022';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: auto;
  color: ${black};
`;

const Overlay = styled.div`
  position: absolute;
  z-index: ${MODAL};
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
`;

const Content = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  height: auto;
  padding: 0 1.25em 0 2.25em;
`;

const Row = styled.div`
  padding: 0.75em 0;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeadingButton = styled(Button)`
  padding: 0.25em;

  & > span {
    margin-left: 4px;
  }

  :disabled {
    color: ${silver};
  }
`;

const Image = styled.img`
  max-width: 88px;
  max-height: 50px;
  padding: 1em 0.5em;
  border: 1px solid ${nobel['200']};
  margin: 0.5em 0;
`;

const PodDetails = ({ label, signedBy, datetime, signature }) => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {REACT_APP_ENABLE_DELIVERY ? (
        <span>{`${label} ${signedBy}`}</span>
      ) : (
        <span>{signedBy}</span>
      )}
      <span>{moment(datetime).format(`DD MMM YYYY ${bull} H:mm`)}</span>
    </div>
    {REACT_APP_ENABLE_DELIVERY && signature && <Image src={signature} />}
  </div>
);

PodDetails.propTypes = {
  label: string.isRequired,
  signedBy: string.isRequired,
  datetime: string.isRequired,
  signature: string,
};

PodDetails.defaultProps = {
  signature: '',
};

class OrderDetails extends Component {
  static defaultProps = {
    status: null,
    editable: false,
    edited: false,
    noteToDriver: '',
    podSignedBy: '',
    podDatetime: '',
    podSignature: '',
    paymentMethod: 'CASH',
    route: null,
    price: null,
    additionalServices: [],
    showOverlay: false,
    onClone: noop,
    onAddPriorityFee: noop,
    onCancel: noop,
    onClose: noop,
    t: noop,
    showDeliveryStatus: false,
    showPod: false,
    historyPush: noop,
  };

  static propTypes = {
    id: string.isRequired,
    status: number,
    editable: bool,
    edited: bool,
    noteToDriver: string,
    podSignedBy: string,
    podDatetime: string,
    podSignature: string,
    paymentMethod: string,
    route: RouteShape,
    price: PriceShape,
    additionalServices: arrayOf(shape()),
    showOverlay: bool,
    onClone: func,
    onAddPriorityFee: func,
    onCancel: func,
    onClose: func,
    t: func,
    orderEditEnabled: bool.isRequired,
    showDeliveryStatus: bool,
    showPod: bool,
    historyPush: func,
  };

  onCloneClick = () => {
    this.props.onClone(this.props.id);
  };

  onEditClick = () => {
    const { historyPush } = this.props;
    historyPush({
      pathname: `/orders/${this.props.id}/edit`,
      search: new URLSearchParams({
        referer: `/orders/${this.props.id}${window.location.search}`,
      }).toString(),
    });
  };

  renderEdit = () => {
    const {
      t,
      status,
      editable,
      edited,
      orderEditEnabled,
      showDeliveryStatus,
    } = this.props;

    // conditional logics:
    const isShown = (status === statusMap.ONGOING && editable) || edited;
    const isDisabled = !editable && edited;

    if (!orderEditEnabled || !isShown) return null;
    return (
      <span
        data-tip={
          showDeliveryStatus ? t('Records.button_tooltip_contact_cs') : ''
        }
        data-place="top"
        data-effect="solid"
        data-for="global"
      >
        <HeadingButton
          size="small"
          variant="link"
          icon={<EditIcon size={12} />}
          iconPosition="before"
          disabled={isDisabled || showDeliveryStatus}
          onClick={this.onEditClick}
        >
          {t(`RecordPanel.button_edit_order${isDisabled ? '_edited' : ''}`)}
        </HeadingButton>
      </span>
    );
  };

  renderFooter = () => {
    const { t, status, showDeliveryStatus, onCancel, onClose } = this.props;
    const cancelOption = {
      secondaryAction: onCancel,
      secondaryButtonText: t('RecordPanel.button_cancel_order'),
    };
    return (
      <SliderPanelFooter
        primaryAction={this.onCloneClick}
        primaryButtonText={t('Records.option_clone_order')}
        primaryButtonTooltip={
          showDeliveryStatus ? t('Records.button_tooltip_contact_cs') : ''
        }
        disablePrimaryButton={showDeliveryStatus}
        {...([statusMap.ASSIGNING, statusMap.ONGOING].includes(status)
          ? cancelOption
          : {})}
        closeAction={onClose}
        closeButtonText={t('RecordPanel.button_close_panel')}
      />
    );
  };

  render() {
    const {
      status,
      noteToDriver,
      podSignedBy,
      podDatetime,
      podSignature,
      paymentMethod,
      route: { waypoints, deliveryInfo, deliveries },
      price,
      additionalServices,
      showOverlay,
      onAddPriorityFee,
      showDeliveryStatus,
      showPod,
      t,
    } = this.props;

    const additionalServicesSummary = additionalServices.reduce(
      (summary, request) =>
        summary +
        (summary ? ` ${bull} ` : '') +
        request.name +
        (request.subRequest ? ` - ${request.subRequest.name}` : ''),
      ''
    );

    const RouteComp =
      REACT_APP_ENABLE_DELIVERY && showDeliveryStatus ? DeliveryRoute : Route;

    return (
      <Container>
        {showOverlay && <Overlay />}
        <Content>
          <Row>
            <HeadingWrapper>
              <Heading htmlTag="h3">{t('RecordPanel.heading_route')}</Heading>
              {this.renderEdit()}
            </HeadingWrapper>
            <RouteComp
              waypoints={waypoints}
              deliveryInfo={deliveryInfo}
              deliveries={deliveries}
              cancelled={cancelled.includes(status)}
              showDeliveryStatus={showDeliveryStatus}
              showPod={showPod}
            />
          </Row>
          {additionalServices.length > 0 && (
            <Row>
              <Heading htmlTag="h3">
                {t('RecordPanel.heading_additional_services')}
              </Heading>
              <div>{additionalServicesSummary}</div>
            </Row>
          )}
          {noteToDriver && (
            <Row>
              <Heading htmlTag="h3">
                {t('RecordPanel.heading_notes_to_driver')}
              </Heading>
              <div>
                {noteToDriver.split('\n').map(text => (
                  <Fragment key={text}>
                    {text}
                    <br />
                  </Fragment>
                ))}
              </div>
            </Row>
          )}
          {!showDeliveryStatus && podSignedBy && (
            <Row>
              <Heading htmlTag="h3">
                {t(
                  REACT_APP_ENABLE_DELIVERY
                    ? 'RecordPanel.heading_signature'
                    : 'RecordPanel.heading_signed_by'
                )}
              </Heading>
              <PodDetails
                label={t('RecordPanel.heading_signed_by')}
                signedBy={podSignedBy}
                datetime={podDatetime}
                signature={podSignature}
              />
            </Row>
          )}
          <Row>
            <Price price={price} paymentMethod={paymentMethod} hideZeroItems />
          </Row>
          {status === statusMap.ASSIGNING && (
            <Row>
              <Heading htmlTag="h3">
                {t('RecordPanel.heading_priority_fee')}
              </Heading>
              <div>{t('RecordPanel.msg_add_priority_fee')}</div>
              <div style={{ marginTop: '1em', textAlign: 'right' }}>
                <Button onClick={onAddPriorityFee} icon={<AddIcon />}>
                  {t('RecordPanel.button_add_priority_fee')}
                </Button>
              </div>
            </Row>
          )}
        </Content>
        {this.renderFooter()}
      </Container>
    );
  }
}

const mapState = (state, { specialRequests, subRequests }) => {
  const additionalServices = [];
  specialRequests.forEach(key => {
    const specialRequest = getSpecialRequest(state, key) || {};
    const subKey = subRequests[key];
    if (subKey) {
      const subRequest = getSubSpecialRequest(state, subKey);
      specialRequest.subRequest = subRequest;
    }
    additionalServices.push(specialRequest);
  });
  return {
    additionalServices,
    orderEditEnabled: getCurrentCity(state).orderEditEnabled,
  };
};

export default compose(
  withTranslation(),
  connect(mapState, { onClone: cloneOrder, historyPush: push })
)(OrderDetails);
