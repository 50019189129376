import SimpleLock from 'utils/SimpleLock';

class FetcherLockService {
  accessTokenMutex = new SimpleLock().lock();

  langMutex = new SimpleLock().lock();

  applicationStartUpMutex = new SimpleLock().lock();

  lockAuthSession() {
    this.accessTokenMutex.lock();
  }

  unlockAuthSession() {
    this.accessTokenMutex.unlock();
  }

  lockLocale() {
    this.langMutex.lock();
  }

  unlockLocale() {
    this.langMutex.unlock();
  }

  unlockApplicationStartUpLock() {
    this.applicationStartUpMutex.unlock();
  }
}

export default new FetcherLockService();
