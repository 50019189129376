import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';

import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

/**
 * Fetches CouponList
 *
 * @param {Object} options
 * @param {string} options.type - history type, i.e. [HISTORY, REWARDS, CASH]
 * @param {number} options.current - current page number
 * @param {number} options.max - number of rows per page
 * @param {string} options.start - filter start date (ISO YYYY-MM-DD)
 * @param {string} options.end - filter end date (ISO YYYY-MM-DD)
 * @return {Promise<Response>} result - resolves to
 * @throws ERROR_INVALID_SESSION, ERROR_INVALID_PARAMETERS, ERROR_OUTOFF_DATERANGE, ...
 */
export default async function fetchCouponList({
  current = 1,
  max = 10,
  start = new Date().toISOString().split('T')[0],
  end = new Date().toISOString().split('T')[0],
  search = '',
}) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  await FetcherLockService.langMutex.release();
  await FetcherLockService.accessTokenMutex.release();
  const {
    locale,
    accessToken,
    clientId,
    profileType,
    countryId,
    timezone,
  } = await fetcher.getState();
  const params = {
    os: 'web',
    is_ep: profileType,
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    htzone: timezone,
    client_id: clientId,
    args: JSON.stringify({
      end_time: end,
      start_time: start,
      // filter_by_date: 1,
      page_no: current,
      page_size: max,
      // user_name: real_name,
      // trans_type: methodMap[method],
      ...(search && { search: [search] }),
    }),
  };

  const { data, ret, msg } = await fetcher.get('?_m=coupon_list_web', params);

  if (ret !== 0) throw new UApiError(msg, ret);

  return data;
}
