import { string, oneOf, arrayOf } from 'prop-types';
import _omit from 'lodash/omit';

import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { CHANGE_LOCATION_REQUEST } from 'store/modules/region/actions';
import _filter from 'lodash/filter';
import {
  WAYPOINT_REMOVE,
  WAYPOINT_UPDATE,
  DELIVERY_INFO_UPDATE,
} from '../routing/actionTypes';

export const MessageShape = {
  id: string,
  type: oneOf(['warning', 'error', 'info', 'success']),
  title: string,
  message: string,
  data: arrayOf(string),
};

// action types
export const DISMISS_MESSAGE = 'DISMISS_MESSAGE';
export const DISMISS_ALL_MESSAGES = 'DISMISS_ALL_MESSAGES';

// action creators
export const dismissMessage = id => ({
  type: DISMISS_MESSAGE,
  id,
});

export const dismissAllMessages = () => ({
  type: DISMISS_ALL_MESSAGES,
});

const initState = {};

const defaultMeta = {
  type: 'error',
  title: '',
  message: '',
  data: [],
};

export default function messageReducer(state = initState, action) {
  const { type, meta, payload } = action;
  const matches = /(.*)_(SUCCESS|FAILURE)/.exec(type);

  switch (action.type) {
    // reset
    case CHANGE_LOCATION_REQUEST:
    case REQUEST_LOGOUT:
    case DISMISS_ALL_MESSAGES:
      return initState;
    case DISMISS_MESSAGE: {
      const { id } = action;
      const updatedState = _omit(state, [id]);
      return updatedState;
    }
    // clear/update IMPORT message on WAYPOINT_REMOVE, WAYPOINT_UPDATE, and DELIVERY_INFO_UPDATE:
    case WAYPOINT_REMOVE:
    case WAYPOINT_UPDATE:
    case DELIVERY_INFO_UPDATE: {
      if (!state.IMPORT) return state;
      const waypointId = action.id;
      const remainData = _filter(state.IMPORT.data, id => id !== waypointId);
      const remainIds = _filter(state.IMPORT.ids, id => id !== waypointId);
      if (!remainData.length && !remainIds.length) {
        const { IMPORT, ...newState } = state;
        return newState;
      }
      return {
        ...state,
        ...(state.IMPORT && {
          IMPORT: {
            ...state.IMPORT,
            ...(state.IMPORT.data && { data: remainData }),
            ...(state.IMPORT.ids && { ids: remainIds }),
          },
        }),
      };
    }

    default:
      null; // eslint-disable-line no-unused-expressions
  }

  if (!matches) return state;

  const [, actionName, actionState] = matches;
  let newState = {
    ...state,
    [actionName]: { ...defaultMeta },
  };

  if (!meta) {
    // handle legacy convention with payload
    if (payload) {
      newState = {
        ...state,
        [actionName]: {
          type: 'error',
          message: payload,
        },
      };
    }
    if (actionState === 'SUCCESS') {
      // ACTION_NAME_SUCCESS without meta will remove prev message set
      const { [actionName]: _, ...rest } = newState || state;
      newState = rest;
    }
  } else {
    newState = {
      ...state,
      [actionName]: { ...defaultMeta, ...meta },
    };
  }

  return newState;
}

// return first match
export const makeMessageSelector = (actionTypes, exclude = '') => state =>
  actionTypes
    .filter(id => Boolean(state.message[id]))
    .map(id => ({ id, ...state.message[id] }))
    .filter(msgObj => msgObj.message !== exclude)
    .reduce((foundMsg, msgObj) => foundMsg || msgObj, undefined);

export const hasMessage = (actionTypes, msgToFind) => state => {
  const msg = makeMessageSelector(actionTypes)(state);
  if (!msg) return false;
  if (Array.isArray(msgToFind)) return msgToFind.includes(msg.message);
  return msg.message === msgToFind;
};
