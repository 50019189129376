import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import styled from 'styled-components';
import _every from 'lodash/every';
import _isEqual from 'lodash/isEqual';

import {
  getWaypointById,
  updateWaypt,
  getWaypoints,
  getFilledWaypts,
  getErrorByStopItemId,
  getOrderIdByWayptId,
  updateSessionToken,
} from 'store/modules/routing';
import {
  updateDeliveryInfo,
  getDeliveryInfoByWayptId,
} from 'store/modules/routing/deliveryInfo';
import { getPlaceDetails } from 'api/restAPI';
import { orange, gray, lightSilver } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { noop } from 'utils/helpers';

import AutocompleteList from '../AutocompleteList';
import { LocationShape, DeliveryInfoShape } from '../../propTypes';
import { BtnRemove, Container, Content } from './style';

import DeliveryInfoForm from '../DeliveryInfo';
import StopItemInput from '../StopItemInput';

const { string, func, bool, arrayOf, shape } = PropTypes;

function isFilled(item) {
  return (!!item.lat && !!item.lng) || !!item.placeId;
}

const StyledContactLabel = styled.div`
  color: ${lightSilver};
  font-size: ${fontSize.small};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export class StopItem extends Component {
  static defaultProps = {
    t: noop,
    indexColor: gray,
    placeholder: 'Stop Location',
    history: [],
    deliveryInfo: null,
    onRemove: noop,
    updateWaypt: noop,
    updateDeliveryInfo: noop,
    updateSessionToken: noop,
    isRemovable: false,
    disabled: false,
    autofocus: false,
    errors: {},
  };

  static propTypes = {
    t: func,
    id: string.isRequired,
    indexColor: string,
    waypoint: LocationShape.isRequired,
    placeholder: string,
    history: arrayOf(shape({ description: string, placeId: string })),
    onRemove: func,
    isRemovable: bool,
    disabled: bool,
    updateWaypt: func,
    updateDeliveryInfo: func,
    updateSessionToken: func,
    deliveryInfo: DeliveryInfoShape,
    autofocus: bool,
    errors: shape({}),
  };

  state = {
    searchText: this.props.waypoint.name,
    suggestions: [],
    isShownDeliveryInfoForm: false,
  };

  nodeRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (prevProps.waypoint !== this.props.waypoint) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ searchText: this.props.waypoint.name });
    }
  }

  componentWillUnmount() {
    clearTimeout(this._onBlurTimeout);
  }

  onBlur = ({ currentTarget, target }) => {
    clearTimeout(this._onBlurTimeout);
    // setTimeout here moves the code block to the end of the execution queue,
    // so that delivery info popover won't be hidden when delivery info form is active:
    this._onBlurTimeout = setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.handleHideDeliveryInfo();
      }
    });
  };

  handleInputChange = e => {
    // https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js/28046731
    e.persist();
    const { value } = e.target;
    this.setState({ searchText: value });
    this.handleHideDeliveryInfo();
  };

  handleWaypointUpdate = async location => {
    let { lat, lng } = location;
    const {
      id,
      waypoint,
      deliveryInfo,
      updateWaypt, // eslint-disable-line no-shadow
      updateDeliveryInfo, // eslint-disable-line no-shadow
    } = this.props;

    if (_isEqual(location, waypoint)) {
      return this.setState({ suggestions: [], searchText: waypoint.name });
    }

    if (location.placeId && (!lat || !lng)) {
      const {
        geometry: { location: loc },
      } = await getPlaceDetails(location.placeId);
      lat = loc.lat;
      lng = loc.lng;
    }

    updateWaypt({
      id,
      name: location.description || location.name || 'Loading...', // TODO: proper loading style
      placeId: location.placeId,
      lat,
      lng,
      method: location.type === 'history' ? 'history' : 'suggestion',
    });

    function serializeAddr(addrObj) {
      return addrObj ? Object.values(addrObj).filter(Boolean).join('/') : '';
    }

    if (location.type === 'history' && location.contact) {
      updateDeliveryInfo(id, {
        ...location.contact,
        addressDetails: serializeAddr(location.addressDetails),
      });
    } else {
      // check if all fields are empty
      _every(deliveryInfo, d => !d) && this.showDeliveryInfo();
    }

    return this.setState({
      searchText: location.description,
      suggestions: [],
    });
  };

  handleRemove = e => {
    const { id, onRemove } = this.props;
    e.preventDefault();
    onRemove(id);
  };

  showDeliveryInfo = () => this.setState({ isShownDeliveryInfoForm: true });

  handleHideDeliveryInfo = () =>
    this.setState({ isShownDeliveryInfoForm: false });

  handleInputBlur = e => {
    const { waypoint } = this.props;
    const { suggestions, searchText } = this.state;
    if (!e.target.value) {
      return this.setState({ searchText: waypoint.name });
    }
    return this.handleWaypointUpdate(
      // pick first in AutocompleteList
      suggestions[0] ||
        (waypoint.name && waypoint) || { description: searchText }
    );
  };

  handleSuggestions = ({ list, token }) => {
    this.setState({ suggestions: list });
    this.props.updateSessionToken(token);
  };

  hasErrors = () => {
    const { errors } = this.props;
    const errMsg = Object.keys(errors).reduce(
      (msg, error) => msg || (errors[error] && error),
      ''
    );
    return errMsg;
  };

  generateListItems(inputValue) {
    const { suggestions } = this.state;
    let { history } = this.props;
    history = !inputValue ? history : [];
    return [...suggestions, ...history.map(h => ({ ...h, type: 'history' }))];
  }

  renderContact() {
    const { deliveryInfo, disabled } = this.props;
    if (!deliveryInfo) return null;
    const { name, phone, addressDetails } = deliveryInfo;
    return (
      <StyledContactLabel
        role="button"
        disabled={disabled}
        {...(!disabled && { onClick: this.showDeliveryInfo })}
      >
        {[name, phone, addressDetails].filter(Boolean).join(' | ')}
      </StyledContactLabel>
    );
  }

  render() {
    const {
      t,
      disabled,
      isRemovable,
      placeholder,
      waypoint,
      indexColor,
      autofocus,
    } = this.props;
    const { isShownDeliveryInfoForm } = this.state;

    return (
      <div onBlur={this.onBlur} ref={this.nodeRef}>
        <Downshift
          inputValue={this.state.searchText}
          defaultHighlightedIndex={0}
          selectedItem={{
            ...waypoint,
            description: waypoint.name /* fix naming */,
          }}
          onChange={this.handleWaypointUpdate}
          itemToString={item => (item ? item.description : '')}
        >
          {({
            getToggleButtonProps,
            getInputProps,
            getItemProps,
            isOpen,
            highlightedIndex,
            selectedItem,
            inputValue,
            openMenu,
            selectItemAtIndex,
          }) => (
            <div>
              <DeliveryInfoForm
                parent={this.nodeRef.current}
                isOpen={isShownDeliveryInfoForm}
                waypointId={waypoint.id}
                indexColor={indexColor}
                onClose={this.handleHideDeliveryInfo}
              >
                {({ isDirty }) => (
                  <Container
                    warning={isDirty}
                    error={this.hasErrors()}
                    shouldShowIndex={isFilled(selectedItem)}
                    indexColor={indexColor}
                    {...(disabled && {
                      'data-tip': t('EditOrder.tooltip_origin_cannot_edit'),
                      'data-place': 'bottom',
                      'data-for': 'global',
                    })}
                  >
                    <Content disabled={disabled}>
                      <StopItemInput
                        onBlur={this.handleInputBlur}
                        item={selectedItem}
                        placeholder={placeholder}
                        onChange={this.handleInputChange}
                        onSuggestions={this.handleSuggestions}
                        onOpenDropdown={openMenu}
                        getInputProps={getInputProps}
                        getToggleButtonProps={getToggleButtonProps}
                        selectItemAtIndex={selectItemAtIndex}
                        showDeliveryInfo={this.showDeliveryInfo}
                        autofocus={autofocus}
                        disabled={disabled}
                      />
                      {this.renderContact()}
                    </Content>
                    {isRemovable && <BtnRemove onClick={this.handleRemove} />}

                    {isOpen && (
                      <AutocompleteList
                        items={this.generateListItems(inputValue)}
                        getItemProps={getItemProps}
                        highlightedIndex={highlightedIndex}
                      />
                    )}
                  </Container>
                )}
              </DeliveryInfoForm>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

const getIndexColor = (filledWaypts, id) => {
  const index = filledWaypts.map(wp => wp.id).indexOf(id) + 1;
  const lastIndex = filledWaypts.length;
  // CAUTION - 1 based index
  if (index === 1 || index === lastIndex) return orange;
  return gray;
};

const getAutofocus = (waypts, id) => {
  const index = waypts.map(wp => wp.id).indexOf(id) + 1;
  return index !== 2; // prevent auto-focus to drop off point
};

const mapState = (state, { id }) => {
  const orderId = getOrderIdByWayptId(state, id);

  return {
    waypoint: getWaypointById(state.routing, id),
    deliveryInfo: getDeliveryInfoByWayptId(state, id),
    indexColor: getIndexColor(getFilledWaypts(state, orderId), id),
    errors: getErrorByStopItemId(state, id),
    autofocus: getAutofocus(getWaypoints(state.routing, orderId), id),
  };
};

export default compose(
  withTranslation(),
  connect(mapState, {
    updateWaypt,
    updateDeliveryInfo,
    updateSessionToken,
  })
)(StopItem);
