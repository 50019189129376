import moment from 'moment';
import Big from 'big.js';
import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import { createSu } from 'utils/apiHelper';
import { getCurrencyRate } from 'store/modules/region/selectors';
import UAPIFetcher from '../UAPIFetcher';
import FetcherFactory from '../FetcherFactory';

export default async function addPriorityFee(
  orderId: number,
  amount: Big
): Promise<void> {
  const fetcher = FetcherFactory.make(UAPIFetcher);

  const {
    accessToken,
    profileType,
    cityId,
    countryId,
    locale,
  } = await fetcher.getState();
  const state = await fetcher.getRootState();
  const currencyRate = getCurrencyRate(state);

  const params = {
    args: JSON.stringify({
      tips_fen: amount.times(currencyRate).toNumber(),
      order_uuid: orderId,
    }),
    city_id: cityId,
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
    os: 'web',
    _t: moment().unix(),
    _su: createSu(),
  };

  const { ret, msg } = await fetcher.get('?_m=add_tips', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }
}
