import React, { Component } from 'react';
import { arrayOf, bool, string, shape, func } from 'prop-types';
import styled from 'styled-components';
import { Select, Input } from '@lalamove/karang';
import { whenMobile } from 'components/MediaQuery';
import { noop } from 'utils/helpers';

const Container = styled.div`
  display: flex;
  width: 100%;

  & ul {
    width: auto;
    white-space: nowrap;
  }

  & > div:nth-child(1) {
    align-items: stretch;
    ${({ error }) => error && `padding-bottom: 2em;`}

    > div:nth-child(1) {
      box-sizing: border-box;
      height: 100%;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & > div:nth-child(2) {
    margin-left: 4px;

    > div:nth-child(2) {
      left: calc(-7em - 4px); /* hack for error message */
    }
  }
`;

const StyledSelect = styled(Select)`
  min-width: 7em;

  & button {
    align-items: center;
    height: 100%;
    padding: 0 0.5em;

    > span {
      margin-left: 0.5em;
    }
  }
`;

const StyledInput = styled(Input)`
  flex: 1;

  ${whenMobile} {
    input {
      font-size: 16px;
    }
  }

  ${({ isInputFocused }) =>
    isInputFocused
      ? ''
      : `label {
        overflow: hidden;
        max-width: calc(100% - 1em);
        text-overflow: ellipsis;
        white-space: nowrap;
      }`}
`;

class PhoneInput extends Component {
  static propTypes = {
    label: string,
    value: string,
    error: string,
    showAreaCode: bool,
    currentCountry: shape({
      id: string.isRequired,
      areaCode: string.isRequired,
      samplePhone: string,
    }).isRequired,
    countries: arrayOf(
      shape({
        id: string.isRequired,
        areaCode: string.isRequired,
        defaultLanguage: string,
        translations: arrayOf(
          shape({
            id: string.isRequired,
            value: string.isRequired,
          })
        ),
      })
    ).isRequired,
    onChange: func,
    onPaste: func,
    onCountryChange: func,
    onBlur: func,
  };

  static defaultProps = {
    label: null,
    value: '',
    error: '',
    showAreaCode: false,
    onChange: noop,
    onPaste: noop,
    onCountryChange: noop,
    onBlur: noop,
  };

  state = {
    isInputFocused: false,
  };

  getCountryList() {
    const { countries } = this.props;
    return countries
      .sort((a, b) => {
        // sort by English name:
        const aTrans = a.translations.find(trans => trans.id.startsWith('en'));
        const bTrans = b.translations.find(trans => trans.id.startsWith('en'));
        const aName = aTrans ? aTrans.value : a.id;
        const bName = bTrans ? bTrans.value : b.id;
        return aName.localeCompare(bName);
      })
      .map(country => {
        const { id, areaCode, defaultLanguage, translations } = country;
        let name = id;
        if (translations && defaultLanguage) {
          name = translations.find(lang => lang.id === defaultLanguage).value;
        }
        const label = `${name} (+${areaCode})`;
        return {
          label,
          value: id,
        };
      });
  }

  getSelectedCountry() {
    const { id, areaCode } = this.props.currentCountry;
    const label = `+${areaCode}`;
    return {
      label,
      value: id,
    };
  }

  handleSelect = ({ value }) => {
    this.props.onCountryChange(value);
  };

  onFocus = () => {
    this.setState({ isInputFocused: true });
  };

  onBlur = e => {
    this.setState({ isInputFocused: false });
    this.props.onBlur(e);
  };

  render() {
    const {
      label,
      value,
      error,
      currentCountry,
      countries,
      onChange,
      onCountryChange,
      onBlur,
      ...props
    } = this.props;
    const { isInputFocused } = this.state;

    const { showAreaCode } = this.props;
    const inputLabel = showAreaCode
      ? `${label} (e.g. ${currentCountry.samplePhone})`
      : label;

    return (
      <Container error={error}>
        {showAreaCode && (
          <StyledSelect
            items={this.getCountryList()}
            selectedItem={this.getSelectedCountry()}
            onChange={this.handleSelect}
          />
        )}
        <StyledInput
          type="text"
          label={inputLabel}
          value={value}
          error={error}
          onChange={onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          isInputFocused={isInputFocused}
          {...props}
        />
      </Container>
    );
  }
}

export default PhoneInput;
