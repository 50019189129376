import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func, shape, bool } from 'prop-types';

import { actions } from 'store/modules/zendesk';
import { getCurrentCity } from 'store/modules/region/selectors';

import Widget from './Widget';
import Button from './Button';

class Zendesk extends Component {
  static propTypes = {
    close: func.isRequired,
    logout: func.isRequired,
    city: shape({ zendeskChatEnabled: bool }).isRequired,
    genesysEnable: bool.isRequired,
    zendeskEnable: bool.isRequired,
  };

  componentWillUnmount() {
    const { close, logout } = this.props;
    close();
    logout();
  }

  render() {
    const { genesysEnable, zendeskEnable } = this.props;
    const showZendesk = genesysEnable ? false : zendeskEnable;
    const {
      city: { zendeskChatEnabled },
    } = this.props;
    if (!zendeskChatEnabled || !showZendesk) {
      return null;
    }
    return (
      <Fragment>
        <Widget />
        <Button />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  city: getCurrentCity(state),
  genesysEnable: state.genesys.genesysEnable,
  zendeskEnable: state.genesys.zendeskEnable,
});

const mapDispatchToProps = {
  close: actions.close,
  logout: actions.logout,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Zendesk);
