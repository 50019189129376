import React, { Component } from 'react';
import { func, number, instanceOf } from 'prop-types';
import moment from 'moment';
import { END_DATE } from 'react-dates/constants';
import { DateRangePicker as LLMDateRangePicker } from '@lalamove/karang';
import { noop } from 'utils/helpers';

class DateRangePicker extends Component {
  static defaultProps = {
    startDate: moment(),
    endDate: moment(),
    onDatesChange: noop,
    onFocusChange: noop,
    minDate: null,
    maxDate: null,
    maxDays: null,
  };

  static propTypes = {
    startDate: instanceOf(moment),
    endDate: instanceOf(moment),
    onDatesChange: func,
    onFocusChange: func,
    minDate: instanceOf(moment),
    maxDate: instanceOf(moment),
    maxDays: number,
  };

  state = {
    // default values from props for internal state (date selection)
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    focusedInput: null,
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
    this.props.onDatesChange({ startDate, endDate });
  };

  getMaxDate = () => {
    const { startDate, focusedInput } = this.state;
    const { maxDate, maxDays } = this.props;
    if (!maxDate) return null;

    if (focusedInput === END_DATE && maxDays) {
      const targetMaxDate = startDate.clone().add(maxDays - 1, 'days'); // include start date
      return moment.min(maxDate, targetMaxDate);
    }
    return maxDate;
  };

  onFocusChange = focusedInput => {
    const { endDate } = this.state;
    const { onFocusChange, startDate } = this.props;
    const maxDate = this.getMaxDate();
    this.setState({ focusedInput });
    onFocusChange(focusedInput);

    if (focusedInput === END_DATE && maxDate) {
      if (endDate && endDate.isAfter(maxDate, 'day')) {
        this.onDatesChange({ startDate, endDate: maxDate });
      }
    }
  };

  isOutsideRange = momentDate => {
    const { minDate } = this.props;
    const maxDate = this.getMaxDate();

    if (minDate && maxDate) {
      return !momentDate.isBetween(minDate, maxDate, 'day', '[]'); // inclusive
    }
    if (minDate && !maxDate) {
      return momentDate.isSameOrBefore(minDate);
    }
    if (!minDate && maxDate) {
      return momentDate.isSameOrAfter(maxDate);
    }
    return false;
  };

  render() {
    const {
      // omit custom props
      minDate,
      maxDate,
      maxDays,
      ...remainProps
    } = this.props;
    const { startDate, endDate } = this.state;
    return (
      <LLMDateRangePicker
        {...remainProps}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={this.onDatesChange}
        onFocusChange={this.onFocusChange}
        isOutsideRange={this.isOutsideRange}
        displayFormat={() => 'DD-MM-YYYY'} // TODO: translation
      />
    );
  }
}

export default DateRangePicker;
