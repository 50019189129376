import React, { Component, Fragment } from 'react';
import { func, bool, string, shape, number } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { GoogleButton } from 'components/Button';
import HtmlTitle from 'components/HtmlTitle';
import { loginSubmit } from 'store/modules/auth/actions';
import {
  facebookLogin as fbLogin,
  cancelFacebookLogin as cancelFBLogin,
  googleLoginRequest as googleLogin,
} from 'interfaces/global/store/modules/auth/actions';
import {
  getUser,
  checkAuth,
  getReferrer,
} from 'interfaces/global/store/modules/auth/selectors';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';
import {
  actions as regionAction,
  selectors as regionSelector,
} from 'store/modules/region';
import {
  disableBrowserWarning,
  getHasShowBrowserWarning,
} from 'store/modules/browserSession';
import { actions as genesysActions } from 'interfaces/global/store/modules/genesys/actions';

import { track as sensorsTracking } from 'interfaces/global/store/modules/tracking/actions';

import { parseUrlParams, noop } from 'utils/helpers';
import { isIE } from 'utils/userAgentHelper';

import loadFacebookSDK from 'utils/facebookApi';
import {
  createClient as loadGoogleSDK,
  getAuth2Instance as getGoogleAuthInstance,
} from 'utils/googleApi';

import LoginBox from './components/LoginBox';
import BrowserSupportBox from './components/BrowserSupportBox';

export const redirect = ({ location, authenticated, token }) => {
  const { redirect: url } = parseUrlParams(location.search);
  if (url && authenticated && token) {
    window.location.assign(
      `${decodeURIComponent(url)}?token=${token}&hostname=${
        window.location.hostname
      }`
    );
  }
};

export class Login extends Component {
  _isMounted = false;

  static defaultProps = {
    loading: false,
    apiError: null,
    referrer: '/',
    token: '',
    disableBrowserWarning: noop,
  };

  static propTypes = {
    t: func.isRequired,
    loading: bool,
    apiError: shape({ message: string, errorCode: number }),
    loginSubmit: func.isRequired,
    facebookLogin: func.isRequired,
    cancelFacebookLogin: func.isRequired,
    googleLoginRequest: func.isRequired,
    authenticated: bool.isRequired,
    location: shape({}).isRequired,
    referrer: string,
    token: string,
    currentCountry: shape({ id: string, areaCode: string }).isRequired,
    language: string.isRequired,
    countryDict: shape({}).isRequired,
    changeLocation: func.isRequired,
    disableBrowserWarning: func,
    hasShownBrowserWarning: bool.isRequired,
    logoutGenesys: func.isRequired,
    track: func.isRequired,
  };

  state = {
    loadingFacebookLoginButton: true,
    loadingGoogleLoginButton: true,
  };

  componentDidMount = async () => {
    this._isMounted = true;

    const { location, authenticated, token, logoutGenesys } = this.props;

    logoutGenesys();
    redirect({ location, authenticated, token });

    // We can only use Google login on one origin domain (lalamove.com). This is to prevent
    // web.lalamove.net.in from throwing errors related to loading the Google login script.
    if (GoogleButton.componentDisabled) await this.loadFacebookLoginScript();
    else
      await Promise.all([
        this.loadFacebookLoginScript(),
        this.loadGoogleLoginScript(),
      ]);
  };

  componentDidUpdate(prevProps) {
    const { location, authenticated, token } = this.props;
    const {
      location: prevLoc,
      authenticated: prevAuth,
      token: prevToken,
    } = prevProps;
    if (
      location !== prevLoc ||
      authenticated !== prevAuth ||
      token !== prevToken
    ) {
      redirect({ location, authenticated, token });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadFacebookLoginScript = async () => {
    const { currentCountry, language } = this.props;
    this.FB = await loadFacebookSDK(currentCountry.id, language);
    this.facebookLoginResponse = await this.FB.getLoginStatus();

    if (this._isMounted)
      this.setState({
        loadingFacebookLoginButton: false,
      });
  };

  loadGoogleLoginScript = async () => {
    try {
      await loadGoogleSDK();
      this.googleAuthInstance = getGoogleAuthInstance();
      await this.googleAuthInstance.then();
    } catch (error) {
      // Ignore it for cases like Chrome's incognito mode that blocks cookies necessary to load the script
    }

    if (this._isMounted)
      this.setState({
        loadingGoogleLoginButton: false,
      });
  };

  handleSubmit = ({ username, password, areaCode }) => {
    this.props.loginSubmit({
      username,
      password,
      areaCode,
    });
  };

  handleFBLogin = async () => {
    const {
      currentCountry,
      track,
      facebookLogin,
      cancelFacebookLogin,
    } = this.props;

    track('social_login_clicked', { type: 'facebook' });

    if (
      !this.facebookLoginResponse.status ||
      this.facebookLoginResponse.status !== 'connected'
    ) {
      this.facebookLoginResponse = await this.FB.login({
        scope: 'email,public_profile',
        return_scopes: true,
      });

      // User cancelled login or did not fully authorize.
      if (
        !this.facebookLoginResponse.status ||
        this.facebookLoginResponse.status !== 'connected'
      ) {
        cancelFacebookLogin();
        return;
      }
    }

    const {
      authResponse: { accessToken },
    } = this.facebookLoginResponse;

    const user = await this.FB.api('/me', {
      fields: 'first_name, last_name, name, email',
    });

    facebookLogin({
      areaCode: currentCountry.areaCode,
      accessToken,
      user,
    });
  };

  handleGoogleLogin = () => {
    const { currentCountry, googleLoginRequest, track } = this.props;
    googleLoginRequest({
      areaCode: currentCountry,
      authInstance: this.googleAuthInstance,
    });
    track('social_login_clicked', { type: 'google' });
  };

  changeCountry = country => {
    const { currentCountry, changeLocation } = this.props;
    if (country === currentCountry.id) {
      return;
    }

    changeLocation({
      country,
    });
  };

  dismissBrowserWarning = () => {
    // eslint-disable-next-line no-shadow
    const { disableBrowserWarning } = this.props;
    disableBrowserWarning();
  };

  render() {
    const {
      t,
      authenticated,
      referrer,
      loading,
      apiError,
      currentCountry,
      countryDict,
      hasShownBrowserWarning,
    } = this.props;

    if (authenticated) {
      return <Redirect to={referrer} />;
    }

    const shouldShowWarning =
      !hasShownBrowserWarning && isIE(navigator.userAgent);
    return (
      <Fragment>
        <HtmlTitle>{t('Title.login')}</HtmlTitle>
        {shouldShowWarning ? (
          <BrowserSupportBox onClose={this.dismissBrowserWarning} />
        ) : (
          <LoginBox
            currentCountry={currentCountry}
            countries={Object.values(countryDict)}
            loading={loading}
            loadingFacebookLoginButton={this.state.loadingFacebookLoginButton}
            loadingGoogleLoginButton={this.state.loadingGoogleLoginButton}
            apiError={apiError}
            onFBLogin={this.handleFBLogin}
            onGoogleLogin={this.handleGoogleLogin}
            onSubmit={this.handleSubmit}
            onCountryChange={this.changeCountry}
            forgotPasswordLink={
              <Link
                to="/forgot-password"
                data-testid="forgotPasswordLink"
                onClick={() => this.props.track('change_password_tapped')}
              >
                {t('Login.forgot_password')}
              </Link>
            }
            registerLink={
              <Trans i18nKey="Login.create_account">
                Dont have an account?
                <Link
                  to="/register"
                  data-testid="registerLink"
                  onClick={() => this.props.track('sign_up_clicked')}
                >
                  Create new account
                </Link>
              </Trans>
            }
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authenticated: checkAuth(state),
  referrer: getReferrer(state),
  token: getUser(state).access_token,
  loading: createLoadingSelector(['LOGIN', 'FB_LOGIN', 'GOOGLE_LOGIN'])(state),
  apiError: makeMessageSelector(['LOGIN', 'FB_LOGIN', 'GOOGLE_LOGIN'])(state),
  currentCountry: regionSelector.getCurrentCountry(state),
  language: regionSelector.getCurrentLocale(state),
  countryDict: regionSelector.getCountryDict(state),
  hasShownBrowserWarning: getHasShowBrowserWarning(state),
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, {
    loginSubmit,
    facebookLogin: fbLogin,
    cancelFacebookLogin: cancelFBLogin,
    googleLoginRequest: googleLogin,
    changeLocation: regionAction.changeLocation,
    disableBrowserWarning,
    logoutGenesys: genesysActions.logoutGenesys,
    track: sensorsTracking,
  })
)(Login);
