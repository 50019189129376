import moment from 'moment';
import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import FetcherFactory from '../FetcherFactory';
import FetcherLockService from '../FetcherLockService';
import UAPIFetcher from '../UAPIFetcher';

export default async function favoriteDriver(driverId) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  await FetcherLockService.langMutex.release();
  await FetcherLockService.accessTokenMutex.release();
  const {
    accessToken,
    profileType,
    cityId,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      driver_fid: driverId,
    }),
    city_id: cityId,
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
    os: 'web',
    _t: moment().unix(),
  };

  const { ret, data, msg } = await fetcher.get(
    '?_m=fleet_add_favorite_web',
    params
  );

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data;
}
