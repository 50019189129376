import genesys from './reducer';
import saga from './saga';
import * as actions from './actions';
import * as selectors from './selectors';

const genesysModule = {
  id: 'genesys',
  reducerMap: { genesys },
  sagas: [saga],
};

export { genesysModule as default, saga, actions, selectors };
