import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { noop } from 'utils/helpers';

import { dismissMessage, makeMessageSelector } from 'store/modules/message';

import { StyledAlert, TRANSITION_MS } from './styles';

const DEFAULT_TIMEOUT = 7000;

interface Props {
  messageSelector: string[];
  timeout?: number;
  dismissOnClick?: boolean;
  onDismiss?: () => void;
  show?: boolean;
}

const ModalAlert: React.FC<Props> = ({
  messageSelector,
  dismissOnClick = true,
  timeout = DEFAULT_TIMEOUT,
  onDismiss = noop,
  show = true,
}) => {
  const { t } = useTranslation();
  const { id, type, message } =
    useSelector(makeMessageSelector(messageSelector)) || {};
  const dispatch = useDispatch();

  const [adjustedShow, setAdjustedShow] = useState(show && !!message); // gives time to fade out instead of immediately hidden

  const timer = useRef<number>();
  const handleDismiss = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    setAdjustedShow(false); // start to fade out

    setTimeout(() => {
      dispatch(dismissMessage(id));
      onDismiss();
    }, TRANSITION_MS); // wait until fade out finishes before dismiss
  }, [dispatch, id, onDismiss]);

  useEffect(() => {
    if (show && message) {
      setAdjustedShow(true); // start to fade in
      timer.current = window.setTimeout(handleDismiss, timeout);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [show, handleDismiss, timeout, message, setAdjustedShow]);

  return (
    <StyledAlert
      type={type}
      show={adjustedShow}
      description={t(message)}
      {...(dismissOnClick && { onClick: handleDismiss })}
    />
  );
};

export default ModalAlert;
