import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Heading } from '@lalamove/karang';

import { black, darkGray } from 'styles/colors';
import Maps from 'components/Maps';
import { getServiceIcon } from 'store/modules/services';
import {
  getCurrentCountry,
  getCurrentLocale,
} from 'store/modules/region/selectors';
import { noop } from 'utils/helpers';
import { toLLMLower } from 'utils/locale';
import { locateDriver } from 'api/mobileAPI';

import defaultVehicleIcon from 'assets/generic.png';

const Wrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 1.5em 1em 1.5em 2.25em;
`;

// aspect ratio is 1:1
const OuterContainer = styled.div`
  position: relative;
  padding-top: 50%;
  margin: 0.5em 0;
`;

const InnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ShareLinkBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5em 1em;
  border: 1px solid ${darkGray};
`;

const ShareLink = styled.input`
  flex: 1 0 auto;
  border: 0;
  outline: none;
  color: ${black};
`;

class DriverMap extends Component {
  static defaultProps = {
    t: noop,
    serviceIcon: defaultVehicleIcon,
  };

  static propTypes = {
    t: func,
    orderId: string.isRequired,
    shareId: string.isRequired,
    waypoints: arrayOf(
      shape({
        id: string,
        name: string,
        lat: number,
        lng: number,
        placeId: string,
      })
    ).isRequired,
    serviceIcon: string,
    currentCountry: shape({
      id: string,
    }).isRequired,
    locale: string.isRequired,
  };

  state = {
    driver: null,
  };

  refUrl = React.createRef();

  componentDidMount() {
    this.fetchDriverLocation();
    this.interval = setInterval(() => {
      this.fetchDriverLocation();
    }, 5 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onCopyClick = () => {
    this.refUrl.current.select();
    document.execCommand('copy');
  };

  async fetchDriverLocation() {
    try {
      const { latitude, longitude, bearing } = await locateDriver(
        this.props.orderId
      );
      const location = { lat: latitude, lng: longitude, bearing };
      this.setState({ driver: location });
    } catch (e) {
      console.error('error in fetchDriverLocation', e); // eslint-disable-line no-console
    }
  }

  render() {
    const { driver } = this.state;
    const {
      serviceIcon,
      shareId,
      t,
      waypoints,
      currentCountry,
      locale,
    } = this.props;

    const llmLocale = toLLMLower(locale);

    const shareUrl = `${process.env.REACT_APP_SHARE_URL}/?${shareId}&lang=${llmLocale}`;

    return (
      <Wrapper>
        <Heading htmlTag="h3">
          {t('RecordPanel.heading_driver_location')}
        </Heading>
        <OuterContainer>
          <InnerContainer>
            <Maps
              locale={locale}
              region={currentCountry.id}
              zoomLevel={11}
              waypoints={waypoints}
              vehicle={
                driver && {
                  lat: driver.lat,
                  lng: driver.lng,
                  bearing: driver.bearing,
                  icon: serviceIcon,
                }
              }
              gestureHandling="cooperative"
            />
          </InnerContainer>
        </OuterContainer>
        <ShareLinkBox>
          <ShareLink value={shareUrl} readOnly ref={this.refUrl} />
          <Button onClick={this.onCopyClick}>
            {t('RecordPanel.button_copy')}
          </Button>
        </ShareLinkBox>
      </Wrapper>
    );
  }
}

const mapState = (state, { serviceTypeId }) => ({
  serviceIcon: getServiceIcon(state, serviceTypeId, 'aerial'),
  currentCountry: getCurrentCountry(state),
  locale: getCurrentLocale(state),
});

export default compose(withTranslation(), connect(mapState))(DriverMap);
