const { REACT_APP_DEBUG } = process.env;
export class LatLng {
  constructor(lat, lng) {
    this.lat = lat;
    this.lng = lng;
  }
}

// https://en.wikipedia.org/wiki/Haversine_formula
export const coordDistance = (p1, p2) => {
  const R = 6371e3; // earth radius in metres
  const toRad = Math.PI / 180;
  const lat1 = p1.lat * toRad;
  const lat2 = p2.lat * toRad;
  const latDiff = (p2.lat - p1.lat) * toRad;
  const lngDiff = (p2.lng - p1.lng) * toRad;

  const a =
    Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(lngDiff / 2) *
      Math.sin(lngDiff / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;
  return d;
};

export const getCurrentPosition = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      timeout: 5000,
    });
  });

export const getNearestLatLng = (latLngs, currentLatLng) => {
  let nearestDistance = Number.MAX_SAFE_INTEGER;
  if (!latLngs.length) {
    return null;
  }
  let nearestLatLng = latLngs[0];

  for (let i = 0; i < latLngs.length; i += 1) {
    let currentDistance = Number.MAX_SAFE_INTEGER;
    if (latLngs[i].lat && latLngs[i].lng) {
      currentDistance = coordDistance(
        currentLatLng,
        new LatLng(latLngs[i].lat, latLngs[i].lng)
      );
    }
    if (currentDistance < nearestDistance) {
      nearestDistance = currentDistance;
      nearestLatLng = latLngs[i];
    }
  }

  return nearestLatLng;
};

export const getNearestCountryFromGeolocation = async countries => {
  try {
    const t1 = performance.now();
    const { coords } = await getCurrentPosition();
    const latLng = new LatLng(coords.latitude, coords.longitude);
    const t2 = performance.now();
    REACT_APP_DEBUG && console.log('⏱ getCoordinates:', t2 - t1, coords); // eslint-disable-line no-console

    const { id: country } = getNearestLatLng(countries, latLng);
    const t3 = performance.now();
    REACT_APP_DEBUG && console.log('⏱ getNearestCountry:', t3 - t2, country); // eslint-disable-line no-console

    return { country, latLng };
  } catch (err) {
    console.log('getNearestCountryFromGeolocation error: ', err); // eslint-disable-line no-console
    return null;
  }
};
