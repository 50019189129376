import React, { Component } from 'react';
import styled from 'styled-components';
import { string, shape, func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { noop } from 'utils/helpers';
import { Button, Input } from '@lalamove/karang';
import validator from 'utils/validator';

import { DELIVERY_INFO_NAME_LIMIT } from 'store/modules/routing/config';

export const StyledForm = styled.form`
  margin-top: 1rem;
`;

const FormItem = styled.div`
  margin: 20px 0;
`;

const ScInput = styled(Input)`
  ${/* sc-selector */ FormItem} & {
    display: block;
  }
`;

const FormActionContainer = styled.div`
  text-align: right;
`;

const CancelButton = styled(Button)`
  margin-right: 1rem;
`;

export class Form extends Component {
  static defaultProps = {
    t: noop,
    name: '',
    phone: '',
    addressDetails: '',
    errors: {},
    onChange: noop,
    onError: noop,
    onCancel: noop,
    onSave: noop,
  };

  static propTypes = {
    t: func,
    name: string,
    phone: string,
    addressDetails: string,
    errors: shape({
      phone: string,
    }),
    onChange: func,
    onError: func,
    onCancel: func,
    onSave: func,
  };

  validateForm = ({ phone }) => {
    const { valid, message } = validator.phone(phone, true);
    return { valid: valid || phone === '', message };
  };

  handleFormSubmit = e => {
    e.preventDefault();
    const { onError, onSave } = this.props;
    const { phone } = this.props;
    const { valid, message } = this.validateForm({ phone });

    if (valid) {
      onSave({ ...this.props });
    } else {
      onError(message);
    }
  };

  handleBlur = () => {
    const { phone } = this.props;
    const { valid, message } = this.validateForm({ phone });
    if (valid) return;
    this.props.onError(message);
  };

  render() {
    const { t, onChange, onCancel, name, phone, addressDetails } = this.props;
    return (
      <StyledForm onSubmit={this.handleFormSubmit}>
        <FormItem>
          <ScInput
            name="name"
            value={name}
            label={t('PlaceOrder.placeholder_delivery_info_name')}
            onChange={onChange}
            maxLength={DELIVERY_INFO_NAME_LIMIT}
          />
        </FormItem>
        <FormItem>
          <ScInput
            name="phone"
            value={phone}
            label={t('PlaceOrder.placeholder_delivery_info_phone')}
            onChange={onChange}
            error={t(this.props.errors.phone)}
            onBlur={this.handleBlur}
          />
        </FormItem>
        <FormItem>
          <ScInput
            name="addressDetails"
            value={addressDetails}
            label={t('PlaceOrder.placeholder_delivery_info_addr')}
            onChange={onChange}
          />
        </FormItem>
        <FormActionContainer>
          <CancelButton variant="link" type="button" onClick={onCancel}>
            {t('Settings.button_cancel')}
          </CancelButton>
          <Button variant="primary" type="submit" solid>
            {t('Settings.button_save')}
          </Button>
        </FormActionContainer>
      </StyledForm>
    );
  }
}

export default withTranslation()(Form);
