import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

import { LalamoveApiError, parseInt10 } from 'utils/helpers';

// interface Request {
//   access_token: String;
//   client_id: Number | String;
//   country: String; // e.g. HK_HKG
//   offset: Number | String;
//   require: Number | String;
//   args: {
//     from_date: String, // YYYY-MM-DD
//     to_date: String,
//     filter_by_date: 1,
//     sort_key: String,
//     sort_order: String,
//     service_type_filter: [],
//     search_by: String[],
//     status_filter?: String,
//   };
// }

// interface Charge {
//   charge: string;
//   option: string;
// }

// interface Order {
//   id: String;
//   addressStr: String[];
//   canTrackDriver: Boolean;
//   cancel: Boolean;
//   canceled: Boolean;
//   // cancellationExpiredTime: null | Number;
//   charge_price: String;
//   city: String;
//   comment_by_user: String;
//   completed: Boolean;
//   cost: String;
//   coupon_held: Number;
//   created: Number; // unix time
//   driver: String;
//   driverRating: String;
//   driverRating_count: Number;
//   driver_called_user: 0 | 1;
//   driver_is_user: Boolean;
//   driver_photo: String;
//   drivertel: String;
//   extra: String;
//   fleet_state: String;
//   free_credit_held: Number;
//   history_canberated: Boolean;
//   history_type: String;
//   hour: String;
//   interest: String;
//   isRead: Boolean;
//   is_favorite: Boolean;
//   lastModify: String; // unix time
//   latlong: String; // a,b|c,d
//   name: String;
//   order_editable: Boolean;
//   order_edited: Boolean;
//   order_status: Number;
//   order_type_id: Number;
//   passengerseat: String;
//   path: String; // csv
//   paymentMethod: 'CREDIT' | 'CASH';
//   place_id: String; // csv
//   plate: String | null;
//   podDetails: null | { signedBy: string, signedTime: number };
//   prepayment_held: Number;
//   promo_easyvan: Number;
//   rank: String;
//   rating_by_user: String;
//   rating_to: String;
//   recipient_address: { block: String, floor: String, room: String }[];
//   recipient_info: { name: String, phone: String }[];
//   ref: String;
//   rejected: Boolean;
//   reroute: Boolean;
//   road_fee: String;
//   serivcer_name: String; // driver?
//   service_type: String;
//   servicer_id: Number;
//   shareByEmail: Boolean;
//   share_id: String;
//   show_completeOrderButton: Boolean;
//   special_req: {
//     option: string,
//     subname?: string,
//     price: number,
//   }[];
//   surcharge: {
//     DISCOUNT?: Charge[],
//     SURCHARGE?: Charge[],
//     USER_TIPS?: Charge[],
//   };
//   take_order_type_id: Number;
//   tel: String;
//   text: String;
//   time: Number; // unixtime
//   timeline: { timestamp: Number, action: String }[];
//   tips: String;
//   total_transport_price: String;
//   tunnel: String;
//   txInterestModify: Number; // unixtime
//   txModify: String; // unixtime
//   txRead: Boolean;
//   vehicle_type: null | string;
// }
// interface Response {
//   data: {
//     [idx: number]: Order,
//   };
//   footprint: String;
// }
/**
 * Fetches order history list
 * @param {number} current current page number
 * @param {number} max number of rows per page
 * @param {string} start filter start date (ISO YYYY-MM-DD)
 * @param {string} end filter end date (ISO YYYY-MM-DD)
 * @param {string} sort sorting criteria, e.g. 'ORDER_DATE'
 * @param {string} order sorting direction, e.g. 'desc'
 * @param {string} search search keyword
 * @param {array<string>} status status filters , e.g. ['ASSIGNING', 'ONGOING', ...]
 * @return {promise} ...
 * @throws ERROR_INVALID_SESSION, ERROR_INVALID_PARAMETERS, ...
 */
export default async function fetchOrderHistoryList({
  current = 1,
  max = 10,
  start = new Date().toISOString().split('T')[0],
  end = new Date().toISOString().split('T')[0],
  sort = '',
  order = '',
  search = '',
  status = null,
}) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, city } = await fetcher.getState();

  const params = {
    access_token: accessToken,
    client_id: clientId,
    country: city,
    offset: (current - 1) * max,
    require: max,
    args: JSON.stringify({
      from_date: start,
      to_date: end,
      filter_by_date: 1,
      sort_key: sort,
      sort_order: order,
      service_type_filter: [], // always empty for new webapp
      search_by: search ? search.split(',') : [],
      ...(status && { status_filter: status }),
    }),
  };
  const { success, data, record_count: recordCount, code } = await fetcher.get(
    'vanhistorylist',
    params
  );

  if (!success) throw new LalamoveApiError(code);
  const { filter: __, ...records } = data;
  return {
    data,
    records: Object.values(records).map(parseRecord),
    recordCount,
  };
}

function parseRecord(record) {
  const specialRequests = [];
  const subRequests = {};
  record.special_req.forEach(item => {
    const { option, subname } = item;
    const specialRequestKey = `${record.service_type}-${option}`;
    specialRequests.push(specialRequestKey);
    if (subname)
      subRequests[specialRequestKey] = `${specialRequestKey}-${subname}`;
  });
  const { SURCHARGE, DISCOUNT, USER_TIPS } = record.surcharge;
  return {
    order: {
      id: record.id,
      refId: record.ref,
      shareId: record.share_id,
      status: parseInt10(record.order_status),
      city: record.city,
      deliveryDatetime: new Date(record.time * 1000).toISOString(), // unix to ISO
      creationDatetime: new Date(record.created * 1000).toISOString(),
      editRevision: '', // not available from old api
      driverId: record.servicer_id,
      canShowDriverInfo: !!record.drivertel,
      creatorName: record.name,
      creatorPhone: record.tel,
      noteToDriver: record.text,
      serviceTypeId: record.service_type,
      paymentMethod: record.paymentMethod,
      podSignedBy: record.podDetails ? record.podDetails.signedBy : null, // proof of delivery
      podDatetime: record.podDetails
        ? new Date(record.podDetails.signedTime * 1000).toISOString()
        : null,
      userRating: parseInt10(record.rating_by_user),
      // vanHistoryList is not returning these 2 items yet, mocked for testing purposes:
      edited: record.order_edited !== undefined ? record.order_edited : false,
      editable:
        record.order_editable !== undefined ? record.order_editable : true,
      specialRequests,
      subRequests,
    },
    route: {
      pathList: record.path.split(','),
      latlngList: record.latlong.split(','),
      placeIdList: record.place_id.split(','),
      addressList: record.addressStr,
      recipientContactList: record.recipient_info || record.contactPersons,
      recipientAddressList: record.recipient_address || record.addressDetails,
    },
    price: {
      fee: parseInt10(record.road_fee),
      services: record.special_req.reduce((sum, item) => sum + item.price, 0),
      surcharge: SURCHARGE
        ? SURCHARGE.reduce((sum, item) => sum + parseInt10(item.charge), 0)
        : 0,
      discount: DISCOUNT
        ? DISCOUNT.reduce((sum, item) => sum + parseInt10(item.charge), 0)
        : 0,
      tips: USER_TIPS
        ? USER_TIPS.reduce((sum, item) => sum + parseInt10(item.charge), 0)
        : parseInt10(record.tips),
      rewards: -record.free_credit_held,
    },
    driver: {
      id: record.servicer_id,
      name: record.driver,
      phone: record.drivertel,
      vehicleTypeId: record.vehicle_type,
      licensePlate: record.plate,
      avgRating: parseFloat(record.driverRating),
      photoUri: record.driver_photo,
      canTrackDriver: record.canTrackDriver,
      favorited: record.fleet_state === 'FAVOURITED', // note the British spelling in API response
      banned: record.fleet_state === 'BANNED',
    },
  };
}
