import { Button } from '@lalamove/karang';
import styled from 'styled-components';
import { transparentize } from 'polished';

import { orange, gray, white } from 'styles/colors';
import { HEADER } from 'styles/zIndex';

export const HEADER_HEIGHT = 4;

export const HeaderContainer = styled.div`
  position: fixed;
  z-index: ${HEADER};
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: ${HEADER_HEIGHT}rem;
  border-bottom: 1px solid ${orange};
  background: ${white};

  & + * {
    height: calc(100% - ${HEADER_HEIGHT}rem);
    margin-top: ${HEADER_HEIGHT}rem;
  }
`;

export const LeftContainer = styled.ul`
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  height: 100%;
  padding: 0;
  margin: 0;
`;

export const RightContainer = styled.div`
  display: inline-flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding: 0 10px;
`;

export const ListItem = styled.li`
  display: flex;
  list-style: none;
  width: 25%;
  max-width: 195px;
  height: 100%;
  cursor: pointer;
`;

export const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 1em;
  border-bottom: 2px solid transparent;
  color: rgba(180, 180, 180, 1);
  font-size: 1em;
  text-align: center;
  &:hover {
    box-shadow: none;
    color: ${transparentize(0.3, orange)};
  }
  &.active {
    border-bottom: 2px solid ${orange};
    color: ${orange};
    font-weight: bold;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  padding: 0 1em;
  border: 0;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  margin: 0;
  color: ${gray};
  box-shadow: none !important;
  &:hover {
    background-color: transparent !important;
    box-shadow: none;
    color: ${transparentize(0.3, orange)};
  }
  &.active {
    border-bottom: 2px solid ${orange};
    color: ${orange};
    font-weight: bold;
  }
`;
