/* eslint-disable import/prefer-default-export */

// extract country and IATA city code
export const matchLLMLocation = location => {
  if (!location) {
    return null;
  }
  const matched = /^([A-Z]{2})($|_([A-Z]{3}$))/.exec(location);
  if (!matched) {
    return null;
  }
  const [, country, , code] = matched;
  return [country, code];
};
