import styled from 'styled-components';
import { HEADER_HEIGHT } from 'views/Header/style';
import { whenMobile, whenDesktop } from 'components/MediaQuery';
import { fadeTransition } from 'styles/transitions';

export const Container = styled.div`
  display: flex;
  flex: 1;

  ${whenDesktop} {
    max-height: calc(100vh - ${HEADER_HEIGHT}rem);
  }

  ${whenMobile} {
    flex-flow: column;
  }

  ${fadeTransition}
`;

export const Left = styled.main`
  position: relative;
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  min-width: 36rem;
  max-width: 48rem;
  border-right: 1px solid #b4b4b4;
`;

export const LeftContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
`;

export const Right = styled.aside`
  flex: 1 1 auto;
`;
