import FetcherFactory from 'api/FetcherFactory';
import RestAPIFetcher from 'api/RestAPIFetcher';
import log from 'api/log';
import {
  getCurrentCity,
  getCurrentCountryCode,
  getCurrentLocale,
} from 'store/modules/region/selectors';
import { getSessionToken } from 'store/modules/routing';
import { LalamoveApiError } from 'utils/helpers';
import { toIETF } from 'utils/locale';

// interface Request {
//   input: string;
//   components?: string;
//   location?: string;
//   radius?: number;
//   language?: string;
//   sessiontoken?: string;
// }

// interface Response {
//   predictions: {
//     descriptions: string;
//     matched_substrings: { length: number; offset: number; }[];
//     place_id: string;
//     structured_formatting: {
//       main_text: string;
//       main_text_matched_substrings: { length: number; offset: number; }[];
//       secondary_text: string;
//     };
//     terms: { value: string; offset: number; }[];
//     types: string[];
//   }[];
//   session: {
//     expiry: string;
//     token: string;
//   };
//   status: string;
// }

export default async function getPlaceAutocomplete(address = '') {
  if (address === '') return [];

  const fetcher = FetcherFactory.make(RestAPIFetcher);
  const state = await fetcher.getRootState();
  const countryCode = getCurrentCountryCode(state);
  const { lat, lng, searchRadius } = getCurrentCity(state);
  const language = getCurrentLocale(state);
  const sessiontoken = getSessionToken(state);

  const request = {
    input: address,
    components: `country:${countryCode}`,
    location: `${lat},${lng}`,
    radius: searchRadius,
    language: toIETF(language),
    ...(sessiontoken && { sessiontoken }),
  };

  const { status, predictions, session } = await fetcher.get(
    'maps/place/autocomplete',
    request
  );
  if (status !== 'OK') {
    log.info('AutocompleteService.getPlacePredictions', {
      category: 'google-maps',
      status,
      response: `JSON: ${JSON.stringify(predictions)}`, // prevent log service parsing JSON string as object
    });
    throw new LalamoveApiError(status);
  }

  return { predictions, sessiontoken: session.token };
}
