/* eslint-disable no-unused-vars */
import {
  put,
  select,
  throttle,
  takeEvery,
  call,
  take,
} from 'redux-saga/effects';
import {
  MAX_NUM_WAYPOINTS,
  DRAFT_ORDER_ID,
  DELIVERY_INFO_NAME_LIMIT,
} from 'interfaces/global/config';
import {
  FETCH_SERVICES_SUCCESS,
  SET_SERVICE,
} from 'interfaces/global/store/modules/services/actions';
import { getPlaceDetails, getDirections } from 'api/uAPI';
import { GeocoderStatus, bulkGeocode } from 'api/uAPI/geocode';
import validateServiceArea from 'api/uAPI/validateServiceArea';
import {
  getCurrentCity,
  getCurrentCountryCode,
} from 'store/modules/region/selectors';
import { DISMISS_MESSAGE } from 'store/modules/message';
import {
  getSelectedService,
  getServiceIdByOrderId,
  getServices,
} from 'interfaces/global/store/modules/services/selectors';
import { getPrice } from 'interfaces/global/store/modules/pricing/selectors';
import _some from 'lodash/some';
import _isEqual from 'lodash/isEqual';
import { FETCH_PRICE_SUCCESS } from 'interfaces/global/store/modules/pricing/actions';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { createLoadingSelector } from 'store/modules/loading';
import {
  getOrderIdByWayptId,
  getShouldOptimize,
  getFilledWaypts,
  getWaypoints,
  getAreaValidationStatus,
} from './selectors';

// action-types
import {
  ROUTE_REARRANGE,
  WAYPOINT_REMOVE,
  WAYPOINT_UPDATE,
  ROUTE_OPTIMIZE_TOGGLE,
  ROUTE_WAYPOINTS_SET,
  IMPORT_REQUEST,
  IMPORT_SUCCESS,
  IMPORT_FAILURE,
  VALIDATE_SERVICE_AREA_REQUEST,
  VALIDATE_SERVICE_AREA_SUCCESS,
  VALIDATE_SERVICE_AREA_FAILURE,
  GOOGLE_DIRECTION_RESULT_RECEIVED,
  NO_SERVICE_FOUND_FAILURE,
  setWaypointsOrder,
  newWaypt,
  updateWaypt,
  removeWaypt,
  updateDeliveryInfo,
  validateArea,
} from './actions';
import {
  ERROR_INVALID_ADDR,
  ERROR_IMPORT_FAILED,
  ERROR_IMPORT_INVALID_PHONE_NUMBER,
  ERROR_IMPORT_MIN,
  ERROR_IMPORT_MAX,
  ERROR_INVALID_PICKUP,
  ERROR_INVALID_DROPOFF,
  ERROR_INVALID_SERVICE_AREA,
  ImportError,
} from './errors';

export const WaypointCreationMethods = {
  imported: 'import_address',
  map: 'map',
  search: 'search',
  history: 'recent',
};

// side effects
export function* showPlaceOrderAgainImportErrorMessage() {
  yield put({
    type: NO_SERVICE_FOUND_FAILURE,
    meta: {
      type: 'error',
      title: '',
      message: 'PlaceOrder.error_import_services_changed',
    },
  });
}

export function* waitFetchServicesSuccess() {
  const isFetchingServices = yield select(
    createLoadingSelector(['FETCH_SERVICES'])
  );
  const isServicesEmpty = (yield select(getServices)).length === 0;
  if (isFetchingServices || isServicesEmpty) {
    yield take(FETCH_SERVICES_SUCCESS);
  }
}

export function* watchWaypointUpdate(payload) {
  if ((!payload.lat || !payload.lng) && payload.placeId) {
    try {
      const { location } = yield call(getPlaceDetails, payload.placeId);

      yield put(
        updateWaypt({
          lat: location.lat,
          lng: location.lng,
          id: payload.id,
          cityId: (yield select(getCurrentCity)).cityId,
        })
      );
    } catch (e) {
      // handle error
    }
  }

  yield call(waitFetchServicesSuccess);
  yield call(trackAddressSelected, payload);
}

export function* trackAddressSelected(payload) {
  const filledWaypoints = yield select(getFilledWaypts);
  const isValidServiceArea = yield select(getAreaValidationStatus);

  if (isValidServiceArea && filledWaypoints.length >= 2)
    yield take(FETCH_PRICE_SUCCESS);

  const waypoints = yield select(getWaypoints);
  const service = yield select(getSelectedService);
  const price = yield select(getPrice);

  const waypointIndex = waypoints.findIndex(point => point.id === payload.id);

  yield put(
    track('address_selected', {
      method: payload.method,
      stop_type: waypointIndex === 0 ? 'pick_up' : 'drop_off',
      vehicle_type: service.name,
      order_amount: price.total.toNumber(),
    })
  );
}

export function* trackStopRemoved() {
  const isValidServiceArea = yield select(getAreaValidationStatus);

  if (isValidServiceArea) {
    yield take(FETCH_PRICE_SUCCESS);

    const filledWaypoints = yield select(getFilledWaypts);
    const service = yield select(getSelectedService);
    const price = yield select(getPrice);

    yield put(
      track('stop_removed', {
        stop_total: filledWaypoints.length,
        vehicle_type: service.name,
        order_amount: price.total.toNumber(),
      })
    );
  }
}

export function getAreaValidationError(locations, unavailablePoints) {
  if (unavailablePoints && unavailablePoints.length) {
    if (unavailablePoints.length > 1) {
      if (unavailablePoints[0] === 0) {
        return ERROR_INVALID_SERVICE_AREA;
      }
      return ERROR_INVALID_DROPOFF;
    }
    if (unavailablePoints[0] === 0) {
      return ERROR_INVALID_PICKUP;
    }
    return ERROR_INVALID_DROPOFF;
  }
  if (locations.length > 1 && locations[0].placeType === 0) {
    return ERROR_INVALID_SERVICE_AREA;
  }
  if (locations.length > 1 && locations[0].placeType !== 0) {
    return ERROR_INVALID_DROPOFF;
  }
  if (locations[0].placeType === 0) {
    return ERROR_INVALID_PICKUP;
  }
  return ERROR_INVALID_DROPOFF;
}

function getPosition(type) {
  if (type === 0) {
    return 'Start';
  }
  if (type === 1) {
    return 'Finish';
  }
  return 'Middle';
}

function getPlaceTypeByIndex(index, length) {
  if (index === 0) {
    return 0;
  }
  if (index === length - 1) {
    return 1;
  }
  return -1;
}

// if user has entered  ‘n’ number of stops (which is the locations parameter), for each entered stop we need to traverse the areas array which is coming in response and find out the desired object if any
function matchServiceAreas(locations, areas, serviceType) {
  for (let i = 0; i < locations.length; i += 1) {
    if (
      !_some(
        areas,
        area =>
          area.latlon === `${locations[i].lat},${locations[i].lng}` &&
          area[`allow${getPosition(locations[i].placeType)}`] &&
          (area.orderVehicleId === 'DEFAULT' ||
            area.orderVehicleId === serviceType)
      )
    ) {
      return false;
    }
  }
  return true;
}

export function* validateAreas({ payload: { locations, orderId } }) {
  try {
    const service = yield select(getSelectedService);
    if (!service || !locations.length) {
      return;
    }

    const { id: serviceType } = service;
    const locationsWithPlaceType = locations.map((stop, index) => ({
      ...stop,
      placeType: getPlaceTypeByIndex(index, locations.length),
      lat: Math.floor(stop.lat * 1000000) / 1000000,
      lng: Math.floor(stop.lng * 1000000) / 1000000,
    }));

    let coordinates = locationsWithPlaceType.map(
      location => `${location.lat},${location.lng}`
    );
    coordinates = coordinates.join(';');

    const { count, areas, unavailablePoints } = yield call(
      validateServiceArea,
      {
        coordinates,
        serviceType,
      }
    );

    if (
      unavailablePoints.length ||
      !count ||
      !matchServiceAreas(locationsWithPlaceType, areas, serviceType)
    ) {
      yield put({
        type: DISMISS_MESSAGE,
        id: 'IMPORT',
      });
      yield put({
        type: VALIDATE_SERVICE_AREA_FAILURE,
        meta: {
          type: 'error',
          title: '',
          message: getAreaValidationError(
            locationsWithPlaceType,
            unavailablePoints
          ),
        },
      });
      return;
    }

    yield put({
      type: VALIDATE_SERVICE_AREA_SUCCESS,
      orderId,
    });
  } catch (e) {
    yield put({
      type: VALIDATE_SERVICE_AREA_FAILURE,
      meta: {
        type: 'error',
        title: '',
        message: e.message,
      },
    });
  }
}

export function* onEdits({ type, orderId, id: wayptId }) {
  yield call(waitFetchServicesSuccess);
  let id;
  if (type.startsWith('ROUTE_') || type === WAYPOINT_REMOVE) {
    id = orderId;
  } else if (type.startsWith('WAYPOINT_')) {
    id = yield select(getOrderIdByWayptId, wayptId);
  } else {
    id = DRAFT_ORDER_ID; // other actions are being triggered in place order only
  }

  if (type !== ROUTE_OPTIMIZE_TOGGLE) {
    const locations = yield select(getFilledWaypts, id);
    yield put(validateArea({ locations, orderId: id }));
  }

  // return immediately as this action was dispatched only to validate areas
  if (type === ROUTE_WAYPOINTS_SET) {
    return;
  }

  const shouldOptimize = yield select(getShouldOptimize, id);

  if (type === ROUTE_OPTIMIZE_TOGGLE && !shouldOptimize) {
    yield put(setWaypointsOrder(undefined, id)); // undo and revert route order
  }

  const waypoints = yield select(getFilledWaypts, id);
  if (waypoints.length > 2 && shouldOptimize) {
    const origin = waypoints[0];
    const stopovers = waypoints.slice(1, -1);
    const destination = waypoints[waypoints.length - 1];

    const serviceId = yield select(getServiceIdByOrderId, id);
    const nextShouldOptimize = yield select(getShouldOptimize, id);
    try {
      const result = yield call(getDirections, {
        origin,
        destination,
        stopovers,
        serviceType: serviceId,
        optimize: nextShouldOptimize,
      });

      yield put({
        type: GOOGLE_DIRECTION_RESULT_RECEIVED,
        result,
        orderId: id,
      });

      const orderMapping = result.routes[0].waypointOrder;
      yield put(setWaypointsOrder(orderMapping, id));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`googleDirectionsSaga error fetching directions`, e);
    }
  }
}

export function parseBulkGeocodeResults(bulkGeocodeResults, cityId) {
  return bulkGeocodeResults.map(bulkGeocodeResult => {
    const { results, status } = bulkGeocodeResult;

    if (status !== GeocoderStatus.OK) {
      return {};
    }
    const result = results;

    return {
      placeId: result.placeId || '',
      name: result.formatted_address,
      lat: result.location.lat,
      lng: result.location.lng,
      cityId,
    };
  });
}

export function* doImport(wayptsToImport, orderId = DRAFT_ORDER_ID) {
  const method = WaypointCreationMethods.imported; // for tracking

  // waypt in wayptsToImport has to have either name or placeId
  // return array of updated ids
  // get waypoints from store
  const { routing } = yield select();
  const existingWayptIds = routing.order[orderId]
    ? routing.order[orderId].sequence.slice()
    : [];

  const ids = [];

  // loop waypts
  for (let i = 0; i < existingWayptIds.length; i += 1) {
    // pop first element from geocoded_waypoints
    if (wayptsToImport.length > 0) {
      const {
        placeId,
        name,
        lat,
        lng,
        cityId,
        address,
      } = wayptsToImport.shift();

      const id = existingWayptIds[i];

      if (placeId) {
        // update waypt with placeId
        yield put(
          updateWaypt({
            id,
            placeId,
            name,
            lat,
            lng,
            method,
            cityId,
            address,
          })
        );
      } else {
        yield put(
          updateWaypt({
            id,
            placeId: '',
            name,
            lat: null,
            lng: null,
            method,
            cityId,
            address: '',
          })
        );
      }
    } else {
      // remove left overs
      yield put(removeWaypt(existingWayptIds[i]));
    }
  }

  // TODO: should combine the 2 cases
  // if wayptsToImport still has item
  for (let i = 0; i < wayptsToImport.length; i += 1) {
    const { placeId, name, lat, lng, cityId, address } = wayptsToImport[i];

    const action = newWaypt(orderId);
    yield put(action);
    const { id } = action;

    if (placeId) {
      // update waypt with placeId
      yield put(
        updateWaypt({ id, placeId, name, lat, lng, method, cityId, address })
      );
    } else {
      yield put(
        updateWaypt({
          id,
          placeId: '',
          name,
          lat: null,
          lng: null,
          method,
          cityId,
          address: '',
        })
      );
    }

    ids.push(id);
  }

  return [...existingWayptIds, ...ids];
}

export function* importDeliveryInfos(csvData, waypointIds) {
  const idsContainsInvalidPhone = [];
  for (let i = 0; i < csvData.length; i += 1) {
    const id = waypointIds[i];
    const { name, phone, addressDetails } = csvData[i];
    // const { valid } = validator.phone(phone, true);
    if (phone) {
      const valid = phone.length >= 2 && phone.length <= 255;
      if (!valid) {
        idsContainsInvalidPhone.push(id);
      }
    }

    yield put(
      updateDeliveryInfo(id, {
        name: name.slice(0, DELIVERY_INFO_NAME_LIMIT),
        phone: (phone || '').trim(),
        addressDetails,
      })
    );
  }
  return idsContainsInvalidPhone;
}

export function* onFileUpload({ payload }) {
  // filter out instructions in the 1st line and empty addresses:
  const csvData = payload.slice(1).filter(item => item.address !== '');
  const region = yield select(getCurrentCountryCode);
  const { cityId } = yield select(getCurrentCity);

  try {
    if (csvData.length < 2) throw new ImportError('MIN');
    if (csvData.length > MAX_NUM_WAYPOINTS) throw new ImportError('MAX');

    const result = yield call(
      bulkGeocode,
      csvData.map(data => data.address),
      {
        region,
      }
    );

    const updatedWayptIds = yield call(
      doImport,
      parseBulkGeocodeResults(result.results, cityId),
      DRAFT_ORDER_ID
    );

    const idsContainsInvalidPhone = yield call(
      importDeliveryInfos,
      csvData,
      updatedWayptIds
    );

    if (idsContainsInvalidPhone.length > 0) {
      throw new ImportError('INVALID_PHONE', idsContainsInvalidPhone);
    }

    yield put({
      type: IMPORT_SUCCESS,
      meta: {
        type: 'success',
        title: 'PlaceOrder.title_import_success',
        message: 'PlaceOrder.msg_import_success',
      },
    });
  } catch (e) {
    console.error('error getting locations', e); // eslint-disable-line no-console
    if (e instanceof ImportError) {
      switch (e.message) {
        case 'INVALID_PHONE': {
          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'warning',
              title: 'PlaceOrder.title_import_failed_invalid_phone_number',
              message: ERROR_IMPORT_INVALID_PHONE_NUMBER,
              // data: e.data,
            },
          });
          break;
        }
        case 'MIN': {
          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'error',
              title: ERROR_IMPORT_FAILED,
              message: ERROR_IMPORT_MIN,
            },
          });
          break;
        }
        case 'MAX': {
          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'error',
              title: ERROR_IMPORT_FAILED,
              message: ERROR_IMPORT_MAX,
            },
          });
          break;
        }
        default: {
          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'error',
              message: ERROR_IMPORT_FAILED,
            },
          });
        }
      }
    } else {
      switch (e.status) {
        // bulk geocode error
        case GeocoderStatus.NOT_FOUND: {
          // remove non-OK stops and retry
          const indexes = []; // indexes with non-OK
          const stopsToRetry = e.results
            .map((__, index) => csvData[index])
            .filter((__, index) => {
              const isOk = e.results[index].status === GeocoderStatus.OK;
              !isOk && indexes.push(index); // remember not found index
              return isOk;
            });

          let wayptsToImport = [];

          if (stopsToRetry.length > 0) {
            const result = yield call(
              bulkGeocode,
              stopsToRetry.map(data => data.address),
              {
                region,
              }
            );

            // put notfound waypt back
            wayptsToImport = parseBulkGeocodeResults(result.results, cityId);
          }

          indexes.forEach(i => {
            // add id back to order
            wayptsToImport.splice(i, 0, {
              name: csvData[i].address,
            });
          });

          const updatedWayptIds = yield call(
            doImport,
            wayptsToImport,
            DRAFT_ORDER_ID
          );

          yield call(importDeliveryInfos, csvData, updatedWayptIds);

          const idsContainsInvalidAddress = updatedWayptIds.filter((__, i) =>
            indexes.includes(i)
          );

          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'error',
              title: ERROR_IMPORT_FAILED,
              message: ERROR_INVALID_ADDR,
              ids: idsContainsInvalidAddress,
            },
          });
          break;
        }
        default: {
          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'error',
              message: ERROR_IMPORT_FAILED,
            },
          });
        }
      }
    }
  }
}

export function* watchAllEdits() {
  yield throttle(
    500,
    [
      ROUTE_REARRANGE,
      WAYPOINT_REMOVE,
      WAYPOINT_UPDATE,
      ROUTE_OPTIMIZE_TOGGLE,
      SET_SERVICE,
      // Calling this action just to validate service areas
      ROUTE_WAYPOINTS_SET,
    ],
    onEdits
  );
}

export default function* routingSaga() {
  yield takeEvery(IMPORT_REQUEST, onFileUpload);
  yield takeEvery(WAYPOINT_UPDATE, watchWaypointUpdate);
  yield takeEvery(VALIDATE_SERVICE_AREA_REQUEST, validateAreas);
  yield takeEvery(WAYPOINT_REMOVE, trackStopRemoved);
  yield watchAllEdits();
}
