/* eslint-disable lines-between-class-members */
import { zip } from 'utils/helpers';
import StatusProgress from './StatusProgress';

// interface DeliveryStruct {
//   startAddressDistrict: string;
//   startAddressLanguage: string;
//   startAddress: string;
//   startLatitude: string;
//   startLongitude: string;

//   startAddressBlock?: string;
//   startAddressFloor?: string;
//   startAddressRoom?: string;
//   startContactName?: string;
//   startContactPhone?: string;

//   endAddressDistrict: string;
//   endAddressLanguage: string;
//   endAddress: string;
//   endLatitude: string;
//   endLongitude: string;

//   endAddressBlock?: string;
//   endAddressFloor?: string;
//   endAddressRoom?: string;
//   endContactName?: string;
//   endContactPhone?: string;

//   isProofOfPickupRequired?: boolean;
//   isProofOfDeliveryRequired?: boolean;

//   statusProgress?: StatusProgress[];
// }

// kraken interface
export default class Delivery {
  startAddressDistrict = '';
  startAddressLanguage = '';
  startAddress = '';
  startLatitude = '';
  startLongitude = '';
  startAddressGooglePlaceId = '';

  startAddressBlock = null;
  startAddressFloor = null;
  startAddressRoom = null;
  startContactName = null;
  startContactPhone = null;

  endAddressDistrict = '';
  endAddressLanguage = '';
  endAddress = '';
  endLatitude = '';
  endLongitude = '';
  endAddressGooglePlaceId = '';

  endAddressBlock = null;
  endAddressFloor = null;
  endAddressRoom = null;
  endContactName = null;
  endContactPhone = null;

  isProofOfPickupRequired = null;
  isProofOfDeliveryRequired = null;

  statusProgress = null;

  constructor(d = {}) {
    Object.assign(this, d);
    this.statusProgress =
      d.statusProgress && d.statusProgress.map(StatusProgress.New);
  }

  static New(d) {
    return new Delivery(d);
  }

  toJSON() {
    return Object.entries(this)
      .filter(([k, val]) => val !== null)
      .reduce((memo, [key, val]) => ({ ...memo, [key]: val }), {});
  }
}

export const parseGeo = num => (num > 0 ? `+${num}` : num.toString());

// Assuming locale is the same for all waypoints
export function newFromWayPoints(
  wayPoints,
  isProofOfDeliveryRequired = false,
  locale = 'en'
) {
  const [, ...tail] = wayPoints;
  return zip(wayPoints, tail).map(
    ([start, end]) =>
      new Delivery({
        startLatitude: parseGeo(start.lat),
        startLongitude: parseGeo(start.lng),
        startAddress: start.name,
        startAddressLanguage: locale,
        startAddressGooglePlaceId: start.placeId,
        endLatitude: parseGeo(end.lat),
        endLongitude: parseGeo(end.lng),
        endAddress: end.name,
        endAddressLanguage: locale,
        endAddressGooglePlaceId: end.placeId,
        isProofOfDeliveryRequired,
        isProofOfPickupRequired: false,
      })
  );
}
