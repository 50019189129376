import React, { Component } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { initTopup } from 'store/modules/wallet';
import { closeDialog } from 'store/modules/ui';

import Dialog from 'components/Dialog';
import { noop } from 'utils/helpers';

class WalletLowBalanceDialog extends Component {
  static defaultProps = {
    t: noop,
    onClose: noop,
    initTopup: noop,
  };

  static propTypes = {
    t: func,
    onClose: func,
    initTopup: func,
  };

  handleTopupClick = () => {
    this.props.onClose();
    this.props.initTopup('checkout_banner'); // pass action origin for tracking
  };

  render() {
    const { t, onClose, ...props } = this.props;
    return (
      <Dialog
        title={t('Checkout.dialog_title_low_balance')}
        subtitle={t('Checkout.dialog_text_low_balance')}
        primaryButtonText={t('Checkout.dialog_button_topup')}
        primaryAction={this.handleTopupClick}
        secondaryButtonText={t('Checkout.dialog_button_cash')}
        secondaryAction={onClose}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={false}
        {...props}
      />
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    initTopup,
    onClose: closeDialog,
  })
)(WalletLowBalanceDialog);
