/* global google */
import React, { Component } from 'react';
import styled from 'styled-components';
import { arrayOf, bool, func, number, oneOf, shape, string } from 'prop-types';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import GoogleMapReact from 'google-map-react';

import mapStyles from 'config/mapstyles.json';
import { orange } from 'styles/colors';
import config from 'config/google';
import { reverseGeocode, GeocoderStatus } from 'api/restAPI/geocode';

import { noop } from 'utils/helpers';

import defaultVehicleIcon from 'assets/generic.png';
import Marker from './components/Marker';

const MapsContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const VehicleIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%) rotate(${({ bearing }) => bearing}deg);
  cursor: pointer;
`;

let mapOptions = {
  styles: mapStyles,
  streetViewControl: false,
  fullscreenControl: false,
  gestureHandling: 'auto',
  // minZoom: 10,
  // maxZoom: 18,
};

const routeOptions = {
  polylineOptions: {
    clickable: false,
    strokeColor: orange,
    strokeOpacity: 0.6,
    strokeWeight: 3,
  },
  suppressInfoWindows: true,
  preserveViewport: false,
  suppressMarkers: true,
};

let MapsAPI;
let MapObj;
let MapBounds;
let MapPolyline;

const getGeocodeDetail = ({ latlng, language }) =>
  reverseGeocode({ latlng, language }).then(data => {
    if (data.status !== GeocoderStatus.OK) {
      throw data.status;
    }
    return data.results;
  });

const renderMarker = ({ marker, index, length }) => {
  const { id, lat, lng, draggable } = marker;
  let type;
  switch (index) {
    case 0:
      type = 'pickup';
      break;
    case length - 1:
      type = 'pinFill';
      break;
    default:
      type = 'midstop';
  }

  return (
    <Marker
      id={id}
      type={type}
      key={id}
      lat={lat}
      lng={lng}
      order={index + 1}
      draggable={draggable !== undefined ? draggable : true}
    />
  );
};

renderMarker.propTypes = {
  marker: shape({
    id: string.isRequired,
    lat: number.isRequired,
    lng: number.isRequired,
    draggable: bool,
  }).isRequired,
  index: number.isRequired,
  length: number.isRequired,
};

class Maps extends Component {
  static propTypes = {
    directions: shape({
      routes: arrayOf(
        shape({
          waypoint_order: arrayOf(number),
        })
      ),
    }),
    waypoints: arrayOf(
      shape({
        lat: number,
        lng: number,
        id: string.isRequired,
      })
    ),
    vehicle: shape({
      lat: number,
      lng: number,
      bearing: number,
      icon: string,
    }),
    zoomLevel: number.isRequired,
    center: shape({
      lat: number,
      lng: number,
    }),
    draggableMarkers: bool,
    // https://developers.google.com/maps/documentation/javascript/interaction#gestureHandling
    gestureHandling: oneOf(['auto', 'cooperative', 'greedy', 'none']),
    onDragEnd: func,
    onGoogleApiLoaded: func,
  };

  static defaultProps = {
    directions: null,
    waypoints: [],
    vehicle: null,
    draggableMarkers: false,
    gestureHandling: 'auto',
    onDragEnd: noop,
    onGoogleApiLoaded: noop,
    center: { lat: 22.320138, lng: 114.1740319 },
  };

  state = {
    markers: this.props.waypoints,
    draggable: true,
    googleApiLoaded: false,
  };

  componentDidUpdate(prevProps) {
    if (!this.state.googleApiLoaded) return;
    const { waypoints, directions, vehicle } = this.props;
    if (!_isEqual(prevProps.waypoints, waypoints)) {
      this.setState({ markers: waypoints }); // eslint-disable-line react/no-did-update-set-state
    }

    if (!_isEqual(prevProps.directions, directions)) {
      this.updateDirectionsDisplay();
    }

    if (!prevProps.vehicle && vehicle) {
      const latlng = new MapsAPI.LatLng(vehicle.lat, vehicle.lng);
      MapBounds.extend(latlng);
      MapObj.fitBounds(MapBounds);
    }
  }

  onGoogleApiLoaded = ({ map, maps }) => {
    this.setState({ googleApiLoaded: true }, () => {
      MapsAPI = maps;
      MapObj = map;

      MapPolyline = new maps.Polyline();
      MapPolyline.setMap(map);
      MapPolyline.setOptions(routeOptions.polylineOptions);

      MapBounds = new MapsAPI.LatLngBounds();
      this.props.waypoints.forEach(waypoint => {
        const latlng = new MapsAPI.LatLng(waypoint.lat, waypoint.lng);
        MapBounds.extend(latlng);
        MapObj.fitBounds(MapBounds);
        MapObj.setCenter(MapBounds.getCenter());
      });

      if (this.props.directions) {
        this.updateDirectionsDisplay();
      }

      // after googleAPILoaded
      mapOptions = {
        ...mapOptions,
        zoomControlOptions: {
          // As this depends on google api
          position: google.maps.ControlPosition.LEFT_TOP,
        },
      };

      this.props.onGoogleApiLoaded({ map, maps });
    });
  };

  onMarkerDrag = (childKey, childProps, mouse) => {
    const { markers } = this.state;
    const { id, order, draggable } = childProps;
    if (!draggable) return;
    const { lat, lng } = mouse;
    const updatedMarkers = [...markers];
    updatedMarkers[order - 1] = { lat, lng, id, draggable };
    this.setState({ draggable: false, markers: updatedMarkers });
  };

  onMarkerDragEnd = _debounce(async (childKey, childProps, mouse) => {
    const { onDragEnd } = this.props;
    const { id, draggable } = childProps;
    if (!draggable) return;
    try {
      const [result] = await getGeocodeDetail({
        latlng: {
          lat: mouse.lat,
          lng: mouse.lng,
        },
        language: config.language,
      });

      if (result) {
        const name = result.formatted_address;
        const placeId = result.place_id || '';
        const { lat, lng } = result.geometry.location;
        onDragEnd(id, lat, lng, name, placeId);
      } else {
        throw new Error('no results returned');
      }
    } catch (e) {
      // console.error(`error fetching address ${e}`);
    } finally {
      this.setState({ draggable: true });
    }
  }, 300);

  updateDirectionsDisplay = () => {
    const { googleApiLoaded } = this.state;
    const { directions } = this.props;
    if (googleApiLoaded && _get(directions, ['routes', 'length'], 0)) {
      const path = MapsAPI.geometry.encoding.decodePath(
        directions.routes[0].overview_polyline.points
      );
      MapBounds = new MapsAPI.LatLngBounds();
      MapBounds.extend(directions.routes[0].bounds.northeast);
      MapBounds.extend(directions.routes[0].bounds.southwest);
      MapPolyline.setPath(path);
      MapObj.fitBounds(MapBounds);
    }
  };

  render() {
    const {
      zoomLevel,
      center,
      vehicle,
      draggableMarkers,
      gestureHandling,
    } = this.props;
    const { draggable, markers, googleApiLoaded } = this.state;

    mapOptions.gestureHandling = gestureHandling;

    return (
      <MapsContainer>
        <GoogleMapReact
          bootstrapURLKeys={config}
          center={center}
          defaultCenter={{ lat: 22.320138, lng: 114.1740319 }}
          defaultZoom={zoomLevel}
          options={mapOptions}
          draggable={draggable}
          onChildMouseDown={draggableMarkers ? this.onMarkerDrag : noop}
          onChildMouseMove={draggableMarkers ? this.onMarkerDrag : noop}
          onChildMouseUp={draggableMarkers ? this.onMarkerDragEnd : noop}
          onGoogleApiLoaded={this.onGoogleApiLoaded}
          yesIWantToUseGoogleMapApiInternals
        >
          {googleApiLoaded &&
            markers &&
            markers.map((marker, index, { length }) =>
              renderMarker({ marker, index, length })
            )}
          {vehicle && (
            <VehicleIcon
              lat={vehicle.lat}
              lng={vehicle.lng}
              bearing={vehicle.bearing}
              src={vehicle.icon || defaultVehicleIcon}
              alt="vehicle"
              width="48"
              height="48"
            />
          )}
        </GoogleMapReact>
      </MapsContainer>
    );
  }
}

export default Maps;
