import { DEFAULT_CURRENCY_RATE } from 'interfaces/global/config';

const { REACT_APP_TOPUP_URL, NODE_ENV } = process.env;

// ===== Selector =====
export const getCurrentLocation = state => state.region.currentLocation;
export const getCurrentCountryCode = state => state.region.currentCountry;
export const getCurrentCountry = state =>
  state.region.countries[state.region.currentCountry];
export const getCurrentCity = state =>
  state.region.cities[getCurrentLocation(state)];
export const getCurrentLocale = state => state.region.currentLocale;
export const getIsRegionLoading = state => state.region.isLoading;
export const getIsGlobal = state => state.region.isGlobal;
export const getCountry = id => state => state.region.countries[id];
export const getCity = id => state => state.region.cities[id];
export const getCountries = state => Object.values(state.region.countries);
export const getCountryDict = state => state.region.countries;
export const getCities = state => Object.values(state.region.cities);
export const getCityDict = state => state.region.cities;
export const getAllValidLocations = state => [
  ...Object.keys(state.region.countries),
  ...Object.keys(state.region.cities),
];
export const getDonationList = state => getCurrentCity(state).donationList;
export const getPriorityFee = state => getCurrentCity(state).priorityFee;
export const getCancelReasons = state => getCurrentCity(state).cancelReasons;
export const getRatingReasons = state => getCurrentCity(state).ratingReasons;

export const getAuthApiDomain = state => getCurrentCountry(state).domainAuth;
export const getMobileApiDomain = state => getCurrentCountry(state).domainApp;
export const getRestApiDomain = state => getCurrentCountry(state).domainRest;
export const getLogApiDomain = state => getCurrentCountry(state).domainLog;
export const getUAPIDomain = state => getCurrentCountry(state).uapi;
export const getUMETADomain = state => getCurrentCountry(state).umeta;

export const getCityRegexRules = state => getCurrentCity(state).regex;
export const getLoginBgImage = state =>
  getCurrentCountry(state).webappLoginBackground;
export const getTopUpDomain = state => {
  if (NODE_ENV !== 'development') {
    return getCurrentCountry(state).topUpDomain || REACT_APP_TOPUP_URL;
  }
  return REACT_APP_TOPUP_URL;
};

export const getCurrentDatacenter = state => getCurrentCity(state).datacenter;

export const getCurrentCityNameTrans = (city, locale, countries) => {
  const trans = city.translations.find(lang => lang.id === locale);
  if (trans?.value) return trans.value;

  const { defaultLanguage } = countries.find(
    c => c.id === city.id.substr(0, 2)
  );
  const defaultTrans = city.translations.find(
    lang => lang.id === defaultLanguage
  );
  if (defaultTrans?.value) return defaultTrans.value;

  const enTrans = city.translations.find(lang => lang.id.startsWith('en'));
  if (enTrans?.value) return enTrans.value;

  return city.id; // e.g. IN_BOM
};

export const getCurrentIsoCurrencyCode = state =>
  getCurrentCountry(state).isoCurrencyCode;

export const getCurrencyRate = state =>
  getCurrentCountry(state).currencyRate || DEFAULT_CURRENCY_RATE;
