import React, { Component } from 'react';
import { shape, func, bool } from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import ClickArea from 'components/ClickArea';
import Popover from 'components/Popover';
import { Button, Heading } from '@lalamove/karang';
import { info as InfoIcon } from '@lalamove/karang/dist/components/Icon/icons';

import formatter from 'utils/formatter';
import { noop } from 'utils/helpers';
import { gray } from 'styles/colors';
import { fontSize } from 'styles/fonts';

const Container = styled.div`
  padding: 8px;
  background: #fffaf7;
`;

const Price = styled.div`
  padding: 4px;
  color: #303030;
  font-weight: bold;
  text-align: right;
`;

const Amount = styled.span`
  margin: 0 6px;
  font-size: 2.6em;
  line-height: 0.8em;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

// TODO: move price breakdown to its own component, for FOX-1780
const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
  color: ${gray};
  font-size: 1em;
`;

const PriceLabel = styled.span`
  margin-right: 0.5em;
`;

const PriceValue = styled.span`
  margin-left: 0.5em;
`;

const ActionButton = styled(Button)`
  margin: auto 0.5rem;
  font-size: ${fontSize.regular};
`;

class Footer extends Component {
  static defaultProps = {
    price: {},
    t: noop,
    onBack: noop,
    onSubmit: noop,
    isLoading: false,
    isFormReady: false,
  };

  static propTypes = {
    price: shape({}),
    t: func,
    onBack: func,
    onSubmit: func,
    isLoading: bool,
    isFormReady: bool,
  };

  state = {
    shownBreakDown: false,
  };

  togglePriceBreakdown = () => {
    ReactTooltip.hide();
    this.setState(prevState => ({ shownBreakDown: !prevState.shownBreakDown }));
  };

  renderBreakdownDetails = () => {
    const { price, t } = this.props;
    return Object.keys(price).map(key =>
      key !== 'symbol' && key !== 'total' && price[key] ? (
        <PriceRow key={`row.${key}`}>
          <PriceLabel key={`label.${key}`}>{t(`PlaceOrder.${key}`)}</PriceLabel>
          <PriceValue key={`value.${key}`}>
            {formatter.currency(price[key])}
          </PriceValue>
        </PriceRow>
      ) : (
        ''
      )
    );
  };

  render() {
    const { price, t, onBack, onSubmit, isLoading, isFormReady } = this.props;
    const { shownBreakDown } = this.state;
    const infoIcon = (
      <ClickArea
        onClick={this.togglePriceBreakdown}
        data-tip={t('PlaceOrder.tip_show_breakdown')}
        data-place="top"
        data-effect="solid"
        data-for="global"
      >
        <InfoIcon />
      </ClickArea>
    );

    const priceBreakDown = (
      <div>
        <Heading htmlTag="h3">{t('PlaceOrder.price_breakdown')}</Heading>
        {this.renderBreakdownDetails()}
      </div>
    );

    return (
      <Container>
        <Price>
          <Amount>{formatter.currency(price.total)}</Amount>
          <Popover
            isOpen={shownBreakDown}
            preferPlace="above"
            target={infoIcon}
            onOuterAction={this.togglePriceBreakdown}
            body={priceBreakDown}
          >
            {infoIcon}
          </Popover>
        </Price>
        <ButtonGroup>
          <ActionButton
            onClick={onBack}
            variant="primary"
            size="large"
            block
            disabled={isLoading}
          >
            {t('EditOrder.button_cancel')}
          </ActionButton>
          <ActionButton
            disabled={isLoading || !isFormReady}
            isLoading={isLoading}
            onClick={onSubmit}
            variant="primary"
            size="large"
            block
            solid
          >
            {t('EditOrder.button_save')}
          </ActionButton>
        </ButtonGroup>
      </Container>
    );
  }
}

export default withTranslation()(Footer);
