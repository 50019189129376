/* eslint-disable lines-between-class-members */

export default class Transaction {
  static METHOD_CREDIT = 'credit';
  static METHOD_REWARD = 'reward';
  static METHOD_CASH = 'cash';
  static ALL_METHODS = [
    Transaction.METHOD_CREDIT,
    Transaction.METHOD_REWARD,
    Transaction.METHOD_CASH,
  ];

  id = null;
  // method: 'credit';
  type = '';
  amount = 0;
  createdAt = '';
  orderId = null;
  orderDateTime = null;
  orderBy = null;

  constructor(t = {}) {
    Object.keys(this).forEach(k => {
      const key = k.startsWith('_') ? k.substring(1, k.length) : k;
      if (Object.prototype.hasOwnProperty.call(t, key)) this[key] = t[key];
    });
  }

  static New(t) {
    return new Transaction(t);
  }

  debug() {
    return Object.entries(this).reduce(
      (memo, [key, val]) => ({ ...memo, [key]: val }),
      {}
    );
  }
}
