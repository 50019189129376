import React, { Component } from 'react';
import { bool, string, func, number } from 'prop-types';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { dropdown as DropDownIcon } from '@lalamove/karang/dist/components/Icon/icons';
import {
  getDaylightSavingsTime,
  closeDaylightSavingsTimeDialog,
} from 'interfaces/global/store/modules/checkout/actions';
import {
  noop,
  daylightSavingsTimeMomentMap,
  daylightSavingsStatus,
} from 'utils/helpers';
import { silver } from 'styles/colors';

import 'rc-time-picker/assets/index.css';
import { openDialog } from 'store/modules/ui';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import addStyle from './style';

const MIN_STEP = 1;

const ScTimePicker = addStyle(TimePicker);

function parseDateOnly(datetime) {
  return datetime.split('T')[0];
}

export class OrderTimePicker extends Component {
  static defaultProps = {
    selectedTime: '',
    defaultDatetime: '',
    isScheduled: false,
    placeholder: '',
    onChange: noop,
    openDialog: noop,
    daylightSavingsTimeType: daylightSavingsStatus['DEFAULT'],
  };

  static propTypes = {
    selectedTime: string,
    defaultDatetime: string,
    isScheduled: bool,
    placeholder: string,
    onChange: func,
    openDialog: func,
    daylightSavingsTimeType: number,
    getDaylightSavingsTime: func.isRequired,
    closeDaylightSavingsTimeDialog: func.isRequired,
  };

  state = {
    open: false,
    format: 'HH:mm',
    temporarySelectedTime: null,
  };

  container = React.createRef();

  componentDidUpdate(prevProps) {
    const { temporarySelectedTime } = this.state;
    const { daylightSavingsTimeType } = this.props;
    if (
      daylightSavingsTimeType !== daylightSavingsStatus['DEFAULT'] &&
      temporarySelectedTime
    ) {
      if (daylightSavingsTimeType === daylightSavingsStatus['SPECIAL_CST']) {
        this.specialDaylightSavingsTime(temporarySelectedTime);
        return;
      }
      this.openDaylightSavingsTimeDialog(temporarySelectedTime);
    }
  }

  disabledHours = () => {
    const { defaultDatetime, selectedTime } = this.props;
    const m = moment(defaultDatetime);
    const prevHours = [];

    if (!m.isSameOrAfter(parseDateOnly(selectedTime))) return prevHours;
    const hourNow = m.hours();
    for (let h = 0; h < hourNow; h += 1) {
      prevHours.push(h);
    }

    return prevHours;
  };

  disabledMinutes = () => {
    const { defaultDatetime, selectedTime } = this.props;
    const m = moment(defaultDatetime);
    const prevMinutes = [];

    if (!m.isSameOrAfter(selectedTime)) return prevMinutes;
    const minuteNow = m.minutes();
    for (let min = 0; min < minuteNow; min += MIN_STEP) {
      prevMinutes.push(min);
    }
    return prevMinutes;
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = m => {
    const { defaultDatetime } = this.props;
    // changing time always makes the order become scheduled:
    const isScheduled = true;
    if (m.isSameOrBefore(defaultDatetime)) {
      this.props.onChange(
        moment(defaultDatetime).add(MIN_STEP, 'mins').toISOString(true),
        isScheduled
      );
    } else {
      this.props.onChange(m.toISOString(true), isScheduled);
    }
    this.setState({ temporarySelectedTime: m });
    const time = m.unix();
    this.props.getDaylightSavingsTime({ time });
  };

  openDaylightSavingsTimeDialog = m => {
    const { daylightSavingsTimeType } = this.props;
    this.handleClose();
    this.setState({ temporarySelectedTime: null });
    const selectedTime = m.format('MMM D[,] YYYY HH[:]mm');
    const CDT =
      daylightSavingsTimeType === daylightSavingsStatus['CDT']
        ? m.add(1, 'h').format('MMM D[,] YYYY HH[:]mm')
        : '';
    this.props.openDialog('DAYLIGHTSAVINGSTIME_CONVERTED', {
      TimeDialogSubmit: selectType =>
        this.submitDaylightSavingsTimeSelection(
          m,
          daylightSavingsTimeType,
          selectType
        ),
      selectedTime,
      CDT,
      daylightSavingsTimeType,
    });
  };

  submitDaylightSavingsTimeSelection = (
    m,
    daylightSavingsTimeType,
    selectType
  ) => {
    this.props.closeDaylightSavingsTimeDialog();
    if (daylightSavingsTimeType === daylightSavingsStatus['CST']) {
      const { format } = this.state;
      const daylightSavingsTimeFormat =
        format.split('[')[0] + daylightSavingsTimeMomentMap[selectType];
      this.setState({ format: daylightSavingsTimeFormat });
      if (selectType === daylightSavingsStatus['CST']) {
        this.handleChange(m.add(1, 'h'));
      }
    } else {
      this.handleChange(m);
    }
  };

  specialDaylightSavingsTime = temporarySelectedTime => {
    // (ios)CDT=>CST，When the user has naturally walked from 1:59 CDT to 1:00 CST, the user places an appointment order, selects 1:30 in the time selector, and clicks OK GOT IT. The order time becomes 1:30 CDT time , But actually this time has passed
    this.handleChange(temporarySelectedTime.add(1, 'h'));
    this.setState({ temporarySelectedTime: null });
    this.props.closeDaylightSavingsTimeDialog();
  };

  renderTimeText(selectedTime, defaultDatetime) {
    const m = moment(selectedTime);
    if (defaultDatetime !== selectedTime || this.props.isScheduled) {
      return m;
    }
    return null;
  }

  render() {
    const {
      selectedTime,
      defaultDatetime,
      isScheduled,
      placeholder,
    } = this.props;
    const { open, format } = this.state;
    return (
      <div ref={this.container} style={{ position: 'relative' }}>
        <ScTimePicker
          popupClassName="llm-time-picker-popup"
          allowEmpty={false}
          showSecond={false}
          minuteStep={MIN_STEP}
          value={this.renderTimeText(selectedTime, defaultDatetime)}
          defaultOpenValue={moment(defaultDatetime)}
          placeholder={!isScheduled ? placeholder : ''}
          onChange={this.handleChange}
          format={format}
          inputReadOnly
          disabledHours={this.disabledHours}
          disabledMinutes={this.disabledMinutes}
          getPopupContainer={node => node}
          inputIcon={<DropDownIcon color={silver} size={24} />}
          open={open}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}
const mapState = state => ({
  daylightSavingsTimeType: state.checkout.daylightSavingsTimeType,
});
export default compose(
  withTranslation(),
  connect(mapState, {
    openDialog,
    getDaylightSavingsTime,
    closeDaylightSavingsTimeDialog,
  })
)(OrderTimePicker);
