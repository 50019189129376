import moment from 'moment';
import storage from 'utils/storage';

// Backend will reject orders placed with timestamp in the past
// Orders placed with a future timestamp up to 30 mins is recognized as immediate orders
// Consequently, scheduled orders need to have their timestamps offset by at least 31 mins
export const IMMEDIATE_ORDER_OFFSET_MIN = 10;
export const SCHEDULED_ORDER_OFFSET_MIN = 31;

export function loadUserContact() {
  return JSON.parse(storage.getItem('userContact'));
}

export function saveUserContact(contact) {
  storage.setItem('userContact', JSON.stringify(contact));
}

export function removeUserContact() {
  storage.removeItem('userContact');
}

/**
 * Gets the next allowed time based on current time and offset
 * @param {Object} currentTime moment object
 * @param {integer} offset scheduled order city offset
 * @return {Object} next allowed time in moment object
 */
export const getImmediateDeliveryUnix = () =>
  moment().add(IMMEDIATE_ORDER_OFFSET_MIN, 'minutes').unix();
