import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import moment from 'moment';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

const register = async ({
  firstName,
  lastName,
  email,
  phoneNumber,
  password,
  smsCode,
}) => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const { cityId, countryId, locale } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email,
      phone_no: phoneNumber,
      password,
      sms_code: smsCode,
      city_id: cityId,
    }),
    hcountry: countryId,
    os: 'web',
    hlang: toLLMLower(locale),
    _t: moment().unix(),
  };

  const { ret, data, msg } = await fetcher.get('?_m=register', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data;
};
export default register;
