import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Checkbox, Heading } from '@lalamove/karang';
import {
  dropdown as DropdownIcon,
  question as QuestionIcon,
} from '@lalamove/karang/dist/components/Icon/icons';

import Modal from 'components/Modal';
import { GhostButton } from 'components/Button';
import formatter from 'utils/formatter';
import Segment from 'utils/segment';
import { orange } from 'styles/colors';
import {
  SEGMENT_CLICK_MORE_SERVICES,
  SEGMENT_CLICK_SPECIAL_REQUEST_INFO,
} from 'store/modules/placeOrder/tracking';
import {
  getSpecialRequests,
  addSpecialRequest,
  removeSpecialRequest,
  getSelectedSubSpecialRequests,
  getSelectedServiceAndSubServiceIds,
} from 'store/modules/services';
import { createLoadingSelector } from 'store/modules/loading';
import SubAdditionalServices from './SubAdditionalServices';
import Insurance from './Insurance'; // eslint-disable-line import/no-named-as-default

const ListContainer = styled.div`
  margin: 1rem 0;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  margin: 1.5em 0;
`;

const ListItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HelpButton = styled.button`
  display: inline;
  padding: 0;
  border: none;
  margin: 0 0.5em;
  appearance: none;
  background: none;
  cursor: pointer;
  outline: 0;
  text-align: left;
`;

const { bool, arrayOf, shape, string, func } = PropTypes;
const SPECIAL_REQUESTS_SLICE_LIMIT = 4;

export class AdditionalServices extends Component {
  static propTypes = {
    isLoading: bool,
    specialRequests: arrayOf(shape({})).isRequired,
    selectedSpecialRequests: arrayOf(string).isRequired,
    addSpecialRequest: func.isRequired,
    removeSpecialRequest: func.isRequired,
    t: func.isRequired,
    serviceId: string.isRequired,
    subServiceId: string.isRequired,
    selectedSubRequests: shape({}),
  };

  static defaultProps = {
    isLoading: false,
    selectedSubRequests: {},
  };

  state = {
    showMore: false,
    modalOpen: false,
    description: '',
  };

  componentDidUpdate() {
    const { selectedSpecialRequests, specialRequests } = this.props;
    if (specialRequests.length > SPECIAL_REQUESTS_SLICE_LIMIT) {
      selectedSpecialRequests.some(selectedReqId => {
        const index = specialRequests.findIndex(
          item => item.id === selectedReqId
        );
        if (!this.state.showMore && index > SPECIAL_REQUESTS_SLICE_LIMIT - 1) {
          return this.showMoreServices();
        }
        return false;
      });
    }
  }

  handleToggle = e => {
    const id = e.target.name;
    const { checked } = e.target;
    if (checked) this.props.addSpecialRequest(id);
    else this.props.removeSpecialRequest(id);
  };

  showMoreServices = () => {
    this.setState({ showMore: true });

    // tracking
    Segment.createTrack(SEGMENT_CLICK_MORE_SERVICES);
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  handleOpenModal = (id, description) => {
    this.setState({
      modalOpen: true,
      description,
    });

    // tracking
    const {
      specialRequests,
      selectedSubRequests,
      serviceId,
      subServiceId,
    } = this.props;
    Segment.createTrack(SEGMENT_CLICK_SPECIAL_REQUEST_INFO, {
      addon_service_type: specialRequests.find(item => item.id === id).trimId,
      addon_subservice_type: selectedSubRequests[id]
        ? selectedSubRequests[id].trimId
        : '',
      vehicle_type: serviceId,
      vehicle_subtype: subServiceId,
    });
  };

  renderIcon = (id, description) => (
    <HelpButton onClick={() => this.handleOpenModal(id, description)}>
      <QuestionIcon color={orange} />
    </HelpButton>
  );

  renderPrice = item => {
    const { selectedSpecialRequests, t } = this.props;
    let price = '';
    if (selectedSpecialRequests.includes(item.id) && !item.subSpecialRequests) {
      if (item.isQuoteByDriver) {
        price = t('PlaceOrder.label_quote_by_driver');
      } else if (item.price.amount === 0) {
        price = t('PlaceOrder.free');
      } else {
        price = `+${formatter.currency(item.price.amount)}`;
      }
    }
    return price;
  };

  render() {
    const {
      isLoading,
      specialRequests,
      selectedSpecialRequests,
      serviceId,
      subServiceId,
      t,
    } = this.props;
    const { showMore, modalOpen, description } = this.state;
    // KOALA-887: It's a hard-coded key representing good damage insurance service that agreed with
    // Simon's team. You may find the corresponding key `SPECIAL_REQUEST_INSURANCE_GOODS_DAMAGE`
    // in Options of Config panel.
    const INSURANCE_KEY = `${subServiceId || serviceId}-INSURANCE_GOODS_DAMAGE`;

    return isLoading
      ? 'Loading...'
      : specialRequests.length > 0 && (
          <Fragment>
            <Heading htmlTag="h3" style={{ lineHeight: '2.143em' }}>
              {t('PlaceOrder.heading_services')}
            </Heading>
            <ListContainer>
              <List>
                {specialRequests
                  .slice(
                    0,
                    showMore
                      ? specialRequests.length
                      : SPECIAL_REQUESTS_SLICE_LIMIT
                  )
                  .map(item => (
                    <ListItem key={item.id}>
                      <ListItemContent>
                        <Checkbox
                          name={item.id}
                          label={item.name}
                          checked={selectedSpecialRequests.includes(item.id)}
                          onChange={this.handleToggle}
                          icon={
                            item.description &&
                            this.renderIcon(item.id, item.description)
                          }
                        />
                        {this.renderPrice(item)}
                      </ListItemContent>
                      {selectedSpecialRequests.includes(item.id) &&
                        item.subSpecialRequests && (
                          <SubAdditionalServices specialRequestId={item.id} />
                        )}
                      {item.id.startsWith(INSURANCE_KEY) &&
                        selectedSpecialRequests.includes(item.id) && (
                          <Insurance />
                        )}
                    </ListItem>
                  ))}
              </List>
              {!showMore &&
                specialRequests.length > SPECIAL_REQUESTS_SLICE_LIMIT && (
                  <GhostButton
                    onClick={this.showMoreServices}
                    icon={<DropdownIcon size={20} />}
                    iconPosition="before"
                  >
                    {t('PlaceOrder.more_services')}
                  </GhostButton>
                )}

              <Modal
                isOpen={modalOpen}
                onClose={this.handleCloseModal}
                style={{
                  content: {
                    width: '40rem',
                    height: '32rem',
                    marginTop: '0px',
                  },
                }}
              >
                {description.startsWith('http') ? (
                  <iframe
                    src={description.replace(/^http:/, '')}
                    title="Description"
                    frameBorder="0"
                    style={{ height: '100%', width: '100%' }}
                  />
                ) : (
                  description
                )}
              </Modal>
            </ListContainer>
          </Fragment>
        );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_SERVICES']);

const mapStateToProps = state => {
  const { serviceId, subServiceId } = getSelectedServiceAndSubServiceIds(state);
  return {
    isLoading: loadingSelector(state),
    // TODO: selector
    specialRequests: getSpecialRequests(
      state,
      state.services.servicesOrder[state.services.selectedService]
    ),
    selectedSpecialRequests: state.services.selectedSpecialRequests,
    selectedSubRequests: getSelectedSubSpecialRequests(state),
    serviceId,
    subServiceId,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, { addSpecialRequest, removeSpecialRequest })
)(AdditionalServices);
