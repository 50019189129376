import React from 'react';
import { bool, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { getDialog } from 'store/modules/ui';

import CancelOrderFailedDialog from './CancelOrderFailedDialog';
import DriverRemovalDialog from './DriverRemovalDialog';
import EditOrderConfirmDialog from './EditOrderConfirmDialog';
import EditOrderDiscardDialog from './EditOrderDiscardDialog';
import MarketingOptInDialog from './MarketingOptInDialog';
import PriceUnmatchedDialog from './PriceUnmatchedDialog';
import SessionErrorDialog from './SessionErrorDialog';
import UnpaidOrderDialog from './UnpaidOrderDialog';
import DaylightSavingsTimeDialog from './DaylightSavingsTimeDialog';
import BusinessRoleAddedDialog from './BusinessRoleAddedDialog.tsx';
import BusinessRoleRemovedDialog from './BusinessRoleRemovedDialog.tsx';

const COMPONENTS = {
  CANCEL_ORDER_FAILED: CancelOrderFailedDialog,
  DRIVER_REMOVAL: DriverRemovalDialog,
  EDIT_ORDER_CONFIRM: EditOrderConfirmDialog,
  EDIT_ORDER_DISCARD: EditOrderDiscardDialog,
  MARKETING_OPT_IN: MarketingOptInDialog,
  PLACE_ORDER_PRICE_UNMATCHED: PriceUnmatchedDialog,
  SESSION_ERROR: SessionErrorDialog,
  UNPAID_NOTICE: UnpaidOrderDialog,
  DAYLIGHTSAVINGSTIME_CONVERTED: DaylightSavingsTimeDialog,
  PROFILE_BUSINESS_ROLE_ADDED: BusinessRoleAddedDialog,
  PROFILE_BUSINESS_ROLE_REMOVED: BusinessRoleRemovedDialog,
};

// Root Dialog component
export const DialogRoot = ({ id, props, isOpen }) => {
  const Comp = COMPONENTS[id];
  if (!id || !Comp) return null;
  return <Comp isOpen={isOpen} {...props} />;
};

DialogRoot.defaultProps = {
  id: null,
  props: {},
  isOpen: false,
};

DialogRoot.propTypes = {
  id: string,
  props: shape({}),
  isOpen: bool,
};

const mapStateToProps = state => {
  const { id, props, isOpen } = getDialog(state);
  return { id, props, isOpen };
};

export default compose(withTranslation(), connect(mapStateToProps))(DialogRoot);
