import { LalamoveApiError } from 'utils/helpers';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Request {
//   access_token: String;
//   password: String;
//   old_password?: String;
// }
// interface Response {
//   success: Boolean;
// }
export default async function updatePassword({ oldPassword, newPassword }) {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { accessToken } = await fetcher.getState();
  const { success, data, code } = await fetcher.post('changeuserpassword', {
    access_token: accessToken,
    password: newPassword,
    ...(oldPassword && { old_password: oldPassword }),
  });
  if (!success) throw new LalamoveApiError(code);
  return data;
}
