import React, { PureComponent } from 'react';
import { bool, func, node, number, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, CountdownBar } from '@lalamove/karang';
import { orange } from 'styles/colors';
import { noop } from 'utils/helpers';

export const HelpLink = styled.div`
  color: ${orange};
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
`;

export class ResendContent extends PureComponent {
  static propTypes = {
    isSubmitting: bool,
    isWaiting: bool,
    waitDuration: number,
    maxReset: bool,
    label: string,
    helpLink: node,
    onResendRequest: func,
  };

  static defaultProps = {
    isSubmitting: false,
    isWaiting: true,
    waitDuration: 60000,
    maxReset: false,
    label: '',
    helpLink: null,
    onResendRequest: noop,
  };

  handleResendRequest = () => {
    this.props.onResendRequest();
  };

  renderResendContent = () => {
    const {
      isSubmitting,
      isWaiting,
      waitDuration,
      maxReset,
      label,
      helpLink,
    } = this.props;

    let resendContent;
    if (isWaiting) {
      // during the 60s wait period, render the countdown bar
      resendContent = (
        <CountdownBar label={label} duration={`${waitDuration}ms`} />
      );
    } else if (maxReset) {
      // max reset reached, render CS link
      resendContent = <HelpLink>{helpLink}</HelpLink>;
    } else {
      // render the resend button
      resendContent = (
        <Button
          type="button"
          variant="primary"
          size="large"
          solid
          block
          onClick={this.handleResendRequest}
          isLoading={isSubmitting}
        >
          {label}
        </Button>
      );
    }
    return resendContent;
  };

  render() {
    return <div>{this.renderResendContent()}</div>;
  }
}

export default withTranslation()(ResendContent);
