import React from 'react';
import { number, string } from 'prop-types';
import styled from 'styled-components';
import {
  pickup,
  pinFilled,
  midstop,
} from '@lalamove/karang/dist/components/Icon/icons';

import { orange, gray, white } from 'styles/colors';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -80%);
  cursor: pointer;
`;

const Order = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${props => props.color};
  color: ${white};
  text-align: center;
`;

const IconMap = {
  pickup,
  midstop,
  pinFill: pinFilled,
};
const Marker = ({ id, type, order }) => {
  const color = type === 'midstop' ? gray : orange;
  const I = IconMap[type] || 'div';
  return (
    <Wrapper id={id}>
      <Order color={color}>{order}</Order>
      <I color={color} size={48} />
    </Wrapper>
  );
};

Marker.propTypes = {
  id: string,
  type: string.isRequired,
  order: number.isRequired, // eslint-disable-line react/no-typos
};

Marker.defaultProps = {
  id: null,
};

export default Marker;
