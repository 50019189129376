import { batch, useDispatch, useSelector } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import {
  getSelectedService,
  getSelectedServiceIndex,
  getServices,
} from 'interfaces/global/store/modules/services/selectors';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { setService } from 'interfaces/global/store/modules/services/actions';

import { Service } from './types';

interface VehicleServices {
  services: Service[];
  selectedService?: string;
  selectedIndex: number;
  onChange: (index: number) => void;
}

const useVehicleServices = (): VehicleServices => {
  const services: Service[] = useSelector(getServices, _isEqual);
  const selectedService: string = useSelector(getSelectedService);
  const selectedIndex: number = useSelector(getSelectedServiceIndex);

  const dispatch = useDispatch();
  const onChange = (index: number) => {
    if (index !== selectedIndex) {
      batch(() => {
        dispatch(setService(index));
        dispatch(
          track('vehicle_selected_lite', { vehicle_type: services[index].name })
        );
      });
    }
  };

  return { services, selectedService, selectedIndex, onChange };
};

export default useVehicleServices;
