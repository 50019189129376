import React from 'react';
import { shape, arrayOf, string, func } from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from '@lalamove/karang';
import createCountryPickerOptions from 'utils/createCountryPickerOptions.ts';
import { noop } from 'utils/helpers';

const Picker = styled(Dropdown)`
  position: relative;
`;

const CountryPicker = ({
  locale,
  currentCity,
  countries,
  cityDict,
  onChange,
  ...rest
}) => {
  const options = createCountryPickerOptions(countries, cityDict, locale);
  let selectedOption;
  const { id: cityCode, translations: cityTranslations } = currentCity;
  const { cities, translations, defaultLanguage } = countries.find(
    country => country.id === cityCode.substr(0, 2)
  );

  if (cities.length > 1) {
    const isCityInCurrentCountry =
      cityCode.split('_')[0] === locale.split('_')[1];
    const trans = cityTranslations.find(
      lang => lang.id === (isCityInCurrentCountry ? locale : defaultLanguage)
    );
    const enTrans = cityTranslations.find(lang => lang.id.startsWith('en'));
    selectedOption = {
      value: cityCode,
      label: (trans || enTrans || {}).value || cityCode,
    };
  } else {
    const trans = translations.find(lang => lang.id === defaultLanguage);
    const enTrans = translations.find(lang => lang.id.startsWith('en'));
    selectedOption = {
      value: cityCode,
      label: (trans || enTrans || {}).value || cityCode,
    };
  }

  const handleOnChange = ({ value }) => {
    if (value !== cityCode) {
      onChange(value);
    }
  };

  return (
    <Picker
      {...rest}
      items={options}
      onChange={handleOnChange}
      selectedItem={selectedOption}
      direction="left"
    />
  );
};

CountryPicker.defaultProps = {
  onChange: noop,
  currentCity: {},
  countries: [],
  locale: 'en_HK',
};

CountryPicker.propTypes = {
  onChange: func,
  locale: string,
  currentCity: shape({}),
  countries: arrayOf(
    shape({
      id: string,
      cities: arrayOf(string),
    })
  ),
  cityDict: shape({}).isRequired,
};

export default CountryPicker;
