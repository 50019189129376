import { LalamoveApiError } from 'utils/helpers';

import RestAPIFetcher from 'api/RestAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Response {
//   id: string;
//   attributes: {
//     cityCode: string,

//     clientId: string,
//     clientName: string,
//     clientPhone: string,
//     clientSmsUpdates: boolean,
//     clientCorporateCode: string,
//     clientCorporateId: string,

//     driverId: string,
//     driverName: string,
//     driverPhone: string,
//     driverPlateNumber: string,

//     clientRemarks: string,
//     driverIsFavorite: boolean,
//     editRevision: string, // unix timestamp
//     expiresAt: string, // ISO Z
//     isForFavoriteDriver: boolean,
//     isImmediate: boolean,
//     paymentMethod: string, // CREDIT | CASH
//     scheduledAt: string, // ISO Z
//     serviceType: string, // prefix SERVICE | SUB_SERVICE
//     vehicleType: string, // prefix VEHICLE
//     createdAt: string, // ISO Z
//     modifiedAt: string, // ISO Z
//     status: string,
//     statusProgress: StatusProgress[],
//     deliveries: DeliveryStruct[],
//     totalPrice: PriceSummaryStruct,
//     priceBreakdown: PriceBreakdownStruct[],
//     totalPayment: PaymentSummaryStruct,
//     paymentBreakdown: PaymentBreakdownStruct[],
//   };
// }
export default async function editOrder({
  orderId,
  quoteId,
  editRevision,
  deliveries,
}) {
  const fetcher = FetcherFactory.make(RestAPIFetcher);
  const params = {
    type: 'client-orders',
    attributes: {
      quoteId,
      editRevision, // unix time string
      deliveries,
    },
  };
  const res = await fetcher.patch(`client-orders/${orderId}`, params);
  const { error, errors } = res;
  if (error) throw new LalamoveApiError(error.code, error.message);
  // TODO: Helper function to loop errors array for auth error
  if (errors) throw new LalamoveApiError(errors[0].code, errors[0].message);
  return res;
}
