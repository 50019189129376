export function getChangeEmailStep(state) {
  return state.settings.emailSettings.changeEmailStep;
}
export function getChangeEmailNewEmail(state) {
  return state.settings.emailSettings.newEmail;
}

export function getUserEmail(state) {
  return state.session.user.profile.email;
}
