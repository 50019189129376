import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayOf, string, number, func, shape } from 'prop-types';
import styled, { css } from 'styled-components';
import Slider from '@lalamove/react-slick';
import { IconButton } from '@lalamove/karang';
import {
  dropdown as ArrowIcon,
  defaultStatus as OrderIcon,
} from '@lalamove/karang/dist/components/Icon/icons';

import { getServices, setService } from 'store/modules/services';
import movingCartSVG from 'assets/svg/moving_cart.svg';
import { orange } from 'styles/colors';
import { GROUND } from 'styles/zIndex';

import { track } from 'interfaces/global/store/modules/tracking/actions';

import 'slick-carousel/slick/slick.css';

const SELECTOR_HEIGHT = 15;
const HOVER_TRANSITION_TIME_MS = 100;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: ${SELECTOR_HEIGHT}rem;
  max-height: ${SELECTOR_HEIGHT}rem;
  margin: -16px -16px 2rem;
  background-color: #f4e7dd;
  background-image: url(${movingCartSVG});
  background-position: right 1.5rem top 1.7rem;
  background-size: 42px 84px;
  background-repeat: no-repeat;

  & .slick-arrow {
    position: absolute;
    top: 50%;
  }
  & .slick-dots {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;

    li {
      display: inline-block;
      width: auto;
      height: auto;
      margin: 0;
    }
  }
`;

const StyledSlider = styled(Slider)`
  z-index: ${GROUND + 1};
  height: ${SELECTOR_HEIGHT}rem;

  && ul {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

const Item = styled.div`
  margin: 1.5rem 0 0;
  color: ${orange};
  text-align: center;
  outline: none;

  && img {
    display: inline-block;
  }
`;

const Title = styled.span`
  display: block;
  margin: 0 0 0.43rem 0;
  font-size: 1.3em;
  font-weight: bold;
`;

const Description = styled.span`
  display: block;
  font-size: 0.8em;

  && svg {
    margin: 0.43rem auto;
  }

  && span {
    display: block;
    margin: 0.43rem 0;
  }
`;

const Nav = styled.a`
  display: inline-block;
  margin: 0 12px;
`;

const Icon = styled.img`
  position: relative;
  cursor: pointer;
  padding: 8px 0 0;
  transition: all ${HOVER_TRANSITION_TIME_MS}ms ease;
  &:hover {
    padding: 0 0 8px;
  }
  ${props =>
    !props.selected &&
    css`
      opacity: 0.8;
      filter: grayscale(100%);
    `}
`;

const ArrowBtn = styled(IconButton)`
  z-index: ${GROUND + 1};
  margin-top: -36px;
  ${({ position }) =>
    position === 'left'
      ? css`
          transform: rotate(90deg);
          left: 25%;
        `
      : css`
          transform: rotate(270deg);
          right: 25%;
        `};
`;

function buttonFactory(position, clickHandler) {
  return (
    <ArrowBtn variant="primary" position={position}>
      <ArrowIcon size={24} onClick={clickHandler} />
    </ArrowBtn>
  );
}

export class VehicleSlider extends Component {
  static propTypes = {
    services: arrayOf(
      shape({
        id: string,
        imgUri: shape({
          selected: string,
          default: string,
          background: string,
        }),
        dimensions: string,
        name: string,
      })
    ),
    selectedService: string,
    selectedIndex: number,
    setService: func.isRequired,
    track: func.isRequired,
  };

  static defaultProps = {
    services: [],
    selectedService: '',
    selectedIndex: null,
  };

  handleChange = index => {
    this.props.setService(index, this.source);
    this.props.track('vehicle_selected', {
      source: this.source,
      vehicle_type: this.props.services[index].name,
    });
    this.source = '';
  };

  handleSwipe = direction => {
    this.source = `${direction}_swipe`;
  };

  handlePrevClick = () => {
    this.source = 'left_arrow';
  };

  handleNextClick = () => {
    this.source = 'right_arrow';
  };

  handleNavClick = () => {
    this.source = 'vehicle_list';
  };

  render() {
    const { services, selectedService, selectedIndex } = this.props;
    if (!selectedService) return <Container />;

    const settings = {
      customPaging: i => (
        <Nav>
          <Icon
            selected={i === selectedIndex}
            src={(i === selectedIndex
              ? services[i].imgUri.selected
              : services[i].imgUri.default
            ).replace(/^http:/, '')}
            alt={services[i].name}
            data-cy={`vehicle-selection-${services[i].name}`}
            height="48"
            data-tip={services[i].name}
            data-place="bottom"
            data-effect="solid"
            data-for="global"
            onClick={this.handleNavClick}
          />
        </Nav>
      ),
      speed: 250,
      dots: true,
      infinite: true,
      initialSlide: selectedIndex,
      prevArrow: buttonFactory('left', this.handlePrevClick),
      nextArrow: buttonFactory('right', this.handleNextClick),
    };

    return (
      <Container>
        <StyledSlider
          {...settings}
          afterChange={this.handleChange}
          onSwipe={this.handleSwipe}
        >
          {services.map(({ id, name, dimensions }) => (
            <Item key={id}>
              <Title>{name}</Title>
              <Description>
                <OrderIcon color={orange} size={14} />
                <span>{dimensions}</span>
              </Description>
            </Item>
          ))}
        </StyledSlider>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  services: getServices(state),
  selectedService: state.services.servicesOrder[state.services.selectedService],
  selectedIndex: state.services.selectedService,
});

const mapDispatchToProps = {
  setService,
  track,
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleSlider);
