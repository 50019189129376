import { LalamoveApiError, formatLocaleString } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Request {
//   access_token: String;
//   client_id: Number | String;
//   country: String; // HK_HKG
//   event: 'get_ban_list';
//   offset: Number | String;
//   require: Number | String;
// }
// interface Response {
//   filter: {
//     category: String,
//     key: String,
//     [lang: String]: String,
//   }[];
//   in_progress_count: Number;
//   myFleet: {
//     avg_rating: Number | null,
//     city: String,
//     id: Number,
//     [name_lang: String]: String,
//     ref_id: String,
//     target: String,
//     vehicletype: String, // key
//     [vehicletype_lang: String]: String,
//   }[];
//   record_count: Number;
//   total: Number;
// }
// MobileAPIResponse<Response>
/**
 * Fetches banned drivers
 *
 * @param {Object} options
 * @param {number} options.current - current page number
 * @param {number} options.max - number of rows per page
 * @return {Promise} result - resolves to
 * ```
 * {
 *   drivers: {Object},
 *   count: {number},
 *   ids: {array<string>},
 * }
 * ```
 * @throws ERROR_INVALID_SESSION, ERROR_INVALID_PARAMETERS, ...
 */
export default async function fetchBannedDrivers({ current = 1, max = 10 }) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.langMutex.release();
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, city, locale } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    country: city,
    event: 'get_ban_list',
    offset: (current - 1) * max,
    require: max,
  };
  const { success, data, code } = await fetcher.get('vanmyfleet', params);
  if (!success) throw new LalamoveApiError(code);
  return {
    drivers: parseDrivers(data.myFleet, locale),
    count: data.total,
    ids: data.myFleet.map(item => item.ref_id), // use `ref_id` instead of `id` for ID
  };
}

export function parseDrivers(data, configLocale) {
  const drivers = {};
  data.forEach(item => {
    const driver = {};
    Object.keys(item).forEach(key => {
      if (key === 'ref_id') {
        // use `ref_id` instead of `id` for ID
        driver.id = item.ref_id;
      } else if (key.startsWith('name_')) {
        const locale = formatLocaleString(key.replace('name_', ''));
        if (locale === configLocale) {
          driver.name = item[key];
        }
      } else if (key.startsWith('vehicletype_')) {
        const locale = formatLocaleString(key.replace('vehicletype_', ''));
        if (locale === configLocale) {
          driver.vehicle = item[key];
        }
      } else if (key === 'avg_rating') {
        driver.avgRating = item.avg_rating;
      }
    });
    if (!driver.name) driver.name = '';
    if (!driver.vehicle) driver.vehicle = item.vehicletype;
    drivers[driver.id] = driver;
  });
  return drivers;
}
