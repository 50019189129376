// action
export const DIALOG_OPEN = 'DIALOG_OPEN';
export const DIALOG_CLOSE = 'DIALOG_CLOSE';
export const DIALOG_RESET = 'DIALOG_RESET';
export const PANEL_OPEN = 'PANEL_OPEN';
export const PANEL_CLOSE = 'PANEL_CLOSE';

export const openDialog = (id, props = {}) => ({
  type: DIALOG_OPEN,
  id,
  props,
});

export const closeDialog = () => ({
  type: DIALOG_CLOSE,
});

export const resetDialog = () => ({
  type: DIALOG_RESET,
});

export const openPanel = (id, props = {}) => ({
  type: PANEL_OPEN,
  id,
  props,
});

export const closePanel = () => ({
  type: PANEL_CLOSE,
});

// state
export const initState = {
  dialog: {
    id: '',
    props: {},
    isOpen: false,
  },
  panel: {
    id: '',
    props: {},
    isOpen: false,
  },
  // handle modal here later
};

// selectors
export const getDialog = ({ ui }) => ui.dialog;
export const getPanel = ({ ui }) => ui.panel;
export const isPanelOpen = id => ({ ui }) =>
  ui.panel.isOpen && ui.panel.id === id;
