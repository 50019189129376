import React from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { requestLogout } from 'store/modules/auth/actions';
import { actions } from 'store/modules/genesys';

import Dialog from 'components/Dialog';
import { noop } from 'utils/helpers';

const SessionErrorDialog = ({ t, logout, logoutGenesys, ...props }) => {
  const logoutAllSession = () => {
    logoutGenesys();
    logout();
  };
  return (
    <Dialog
      title={t('Login.dialog_title_invalid_session')}
      subtitle={t('Login.dialog_text_invalid_session')}
      primaryButtonText={t('Login.dialog_button_ok')}
      primaryAction={logoutAllSession}
      shouldCloseOnOverlayClick={false}
      {...props}
    />
  );
};

SessionErrorDialog.defaultProps = {
  t: noop,
  logout: noop,
  logoutGenesys: noop,
};

SessionErrorDialog.propTypes = {
  t: func,
  logout: func,
  logoutGenesys: func,
};

export default compose(
  withTranslation(),
  connect(null, {
    logout: requestLogout,
    logoutGenesys: actions.logoutGenesys,
  })
)(SessionErrorDialog);
