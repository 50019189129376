import React from 'react';
import { withTranslation } from 'react-i18next';
import { func } from 'prop-types';
import styled from 'styled-components';
import { Card, Button, Heading } from '@lalamove/karang';
import {
  edgeColored as Edge,
  safariColored as Safari,
  firefoxColored as Firefox,
  chromeColored as Chrome,
} from '@lalamove/karang/dist/components/Icon/icons';

import { gray } from 'styles/colors';

const StyledCard = styled(Card)`
  padding: 40px 48px 24px 48px;
`;

const Subtitle = styled.div`
  margin: 1em 0 2em 0;
  color: ${gray};
  font-size: 1em;
`;

const Panel = styled.div`
  float: right;
  button {
    margin: 0 0.5em;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledIconContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
  margin: 0;
  list-style: none;
  text-align: center;
`;

const StyledDescription = styled.span`
  display: block;
  color: ${gray};
`;

const ICON_SIZE = 80;

const BrowserSupportBox = ({ t, onClose }) => (
  <StyledCard>
    <Heading size="large">{t('Browser.title')}</Heading>
    <Subtitle>
      <p>{t('Browser.content')}</p>
      <StyledIconContainer>
        <li>
          <Chrome size={ICON_SIZE} />
          <StyledDescription>Chrome</StyledDescription>
        </li>
        <li>
          <Firefox size={ICON_SIZE} />
          <StyledDescription>Firefox</StyledDescription>
        </li>
        <li>
          <Edge size={ICON_SIZE} />
          <StyledDescription>Edge</StyledDescription>
        </li>
        <li>
          <Safari size={ICON_SIZE} />
          <StyledDescription>Safari</StyledDescription>
        </li>
      </StyledIconContainer>
    </Subtitle>
    <Panel>
      <Button variant="primary" onClick={onClose} solid>
        {t('Browser.confirm')}
      </Button>
    </Panel>
  </StyledCard>
);

BrowserSupportBox.propTypes = {
  t: func.isRequired,
  onClose: func.isRequired,
};

export default withTranslation()(BrowserSupportBox);
