export const FETCH_RECORDS_REQUEST = 'FETCH_RECORDS_REQUEST';
export const FETCH_RECORDS_SUCCESS = 'FETCH_RECORDS_SUCCESS';
export const FETCH_RECORDS_FAILURE = 'FETCH_RECORDS_FAILURE';

export const FETCH_RECORD_REQUEST = 'FETCH_RECORD_REQUEST';
export const FETCH_RECORD_SUCCESS = 'FETCH_RECORD_SUCCESS';
export const FETCH_RECORD_FAILURE = 'FETCH_RECORD_FAILURE';

export const FETCH_CLIENT_ORDER_REQUEST = 'FETCH_CLIENT_ORDER_REQUEST';
export const FETCH_CLIENT_ORDER_SUCCESS = 'FETCH_CLIENT_ORDER_SUCCESS';
export const FETCH_CLIENT_ORDER_FAILURE = 'FETCH_CLIENT_ORDER_FAILURE';

export const ORDER_ADD = 'ORDER_ADD';
export const CLONE_ORDER = 'CLONE_ORDER';

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';

export const UPDATE_PRIORITY_FEE_REQUEST = 'UPDATE_PRIORITY_FEE_REQUEST';
export const UPDATE_PRIORITY_FEE_SUCCESS = 'UPDATE_PRIORITY_FEE_SUCCESS';
export const UPDATE_PRIORITY_FEE_FAILURE = 'UPDATE_PRIORITY_FEE_FAILURE';

export const RATE_ORDER_REQUEST = 'RATE_ORDER_REQUEST';
export const RATE_ORDER_SUCCESS = 'RATE_ORDER_SUCCESS';
export const RATE_ORDER_FAILURE = 'RATE_ORDER_FAILURE';

export const FAVORITE_DRIVER_REQUEST = 'FAVORITE_DRIVER_REQUEST';
export const FAVORITE_DRIVER_SUCCESS = 'FAVORITE_DRIVER_SUCCESS';
export const FAVORITE_DRIVER_FAILURE = 'FAVORITE_DRIVER_FAILURE';

export const BAN_DRIVER_REQUEST = 'BAN_DRIVER_REQUEST';
export const BAN_DRIVER_SUCCESS = 'BAN_DRIVER_SUCCESS';
export const BAN_DRIVER_FAILURE = 'BAN_DRIVER_FAILURE';

export const INIT_EDIT_REQUEST = 'INIT_EDIT_REQUEST';
export const INIT_EDIT_SUCCESS = 'INIT_EDIT_SUCCESS';
export const INIT_EDIT_FAILURE = 'INIT_EDIT_FAILURE';

export const EDIT_ORDER_REQUEST = 'EDIT_ORDER_REQUEST';
export const EDIT_ORDER_SUCCESS = 'EDIT_ORDER_SUCCESS';
export const EDIT_ORDER_FAILURE = 'EDIT_ORDER_FAILURE';
