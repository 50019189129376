import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bool, func, string, number, shape } from 'prop-types';
import styled from 'styled-components';
import { RadioGroup, TextArea } from '@lalamove/karang';
import { question as QuestionIcon } from '@lalamove/karang/dist/components/Icon/icons';

import { cancelOrder } from 'store/modules/records';
import { createLoadingSelector } from 'store/modules/loading';
import { getCancelReasons } from 'store/modules/region/selectors';
import Heading from 'components/SliderPanel/Heading';
import SliderPanelFooter from 'components/SliderPanelFooter';

import { noop, statusMap } from 'utils/helpers';

import { Container, Content, Wrapper } from './style';

const TextAreaWrapper = styled.div`
  margin: 1.3em 0;
  > div {
    display: block;
  }
`;

class Cancel extends Component {
  static defaultProps = {
    id: null,
    status: null,
    cancelOrder: noop,
    onClose: noop,
    onBack: noop,
    isLoading: false,
  };

  static propTypes = {
    t: func.isRequired,
    id: string,
    status: number,
    isLoading: bool,
    cancelOrder: func,
    onBack: func,
    onClose: func,
    cancelReasons: shape({}).isRequired,
  };

  state = {
    selected: null,
    comment: '', // Custom reason for others
  };

  componentDidMount() {
    const { status, onClose } = this.props;
    if (![statusMap.ASSIGNING, statusMap.ONGOING].includes(status)) {
      onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.props.onClose();
    }
  }

  onReasonChange = value => {
    this.setState({ selected: value, comment: '' });
  };

  onCommentChange = e => {
    this.setState({ comment: e.target.value });
  };

  cancelOrder = () => {
    const { selected, comment } = this.state;
    this.props.cancelOrder(this.props.id, selected, comment);
  };

  render() {
    const { selected, comment } = this.state;
    const {
      t,
      id,
      status,
      onBack,
      onClose,
      isLoading,
      cancelReasons,
    } = this.props;
    if (!id) return null;
    const reasons = cancelReasons[status] || [];
    const selectedOtherReason = [
      'OTHER_BEFORE_MATCH',
      'OTHER_AFTER_MATCH',
    ].includes(selected);

    return (
      <Container>
        <Content>
          <Heading
            icon={<QuestionIcon size={24} />}
            title={t('RecordPanel.title_cancel_order')}
          />
          <Wrapper>
            <RadioGroup
              name="cancel_reason"
              value={selected}
              onChange={this.onReasonChange}
              variant="toggle"
            >
              {Radio =>
                reasons.map(reason => (
                  <Radio key={reason} value={reason} block>
                    {t(`cancel_reasons:${reason}`)}
                  </Radio>
                ))
              }
            </RadioGroup>
            {selectedOtherReason && (
              <TextAreaWrapper>
                <TextArea
                  row={3}
                  label={t(`cancel_reasons:${selected}`)}
                  value={comment}
                  onChange={this.onCommentChange}
                  maxLength={1000}
                  autoFocus
                />
              </TextAreaWrapper>
            )}
          </Wrapper>
        </Content>
        <SliderPanelFooter
          primaryAction={this.cancelOrder}
          primaryButtonText={t('RecordPanel.button_cancel_order')}
          isLoadingPrimaryButton={isLoading}
          disablePrimaryButton={
            isLoading ||
            !selected ||
            !reasons.length ||
            (selectedOtherReason && !comment)
          }
          secondaryAction={onBack}
          secondaryButtonText={t('RecordPanel.button_keep_order')}
          closeAction={onClose}
          closeButtonText={t('RecordPanel.button_close_panel')}
        />
      </Container>
    );
  }
}

const mapState = state => ({
  isLoading: createLoadingSelector(['CANCEL_ORDER'])(state),
  cancelReasons: getCancelReasons(state),
});

export default compose(
  withTranslation(),
  connect(mapState, { cancelOrder })
)(Cancel);
