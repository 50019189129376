import { channel } from 'redux-saga';
import {
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
  delay,
} from 'redux-saga/effects';

import { fetchWalletAmount, fetchWalletRecords } from 'api/mobileAPI';
import { createStatement, fetchStatement } from 'api/restAPI';
import { getUser, checkAuth } from 'store/modules/auth/selectors';
import { CHANGE_LOCATION_SUCCESS } from 'store/modules/region/actions';
import { PLACE_ORDER_SUCCESS } from 'store/modules/checkout';
import {
  getCurrentCountry,
  getCurrentLocale,
  getCurrentCountryCode,
  getTopUpDomain,
} from 'store/modules/region/selectors';
import { triggerDownload } from 'utils/helpers';
import { toLLMLower } from 'utils/locale';

import {
  FETCH_BALANCE_REQUEST,
  FETCH_BALANCE_SUCCESS,
  FETCH_WALLET_HISTORY_REQUEST,
  FETCH_WALLET_HISTORY_SUCCESS,
  FETCH_WALLET_HISTORY_FAILURE,
  FETCH_STATEMENT_REQUEST,
  FETCH_STATEMENT_FAILURE,
  TOPUP_INIT,
  TOPUP_DONE,
  FETCH_STATEMENT_SUCCESS,
} from './actionTypes';
import { initTopup, getStatementTypes, setTopUpAmount } from './index';
import { constructTopupUrl, constructWindowSpecs } from './helpers';

// According to Wallet team, the generation should take under 10 secs
// User reports getting timeout for 10 secs https://lalamove.atlassian.net/browse/TP-1486, so we update it to 60s
const STATEMENT_RETRIES = 30;
const STATEMENT_INTERVAL = 2000;

let topupWindow = null;
const topupChannel = channel();

export function* onFetchBalance() {
  const authenticated = yield select(checkAuth);
  if (!authenticated) return;

  try {
    const { creditBalance, rewardBalance } = yield call(fetchWalletAmount);
    yield put({
      type: FETCH_BALANCE_SUCCESS,
      creditBalance,
      rewardBalance,
    });
  } catch (errorCode) {
    // eslint-disable-next-line no-console
    console.error('error on fetch balance', errorCode);
  }
}

export function* onFetchWalletHistory({ query }) {
  try {
    const data = yield call(fetchWalletRecords, query);
    yield put({
      type: FETCH_WALLET_HISTORY_SUCCESS,
      method: query.method,
      data,
    });
  } catch ({ message }) {
    yield put({
      type: FETCH_WALLET_HISTORY_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* onFetchStatement({ startDate, endDate }) {
  try {
    const { corporate_id: corporateId } = yield select(getUser);
    const { xls, pdf, expiredAt } = yield call(
      generateStatement,
      corporateId,
      startDate,
      endDate
    );

    const statementTypes = yield select(getStatementTypes);
    statementTypes.forEach(fileType => {
      if (fileType === 'xls') {
        triggerDownload(xls);
      } else {
        triggerDownload(pdf);
      }
    });

    yield put({
      type: FETCH_STATEMENT_SUCCESS,
      startDate,
      endDate,
      xls,
      pdf,
      expiredAt,
    });
  } catch ({ message }) {
    yield put({
      type: FETCH_STATEMENT_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* generateStatement(corpId, startDate, endDate) {
  const countryCode = yield select(getCurrentCountryCode);

  const fromDate = new Date(startDate);
  fromDate.setHours(0, 0, 0);
  const toDate = new Date(endDate);
  toDate.setHours(23, 59, 59);

  const { id: statementId } = yield call(createStatement, {
    corporateId: corpId.toString(),
    fromDate: fromDate.toISOString(),
    toDate: toDate.toISOString(),
    countryCode,
  });
  for (let i = 1; i <= STATEMENT_RETRIES; i += 1) {
    yield delay(STATEMENT_INTERVAL);
    const {
      attributes: { generated, linkXLSX, linkPDF, expiredAt },
    } = yield call(fetchStatement, statementId);
    if (generated) {
      return { xls: linkXLSX, pdf: linkPDF, expiredAt };
    }
  }

  throw new Error(
    `Links not received after ${STATEMENT_RETRIES * STATEMENT_INTERVAL}ms`
  );
}

export function* onTopupInit() {
  const { id: countryCode } = yield select(getCurrentCountry);
  const lang = yield select(getCurrentLocale);
  const locale = toLLMLower(lang);

  const baseUrl = yield select(getTopUpDomain);
  const { access_token: token, client_id: clientId } = yield select(getUser);
  const url = constructTopupUrl({
    baseUrl,
    countryCode,
    locale,
    token,
    clientId,
  });

  if (!topupWindow) {
    const specs = constructWindowSpecs();
    topupWindow = window.open(url, 'topupWindow', specs, true);

    const listener = event => receiveTopupMessage(url, event);
    window.addEventListener('message', listener, false);

    const topupTimer = setInterval(() => {
      if (topupWindow == null || topupWindow.closed) {
        clearInterval(topupTimer);
        window.removeEventListener('message', listener, false);
        topupWindow = null;
      }
    }, 1000);
  } else topupWindow.location.href = url;
  if (window.focus) topupWindow.focus();
}

// Top-up event data (data.action):
// - CONTINUE: Top-up successful
// - TRY_AGAIN: Top-up failed, user choose to try again
// - DISMISS: Top-up failed, user choose to dismiss
// - SET_AMOUNT: Pass amount to redux store for tracking
function receiveTopupMessage(url, event) {
  const originUrl = new URL(url);
  if (!event || !event.origin.includes(originUrl.hostname)) return;

  const { data } = event;
  if (data && (data === 'TRY_AGAIN' || data.action === 'TRY_AGAIN')) {
    topupChannel.put(initTopup('retry'));
  } else if (data && (data === 'CONTINUE' || data.action === 'CONTINUE')) {
    topupChannel.put({ type: TOPUP_DONE });
    topupWindow.close();
    window.focus();
  } else if (data && (data === 'DISMISS' || data.action === 'DISMISS')) {
    topupWindow.close();
    window.focus();
  } else if (data && data.action === 'SET_AMOUNT') {
    topupChannel.put(setTopUpAmount(data.amount));
  }
}

export function* watchTopupChannel() {
  while (true) {
    const action = yield take(topupChannel);
    yield put(action);
  }
}

export default function* rootSaga() {
  yield takeLatest(
    [
      FETCH_BALANCE_REQUEST,
      TOPUP_DONE,
      PLACE_ORDER_SUCCESS,
      CHANGE_LOCATION_SUCCESS,
    ],
    onFetchBalance
  );
  yield takeLatest(FETCH_WALLET_HISTORY_REQUEST, onFetchWalletHistory);
  yield takeLatest(FETCH_STATEMENT_REQUEST, onFetchStatement);
  yield takeLatest(TOPUP_INIT, onTopupInit);
  yield fork(watchTopupChannel);
}
