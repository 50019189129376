import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { CSSTransition } from 'react-transition-group';

import Alert from 'interfaces/global/containers/Alert';
import Dialog from 'interfaces/global/containers/Dialog';
import Modal from 'interfaces/global/containers/Modal';
import Register from 'interfaces/global/containers/Register';
import Verification from 'interfaces/global/containers/Register/Verification';
import AccountVerification from 'interfaces/global/containers/Register/AccountVerification';
import Login from 'interfaces/global/containers/Login';
import Panel from 'interfaces/global/containers/Panel';
import ForgotPassword from 'interfaces/global/containers/ForgotPassword';
import ResetPassword from 'interfaces/global/containers/ResetPassword';
import VersionDisplay from 'components/VersionDisplay';
import { useResponsiveMedia } from 'components/MediaQuery';
import { createLoadingSelector } from 'store/modules/loading';

import Orders from 'interfaces/global/views/Records';
// import EditOrder from 'views/EditOrder';
import Drivers from 'interfaces/global/containers/Drivers';
import ConnectedNotFound from 'views/NotFound';
import branch from 'branch-sdk';
import Wallet from './views/Wallet';
import Home from './views/Home';
import Settings from './containers/Settings';
import Layout from './Layout';
import PrivateRoutes from './PrivateRoutes';

const App = () => {
  const { isDesktop } = useResponsiveMedia();
  const [profileKey, setProfileKey] = useState(Math.random());
  const [isEnteringProfile, setIsEnteringProfile] = useState(true);
  const isProfileLoading = useSelector(
    createLoadingSelector(['SET_PROFILE_TYPE'])
  );

  useEffect(() => {
    branch.init(process.env.REACT_APP_BRANCH_SDK_KEY, (branchInitError, _) => {
      // eslint-disable-next-line no-console
      if (branchInitError) console.error(branchInitError);
    });
  }, []);

  useEffect(() => {
    if (!isProfileLoading) {
      setProfileKey(Math.random());
    }
    setIsEnteringProfile(!isProfileLoading);
  }, [isProfileLoading]);

  return (
    <div
      className="App"
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Layout>
        <CSSTransition
          appear
          key={profileKey}
          in={isEnteringProfile}
          timeout={{
            appear: 0,
            enter: 800,
            exit: 800,
          }}
        >
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/register" component={Register} />
            <Route path="/verification" component={Verification} />
            <Route path="/account" component={AccountVerification} />
            <PrivateRoutes>
              <Switch>
                <Route exact path="/(confirmation)?" component={Home} />
                {/* <Route path="/orders/:orderId/edit" component={EditOrder} /> */}
                <Route path="/orders" component={Orders} />
                <Route path="/wallet/:type?" component={Wallet} />
                <Route path="/drivers/:type?" component={Drivers} />
                <Route path="/settings" component={Settings} />
                <Route component={ConnectedNotFound} />
              </Switch>
            </PrivateRoutes>
          </Switch>
        </CSSTransition>
      </Layout>
      <ReactTooltip id="global" />
      <Alert
        variant={isDesktop ? 'toast' : 'default'}
        messageSelector={[
          'CANCEL_ORDER',
          'RATE_ORDER',
          'UPDATE_PRIORITY_FEE',
          'FAVORITE_DRIVER',
          'BAN_DRIVER',
          'FETCH_STATEMENT',
          'FETCH_SAVED_DRIVERS',
          'REMOVE_DRIVER',
          'REDEEM_WALLET_COUPON',
          'SET_PROFILE_TYPE',
          'UPDATE_PROFILE_FLAG',
          'CHANGE_EMAIL_VERIFY_PIN',
          'RESET_PW',
          'TOPUP',
          'SETTLE_FEE',
        ]}
        persist={false}
      />
      <Panel />
      <Dialog />
      <Modal />
      <VersionDisplay />
    </div>
  );
};

export default React.memo(App);
