import React from 'react';
import Tick from 'assets/svg/vehicle-list-active-tick.svg';
import { FlatList, Icon, Info, Name, Dimensions } from './styles';

import { Service } from './types';

interface Props {
  vehicles: Service[];
  selectedIndex: number;
  onChange: (index: number) => void;
}

interface RenderFuncProps {
  data: Service;
  Item: React.FC;
  getProps: () => any; // Karang internal <Item /> props
  index: number;
}

const VehicleList: React.FC<Props> = ({
  vehicles,
  selectedIndex,
  onChange,
}) => (
  <FlatList
    hoverable
    items={vehicles}
    unique="id"
    render={({ Item: Vehicle, getProps, data, index }: RenderFuncProps) => (
      <Vehicle
        {...getProps()}
        active={index === selectedIndex}
        onClick={() => onChange(index)}
      >
        <Icon src={data.imgUri.default} />
        <Info>
          <Name>{data.name}</Name>
          <Dimensions>{data.dimensions}</Dimensions>
        </Info>
        <Icon src={Tick} active={selectedIndex === index} />
      </Vehicle>
    )}
  />
);

export default VehicleList;
