import records from 'store/modules/records';
import recordsSaga from 'store/modules/records/saga';

const recordsModule = {
  id: 'records',
  reducerMap: { records },
  sagas: [recordsSaga],
};

export default recordsModule;
