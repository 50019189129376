import { LalamoveApiError } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Response {
//   success: Boolean;
//   footprint: String;
// }
/**
 * Cancels order by ID with reason
 * @param {string} orderId order ID
 * @param {string} reason cancellation reason key, e.g. 'OTHER_BEFORE_MATCH'
 * @param {string} comment custom reason for others
 * @return {promise} ...
 * @throws ERROR_INVALID_SESSION, ERROR_INVALID_PARAMETERS, ERROR_ORDER_PICKED_UP, ERROR_EXCEED_CANCEL_PERIOD, ...
 */
export default async function cancelOrder(orderId, reason, comment) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, city } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    country: city,
    order: orderId,
    cancel_reason: reason, // List of selectable reasons are fetched from VANLOOKUP
    cancel_reason_comment: comment, // Custom reason for others
  };
  const res = await fetcher.post('vancancel', params);
  const { success, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return res;
}
