import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';

const getServiceInfoByCity = async locale => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    cityId,
    countryId,
    locale: defaultLocale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      city_id: cityId, // number
      fetch_spec_req: 1, // specialRequest, // number
      fetch_vehicle_std: 1, // vehicleStd, // number
      fetch_vehicle_price: 1, // vehiclePrice, // number
      revision: 0, // According to Brian So, should be 0 to indicate that we still need to fetch the city_info_revision, but if we save this value in localStorage, we can specify that previously stored value here
    }),
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale || defaultLocale), // some fetch service request calls do not pass in a specific locale, use current country locale instead
  };

  const { ret, data, msg } = await fetcher.get(
    'index.php?_m=city_info',
    params
  );

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  // Example response
  //   {
  //     "ret":0,
  //     "msg":"",
  //     "data":{
  //        "city_info_item":[
  //           {
  //              "porterage_item":{
  //                 "order_vehicle":[

  //                 ],
  //                 "plat_caculate_ishot":1
  //              },
  //              "enable_overseas":2,
  //              "district_list":[
  //                 {
  //                    "name":"Default zone",
  //                    "id":0
  //                 },
  //                 ...
  //              ],
  //              "virtua_default_state":2,
  //              "city_code":0,
  //              "spec_req_item":[
  //                 {
  //                    "price_value_fen":20,
  //                    "name":"Trolley",
  //                    "price_type":2,
  //                    "type":2,
  //                    "desc":"(Trolley)",
  //                    "item_id":2
  //                 },
  //                 ...
  //              ],
  //              "revision":888,
  //              "enable_virtual":0,
  //              "city_code_map":"IN_AMD",
  //              "enable_insurance":0,
  //              "name":"Ahmedabad",
  //              "vehicle_item":[
  //                 {
  //                    "surcharge_desc":"Surcharge Description",
  //                    "car_url":"",
  //                    "name":"Trcuk 8ft",
  //                    "order_vehicle_id":51,
  //                    "price_text_item":{
  //                       "exceed_segment_price":[
  //                          {
  //                             "end_exdistancekm":10,
  //                             "price_extra_fen":1000,
  //                             "start_exdistancekm":6
  //                          },
  //                          ...
  //                       ],
  //                       "text_desc":"Expense description",
  //                       "size":"Load:20kg210.0square",
  //                       "text_size":"5.0*6.0*7.0Meter",
  //                       "text_volume":"210.0square",
  //                       "text_weight":"20kg",
  //                       "price":"200dollar(s)（5km）",
  //                       "price_extra":"After：30dollar(s) \/ km"
  //                    },
  //                    "vehicle_related_business_item":[
  //                       {
  //                          "business_type":1,
  //                          "value":2
  //                       },
  //                       ...
  //                    ],
  //                    "vehicle_std_item":[
  //                       {
  //                          "name":"Turbo",
  //                          "value_fen":20000
  //                       },
  //                       ...
  //                    ],
  //                    "image_url_high_light":"https:\/\/sg-ops2-stg.lalamove.com\/rs\/dist\/images\/Truck2.png",
  //                    "is_big_vehicle":2
  //                 },
  //                 ...
  //              ],
  //              "lat_lon":{
  //                 "lon":72.585022,
  //                 "lat":23.033863
  //              },
  //              "country_id":"10000",
  //              "district_default_id":0,
  //              "city_id":11014,
  //              "name_en":"Ahmedabad",
  //              "show_moving":0,
  //              "default_use_virtual":1,
  //              "spec_req_text":"Do you need services such as moving and return trip",
  //              "enable_user_invite":0,
  //              "enable_share":0,
  //              "enable_itinery":0,
  //              "enable_user_vip":0,
  //              "enable_realname":0,
  //              "enable_csc":1,
  //              "enable_mywallet":0,
  //              "enable_hll_rating":0,
  //              "enable_carray_fees":0,
  //              "enable_spec_req":1,
  //              "enable_account_remove":0,
  //              "enable_coupon":1,
  //              "welcome_message": {
  //                "message": "Welcome",
  //                "banner": "https://sg-oimg.lalamove.com/ops/2020/07/30/1596116726768261268737.png"
  //              }
  //           }
  //        ]
  //     }
  //  }
  const {
    vehicle_item: servicesInfo,
    revision: cityInfoRevision,
    enable_proof_of_delivery: enablePOD,
    welcome_message: welcomeMessage,
  } = data.city_info_item[0];

  const services = servicesInfo.map(service => ({
    name: service.name,
    id: service.order_vehicle_id.toString(),
    vehicleStdArr: service.vehicle_std_item.map(std => ({
      name: std.name,
      price: std.value_fen,
      stdTagId: std.std_tag_id,
    })),
    vehiclePriceTextItem: {
      dimensions: service.price_text_item.text_size,
      weight: service.price_text_item.text_weight,
    },
    imgUrlHighLight: service.image_url_high_light,
    specialRequests: service.spec_req_item.map(specialRequest => ({
      id: specialRequest.item_id.toString(),
      name: specialRequest.name,
      displayName: specialRequest.display_name,
      price: specialRequest.price_value_fen,
      description: specialRequest.desc,
    })),
  }));

  return {
    services,
    cityInfoRevision,
    enablePOD,
    welcomeMessage,
  };
};
export default getServiceInfoByCity;
