import React from 'react';
import createSVGIcon from '@lalamove/karang/dist/components/Icon/icons/utils/createSVGIcon';

const bulb = createSVGIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99996 2.5C6.78972 2.5 4.16663 5.1231 4.16663 8.33333C4.16663 10.0121 4.95792 11.0414 5.59263 11.856C6.23499 12.6803 6.66663 13.2394 6.66663 14.1667C6.66663 14.6269 7.03972 15 7.49996 15H12.5C12.9602 15 13.3333 14.6269 13.3333 14.1667C13.3333 13.2394 13.7649 12.6803 14.4073 11.856C15.042 11.0414 15.8333 10.0121 15.8333 8.33333C15.8333 5.1231 13.2102 2.5 9.99996 2.5ZM8.33329 15.8333C7.87305 15.8333 7.49996 16.2064 7.49996 16.6667C7.49996 17.1269 7.87305 17.5 8.33329 17.5H11.6666C12.1269 17.5 12.5 17.1269 12.5 16.6667C12.5 16.2064 12.1269 15.8333 11.6666 15.8333H8.33329ZM18.3333 8.33333C18.3333 8.79357 17.9602 9.16667 17.5 9.16667C17.0397 9.16667 16.6666 8.79357 16.6666 8.33333C16.6666 7.8731 17.0397 7.5 17.5 7.5C17.9602 7.5 18.3333 7.8731 18.3333 8.33333ZM2.49996 9.16667C2.9602 9.16667 3.33329 8.79357 3.33329 8.33333C3.33329 7.8731 2.9602 7.5 2.49996 7.5C2.03972 7.5 1.66663 7.8731 1.66663 8.33333C1.66663 8.79357 2.03972 9.16667 2.49996 9.16667ZM4.16663 5C4.16663 5.46024 3.79353 5.83333 3.33329 5.83333C2.87306 5.83333 2.49996 5.46024 2.49996 5C2.49996 4.53976 2.87306 4.16667 3.33329 4.16667C3.79353 4.16667 4.16663 4.53976 4.16663 5ZM16.6666 5.83333C17.1269 5.83333 17.5 5.46024 17.5 5C17.5 4.53976 17.1269 4.16667 16.6666 4.16667C16.2064 4.16667 15.8333 4.53976 15.8333 5C15.8333 5.46024 16.2064 5.83333 16.6666 5.83333ZM4.16663 11.6667C4.16663 12.1269 3.79353 12.5 3.33329 12.5C2.87306 12.5 2.49996 12.1269 2.49996 11.6667C2.49996 11.2064 2.87306 10.8333 3.33329 10.8333C3.79353 10.8333 4.16663 11.2064 4.16663 11.6667ZM16.6666 12.5C17.1269 12.5 17.5 12.1269 17.5 11.6667C17.5 11.2064 17.1269 10.8333 16.6666 10.8333C16.2064 10.8333 15.8333 11.2064 15.8333 11.6667C15.8333 12.1269 16.2064 12.5 16.6666 12.5Z"
      fill="#FEA000"
    />
  </g>,
  'bulb',
  '0 0 20 20'
);

export default bulb;
