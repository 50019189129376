import { UApiError } from 'utils/helpers';
import moment from 'moment';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import { createSu } from 'utils/apiHelper';

export const FACEBOOK_LOGIN_SOURCE = 3;
export const GOOGLE_LOGIN_SOURCE = 4;
export const FACEBOOK_LOGIN_SECRET_TYPE = 2;
export const GOOGLE_LOGIN_SECRET_TYPE = 1;

const socialLogin = async ({
  socialSource,
  socialSecret,
  socialSecretType,
}) => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const { cityId, countryId } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      social_secret_type: socialSecretType,
      social_secret: socialSecret,
      social_source: socialSource,
    }),
  };

  const { ret, data, msg } = await fetcher.post(
    `?_m=social_login&city_id=${cityId}&hcountry=${countryId}&os=web&hlang=en_in&_t=${moment().unix()}&device_type=web&device_id=0&_su=${createSu()}`,
    params
  );

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  // eslint-disable-next-line camelcase
  const { token, user_fid, is_ep } = data;

  return {
    ...data,
    // eslint-disable-next-line camelcase
    access_token: token,
    user_fid,
    // eslint-disable-next-line camelcase
    profile_type: is_ep, // 1: corporate, 2: personal,
  };
};
export default socialLogin;
