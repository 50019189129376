export default class SimpleLock {
  static TIMEOUT = 2000;

  _locked = false;

  _listener = null;

  _releasePromise = null;

  lock() {
    this._locked = true;
    return this;
  }

  unlock() {
    this._locked = false;
    if (this._listener) {
      this._listener();
      this._listener = null;
      this._releasePromise = null;
    }
    return this;
  }

  release() {
    if (!this._locked) return Promise.resolve();
    if (this._releasePromise) return this._releasePromise;
    this._releasePromise = new Promise(resolve => {
      setTimeout(resolve, SimpleLock.TIMEOUT);
      this._listener = resolve;
    });
    return this._releasePromise;
  }
}
