import _mergeWith from 'lodash/mergeWith';
import {
  REGION_INIT,
  REGION_INIT_SUCCESS,
  FETCH_LOCATION_SUCCESS,
  UPDATE_COUNTRY_SETTING,
  UPDATE_CITY_SETTING,
  CHANGE_LOCATION_REQUEST,
  CHANGE_LOCALE_REQUEST,
  CHANGE_LOCATION_SUCCESS,
  FETCH_REGION_CONFIG_SUCCESS,
  UPDATE_CITY_NAMES,
} from './actions';

export const INIT_STATE = {
  currentCountry: 'HK',
  currentLocation: 'HK_HKG',
  currentLocale: 'en_HK',
  isLoading: true,
  isGlobal: false,
  cities: {
    HK_HKG: {
      id: 'HK_HKG',
      cancelReasons: {},
      ratingReasons: {},
      priorityFee: {},
      donationList: [],
      regex: {},
      urls: {},
    },
    // IN_BOM: {
    //   id: 'IN_BOM',
    //   cancelReasons: {},
    //   ratingReasons: {},
    //   priorityFee: {},
    //   donationList: [],
    //   regex: {},
    //   urls: {},
    // },
  },
  countries: {
    HK: {
      id: 'HK',
      cities: ['HK_HKG'],
      languages: [{ id: 'en_HK', enable: true }],
      translations: [{ id: 'en_HK', value: 'Hong Kong' }],
      areaCode: '852',
      topUpDomain: '',
    },
    // IN: {
    //   id: 'IN',
    //   cities: ['IN_BOM'],
    //   languages: [{ id: 'en_IN', enable: true }],
    //   translations: [{ id: 'en_IN', value: 'India' }],
    //   areaCode: '91',
    //   topUpDomain: '',
    // },
  },
};

/**
 * Customizer function for lodash mergeWith
 * https://lodash.com/docs/4.17.15#mergeWith
 *
 * When merging arrays, keep the original
 * instead of concatenating two arrays together.
 *
 * @param {*} left original value in store
 * @param {*} right new value
 */
// eslint-disable-next-line consistent-return
const customizer = (left, right) => {
  if (Array.isArray(right) && Array.isArray(right)) return left;
};

// ===== Reducer =====
const onRegionInit = state => ({
  ...state,
  isLoading: true,
});
const onRegionInitDone = (state, { location, isGlobal }) => ({
  ...state,
  isLoading: false,
  isGlobal,
  currentLocation: location,
});
const onFetchLocationSuccess = (state, { countries, cities }) => ({
  ...state,
  countries: _mergeWith({}, state.countries, countries, customizer),
  cities: _mergeWith({}, state.cities, cities, customizer),
});
const onUpdateCountrySetting = (state, { country }) => ({
  ...state,
  countries: {
    ...state.countries,
    [country.id]: {
      ...state.countries[country.id],
      ...country,
    },
  },
});
const onUpdateCitySetting = (state, { city }) => ({
  ...state,
  cities: {
    ...state.cities,
    [city.id]: {
      ...state.cities[city.id],
      ...city,
    },
  },
});
const onUpdateCityNames = (state, { cities }) => ({
  ...state,
  cities: {
    ...state.cities,
    ...cities.reduce(
      (memo, { cityCodeMap, cityName, translations }) => ({
        ...memo,
        [cityCodeMap]: {
          ...state.cities[cityCodeMap],
          cityName,
          translations,
        },
      }),
      {}
    ),
  },
});
// case 1) ✅location => assume country matches location.substr(0, 2) if it exists
// case 2) ❌location, ✅latLng, ✅country => determine closest location
// case 3) ❌location, ❌latLng, ✅country => default location within city
// Note: one of country and location should exist
const onChangeLocationRequest = (state, action) => {
  const { latLng, location, locale } = action;
  const country = action.country || location.substr(0, 2);
  // Limitation: it switches locale to default to make sure currentLocale is within the country
  // can be improved by finding the best locale match between countries (remove currentLocale here and let saga handle the locale matches)
  const currentLocale =
    locale ||
    (state.countries[country] && state.countries[country].defaultLanguage);
  // case 2, 3
  if (!location) {
    return {
      ...state,
      isLoading: true,
      currentCountry: country,
      currentLatLng: latLng,
      currentLocale,
    };
  }

  // case 1
  return {
    ...state,
    isLoading: true,
    currentCountry: country,
    currentLocation: location,
    currentLocale,
  };
};
const onChangeLocationSuccess = (state, { country, location }) => ({
  ...state,
  currentCountry: country,
  currentLocation: location,
});
const onChangeLocale = (state, { locale }) => ({
  ...state,
  currentLocale: locale,
});

const onFetchRegionConfigSuccess = (state, { countries }) => ({
  ...state,
  countries: {
    ...state.countries,
    ...countries.reduce(
      (memo, country) => ({
        ...memo,
        [country.id]: {
          ...state.countries[country.id],
          ...country,
        },
      }),
      {}
    ),
  },
});

const onFetchCitiesSuccess = (state, { cities, countryId }) => ({
  ...state,
  countries: {
    ...state.countries,
    [countryId]: {
      ...state.countries[countryId],
      cities: cities.map(c => c.cityCodeMap),
    },
  },
  cities: {
    ...state.cities,
    ...cities.reduce(
      (memo, { cityCodeMap, enableOverseas, latLon, ...city }) => ({
        ...memo,
        [cityCodeMap]: {
          ...state.cities[cityCodeMap],
          ...city,
          id: cityCodeMap,
          globalEnabled: enableOverseas === 2,
          lat: latLon.lat,
          lng: latLon.lon,
          urls: {},
        },
      }),
      {}
    ),
  },
});

export default function regionReducer(state = INIT_STATE, action) {
  const actionName = action.type;
  // matches FETCH_XX_CITIES_SUCCESS
  if (/FETCH_([A-Z]{2})_CITIES_SUCCESS/.test(actionName)) {
    return onFetchCitiesSuccess(state, action);
  }

  switch (actionName) {
    case REGION_INIT:
      return onRegionInit(state, action);
    case REGION_INIT_SUCCESS:
      return onRegionInitDone(state, action);
    case FETCH_LOCATION_SUCCESS:
      return onFetchLocationSuccess(state, action);
    case UPDATE_COUNTRY_SETTING:
      return onUpdateCountrySetting(state, action);
    case UPDATE_CITY_SETTING:
      return onUpdateCitySetting(state, action);
    case UPDATE_CITY_NAMES:
      return onUpdateCityNames(state, action);
    case CHANGE_LOCATION_REQUEST: // assume change location always valid
      return onChangeLocationRequest(state, action);
    case CHANGE_LOCATION_SUCCESS:
      return onChangeLocationSuccess(state, action);
    case CHANGE_LOCALE_REQUEST:
      return onChangeLocale(state, action);
    case FETCH_REGION_CONFIG_SUCCESS:
      return onFetchRegionConfigSuccess(state, action);
    default:
      return state;
  }
}
