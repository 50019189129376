import styled from 'styled-components';
import { Alert as LLMAlert } from '@lalamove/karang';
import { ALERT } from 'styles/zIndex';

import { mountainMeadow, treePoppy, valencia, pictonBlue } from 'styles/colors';

interface Props {
  type: 'success' | 'warning' | 'error' | 'info';
  show: boolean;
  onClick?: () => void;
}

export const TRANSITION_MS = 200;

export const StyledAlert = styled(LLMAlert).attrs({ message: '' })`
  position: absolute;
  z-index: ${ALERT};
  top: 0;
  left: 0;
  box-sizing: border-box;

  visibility: ${({ show }: Props) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }: Props) => (show ? 1 : 0)};
  width: 100%;
  background-color: ${({ type }: Props) => {
    switch (type) {
      case 'success':
        return mountainMeadow.main;
      case 'warning':
        return treePoppy.main;
      case 'error':
        return valencia.main;
      default:
        return pictonBlue.main;
    }
  }};
  cursor: ${({ onClick }: Props) => (onClick ? 'pointer' : 'default')};
  transition-property: opacity, visibility;
  transition-duration: ${TRANSITION_MS}ms;
  transition-delay: 0ms, ${({ show }: Props) => (show ? 0 : TRANSITION_MS)}ms;

  > div:last-child {
    display: inline-flex; /* for centering multiline messages */
  }
`;
