import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { CHANGE_LOCATION_REQUEST } from 'store/modules/region/actions';
import { parseInt10 } from 'utils/helpers';
import Transaction from 'models/Transaction';

import {
  FETCH_BALANCE_REQUEST,
  FETCH_BALANCE_SUCCESS,
  FETCH_WALLET_HISTORY_REQUEST,
  FETCH_WALLET_HISTORY_SUCCESS,
  FETCH_WALLET_HISTORY_FAILURE,
  FETCH_STATEMENT_REQUEST,
  FETCH_STATEMENT_SUCCESS,
  ADD_STATEMENT_TYPE,
  TOPUP_INIT,
  TOPUP_DONE,
  SET_TOPUP_AMOUNT,
} from './actionTypes';

export const fetchBalance = () => ({
  type: FETCH_BALANCE_REQUEST,
});

export const fetchWalletHistory = query => ({
  type: FETCH_WALLET_HISTORY_REQUEST,
  query,
});

export const initTopup = origin => ({
  type: TOPUP_INIT,
  origin, // action origin for tracking
});

export const fetchStatement = (startDate, endDate, fileType) => ({
  type: FETCH_STATEMENT_REQUEST,
  startDate,
  endDate,
  fileType,
});

export const addStatementType = fileType => ({
  type: ADD_STATEMENT_TYPE,
  fileType,
});

export const setTopUpAmount = amount => ({
  type: SET_TOPUP_AMOUNT,
  amount,
});

export const initStatementState = {
  startDate: '',
  endDate: '',
  xls: '',
  pdf: '',
  expiredAt: '',
};

export const initState = {
  creditBalance: 0,
  rewardBalance: 0,
  history: [],
  historyCount: 0,
  statement: initStatementState,
  statementTypes: [], // keep track statement file types being genereated
  topUpAmount: 0, // tracking
};

export function getCreditBalance({ wallet }) {
  return wallet.creditBalance;
}

export function getRewardBalance({ wallet }) {
  return wallet.rewardBalance;
}

export function getWalletHistory(state) {
  return state.wallet.history;
}

export function getWalletHistoryCount(state) {
  return state.wallet.historyCount;
}

export function getStatement(state) {
  return state.wallet.statement;
}

export function getStatementTypes(state) {
  return state.wallet.statementTypes;
}

export function getTopUpAmount(state) {
  return state.wallet.topUpAmount;
}

export function statementReducer(state = initStatementState, action) {
  switch (action.type) {
    case FETCH_STATEMENT_SUCCESS: {
      const { type, ...attributes } = action;
      return {
        ...state,
        ...attributes,
      };
    }
    default: {
      return state;
    }
  }
}

function onFetchWalletHistorySuccess(state, { method, data }) {
  const statusDict = Object.entries(data.filter).reduce(
    (memo, [a, b]) => ({ ...memo, [b]: a }),
    {}
  );
  return {
    ...state,
    creditBalance: parseFloat(data.credit_remain),
    rewardBalance: parseFloat(data.reward_remain),
    history:
      method === Transaction.METHOD_CASH
        ? data.order.map(co =>
            Transaction.New({
              type: statusDict[co.order_status],
              createdAt: new Date(co.order_date * 1000).toISOString(),
              amount: -parseInt10(co.order_amount),
              orderId: co.order_id,
              orderBy: co.order_by,
            })
          )
        : data.order
            .map(({ transactions }) =>
              transactions.map(dt =>
                Transaction.New({
                  id: dt.transactionID,
                  type: dt.type,
                  amount: dt.amount,
                  createdAt: new Date(dt.time * 1000).toISOString(),
                  orderId: dt.orderId || null,
                  orderDateTime: dt.ordertime
                    ? new Date(dt.ordertime * 1000).toISOString()
                    : null,
                  orderBy: dt.orderby,
                })
              )
            )
            .reduce((memo, ar) => memo.concat(ar), []),
    historyCount: data.record_count,
  };
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case CHANGE_LOCATION_REQUEST:
    case REQUEST_LOGOUT:
      return initState;
    case FETCH_BALANCE_SUCCESS: {
      return {
        ...state,
        creditBalance: action.creditBalance,
        rewardBalance: action.rewardBalance,
      };
    }
    case FETCH_WALLET_HISTORY_SUCCESS: {
      return onFetchWalletHistorySuccess(state, action);
    }
    case FETCH_WALLET_HISTORY_REQUEST:
    case FETCH_WALLET_HISTORY_FAILURE: {
      return {
        ...state,
        history: [],
        historyCount: 0,
      };
    }
    case ADD_STATEMENT_TYPE:
    case FETCH_STATEMENT_REQUEST: {
      return {
        ...state,
        statementTypes: state.statementTypes.includes(action.fileType)
          ? state.statementTypes
          : [...state.statementTypes, action.fileType],
      };
    }
    case FETCH_STATEMENT_SUCCESS: {
      return {
        ...state,
        statementTypes: [],
        statement: statementReducer(state.statement, action),
      };
    }
    case SET_TOPUP_AMOUNT: {
      return {
        ...state,
        topUpAmount: action.amount,
      };
    }
    case TOPUP_INIT:
    case TOPUP_DONE: {
      return {
        ...state,
        topUpAmount: 0,
      };
    }
    default:
      return state;
  }
}
