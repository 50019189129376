import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func } from 'prop-types';

import { actions } from 'store/modules/zendesk';
import withZendesk from 'store/modules/zendesk/withZendesk';

class Widget extends Component {
  static propTypes = {
    close: func.isRequired,
  };

  webWidgetObserver = null;

  componentDidMount() {
    const { close } = this.props;
    // reference: store/modules/zendesk/zendesk
    // close event will not fire for all wanted event
    // so we use dom changes to replace close event
    // EXTEND: if zendesk cover all close event can switch back
    const webWidget = document.getElementById('webWidget');
    let previous = webWidget.style.top;
    this.webWidgetObserver = new MutationObserver(() => {
      const outOfScreen = webWidget.style.top !== '';
      if (outOfScreen && previous !== webWidget.style.top) {
        close();
      }
      previous = webWidget.style.top;
    });
    this.webWidgetObserver.observe(webWidget, {
      attributes: true,
    });
  }

  componentWillUnmount() {
    this.webWidgetObserver.disconnect();
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = {
  close: actions.close,
};

export default compose(withZendesk, connect(null, mapDispatchToProps))(Widget);
