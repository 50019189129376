/* eslint-disable camelcase */

export enum ProfileType {
  BUSINESS = 1,
  PERSONAL = 2,
}

// ep_role： 0: Non-corporate identity、1: admin、2: Staff
export enum ProfileRole {
  NONBUSINESS = 0,
  ADMIN = 1,
  STAFF = 2,
}

export type User = {
  token: string;
  user_fid: string;
  sig: string;
  is_new: number;
  is_ep: ProfileType;
  access_token: string;
  profile_type: ProfileType;
  profile: {
    phone_no: string;
    avatar: string;
    nickname: string;
    sex: number;
    industry_name: string;
    license_no: string;
    real_name: string;
    member_no: string;
    member_icon: string;
    member_url: string;
    ep_role: ProfileRole;
    email: string;
    city_id: number;
    contact_email: string;
    is_send_receipt: number;
    is_send_topup_reminder: number;
    is_proof_of_delivery_required: number;
    first_name: string;
    last_name: string;
    country_code: string;
  };
};
