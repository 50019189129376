import { call, put, select, takeLatest } from 'redux-saga/effects';
import _omit from 'lodash/omit';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';

import { CHANGE_LOCALE_SUCCESS } from 'store/modules/region/actions';
import {
  getCurrentCountry,
  getCurrentLocale,
} from 'store/modules/region/selectors';

import { fetchServiceOptions } from 'api/mobileAPI';

import {
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
} from './actionTypes';
import { addSpecialRequest, setSubRequest, getSpecialRequests } from './index';

const SVC = 'SERVICE_';
const SUB_SVC = 'SUB_SERVICE_';
const REQ = 'SPECIAL_REQUEST_';
const SUB_REQ = 'SUB_SPECIAL_REQUEST_';
const toDelete = [
  'category',
  'imageUri',
  'image3dUri',
  'imageOffUri',
  'imageSlideUri',
  'iconAerialUri',
  'iconSideUri',
  'iconSideGreyscaleUri',
  'translations',
  'children',
];

export function replaceKeysPrefix(keys, prefix, newPrefix) {
  return keys.map(key => key.replace(prefix, `${newPrefix}-`));
}

export function getTranslation(translations, locale) {
  const { name, description, dimensions } = translations.find(
    item => item.id === locale
  ) || {
    name: '',
    description: '',
    dimensions: '',
  };
  return { name, description, dimensions };
}

export function sortKeysByPriority(items, prefix, parentId) {
  const keys = Object.keys(items).sort(
    (a, b) => items[a].sortingPriority - items[b].sortingPriority
  );
  return replaceKeysPrefix(keys, prefix, parentId);
}

export function extractSubRequests(parentId, subReqs, country, locale) {
  const subRequests = {};
  const subReqIds = Object.keys(subReqs);
  subReqIds.forEach(longSubReqId => {
    if (longSubReqId.startsWith(SUB_REQ)) {
      const { name, description } = getTranslation(
        subReqs[longSubReqId].translations,
        locale
      );
      const subReqId = longSubReqId.replace(SUB_REQ, '');
      const prefixedSubReqId = `${parentId}-${subReqId}`;
      subRequests[prefixedSubReqId] = {
        ...subReqs[longSubReqId],
        id: prefixedSubReqId,
        trimId: subReqId,
        fullId: longSubReqId,
        name,
        description,
        price: {
          symbol: country.currencySymbol,
          amount: subReqs[longSubReqId].price,
        },
      };
      // delete unnecessary data
      subRequests[prefixedSubReqId] = _omit(
        subRequests[prefixedSubReqId],
        toDelete
      );
    }
  });
  return subRequests;
}

export function extractRequests(parentId, reqs, country, locale) {
  const requests = {};
  let subRequests = {};

  const reqIds = Object.keys(reqs);
  reqIds.forEach(longReqId => {
    if (longReqId.startsWith(REQ)) {
      const { name, description } = getTranslation(
        reqs[longReqId].translations,
        locale
      );
      const reqId = longReqId.replace(REQ, '');
      const prefixedReqId = `${parentId}-${reqId}`;
      requests[prefixedReqId] = {
        ...reqs[longReqId],
        id: prefixedReqId,
        trimId: reqId,
        fullId: longReqId,
        name,
        description,
        price: {
          symbol: country.currencySymbol,
          amount: reqs[longReqId].price,
        },
      };

      const subReqs = requests[prefixedReqId].children;
      if (subReqs) {
        // extract sub-special requests
        const data = extractSubRequests(
          prefixedReqId,
          subReqs,
          country,
          locale
        );
        subRequests = { ...subRequests, ...data };

        requests[prefixedReqId].subSpecialRequests = sortKeysByPriority(
          subReqs,
          SUB_REQ,
          prefixedReqId
        );
      }
      // delete unnecessary data
      requests[prefixedReqId] = _omit(requests[prefixedReqId], toDelete);
    }
  });
  return { requests, subRequests };
}

export function extractServices(
  json,
  prefix,
  country,
  locale,
  parentSvcId = '',
  parentSortingPriority = 0
) {
  let services = {};
  let requests = {};
  let subRequests = {};

  Object.keys(json).forEach(longSvcId => {
    if (longSvcId.startsWith(prefix)) {
      const { children, sortingPriority } = json[longSvcId];
      const svcId = longSvcId.replace(prefix, '');
      if (
        children &&
        // if children has sub-service, extract them as service:
        Object.keys(children).some(key => key.startsWith(SUB_SVC))
      ) {
        // extract sub-services as services
        const data = extractServices(
          children,
          SUB_SVC,
          country,
          locale,
          svcId, // parent service id
          sortingPriority // parent sorting priority
        );
        services = { ...services, ...data.services };
        requests = { ...requests, ...data.requests };
        subRequests = { ...subRequests, ...data.subRequests };
      } else {
        if (
          children &&
          // only do extraction if children are all special request:
          Object.keys(children).every(key => key.startsWith(REQ))
        ) {
          // extract special requests
          const data = extractRequests(svcId, children, country, locale);
          requests = { ...requests, ...data.requests };
          subRequests = { ...subRequests, ...data.subRequests };
        }
        // save the service
        const { name, dimensions } = getTranslation(
          json[longSvcId].translations,
          locale
        );
        services[svcId] = {
          ...json[longSvcId],
          id: svcId,
          trimId: svcId,
          fullId: longSvcId,
          name,
          dimensions: dimensions || 'dimensions n/a', // fallback until config panel is updated
          specialRequests: children
            ? sortKeysByPriority(children, REQ, svcId)
            : [],
          parentTrimId: parentSvcId || svcId,
          parentSortingPriority: parentSortingPriority || sortingPriority,
        };

        const {
          imageUri,
          image3dUri,
          imageOffUri,
          iconAerialUri,
          iconSideUri,
        } = services[svcId];
        services[svcId].imgUri = {
          background: image3dUri,
          default: imageOffUri,
          selected: imageUri,
          aerial: iconAerialUri,
          color: iconSideUri || imageUri,
        };
        // delete unnecessary data
        services[svcId] = _omit(services[svcId], toDelete);
      }
    }
  });
  return { services, requests, subRequests };
}

export function normalizeData(json, country, locale) {
  // get services
  const { services, requests, subRequests } = extractServices(
    json,
    SVC,
    country,
    locale
  );

  // sort order for services
  const servicesOrder = Object.values(services)
    .sort(
      (a, b) =>
        a.parentSortingPriority - b.parentSortingPriority ||
        a.sortingPriority - b.sortingPriority
    )
    .map(service => service.id);

  return {
    services,
    servicesOrder,
    specialRequests: requests,
    subSpecialRequests: subRequests,
  };
}

export function* onFetchServices() {
  try {
    const json = yield call(fetchServiceOptions);
    const country = yield select(getCurrentCountry);
    const locale = yield select(getCurrentLocale);
    const allServices = normalizeData(json, country, locale);
    yield put({
      type: FETCH_SERVICES_SUCCESS,
      payload: allServices,
    });
  } catch (error) {
    yield put({ type: FETCH_SERVICES_FAILURE, error: error.message });
  }
}

export function* importSpecialRequests(
  serviceId,
  specialReqToImport,
  subReqToImport = {}
) {
  const requests = yield select(getSpecialRequests, serviceId);
  const validRequests = _filter(requests, item =>
    _includes(specialReqToImport, item.id)
  );
  const validSubRequests = {};
  for (const item of validRequests) {
    yield put(addSpecialRequest(item.id));

    if (
      item.subSpecialRequests &&
      item.subSpecialRequests.includes(subReqToImport[item.id])
    ) {
      validSubRequests[item.id] = subReqToImport[item.id];
      yield put(setSubRequest(item.id, subReqToImport[item.id]));
    }
  }

  return {
    specialRequests: validRequests,
    subSpecialRequests: validSubRequests,
  };
}

export default function* rootSaga() {
  yield takeLatest(
    [FETCH_SERVICES_REQUEST, CHANGE_LOCALE_SUCCESS],
    onFetchServices
  );
}
