import React from 'react';
import { node, oneOf, bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { offWhite, gray, white } from 'styles/colors';
import { fontSize } from 'styles/fonts';

const colorsMap = {
  yellow: '#F7981C',
  red: '#D8534F',
  blue: '#2FA0ED',
  darkBlue: '#0061B9',
  green: '#19BC9B',
  darkRed: '#9F231E',
};

const SCTag = styled.span.attrs(({ children }) => ({
  title: children,
}))`
  display: inline-block;
  overflow: hidden;
  max-width: 8em;
  padding: 0.4em 0.8em;
  font-size: ${fontSize.small};
  line-height: 1;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ tagType }) =>
    tagType && tagType !== 'default'
      ? css`
          background-color: ${({ solid }) =>
            solid ? `${white}` : `${colorsMap[tagType]}`};
          color: ${({ solid }) =>
            solid ? `${colorsMap[tagType]}` : `${white}`};
          border: 1px solid;
          border-color: ${({ solid }) =>
            solid ? `${colorsMap[tagType]}` : `${white}`};
        `
      : css`
          background-color: ${offWhite};
          color: ${gray};
        `};
`;

const Tag = ({ type, solid, ...rest }) => (
  <SCTag {...rest} tagType={type} solid={solid} />
);

Tag.propTypes = {
  children: node.isRequired,
  type: oneOf([
    'green',
    'yellow',
    'red',
    'blue',
    'darkBlue',
    'darkRed',
    'default',
  ]),
  solid: bool,
};

Tag.defaultProps = {
  type: 'default',
  solid: false,
};

export default Tag;
