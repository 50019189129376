/* eslint-disable import/prefer-default-export */

export const createSu = () =>
  `${new Date().getTime()}ehll${parseInt(
    Math.random() * (10000000000 + 1),
    10
  )}`;

export const ERROR_INVALID_ACCESS_RET = 3;
export const ERROR_INVALID_SESSION_RET = 10003;
export const ERROR_EXPIRED_SESSION_RET = 10001;

export const ERROR_BUSINESS_PROFILE_REMOVED_RET = 10019;
export const isAllowBusinessProfileErrorHandle = url =>
  !url.includes('get_user_info');

export const invalidSessionErrors = [
  ERROR_INVALID_ACCESS_RET,
  ERROR_INVALID_SESSION_RET,
  ERROR_EXPIRED_SESSION_RET,
];
