import i18n from 'i18next';
import ICU from 'i18next-icu';
import i18nextXHRBackend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import { toIETF } from './locale';

const { REACT_APP_VERSION } = process.env;

const icu = new ICU();

const toMomentLocale = locale => {
  const localeShort = locale.substring(0, 2);
  if (localeShort === 'en') return 'en-gb';
  return ['zh', 'pt'].includes(localeShort)
    ? toIETF(locale).toLowerCase()
    : localeShort;
};

i18n
  .use(icu)
  .use(initReactI18next)
  .use(i18nextXHRBackend)
  .on('languageChanged', async lng => {
    const localeShort = lng.substring(0, 2);
    const momentLocale = toMomentLocale(lng);
    const icuLocaleData = await import(
      `i18next-icu/locale-data/${localeShort}`
    );
    icu.addLocaleData(icuLocaleData);

    await import(`moment/locale/${momentLocale}`);
    const calendar = {
      sameDay: i18n.t('DateTime.same_day'),
      nextDay: i18n.t('DateTime.next_day'),
      nextWeek: i18n.t('DateTime.other_day'),
      sameElse: i18n.t('DateTime.other_day'),
    };

    moment.updateLocale(momentLocale, {
      calendar,
      // disable custom numerals in Hindi, other languages are not affected
      // https://github.com/moment/moment/blob/2.22.1/locale/hi.js
      preparse: string => string,
      postformat: string => string,
    });
  })
  .on('failedLoading', (lngs, namespace, key) => {
    // eslint-disable-next-line no-console
    console.warn(
      `Failed to load ${lngs} translations to namespace "${namespace}": "${key}"`
    );
  })
  .init({
    preload: ['en'],
    fallbackLng: 'en',
    backend: {
      loadPath: `/locales/{{lng}}/translation.json`,
      queryStringParams: {
        version: REACT_APP_VERSION,
      },
    },
    react: {
      wait: true,
      useSuspense: false, // not ready yet
    },
  });

export default i18n;
