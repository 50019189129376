import React from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { connect } from 'react-redux';
import { bool, string, shape, objectOf } from 'prop-types';
import { hot } from 'react-hot-loader/root';
import {
  getCurrentLocation,
  getIsRegionLoading,
  getCityDict,
  getIsGlobal,
} from 'store/modules/region/selectors';
// import { createLoadingSelector } from 'store/modules/loading';
import Container from 'components/Container';
import App from 'interfaces/origin/App';
import originModules from 'interfaces/origin/store/modules';
import GlobalApp from 'interfaces/global/App';
import globalModules from 'interfaces/global/store/modules';
import { makeMessageSelector } from 'store/modules/message';
import InitAppFailedDialog from 'interfaces/global/containers/Dialog/InitAppFailedDialog';
import LoadingBox from 'interfaces/global/containers/Login/components/LoadingBox.tsx';

const Main = ({ isLoading, isGlobal, cities, currentLocation, error }) => {
  if (
    isLoading ||
    !currentLocation ||
    !Object.keys(cities).length ||
    !cities[currentLocation]
  ) {
    return (
      <>
        <Container>
          <LoadingBox />
        </Container>
        {error && <InitAppFailedDialog message={error.message} />}
      </>
    );
  }

  const city = cities[currentLocation];

  const { id = currentLocation } = city;
  let AppComponent = App;
  let key = `origin-${id}`;
  let modules = originModules;

  if (isGlobal) {
    AppComponent = GlobalApp;
    key = `global-${id}`;
    modules = globalModules;
  }

  if (module.hot) {
    // simple replaceReducer not working, apply force reload instead
    // https://github.com/microsoft/redux-dynamic-modules/issues/53
    module.hot.decline([
      './interfaces/origin/store/modules',
      './interfaces/global/store/modules',
    ]);

    // hot reload App component only
    module.hot.accept(['./interfaces/origin/App', './interfaces/global/App']);
  }

  return (
    <DynamicModuleLoader key={key} strictMode modules={modules}>
      <AppComponent />
    </DynamicModuleLoader>
  );
};

Main.defaultProps = {
  isLoading: true,
  isGlobal: false,
  cities: {},
  error: null,
};

Main.propTypes = {
  isLoading: bool,
  isGlobal: bool,
  currentLocation: string.isRequired,
  cities: objectOf(
    shape({
      id: string.isRequired,
      globalEnabled: bool,
    })
  ),
  error: shape({}),
};

const mapState = state => ({
  isLoading: getIsRegionLoading(state),
  isGlobal: getIsGlobal(state),
  cities: getCityDict(state),
  currentLocation: getCurrentLocation(state),
  error: makeMessageSelector(['REGION_INIT'])(state),
});

export default connect(mapState)(hot(Main));
