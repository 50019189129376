import React, { Component } from 'react';
import { bool, string, func, shape, number } from 'prop-types';
import { fromEvent, from, of } from 'rxjs';
import {
  tap,
  map,
  debounceTime,
  filter,
  distinctUntilChanged,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { getPlaceAutocomplete } from 'api/uAPI';
import { noop } from 'utils/helpers';
import { withResponsiveMedia } from 'components/MediaQuery';
import { makeWaypointDescription } from 'interfaces/global/store/modules/routing/helpers';
import { MOBILE_HEADER_HEIGHT } from 'components/MobileHeader/styles';
import { Input } from '../StopItem/style';

Input.displayName = 'Input';

const parseSuggestion = (poi, cityId) => {
  const { uid, address, name, location } = poi;
  const { lat, lon } = location;

  return {
    address,
    name,
    description: makeWaypointDescription({ name, address }),
    placeId: uid,
    lat,
    lng: lon,
    cityId,
  };
};

export class StopItemInput extends Component {
  isComposingChineseInput = false;

  static defaultProps = {
    placeholder: '',
    autofocus: false,
    disabled: false,
    getToggleButtonProps: props => props,
    getInputProps: props => props,
    onOpenDropdown: noop,
    onChange: noop,
    onBlur: noop,
    selectItemAtIndex: noop,
    showDesktopDeliveryInfo: noop,
    onSuggestions: noop,
    placeType: 0,
    index: 0,
  };

  static propTypes = {
    item: shape({ description: string }).isRequired,
    placeholder: string,
    autofocus: bool,
    disabled: bool,
    getToggleButtonProps: func,
    getInputProps: func,
    onOpenDropdown: func,
    onChange: func,
    onBlur: func,
    selectItemAtIndex: func,
    showDesktopDeliveryInfo: func,
    onSuggestions: func,
    placeType: number,
    index: number,
    cityId: number.isRequired,
    isMobile: bool.isRequired,
    isDesktop: bool.isRequired,
  };

  componentDidMount() {
    const { autofocus, placeType, cityId } = this.props;
    if (autofocus) {
      // hack to handle autofocus when importing CSV or clone order
      this.input.value.length === 0 && this.input.focus();
    }

    const keyups$ = fromEvent(this.input, 'input').pipe(
      debounceTime(500),
      filter(value => !this.isComposingChineseInput), // onCompositionEnd fires AFTER this RXJS pipe fires, so placing the filter after debounceTime ensures we can check the flag after onCompositionEnd
      map(e => e.target.value),
      tap(val => val.trim() === '' && this.props.onSuggestions([])), // clear the list
      distinctUntilChanged(),
      filter(value => !!value && value.length > 1),
      switchMap(text =>
        // use config to set country and locale
        from(getPlaceAutocomplete(text, placeType)).pipe(
          catchError(_ => of([]))
        )
      ),
      map(pois => pois.map(poi => parseSuggestion(poi, cityId)))
    );

    this.subscription = keyups$.subscribe(this.props.onSuggestions);
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  scrollToInput = () => {
    if (this.props.isMobile && this.input) {
      const { top } = this.input.getBoundingClientRect();
      window.scrollBy({
        top: top - MOBILE_HEADER_HEIGHT,
        behavior: 'smooth',
      });
    }
  };

  handleInputFocus = () => {
    const { item, showDesktopDeliveryInfo } = this.props;
    item.description && showDesktopDeliveryInfo();

    this.scrollToInput();

    // prevent user from losing the first typed-in character
    // when she is quickly adding stops
    this.input.value.length > 1 && this.input.select();
  };

  handleClick = e => {
    const { onOpenDropdown } = this.props;
    this.scrollToInput();
    if (!e.target.value) onOpenDropdown();
  };

  handleGrow = e => {
    this.props.onChange(e);
    if (this.input instanceof Element) {
      this.input.style.height = 'auto';
      this.input.style.height = `${this.input.scrollHeight}px`;
    }
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  handleCompositionEnd = () => {
    this.isComposingChineseInput = false;
  };

  handleCompositionUpdate = () => {
    this.isComposingChineseInput = true;
  };

  render() {
    const {
      getInputProps,
      placeholder,
      disabled,
      onBlur,
      index,
      isMobile,
    } = this.props;
    return (
      <Input
        {...getInputProps({
          ref: ref => {
            this.input = ref;
          },
          style: {
            height: this.input ? this.input.scrollHeight : 'auto',
            fontSize: isMobile ? 16 : 14, // set font size to 16 to avoid iOS safari auto zoom
            // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
          },
          onChange: this.handleGrow,
          onFocus: this.handleInputFocus,
          onBlur,
          onClick: this.handleClick,
          onKeyPress: this.handleKeyPress,
          placeholder,
          disabled,
          onCompositionUpdate: this.handleCompositionUpdate,
          onCompositionEnd: this.handleCompositionEnd,
        })}
        data-cy={`stop-item-input-${index}`}
      />
    );
  }
}

export default withResponsiveMedia(StopItemInput);
