import { CouponPayTypes, CouponStatuses } from './types.ts';

export function getPrice({ pricing: { price } }) {
  return price;
}

export function getPriceRaw({ pricing: { priceRaw } }) {
  return priceRaw;
}

export function getPricingError(state) {
  return state.pricing.error;
}

export const getSelectedOnlineCoupon = state =>
  state.pricing.selectedOnlineCoupon;
export const getSelectedCashCoupon = state => state.pricing.selectedCashCoupon;

export const getAvailableCoupons = state =>
  state.pricing.orderCouponList.filter(
    item => item.status === CouponStatuses.AVAILABLE
  );

export const getShowFooter = state => {
  const { total, items } = getPrice(state);
  return total > 0 || items.length > 0;
};

/**
 * Returns `coupons` redeemed within the order
 * that are usable in the current payment method,
 * sorted by reversed order of redemption.
 * @param {*} state
 * @returns {Array} `coupons`
 */
export const getAvailableRedeemedCoupons = state => {
  const coupons = getAvailableCoupons(state);
  const { redeemedCouponIds } = state.pricing;

  return coupons
    .filter(coupon => redeemedCouponIds.includes(coupon.coupon_id))
    .sort(
      (a, b) =>
        redeemedCouponIds.indexOf(a.coupon_id) -
        redeemedCouponIds.indexOf(b.coupon_id)
    );
};

export const getBestOnlinePaymentOnlyCoupon = state =>
  // First coupon found is the best
  state.pricing.orderCouponList.find(coupon => {
    const isOnlinePaymentOnlyCoupon =
      coupon.pay_type === CouponPayTypes.ONLINE_ONLY;
    const isAvailableCoupon = coupon.unavailable_status === 0;
    const isNotApplicableCurrentPaymentMethodCoupon =
      coupon.unavailable_status === 2;

    return (
      isOnlinePaymentOnlyCoupon &&
      (isAvailableCoupon || isNotApplicableCurrentPaymentMethodCoupon)
    );
  });
