import React, { Component, Fragment } from 'react';
import Big from 'big.js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import {
  arrayOf,
  bool,
  func,
  number,
  shape,
  string,
  instanceOf,
} from 'prop-types';
import moment from 'moment';
import _pickBy from 'lodash/pickBy';
import { Pagination, SearchInput } from '@lalamove/karang';
import styled from 'styled-components';
import {
  initTopup,
  fetchCouponList,
  getChargeList,
  handleCouponExchange,
} from 'interfaces/global/store/modules/wallet/actions';
import { getUser } from 'store/modules/auth/selectors';
import { getCreditBalance, getRewardBalance } from 'store/modules/wallet';

import { createLoadingSelector } from 'store/modules/loading';
import Coupon from 'components/Coupon';
import RedeemCoupon from 'components/RedeemCoupon';
import DateRangePicker from 'components/DateRangePicker';
import PageHeader from 'components/PageHeader';
import { noop, parseUrlParams, encodeQueryData } from 'utils/helpers';
import Spinner from 'components/Spinner';
import { makeMessageSelector, dismissMessage } from 'store/modules/message';
import convertWalletCoupon from '../convertWalletCoupon.ts';
import WalletBanner from './WalletBanner';
import {
  SELECTED_DATE_RANGE,
  MAX_ROWS,
  DATE_DATA_FORMAT,
  MAX_RANGE_LIMIT_DAYS,
  PAST_DATE_LIMIT_DAYS,
} from '../config';
import { validateDates } from '../helpers';
import { Container, SearchForm, CouponsTitle } from '../style';

const now = moment();
const DEFAULT_END_DATE = now.format(DATE_DATA_FORMAT);
const DEFAULT_START_DATE = now
  .subtract(SELECTED_DATE_RANGE - 1, 'days')
  .format(DATE_DATA_FORMAT);

const DateFilter = styled.div`
  margin-right: 2rem;
  opacity: 0;
  pointer-events: none;
`;
const CouponContainer = styled.div`
  width: 450px;
  margin-left: 2rem;
`;
const UserCouponNum = styled.p`
  margin: 32px 0 16px;
  color: #737373;
  font-size: 12px;
`;
const StyledSpinner = styled(Spinner)`
  display: block;
  margin: 2rem auto;
`;
const RedeemCouponContainer = styled.div`
  margin: 18px 0;
`;
const CouponItem = styled.div`
  margin: 16px 0;
`;
export class WalletCoupon extends Component {
  static defaultProps = {
    t: noop,
    creditBalance: Big(0),
    rewardBalance: Big(0),
    couponNum: 0,
    couponList: [],
    dismissMessage: noop,
    fetchCouponList: noop,
    initTopup: noop,
    getChargeList: noop,
    handleCouponExchange: func,
    isLoading: false,
    listIsLoading: false,
    apiError: null,
  };

  static propTypes = {
    location: shape({}).isRequired,
    t: func,
    creditBalance: instanceOf(Big),
    rewardBalance: instanceOf(Big),
    couponNum: number,
    couponList: arrayOf(shape()),
    dismissMessage: func,
    fetchCouponList: func,
    initTopup: func,
    getChargeList: func,
    isLoading: bool,
    listIsLoading: bool,
    historyReplace: func.isRequired,
    handleCouponExchange: func,
    isCorporate: bool.isRequired,
    Tabs: func.isRequired,
    apiError: shape({ message: string }),
  };

  // driven state by URL params
  params = this.getStateFromUrlParams();

  state = {
    start: this.params.start,
    end: this.params.end,
    current: this.params.current,
    max: this.params.max,
    search: this.params.search,
    couponRulesId: '0',
    listSuccess: false,
    redeemCode: '',
  };

  componentDidMount() {
    const { historyReplace, location } = this.props;
    if (!location.search) {
      historyReplace({
        search: encodeQueryData(_pickBy(this.state)),
      });
    }
    this.fetchCouponList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.current !== this.state.current) {
      const { historyReplace } = this.props;
      historyReplace({ search: encodeQueryData(_pickBy(this.state)) });
      this.fetchCouponList();
    }
    if (!this.props.isLoading && prevProps.isLoading) {
      if (!this.props.apiError) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ redeemCode: '' });
        this.initCouponList();
      }
    }
    if (!this.props.listIsLoading && prevProps.listIsLoading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ listSuccess: true });
    }
  }

  getStateFromUrlParams() {
    const { location } = this.props;
    const { start, end, current, max, search } = parseUrlParams(
      location.search
    );
    const areValidDates = validateDates(start, end);
    return {
      start: areValidDates ? start : DEFAULT_START_DATE,
      end: areValidDates ? end : DEFAULT_END_DATE,
      current: current ? +current : 1,
      max: max && max <= MAX_ROWS ? +max : MAX_ROWS,
      search: search || '',
    };
  }

  fetchCouponList = () => {
    // eslint-disable-next-line no-shadow
    const { fetchCouponList } = this.props;
    fetchCouponList({ ...this.state });
  };

  handleDateChange = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      this.setState({
        start: startDate.toISOString(true).split('T')[0],
        end: endDate.toISOString(true).split('T')[0],
        current: 1,
      });
    }
  };

  initCouponList = () => {
    this.setState({ current: 1 });
    this.fetchCouponList();
  };

  handlePagination = nextPage => {
    this.setState({ current: nextPage });
  };

  handleSearch = e => {
    e.preventDefault();
    this.setState({
      search: e.target.elements[0].value.trim(),
      current: 1,
    });
  };

  handleCouponExchange = () => {
    this.props.handleCouponExchange({
      code: this.state.redeemCode,
    });
  };

  handleCouponExchangeChange = e => {
    this.props.dismissMessage('FETCH_COUPONEXCHANGE');
    this.setState({
      redeemCode: e.target.value.toUpperCase(),
    });
  };

  initTopup = () => {
    this.props.initTopup('wallet');
    this.props.getChargeList();
  };

  openUseRules = id => {
    const { couponRulesId } = this.state;
    this.setState({ couponRulesId: couponRulesId === id ? '0' : id });
  };

  transformCouponList = (couponList, t) => {
    const listItems = [];
    let isFirstAvailableCoupon = true;
    let isFirstPastCoupon = true;
    couponList.forEach(item => {
      const couponProps = convertWalletCoupon(item);
      if (couponProps.unavailable) {
        if (isFirstPastCoupon) {
          listItems.push(
            <CouponsTitle key="Coupon.past_coupons_title">
              {t('Coupon.past_coupons_title')}
            </CouponsTitle>
          );
          isFirstPastCoupon = false;
        }
      } else if (isFirstAvailableCoupon) {
        listItems.push(
          <CouponsTitle key="Coupon.available_coupons_title">
            {t('Coupon.available_coupons_title')}
          </CouponsTitle>
        );
        isFirstAvailableCoupon = false;
      }
      listItems.push(
        <CouponItem key={item.coupon_id}>
          <Coupon
            {...couponProps}
            onClickDetail={() => this.openUseRules(item.coupon_id)}
            isExpanded={this.state.couponRulesId === item.coupon_id}
          />
        </CouponItem>
      );
    });

    return listItems;
  };

  render() {
    const {
      t,
      creditBalance,
      rewardBalance,
      couponNum,
      couponList,
      isLoading,
      isCorporate,
      Tabs,
      apiError,
      listIsLoading,
    } = this.props;
    const { start, end, current, max, search, listSuccess } = this.state;
    const total = couponNum;
    const fromIndex = total ? current * max - max + 1 : 0;
    let toIndex = total ? current * max : 0;
    if (toIndex > total) toIndex = total;

    const topControls = (
      <Fragment>
        {isCorporate && (
          <SearchForm onSubmit={this.handleSearch}>
            <SearchInput
              name="search"
              defaultValue={search}
              placeholder={t('Wallet.placeholder_search')}
            />
          </SearchForm>
        )}
        <WalletBanner
          rewardBalance={rewardBalance}
          creditBalance={creditBalance}
          openTopUp={this.initTopup}
        />
      </Fragment>
    );
    const ListItems = this.transformCouponList(couponList, t);
    return (
      <Container>
        <PageHeader
          topControls={topControls}
          title={t('Wallet.title_wallet')}
          tabs={<Tabs />}
          headerEnd={
            <Fragment>
              <DateFilter>
                <DateRangePicker
                  startDate={moment(start)}
                  endDate={moment(end)}
                  onDatesChange={this.handleDateChange}
                  minDate={moment().subtract(PAST_DATE_LIMIT_DAYS, 'days')}
                  maxDate={moment()}
                  maxDays={MAX_RANGE_LIMIT_DAYS}
                />
              </DateFilter>
              <Pagination
                current={current}
                pageSize={max}
                total={total}
                description={t('Wallet.pagination', {
                  fromIndex,
                  toIndex,
                  total,
                })}
                onChange={this.handlePagination}
              />
            </Fragment>
          }
        />
        <CouponContainer>
          <RedeemCouponContainer>
            <RedeemCoupon
              value={this.state.redeemCode}
              error={apiError?.message}
              onRedeem={this.handleCouponExchange}
              onChange={this.handleCouponExchangeChange}
              placeholder={t('Coupon.exchange_placeholder')}
              isLoading={isLoading}
            />
          </RedeemCouponContainer>
          {listIsLoading && <StyledSpinner />}
          {listSuccess && couponList.length === 0 && (
            <UserCouponNum>{t('Coupon.no_coupon_tip')}</UserCouponNum>
          )}
          {ListItems}
        </CouponContainer>
      </Container>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_COUPONEXCHANGE']);
const couponLoadingSelector = createLoadingSelector(['FETCH_COUPONLIST']);

const mapState = state => {
  const { corporate_id: corporateId } = getUser(state);
  return {
    creditBalance: getCreditBalance(state),
    rewardBalance: getRewardBalance(state),
    isLoading: loadingSelector(state),
    listIsLoading: couponLoadingSelector(state),
    isCorporate: Boolean(corporateId),
    apiError: makeMessageSelector(['FETCH_COUPONEXCHANGE'])(state),
    couponNum: state.wallet.coupon.couponNum,
    couponList: state.wallet.coupon.couponList,
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapState, {
    dismissMessage,
    fetchCouponList,
    initTopup,
    getChargeList,
    handleCouponExchange,
    historyReplace: replace,
  })
)(WalletCoupon);
