import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';

// eslint-disable-next-line import/no-anonymous-default-export
export default async body => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const { accessToken, cityId, countryId, locale } = await fetcher.getState();
  const { coordinates, serviceType } = body;
  const params = {
    args: JSON.stringify({
      coordinates,
      order_vehicle_id: serviceType,
      city_id: cityId,
    }),
    token: accessToken,
    device_type: 'web',
    device_id: 0,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    channel: 'huolala',
    os: 'web',
  };

  const { ret, data, msg } = await fetcher.get(
    'index.php?_m=get_service_area',
    params
  );

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data;
};
