import Fetcher from './Fetcher';

const {
  REACT_APP_REGION_CONFIG_API,
  REACT_APP_REGION_CONFIG_ENV,
} = process.env;

// Check /CHANGELOG.md for revision number should be used
const commonParams = {
  env: REACT_APP_REGION_CONFIG_ENV,
};

export default class RegionConfigFetcher extends Fetcher {
  static uniqueName = 'RegionConfigFetcher';

  prepareBaseUrl = (state, { customDomain } = {}) =>
    customDomain || REACT_APP_REGION_CONFIG_API;

  prepareHeaders = (method, state, options) => {
    let headers = {
      ...options.headers,
    };
    if (['POST', 'PATCH'].includes(method)) {
      headers = {
        ...headers,
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      };
    }
    return headers;
  };

  prepareQuery = params => ({
    ...commonParams,
    ...params,
  });

  prepareBody = params =>
    new URLSearchParams({
      ...commonParams,
      ...params,
    });
}
