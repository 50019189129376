import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import moment from 'moment';
import _isEqual from 'lodash/isEqual';
import _pickBy from 'lodash/pickBy';
import { Pagination, SearchInput } from '@lalamove/karang';
import styled from 'styled-components';

import { getUser } from 'store/modules/auth/selectors';
import {
  fetchWalletHistory,
  getCreditBalance,
  getRewardBalance,
  getWalletHistory,
  getWalletHistoryCount,
} from 'store/modules/wallet';
import { createLoadingSelector } from 'store/modules/loading';
import DateRangePicker from 'components/DateRangePicker';
import PageHeader from 'components/PageHeader';
import Transaction from 'models/Transaction';
import { noop, parseUrlParams, encodeQueryData } from 'utils/helpers';

import WalletBanner from './WalletBanner'; // eslint-disable-line import/no-named-as-default
import WalletTable from './WalletTable';
import {
  SELECTED_DATE_RANGE,
  MAX_ROWS,
  DATE_DATA_FORMAT,
  MAX_RANGE_LIMIT_DAYS,
  PAST_DATE_LIMIT_DAYS,
} from '../config';
import { validateDates } from '../helpers';
import { Container, SearchForm } from '../style';

const now = moment();
const DEFAULT_LIST_TYPE = Transaction.METHOD_CREDIT;
const DEFAULT_END_DATE = now.format(DATE_DATA_FORMAT);
const DEFAULT_START_DATE = now
  .subtract(SELECTED_DATE_RANGE - 1, 'days')
  .format(DATE_DATA_FORMAT);

const DateFilter = styled.div`
  margin-right: 2rem;
`;

class TransactionTable extends Component {
  static defaultProps = {
    t: noop,
    creditBalance: 0,
    rewardBalance: 0,
    method: DEFAULT_LIST_TYPE,
    historyList: [],
    historyCount: 0,
    fetchWalletHistory: noop,
    isLoading: false,
  };

  static propTypes = {
    location: shape({}).isRequired,
    t: func,
    creditBalance: number,
    rewardBalance: number,
    method: string,
    historyList: arrayOf(shape()),
    historyCount: number,
    fetchWalletHistory: func,
    isLoading: bool,
    historyReplace: func.isRequired,
    isCorporate: bool.isRequired,
    Tabs: func.isRequired,
  };

  // driven state by URL params
  params = this.getStateFromUrlParams();

  state = {
    start: this.params.start,
    end: this.params.end,
    current: this.params.current,
    max: this.params.max,
    search: this.params.search,
  };

  componentDidMount() {
    const { historyReplace, location, method } = this.props;

    if (!location.search) {
      historyReplace({
        search: encodeQueryData(_pickBy(this.state)),
      });
      this.fetchWalletHistory(method);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { historyReplace, method, creditBalance, rewardBalance } = this.props;

    if (prevProps.method !== method) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ current: 1 }, () => {
        historyReplace({
          search: encodeQueryData(
            _pickBy({
              ...this.state,
              current: 1,
            })
          ),
        });
        this.fetchWalletHistory(method);
      });
    }

    if (prevProps.method === method && !_isEqual(prevState, this.state)) {
      historyReplace({ search: encodeQueryData(_pickBy(this.state)) });
      this.fetchWalletHistory(method);
    }

    if (
      prevProps.creditBalance !== creditBalance ||
      prevProps.rewardBalance !== rewardBalance
    ) {
      this.fetchWalletHistory(method);
    }
  }

  getStateFromUrlParams() {
    const { location, method } = this.props;
    const { start, end, current, max, search } = parseUrlParams(
      location.search
    );
    const areValidDates = validateDates(start, end, method);
    return {
      start: areValidDates ? start : DEFAULT_START_DATE,
      end: areValidDates ? end : DEFAULT_END_DATE,
      current: current ? +current : 1,
      max: max && max <= MAX_ROWS ? +max : MAX_ROWS,
      search: search || '',
    };
  }

  fetchWalletHistory = method => {
    // eslint-disable-next-line no-shadow
    const { fetchWalletHistory } = this.props;
    fetchWalletHistory({ method, ...this.state });
  };

  handleDateChange = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      this.setState({
        start: startDate.toISOString(true).split('T')[0],
        end: endDate.toISOString(true).split('T')[0],
        current: 1,
      });
    }
  };

  handlePagination = nextPage => {
    this.setState({ current: nextPage });
  };

  handleSearch = e => {
    e.preventDefault();
    this.setState({
      search: e.target.elements[0].value.trim(),
      current: 1,
    });
  };

  render() {
    const {
      t,
      creditBalance,
      rewardBalance,
      historyList,
      historyCount: total,
      isLoading,
      isCorporate,
      method,
      Tabs,
    } = this.props;
    const { start, end, current, max, search } = this.state;
    const fromIndex = total ? current * max - max + 1 : 0;
    let toIndex = total ? current * max : 0;
    if (toIndex > total) toIndex = total;

    const topControls = (
      <Fragment>
        {isCorporate && (
          <SearchForm onSubmit={this.handleSearch}>
            <SearchInput
              name="search"
              defaultValue={search}
              placeholder={t('Wallet.placeholder_search')}
            />
          </SearchForm>
        )}
        <WalletBanner
          rewardBalance={rewardBalance}
          creditBalance={creditBalance}
        />
      </Fragment>
    );

    return (
      <Container>
        <PageHeader
          topControls={topControls}
          title={t('Wallet.title_wallet')}
          tabs={<Tabs />}
          headerEnd={
            <Fragment>
              <DateFilter>
                <DateRangePicker
                  startDate={moment(start)}
                  endDate={moment(end)}
                  onDatesChange={this.handleDateChange}
                  minDate={moment().subtract(PAST_DATE_LIMIT_DAYS, 'days')}
                  maxDate={moment()}
                  maxDays={MAX_RANGE_LIMIT_DAYS}
                />
              </DateFilter>
              <Pagination
                current={current}
                pageSize={max}
                total={total}
                description={t('Wallet.pagination', {
                  fromIndex,
                  toIndex,
                  total,
                })}
                onChange={this.handlePagination}
                loading={isLoading}
              />
            </Fragment>
          }
        />
        <WalletTable
          method={method}
          historyList={historyList}
          loading={isLoading}
        />
      </Container>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_WALLET_HISTORY']);

const mapState = state => {
  const { corporate_id: corporateId } = getUser(state);
  return {
    creditBalance: getCreditBalance(state),
    rewardBalance: getRewardBalance(state),
    historyList: getWalletHistory(state),
    historyCount: getWalletHistoryCount(state),
    isLoading: loadingSelector(state),
    isCorporate: Boolean(corporateId),
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapState, { fetchWalletHistory, historyReplace: replace })
)(TransactionTable);
