import Big from 'big.js';
import { getCurrentCountry } from 'store/modules/region/selectors';

const primitiveCurrency = (
  amount,
  showDecimal,
  currencyExponent,
  currencySymbol
) => {
  const sign = amount < 0 ? '-' : '';
  const value = showDecimal
    ? Math.abs(amount).toFixed(currencyExponent)
    : Math.abs(amount).toFixed(2).replace(/\.00/g, '');
  return `${sign}${currencySymbol || '$'}${value}`;
};

const bigCurrency = (amount, showDecimal, currencyExponent, currencySymbol) => {
  const sign = amount.s < 0 ? '-' : '';
  const value = showDecimal
    ? amount.abs().toFixed(currencyExponent)
    : amount.abs().toFixed(2).replace(/\.00/g, '');
  return `${sign}${currencySymbol || '$'}${value}`;
};

class Formatter {
  constructor(store) {
    this.store = store;
  }

  bindStore(store) {
    this.store = store;
  }

  currency(amount) {
    if (!this.store) return amount;

    const state = this.store.getState();
    const { showDecimal, currencyExponent, currencySymbol } = getCurrentCountry(
      state
    );

    return amount instanceof Big
      ? bigCurrency(amount, showDecimal, currencyExponent, currencySymbol)
      : primitiveCurrency(
          amount,
          showDecimal,
          currencyExponent,
          currencySymbol
        );
  }
}

export default new Formatter();
