import { LalamoveApiError } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Request {
//   country: String; // e.g. HK_HKG
//   access_token: String;
//   client_id: Number | String;
// }
// interface Response {
//   success: Boolean;
//   code: String;
//   footprint: String;
// }
export default async function vanauthlogin(accessToken, clientId) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  const { city } = await fetcher.getState();
  const res = await fetcher.post('vanauthlogin', {
    country: city,
    access_token: accessToken,
    client_id: clientId,
  });
  const { success, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return res;
}
