import React from 'react';
import styled from 'styled-components';
import {
  RadioGroup,
  Heading,
  HeadingGroup,
  Input,
  TextArea,
} from '@lalamove/karang';
import { success as SuccessIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { whenMobile } from 'components/MediaQuery';

import { GROUND } from 'styles/zIndex';
import { orange, darkGray, gray, nobel } from 'styles/colors';
import spinnerImg from 'assets/loader.gif';
import onlinePaymentIcon from 'assets/svg/online_payment.svg';
import onlinePaymentActiveIcon from 'assets/svg/online_payment_a.svg';
import cashIcon from 'assets/svg/cash.svg';
import cashActiveIcon from 'assets/svg/cash_a.svg';
import { ICON_SIZE } from './config';

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.6em;
  max-height: 1.6em;
  padding: 0.75em 1em;
`;

export const Con = styled.span`
  display: inline-block;
  margin-right: 0.5em;
`;

export const Form = styled.fieldset`
  /*margin: 0 0 0 calc(24px + 0.5em);*/
  padding: 0;
  border: 0;
  margin: 0;

  ${whenMobile} {
    margin-bottom: 32px;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
`;

export const FormItem = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  border: ${({ bordered }) => (bordered ? '1px' : 0)} solid ${darkGray};
  margin-bottom: 1em;
  > * {
    flex: 1 1 auto;
  }
  ${/* sc-selector */ FormGroup} & {
    width: 50%;
  }
  &:not(:nth-child(1)) {
    border-left: 0;
  }
`;

export const DateContactFormItem = styled(FormItem)`
  ${whenMobile} {
    height: 44px;
  }
`;

export const ScInput = styled(Input)`
  > * {
    box-sizing: border-box;
  }

  ${whenMobile} {
    input {
      font-size: 16px;
    }
  }

  ${/* sc-selector */ FormItem} & {
    position: relative;
    width: 100%;

    &:focus-within {
      z-index: ${GROUND + 1}; /* to show active collapse border */
    }
  }
  ${/* sc-selector */ FormItem}:not(:nth-child(1)) & {
    margin-left: -1px; /* collapse border */
  }
`;

export const ScTextArea = styled(TextArea)`
  width: 100%;

  ${whenMobile} {
    textarea {
      font-size: 16px;
    }
  }
`;

export const RadioLabel = styled.span`
  color: ${({ checked }) => (checked ? orange : gray)};
  font-weight: bold;
`;

export const RadioDesc = styled.p`
  padding: 0 0.75em 0.4em 3.6em;
  margin: 0;
  color: ${({ checked }) => (checked ? orange : gray)};
  font-size: 12px;
  line-height: 1.5;
`;

const StyledHeadingGroup = styled(HeadingGroup)`
  margin-bottom: 0.5em;
`;

const FlexHeading = styled(Heading)`
  ${whenMobile} {
    &&& {
      flex: 1;
    }
  }
`;

// eslint-disable-next-line react/prop-types
export const CheckoutHeading = ({ children, text }) => (
  <StyledHeadingGroup>
    <Con>{children}</Con>
    <FlexHeading size="large" htmlTag="h2">
      <legend>{text}</legend>
    </FlexHeading>
  </StyledHeadingGroup>
);

export const CheckIcon = styled(SuccessIcon)`
  ${/* sc-selector */ FormItem} & {
    position: absolute;
    z-index: ${GROUND + 1};
    top: 50%;
    right: 1em;
    margin-top: ${-(ICON_SIZE / 2)}px;
  }
`;

export const Spinner = styled.span`
  position: absolute;
  z-index: ${GROUND + 1};
  top: 1em; /* hack to avoid error msg*/
  right: 1em;
  width: ${ICON_SIZE * 0.8}px;
  height: ${ICON_SIZE * 0.8}px;
  background-image: url(${spinnerImg});
  background-position: center;
  background-size: cover;
`;

export const MobilePaymentItem = styled.div`
  display: flex;
  padding: 14px 10px 14px 8px;
  border: 1px solid ${nobel[400]};
`;

export const StyledRadioGroup = styled(RadioGroup)`
  > div > div > div label {
    position: relative;

    > span:last-child {
      width: calc(100% - 16px - 0.5em);
      white-space: nowrap;
    }
  }
`;

// eslint-disable-next-line react/prop-types
export const OnlinePaymentIcon = () => (
  <img src={onlinePaymentIcon} style={{ width: '12px' }} alt="" />
);

// eslint-disable-next-line react/prop-types
export const OnlinePaymentActiveIcon = () => (
  <img src={onlinePaymentActiveIcon} style={{ width: '12px' }} alt="" />
);

// eslint-disable-next-line react/prop-types
export const CashIcon = () => (
  <img src={cashIcon} style={{ width: '20px' }} alt="" />
);

// eslint-disable-next-line react/prop-types
export const CashActiveIcon = () => (
  <img src={cashActiveIcon} style={{ width: '20px' }} alt="" />
);
