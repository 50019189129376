import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { string, bool } from 'prop-types';
import { useResponsiveMedia } from 'components/MediaQuery';

import { ARE_YOU_SURE_YOU_WANT_TO_DO_THIS } from 'styles/zIndex';
import {
  getIsGlobal,
  getCurrentLocale,
  getCurrentLocation,
} from 'store/modules/region/selectors';

const {
  REACT_APP_HOST_ENV,
  REACT_APP_VERSION,
  REACT_APP_COMMIT_HASH,
} = process.env;

const debugInfo = {
  env: REACT_APP_HOST_ENV,
  version: REACT_APP_VERSION,
  commitId: REACT_APP_COMMIT_HASH || '',
  userAgent: navigator.userAgent || '',
};

const Wrapper = styled.div`
  position: fixed;
  z-index: ${ARE_YOU_SURE_YOU_WANT_TO_DO_THIS};
  top: 0;
  right: 0;
  padding: 1px 2px;
  background: rgba(255, 255, 255, ${({ hideMe }) => (hideMe ? `0` : `0.9`)});
  color: rgba(0, 0, 0, ${({ hideMe }) => (hideMe ? `0` : `1`)});
  font-size: 0.6em;
  user-select: ${({ hideMe }) => (hideMe ? `none` : `auto`)};
`;

export const VersionDisplay = ({ isGlobal, location, lang }) => {
  const { isMobile } = useResponsiveMedia();

  const hideMe = isMobile || REACT_APP_HOST_ENV === 'production';

  const { env, version, commitId, userAgent } = debugInfo;
  const global = isGlobal ? 'GLOBAL' : 'LLM';
  const infos = [env, version, location, global, lang, commitId, userAgent];
  return <Wrapper hideMe={hideMe}>{infos.join(' / ')}</Wrapper>;
};

VersionDisplay.propTypes = {
  isGlobal: bool.isRequired,
  location: string.isRequired,
  lang: string.isRequired,
};

const mapStateToProps = state => ({
  isGlobal: getIsGlobal(state),
  location: getCurrentLocation(state),
  lang: getCurrentLocale(state),
});

export default connect(mapStateToProps)(VersionDisplay);
