import Big from 'big.js';
import { toLLMLower } from 'utils/locale';
import {
  UApiError,
  globalOrderStatusMap,
  globalCompletedOrderStatusIDs,
  globalCancelledOrderStatusIDs,
} from 'utils/helpers';
import { getUser } from 'store/modules/auth/selectors';
import {
  getCurrentCountry,
  getCurrentCity,
  getCurrentLocale,
} from 'store/modules/region/selectors';
import FetcherFactory from '../FetcherFactory';
import FetcherLockService from '../FetcherLockService';
import UAPIFetcher from '../UAPIFetcher';

const PODStatusMap = [
  'PENDING',
  'SIGNED',
  'DELIVERED',
  'FAILED',
  'NOT_APPLICABLE',
];

export default async function fetchOrderDetails({
  orderUuid = '',
  interestId = 0,
}) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  await FetcherLockService.langMutex.release();
  await FetcherLockService.accessTokenMutex.release();

  const state = await fetcher.getRootState();
  const { access_token: accessToken, profile_type: profileType } = getUser(
    state
  );
  const { cityZone, cityId } = getCurrentCity(state);
  const { countryId, currencyRate } = getCurrentCountry(state);
  const locale = getCurrentLocale(state);

  const params = {
    args: JSON.stringify({
      order_uuid: orderUuid,
      interest_id: interestId,
      daylight_zone: cityZone,
    }),
    token: accessToken,
    hcountry: countryId,
    city_id: cityId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
  };

  const { ret, data, msg } = await fetcher.get('?_m=order_detail', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return parseRecord(data.order_detail_info, currencyRate);
}

export function parseRecord(record, currencyRate) {
  return {
    order: {
      id: record.order_uuid,
      refId: String(record.order_display_id),
      status: getStatus({
        id: record.order_status,
        parentID:
          globalOrderStatusMap[
            Object.keys(globalOrderStatusMap).find(
              key => globalOrderStatusMap[key].id === record.order_status
            )
          ].parentID,
      }),
      orderStatus: record.order_status,
      deliveryDatetime: {
        time: new Date(record.order_time_hts).toISOString(),
        daylightSavingsTimeType: record.daylight_type || 0,
      },
      completionDatetime: record.order_complete_ts_hts,
      serviceTypeId: record.order_vehicle_id.toString(),
      creationDatetime: new Date(record.create_time_hts).toISOString(),
      driverId: record.driver_id,
      canShowDriverInfo: !!record.driver_id,
      creatorName: record.user_name,
      creatorPhone: record.user_tel,
      noteToDriver: record.remark,
      paymentMethodId: record.pay_type,
      userRating: parseInt(record.rating_by_user, 10),
      edited: false,
      editable: false,
      specialRequests: record.spec_req_price_item.map(item => item.name),
      subRequests: record.vehicle_std_price_item.map(item => item.name),
      collectionFen: record.collection_fen,
      currentNode: record.in_node,
      podEnabled:
        record.is_proof_of_delivery_required === 1 ||
        record.is_proof_of_delivery_required === '1',
      preferFavorite: record.send_type === 1,
      onlyFavorite: record.send_type === 2,
    },
    driver: getDriverInfo(record),
    route: {
      waypoints: record.addr_info.map(address => ({
        name: address.name,
        placeId: address.placeId,
        lat: address.lat_lon.lat,
        lng: address.lat_lon.lon,
        pod: getPOD(address.proof_of_delivery),
      })),
      deliveryInfo: record.addr_info.map(address => ({
        name: address.contacts_name,
        phone: address.contacts_phone_no,
        addressDetails: [address.house_number, address.addr]
          .filter(Boolean)
          .join(' , '),
      })),
    },
    priceInfo: getPriceInfo(record),
    payStatus: record.pay_status,
    appeal: {
      unpaid: record.price_info.unpaid,
      appeal: record.price_info.appeal,
      appealHandleInfo: record.appeal_handle_info,
      appealStatus: record.appeal_status,
      sendBillRemark: record.send_bill_remark,
      handleDesc: record.appeal_note_msg,
      billTypeList: record.bill_type_list || [],
    },
    priorityFee: {
      min: Big(record.tips_min).div(currencyRate),
      max: Big(record.tips_max).div(currencyRate),
      options: record.tips_option.map(option => Big(option).div(currencyRate)),
    },
    tips: Big(record.tips_price_fen).div(currencyRate),
  };
}

function getPOD(pod) {
  if (pod && Object.keys(pod).length) {
    return {
      status: PODStatusMap[pod.proof_of_delivery_status],
      signedBy: pod.signed_by,
      images: pod.image_urls,
      remarks: pod.remarks,
      time: new Date(pod.create_time * 1000).toISOString(),
    };
  }
  return null;
}

function getPriceInfo(record) {
  return {
    price_item: record.price_item,
    price_info: {
      ...record.price_info,
      final_price: record.price_info.final_price || record.price_info.total,
    },
  };
}

function getDriverInfo(record) {
  if (record.driver_info && Object.keys(record.driver_info).length) {
    return {
      id: record.driver_info.driver_fid,
      name: record.driver_info.name,
      vehicleTypeId: record.vehicle_type_name,
      licensePlate: record.driver_info.license_plate,
      avgRating: parseFloat(record.driver_info.avg_rating),
      photoUri: record.driver_info.photo,
      canTrackDriver: false,
      favorited: !!record.driver_info.is_favorite,
      banned: !!record.driver_info.is_ban,
    };
  }
  return {
    vehicleTypeId: record.vehicle_type_name,
  };
}

function getStatus(status) {
  return [
    ...globalCompletedOrderStatusIDs,
    ...globalCancelledOrderStatusIDs,
  ].includes(status.id)
    ? status.parentID
    : status.id;
}
