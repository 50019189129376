import pricing from './reducer';
import saga from './saga';
import * as actions from './actions';
import * as selectors from './selectors';

const pricingModule = {
  id: 'pricing',
  reducerMap: { pricing },
  sagas: [saga],
};

export { pricingModule as default, saga, actions, selectors };
