import moment from 'moment';
import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import {
  getCurrentCountry,
  getCurrentCity,
  getCurrentLocale,
} from 'store/modules/region/selectors';
import { getUser } from 'store/modules/auth/selectors';
import UAPIFetcher from '../UAPIFetcher';
import FetcherFactory from '../FetcherFactory';

type UserPlaceOrderInfo = {
  // eslint-disable-next-line camelcase
  has_place_order: number;
};

export default async function getUserPlaceOrderInfo(): Promise<UserPlaceOrderInfo> {
  const fetcher = FetcherFactory.make(UAPIFetcher);

  const state = await fetcher.getRootState();

  const { access_token: accessToken, profile_type: profileType } = getUser(
    state
  );
  const { cityId } = getCurrentCity(state);
  const { countryId } = getCurrentCountry(state);
  const locale = getCurrentLocale(state);

  const params = {
    args: {},
    city_id: cityId,
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
    os: 'web',
    _t: moment().unix(),
  };

  const { ret, msg, data } = await fetcher.get(
    '?_m=get_user_place_order_info',
    params
  );

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data;
}
