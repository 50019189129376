import { LalamoveApiError } from 'utils/helpers';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Request {
//   uuid: String;
//   country_code: String; // e.g. 852
//   app_type: 'webapp';
//   sms_code: String;
//   auth_client: String;
// }
// interface Response {
//   code: string;
//   success: Boolean;
// }
// pin verification for forgot password
export default async function getPendingSmsUserByUuid(
  uuid,
  smsCode,
  username,
  areaCode
) {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  const params = {
    uuid,
    country_code: areaCode,
    app_type: 'webapp',
    sms_code: smsCode,
    auth_client: username,
  };
  const res = await fetcher.post('getpendingsmsuserbyuuid', params);
  const { success, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return res;
}
