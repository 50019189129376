import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import _curry from 'lodash/curry';
import _isMatch from 'lodash/isMatch';
import _pick from 'lodash/pick';
import { noop } from 'utils/helpers';
import MobileHeader from 'components/MobileHeader';
import { withResetScrollOnMount } from 'components/ResetScrollOnMount';
import { whenMobile, withResponsiveMedia } from 'components/MediaQuery';
import {
  getIsFavDriverOnly,
  getUser,
} from 'interfaces/global/store/modules/auth/selectors';
import { getPrice } from 'interfaces/global/store/modules/pricing/selectors';
import {
  getDefaultDatetime,
  getDeliveryDatetime,
  getContact,
  getHasFavDriver,
  getPreferFavorite,
  getDriverNote,
  getCheckoutErrors,
  getCheckoutDirty,
} from 'interfaces/global/store/modules/checkout/selectors';
import {
  initDate,
  changeDate,
  updateContact,
  toggleFavDriver,
  setCheckoutError,
  updateNote,
  getUnpaidOrder,
  submitOrder,
} from 'interfaces/global/store/modules/checkout/actions';
import { setSelectedCoupon } from 'interfaces/global/store/modules/pricing/actions';
import { PaymentMethods } from 'interfaces/global/store/modules/checkout/types';
import { getCurrentCountry } from 'store/modules/region/selectors';
import { createLoadingSelector } from 'store/modules/loading';
import uniformInvoiceDonations from 'interfaces/global/config/uniformInvoiceDonations.json';
import {
  saveUserContact,
  loadUserContact,
  IMMEDIATE_ORDER_OFFSET_MIN,
  SCHEDULED_ORDER_OFFSET_MIN,
} from 'interfaces/global/store/modules/checkout/helpers';
import Footer from 'interfaces/global/containers/PlaceOrderFooter';
import { track } from 'interfaces/global/store/modules/tracking/actions';

import DateContact from './components/DateContact';
import Driver from './components/Driver';
import Payment from './components/Payment';
import SelectCoupon from './components/SelectCoupon';
import UniformInvoice from './components/UniformInvoice';

function prevent(e) {
  e.preventDefault();
}

const { bool, func, number, shape, string } = PropTypes;

/**
 * Gets the next allowed time based on current time and offset
 * @param {Object} currentTime moment object
 * @param {integer} offset scheduled order city offset
 * @param {boolean} isScheduled is current order scheduled or immediate
 * @return {Object} next allowed time in moment object
 */
export const genNextAllowedTime = (currentTime, isScheduled) => {
  let hrs = currentTime.hours();
  let min = currentTime.minutes();

  const offset = isScheduled
    ? SCHEDULED_ORDER_OFFSET_MIN
    : IMMEDIATE_ORDER_OFFSET_MIN;
  min += offset;

  // re-adjust hrs & min:
  if (min >= 60) {
    min -= 60;
    hrs += 1;
    if (hrs === 24) hrs = 0;
  }
  return moment(currentTime).hour(hrs).minute(min).second(0);
};

export const FormGroup = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${whenMobile} {
    padding: 0 14px;
  }
`;

export class Checkout extends Component {
  static defaultProps = {
    t: noop,
    initDate: noop,
    changeDate: noop,
    updateContact: noop,
    updateNote: noop,
    toggleFavDriver: noop,
    setCheckoutError: noop,
    defaultDatetime: '',
    deliveryDatetime: '',
    contact: { name: '', phone: '' },
    note: '',
    hasFavDriver: false,
    isFavDriverOnly: false,
    preferFavorite: false,
    error: { name: '', phone: '' },
    price: { fee: 0 },
    isSubmitting: false,
    userContact: {},
    dirty: {},
    getUnpaidOrder: noop,
  };

  static propTypes = {
    t: func,
    initDate: func,
    changeDate: func,
    updateContact: func,
    updateNote: func,
    toggleFavDriver: func,
    setCheckoutError: func,
    defaultDatetime: string,
    deliveryDatetime: string,
    contact: shape({ name: string, phone: string }),
    note: string,
    hasFavDriver: bool,
    isFavDriverOnly: bool,
    preferFavorite: bool,
    error: shape({ name: string, phone: string }),
    price: shape({ fee: number }),
    isSubmitting: bool,
    userContact: shape({ name: string, phone: string, country: string }),
    dirty: shape({ name: bool, phone: bool }),
    country: shape({ id: string }).isRequired,
    getUnpaidOrder: func,
    isMobile: bool.isRequired,
    submitOrder: func.isRequired,
    setSelectedCoupon: func.isRequired,
    track: func.isRequired,
  };

  state = {
    isScheduled: window.location.search.includes('scheduled'),
    removedCoupon: false,
  };

  componentDidMount() {
    const { isScheduled } = this.state;
    const { isMobile } = this.props;

    if (isMobile) {
      window.scrollTo(0, 0);
    }

    const now = moment();
    const defaultDate = genNextAllowedTime(now, isScheduled).toISOString(true);
    this.props.initDate(defaultDate);
    this.props.changeDate(defaultDate, this.state.isScheduled);

    const getFilledInUserContact = () => {
      const { dirty, contact } = this.props;
      const { name, phone } = this.props.userContact;

      let userContact = null;
      if (name && !contact.name && !dirty.name) {
        this.props.updateContact({ name }, false);
        userContact = { name };
      }
      if (phone && !contact.phone && !dirty.phone) {
        this.props.updateContact({ phone }, false);
        userContact = { ...userContact, phone };
      }

      return userContact;
    };

    // prefill contact
    const loadedUserContact = loadUserContact();

    if (loadedUserContact) {
      this.props.updateContact(loadedUserContact, false);
    } else {
      const filledInUserContact = getFilledInUserContact();

      if (filledInUserContact) saveUserContact(filledInUserContact);
    }

    this.props.getUnpaidOrder();
  }

  componentDidUpdate(_, prevState) {
    // remove the coupon for CASH when device changed from desktop to mobile
    if (this.props.isMobile) {
      if (!this.state.removedCoupon) {
        this.props.setSelectedCoupon({
          selectedPaymentMethodId: PaymentMethods.CASH.id,
          coupon: null,
        });
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ removedCoupon: true });
      }
    } else if (prevState.removedCoupon) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ removedCoupon: false });
    }
  }

  handleNoteChange = e => {
    this.props.updateNote(e.target.value);
  };

  handleContactChange = newContact => {
    const { contact, userContact } = this.props;
    const changedContact = _pick(newContact, ['name', 'phone']);
    const isDirty = !_isMatch(userContact, changedContact);
    this.props.updateContact(changedContact, isDirty);
    saveUserContact({ ...contact, ...changedContact });
  };

  handleContactBlur = newContact => {
    const { userContact } = this.props;
    this.handleContactChange(newContact);
    if (newContact.name !== userContact.name) {
      this.props.track('name_updated');
    }
    if (newContact.phone !== userContact.phone) {
      this.props.track('contact_number_updated');
    }
  };

  handleDatetimeChange = (datetime, isScheduled) => {
    const { defaultDatetime, deliveryDatetime } = this.props;
    const dirty =
      moment
        .duration(moment(datetime).diff(moment(defaultDatetime)))
        .as('minutes') > 0;
    this.setState({ isScheduled });
    this.props.changeDate(datetime, dirty || isScheduled);

    this.props.track('pick_up_time_updated', {
      original_pickuptime: deliveryDatetime,
      new_pickuptime: datetime,
    });
  };

  handleToggleFavDriver = checked => {
    const { isFavDriverOnly } = this.props;
    this.props.toggleFavDriver(checked);
    this.props.track('favorite_driver_updated', {
      is_favorite_driver: checked,
      type: isFavDriverOnly ? 'favorite_only' : 'favorite_first',
    });
  };

  render() {
    /* eslint-disable no-shadow */
    const {
      t,
      defaultDatetime,
      deliveryDatetime,
      hasFavDriver,
      isFavDriverOnly,
      preferFavorite,
      price,
      isSubmitting,
      contact,
      error,
      note,
      setCheckoutError,
      country: currentCountry,
      isMobile,
    } = this.props;

    const { isScheduled } = this.state;

    return price.total <= 0 && !price.items.length ? (
      // Redirect to Place Order page if invalid route fee passed
      <Redirect to="/" />
    ) : (
      <>
        {isMobile && <MobileHeader />}
        <FormGroup onSubmit={prevent}>
          <DateContact
            title={t('Checkout.heading_date_contact')}
            onError={setCheckoutError}
            onDatetimeChange={this.handleDatetimeChange}
            onContactChange={this.handleContactChange}
            onContactBlur={this.handleContactBlur}
            placeholderTime={t('Checkout.label_now')}
            nowButtonText={t('Checkout.button_now')}
            labelName={t('Checkout.placeholder_name')}
            labelPhone={t('Checkout.placeholder_phone')}
            name={contact.name}
            phone={contact.phone}
            errors={{ name: t(error.name), phone: t(error.phone) }}
            defaultDatetime={defaultDatetime}
            deliveryDatetime={deliveryDatetime}
            isScheduled={isScheduled}
          />
          <Driver
            title={t('Checkout.heading_driver_notes')}
            hasFavDriver={hasFavDriver}
            isFavDriverOnly={isFavDriverOnly}
            preferFavorite={preferFavorite}
            note={note}
            onFavToggle={this.handleToggleFavDriver}
            onNoteChange={this.handleNoteChange}
            limitMsgFunc={_curry(t, 2)('Checkout.label_driver_note_left')}
            errorMsgFunc={_curry(t, 2)('Checkout.label_driver_note_error')}
            placeholder={t('Checkout.placeholder_driver_notes')}
            toolTipText={t('Checkout.tip_view_details')}
            label={t(
              isFavDriverOnly
                ? 'Checkout.checkbox_favourite_driver_only'
                : 'Checkout.checkbox_favourite_driver'
            )}
            favDriverInfoText={t('Checkout.tip_favourite_driver')}
            isMobile={isMobile}
          />
          <Payment />
          {!isMobile && <SelectCoupon />}
          {currentCountry.id === 'TW' && (
            <UniformInvoice donationList={uniformInvoiceDonations} />
          )}
        </FormGroup>
        {isMobile && (
          <Footer onSubmit={this.props.submitOrder} isLoading={isSubmitting} />
        )}
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  'PLACE_ORDER',
  'VALIDATE_SERVICE_AREA',
]);

const mapState = state => {
  const {
    profile: {
      first_name: firstName,
      phone_no: phoneNumber,
      country_code: countryCode,
    },
  } = getUser(state);

  const name = firstName;
  const phone = countryCode ? phoneNumber.replace(`+${countryCode}`, '') : '';
  const userContact = { name, phone };

  return {
    defaultDatetime: getDefaultDatetime(state),
    deliveryDatetime: getDeliveryDatetime(state),
    contact: getContact(state),
    note: getDriverNote(state),
    hasFavDriver: getHasFavDriver(state),
    isFavDriverOnly: getIsFavDriverOnly(state),
    preferFavorite: getPreferFavorite(state),
    error: getCheckoutErrors(state),
    price: getPrice(state),
    isSubmitting: loadingSelector(state),
    userContact,
    dirty: getCheckoutDirty(state),
    country: getCurrentCountry(state),
  };
};

export default compose(
  withTranslation(),
  withResponsiveMedia,
  withResetScrollOnMount,
  connect(mapState, {
    initDate,
    changeDate,
    updateContact,
    updateNote,
    toggleFavDriver,
    setCheckoutError,
    getUnpaidOrder,
    submitOrder,
    setSelectedCoupon,
    track,
  })
)(Checkout);
