import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { func, number } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@lalamove/karang';
import { walletAdd as WalletAddIcon } from '@lalamove/karang/dist/components/Icon/icons';

import { initTopup } from 'store/modules/wallet';
import { offWhite, silver } from 'styles/colors';
import formatter from 'utils/formatter';
import { noop } from 'utils/helpers';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25em 2em;
  margin-left: auto;
  border-radius: 2em 0 0 2em;
  background-color: ${offWhite};
`;

export const Label = styled.span`
  margin-right: 1em;
  color: ${silver};
  font-size: 0.75em;
`;

export const Value = styled.span`
  margin-right: 0.5em;
  font-size: 1.25em;
`;

export const WalletBanner = ({
  t,
  rewardBalance,
  creditBalance,
  initTopup, // eslint-disable-line no-shadow
}) => {
  const onClick = () => initTopup('wallet'); // pass action origin for tracking
  return (
    <Container>
      <Label>{t('Wallet.label_rewards')}</Label>
      <Value>{formatter.currency(rewardBalance)}</Value>
      <Label>{t('Wallet.label_credits')}</Label>
      <Value>{formatter.currency(creditBalance)}</Value>
      <Button
        variant="primary"
        icon={<WalletAddIcon />}
        onClick={onClick}
        solid
      >
        {t('Wallet.button_topup')}
      </Button>
    </Container>
  );
};

WalletBanner.propTypes = {
  t: func,
  rewardBalance: number,
  creditBalance: number,
  initTopup: func,
};

WalletBanner.defaultProps = {
  t: noop,
  rewardBalance: 0,
  creditBalance: 0,
  initTopup: noop,
};

export default compose(
  withTranslation(),
  connect(null, { initTopup })
)(WalletBanner);
