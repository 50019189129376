import storage from 'utils/storage';
import { toIETF } from 'utils/locale';

const config = {
  key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  v: 3.36, // TODO: update to 3.39 [https://developers.google.com/maps/documentation/javascript/versions]
  language: toIETF(storage.getItem('userLang') || 'en'),
};

export default config;
