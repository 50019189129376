export const FETCH_RECORDS_REQUEST = 'FETCH_RECORDS_REQUEST';
export const FETCH_RECORDS_SUCCESS = 'FETCH_RECORDS_SUCCESS';
export const FETCH_RECORDS_FAILURE = 'FETCH_RECORDS_FAILURE';

export const VOID_RECORDS = 'VOID_RECORDS';

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';
export const FETCH_CANCEL_REASONS_REQUEST = 'FETCH_CANCEL_REASONS_REQUEST';
export const FETCH_CANCEL_REASONS_SUCCESS = 'FETCH_CANCEL_REASONS_SUCCESS';
export const FETCH_RECORD_REQUEST = 'FETCH_RECORD_REQUEST';
export const FETCH_RECORD_SUCCESS = 'FETCH_RECORD_SUCCESS';
export const FETCH_RECORD_FAILURE = 'FETCH_RECORD_FAILURE';

export const FAVORITE_DRIVER_REQUEST = 'FAVORITE_DRIVER_REQUEST';
export const FAVORITE_DRIVER_SUCCESS = 'FAVORITE_DRIVER_SUCCESS';
export const FAVORITE_DRIVER_FAILURE = 'FAVORITE_DRIVER_FAILURE';

export const BAN_DRIVER_REQUEST = 'BAN_DRIVER_REQUEST';
export const BAN_DRIVER_SUCCESS = 'BAN_DRIVER_SUCCESS';
export const BAN_DRIVER_FAILURE = 'BAN_DRIVER_FAILURE';

export const ORDERAPPEAL_UPDATE_REQUEST = 'ORDERAPPEAL_UPDATE_REQUEST';
export const ORDERAPPEAL_UPDATE_SUCCESS = 'ORDERAPPEAL_UPDATE_SUCCESS';
export const ORDERAPPEAL_UPDATE_FAILURE = 'ORDERAPPEAL_UPDATE_FAILURE';

export const PAY_APPEAL_REQUEST = 'PAY_APPEAL_REQUEST';
export const PAY_APPEAL_SUCCESS = 'PAY_APPEAL_SUCCESS';
export const SETTLE_FEE_SUCCESS = 'SETTLE_FEE_SUCCESS';

export const CLONE_ORDER = 'CLONE_ORDER';

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';

export const UPDATE_PRIORITY_FEE_REQUEST = 'UPDATE_PRIORITY_FEE_REQUEST';
export const UPDATE_PRIORITY_FEE_SUCCESS = 'UPDATE_PRIORITY_FEE_SUCCESS';
export const UPDATE_PRIORITY_FEE_FAILURE = 'UPDATE_PRIORITY_FEE_FAILURE';

export const RATE_ORDER_REQUEST = 'RATE_ORDER_REQUEST';
export const RATE_ORDER_SUCCESS = 'RATE_ORDER_SUCCESS';
export const RATE_ORDER_FAILURE = 'RATE_ORDER_FAILURE';

export const TRACK_HELP_CENTER_TAPPED = 'TRACK_HELP_CENTER_TAPPED';

export const clearRecords = () => ({ type: VOID_RECORDS });

export const fetchRecords = params => ({
  type: FETCH_RECORDS_REQUEST,
  params,
});

export const cancelOrder = (orderId, reason, status, reasonId) => ({
  type: CANCEL_ORDER_REQUEST,
  orderId,
  reason,
  status,
  reasonId,
});

export const fetchCancelReasons = () => ({
  type: FETCH_CANCEL_REASONS_REQUEST,
});

export const fetchRecord = orderId => ({
  type: FETCH_RECORD_REQUEST,
  orderId,
});

export const favoriteDriver = driverId => ({
  type: FAVORITE_DRIVER_REQUEST,
  driverId,
});

export const banDriver = driverId => ({
  type: BAN_DRIVER_REQUEST,
  driverId,
});

export const updateOrderAppeal = ({ refId, orderId, appealItems, desc }) => ({
  type: ORDERAPPEAL_UPDATE_REQUEST,
  refId,
  orderId,
  appealItems,
  desc,
});
export const payAppeal = ({ orderId, amount }) => ({
  type: PAY_APPEAL_REQUEST,
  orderId,
  amount,
});

export const cloneOrder = orderId => ({
  type: CLONE_ORDER,
  orderId,
});

export const updatePriorityFee = (orderId, amount) => ({
  type: UPDATE_PRIORITY_FEE_REQUEST,
  orderId,
  amount,
});

export const rateOrder = ({
  orderId,
  driverId,
  rating,
  reasons,
  suggestions,
  option,
}) => ({
  type: RATE_ORDER_REQUEST,
  orderId,
  driverId,
  rating,
  reasons,
  suggestions,
  option,
});

export const settleFeeSuccess = () => ({
  type: SETTLE_FEE_SUCCESS,
  meta: { type: 'success', message: 'Records.payment_settled_successfully' },
});

export const trackHelpCenterTapped = () => ({
  type: TRACK_HELP_CENTER_TAPPED,
});
