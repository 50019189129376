import { shape, string, number } from 'prop-types';

export const LocationShape = shape({
  id: string,
  name: string,
  lat: number,
  lng: number,
  placeId: string,
});

export const DeliveryInfoShape = shape({
  name: string,
  phone: string,
  addressDetails: string,
});
