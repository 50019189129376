import auth from 'store/modules/auth';
import authSaga from 'store/modules/auth/saga';
import { settingsSaga } from 'store/modules/settings';
import { refreshLoginSession } from 'store/modules/auth/actions';

const authModule = {
  id: 'auth',
  reducerMap: { auth },
  sagas: [authSaga, settingsSaga],
  initialActions: [refreshLoginSession()],
};

export default authModule;
