import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonWithComponent } from '@lalamove/karang';

const LinkButton = styled(ButtonWithComponent(Link))`
  border-radius: 2px;
  box-sizing: border-box;
  margin-top: 20px;
  text-align: center;
`;

export default LinkButton;
