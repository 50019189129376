import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { closeDialog } from 'store/modules/ui';

import Dialog from 'components/Dialog';
import { noop } from 'utils/helpers';

class EditOrderDiscardDialog extends Component {
  static defaultProps = {
    t: noop,
    onClose: noop,
    onSubmit: noop,
    onDiscard: noop,
    nextPath: '',
  };

  static propTypes = {
    t: func,
    onClose: func,
    onSubmit: func,
    onDiscard: func,
    nextPath: string,
  };

  handleDiscard = () => {
    const { nextPath, onClose, onDiscard } = this.props;
    onClose();
    onDiscard(nextPath);
  };

  render() {
    const { t, onClose, onDiscard, nextPath, ...props } = this.props;
    return (
      <Dialog
        title={t('EditOrder.dialog_title_discard_changes')}
        subtitle={t('EditOrder.dialog_text_discard_changes')}
        primaryButtonText={t('EditOrder.dialog_button_discard')}
        primaryAction={this.handleDiscard}
        secondaryButtonText={t('EditOrder.dialog_button_back')}
        secondaryAction={onClose}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={false}
        {...props}
      />
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    onClose: closeDialog,
  })
)(EditOrderDiscardDialog);
