import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  race,
  take,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _isEqual from 'lodash/isEqual';
import {
  fetchOrderHistoryList,
  fetchOrderById,
  cancelOrder,
  updatePriorityFee,
  rateOrder,
  favoriteDriver,
  banDriver,
} from 'api/mobileAPI';
import { editOrder, fetchClientOrder } from 'api/restAPI';

import { updateContact, updateNote } from 'store/modules/checkout';
import { getCurrentCountryCode } from 'store/modules/region/selectors';
import {
  doImport,
  importDeliveryInfos,
  getFilledWayptsIds,
} from 'store/modules/routing';
import { getDeliveryInfoByWayptIds } from 'store/modules/routing/deliveryInfo';
import { setService, getServices } from 'store/modules/services';
import { importSpecialRequests } from 'store/modules/services/saga';
import { openDialog, closePanel } from 'store/modules/ui';
import {
  fetchQuotation,
  FETCH_QUOTATION_SUCCESS,
  FETCH_QUOTATION_FAILURE,
  getLatestQuotation,
} from 'store/modules/quotation';

import { LOCATION_REGEX, zip } from 'utils/helpers';

import {
  FETCH_RECORDS_REQUEST,
  FETCH_RECORDS_SUCCESS,
  FETCH_RECORDS_FAILURE,
  FETCH_RECORD_REQUEST,
  FETCH_RECORD_SUCCESS,
  FETCH_RECORD_FAILURE,
  FETCH_CLIENT_ORDER_REQUEST,
  FETCH_CLIENT_ORDER_SUCCESS,
  FETCH_CLIENT_ORDER_FAILURE,
  ORDER_ADD,
  CLONE_ORDER,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILURE,
  UPDATE_PRIORITY_FEE_REQUEST,
  UPDATE_PRIORITY_FEE_SUCCESS,
  UPDATE_PRIORITY_FEE_FAILURE,
  RATE_ORDER_REQUEST,
  RATE_ORDER_SUCCESS,
  RATE_ORDER_FAILURE,
  FAVORITE_DRIVER_REQUEST,
  FAVORITE_DRIVER_SUCCESS,
  FAVORITE_DRIVER_FAILURE,
  BAN_DRIVER_REQUEST,
  BAN_DRIVER_SUCCESS,
  BAN_DRIVER_FAILURE,
  INIT_EDIT_REQUEST,
  INIT_EDIT_SUCCESS,
  INIT_EDIT_FAILURE,
  EDIT_ORDER_REQUEST,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
} from './actionTypes';
import {
  getRecordCount,
  getOrder,
  getRouteByOrderId,
  getPriceByOrderId,
} from './index';

let previousData;

export function* onFetchRecords({ query }) {
  try {
    const previousCount = yield select(getRecordCount);
    const { data, records, recordCount } = yield call(
      fetchOrderHistoryList,
      query
    );
    const hasSameCount = previousCount === recordCount;
    const hasSameData = _isEqual(previousData, data);

    if (!hasSameCount || !hasSameData) {
      previousData = data;

      for (const row of records) {
        yield put({
          type: ORDER_ADD,
          row,
        });
      }
    }

    yield put({
      type: FETCH_RECORDS_SUCCESS,
      ids: records.map(item => item.order.id),
      count: recordCount,
    });
  } catch (e) {
    yield put({ type: FETCH_RECORDS_FAILURE });
  }
}

export function* onFetchRecord({ orderId }) {
  try {
    const data = yield call(fetchOrderById, orderId);
    yield put({ type: FETCH_RECORD_SUCCESS, row: data });
  } catch (e) {
    yield put({ type: FETCH_RECORD_FAILURE });
  }
}

export function* onFetchClientOrder({ clientOrderId }) {
  try {
    const co = yield call(fetchClientOrder, clientOrderId);
    const { cityCode } = co;
    const [, country] = LOCATION_REGEX.exec(cityCode);
    const currentCountry = yield select(getCurrentCountryCode);
    // filter the order that not in current country
    if (country !== currentCountry) throw new Error();
    yield put({ type: FETCH_CLIENT_ORDER_SUCCESS, clientOrder: co });
  } catch (e) {
    yield put({ type: FETCH_CLIENT_ORDER_FAILURE });
  }
}

export function* onCloneOrder({ orderId }) {
  const order = yield select(getOrder, orderId);
  const {
    specialRequests,
    subRequests,
    creatorName,
    creatorPhone,
    noteToDriver,
    serviceTypeId,
  } = order;

  const { waypoints, deliveryInfo } = yield select(getRouteByOrderId, orderId);
  const wayptsToImport = [...waypoints]; // create shadow copy
  const updatedWayptIds = yield call(doImport, wayptsToImport);
  yield call(importDeliveryInfos, deliveryInfo, updatedWayptIds);
  yield put(
    updateContact({
      name: creatorName,
      phone: creatorPhone || '',
    })
  );
  yield put(updateNote(noteToDriver));

  const services = yield select(getServices);
  const serviceIndex = services.findIndex(
    service => service.id === serviceTypeId
  );

  if (serviceIndex > -1) {
    yield put(setService(serviceIndex));
    yield call(
      importSpecialRequests,
      serviceTypeId,
      specialRequests,
      subRequests
    );
  }

  yield put(push('/'));
}

export function* onInitEdit({ orderId }) {
  try {
    yield call(onFetchRecord, { orderId });
    yield call(onFetchClientOrder, { clientOrderId: orderId });
    const { waypoints, deliveryInfo } = yield select(
      getRouteByOrderId,
      orderId
    );
    const price = yield select(getPriceByOrderId, orderId);
    const wayptsToImport = [...waypoints]; // create shadow copy
    const updatedWayptIds = yield call(doImport, wayptsToImport, orderId);
    yield call(importDeliveryInfos, deliveryInfo, updatedWayptIds);
    yield put({
      type: INIT_EDIT_SUCCESS,
      orderId,
      price,
    });
  } catch ({ message }) {
    yield put({
      type: INIT_EDIT_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

// EXTEND: isProofOfDeliveryRequired should be delivery based when backend support
export function constructDeliveries(
  deliveryInfos,
  isProofOfDeliveryRequired = false
) {
  const [, ...tail] = deliveryInfos;
  return zip(deliveryInfos, tail).map(([start, end]) => ({
    startAddressBlock: start.addressDetails,
    startAddressFloor: '',
    startAddressRoom: '',
    startContactName: start.name,
    startContactPhone: start.phone,
    endAddressBlock: end.addressDetails,
    endAddressFloor: '',
    endAddressRoom: '',
    endContactName: end.name,
    endContactPhone: end.phone,
    isProofOfPickupRequired: false,
    isProofOfDeliveryRequired,
  }));
}

const redirectToOrderDetails = orderId => {
  const referer = new URLSearchParams(window.location.search).get('referer');
  return push(referer ? decodeURIComponent(referer) : `/orders/${orderId}`);
};

export function* handleQuotationExpire({ orderId, editRevision, deliveries }) {
  const lastQuote = yield select(getLatestQuotation);
  yield put(fetchQuotation(orderId));
  const { fail } = yield race({
    success: take(FETCH_QUOTATION_SUCCESS),
    fail: take(FETCH_QUOTATION_FAILURE),
  });
  if (fail) return;

  const quote = yield select(getLatestQuotation);
  if (lastQuote.totalPrice.exactAmount === quote.totalPrice.exactAmount) {
    yield call(editOrder, {
      orderId,
      quoteId: quote.id,
      editRevision,
      deliveries,
    });
    yield put({
      type: EDIT_ORDER_SUCCESS,
      orderId,
      meta: {
        type: 'error',
        title: 'EditOrder.notification_title_edit_success',
        message: 'EditOrder.notification_msg_edit_success',
      },
    });
    yield put(redirectToOrderDetails(orderId));
    return;
  }
  throw new Error('ERROR_INVALID_QUOTATION');
}

export function* onEditOrder({ orderId, quoteId }) {
  const { editRevision, isProofOfDeliveryRequired } = yield select(
    getOrder,
    orderId
  );
  const wayptsIds = yield select(getFilledWayptsIds, orderId);
  const deliveryInfos = yield select(getDeliveryInfoByWayptIds, wayptsIds);
  const deliveries = constructDeliveries(
    deliveryInfos,
    isProofOfDeliveryRequired
  );
  try {
    yield call(editOrder, {
      orderId,
      quoteId,
      editRevision,
      deliveries,
    });
    yield put(redirectToOrderDetails(orderId));
    yield put({
      type: EDIT_ORDER_SUCCESS,
      orderId,
      meta: {
        type: 'info',
        title: 'EditOrder.notification_title_edit_success',
        message: 'EditOrder.notification_msg_edit_success',
      },
    });
    yield put(redirectToOrderDetails(orderId));
  } catch (e) {
    try {
      if (e.message === 'ERROR_INVALID_QUOTATION') {
        yield handleQuotationExpire({
          orderId,
          editRevision,
          deliveries,
        });
      } else {
        throw e;
      }
    } catch (sagaError) {
      if (
        sagaError.message === 'ERROR_EDIT_LIMIT_REACHED' ||
        sagaError.message === 'ERROR_UNEXPECTED_ORDER_STATUS'
      ) {
        yield put(redirectToOrderDetails(orderId));
      }
      yield put({
        type: EDIT_ORDER_FAILURE,
        meta: {
          type: 'error',
          title: 'EditOrder.notification_title_edit_failure',
          message: sagaError.message,
        },
      });
    }
  }
}

export function* onCancelOrder({ orderId, reason, comment }) {
  try {
    yield call(cancelOrder, orderId, reason, comment);
    yield put({ type: CANCEL_ORDER_SUCCESS, orderId, reason });
  } catch ({ message }) {
    if (message === 'ERROR_EXCEED_CANCEL_PERIOD') {
      yield put({ type: CANCEL_ORDER_FAILURE });
      yield put(openDialog('CANCEL_ORDER_FAILED', { orderId }));
    } else {
      yield put({
        type: CANCEL_ORDER_FAILURE,
        meta: {
          type: 'error',
          message,
        },
      });
    }
  }
}

export function* onUpdatePriorityFee({ orderId, amount }) {
  try {
    const { tips } = yield call(updatePriorityFee, orderId, amount);
    yield put({
      type: UPDATE_PRIORITY_FEE_SUCCESS,
      orderId,
      amount: tips,
    });
  } catch ({ message }) {
    yield put({
      type: UPDATE_PRIORITY_FEE_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  } finally {
    yield put(closePanel());
  }
}

export function* onRateOrder({ orderId, rating, reason, comment }) {
  try {
    const { driverId } = yield select(getOrder, orderId);
    const res = yield call(
      rateOrder,
      orderId,
      driverId,
      rating,
      reason,
      comment
    );
    yield put({
      type: RATE_ORDER_SUCCESS,
      orderId,
      rating,
      driverId,
      avgRating: res.rating,
    });
  } catch ({ message }) {
    yield put({
      type: RATE_ORDER_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
    yield put(closePanel());
  }
}

export function* onFavoriteDriver({ driverId }) {
  try {
    yield call(favoriteDriver, driverId);
    yield put({ type: FAVORITE_DRIVER_SUCCESS, driverId });
  } catch ({ message }) {
    yield put({
      type: FAVORITE_DRIVER_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* onBanDriver({ driverId }) {
  try {
    yield call(banDriver, driverId);
    yield put({ type: BAN_DRIVER_SUCCESS, driverId });
  } catch ({ message }) {
    yield put({
      type: BAN_DRIVER_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* watchFetchRecords() {
  yield takeLatest(FETCH_RECORDS_REQUEST, onFetchRecords);
  yield takeLatest(FETCH_RECORD_REQUEST, onFetchRecord);
  yield takeLatest(FETCH_CLIENT_ORDER_REQUEST, onFetchClientOrder);
}

export function* watchOrder() {
  yield takeEvery(INIT_EDIT_REQUEST, onInitEdit);
  yield takeEvery(CLONE_ORDER, onCloneOrder);
  yield takeEvery(EDIT_ORDER_REQUEST, onEditOrder);
  yield takeEvery(CANCEL_ORDER_REQUEST, onCancelOrder);
  yield takeEvery(UPDATE_PRIORITY_FEE_REQUEST, onUpdatePriorityFee);
  yield takeEvery(RATE_ORDER_REQUEST, onRateOrder);
  yield takeEvery(FAVORITE_DRIVER_REQUEST, onFavoriteDriver);
  yield takeEvery(BAN_DRIVER_REQUEST, onBanDriver);
}

export default function* rootSaga() {
  yield all([watchFetchRecords(), watchOrder()]);
}
