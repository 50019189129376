import styled from 'styled-components';
import { Dropdown } from '@lalamove/karang';
import WrappedTable from 'components/WrappedTable';
import { silver } from 'styles/colors';
import { fontSize } from 'styles/fonts';

// space at bottom for not overlay with zendesk button
export const Layout = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 73rem;
  max-width: 110rem;
  padding-bottom: 5em;
  margin-right: auto;
  margin-left: auto;
`;

export const SearchForm = styled.form`
  margin-left: 2em;

  @media (min-width: 110rem) {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const StatusDropdown = styled(Dropdown)`
  & button {
    padding: 0.25em 1em;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  display: none;
`;

export const RecordsTable = styled(WrappedTable)`
  table-layout: fixed;

  & th:nth-of-type(1),
  & td:nth-of-type(1) {
    width: 9em;
  }

  & th:nth-of-type(2),
  & td:nth-of-type(2) {
    width: 8em;
  }

  & th:nth-of-type(4),
  & td:nth-of-type(4) {
    width: 8em;
  }

  & th:nth-of-type(5),
  & td:nth-of-type(5) {
    width: 8em;
  }

  & th:nth-of-type(6),
  & td:nth-of-type(6) {
    width: 8em;
  }

  & th:nth-of-type(7),
  & td:nth-of-type(7) {
    width: 10em;
  }

  & th:nth-of-type(8),
  & td:nth-of-type(8) {
    width: 8em;
  }

  & th:nth-of-type(9),
  & td:nth-of-type(9) {
    width: 5em;
  }

  & tr:hover ${/* sc-selector */ StyledDropdown} {
    display: inline-block;
  }

  & thead th:first-of-type,
  & td:first-of-type {
    text-align: center;
  }
`;

export const SupportText = styled.div`
  color: ${silver};
  font-size: ${fontSize.small};
  white-space: nowrap;
`;

export const Truncate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
