import moment from 'moment';
import log from 'api/log';
import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';

import FetcherFactory from '../FetcherFactory';
import FetcherLockService from '../FetcherLockService';
import UAPIFetcher from '../UAPIFetcher';

const placeOrder = async ({
  vehicleStdNames,
  vehicleStdTagIds,
  contactPhoneNo,
  contactName,
  cityInfoRevision,
  remark,
  addrInfo,
  selectedSpecReq,
  priceItems,
  orderTime,
  selectedPaymentMethodId,
  orderVehicleId,
  uniformInvoice,
  isProofOfDeliveryRequired,
  selectedCouponId,
  preferFavorite,
  isFavDriverOnly,
}) => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const {
    accessToken,
    profileType,
    cityId,
    countryId,
    locale,
    timezone,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      last_repeat_uuid: '',
      send_type: getSendType(preferFavorite, isFavDriverOnly),
      pay_type: selectedPaymentMethodId,
      user_name: contactName,
      user_tel: contactPhoneNo,
      order_vehicle_id: orderVehicleId,
      remark,
      order_time: orderTime,
      city_info_revision: cityInfoRevision,
      price_item: priceItems,
      distance_by: 3,
      porterage_type: 0,
      addr_info: addrInfo,
      use_virtual_phone: 0,
      is_subscribe: 0,
      spec_req: selectedSpecReq.map(req => Number(req)),
      std_tag: vehicleStdNames,
      std_tag_ids: vehicleStdTagIds,
      uniform_invoice: uniformInvoice,
      is_proof_of_delivery_required: isProofOfDeliveryRequired,
      coupon_id: selectedCouponId,
    }),
    os: 'web',
    _t: moment().unix(),
    city_id: cityId,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    htzone: timezone,
    revision: 6501,
    version: '6.5.1',
    token: accessToken,
    is_ep: profileType,
  };

  const { ret, data, msg } = await fetcher.get('?_m=order_request', params);

  if (ret !== 0) {
    log.info(`placeOrder 200 fail: ${ret}`, {
      debug: `JSON: Request: ${JSON.stringify(
        params
      )}, Response: ${JSON.stringify(ret)}`,
    });
    throw new UApiError(msg, ret);
  }

  return data;
};

export const getSendType = (preferFavorite, isFavDriverOnly) => {
  if (!preferFavorite) return 0; // normal order
  if (isFavDriverOnly) return 3; // fav driver only
  return 1; // fav driver first
};

export default placeOrder;

// Working args example:
// {
//   "std_tag":[

//   ],
//   "coupon_id":0,
//   "premium_type":1,
//   "porterage_order_item":null,
//   "user_phone_no":"10010001",
//   "send_type":0,
//   "porterage_total_fen":0,
//   "user_name":"10010001",
//   "city_info_revision":3,
//   "remark":"",
//   "city_id":11014,
//   "addr_info":[
//      {
//         "district_name":"",
//         "contact_phone_no":"",
//         "name":"101\/102, Mirzapur Road",
//         "house_number":"",
//         "city_id":"11014",
//         "addr":"101\/102, Mirzapur Rd, Old City, Mirzapur, Bhadra, Ahmedabad, Gujarat 380001, India",
//         "lat_lon":{
//            "lon":72.585022039711475,
//            "lat":23.033866525253405
//         },
//         "contact_name":"101\/102,Mirzapur Road"
//      },
//      {
//         "house_number":"",
//         "city_id":"-1795742675",
//         "addr":"101\/102, Mirzapur Rd, Old City, Mirzapur, Bhadra, Ahmedabad, Gujarat 380001, India",
//         "contact_phone_no":"",
//         "contact_name":"101\/102,Mirzapur Road",
//         "lat_lon":{
//            "lon":72.585022039711006,
//            "lat":23.033863131248999
//         },
//         "district_name":"Bhadra",
//         "name":"101\/102,Mirzapur Road"
//      }
//   ],
//   "spec_req":[
//      2
//   ],
//   "is_subscribe":0,
//   "total_price_fen":900,
//   "coupon_fen":0,
//   "order_time":1589446873,
//   "porterage_type":0,
//   "distance_by":3,
//   "pay_type":3,
//   "order_vehicle_id":1,
//   "order_reason":"",
//   "use_virtual_phone":0,
//   "uniform_invoice": {
// 	   "key": "DONATION",
// 	   "code": 88888,
// 	   "name": "兒癌基金會"
//   }
// }
