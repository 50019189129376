import React, { Component } from 'react';
import Big from 'big.js';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { arrayOf, func, number, shape, string, bool } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Heading, Button, Checkbox, TextArea } from '@lalamove/karang';
import Modal from 'components/Modal';
import { noop } from 'utils/helpers';
import {
  payAppeal,
  updateOrderAppeal,
} from 'interfaces/global/store/modules/records/actions';
import outstanding from 'assets/outstanding.png';
import noAppealImage from 'assets/noAppeal.png';
import formatter from 'utils/formatter';
import { orange } from 'styles/colors';
import appealnoticeSvg from 'assets/svg/appealnotice.svg';
import appealtipSvg from 'assets/svg/appealtip.svg';
import { getOrderDetails } from 'interfaces/global/store/modules/records/selectors';
import { createLoadingSelector } from 'store/modules/loading';
import { getCurrencyRate } from 'store/modules/region/selectors';

const Notice = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #fbeaed;
  margin: 21px 0 24px;
  background: rgba(251, 234, 237, 0.5);
  color: #303030;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
`;
const NoticeIcon = styled.img`
  vertical-align: middle;
  align-self: baseline;
  margin-right: 4px;
`;
const DriverNotice = styled.div`
  margin-bottom: 24px;
  color: #303030;
  font-size: 14px;
  font-style: italic;
  line-height: 16px;
`;
const FeeContent = styled.div`
  padding: 0 1.25em 0 2.25em;
  margin-bottom: 15px;
`;
const FeeItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  color: ${({ isAppeal }) => (isAppeal ? `${orange}` : 'inherit')};
`;
const FeeImage = styled.div`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  margin-right: 10px;
  background: ${({ image }) => `url(${image}) center center/cover no-repeat`};
  cursor: pointer;
`;
const FeeNoImage = styled.img`
  flex-shrink: 0;
  width: 13px;
  height: 13px;
  border: 17px solid #f3f3f3;
  border-radius: 4px;
  margin-right: 10px;
  background: #f3f3f3;
`;
const FeeListContainer = styled.div`
  overflow: auto;
  max-height: 200px;
  margin-bottom: 24px;
`;
const FeeItemRigth = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px 0;
  border-bottom: 1px solid #d8d8d8;
`;
const FeeButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
  border-bottom: 1px solid #b2b2b2;
`;
const FeeButtonPay = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #b2b2b2;
`;
const OtherOperation = styled.div`
  display: flex;
  align-items: center;
  color: ${orange};
`;
const OperationItem = styled.div`
  margin-right: 24px;
  cursor: pointer;
  color: ${({ status }) => (status ? { orange } : '#B2B2B2')};
`;
const FeePrice = styled.div`
  color: #303030;
  font-weight: bold;
`;
const ModalImage = styled.div`
  text-align: center;
`;
const FeeItemIntro = styled.div`
  margin-top: 4px;
  color: #888888;
  font-size: 12px;
`;
const OutstandingTop = styled.div`
  box-sizing: border-box;
  height: 145px;
  padding: 48px 221px 48px 28px;
  background-image: url(${outstanding});
  background-size: cover;
  color: #303030;
  font-size: 18px;
  line-height: 24px;
`;
const Panel = styled.div`
  padding: 15px 32px;
  float: right;
  button {
    margin: 1em 0.5em 0;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const OutstandingIntro = styled.div`
  padding: 23px 32px 0;
  font-size: 14px;
  line-height: 16px;
`;
const TextAreaWrapper = styled.div`
  margin: 1em 0;
  > div {
    display: block;
  }
  > p {
    color: #888888;
    font-size: 12px;
  }
`;
const modalStyle = {
  content: {
    border: 'none',
    width: '428px',
    maxWidth: '428px',
    padding: 0,
  },
};
const modalPicStyle = {
  content: {
    border: 'none',
    width: '450px',
    maxWidth: '600px',
    padding: 0,
    marginTop: '0',
  },
};
export class OrderDetails extends Component {
  static defaultProps = {
    t: noop,
    payAppeal: noop,
    appealList: [],
    allowAppealList: [],
    refId: '',
    orderId: '',
    appeal: null,
    status: 0,
    isLoading: false,
    isLoadingUpdateAppeal: false,
    updateOrderAppeal: noop,
  };

  static propTypes = {
    t: func,
    payAppeal: func,
    refId: string,
    appealList: arrayOf(shape()),
    allowAppealList: arrayOf(shape()),
    orderId: string,
    appeal: shape({}),
    status: number,
    isLoading: bool,
    isLoadingUpdateAppeal: bool,
    updateOrderAppeal: func,
    currencyRate: number.isRequired,
  };

  state = {
    ModalImageOpen: false,
    ModalWhatOpen: false,
    bigPic: '',
    totalPrice: 0,
    appealFlag: false,
    reason: '',
    allowAppealList: [],
  };

  componentDidMount() {
    const { appeal, appealList } = this.props;
    const totalPrice = appealList.reduce((acc, cur) => acc + cur.amount, 0);
    const allowAppealList = appeal.allowAppealList.map(item =>
      Object.assign(item, {
        title: item.bill_type_name,
        amount: item.amount_fen,
      })
    );
    this.setState({ totalPrice, allowAppealList });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isLoadingUpdateAppeal && prevProps.isLoadingUpdateAppeal) {
      this.closeAppeal();
    }
  }

  closeAppeal = () => {
    this.setState({ appealFlag: false });
  };

  openAppealRequest = () => {
    const { appeal } = this.props;
    const appealStatus = appeal ? appeal.appealStatus : 0;
    if (appealStatus !== 2) {
      return;
    }
    this.setState({ appealFlag: true });
  };

  handleReason = e => {
    this.setState({ reason: e.target.value });
  };

  handleImageModal = index => {
    const { ModalImageOpen, allowAppealList, appealFlag } = this.state;
    const { appealList } = this.props;
    const currentAppealList = appealFlag ? allowAppealList : appealList;
    const bigPic = currentAppealList[index].img_url;
    if (!bigPic) return;
    this.setState({ bigPic, ModalImageOpen: !ModalImageOpen });
  };

  handleCloseImageModal = () => {
    this.setState({ ModalImageOpen: false });
  };

  handleModalSwitch = () => {
    const { ModalWhatOpen } = this.state;
    this.setState({ ModalWhatOpen: !ModalWhatOpen });
  };

  submitOrderAppeal = () => {
    const { orderId } = this.props;
    const amount = this.state.totalPrice;
    this.props.payAppeal({ orderId, amount });
  };

  updateOrderAppeal = () => {
    const { refId, orderId } = this.props;
    const desc = this.state.reason;
    let appealItems = this.state.allowAppealList;
    appealItems = appealItems.filter(item => item.checked);
    appealItems = appealItems.map(item =>
      Object.assign(item, { name: item.bill_type_name, expect_fen: '' })
    );
    this.props.updateOrderAppeal({ refId, orderId, appealItems, desc });
  };

  handleFee = index => {
    const { allowAppealList } = this.state;
    const { checked } = allowAppealList[index];
    this.setState({
      allowAppealList: allowAppealList.map((item, i) =>
        i === index ? { ...item, checked: !checked } : item
      ),
    });
  };

  appealButtonStatus = () => {
    const { allowAppealList, reason } = this.state;
    const checkedAppeal = allowAppealList.filter(item => item.checked);
    return (
      checkedAppeal.length > 0 && reason.replace(/^\s+|\s+$/g, '').length > 0
    );
  };

  render() {
    const {
      ModalImageOpen,
      ModalWhatOpen,
      bigPic,
      totalPrice,
      appealFlag,
      allowAppealList,
      reason,
    } = this.state;
    const {
      t,
      appeal,
      status,
      isLoading,
      appealList,
      isLoadingUpdateAppeal,
      currencyRate,
    } = this.props;
    let handleDesc = appeal ? appeal.appealHandleInfo.handle_desc : '';
    if (!handleDesc) {
      handleDesc = appeal.handleDesc;
    }
    const appealStatus = appeal ? appeal.appealStatus : 0;
    const billRemark = appeal ? appeal.sendBillRemark : '';
    const currentAppealList = appealFlag ? allowAppealList : appealList;
    const FeeList = currentAppealList.map((item, index) => (
      <FeeItem
        key={item.title}
        isAppeal={appeal.billTypeList.includes(item.type)}
      >
        {appealFlag && (
          <Checkbox
            checked={item.checked}
            name={item.bill_type_name}
            label=""
            title="A simple checkbox"
            highlightLabel
            onChange={() => this.handleFee(index)}
            style={{ marginLeft: '4px' }}
          />
        )}
        {item.img_url ? (
          <FeeImage
            onClick={() => this.handleImageModal(index)}
            width={48}
            height={48}
            image={item.img_url}
            alt=""
            className="icon"
          />
        ) : (
          <FeeNoImage src={noAppealImage} />
        )}
        <FeeItemRigth>
          <div>
            <div>{item.title}</div>
            <FeeItemIntro />
          </div>
          <FeePrice>
            {formatter.currency(Big(item.amount).div(currencyRate))}
          </FeePrice>
        </FeeItemRigth>
      </FeeItem>
    ));
    return (
      <FeeContent>
        {handleDesc && (
          <Notice>
            <NoticeIcon
              src={status === 13 ? appealtipSvg : appealnoticeSvg}
              alt=""
            />
            <div>{handleDesc}</div>
          </Notice>
        )}
        {billRemark && (
          <div>
            <Heading htmlTag="h3">{t('Records.notice_driver')}</Heading>
            <DriverNotice>{billRemark}</DriverNotice>
          </div>
        )}
        <Heading htmlTag="h3">{t('Records.review_fee')}</Heading>
        <FeeListContainer>{FeeList}</FeeListContainer>
        {appealFlag && (
          <TextAreaWrapper>
            <p>{t('Records.appeal_reason_tip')}</p>
            <TextArea
              row={3}
              value={reason}
              width="100%"
              onChange={this.handleReason}
              maxLength={1000}
            />
          </TextAreaWrapper>
        )}
        {appealFlag ? (
          <FeeButton>
            <Button
              variant="link"
              onClick={this.closeAppeal}
              style={{ marginRight: '10px' }}
            >
              {t('TW_Invoice.button_cancel')}
            </Button>
            <Button
              variant="danger"
              isLoading={isLoadingUpdateAppeal}
              disabled={!this.appealButtonStatus()}
              onClick={this.updateOrderAppeal}
              solid
            >
              {t('RecordPanel.button_submit')}
            </Button>
          </FeeButton>
        ) : (
          <FeeButtonPay>
            <OtherOperation>
              <OperationItem status onClick={this.handleModalSwitch}>
                {t('Records.record_panel')}
              </OperationItem>
              <OperationItem
                status={appealStatus === 2}
                onClick={this.openAppealRequest}
              >
                {t('Records.report_request')}
              </OperationItem>
            </OtherOperation>
            <Button
              variant="danger"
              onClick={this.submitOrderAppeal}
              isLoading={isLoading}
              disabled={status !== 13}
              solid
            >
              {t('Records.Pay')}{' '}
              {formatter.currency(Big(totalPrice).div(currencyRate))}
            </Button>
          </FeeButtonPay>
        )}
        <Modal
          name="big_image"
          isOpen={ModalImageOpen}
          onClose={this.handleCloseImageModal}
          style={modalPicStyle}
        >
          <ModalImage>
            <img alt="bigImage" width={450} src={bigPic} />
          </ModalImage>
        </Modal>
        <Modal
          name={t('Records.what_outstanding')}
          isOpen={ModalWhatOpen}
          onClose={this.handleModalSwitch}
          style={modalStyle}
        >
          <div>
            <OutstandingTop>{t('Records.what_outstanding')}?</OutstandingTop>
            <OutstandingIntro>
              {t('Records.what_outstanding_contentfirst')}
            </OutstandingIntro>
            <OutstandingIntro>
              {t('Records.what_outstanding_contentseconde')}
            </OutstandingIntro>
          </div>
          <Panel>
            <Button variant="primary" solid onClick={this.handleModalSwitch}>
              {t('RecordPanel.button_close_panel')}
            </Button>
          </Panel>
        </Modal>
      </FeeContent>
    );
  }
}

const mapState = state => {
  const orderDetails = getOrderDetails(state);
  return {
    isLoading: createLoadingSelector(['PAY_APPEAL'])(state),
    appeal: orderDetails.appeal,
    isLoadingUpdateAppeal: createLoadingSelector(['ORDERAPPEAL_UPDATE'])(state),
    currencyRate: getCurrencyRate(state),
  };
};

export default compose(
  withTranslation(),
  connect(mapState, { payAppeal, updateOrderAppeal })
)(OrderDetails);
