import React, { Component, Fragment } from 'react';
import { func, bool, string, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';

import HtmlTitle from 'components/HtmlTitle';
import {
  loginSubmit,
  facebookLogin as fbLogin,
  cancelFacebookLogin as cancelFBLogin,
} from 'store/modules/auth/actions';
import { getUser, checkAuth, getReferrer } from 'store/modules/auth/selectors';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';
import {
  actions as regionAction,
  selectors as regionSelector,
} from 'store/modules/region';
import {
  disableBrowserWarning,
  getHasShowBrowserWarning,
} from 'store/modules/browserSession';
import { actions as genesysActions } from 'interfaces/global/store/modules/genesys/actions';

import { parseUrlParams, noop } from 'utils/helpers';
import { isIE } from 'utils/userAgentHelper';

import loadFacebookSDK from 'utils/facebookApi';
import LoginBox from './components/LoginBox';
import BrowserSupportBox from './components/BrowserSupportBox';

export const redirect = ({ location, authenticated, token }) => {
  const { redirect: url } = parseUrlParams(location.search);
  if (url && authenticated && token) {
    window.location.assign(
      `${decodeURIComponent(url)}?token=${token}&hostname=${
        window.location.hostname
      }`
    );
  }
};

export class Login extends Component {
  _isMounted = false;

  static defaultProps = {
    loading: false,
    apiError: null,
    referrer: '/',
    token: '',
    disableBrowserWarning: noop,
  };

  static propTypes = {
    t: func.isRequired,
    loading: bool,
    apiError: shape({ message: string }),
    loginSubmit: func.isRequired,
    facebookLogin: func.isRequired,
    cancelFacebookLogin: func.isRequired,
    authenticated: bool.isRequired,
    location: shape({}).isRequired,
    referrer: string,
    token: string,
    currentCountry: shape({ id: string, areaCode: string }).isRequired,
    language: string.isRequired,
    countryDict: shape({}).isRequired,
    changeLocation: func.isRequired,
    disableBrowserWarning: func,
    hasShownBrowserWarning: bool.isRequired,
    logoutGenesys: func.isRequired,
  };

  state = {
    loadingFacebookSDK: true,
  };

  //  debt #103
  componentDidMount = async () => {
    this._isMounted = true;

    // eslint-disable-next-line no-shadow
    const {
      location,
      authenticated,
      token,
      logoutGenesys,
      currentCountry,
      language,
    } = this.props;
    logoutGenesys();
    redirect({ location, authenticated, token });

    this.FB = await loadFacebookSDK(currentCountry.id, language);

    this.facebookLoginResponse = await this.FB.getLoginStatus();

    if (this._isMounted) this.setState({ loadingFacebookSDK: false });
  };

  componentDidUpdate(prevProps) {
    const { location, authenticated, token } = this.props;
    const {
      location: prevLoc,
      authenticated: prevAuth,
      token: prevToken,
    } = prevProps;
    if (
      location !== prevLoc ||
      authenticated !== prevAuth ||
      token !== prevToken
    ) {
      redirect({ location, authenticated, token });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit = ({ username, password, areaCode }) => {
    this.props.loginSubmit({
      username,
      password,
      areaCode,
    });
  };

  handleFBLogin = async () => {
    const {
      facebookLogin,
      cancelFacebookLogin,
      currentCountry: { areaCode },
    } = this.props;

    if (
      !this.facebookLoginResponse.status ||
      this.facebookLoginResponse.status !== 'connected'
    ) {
      const secondLoginResponse = await this.FB.login({
        scope: 'email,public_profile',
        return_scopes: true,
      });

      // User cancelled login or did not fully authorize.
      if (
        !secondLoginResponse.status ||
        secondLoginResponse.status !== 'connected'
      ) {
        cancelFacebookLogin();
        return;
      }
    }

    const user = await this.FB.api('/me', {
      fields: 'first_name, last_name, name, email',
    });

    facebookLogin({
      user,
      areaCode,
    });
  };

  changeCountry = country => {
    const { currentCountry, changeLocation } = this.props;
    if (country === currentCountry.id) {
      return;
    }

    changeLocation({
      country,
    });
  };

  dismissBrowserWarning = () => {
    // eslint-disable-next-line no-shadow
    const { disableBrowserWarning } = this.props;
    disableBrowserWarning();
  };

  render() {
    const {
      t,
      authenticated,
      referrer,
      loading,
      apiError,
      currentCountry,
      countryDict,
      hasShownBrowserWarning,
    } = this.props;

    if (authenticated) {
      return <Redirect to={referrer} />;
    }

    const shouldShowWarning =
      !hasShownBrowserWarning && isIE(navigator.userAgent);
    return (
      <Fragment>
        <HtmlTitle>{t('Title.login')}</HtmlTitle>
        {shouldShowWarning ? (
          <BrowserSupportBox onClose={this.dismissBrowserWarning} />
        ) : (
          <LoginBox
            currentCountry={currentCountry}
            countries={Object.values(countryDict)}
            loading={loading}
            loadingFacebookSDK={this.state.loadingFacebookSDK}
            apiError={apiError}
            onFBLogin={this.handleFBLogin}
            onSubmit={this.handleSubmit}
            onCountryChange={this.changeCountry}
            forgotPasswordLink={
              <Link to="/forgot-password" data-testid="forgotPasswordLink">
                {t('Login.forgot_password')}
              </Link>
            }
            registerLink={
              <Trans i18nKey="Login.create_account">
                Dont have an account?
                <Link to="/register" data-testid="registerLink">
                  Create new account
                </Link>
              </Trans>
            }
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authenticated: checkAuth(state),
  referrer: getReferrer(state),
  token: getUser(state).access_token,
  loading: createLoadingSelector(['LOGIN', 'FB_LOGIN'])(state),
  apiError: makeMessageSelector(['LOGIN', 'FB_LOGIN'])(state),
  currentCountry: regionSelector.getCurrentCountry(state),
  language: regionSelector.getCurrentLocale(state),
  countryDict: regionSelector.getCountryDict(state),
  hasShownBrowserWarning: getHasShowBrowserWarning(state),
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, {
    loginSubmit,
    facebookLogin: fbLogin,
    cancelFacebookLogin: cancelFBLogin,
    changeLocation: regionAction.changeLocation,
    disableBrowserWarning,
    logoutGenesys: genesysActions.logoutGenesys,
  })
)(Login);
