import { LalamoveApiError } from 'utils/helpers';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Request {
//   access_token: String;
//   client_id: Number | String;
//   contact: {
//     contact_email?: String,
//     subscriptions?: Boolean[],
//   };
//   is_pod_enabled?: Boolean;
// }
// interface Response {
//   success: Boolean;
// }
export default async function updatePreferences({ email, eReceipt, pod }) {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    contact: JSON.stringify({
      ...(email !== undefined && { contact_email: email }),
      ...(eReceipt !== undefined && { subscriptions: [+eReceipt] }),
    }),
    ...(pod !== undefined && { is_pod_enabled: pod }),
  };
  const { success, data, code } = await fetcher.post(
    'updatepreferences',
    params
  );
  if (!success) throw new LalamoveApiError(code);
  return data;
}
