import React, { Component } from 'react';
import styled from 'styled-components';
import { string, shape, func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { noop } from 'utils/helpers';
import { Button, Input } from '@lalamove/karang';

import { ERROR_INVALID_PHONE_NUMBER_WITH_PREFIX } from 'interfaces/global/store/modules/routing/errors';

import { DELIVERY_INFO_NAME_LIMIT } from 'store/modules/routing/config';
import { whenDesktop, whenMobile } from 'components/MediaQuery';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const ScInput = styled(Input)`
  margin-bottom: 16px;

  ${whenMobile} {
    input {
      font-size: 16px;
    }
  }
`;

const CancelButton = styled(Button)`
  ${whenDesktop} {
    margin-right: 1rem;
  }
`;

const FormActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${whenMobile} {
    flex-direction: column-reverse;

    & button {
      padding: 16px;
      margin: 0;
    }
  }
`;

export class Form extends Component {
  static defaultProps = {
    t: noop,
    name: '',
    phone: '',
    addressDetails: '',
    errors: {},
    onChange: noop,
    onError: noop,
    onCancel: noop,
    onSave: noop,
  };

  static propTypes = {
    t: func,
    name: string,
    phone: string,
    addressDetails: string,
    errors: shape({
      phone: string,
    }),
    onChange: func,
    onError: func,
    onCancel: func,
    onSave: func,
  };

  validateForm = ({ phone }) => {
    const valid = phone === '' || (phone.length >= 2 && phone.length <= 255);

    const message = valid ? '' : ERROR_INVALID_PHONE_NUMBER_WITH_PREFIX;

    return { valid, message };
  };

  handleFormSubmit = e => {
    e.preventDefault();
    const { onError, onSave } = this.props;
    const { phone } = this.props;
    const { valid, message } = this.validateForm({ phone });

    if (valid) {
      onSave({ ...this.props });
    } else {
      onError(message);
    }
  };

  handleBlur = () => {
    const { phone } = this.props;
    const { valid, message } = this.validateForm({ phone });
    if (valid) return;
    this.props.onError(message);
  };

  render() {
    const { t, onChange, onCancel, name, phone, addressDetails } = this.props;
    return (
      <StyledForm onSubmit={this.handleFormSubmit}>
        <ScInput
          name="name"
          value={name}
          label={t('PlaceOrder.placeholder_delivery_info_name')}
          onChange={onChange}
          maxLength={DELIVERY_INFO_NAME_LIMIT}
        />
        <ScInput
          name="phone"
          value={phone}
          label={t('PlaceOrder.placeholder_delivery_info_phone')}
          onChange={onChange}
          error={t(this.props.errors.phone)}
          onBlur={this.handleBlur}
        />
        <ScInput
          name="addressDetails"
          value={addressDetails}
          label={t('PlaceOrder.placeholder_delivery_info_addr')}
          onChange={onChange}
        />
        <FormActionContainer>
          <CancelButton variant="link" type="button" onClick={onCancel}>
            {t('Settings.button_cancel')}
          </CancelButton>
          <Button variant="primary" type="submit" solid>
            {t('Settings.button_save')}
          </Button>
        </FormActionContainer>
      </StyledForm>
    );
  }
}

export default withTranslation()(Form);
