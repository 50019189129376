import { call, put, takeLatest } from 'redux-saga/effects';
import { resetDialog } from 'store/modules/ui';
import { fetchSavedDrivers, removeDriver } from 'api/uAPI';
import {
  FETCH_SAVED_DRIVERS_REQUEST,
  FETCH_SAVED_DRIVERS_SUCCESS,
  FETCH_SAVED_DRIVERS_FAILURE,
  REMOVE_DRIVER_REQUEST,
  REMOVE_DRIVER_SUCCESS,
  REMOVE_DRIVER_FAILURE,
} from './actions';
import { track } from '../tracking/actions';

export function* onFetchFavoriteDrivers({ query }) {
  try {
    const drivers = yield call(fetchSavedDrivers, query);
    yield put({ type: FETCH_SAVED_DRIVERS_SUCCESS, drivers });
  } catch ({ message }) {
    yield put({
      type: FETCH_SAVED_DRIVERS_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* onRemoveDriver({ driverId }) {
  try {
    yield call(removeDriver, { driverId });
    yield put({ type: REMOVE_DRIVER_SUCCESS, driverId });
    yield put(track('favorited_driver_removed'));
  } catch ({ message }) {
    yield put({
      type: REMOVE_DRIVER_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  } finally {
    yield put(resetDialog());
  }
}

export default function* driverSaga() {
  yield takeLatest(FETCH_SAVED_DRIVERS_REQUEST, onFetchFavoriteDrivers);
  yield takeLatest(REMOVE_DRIVER_REQUEST, onRemoveDriver);
}
