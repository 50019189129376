/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';

export const fadeTransition = css`
  opacity: 1;
  transition: opacity 800ms cubic-bezier(0.65, 0, 0.35, 1);

  &.appear {
    opacity: 0;
    transition: none;
  }

  &.enter {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
    pointer-events: none;
  }

  &.enter-done {
    opacity: 1;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    pointer-events: none;
  }

  &.exit-done {
    opacity: 0;
  }
`;
