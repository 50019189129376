import React from 'react';
import { Consumer } from './context';

export default function withZendesk(WrappedComponent) {
  const C = props => (
    <Consumer>
      {({ zE, zopim, bindOnce }) => {
        if (zE === null) {
          // TODO: in react 16.8 useContext for suspense instead of hardcode null
          return null;
        }
        return (
          <WrappedComponent
            zE={zE}
            zopim={zopim}
            bindOnce={bindOnce}
            {...props}
          />
        );
      }}
    </Consumer>
  );
  const name = WrappedComponent.displayName || WrappedComponent.name;
  C.displayName = `WithZendesk(${name})`;
  return C;
}
