import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'utils/helpers';
import { List as ListComponent } from '@lalamove/karang';
import {
  recent as RecentIcon,
  pinFilled as PinIcon,
} from '@lalamove/karang/dist/components/Icon/icons';

import { orange, gray, lightSilver } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { DROPDOWN } from 'styles/zIndex';

export const SuggestionList = styled(ListComponent)`
  position: absolute;
  z-index: ${DROPDOWN};
  top: 100%;
  right: 0;
  left: 0;
  overflow-y: scroll;
  max-height: 24em;
  color: ${gray};
`;

const ContactLabel = styled.div`
  color: ${lightSilver};
  font-size: ${fontSize.small};
`;

const AutocompleteList = ({ items, getItemProps, highlightedIndex }) => (
  // Sactuary's autocomplete predictions do not have `id` like Google's, only `place_id`:
  <SuggestionList items={items} unique="placeId" hoverable>
    {({ data: option, Item, index, getProps }) => (
      <Item
        {...compose(
          getItemProps,
          getProps
        )({
          active: highlightedIndex === index,
          index,
          item: option,
          icon:
            option.type === 'history' ? (
              <RecentIcon color={orange} />
            ) : (
              <PinIcon color={orange} />
            ),
        })}
      >
        <div>{option.description}</div>
        {option.contact && (
          <ContactLabel>
            {option.contact.name} {option.contact.phone}
          </ContactLabel>
        )}
      </Item>
    )}
  </SuggestionList>
);

AutocompleteList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired, // add shape
  getItemProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
};

export default AutocompleteList;
