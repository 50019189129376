import React, { useRef, useState, useEffect } from 'react';
import Big from 'big.js';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import formatter from 'utils/formatter';
import { noop } from 'utils/helpers';
import { toLLMUpper } from 'utils/locale';
import { TFunction, i18n as i18nType } from 'i18next';
import convertPercentDiscountDisplay from 'utils/convertPercentDiscountDisplay';
import couponDetailsClose from 'assets/svg/coupon-details-close.svg';
import couponDetailsOpen from 'assets/svg/coupon-details-open.svg';
import { useSelector } from 'react-redux';
import { getCurrencyRate } from 'store/modules/region/selectors';
import { Coupon as CouponType } from './types';
import {
  Container,
  Header,
  Text,
  Main,
  Tick,
  Tag,
  DiscountInfo,
  Discount,
  DiscountAmountDetail,
  Footer,
  ShowDetailText,
  Icon,
  Details,
  DetailHeader,
  DetailListItem,
  Badge,
} from './styles';

export interface Props {
  coupon: CouponType;
  unavailable?: boolean;
  showNewBadge?: boolean;
  isSelected?: boolean;
  isExpanded?: boolean;
  details?: string[];
  onClick?: () => void;
  onClickDetail?: () => void;
}

const Coupon: React.FC<Props> = ({
  coupon,
  unavailable = false,
  showNewBadge = false,
  isSelected = false,
  isExpanded = false,
  details = [],
  onClick = noop,
  onClickDetail = noop,
}) => {
  const { t, i18n } = useTranslation();
  const [detailsHeight, setDetailsHeight] = useState<null | string>(null);
  const detailsEl = useRef<HTMLDivElement>(null);
  const expandable = Boolean(details.length);
  const currencyRate = useSelector(getCurrencyRate);

  useEffect(() => {
    if (isExpanded && detailsEl.current && !detailsHeight) {
      const { height } = getComputedStyle(detailsEl.current);
      setDetailsHeight(height);
    }
  }, [detailsEl, isExpanded, setDetailsHeight, detailsHeight]);

  return (
    <Container onClick={onClick} active={isSelected}>
      {showNewBadge && <Badge>{t('Coupon.new')}</Badge>}
      <Header>
        <Text>{coupon.name}</Text>
        <Tick active={isSelected} />
      </Header>
      <Main>
        <Tag active={!unavailable} />
        <DiscountInfo>
          <Discount>
            {getCouponDiscount(coupon, t, i18n, currencyRate)}
          </Discount>
          <DiscountAmountDetail>
            {getCouponDiscountAmountDetail(coupon, t, currencyRate)}
          </DiscountAmountDetail>
        </DiscountInfo>
      </Main>
      {expandable && (
        <Details show={isExpanded} height={detailsHeight} ref={detailsEl}>
          <DetailHeader>{t('Coupon.details')}</DetailHeader>
          {details.map(detail => (
            <DetailListItem key={detail}>• {detail}</DetailListItem>
          ))}
        </Details>
      )}
      <Footer active={isSelected} onClick={expandable ? onClickDetail : null}>
        <Text>{getCouponExpiryDate(coupon, t)}</Text>
        {expandable && (
          <ShowDetailText>
            {isExpanded ? t('Coupon.hide_details') : t('Coupon.show_details')}
            <Icon src={isExpanded ? couponDetailsOpen : couponDetailsClose} />
          </ShowDetailText>
        )}
      </Footer>
    </Container>
  );
};

export const getCouponDiscount = (
  coupon: CouponType,
  t: TFunction,
  i18n: i18nType,
  currencyRate: number
): string => {
  const isPercentageDiscount = coupon.discount_type === 2;
  return isPercentageDiscount
    ? t('Coupon.percentage_off', {
        percentage: convertPercentDiscountDisplay(
          100 - coupon.discount_rate,
          toLLMUpper(i18n.language)
        ),
      })
    : t('Coupon.amount_off', {
        amount: formatter.currency(
          Big(coupon.discount_amount_fen).div(currencyRate)
        ),
      });
};

export const getCouponDiscountAmountDetail = (
  coupon: CouponType,
  t: TFunction,
  currencyRate: number
): string => {
  const {
    discount_type: type,
    reach_fen: minAmount,
    discount_amount_fen: discountedAmount,
    max_discount_amount: maxDiscountAmount,
  } = coupon;
  // fixed discount w/ min amount
  if (type === 1 && minAmount && minAmount > 0) {
    return t('Coupon.amount_minimum_order', {
      amount: formatter.currency(Big(minAmount).div(currencyRate)),
    });
  }
  // percentage discount
  if (type === 2) {
    return t('Coupon.up_to_amount_off', {
      amount: formatter.currency(
        Big(maxDiscountAmount ?? discountedAmount).div(currencyRate)
      ),
    });
  }
  // fixed discount w/o min amount
  return t('Coupon.order_reduction');
};

export const getCouponExpiryDate = (
  coupon: CouponType,
  t: TFunction
): string => {
  const { start_ts: start, end_ts: end, used_ts: used } = coupon;
  const dateFullFormat = t('DateTime.date_full');
  if (used) {
    const usedDate = moment(new Date(used * 1000));
    return t('Coupon.used_on_date', {
      date: usedDate.format(dateFullFormat),
    });
  }

  const now = Date.now() / 1000;
  const SECONDS_IN_A_DAY = 60 * 60 * 24;
  if (start > now) {
    const startsIn = Math.floor((start - now) / SECONDS_IN_A_DAY);
    return startsIn === 0
      ? t('Coupon.starts_tomorrow')
      : t('Coupon.starts_in_days', { dayNum: startsIn });
  }
  const expiresIn = Math.floor((end - now) / SECONDS_IN_A_DAY);
  if (expiresIn === 0) {
    return t('Coupon.expires_today');
  }
  if (expiresIn === 1) {
    return t('Coupon.expires_tomorrow');
  }
  if (expiresIn < 0) {
    const expiryDate = moment(new Date(end * 1000));
    return t('Coupon.expired_on_date', {
      date: expiryDate.format(dateFullFormat),
    });
  }
  return t('Coupon.expires_in_days', { dayNum: expiresIn });
};

export default Coupon;
