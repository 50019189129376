import wallet from 'store/modules/wallet';
import walletSaga from 'store/modules/wallet/saga';

const walletModule = {
  id: 'wallet',
  reducerMap: { wallet },
  sagas: [walletSaga],
};

export default walletModule;
