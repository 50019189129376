import { LalamoveApiError } from 'utils/helpers';
import { toLLMLower } from 'utils/locale';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Request {
//   auth_client: String;
//   country_code: String; // e.g. 852
//   lang: String; // e.g. en_hk
//   app_type: 'webapp';
// }
// interface Response {
//   code: 'PW_RESET_EMAIL_SENT';
//   created_time: string; // mysql datetime
//   expired_time: String; // mysql datetime
//   status: 0 | 1;
//   success: Boolean;
//   uuid: String;
// }
// request link (email) or pin (mobile) for forgot password
export default async function getUserEmailOrMobile(username, areaCode, lang) {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  const params = {
    auth_client: username,
    country_code: areaCode,
    lang: toLLMLower(lang),
    app_type: 'webapp',
  };
  const res = await fetcher.get('getuseremailormobile', params);
  const { success, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return res;
}
