/* eslint-disable lines-between-class-members */
import Delivery from './Delivery';
// import StatusProgress from './StatusProgress';
// import { PaymentBreakdown, PaymentSummary } from './Payment';
// import { PriceBreakdown, PriceSummary } from './Pricing';

// interface ClientOrderStruct {
//   id: string;
//   cityCode: string;

//   clientId: string;
//   clientName: string;
//   clientPhone: string;
//   clientSmsUpdates: boolean;
//   clientCorporateCode: string;
//   clientCorporateId: string;

//   driverId: string;
//   driverName: string;
//   driverPhone: string;
//   driverPlateNumber: string;

//   clientRemarks: string;
//   driverIsFavorite: boolean;
//   editRevision: string; // unix timestamp
//   expiresAt: string; // ISO Z
//   isForFavoriteDriver: boolean;
//   isImmediate: boolean;
//   paymentMethod: string; // CREDIT | CASH
//   scheduledAt: string; // ISO Z
//   serviceType: string; // prefix SERVICE | SUB_SERVICE
//   vehicleType: string; // prefix VEHICLE
//   createdAt: string; // ISO Z
//   modifiedAt: string; // ISO Z
//   status: string;
//   statusProgress: StatusProgress[];
//   deliveries: DeliveryStruct[];
//   totalPrice: PriceSummaryStruct;
//   priceBreakdown: PriceBreakdownStruct[];
//   totalPayment: PaymentSummaryStruct;
//   paymentBreakdown: PaymentBreakdownStruct[];
// }

export default class ClientOrder {
  id = '';
  cityCode = '';

  // clientId = '';
  // clientName = '';
  // clientPhone = '';
  // clientSmsUpdates = false;
  // clientCorporateCode = '';
  // clientCorporateId = '';

  // driverId = '';
  // driverName = '';
  // driverPhone = '';
  // driverPlateNumber = '';

  // clientRemarks = '';
  // driverIsFavorite = false;
  editRevision = ''; // unix timestamp
  // expiresAt = ''; // ISO Z
  // isForFavoriteDriver = false;
  // isImmediate = false;
  // paymentMethod = ''; // CREDIT | CASH
  // scheduledAt = ''; // ISO Z
  // serviceType = ''; // prefix SERVICE | SUB_SERVICE
  // vehicleType = ''; // prefix VEHICLE
  // createdAt = ''; // ISO Z
  // modifiedAt = ''; // ISO Z
  // status = '';

  _deliveries = [];
  // _totalPayment = new PaymentSummary({});
  // _totalPrice = new PriceSummary({});
  // _paymentBreakdown = [];
  // _priceBreakdown = [];
  // _statusProgress = [];

  constructor(co = {}) {
    Object.assign(this, co);
  }

  // get statusProgress() {
  //   return this._statusProgress;
  // }
  // set statusProgress(sp) {
  //   this._statusProgress = sp.map(StatusProgress.New);
  // }

  get deliveries() {
    return this._deliveries;
  }
  set deliveries(ds) {
    this._deliveries = ds.map(Delivery.New);
  }

  // get totalPayment() {
  //   return this._totalPayment;
  // }
  // set totalPayment(tp) {
  //   this._totalPayment = new PaymentSummary(tp);
  // }

  // get totalPrice() {
  //   return this._totalPrice;
  // }
  // set totalPrice(tp) {
  //   this._totalPrice = new PriceSummary(tp);
  // }

  // get paymentBreakdown() {
  //   return this._paymentBreakdown;
  // }
  // set paymentBreakdown(pb) {
  //   this._paymentBreakdown = pb.map(PaymentBreakdown.New);
  // }

  // get priceBreakdown() {
  //   return this._priceBreakdown;
  // }
  // set priceBreakdown(pb) {
  //   this._priceBreakdown = pb.map(PriceBreakdown.New);
  // }

  // assume right now isProofOfDeliveryRequired is order based
  // TODO: not hardcode isProofOfDeliveryRequired if delivery based
  get isProofOfDeliveryRequired() {
    return this._deliveries[0].isProofOfDeliveryRequired;
  }

  update(co) {
    return new ClientOrder(Object.assign(this, co));
  }
}
