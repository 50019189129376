import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import FetcherFactory from '../FetcherFactory';
import FetcherLockService from '../FetcherLockService';
import UAPIFetcher from '../UAPIFetcher';

export default async function fetchSavedDrivers({ current, max }) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  await FetcherLockService.langMutex.release();
  await FetcherLockService.accessTokenMutex.release();
  const {
    accessToken,
    profileType,
    cityId,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      page_no: current,
      page_size: max,
    }),
    token: accessToken,
    hcountry: countryId,
    city_id: cityId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
  };

  const { ret, data, msg } = await fetcher.get('?_m=get_fleet', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  // eslint-disable-next-line camelcase
  const { driver_info } = data;

  if (driver_info.length > 0)
    return driver_info.reduce(
      (obj, driver) => ({
        ...obj,
        [driver.driver_fid]: { ...driver },
      }),
      {}
    );

  return [];
}
