import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withResponsiveMedia } from 'components/MediaQuery';
import { arrayOf, bool, func, shape, string, object, number } from 'prop-types';
import _pickBy from 'lodash/pickBy';
import _isEqual from 'lodash/isEqual';
import { push, replace } from 'connected-react-router';
import moment from 'moment';
import {
  fetchRecords,
  clearRecords,
} from 'interfaces/global/store/modules/records/actions';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import {
  getOrdersWithInfo,
  getOrdersPaginationParams,
  getOpenedOrderId,
} from 'interfaces/global/store/modules/records/selectors';
import { cloneOrder } from 'store/modules/records';
import { getServiceNames } from 'store/modules/services';
import { createLoadingSelector } from 'store/modules/loading';
import { invalidSessionErrors } from 'store/modules/auth/helpers';
import { hasMessage } from 'store/modules/message';
import { openPanel, closePanel, isPanelOpen, getPanel } from 'store/modules/ui';

import HtmlTitle from 'components/HtmlTitle';
import PageHeader from 'components/PageHeader';

import {
  noop,
  parseUrlParams,
  encodeQueryData,
  globalOrderStatusMap as statusMap,
  globalOrderStatusLabels as statusLabels,
  trackingStatusMap,
  globalOrderStatusMap,
  statusFilterMap,
} from 'utils/helpers';

import {
  getCurrentCity,
  getCurrentLocale,
  getCurrentCountry,
} from 'store/modules/region/selectors';
import { getUser } from 'store/modules/auth/selectors';
import MobileRecordsPage from './components/MobileRecords/index.tsx';
import TopControls from './components/TopControls';
import FilterControls from './components/FilterControls';
import RecordsTable from './components/RecordsTable';
import { OrderShape } from './propTypes';
import { Layout } from './style';
import {
  MAX_ROWS,
  REFRESH_ENABLED_PAGE_RANGE,
  REFRESH_RECORDS_LIST_INTERVAL,
  DEFAULT_MIN_DATE,
  MIN_DATE,
  MAX_DATE,
  DATE_FORMAT,
  MOBILE_RECORDS_PAGE_MAX_RECORDS,
} from './config';

export const convertStatusToStatusFilter = status => {
  const orderStatus = globalOrderStatusMap[status];
  if (!orderStatus || !statusFilterMap[orderStatus.id]) {
    return -1;
  }
  return statusFilterMap[orderStatus.id];
};

export class Records extends Component {
  static defaultProps = {
    t: noop,
    orders: [],
    openedOrderId: '',
    paginationParams: {},
    serviceNames: {},
    fetchRecords: noop,
    clearRecords: noop,
    // cloneOrder: noop,
    openPanel: noop,
    closePanel: noop,
    isLoading: false,
    hasSessionErrors: false,
    isDetailsOpen: false,
    panel: {
      id: '',
      props: {},
    },
    cityId: '',
    accessToken: '',
    locale: '',
    countryCode: 0,
    city: {},
    track: noop,
  };

  static propTypes = {
    match: object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: object.isRequired, // eslint-disable-line react/forbid-prop-types
    t: func,
    orders: arrayOf(OrderShape),
    openedOrderId: string,
    paginationParams: shape({
      lastRecordIDRef: string,
      endPage: number,
    }),
    serviceNames: shape({ [string]: string }),
    fetchRecords: func,
    clearRecords: func,
    // cloneOrder: func,
    openPanel: func,
    closePanel: func,
    isLoading: bool,
    hasSessionErrors: bool,
    isDetailsOpen: bool,
    panel: shape({
      id: string,
      props: shape({
        orderId: string,
        currentType: string,
      }),
    }),
    historyPush: func.isRequired,
    historyReplace: func.isRequired,
    cityId: number,
    accessToken: string,
    locale: string,
    countryCode: number,
    city: shape({}),
    isMobile: bool.isRequired,
    isDesktop: bool.isRequired,
    track: func,
  };

  // driven state by URL params
  params = this.getStateFromUrlParams();

  state = {
    search: this.params.search,
    status: this.params.status,
    current: this.params.current,
    max: this.params.max,
    recalculateTotal: false, // We need to force pagination to recalculate its "imaginary" `total` state when changing order status filter
    start: this.params.start,
    end: this.params.end,
  };

  componentDidMount() {
    const { historyReplace, location } = this.props;
    const [, , orderId, currentType] = location.pathname.split('/');
    if (orderId) {
      this.props.openPanel('ORDER', { orderId, currentType });
    }

    historyReplace({
      search: encodeQueryData(_pickBy(this.state, this.urlDefaultParams)),
    });

    this.initList();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      historyReplace,
      panel,
      isDetailsOpen,
      isLoading,
      isDesktop,
      isMobile,
    } = this.props;
    const { orderId, currentType } = panel.props;
    const { endPage } = this.props.paginationParams;

    if (!_isEqual(prevState, this.state)) {
      historyReplace({
        search: encodeQueryData(_pickBy(this.state, this.urlDefaultParams)),
      });

      if (isDesktop) {
        if (endPage === this.state.current) return;

        const { lastRecordIDRef } = this.props.paginationParams;
        this.fetchRecords({ lastId: lastRecordIDRef });
      } else this.fetchRecords({ max: MOBILE_RECORDS_PAGE_MAX_RECORDS });
    }

    if (!prevProps.isDesktop && isDesktop) {
      const { lastRecordIDRef } = this.props.paginationParams;
      this.fetchRecords({ lastId: lastRecordIDRef });
    }

    if (!prevProps.isMobile && isMobile) {
      if (this.interval) clearTimeout(this.interval);

      this.fetchRecords({ max: MOBILE_RECORDS_PAGE_MAX_RECORDS });
    }

    if (!prevProps.isDetailsOpen && isDetailsOpen) {
      this.updateUrlPath(orderId, currentType);
    }

    if (prevProps.isDetailsOpen && !isDetailsOpen) {
      this.updateUrlPath();
    }

    if (isDetailsOpen && prevProps.panel.props.currentType !== currentType) {
      this.updateUrlPath(orderId, currentType);
    }

    // This is a timeout that acts as an interval with conditions. Every time props.loading changes from true to false,
    // be it from an action the user performs (like paginating to another page or setting a filter),
    // or this timeout's function is called, a new timeout for the next refresh interval will be set.
    // Anytime the user performs a manual action like pagination, this timer is cleared before being reset again here.
    if (
      prevProps.isLoading &&
      !isLoading &&
      this.state.current <= REFRESH_ENABLED_PAGE_RANGE &&
      isDesktop
    ) {
      clearTimeout(this.interval); // Just to be really sure it's been cleared before setting a new one

      this.interval = setTimeout(
        this.refreshListOnTimeout,
        REFRESH_RECORDS_LIST_INTERVAL
      );
    }
  }

  componentWillUnmount() {
    if (this.props.isDetailsOpen) {
      this.props.closePanel();
    }

    if (this.interval) {
      clearTimeout(this.interval);
    }
  }

  getStateFromUrlParams() {
    const { location } = this.props;
    const { search, status, current, max, start, end } = parseUrlParams(
      location.search
    );
    return {
      search: search || '',
      status: status ? status.toUpperCase() : '',
      current: current ? +current : 1,
      max: max && max <= MAX_ROWS ? +max : MAX_ROWS,
      start: isDateValid(start) ? start : DEFAULT_MIN_DATE,
      end: isDateValid(end) ? end : MAX_DATE,
    };
  }

  fetchRecords = (options = {}) => {
    if (this.props.hasSessionErrors) return;

    const {
      max,
      status,
      search,
      current,
      start = DEFAULT_MIN_DATE,
      end = MAX_DATE,
    } = this.state;

    const statusFilter = convertStatusToStatusFilter(status);
    this.props.fetchRecords({
      prepend: false,
      max: current === 1 ? max * 2 : max, // double page size for page = 1 (prefetch the 2nd)
      searchOrderDisplayId: search,
      status: statusFilter,
      lastId: '',
      current,
      start,
      end,
      ...options,
    });
  };

  refreshListOnTimeout = () => {
    const { current, max } = this.state;
    this.fetchRecords({ prepend: true, max: (current + 1) * max }); // refresh all records from beginning until end of (current + 1) page (due to prefetching)
  };

  handleSearch = e => {
    e.preventDefault();

    const search = e.target.elements[0].value.trim();
    if (search === this.state.search) return;

    clearTimeout(this.interval);
    this.props.clearRecords();

    this.setState({ search, current: 1, recalculateTotal: true });

    this.props.track('orders_search_updated');
  };

  handleStatusFilter = ({ value }) => {
    clearTimeout(this.interval);
    this.props.clearRecords();

    this.setState({
      status: value,
      current: 1,
      recalculateTotal: true,
    });

    const parentStatusID = statusMap[value]?.parentID;
    const filterName = value ? trackingStatusMap[parentStatusID] : 'all';
    this.props.track('orders_filter_updated', {
      order_filter: filterName,
    });
  };

  handleDateChange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) return;

    const { start: oldStartDate, end: oldEndDate } = this.state;
    const newStartDate = startDate.format(DATE_FORMAT);
    const newEndDate = endDate.format(DATE_FORMAT);

    if (newStartDate === oldStartDate && newEndDate === oldEndDate) return;

    this.props.clearRecords();

    this.setState({
      start: newStartDate,
      end: newEndDate,
      current: 1,
      recalculateTotal: true,
    });

    this.props.track('orders_date_updated');
  };

  handlePagination = nextPage => {
    clearTimeout(this.interval);

    this.setState({
      current: nextPage,
      recalculateTotal: false,
    });
  };

  handleRowClick = (_, id) => {
    this.props.openPanel('ORDER', { orderId: id });
  };

  initList = () => {
    this.props.clearRecords();
    this.setState({
      current: 1,
      recalculateTotal: true,
    });
  };

  onManuallyRefreshList = () => {
    clearTimeout(this.interval);

    this.initList();
    this.fetchRecords();
  };

  renderTopControls = () => {
    const { search } = this.state;
    const { t, cityId, accessToken, locale, countryCode, city } = this.props;

    const searchOptions = {
      placeholder: t('Records.placeholder_search'),
      defaultValue: search,
      onSubmit: this.handleSearch,
    };

    // India ban workaround link
    const getArchivedOrderURL = () => {
      if (
        countryCode === 10000 &&
        (process.env.REACT_APP_HOST_ENV === 'production' ||
          process.env.REACT_APP_HOST_ENV === 'staging')
      )
        return city.urls.archivedOrders;

      return process.env.REACT_APP_ARCHIVED_ORDERS_URL;
    };

    const archivedOrdersOptions = {
      show: city.legacy, // Hide the link for US_DAL and other "new" cities that don't have LLM legacy system support
      url: `${getArchivedOrderURL()}?city-id=${cityId}&token=${accessToken}&locale=${locale}&hcountry=${countryCode}`,
      text: t('Records.archived_orders_link'),
    };

    return (
      <TopControls
        search={searchOptions}
        archivedOrders={archivedOrdersOptions}
      />
    );
  };

  renderFilterControls = () => {
    const { t, isLoading, orders } = this.props;
    const { current, max, status, start, end } = this.state;

    const { MATCHING, ON_GOING, COMPLETED, CANCELLED, SEND_BILL } = statusMap;
    const filters = [
      { value: '', label: t('Records.option_all') },
      ...[MATCHING, ON_GOING, COMPLETED, CANCELLED, SEND_BILL].map(s => ({
        value: Object.keys(statusMap)[s.id],
        label: t(statusLabels[s.id]),
      })),
    ];
    const selectedFilter = filters.find(option => option.value === status);

    const statusFilterOptions = {
      filters,
      selectedFilter,
      onFilterChange: this.handleStatusFilter,
    };

    const dateFilterOptions = {
      start,
      end,
      min: MIN_DATE,
      max: MAX_DATE,
      onDatesChange: this.handleDateChange,
    };

    const paginationOptions = {
      currentPage: current,
      pageSize: max,
      loading: isLoading,
      total: orders.length,
      onPageChange: this.handlePagination,
    };

    return (
      <FilterControls
        statusFilterOptions={statusFilterOptions}
        dateFilterOptions={dateFilterOptions}
        paginationOptions={paginationOptions}
        onRefresh={this.onManuallyRefreshList}
      />
    );
  };

  updateUrlPath = (orderId, currentType) => {
    const { match, location, historyPush } = this.props;
    let { url } = match;
    if (orderId) url = `${url}/${orderId}`;
    if (currentType) url = `${url}/${currentType}`;
    historyPush({ ...location, pathname: url });
  };

  urlDefaultParams = (value, key) => {
    if (key === 'max' && value > MAX_ROWS) {
      return MAX_ROWS;
    }
    return key !== 'current' && value !== '' && key !== 'recalculateTotal';
  };

  render() {
    const {
      t,
      orders,
      serviceNames,
      isLoading,
      isMobile,
      openedOrderId,
    } = this.props;
    const { search, max, current, start, end, status } = this.state;

    const isCustomFilters =
      search !== '' ||
      status !== '' ||
      current !== 1 ||
      max !== MAX_ROWS ||
      start !== DEFAULT_MIN_DATE ||
      end !== MAX_DATE;

    if (isMobile)
      return (
        <MobileRecordsPage
          onFilterChange={this.handleStatusFilter}
          status={status}
          isCustomFilters={isCustomFilters}
        />
      );

    return (
      <Layout>
        <HtmlTitle>{t('Title.records')}</HtmlTitle>
        <PageHeader
          topControls={this.renderTopControls()}
          title={t('Records.title_records')}
          headerEnd={this.renderFilterControls()}
        />
        <RecordsTable
          max={max}
          current={current}
          orders={orders}
          openedOrderId={openedOrderId}
          isLoading={isLoading}
          isCustomFilters={isCustomFilters}
          serviceNames={serviceNames}
          openPanel={this.props.openPanel}
          track={this.props.track}
        />
      </Layout>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_RECORDS']);

const isDateValid = dateString =>
  moment(dateString, DATE_FORMAT, true).isValid() &&
  dateString >= MIN_DATE &&
  dateString <= MAX_DATE;

const mapState = state => {
  const city = getCurrentCity(state);

  return {
    orders: getOrdersWithInfo(state),
    openedOrderId: getOpenedOrderId(state),
    serviceNames: getServiceNames(state),
    isLoading: loadingSelector(state),
    panel: getPanel(state),
    hasSessionErrors: hasMessage(['SESSION'], invalidSessionErrors)(state),
    isDetailsOpen: isPanelOpen('ORDER')(state),
    paginationParams: getOrdersPaginationParams(state),
    cityId: city && city.cityId,
    accessToken: getUser(state).access_token,
    locale: getCurrentLocale(state),
    countryCode: getCurrentCountry(state).countryId,
    city,
  };
};

export default compose(
  withTranslation(),
  withResponsiveMedia,
  connect(mapState, {
    fetchRecords,
    clearRecords,
    cloneOrder,
    openPanel,
    closePanel,
    historyPush: push,
    historyReplace: replace,
    track,
  })
)(Records);
