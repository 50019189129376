import React, { useEffect, useState } from 'react';
import { Button, Input } from '@lalamove/karang';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'store/modules/loading';
import { dismissMessage, makeMessageSelector } from 'store/modules/message';
import { Form } from 'interfaces/global/containers/Settings/style';
import { RESET_PW_FAILURE } from 'interfaces/global/store/modules/auth/actions';
import { whenMobile } from 'components/MediaQuery';

const StyledInput = styled(Input)`
  display: block;
  margin-bottom: 1rem;

  ${whenMobile} {
    input {
      font-size: 16px;
    }
  }
`;

const ButtonGroup = styled.div`
  text-align: right;
`;

const CancelButton = styled(Button)`
  margin-right: 1rem;
`;

const PasswordChangeForm = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSubmitting = useSelector(state =>
    createLoadingSelector(['RESET_PW'])(state)
  );
  const apiError = useSelector(state =>
    makeMessageSelector(['RESET_PW'])(state)
  );

  const [formState, setFormState] = useState({
    newPassword: '',
    newPasswordConfirm: '',
    mismatch: false,
    isReady: false,
  });

  const formStateJSONString = JSON.stringify(formState);

  useEffect(() => {
    setFormState(state => ({
      ...state,
      isReady: !!(state.newPassword && state.newPasswordConfirm),
    }));
  }, [formStateJSONString]);

  const MIN_PASSWORD_LENGTH = 6;

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormState(state => ({ ...state, [name]: value, mismatch: false }));
    if (apiError && apiError.message) dispatch(dismissMessage('RESET_PW'));
  };

  const validateSubmit = e => {
    e.preventDefault();
    if (formState.newPassword !== formState.newPasswordConfirm) {
      setFormState(state => ({ ...state, mismatch: true }));
      return;
    }

    if (formState.newPassword.length < MIN_PASSWORD_LENGTH) {
      dispatch({
        type: RESET_PW_FAILURE,
        meta: {
          type: 'error',
          message: t('ForgotPassword.password_req'),
        },
      });
      return;
    }

    onSubmit(formState.newPassword);
  };

  return (
    <Form onSubmit={validateSubmit}>
      <StyledInput
        disabled={isSubmitting}
        name="newPassword"
        label={t('Settings.input_label_password_new')}
        value={formState.newPassword}
        type="password"
        onChange={handleInputChange}
        error={apiError && apiError.message ? t(apiError.message) : null}
      />
      <StyledInput
        disabled={isSubmitting}
        name="newPasswordConfirm"
        label={t('Settings.input_label_password_confirm')}
        value={formState.newPasswordConfirm}
        type="password"
        onChange={handleInputChange}
        error={
          formState.mismatch
            ? t('Settings.input_error_password_mismatch')
            : null
        }
      />
      <ButtonGroup>
        <CancelButton
          disabled={isSubmitting}
          type="reset"
          variant="link"
          onClick={onCancel}
        >
          {t('Settings.button_cancel')}
        </CancelButton>
        <Button
          disabled={!formState.isReady || isSubmitting}
          type="submit"
          variant="primary"
          solid
        >
          {t('Settings.button_save')}
        </Button>
      </ButtonGroup>
    </Form>
  );
};

PasswordChangeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PasswordChangeForm;
