import React from 'react';
import { arrayOf, string, func } from 'prop-types';
import styled from 'styled-components';

import { noop } from 'utils/helpers';
import { fontSize, fontWeight } from 'styles/fonts';
import { silver } from 'styles/colors';
import { GROUND } from 'styles/zIndex';

const lineThickness = 1;
const leftPad = 8;

const UL = styled.ul`
  position: relative;
  padding: 0;
  padding-left: ${leftPad}px;
  margin: 0;
  list-style: none;

  /* line */
  &:before {
    content: '';
    position: absolute;
    z-index: ${GROUND};
    top: 0.7em;
    bottom: 0.7em;
    left: 0;
    width: ${leftPad}px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: ${lineThickness}px dotted ${silver};
    border-right: 0;
  }

  /* arrow */
  &:after {
    content: '';
    position: absolute;
    z-index: ${GROUND + 1};
    bottom: calc(0.7em + 0.5px);
    left: calc(${leftPad}px - 0.2em); /* Diagonal 0.283 */
    width: 0.2em;
    height: 0.2em;
    border: 1px solid ${silver};
    border-bottom: 0;
    border-left: 0;
    transform: translateY(50%) rotate(45deg);
  }
`;

const LI = styled.li`
  position: relative;
  z-index: ${GROUND + 1};
  overflow: hidden;
  width: 100%;
  padding-left: 0.5em;
  line-height: 1.4;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:not(:first-of-type) {
    margin-top: 4px;
  }

  &:not(:first-of-type):not(:last-of-type) {
    font-size: ${fontSize.small};
    font-weight: ${fontWeight.bold};
  }
`;

const RouteList = ({ list, t, ...rest }) => (
  <UL {...rest}>
    {list
      .reduce((array, address, index) => {
        if (index === 0 || index === list.length - 1) {
          return [...array, address];
        }
        const { 0: first, length: l, [l - 1]: count } = array;
        if (typeof count === 'number') {
          return [first, count + 1];
        }
        return [...array, 1];
      }, [])
      .map((address, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <LI key={index}>
          {typeof address === 'number' ? t({ count: address }) : address}
        </LI>
      ))}
  </UL>
);
RouteList.defaultProps = {
  list: [],
  t: noop,
};
RouteList.propTypes = {
  list: arrayOf(string),
  t: func,
};

export default RouteList;
