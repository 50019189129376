import { takeLatest, put, call, take, cancel, fork } from 'redux-saga/effects';
import _some from 'lodash/some';

import { fetchLocationSearchHistory as fetchHistoryAPI } from 'api/mobileAPI';
import { CHANGE_LOCATION_SUCCESS } from 'store/modules/region/actions';

// ==== action ====
export const FETCH_LOCATION_SEARCH_HISTORY_REQUEST =
  'FETCH_LOCATION_SEARCH_HISTORY_REQUEST';
export const FETCH_LOCATION_SEARCH_HISTORY_SUCCESS =
  'FETCH_LOCATION_SEARCH_HISTORY_SUCCESS';
export const ENABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY =
  'ENABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY';
export const DISABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY =
  'DISABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY';
export const fetchLocationSearchHistory = () => ({
  type: FETCH_LOCATION_SEARCH_HISTORY_REQUEST,
});
export const fetchLocationSearchHistorySuccess = history => ({
  type: FETCH_LOCATION_SEARCH_HISTORY_SUCCESS,
  history,
});
export const enableActiveFetchLocationSearchHistory = () => ({
  type: ENABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY,
});
export const disableActiveFetchLocationSearchHistory = () => ({
  type: DISABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY,
});

// ==== selector =====
export const getPlacingLocationHistory = state => state.searchHistory.placing;
export const selector = {
  getPlacingLocationHistory,
};

// ==== saga ====
export function* handleFetchLocationSearchHistory() {
  try {
    const data = yield call(fetchHistoryAPI);
    yield put(fetchLocationSearchHistorySuccess(data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in fetchLocationSearchHistory', e);
  }
}

export function* handleLocationChange() {
  yield put(fetchLocationSearchHistory());
}

export function* handleActiveFetchLocationSearchHistory() {
  while (true) {
    yield take(ENABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY);
    yield put(fetchLocationSearchHistory());
    const task = yield takeLatest(
      CHANGE_LOCATION_SUCCESS,
      handleLocationChange
    );
    yield take(DISABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY);
    yield cancel(task);
  }
}

export function* saga() {
  yield takeLatest(
    FETCH_LOCATION_SEARCH_HISTORY_REQUEST,
    handleFetchLocationSearchHistory
  );
  yield fork(handleActiveFetchLocationSearchHistory);
}

// ==== reducer =====
export const INIT_STATE = {
  placing: [],
};
const onFetchLocationSearchHistorySuccess = (state, { history }) => ({
  ...state,
  placing: history.map(item => ({
    id: item.id,
    description: item.addressStr,
    lat: item.lat,
    lng: item.lng,
    placeId: item.placeId,
    contact: _some(item.contactPerson) ? item.contactPerson : undefined,
    addressDetails: _some(item.addressDetails)
      ? item.addressDetails
      : undefined,
  })),
});
export default function reducer(state = INIT_STATE, act) {
  switch (act.type) {
    case FETCH_LOCATION_SEARCH_HISTORY_SUCCESS:
      return onFetchLocationSearchHistorySuccess(state, act);
    default:
      return state;
  }
}
