const { REACT_APP_GOOGLE_OAUTH2_CLIENT_ID } = process.env; // oauth2 client id for google signIn

let GAPI; // Google Client (window.gapi)
const GAPI_LIBS = 'auth2';

/**
 * Add google script to the HEAD document, create Google client and load modules for google api.
 * @returns {Promise} Google API object
 */
export const createClient = modules =>
  new Promise((resolve, reject) => {
    window.googleAsyncInit = () => {
      GAPI = window.gapi;
      GAPI.load(GAPI_LIBS, {
        callback: () => resolve(GAPI),
        onerror: reject,
      });
      delete window.googleAsyncInit;
    };
    const init = () => {
      const script = document.createElement('script');
      script.onerror = () => reject(); // e.g. when script is blocked by privacy plugin
      script.src =
        'https://apis.google.com/js/platform.js?onload=googleAsyncInit';
      document.body.appendChild(script);
    };
    if (GAPI === undefined) {
      init();
    } else {
      resolve(GAPI);
    }
  });

export const getAuth2Instance = () => {
  let gAuth2 = GAPI.auth2.getAuthInstance();
  if (!gAuth2) {
    gAuth2 = GAPI.auth2.init({
      client_id: REACT_APP_GOOGLE_OAUTH2_CLIENT_ID,
    });
  }
  return gAuth2;
};
