import React from 'react';
import { useTranslation } from 'react-i18next';
import { checked as CheckedIcon } from 'components/Icons';

import {
  ListWrapper,
  ListContainer,
  StyledList,
  ItemWrapper,
  Content,
  Icon,
} from './styles';

import { ProfileType, ProfileTypeItem } from './useProfileItems';

interface ProfileItemProps {
  icon?: JSX.Element;
  selected?: boolean;
  onClick: () => void;
  children: any;
}

const ProfileItem: React.FC<ProfileItemProps> = ({
  icon,
  selected,
  children,
  onClick,
}) => (
  <ItemWrapper onClick={onClick}>
    {icon && <Icon>{icon}</Icon>}
    <Content>{children}</Content>
    {selected && <CheckedIcon />}
  </ItemWrapper>
);

interface ProfileListProps {
  profile: ProfileType;
  profileItems: ProfileTypeItem[];
  onSelectItem: (profile: ProfileTypeItem) => void;
  onLogout: () => void;
}

const ProfileList: React.FC<ProfileListProps> = ({
  profile,
  profileItems,
  onSelectItem,
  onLogout,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <ListContainer {...rest}>
      <ListWrapper>
        <StyledList
          hoverable
          items={profileItems}
          render={({ data, getProps }: any) => (
            <ProfileItem
              {...getProps()}
              icon={data.icon}
              selected={data.value === profile}
              onClick={() => onSelectItem(data)}
            >
              <span>{data.label}</span>
            </ProfileItem>
          )}
        />
      </ListWrapper>
      <ListWrapper>
        <ProfileItem onClick={onLogout}>
          <span>{t('Profile.logout')}</span>
        </ProfileItem>
      </ListWrapper>
    </ListContainer>
  );
};

export default ProfileList;
