export function constructTopupUrl({
  baseUrl,
  countryCode,
  locale,
  token,
  clientId,
}) {
  return `${baseUrl}/user/web?countryCode=${countryCode}&lang=${locale}&authToken=${token}&clientId=${clientId}`;
}

export function constructWindowSpecs() {
  const ps = calcCenterPosAndSize();
  return `left=${ps.left},top=${ps.top},height=${ps.height},width=${ps.width}`;
}

export function calcCenterPosAndSize() {
  const idealWidth = 720;
  const idealHeight = idealWidth;
  const { screen, screenX, screenY, innerWidth, innerHeight } = window;
  return {
    left: innerWidth / 2 - idealWidth / 2 + screenX,
    top: innerHeight / 2 - idealHeight / 2 + screenY,
    width: screen.width < idealWidth ? screen.width : idealWidth,
    height: screen.height < idealHeight ? screen.height : idealHeight,
  };
}
