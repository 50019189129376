import { createSu } from 'utils/apiHelper';
import { getUMETADomain } from 'store/modules/region/selectors';
import Fetcher from './Fetcher';

const { REACT_APP_UMETA_URL, NODE_ENV } = process.env;

export default class UMETAFetcher extends Fetcher {
  static uniqueName = 'UMETAFetcher';

  prepareBaseUrl = state => {
    let umetaUrl = getUMETADomain(state)
      ? getUMETADomain(state).replace(/http(s?):/, '')
      : REACT_APP_UMETA_URL;
    if (NODE_ENV === 'development') {
      umetaUrl = REACT_APP_UMETA_URL;
    }
    return umetaUrl;
  };

  prepareHeaders = (method, state, options) => {
    let headers = {
      ...options.headers,
    };
    if (['POST', 'PATCH'].includes(method)) {
      headers = {
        ...headers,
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      };
    }
    return headers;
  };

  prepareQuery = params => ({
    _su: createSu(),
    ...params,
  });

  prepareBody = params =>
    new URLSearchParams({
      ...params,
    });
}
