import { LalamoveApiError } from 'utils/helpers';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Request {
//   access_token: String;
//   client_id: Number | String;
//   is_marketing_opt_in: Boolean;
// }
/**
 * Set Marketing Opt-in status (For SG only)
 *
 * @param {bool} optIn - true if agree to opt-in, false if disagree to opt-in
 * @return {Promise} statusObj - `{success: {bool}}`
 * @throws UNKNOWN
 */
export default async function setMarketingOptIn(optIn) {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    is_marketing_opt_in: +optIn,
  };
  const { success, data, code } = await fetcher.post(
    'updatepreferences',
    params
  );
  if (!success) throw new LalamoveApiError(code);
  return data;
}
