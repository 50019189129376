import uniformInvoiceDonations from 'interfaces/global/config/uniformInvoiceDonations.json';
import storage from 'utils/storage';

export const DUPLICATE = 'DUPLICATE';
export const TRIPLICATE = 'TRIPLICATE';
export const DONATION = 'DONATION';
export const DEFAULT_INVOICE_TYPE = DONATION;
export const DEFAULT_INVOICE_INFO = {
  name: '',
  email: '',
  address: '',
};

const INVOICE_SELECTED_MAP_KEY = 'invoice_selected_map';

export function loadSelectedInvoice(userFid) {
  const selectedInvoiceMap = loadSelectedInvoiceMap();
  return selectedInvoiceMap[userFid] || DEFAULT_INVOICE_TYPE;
}

export function saveSelectedInvoice(userFid, type) {
  const selectedInvoiceMap = loadSelectedInvoiceMap();
  storage.setItem(
    INVOICE_SELECTED_MAP_KEY,
    JSON.stringify({
      ...selectedInvoiceMap,
      [userFid]: type,
    })
  );
}

function loadSelectedInvoiceMap() {
  return JSON.parse(storage.getItem(INVOICE_SELECTED_MAP_KEY)) || {};
}

export function loadInvoiceInfo(userFid) {
  return {
    [DUPLICATE]: loadInvoiceInfoMap(DUPLICATE)[userFid] || DEFAULT_INVOICE_INFO,
    [TRIPLICATE]: loadInvoiceInfoMap(TRIPLICATE)[userFid] || {
      ...DEFAULT_INVOICE_INFO,
      taxID: '',
    },
    [DONATION]: loadInvoiceInfoMap(DONATION)[userFid] || {
      donationIndex: 0,
    },
  };
}

export function saveInvoiceInfo(userFid, type, info) {
  saveSelectedInvoice(userFid, type);
  const invoiceInfoMap = loadInvoiceInfoMap(type);
  storage.setItem(
    `invoice_${type}_map`,
    JSON.stringify({
      ...invoiceInfoMap,
      [userFid]: info,
    })
  );
}

export function getCompleteInvoiceInfo(userFid, type) {
  const info = loadInvoiceInfo(userFid)[type];
  if (type === DONATION) {
    const index = info.donationIndex;
    return uniformInvoiceDonations[index];
  }
  return info;
}

function loadInvoiceInfoMap(type) {
  return JSON.parse(storage.getItem(`invoice_${type}_map`)) || {};
}
