import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bool, func, string, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';

import { resendPinRequest, verifyPinRequest } from 'store/modules/auth/actions';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';
import {
  selectors as regionSelector,
  actions as regionAction,
} from 'store/modules/region';
import HtmlTitle from 'components/HtmlTitle';

import UsernameForm from './UsernameForm'; // eslint-disable-line import/no-named-as-default
import PinForm from './PinForm';
import EmailSent from './EmailSent';

const waitDuration = 60000;
const PW_RESET_EMAIL_SENT = 'PW_RESET_EMAIL_SENT';
const CODE_SMS_SENT = 'CODE_SMS_SENT';
const SMS_CODE_RESENT = 'SMS_CODE_RESENT';
const ERROR_MAX_RESET = 'ERROR_MAX_RESET';

export class ForgotPasswordContainer extends Component {
  static defaultProps = {
    apiError: {},
  };

  static propTypes = {
    resendPinRequest: func.isRequired,
    verifyPinRequest: func.isRequired,
    t: func.isRequired,
    username: string.isRequired,
    uuid: string.isRequired,
    isWaiting: bool.isRequired,
    status: string.isRequired,
    loading: bool.isRequired,
    apiError: shape({ message: string }),
    currentCountry: shape({ areaCode: string }).isRequired,
    currentCity: shape({ csPhone: string }).isRequired,
    countryDict: shape({}).isRequired,
    locale: string.isRequired,
    changeLocation: func.isRequired,
  };

  handleSubmitUsername = username => {
    const { currentCountry, locale } = this.props;
    this.props.resendPinRequest({
      username,
      areaCode: currentCountry.areaCode,
      lang: locale,
      waitDuration,
    });
  };

  handleSubmitPin = smsCode => {
    const {
      currentCountry: { areaCode },
    } = this.props;
    const { uuid, username } = this.props;
    this.props.verifyPinRequest({
      smsCode,
      uuid,
      username,
      areaCode,
    });
  };

  changeCountry = country => {
    const { currentCountry, changeLocation } = this.props;
    if (country === currentCountry.id) {
      return;
    }

    changeLocation({
      country,
    });
  };

  renderFormContent = () => {
    const { currentCountry, currentCity, countryDict } = this.props;
    const { username, loading, isWaiting, apiError, status, t } = this.props;
    const emailSent = status === PW_RESET_EMAIL_SENT;
    const smsSent = status === CODE_SMS_SENT || status === SMS_CODE_RESENT;
    const maxReset = apiError.message === ERROR_MAX_RESET;

    const helpLink = (
      <a href={`tel:${currentCity.csPhone}`}>
        {t('ForgotPassword.contact_cs', { hotline: currentCity.csPhone })}
      </a>
    );

    let formContent;
    if (!emailSent) {
      if (!smsSent) {
        formContent = (
          <UsernameForm
            countries={Object.values(countryDict)}
            country={currentCountry}
            onSubmit={this.handleSubmitUsername}
            loading={loading}
            error={!loading ? apiError.message : ''}
            onCountryChange={this.changeCountry}
          />
        );
      } else {
        formContent = (
          <PinForm
            onSubmitPin={this.handleSubmitPin}
            onResendRequest={this.handleSubmitUsername}
            loading={loading}
            isWaiting={isWaiting}
            waitDuration={waitDuration}
            maxReset={maxReset}
            username={username}
            label={t('ForgotPassword.resend')}
            error={!loading ? apiError.message : ''}
            helpLink={helpLink}
          />
        );
      }
    } else {
      formContent = (
        <EmailSent
          title={t('ForgotPassword.email_sent')}
          text={t('ForgotPassword.email_instruction', { email: username })}
          buttonLabel={t('ForgotPassword.back_to_login')}
        />
      );
    }
    return formContent;
  };

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <HtmlTitle>{t('Title.forgot_password')}</HtmlTitle>
        {this.renderFormContent()}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  username: state.auth.forgotPassword.username,
  uuid: state.auth.forgotPassword.uuid,
  isWaiting: state.auth.forgotPassword.isWaiting,
  status: state.auth.forgotPassword.status,
  loading: createLoadingSelector(['RESEND_PIN', 'VERIFY_PIN'])(state),
  apiError: makeMessageSelector(['RESEND_PIN', 'VERIFY_PIN'])(state),
  countryDict: regionSelector.getCountryDict(state),
  currentCity: regionSelector.getCurrentCity(state),
  currentCountry: regionSelector.getCurrentCountry(state),
  locale: regionSelector.getCurrentLocale(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    resendPinRequest,
    verifyPinRequest,
    changeLocation: regionAction.changeLocation,
  })
)(ForgotPasswordContainer);
