import { toLLMLower } from 'utils/locale';
import FetcherFactory from 'api/FetcherFactory';
import UAPIFetcher from 'api/UAPIFetcher';
import log from 'api/log';
import { getCurrentCity } from 'store/modules/region/selectors';
import { UApiError } from 'utils/helpers';

const getPlaceAutocomplete = async (keyword = '', placeType) => {
  if (keyword === '') return [];

  const fetcher = FetcherFactory.make(UAPIFetcher);
  const state = await fetcher.getRootState();
  const { translations } = getCurrentCity(state);
  const cityName = translations[0].value;
  const {
    accessToken,
    profileType,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      kw: keyword,
      city: cityName,
      place_type: placeType, // 0 起点, 1 终点, -1 无起点终点，例如常用地址
    }),
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
  };

  const { ret, data, msg } = await fetcher.get('?_m=map_poi_search', params);

  if (ret !== 0) {
    log.info('AutocompleteService.getPlacePredictions', {
      category: 'google-maps',
      ret,
      response: `JSON: ${JSON.stringify(data)}`, // prevent log service parsing JSON string as object
    });
    throw new UApiError(msg, ret);
  }

  return data.poi;
};
export default getPlaceAutocomplete;
