import _partial from 'lodash/partial';
import _omit from 'lodash/omit';
import { v4 as uuid } from 'uuid';
import { compose } from 'utils/helpers';
import { CHANGE_LOCATION_REQUEST } from 'store/modules/region/actions';
import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { PLACE_ORDER_SUCCESS } from 'store/modules/checkout';
import { INIT_EDIT_REQUEST } from 'store/modules/records/actionTypes';
import stopItemReducer from './stopItem';
import deliveryInfoReducer, {
  initState as deliveryInfoInitState,
} from './deliveryInfo';
import { MAX_NUM_WAYPOINTS, DRAFT_ORDER_ID } from './config';

// action-types
import {
  ROUTE_REARRANGE,
  WAYPOINT_NEW,
  WAYPOINT_REMOVE,
  WAYPOINT_UPDATE,
  ROUTE_OPTIMIZE_TOGGLE,
  ROUTE_WAYPOINTS_SET,
  ROUTE_WAYPOINTS_TRIM,
  GOOGLE_DIRECTION_RESULT_RECEIVED,
  IMPORT_REQUEST,
  IMPORT_FAILURE,
  WAYPOINT_ERROR_TOGGLE,
  SESSION_TOKEN_UPDATE,
} from './actionTypes';

// error-types
import { ERROR_INVALID_ADDR } from './errorTypes';

export * from './saga';

// action creator
export const rearrangeRoute = (
  { sourceIndex, destinationIndex },
  orderId = DRAFT_ORDER_ID
) => ({
  type: ROUTE_REARRANGE,
  from: sourceIndex,
  to: destinationIndex,
  orderId,
});

export const newWaypt = (orderId = DRAFT_ORDER_ID) => ({
  type: WAYPOINT_NEW,
  id: uuid(),
  orderId,
});

export const removeWaypt = (id, orderId = DRAFT_ORDER_ID) => ({
  type: WAYPOINT_REMOVE,
  id,
  orderId,
});

export const updateWaypt = ({ id, ...rest }) => {
  if (!id) throw new Error();
  return {
    type: WAYPOINT_UPDATE,
    id,
    ...rest,
  };
};

export const toggleOptimize = (toggle, orderId = DRAFT_ORDER_ID) => ({
  type: ROUTE_OPTIMIZE_TOGGLE,
  toggle,
  orderId,
});

export const setWaypointsOrder = (mapping, orderId = DRAFT_ORDER_ID) => ({
  type: ROUTE_WAYPOINTS_SET,
  mapping,
  orderId,
});

export const trimWaypoints = (orderId = DRAFT_ORDER_ID) => ({
  type: ROUTE_WAYPOINTS_TRIM,
  orderId,
});

export const receiveFileUpload = payload => ({
  type: IMPORT_REQUEST,
  payload,
});

export const toggleWayptError = (id, errorMsg, bool) => {
  const args = { id, errorMsg, bool };
  for (const field in args) {
    if (args[field] === undefined)
      throw new Error(`missing arg: ${field} in ${JSON.stringify(args)}`);
  }
  return {
    type: WAYPOINT_ERROR_TOGGLE,
    errorMsg,
    id,
    bool,
  };
};

export const updateSessionToken = sessiontoken => ({
  type: SESSION_TOKEN_UPDATE,
  sessiontoken,
});

// state
export const initState = {
  waypoints: {},
  order: {},
  waypointsMap: {},
  deliveryInfo: deliveryInfoInitState,
  errors: {},
  sessiontoken: '',
};

const genNewState = () => {
  // add 2 stops for initState
  const stopOne = _partial(reducer, _partial.placeholder, newWaypt());
  const stopTwo = _partial(reducer, _partial.placeholder, newWaypt());
  return compose(stopOne, stopTwo)(initState);
};

// selectors
export function getWaypoints({ order, waypoints }, orderId = DRAFT_ORDER_ID) {
  return order[orderId] ? order[orderId].sequence.map(id => waypoints[id]) : [];
}
export function getWaypointById({ waypoints }, id) {
  return waypoints[id];
}
export function getFilledWaypts(state, orderId = DRAFT_ORDER_ID) {
  const { order, waypoints } = state.routing;
  return order && order[orderId]
    ? order[orderId].sequence
        .filter(wpId => !!waypoints[wpId].lat && !!waypoints[wpId].lng)
        .map(id => waypoints[id])
    : [];
}
export function getFilledWayptsIds(state, orderId = DRAFT_ORDER_ID) {
  const { order, waypoints } = state.routing;
  return order && order[orderId]
    ? order[orderId].sequence.filter(
        wpId => !!waypoints[wpId].lat && !!waypoints[wpId].lng
      )
    : [];
}
export function getGoogleDirections(state, orderId = DRAFT_ORDER_ID) {
  const { order } = state.routing;
  return order[orderId] ? order[orderId].googleDirectionsResult : null;
}
export function getShouldOptimize(state, orderId = DRAFT_ORDER_ID) {
  const { order } = state.routing;
  return order[orderId] ? order[orderId].shouldOptimize : false;
}
export function getErrorByStopItemId(state, id) {
  return state.routing.errors[id];
}
export function getRouteErrors(state) {
  return state.routing.errors;
}
export function getOrderIdByWayptId(state, id) {
  return state.routing.waypointsMap[id];
}

export function getSessionToken(state) {
  return state.routing.sessiontoken;
}

let orderMemo = {};
// reducer
export default function reducer(state = genNewState(), action) {
  switch (action.type) {
    case CHANGE_LOCATION_REQUEST:
    case IMPORT_REQUEST:
    case REQUEST_LOGOUT:
    case PLACE_ORDER_SUCCESS:
      return genNewState();
    case INIT_EDIT_REQUEST: {
      const { orderId } = action;
      const updatedState = _omit(state.order, [orderId]);
      return {
        ...state,
        order: {
          ...updatedState,
        },
      };
    }
    case GOOGLE_DIRECTION_RESULT_RECEIVED: {
      const { result, orderId } = action;
      return {
        ...state,
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            googleDirectionsResult: result,
          },
        },
      };
    }
    case ROUTE_WAYPOINTS_SET: {
      const { mapping, orderId } = action;
      if (!mapping) {
        return {
          ...state,
          order: {
            ...state.order,
            [orderId]: {
              ...state.order[orderId],
              sequence: orderMemo[orderId],
            },
          },
        };
      }

      // faking the rootState.
      const orderIdArray = getFilledWaypts({ routing: state }, orderId).map(
        wypt => wypt.id
      );

      orderMemo = { ...orderMemo, [orderId]: orderIdArray }; // remember order
      const start = orderIdArray[0];

      const newWayptsOrder = mapping.map(i => orderIdArray[i + 1]);
      return {
        ...state,
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            sequence: [start, ...newWayptsOrder],
          },
        },
      };
    }
    case ROUTE_OPTIMIZE_TOGGLE: {
      const { orderId } = action;
      return {
        ...state,
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            shouldOptimize: action.toggle,
          },
        },
      };
    }
    case WAYPOINT_REMOVE: {
      const { orderId } = action;
      const { [action.id]: _, ...rest } = state.waypoints; // remove the specific waypoint
      const { [action.id]: __, ...restMap } = state.waypointsMap;
      const newSequence = state.order[orderId].sequence.filter(
        id => id !== action.id
      );
      orderMemo = { ...orderMemo, [orderId]: newSequence };
      const nextState = {
        ...state,
        waypoints: rest,
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            sequence: newSequence,
          },
        },
        waypointsMap: restMap,
        errors: errorsReducer(state.errors, action),
      };
      const { length } = getFilledWaypts({ routing: nextState }, orderId);

      if (length < 2) {
        nextState.order[orderId].googleDirectionsResult = null;
      }

      return nextState;
    }
    case WAYPOINT_NEW: {
      const { id, orderId } = action;
      const { order } = state;
      if (
        order[orderId] &&
        order[orderId].sequence.length === MAX_NUM_WAYPOINTS
      ) {
        return state;
      }

      if (order[orderId]) {
        return {
          ...state,
          waypoints: {
            ...state.waypoints,
            [id]: stopItemReducer(null, action),
          },
          order: {
            ...state.order,
            [orderId]: {
              ...order[orderId],
              sequence: [...order[orderId].sequence, id],
            },
          },
          waypointsMap: {
            ...state.waypointsMap,
            [id]: orderId,
          },
          deliveryInfo: deliveryInfoReducer(state.deliveryInfo, action),
        };
      }

      return {
        ...state,
        waypoints: {
          ...state.waypoints,
          [action.id]: stopItemReducer(null, action),
        },
        order: {
          ...state.order,
          [orderId]: {
            sequence: [id],
            googleDirectionsResult: null,
            shouldOptimize: false,
          },
        },
        waypointsMap: {
          ...state.waypointsMap,
          [id]: orderId,
        },
        deliveryInfo: deliveryInfoReducer(state.deliveryInfo, action),
      };
    }
    case ROUTE_REARRANGE: {
      const { from, to, orderId } = action;
      const { order } = state;
      // remove
      let newSequence = [...order[orderId].sequence];
      const waypoint = newSequence.splice(from, 1);

      // insert
      newSequence = [
        ...newSequence.slice(0, to),
        ...waypoint,
        ...newSequence.slice(to),
      ];
      return {
        ...state,
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            sequence: newSequence,
          },
        },
      };
    }
    case WAYPOINT_UPDATE: {
      const { id, lat, lng, placeId } = action;
      const item = state.waypoints[id];
      if (!item) return state;

      const isValidAddr =
        !!(item.lat && item.lng) || !!((lat && lng) || placeId);

      return {
        ...state,
        waypoints: {
          ...state.waypoints,
          [id]: stopItemReducer(item, action),
        },
        errors: errorsReducer(state.errors, action, !isValidAddr),
        sessiontoken: '',
      };
    }
    case WAYPOINT_ERROR_TOGGLE: {
      const { errors } = state;
      return {
        ...state,
        errors: errorsReducer(errors, action),
      };
    }
    case IMPORT_FAILURE: {
      return {
        ...state,
        errors: errorsReducer(state.errors, action),
      };
    }
    case ROUTE_WAYPOINTS_TRIM: {
      const { orderId } = action;
      const ids = [];
      // trim empty waypoints
      return {
        ...state,
        waypoints: Object.values(state.waypoints)
          .filter(waypt => {
            const isEmpty = waypt.lat === null || waypt.lng === null;
            if (isEmpty) ids.push(waypt.id);
            return !isEmpty;
          })
          .reduce(
            (newWaypts, waypt) => ({ ...newWaypts, [waypt.id]: waypt }),
            {}
          ),
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            sequence: state.order[orderId].sequence.filter(
              id => !ids.includes(id)
            ),
          },
        },
      };
    }
    case SESSION_TOKEN_UPDATE: {
      return {
        ...state,
        sessiontoken: action.sessiontoken,
      };
    }
    default:
      return {
        ...state,
        deliveryInfo: deliveryInfoReducer(state.deliveryInfo, action),
      };
  }
}

export function errorsReducer(
  state = initState.errors,
  action,
  toggle = false
) {
  switch (action.type) {
    case IMPORT_FAILURE: {
      const { meta } = action;
      if (!meta.ids) return state;
      const errors = meta.ids.reduce(
        (map, id) => ({
          ...map,
          [id]: {
            ...map[id],
            [meta.message]: true,
          },
        }),
        {}
      );
      return {
        ...state,
        ...errors,
      };
    }
    case WAYPOINT_REMOVE: {
      const { [action.id]: __, ...rest } = state; // remove the specific error
      return rest;
    }
    case WAYPOINT_UPDATE: {
      const { id } = action;
      const errorsObj = state[id];
      return {
        ...state,
        [id]: { ...errorsObj, [ERROR_INVALID_ADDR]: toggle },
      };
    }
    case WAYPOINT_ERROR_TOGGLE: {
      const { id, errorMsg, bool } = action;
      const errorsObj = state[id];
      return {
        ...state,
        [id]: { ...errorsObj, [errorMsg]: bool },
      };
    }
    default:
      return state;
  }
}
