export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';
export const SET_SERVICE = 'SERVICE_SET';
export const RESET_SERVICES = 'SERVICES_RESET';
export const ADD_SPECIAL_REQUEST = 'SPECIAL_REQUEST_ADD';
export const REMOVE_SPECIAL_REQUEST = 'SPECIAL_REQUEST_REMOVE';
export const SET_SUB_SPECIAL_REQUEST = 'SUB_SPECIAL_REQUEST_SET';

export const fetchServices = locale => ({
  type: FETCH_SERVICES_REQUEST,
  locale,
});

export const setService = (serviceIndex, source = '') => ({
  type: SET_SERVICE,
  serviceIndex,
  source, // event source for tracking
});

export const addSpecialRequest = (id, nextSubId) => ({
  type: ADD_SPECIAL_REQUEST,
  id,
  nextSubId,
});

export const removeSpecialRequest = (id, prevSubId) => ({
  type: REMOVE_SPECIAL_REQUEST,
  id,
  prevSubId,
});

export const setSubRequest = (id, prevSubId, nextSubId) => ({
  type: SET_SUB_SPECIAL_REQUEST,
  id,
  prevSubId,
  nextSubId,
});

export const resetServices = () => ({
  type: RESET_SERVICES,
});
