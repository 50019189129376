import React, { useState, useEffect, useRef } from 'react';
import { arrayOf, number } from 'prop-types';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { darkGray, silver } from 'styles/colors';
import {
  WaypointShape,
  DeliveryInfoShape,
} from 'interfaces/global/views/Records/propTypes';
import {
  globalOrderStatusMap as statusMap,
  globalCompletedOrderStatusIDs as completed,
  globalCancelledOrderStatusIDs as cancelled,
} from 'utils/helpers';
import PODSuccessIcon from 'assets/svg/pod_success.svg';
import PinIcon from 'assets/svg/pin.svg';
import PickupIcon from 'assets/svg/pickup.svg';
import MidStopIcon from 'assets/svg/mid_stop.svg';

const matching = [statusMap.MATCHING.id, statusMap.LOADING.id];

const delivering = [
  statusMap.ON_GOING.id,
  statusMap.UNLOADING.id,
  statusMap.ORDER_LOADED.id,
];

const getIcon = (status, index, last, currentNode) => {
  if (cancelled.includes(status) || matching.includes(status)) {
    if (index === 0) {
      return PickupIcon;
    }
    if (last) {
      return PinIcon;
    }
    return MidStopIcon;
  }
  if (completed.includes(status)) {
    return PODSuccessIcon;
  }
  if (delivering.includes(status)) {
    if (index === 0) {
      if (currentNode > 1 || status === statusMap.ORDER_LOADED.id) {
        return PODSuccessIcon;
      }
      return PickupIcon;
    }
    if (last) {
      return PinIcon;
    }
    if (index > 0) {
      if (currentNode > index + 1) {
        return PODSuccessIcon;
      }
      if (currentNode === index + 1 && status === statusMap.ORDER_LOADED.id) {
        return PODSuccessIcon;
      }
    }
    return MidStopIcon;
  }
  return MidStopIcon;
};

const List = styled.ul`
  position: relative;
  padding: 0;
  list-style: none;

  &:before {
    content: '';
    position: absolute;
    top: 1.3em;
    bottom: ${({ height }) => height};
    left: 0.7em;
    opacity: ${({ status }) => (cancelled.includes(status) ? 0.5 : 1)};
    border-left: 2px dotted ${darkGray};
  }
`;

const ListItem = styled.li`
  position: relative;
  padding-left: 28px;
`;

const ItemContainer = styled.div`
  padding-bottom: 24px;
  line-height: 1.4;

  &:before {
    content: url(${({ status, index, last, currentNode }) =>
      getIcon(status, index, last, currentNode)});
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    background: #ffffff;
    opacity: ${({ status }) => (cancelled.includes(status) ? 0.5 : 1)};
  }
`;

const DeliveryInfo = styled.div`
  color: ${silver};
  font-size: 0.8em;
`;

const Route = ({ waypoints, deliveryInfo: infos, status, currentNode }) => {
  const [height, setHeight] = useState('5.2em');
  const ref = useRef(null);
  useEffect(() => {
    setHeight(`${ref.current.clientHeight}px`);
  }, []);
  return (
    <List height={height}>
      {waypoints.map((waypoint, i) => (
        <ListItem key={uuid()} ref={i === waypoints.length - 1 ? ref : null}>
          <ItemContainer
            status={status}
            last={i === waypoints.length - 1}
            index={i}
            currentNode={currentNode}
          >
            <div>{waypoint.name}</div>
            {Boolean(infos) && Boolean(infos[i]) && (
              <DeliveryInfo>
                {[infos[i].name, infos[i].phone, infos[i].addressDetails]
                  .filter(Boolean)
                  .join(' | ')}
              </DeliveryInfo>
            )}
          </ItemContainer>
        </ListItem>
      ))}
    </List>
  );
};

Route.propTypes = {
  waypoints: arrayOf(WaypointShape).isRequired,
  deliveryInfo: arrayOf(DeliveryInfoShape),
  status: number,
  currentNode: number,
};

Route.defaultProps = {
  deliveryInfo: null,
  status: null,
  currentNode: 0,
};

export default Route;
