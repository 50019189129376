import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchUserLocationSettings } from 'api/mobileAPI';

export const GENESYS_WIDGET_OPEN = 'GENESYS_WIDGET_OPEN';
export const GENESYS_WIDGET_CLOSE = 'GENESYS_WIDGET_CLOSE';
export const GENESYS_ADVANCE_CONFIG = 'GENESYS_ADVANCE_CONFIG';
export const GENESYS_LOGOUT = 'GENESYS_LOGOUT';
export const KHAKI_META_API = 'KHAKI_META_API';
export const WEB_CHAT_FLAG = 'WEB_CHAT_FLAG';

// export function createAction<P> = (type: string) => (payload: P = {}) => Action<string, P>;
const createAction = type => (payload = {}) => ({
  type,
  payload,
});

// Action
export const actions = {
  openWidget: createAction(GENESYS_WIDGET_OPEN),
  closeWidget: createAction(GENESYS_WIDGET_CLOSE),
  genesysAdvanceConfig: createAction(GENESYS_ADVANCE_CONFIG),
  logoutGenesys: createAction(GENESYS_LOGOUT),
  khakiMetaAPI: createAction(KHAKI_META_API),
  setWebChatFlag: createAction(WEB_CHAT_FLAG),
};

// reducer
export const initState = {
  isOpen: false,
  isScriptLoaded: false,
  config: {},
  genesysEnable: false,
  zendeskEnable: false,
};

const handleOpen = state => ({
  ...state,
  isScriptLoaded: true,
  isOpen: true,
});

const handleClose = state => ({
  ...state,
  isOpen: false,
});

const advanceConfig = (state, config) => ({
  ...state,
  config,
});
const handleLogout = state => ({
  ...state,
  isOpen: false,
});
const handleFlagSet = (state, data) => ({
  ...state,
  genesysEnable: data.genesysEnable,
  zendeskEnable: data.zendeskEnable,
});

export default function genesysReducer(state = initState, action) {
  switch (action.type) {
    case GENESYS_WIDGET_OPEN:
      return handleOpen(state);
    case GENESYS_WIDGET_CLOSE:
      return handleClose(state);
    case GENESYS_ADVANCE_CONFIG:
      return advanceConfig(state, action.payload);
    case GENESYS_LOGOUT:
      return handleLogout(state);
    case WEB_CHAT_FLAG:
      return handleFlagSet(state, action.payload);
    default:
      return state;
  }
}
export function logoutWidget() {
  if (typeof window.customPlugin === 'undefined') {
    return;
  }
  window.customPlugin.command('WebChatService.endChat');
  window.customPlugin.command('WebChat.close');
}
export function* khakiApiSaga(action) {
  const data = yield call(fetchUserLocationSettings, action.payload);
  const { webappGenesysEnabled, webappZendeskEnabled } = data.country;
  if (webappGenesysEnabled || webappZendeskEnabled) {
    yield put(
      actions.setWebChatFlag({
        genesysEnable: webappGenesysEnabled,
        zendeskEnable: webappZendeskEnabled,
      })
    );
  }
}

export function* genesysSaga() {
  yield takeLatest(GENESYS_LOGOUT, logoutWidget);
  yield takeLatest(KHAKI_META_API, khakiApiSaga);
}
