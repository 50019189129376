import React from 'react';
import Big from 'big.js';
import { instanceOf, string } from 'prop-types';
import styled from 'styled-components';
import { Button } from '@lalamove/karang';
import { walletAdd as WalletAddIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { green, red, darkGray } from 'styles/colors';
import formatter from 'utils/formatter';

const WalletBtn = styled(Button)`
  padding: 0;
  margin: 0 1em;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 210px;
  height: 30px;
`;

const NameWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 140px;
  padding: 0.5em 0.8em;
  border-right: 1px solid ${darkGray};
  color: rgba(180, 180, 180, 1);
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  box-sizing: border-box;
  padding: 0.5em 0.8em;
  color: ${({ color }) => color};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5em;
`;

const Content = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const WalletButton = ({ name, balance, ...remainProps }) => {
  const color = +balance > 0 ? green : red;
  return (
    <WalletBtn {...remainProps}>
      <Container>
        <NameWrapper>
          <Content>{name}</Content>
        </NameWrapper>
        <BalanceWrapper color={color}>
          <Content>{formatter.currency(balance)}</Content>
        </BalanceWrapper>
        <IconWrapper>
          <WalletAddIcon size={24} color={color} />
        </IconWrapper>
      </Container>
    </WalletBtn>
  );
};

WalletButton.propTypes = {
  name: string,
  balance: instanceOf(Big),
};

WalletButton.defaultProps = {
  name: '',
  balance: 0,
};

export default WalletButton;
