import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';

import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

export default async function calculatePrice({
  vehicleId,
  specialReq,
  latLon,
  orderTime,
  vehicleStdNames,
  addressInfo,
  cityInfoRevision,
  sameNum,
  vehicleStdTagIds,
  selectedPaymentMethodId,
  couponId,
}) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    cityId,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      city_id: cityId, // 城市id, e.g. 1002
      order_vehicle_id: vehicleId, // 订单车型id, e.g. 5
      std_tag: vehicleStdNames, // vehicleStd, // 车型规格
      std_tag_ids: vehicleStdTagIds,
      spec_req: specialReq, // 特别需求id: [1, 2, 3, 6]
      order_time: orderTime, // 订单时间戳
      city_info_revision: cityInfoRevision, // city_info的数字版本号
      // pay_type, // 支付方式
      // coupon_id, // 企业版优惠券id
      is_get_max_discount_coupon: '1', // 是否获取最大折扣优惠券
      addr_info: addressInfo,
      type: 2,
      lat_lon: latLon, // 发货地和收货地经纬度
      same_num: sameNum, // 电话号码是否是本人，0是，1不是
      pay_type: selectedPaymentMethodId,
      coupon_id: couponId,
    }),
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
    os: 'web',
  };

  const { ret, data, msg } = await fetcher.get('?_m=price_calculate', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data;
}
