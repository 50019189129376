import {
  primary,
  secondary,
  valencia,
  mountainMeadow,
  treePoppy,
  pictonBlue,
  mineShaft,
  nobel,
  white as LLMWhite,
} from '@lalamove/karang/dist/styles/colors';

export const orange = primary.main;
export const gold = secondary.main;
export const red = valencia.main;
export const green = mountainMeadow.main;
export const blue = pictonBlue.main;
export const darkBlue = pictonBlue['800'];
export const offWhite = mineShaft['300'];
export const silver = mineShaft['700'];
export const lightSilver = 'rgb(153, 153, 153)';
export const darkGray = nobel.main;
export const gray = mineShaft['800'];
export const black = mineShaft['900'];
export const white = LLMWhite;
export const facebook = 'rgba(59,89,152,1)';
export const google = '#4285f4';
export const lightGray = '#b2b2b2';
export const lightBlack = '#282828';
export const darkRed = '#9F231E';

// found that using the named colors above is no longer scalable
// export Karang colors to be directly used in web app:
export {
  mineShaft,
  mountainMeadow,
  nobel,
  pictonBlue,
  primary,
  secondary,
  treePoppy,
  valencia,
};
