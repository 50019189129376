/* eslint-disable lines-between-class-members */
import MonetaryAmount from './MonetaryAmount';
// interface PriceBreakdownAttr {
// additional attributes
//   id: string;
//   distance: string; // order base pricing
//   name: string; // promo code name
//   paidBy: string;
// }

// interface PriceBreakdownStruct extends MonetaryAmount {
//   type: string;
//   name: string;
//   paidBy: string;
//   paidTo: string;
//   attributes: PriceBreakdownAttr;
// }

// interface PriceSummaryStruct extends MonetaryAmount {}

export class PriceBreakdown extends MonetaryAmount {
  type = '';
  name = '';
  paidBy = '';
  paidTo = '';
  attributes = {};

  constructor(p = {}) {
    super(p);
    Object.assign(this, p);
  }

  static New(p) {
    return new PriceBreakdown(p);
  }
}

export class PriceSummary extends MonetaryAmount {}
