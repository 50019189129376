import styled from 'styled-components';
import BaseModal from 'components/BaseModal';
import { white } from 'styles/colors';

interface CircleProps {
  color: string;
}

export const Modal = styled(BaseModal)`
  & .coupon-modal-overlay--after-open {
    background-color: rgba(0, 0, 0, 0.7);
    transition: background-color 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  }

  & .ReactModal__Overlay--before-close {
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.4s;
  }

  & .coupon-modal {
    box-sizing: border-box;
    width: 95%;
    max-width: 24rem;
    padding: 16px 16px 0 16px;
    border-radius: 2px;

    opacity: 0;
    transform: scale(0.7);
  }

  & .coupon-modal--after-open {
    opacity: 1;
    transform: scale(1);
    transition: transform 0.3s 0.1s cubic-bezier(0.65, 0, 0.35, 1),
      opacity 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  }

  & .ReactModal__Content--before-close {
    opacity: 0;
    transform: scale(0);
    transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: 24rem;
  max-width: 100%;
  padding: 4px;
`;

export const Circle = styled.span`
  width: 18px;
  height: 18px;
  margin-right: 1em;
  background: ${({ color }: CircleProps) => color};
  border-radius: 50%;
  color: ${white};
  font-size: 0.714em;
  line-height: 18px;
  text-align: center;
`;
