// selectors
export function getUser(state) {
  return state.session.user;
}

export function checkAuth(state) {
  return (
    !!state.session.user.client_id &&
    state.session.checked &&
    state.session.authenticated
  );
}

export function getReferrer({ router }) {
  const locationState = router.location && router.location.state;
  return locationState && locationState.referrer ? locationState.referrer : '/';
}
