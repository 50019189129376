import { takeLatest, call, select, takeEvery, put } from 'redux-saga/effects';
import { GET_USER_SESSION_SUCCESS } from 'redux-react-session/dist/actionTypes';
import { LOCATION_CHANGE } from 'connected-react-router';

import i18n from 'utils/i18n';
import { elementExistPromise, elementNotExistsPromise } from 'utils/domHelper';

import {
  CHANGE_LOCATION_SUCCESS,
  CHANGE_LOCALE_SUCCESS,
} from 'store/modules/region/actions';
import { PANEL_OPEN, PANEL_CLOSE } from 'store/modules/ui';
import { getCurrentCity } from 'store/modules/region/selectors';
import { getUser } from 'store/modules/auth/selectors';

import { zEInstance, zopimInstance } from './instances';

export const ZENDESK_UNREAD_MESSAGE = 'ZENDESK_UNREAD_MESSAGE';
export const ZENDESK_OPEN = 'ZENDESK_OPEN';
export const ZENDESK_CLOSE = 'ZENDESK_CLOSE';
export const ZENDESK_LOGOUT = 'ZENDESK_LOGOUT';
export const ZENDESK_UPDATE_TAGS = 'ZENDESK_UPDATE_TAGS';
export const ZENDESK_MOVE_POSITION = 'ZENDESK_MOVE_POSITION';
export const ZENDESK_UI_INIT = 'ZENDESK_UI_INIT';

// export function createAction<P> = (type: string) => (payload: P = {}) => Action<string, P>;
const createAction = type => (payload = {}) => ({
  type,
  payload,
});
// interface updateTagsParam {
//   clientId?: String;
//   corporateCode?: string;
//   orderId?: string;
// }
// interface movePositionParam {
//   right: string;
//   bottom: string;
// }
export const actions = {
  updateUnreadCount: createAction(ZENDESK_UNREAD_MESSAGE), // createAction<number>
  open: createAction(ZENDESK_OPEN), // createAction<undefined>
  close: createAction(ZENDESK_CLOSE), // createAction<undefined>
  logout: createAction(ZENDESK_LOGOUT), // createAction<undefined>
  updateTags: createAction(ZENDESK_UPDATE_TAGS), // createAction<updateTagsParam>
  movePosition: createAction(ZENDESK_MOVE_POSITION), // createAction<movePositionParam>
  uiInit: createAction(ZENDESK_UI_INIT),
};

// reducer
export const initState = {
  isOpen: false,
  offset: { x: 16, y: 8 },
  unread: 0,
  tags: {
    userType: 'USER',
    corporateCode: '',
    clientId: '',
    orderId: '',
    city: '',
  },
};
const handleUnreadMessage = (state, unread) => ({
  ...state,
  unread: state.isOpen ? 0 : unread,
});
const handleOpen = state => ({
  ...state,
  unread: 0,
  isOpen: true,
});
const handleClose = state => ({
  ...state,
  isOpen: false,
});
const handleUpdateTags = (state, tags) => ({
  ...state,
  tags: {
    ...state.tags,
    ...tags,
  },
});
const handleMovePosition = (state, { right, bottom }) => ({
  ...state,
  offset: { x: right, y: bottom },
});
export default function zendeskReducer(state = initState, action) {
  switch (action.type) {
    case ZENDESK_UNREAD_MESSAGE:
      return handleUnreadMessage(state, action.payload);
    case ZENDESK_OPEN:
      return handleOpen(state);
    case ZENDESK_CLOSE:
      return handleClose(state);
    case ZENDESK_UPDATE_TAGS:
      return handleUpdateTags(state, action.payload);
    case ZENDESK_MOVE_POSITION:
      return handleMovePosition(state, action.payload);
    default:
      return state;
  }
}

// Saga
export function* updateIdentity() {
  const zE = yield call(zEInstance);
  const $zopim = yield call(zopimInstance);
  const {
    name,
    last_name: lastName,
    corporate_code: corporateCode,
    email,
    phone_number: phoneNumber,
    client_id: clientId,
  } = yield select(getUser);
  // CAUTION: $zopim.livechat is supposed to be deprecated
  $zopim.livechat.setPhone(phoneNumber);
  zE('webWidget', 'identify', {
    name: `${name} ${lastName}`,
    email,
  });
  yield put(
    actions.updateTags({
      clientId,
      corporateCode,
    })
  );
}
export function* openWidget() {
  const zE = yield call(zEInstance);
  zE('webWidget', 'show');
  zE('webWidget', 'open');
}
export function* closeWidget() {
  const zE = yield call(zEInstance);
  zE('webWidget', 'close');
  zE('webWidget', 'hide');
}
export function* logoutZendesk() {
  const zE = yield call(zEInstance);
  zE('webWidget', 'chat:end');
  zE('webWidget', 'logout');
}
export function* updateZendeskTags({ payload: newTags }) {
  const zE = yield call(zEInstance);
  const oldTags = yield select(state => state.zendesk.tags);
  const tags = { ...oldTags, ...newTags };
  const tagsToAdd = [
    tags.userType,
    `UserID: ${tags.clientId}`,
    ...(tags.corporateCode ? ['CORPORATE', tags.corporateCode] : []),
    ...(tags.orderId ? [`OrderID: ${tags.orderId}`] : []),
    ...(tags.city ? [tags.city] : []),
  ];
  zE('webWidget', 'updateSettings', {
    webWidget: {
      chat: { tags: tagsToAdd },
      // CAUTION: this is not from offical API doc
      // need to replace empty space as ticket tags will auto split string
      contactForm: { tags: tagsToAdd.map(x => x.replace(/ /g, '')) },
    },
  });
}
export function* handleLocaleChange({ locale }) {
  const zE = yield call(zEInstance);
  const zopim = yield call(zopimInstance);
  zE('webWidget', 'setLocale', locale);
  zopim.livechat.window.setTitle(i18n.t('Zendesk.widget_title'));
}
export function* handleLocationChange() {
  const zopim = yield call(zopimInstance);
  const { id, zendeskChatEnabled } = yield select(getCurrentCity);
  zopim.livechat.departments.setVisitorDepartment(id);
  if (!zendeskChatEnabled) {
    yield put(actions.close());
  }
  yield put(actions.updateTags({ city: id }));
}
export function* moveWidget({ payload: offset }) {
  const zE = yield call(zEInstance);
  zE('webWidget', 'updateSettings', {
    webWidget: {
      offset: {
        // remove preset offset in widget
        horizontal: `${offset.right - 16}px`,
        vertical: `${offset.bottom - 10}px`,
      },
    },
  });
}
export function* onBrowserLocationChange({
  payload: {
    location: { pathname },
  },
}) {
  const zE = yield call(zEInstance);
  const orderIdRoute = pathname.match(/^\/orders\/(\w*)\/?/);
  if (orderIdRoute) {
    yield put(actions.updateTags({ orderId: orderIdRoute[1] }));
  } else {
    yield put(actions.updateTags({ orderId: '' }));
  }
  zE('webWidget', 'updatePath');
}
export function* handlePanelOpen() {
  // TODO: not to hardcode Panel element
  const panel = yield elementExistPromise(node =>
    node.getElementsByClassName('LLM_SliderPanel')
  );
  if (panel[0]) {
    const width = panel[0].offsetWidth;
    yield put(
      actions.movePosition({
        right: width + 16,
        bottom: 8,
      })
    );
  }
}
export function* handlePanelClose() {
  yield elementNotExistsPromise(node =>
    node.getElementsByClassName('LLM_SliderPanel')
  );
  yield put(
    actions.movePosition({
      right: 16,
      bottom: 8,
    })
  );
}
export function* zendeskSaga() {
  yield takeLatest(GET_USER_SESSION_SUCCESS, updateIdentity);
  yield takeLatest(ZENDESK_OPEN, openWidget);
  yield takeLatest(ZENDESK_CLOSE, closeWidget);
  yield takeLatest(ZENDESK_LOGOUT, logoutZendesk);
  yield takeEvery(ZENDESK_UPDATE_TAGS, updateZendeskTags);
  yield takeEvery(CHANGE_LOCALE_SUCCESS, handleLocaleChange);
  yield takeEvery(CHANGE_LOCATION_SUCCESS, handleLocationChange);
  yield takeEvery(ZENDESK_MOVE_POSITION, moveWidget);
  yield takeEvery(LOCATION_CHANGE, onBrowserLocationChange);
  yield takeEvery(PANEL_OPEN, handlePanelOpen);
  yield takeEvery(PANEL_CLOSE, handlePanelClose);
}
