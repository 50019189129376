import React, { Component } from 'react';
import { bool, string, func } from 'prop-types';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { dropdown as DropDownIcon } from '@lalamove/karang/dist/components/Icon/icons';

import { noop } from 'utils/helpers';
import { silver } from 'styles/colors';

import addStyle from './style';
import 'rc-time-picker/assets/index.css';

const MIN_STEP = 10;

const ScTimePicker = addStyle(TimePicker);

function parseDateOnly(datetime) {
  return datetime.split('T')[0];
}

class OrderTimePicker extends Component {
  static defaultProps = {
    selectedTime: '',
    defaultDatetime: '',
    isScheduled: false,
    placeholder: '',
    onChange: noop,
  };

  static propTypes = {
    selectedTime: string,
    defaultDatetime: string,
    isScheduled: bool,
    placeholder: string,
    onChange: func,
  };

  state = {
    open: false,
  };

  container = React.createRef();

  disabledHours = () => {
    const { defaultDatetime, selectedTime } = this.props;
    const m = moment(defaultDatetime);
    const prevHours = [];

    if (!m.isSameOrAfter(parseDateOnly(selectedTime))) return prevHours;
    const hourNow = m.hours();
    for (let h = 0; h < hourNow; h += 1) {
      prevHours.push(h);
    }

    return prevHours;
  };

  disabledMinutes = () => {
    const { defaultDatetime, selectedTime } = this.props;
    const m = moment(defaultDatetime);
    const prevMinutes = [];

    if (!m.isSameOrAfter(selectedTime)) return prevMinutes;
    const minuteNow = m.minutes();
    for (let min = 0; min < minuteNow; min += MIN_STEP) {
      prevMinutes.push(min);
    }
    return prevMinutes;
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = m => {
    const { defaultDatetime } = this.props;
    // changing time always makes the order become scheduled:
    const isScheduled = true;
    if (m.isSameOrBefore(defaultDatetime)) {
      this.props.onChange(
        moment(defaultDatetime).add(MIN_STEP, 'mins').toISOString(true),
        isScheduled
      );
    } else {
      this.props.onChange(m.toISOString(true), isScheduled);
    }
  };

  renderTimeText(selectedTime, defaultDatetime) {
    const m = moment(selectedTime);
    if (defaultDatetime !== selectedTime || this.props.isScheduled) {
      return m;
    }
    return null;
  }

  render() {
    const {
      selectedTime,
      defaultDatetime,
      isScheduled,
      placeholder,
    } = this.props;
    const { open } = this.state;

    return (
      <div ref={this.container} style={{ position: 'relative' }}>
        <ScTimePicker
          popupClassName="llm-time-picker-popup"
          allowEmpty={false}
          showSecond={false}
          minuteStep={MIN_STEP}
          value={this.renderTimeText(selectedTime, defaultDatetime)}
          defaultOpenValue={moment(defaultDatetime)}
          placeholder={!isScheduled ? placeholder : ''}
          onChange={this.handleChange}
          format="HH:mm"
          inputReadOnly
          disabledHours={this.disabledHours}
          disabledMinutes={this.disabledMinutes}
          getPopupContainer={node => node}
          inputIcon={<DropDownIcon color={silver} size={24} />}
          open={open}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default OrderTimePicker;
