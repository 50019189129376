import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { CHANGE_LOCATION_REQUEST } from 'store/modules/region/actions';
import {
  FETCH_RECORDS_REQUEST,
  FETCH_RECORDS_SUCCESS,
  FETCH_RECORD_REQUEST,
  FETCH_RECORD_SUCCESS,
  FETCH_CLIENT_ORDER_REQUEST,
  FETCH_CLIENT_ORDER_SUCCESS,
  ORDER_ADD,
  CLONE_ORDER,
  CANCEL_ORDER_REQUEST,
  UPDATE_PRIORITY_FEE_REQUEST,
  UPDATE_PRIORITY_FEE_SUCCESS,
  RATE_ORDER_REQUEST,
  RATE_ORDER_SUCCESS,
  FAVORITE_DRIVER_REQUEST,
  FAVORITE_DRIVER_SUCCESS,
  BAN_DRIVER_REQUEST,
  BAN_DRIVER_SUCCESS,
  CANCEL_ORDER_SUCCESS,
  INIT_EDIT_REQUEST,
  EDIT_ORDER_REQUEST,
} from './actionTypes';
import orderReducer from './order';
import { mockOrders, mockPrice, mockDriver } from './tempMock';

export const fetchRecord = orderId => ({
  type: FETCH_RECORD_REQUEST,
  orderId,
});

export const fetchRecords = query => ({
  type: FETCH_RECORDS_REQUEST,
  query,
});

export const fetchClientOrder = clientOrderId => ({
  type: FETCH_CLIENT_ORDER_REQUEST,
  clientOrderId,
});

export const cloneOrder = orderId => ({
  type: CLONE_ORDER,
  orderId,
});

export const initEdit = orderId => ({
  type: INIT_EDIT_REQUEST,
  orderId,
});

export const editOrder = (orderId, quoteId) => ({
  type: EDIT_ORDER_REQUEST,
  orderId,
  quoteId,
});

export const cancelOrder = (orderId, reason, comment) => ({
  type: CANCEL_ORDER_REQUEST,
  orderId,
  reason,
  comment,
});

export const updatePriorityFee = (orderId, amount) => ({
  type: UPDATE_PRIORITY_FEE_REQUEST,
  orderId,
  amount,
});

export const rateOrder = (orderId, rating, reason, comment) => ({
  type: RATE_ORDER_REQUEST,
  orderId,
  rating,
  reason,
  comment,
});

export const favoriteDriver = driverId => ({
  type: FAVORITE_DRIVER_REQUEST,
  driverId,
});

export const banDriver = driverId => ({
  type: BAN_DRIVER_REQUEST,
  driverId,
});

export const mockDeliveryOrders = () => ({
  type: 'MOCK_DELIVERY_ORDERS',
});

export const initState = {
  orders: {},
  routes: {},
  prices: {},
  drivers: {},
  ids: [],
  count: 0,
};

export function getOrders(state) {
  return state.records.ids.map(id => state.records.orders[id]);
}

export function getOrdersWithInfo(state) {
  return state.records.ids.map(id => ({
    ...state.records.orders[id],
    waypoints: getWaypointsByOrderId(state, id),
    price: getPriceByOrderId(state, id),
    driver: getDriverById(state, state.records.orders[id].driverId),
  }));
}

export function getOrder(state, id) {
  return state.records.orders[id];
}

export function getRouteByOrderId(state, orderId) {
  if (!state.records.routes[orderId]) return {};
  const { waypoints, deliveryInfo, deliveries } = state.records.routes[orderId];
  return {
    waypoints,
    deliveryInfo,
    ...(deliveries && {
      deliveries: deliveries.map(delivery => ({
        ...delivery,
        parcels: delivery.parcels.reduce(
          (acc, parcel) => ({
            ...acc,
            [parcel.parcelStatus]: [
              ...(acc[parcel.parcelStatus] || []),
              {
                id: parcel.id,
                name: parcel.name,
                parcelStatus: parcel.parcelStatus,
                failedReason: parcel.failedReason,
              },
            ],
          }),
          {}
        ),
      })),
    }),
  };
}

export function getWaypointsByOrderId(state, orderId) {
  return state.records.routes[orderId].waypoints;
}

export function getPriceByOrderId(state, orderId) {
  return state.records.prices[orderId];
}

export function getDriverById(state, id) {
  return state.records.drivers[id];
}

export function getRecordCount(state) {
  return state.records.count;
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case CHANGE_LOCATION_REQUEST:
    case REQUEST_LOGOUT:
      return initState;
    case FETCH_RECORDS_SUCCESS: {
      const { count, ids } = action;
      return {
        ...state,
        ids,
        count,
      };
    }
    case ORDER_ADD:
    case FETCH_CLIENT_ORDER_SUCCESS:
    case FETCH_RECORD_SUCCESS: {
      return {
        ...state,
        orders: orderReducer(state.orders, action),
        routes: routeReducer(state.routes, action),
        prices: pricesReducer(state.prices, action),
        drivers: driversReducer(state.drivers, action),
      };
    }
    case CANCEL_ORDER_SUCCESS: {
      return {
        ...state,
        orders: orderReducer(state.orders, action),
      };
    }
    case UPDATE_PRIORITY_FEE_SUCCESS: {
      return {
        ...state,
        prices: pricesReducer(state.prices, action),
      };
    }
    case RATE_ORDER_SUCCESS: {
      return {
        ...state,
        orders: orderReducer(state.orders, action),
        drivers: driversReducer(state.drivers, action),
      };
    }
    case FAVORITE_DRIVER_SUCCESS:
    case BAN_DRIVER_SUCCESS:
      return {
        ...state,
        drivers: driversReducer(state.drivers, action),
      };
    // mock orders
    case 'MOCK_DELIVERY_ORDERS': {
      const newOrders = {};
      const newRoutes = {};
      const newPrices = {};
      const ids = [];
      mockOrders.forEach(item => {
        const { routes, ...order } = item;
        newOrders[item.id] = order;
        newRoutes[item.id] = routes;
        newPrices[item.id] = mockPrice;
        ids.push(item.id);
      });
      return {
        orders: newOrders,
        routes: newRoutes,
        prices: newPrices,
        drivers: mockDriver,
        ids,
        count: mockOrders.length,
      };
    }
    default: {
      return state;
    }
  }
}

export function routeReducer(state = initState.routes, action) {
  switch (action.type) {
    case ORDER_ADD:
    case FETCH_RECORD_SUCCESS: {
      const { row } = action;
      const {
        pathList,
        latlngList,
        addressList,
        placeIdList,
        recipientContactList,
        recipientAddressList,
      } = row.route;

      const waypoints = [];
      const deliveryInfo = [];

      pathList.forEach((p, idx) => {
        const latlngPair = latlngList[idx].split('|');
        waypoints.push({
          name: addressList[idx],
          placeId: placeIdList[idx],
          lat: parseFloat(latlngPair[0]),
          lng: parseFloat(latlngPair[1]),
        });
        deliveryInfo.push({
          name:
            recipientContactList[idx] && recipientContactList[idx].name
              ? recipientContactList[idx].name
              : '',
          phone:
            recipientContactList[idx] && recipientContactList[idx].phone
              ? recipientContactList[idx].phone
              : '',
          addressDetails: constructAddressDetails(recipientAddressList[idx]),
        });
      });
      return {
        ...state,
        [row.order.id]: { waypoints, deliveryInfo },
      };
    }
    default: {
      return state;
    }
  }
}

export function constructAddressDetails(recipientAddress) {
  if (recipientAddress === null) return '';
  if (typeof recipientAddress === 'object') {
    const addressDetails = _pick(recipientAddress, ['block', 'floor', 'room']);
    return Object.values(addressDetails).filter(Boolean).join('/');
  }
  return recipientAddress;
}

export function pricesReducer(state = initState.prices, action) {
  switch (action.type) {
    case ORDER_ADD:
    case FETCH_RECORD_SUCCESS: {
      const { row } = action;
      const { price } = row;
      price.total = Object.values(price).reduce((sum, item) => sum + item);
      return {
        ...state,
        [row.order.id]: price,
      };
    }
    case UPDATE_PRIORITY_FEE_SUCCESS: {
      const { orderId, amount } = action;
      const { total, ...price } = state[orderId]; // eslint-disable-line no-unused-vars
      price.tips = amount;
      price.total = Object.values(price).reduce((sum, item) => sum + item);
      return {
        ...state,
        [action.orderId]: price,
      };
    }
    default: {
      return state;
    }
  }
}

export function driversReducer(state = initState.drivers, action) {
  switch (action.type) {
    case ORDER_ADD:
    case FETCH_RECORD_SUCCESS: {
      const { driver } = action.row;
      const storedDriver = state[driver.id];
      return {
        ...state,
        ...(driver.id && {
          [driver.id]: storedDriver
            ? { ...storedDriver, ..._omitBy(driver, _isNil) }
            : driver,
        }),
      };
    }
    case FAVORITE_DRIVER_SUCCESS: {
      return {
        ...state,
        [action.driverId]: {
          ...state[action.driverId],
          favorited: true,
        },
      };
    }
    case BAN_DRIVER_SUCCESS: {
      return {
        ...state,
        [action.driverId]: {
          ...state[action.driverId],
          banned: true,
        },
      };
    }
    case RATE_ORDER_SUCCESS: {
      return {
        ...state,
        [action.driverId]: {
          ...state[action.driverId],
          avgRating: action.avgRating,
        },
      };
    }
    default: {
      return state;
    }
  }
}
