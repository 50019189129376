import _pickBy from 'lodash/pickBy';
import { toLLMUpper } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import RegionConfigAPIFetcher from 'api/RegionConfigAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

export default async function getRegionConfig(customDomain) {
  const fetcher = FetcherFactory.make(RegionConfigAPIFetcher);

  const res = await fetcher.get(
    '',
    {},
    { customDomain, skipStartUpLock: true }
  );
  const { ret, msg, data } = res;

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  const withoutIndiaDataOnProduction =
    process.env.REACT_APP_HOST_ENV === 'production'
      ? data.filter(country => country.id !== 'IN')
      : data;

  return withoutIndiaDataOnProduction.map(
    ({
      areaCode,
      defaultLanguage,
      translations,
      languages,
      currency,
      ...restData
    }) => ({
      areaCode: areaCode.replace('+', ''),
      defaultLanguage: toLLMUpper(defaultLanguage),
      translations: translations.map(x => ({
        ...x,
        id: toLLMUpper(x.id),
      })),
      languages: languages.map(x => ({
        ...x,
        id: toLLMUpper(x.id),
      })),
      ...(currency && {
        currencySymbol: currency.symbol,
        currencyRate: currency.rate,
        // to ISO 4217 format currency code, since some countries do not return uppercase code, e.g. inr
        isoCurrencyCode: currency.code.toUpperCase(),
      }),
      ..._pickBy(restData), // filter out falsy values
    })
  );
}
