import _pick from 'lodash/pick';
// action-types
import {
  DELIVERY_INFO_UPDATE,
  IMPORT_SUCCESS,
  WAYPOINT_NEW,
} from './actionTypes';

// action creators
export const updateDeliveryInfo = (wayptId, payload) => ({
  type: DELIVERY_INFO_UPDATE,
  id: wayptId,
  info: _pick(payload, ['name', 'phone', 'addressDetails']),
});

// state
export const initState = {};

// selector
export const getDeliveryInfo = rootState => rootState.routing.deliveryInfo;
export const getDeliveryInfoByWayptId = (rootState, id) =>
  rootState.routing.deliveryInfo[id];
export const getDeliveryInfoByWayptIds = (rootState, ids) =>
  ids.map(id => rootState.routing.deliveryInfo[id]);

// reducer
export default function reducer(state = initState, action) {
  switch (action.type) {
    case WAYPOINT_NEW: {
      return {
        ...state,
        [action.id]: {
          name: '',
          phone: '',
          addressDetails: '',
        },
      };
    }
    case IMPORT_SUCCESS: {
      return {
        ...state,
        ...action.deliveryInfo,
      };
    }
    case DELIVERY_INFO_UPDATE: {
      const info = state[action.id] || {};
      const updatedInfo = {
        ...info,
        ...action.info,
      };
      return { ...state, [action.id]: updatedInfo };
    }

    default:
      return state;
  }
}
