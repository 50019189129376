import React from 'react';
import { func, string } from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Dialog from 'components/Dialog';

const InitAppFailedDialog = ({ t, message, ...props }) => {
  const reloadLocation = () => {
    window.location.reload();
  };
  return (
    <Dialog
      isOpen
      title={t('Initialization.error_dialog_title_general')}
      subtitle={t(message)}
      primaryButtonText={t('Initialization.error_dialog_button_reload')}
      primaryAction={reloadLocation}
      shouldCloseOnOverlayClick={false}
      {...props}
    />
  );
};

InitAppFailedDialog.defaultProps = {
  message: 'Initialization.error_dialog_message_general',
};

InitAppFailedDialog.propTypes = {
  t: func.isRequired,
  message: string,
};

export default compose(withTranslation())(InitAppFailedDialog);
