import { LalamoveApiError } from 'utils/helpers';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Response {
//   reset_count: Number;
//   reset_oauth_client_id: Number;
//   uuid: String;
//   reset_expired_time: String;
//   reset_verified_time: String | null;
//   reset_status: 0 | 1;
// }
// email code verification for forgot password
export default async function checkPwrCode(emailCode) {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  const params = { secret: emailCode };
  const res = await fetcher.post('checkpwrcode', params);
  const { success, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return res;
}
