import React, { Component } from 'react';
import { compose } from 'redux';
import { Button, PinInput } from '@lalamove/karang';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { func, shape, string, bool, number } from 'prop-types';
import { noop } from 'utils/helpers';
import { withTranslation } from 'react-i18next';
import { silver } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { makeMessageSelector, dismissMessage } from 'store/modules/message';
import { createLoadingSelector } from 'store/modules/loading';
import { whenMobile, withResponsiveMedia } from 'components/MediaQuery';
import { PinInputContainer } from 'interfaces/global/containers/Settings/style';

const SMSForm = styled.form`
  width: 100%;
`;

const PinRemark = styled.div`
  margin: 1rem auto;
  color: ${silver};
  font-size: ${fontSize.regular};
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  text-align: right;

  ${whenMobile} {
    flex-direction: column-reverse;
  }
`;

const StyledButton = styled(Button)`
  ${whenMobile} {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin-bottom: 8px;
  }
`;

const CancelButton = styled(Button)`
  margin-right: 1rem;

  ${whenMobile} {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
`;

const SpacedContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EmailStrong = styled.span`
  font-weight: bold;
`;

export class EmailChangeVerifySMS extends Component {
  static defaultProps = {
    t: noop,
    emailState: {},
    error: null,
    verifyLoading: false,
    sendSMSLoading: false,
  };

  static propTypes = {
    t: func,
    emailState: shape({
      smsCode: string,
      changeEmailStep: number,
      newEmail: string,
    }),
    handleSendSMS: func.isRequired,
    handlePinChanged: func.isRequired,
    onCancel: func.isRequired,
    error: shape({ message: string, errorCode: number }),
    verifyLoading: bool,
    sendSMSLoading: bool,
    isMobile: bool.isRequired,
  };

  state = {
    timer: '',
  };

  componentDidUpdate(prevProps) {
    const { sendSMSLoading } = this.props;
    if (!sendSMSLoading && prevProps.sendSMSLoading) {
      this.initSendSMSTimer();
    }
  }

  componentWillUnmount() {
    clearInterval(this.sendSMSTimer);
  }

  initSendSMSTimer = () => {
    const blockTime = 60;
    this.setState({ timer: blockTime });
    clearInterval(this.sendSMSTimer);
    this.sendSMSTimer = setInterval(() => {
      const { timer } = this.state;
      this.setState({
        timer: timer - 1,
      });
      if (timer < 2) {
        clearInterval(this.sendSMSTimer);
      }
    }, 1000);
  };

  onPinChanged = smsCode => {
    if (smsCode.length === 4) {
      this.props.handlePinChanged(smsCode);
    } else {
      dismissMessage('CHANGE_EMAIL_SMS');
      dismissMessage('CHANGE_EMAIL_VERIFY_PIN');
    }
  };

  render() {
    const {
      t,
      error,
      verifyLoading,
      onCancel,
      emailState,
      handleSendSMS,
      sendSMSLoading,
      isMobile,
    } = this.props;
    const { newEmail } = emailState;
    const { timer } = this.state;
    return (
      <SpacedContainer>
        <SMSForm>
          <PinRemark>
            {t('Settings.update_email_tip')}{' '}
            <EmailStrong>{newEmail}</EmailStrong>
          </PinRemark>
          <PinInputContainer>
            <PinInput
              type="tel"
              size="small"
              disabled={verifyLoading}
              error={error && error.message ? t(error.message) : null}
              onChange={this.onPinChanged}
            />
          </PinInputContainer>
          <ButtonGroup>
            <CancelButton
              size={isMobile ? 'large' : 'default'}
              block={isMobile}
              disabled={sendSMSLoading}
              type="reset"
              variant="link"
              onClick={onCancel}
            >
              {t('Settings.button_cancel')}
            </CancelButton>
            <StyledButton
              size={isMobile ? 'large' : 'default'}
              block={isMobile}
              variant={isMobile ? 'primary' : 'link'}
              solid
              disabled={timer > 0}
              onClick={handleSendSMS}
              isLoading={sendSMSLoading}
            >
              {timer > 0
                ? t('Settings.email_send_sms_block', { timer })
                : t('Settings.email_send_sms_allow')}
            </StyledButton>
          </ButtonGroup>
        </SMSForm>
      </SpacedContainer>
    );
  }
}

const mapStateToProps = state => ({
  emailState: state.settings.emailSettings,
  error: makeMessageSelector(['CHANGE_EMAIL_VERIFY_PIN', 'CHANGE_EMAIL_SMS'])(
    state
  ),
  verifyLoading: createLoadingSelector(['CHANGE_EMAIL_VERIFY_PIN'])(state),
  sendSMSLoading: createLoadingSelector(['CHANGE_EMAIL_SMS'])(state),
});
export default compose(
  withTranslation(),
  withResponsiveMedia,
  connect(mapStateToProps)
)(EmailChangeVerifySMS);
