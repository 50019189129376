import { toLLMLower } from 'utils/locale';
import { LalamoveApiError } from 'utils/helpers';
import FetcherFactory from '../FetcherFactory';
import FetcherLockService from '../FetcherLockService';
import UAPIFetcher from '../UAPIFetcher';

export default async function getCancelReasons() {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  await FetcherLockService.langMutex.release();
  await FetcherLockService.accessTokenMutex.release();
  const {
    accessToken,
    profileType,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({}),
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
  };

  const { ret, data, msg } = await fetcher.get(
    '?_m=get_cancel_order_reason_web',
    params
  );

  if (ret !== 0) {
    throw new LalamoveApiError(ret, msg);
  }

  return data;
}
