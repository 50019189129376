import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { withTranslation } from 'react-i18next';
import { bool, func, shape } from 'prop-types';
import { Tab } from '@lalamove/karang';
import { getUser } from 'store/modules/auth/selectors';
import Transaction from 'models/Transaction';
import { noop } from 'utils/helpers';
import { STATEMENT, COUPON } from './config';
import { TabGroup } from './style';
import TransactionTable from './components/TransactionTable';
import Statement from './components/Statement';
import Coupon from './components/Coupon';

const { METHOD_CREDIT: CREDIT, METHOD_CASH: CASH } = Transaction;

export class Wallet extends Component {
  static defaultProps = {
    t: noop,
    track: noop,
  };

  static propTypes = {
    location: shape({}).isRequired,
    t: func,
    historyReplace: func.isRequired,
    shouldShowStatement: bool.isRequired,
    track: func,
  };

  handleTabChange = tabName => {
    // eslint-disable-next-line no-shadow
    const { historyReplace, track } = this.props;
    historyReplace({
      pathname: `/wallet/${tabName}`,
    });

    switch (tabName) {
      case CREDIT:
        track('online_payment_history_tapped');
        break;
      case COUPON:
        track('coupon_tapped');
        break;
      case STATEMENT:
        track('statement_tapped');
        break;
      default:
    }
  };

  renderTabs = () => {
    const { location, shouldShowStatement, t } = this.props;
    const [, , tabName] = location.pathname.split('/');
    return (
      <TabGroup
        activeTab={tabName}
        onTabChange={this.handleTabChange}
        variant="rounded"
      >
        <Tab name={CREDIT}>{t('Wallet.tab_online_payment')}</Tab>
        <Tab name={COUPON}>{t('Coupon.Coupon_tab')}</Tab>
        {shouldShowStatement && (
          <Tab name={STATEMENT}>{t('Wallet.tab_statement')}</Tab>
        )}
      </TabGroup>
    );
  };

  render() {
    return (
      <Switch>
        <Route
          path={`/wallet/${CREDIT}`}
          render={() => (
            <TransactionTable method={CREDIT} Tabs={this.renderTabs} />
          )}
        />
        <Route
          path={`/wallet/${COUPON}`}
          render={() => <Coupon Tabs={this.renderTabs} />}
        />
        <Route
          path={`/wallet/${CASH}`}
          render={() => (
            <TransactionTable method={CASH} Tabs={this.renderTabs} />
          )}
        />
        {this.props.shouldShowStatement && (
          <Route
            path={`/wallet/${STATEMENT}`}
            render={() => <Statement Tabs={this.renderTabs} />}
          />
        )}
        <Redirect from="/wallet" to={`/wallet/${CREDIT}`} />
      </Switch>
    );
  }
}

const mapState = state => {
  const { profile_type: profileType, profile = {} } = getUser(state);
  const { ep_role: epRole } = profile;
  // epRole: 1(super admin)
  return {
    shouldShowStatement: profileType === 1 && epRole === 1,
  };
};

export default compose(
  withTranslation(),
  connect(mapState, { historyReplace: replace, track })
)(Wallet);
