import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';
import styled from 'styled-components';

const MAX_LENGTH = 10;
const CHUNK_LENGTH = 5;
const SEPARATOR = '–';

const Separator = styled.span`
  user-select: none;
  &:after {
    content: '${SEPARATOR}';
  }
`;

const OrderIdDisplay = ({ orderId, chunked }) => {
  if (!orderId) return null;
  if (!chunked) {
    return <Fragment>{orderId}</Fragment>;
  }
  const regex = new RegExp(`(.{1,${CHUNK_LENGTH}})`, 'g');
  const chunks = orderId.substr(0, MAX_LENGTH).match(regex);
  return (
    <Fragment>
      {chunks.map((chunk, index) => (
        <Fragment key={chunk}>
          {index !== 0 && <Separator />}
          <span>{chunk}</span>
        </Fragment>
      ))}
    </Fragment>
  );
};

OrderIdDisplay.defaultProps = {
  orderId: null,
  chunked: true,
};

OrderIdDisplay.propTypes = {
  orderId: string,
  chunked: bool,
};

export default OrderIdDisplay;
