import { getOrder } from 'store/modules/records';
import {
  CLONE_ORDER,
  UPDATE_PRIORITY_FEE_SUCCESS,
  CANCEL_ORDER_SUCCESS,
} from 'store/modules/records/actionTypes';
import { getCurrentCountry } from 'store/modules/region/selectors';
import Segment from 'utils/segment';

export const SEGMENT_CLICK_ORDERS = 'Orders Tapped';
export const SEGMENT_OPEN_ORDER_DETAIL = 'Orders Detail Tapped';
export const SEGMENT_UPDATE_ORDERS_STATUS_FILTER = 'Orders Filter Updated';
export const SEGMENT_UPDATE_ORDERS_DATE_FILTER = 'Orders Date Updated';
export const SEGMENT_SUBMIT_ORDERS_SEARCH = 'Orders Search Updated';
export const SEGMENT_CLICK_ORDER_DROPDOWN = 'Orders More Tapped';
export const SEGMENT_CLONE_ORDER = 'Order Cloned';
export const SEGMENT_ADD_PRIORITY_FEE = 'Priority Fee Added';
export const SEGMENT_CANCEL_ORDER = 'Order Cancelled';

const recordsTracking = (prevState, action, nextState) => {
  switch (action.type) {
    case CLONE_ORDER: {
      const { orderId } = action;
      Segment.createTrack(SEGMENT_CLONE_ORDER, {
        order_id: orderId,
      });
      break;
    }
    case UPDATE_PRIORITY_FEE_SUCCESS: {
      const { orderId, amount } = action;
      const { isoCurrencyCode } = getCurrentCountry(prevState);
      Segment.createTrack(SEGMENT_ADD_PRIORITY_FEE, {
        order_id: orderId,
        amount_local: amount.toString(),
        currency_code: isoCurrencyCode,
      });
      break;
    }
    case CANCEL_ORDER_SUCCESS: {
      const { orderId, reason } = action;
      const { status } = getOrder(prevState, orderId);
      Segment.createTrack(SEGMENT_CANCEL_ORDER, {
        order_id: orderId,
        cancellation_reason: reason,
        order_status: status === 0 ? 'assigning' : 'matched',
      });
      break;
    }
    default:
      break;
  }
};

export default recordsTracking;
