import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';

import _curry from 'lodash/curry';
import _isMatch from 'lodash/isMatch';
import _pick from 'lodash/pick';
import formatter from 'utils/formatter';
import { noop } from 'utils/helpers';
import { fetchFavoriteDrivers } from 'api/mobileAPI';

import { getUser } from 'store/modules/auth/selectors';
import { getPrice, getBalance } from 'store/modules/pricing';
import { createLoadingSelector } from 'store/modules/loading';
import {
  initDate,
  changeDate,
  updateContact,
  toggleFavDriver,
  togglePayMethod,
  setCheckoutError,
  updatePaymentMethod,
  updatePromoCode,
  updateNote,
  getDefaultDatetime,
  getDeliveryDatetime,
  getContact,
  getPaymentMethod,
  getPromoCode,
  getPreferFavorite,
  getDriverNote,
  getCheckoutErrors,
  getCheckoutDirty,
} from 'store/modules/checkout';
import {
  getCurrentCity,
  getCurrentCountry,
} from 'store/modules/region/selectors';
import { openDialog } from 'store/modules/ui';

import DateContact from './components/DateContact';
import Driver from './components/Driver';
import Payment from './components/Payment';
import UniformInvoice from './components/UniformInvoice';

const DEFAULT_BUFFER_MIN = 10;

function prevent(e) {
  e.preventDefault();
}

const { bool, func, number, shape, string, oneOf } = PropTypes;

/**
 * Gets the next allowed time based on current time and offset
 * @param {Object} currentTime moment object
 * @param {integer} offset scheduled order city offset
 * @return {Object} next allowed time in moment object
 */
export const genNextAllowedTime = (currentTime, offset) => {
  let hrs = currentTime.hours();
  let min = currentTime.minutes();
  // add offset:
  min += offset;
  // if already in tenth minute, add 10 more:
  if (min % 10 === 0) min += 10;
  // else, round to the next tenth minute:
  else min = Math.ceil(min / 10) * 10;
  // readjust hrs & min:
  if (min >= 60) {
    min -= 60;
    hrs += 1;
    if (hrs === 24) hrs = 0;
  }
  return moment(currentTime).hour(hrs).minute(min).second(0);
};

export const FormGroup = styled.form`
  display: flex;
  flex-direction: column;
`;

class Checkout extends Component {
  static defaultProps = {
    t: noop,
    initDate: noop,
    changeDate: noop,
    updateContact: noop,
    updateNote: noop,
    updatePaymentMethod: noop,
    toggleFavDriver: noop,
    updatePromoCode: noop,
    setCheckoutError: noop,
    openDialog: noop,
    defaultDatetime: '',
    deliveryDatetime: '',
    paymentMethod: 'cash',
    contact: { name: '', phone: '' },
    note: '',
    preferFavorite: false,
    promoCode: '',
    error: { name: '', phone: '', promoCode: '' },
    price: { fee: 0 },
    balance: 0,
    canUseWallet: true,
    userContact: {},
    dirty: {},
    isFetchingPrice: true,
  };

  static propTypes = {
    t: func,
    initDate: func,
    changeDate: func,
    updateContact: func,
    updateNote: func,
    updatePaymentMethod: func,
    toggleFavDriver: func,
    updatePromoCode: func,
    setCheckoutError: func,
    openDialog: func,
    defaultDatetime: string,
    deliveryDatetime: string,
    paymentMethod: oneOf(['cash', 'wallet']),
    contact: shape({ name: string, phone: string }),
    note: string,
    preferFavorite: bool,
    promoCode: string,
    error: shape({ name: string, phone: string, promoCode: string }),
    price: shape({ fee: number }),
    balance: number,
    canUseWallet: bool,
    userContact: shape({ name: string, phone: string, country: string }),
    dirty: shape({ name: bool, phone: bool }),
    isFetchingPrice: bool,
    country: shape({ id: string }).isRequired,
    city: shape({ id: string, immediateOrderMinute: number }).isRequired,
  };

  static mounted = false;

  state = {
    // moved up from OrderTimePicker:
    isScheduled: window.location.search.indexOf('scheduled') > -1,
    hasFavDriver: false,
  };

  // TODO: Update deprecated usage before update to React 17
  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillMount() {
    this.fetchFavDrivers();
    const { city, country: currentCountry } = this.props;
    const offset = city.immediateOrderMinute || DEFAULT_BUFFER_MIN;
    const now = moment();
    const defaultDate = genNextAllowedTime(now, offset).toISOString(true);
    this.props.initDate(defaultDate);
    this.props.changeDate(defaultDate, this.state.isScheduled);

    // prefill contact
    const { dirty, contact } = this.props;
    const { name, phone, country: userCountry } = this.props.userContact;
    if (!contact.name && !dirty.name) {
      this.props.updateContact({ name }, false);
    }
    if (
      !contact.phone &&
      !dirty.phone &&
      // user.country now contains city code e.g. 'vn_han'
      userCountry.split('_')[0].toUpperCase() === currentCountry.id
    ) {
      phone && this.props.updateContact({ phone }, false);
    }
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleNoteChange = e => {
    this.props.updateNote(e.target.value);
  };

  handleDriverToggle = () => {
    this.props.toggleFavDriver(!this.props.preferFavorite);
  };

  handleContactChange = contact => {
    const changedContact = _pick(contact, ['name', 'phone']);
    const isDirty = !_isMatch(this.props.userContact, changedContact);
    this.props.updateContact(changedContact, isDirty);
  };

  handleDatetimeChange = (datetime, isScheduled) => {
    const { defaultDatetime } = this.props;
    const dirty =
      moment
        .duration(moment(datetime).diff(moment(defaultDatetime)))
        .as('minutes') > 0;
    this.setState({ isScheduled });
    this.props.changeDate(datetime, dirty || isScheduled);
  };

  handlePaymentChange = method => {
    // eslint-disable-next-line no-shadow
    const { canUseWallet, updatePaymentMethod, openDialog } = this.props;
    if (canUseWallet) {
      updatePaymentMethod(method);
    } else {
      openDialog('WALLET_LOW_BALANCE');
    }
  };

  async fetchFavDrivers() {
    try {
      const { count } = await fetchFavoriteDrivers({ current: 1, max: 1 });
      this.mounted &&
        this.setState({
          hasFavDriver: count > 0,
        });
    } catch (e) {
      console.error('error in fetchFavDrivers', e); // eslint-disable-line no-console
    }
  }

  render() {
    /* eslint-disable no-shadow */
    const {
      t,
      defaultDatetime,
      deliveryDatetime,
      preferFavorite,
      promoCode,
      price,
      contact,
      error,
      note,
      paymentMethod,
      toggleFavDriver,
      setCheckoutError,
      updatePromoCode,
      balance,
      country: currentCountry,
    } = this.props;
    /* eslint-enable no-shadow */
    const { isScheduled, hasFavDriver } = this.state;
    const displayBalance =
      paymentMethod === 'wallet' ? balance - price.total : balance; // deducted Rewards

    return price.fee <= 0 && price.services <= 0 ? (
      // Redirect to Place Order page if invalid route fee passed
      <Redirect to="/" />
    ) : (
      <FormGroup onSubmit={prevent}>
        <DateContact
          title={t('Checkout.heading_date_contact')}
          onError={setCheckoutError}
          onDatetimeChange={this.handleDatetimeChange}
          onContactChange={this.handleContactChange}
          placeholderTime={t('Checkout.label_now')}
          nowButtonText={t('Checkout.button_now')}
          labelName={t('Checkout.placeholder_name')}
          labelPhone={t('Checkout.placeholder_phone')}
          name={contact.name}
          phone={contact.phone}
          errors={{ name: t(error.name), phone: t(error.phone) }}
          defaultDatetime={defaultDatetime}
          deliveryDatetime={deliveryDatetime}
          isScheduled={isScheduled}
        />
        <Driver
          title={t('Checkout.heading_driver_notes')}
          hasFavorite={hasFavDriver}
          preferFavorite={preferFavorite}
          note={note}
          onFavToggle={toggleFavDriver}
          onNoteChange={this.handleNoteChange}
          limitMsgFunc={_curry(t, 2)('Checkout.label_driver_note_left')}
          errorMsgFunc={_curry(t, 2)('Checkout.label_driver_note_error')}
          placeholder={t('Checkout.placeholder_driver_notes')}
          toolTipText={t('Checkout.tip_view_details')}
          label={t('Checkout.checkbox_favourite_driver')}
          favDriverInfoText={t('Checkout.tip_favourite_driver')}
        />
        <Payment
          title={t('Checkout.heading_payment')}
          method={paymentMethod}
          onChange={this.handlePaymentChange}
          isFetchingPrice={this.props.isFetchingPrice}
          promoCode={promoCode}
          onPromoCodeChange={updatePromoCode}
          promoCodeErrorMsg={error.promoCode && t(error.promoCode)}
          labelPromoCode={t('Checkout.placeholder_promo_code')}
          labelWallet={t('Checkout.radio_wallet', {
            balance: formatter.currency(displayBalance),
          })}
          labelCash={t('Checkout.radio_cash')}
        />
        {currentCountry.id === 'TW' && paymentMethod === 'cash' && (
          <UniformInvoice />
        )}
      </FormGroup>
    );
  }
}

const mapState = state => {
  const { name, phone_number: phone, country } = getUser(state);
  const userContact = { name, phone, country };
  return {
    defaultDatetime: getDefaultDatetime(state),
    deliveryDatetime: getDeliveryDatetime(state),
    contact: getContact(state),
    note: getDriverNote(state),
    preferFavorite: getPreferFavorite(state),
    promoCode: getPromoCode(state),
    error: getCheckoutErrors(state),
    price: getPrice(state),
    paymentMethod: getPaymentMethod(state),
    canUseWallet: state.checkout.canUseWallet,
    balance: getBalance(state),
    userContact,
    dirty: getCheckoutDirty(state),
    isFetchingPrice: createLoadingSelector(['FETCH_PRICE'])(state),
    city: getCurrentCity(state),
    country: getCurrentCountry(state),
  };
};

export default compose(
  withTranslation(),
  connect(mapState, {
    initDate,
    changeDate,
    updateContact,
    updateNote,
    toggleFavDriver,
    togglePayMethod,
    setCheckoutError,
    updatePaymentMethod,
    updatePromoCode,
    openDialog,
  })
)(Checkout);
