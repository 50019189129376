import React from 'react';
import styled, { css } from 'styled-components';

import Modal from 'react-modal';
import { MODAL } from 'styles/zIndex';

interface StyledModalAdapterProps {
  className: string;
  modalClassName: string;
  overlayClassName: string;
  isOpen: boolean;
}

// https://github.com/reactjs/react-modal/issues/627#issuecomment-578814799
export const StyledModalAdapter: React.FC<StyledModalAdapterProps> = ({
  className,
  modalClassName,
  overlayClassName,
  ...props
}) => {
  return (
    <Modal
      className={modalClassName}
      portalClassName={className}
      overlayClassName={overlayClassName}
      bodyOpenClassName="portalOpen"
      closeTimeoutMS={600}
      // workaround for safari open not working
      {...({
        overlayElement: (overlayElementProps: any, contentElement: Element) => {
          if (
            overlayElementProps.className.indexOf(
              'ReactModal__Overlay--after-open'
            ) > -1
          ) {
            setTimeout(() => {
              const ele = document.querySelector(`.${overlayClassName}`);
              // eslint-disable-next-line no-unused-expressions
              ele?.classList.add(`${overlayClassName}--after-open`);
            });
          }
          return <div {...overlayElementProps}>{contentElement}</div>;
        },
        contentElement: (contentElementProps: any, children: Element) => {
          if (
            contentElementProps.className.indexOf(
              'ReactModal__Content--after-open'
            ) > -1
          ) {
            setTimeout(() => {
              const ele = document.querySelector(`.${modalClassName}`);
              // eslint-disable-next-line no-unused-expressions
              ele?.classList.add(`${modalClassName}--after-open`);
            });
          }
          return <div {...contentElementProps}>{children}</div>;
        },
      } as any)}
      {...props}
    />
  );
};

const StyledModal = styled(StyledModalAdapter).attrs(({ name = 'base' }) => ({
  overlayClassName: `${name}-modal-overlay`,
  modalClassName: `${name}-modal`,
}))`
  ${({ name = 'base' }) => css`
    & .${name}-modal-overlay {
      position: fixed;
      z-index: ${MODAL};
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      overflow-y: auto;
      padding: 32px 16px;
      background-color: rgba(0, 0, 0, 0);
    }

    & .${name}-modal-overlay--after-open {
      background-color: rgba(0, 0, 0, 0.7);
      transition: background-color 0.4s cubic-bezier(0.65, 0, 0.35, 1);
    }

    & .ReactModal__Overlay--before-close {
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 0.4s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
    }

    & .${name}-modal {
      position: relative;
      display: flex;
      flex-direction: column;

      margin: auto;
      outline: none;
      background-color: #fff;
      border-radius: 8px;
      opacity: 0;
    }

    & .${name}-modal--after-open {
      opacity: 1;
      transition: all 0.2s 0.4s cubic-bezier(0.65, 0, 0.35, 1);
    }

    & .ReactModal__Content--before-close {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s cubic-bezier(0.65, 0, 0.35, 1);
    }
  `}
`;

export default StyledModal;
