import React, { Component } from 'react';
import { bool, func, string, shape, number } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';

import { getOrder } from 'store/modules/records';
import { closeDialog } from 'store/modules/ui';
import { getCurrentCity } from 'store/modules/region/selectors';
import { actions as zendeskActions } from 'store/modules/zendesk';

import Dialog from 'components/Dialog';
import { noop } from 'utils/helpers';

class CancelOrderFailedDialog extends Component {
  static defaultProps = {
    t: noop,
    status: null,
    closeDialog: noop,
    startChat: noop,
  };

  static propTypes = {
    t: func,
    city: shape({
      zendeskChatEnabled: bool,
      csPhone: string,
    }).isRequired,
    orderId: string.isRequired,
    status: number,
    closeDialog: func,
    startChat: func,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.handleClose();
    }
  }

  handleOpenChat = () => {
    const { startChat, closeDialog } = this.props; // eslint-disable-line no-shadow
    startChat();
    closeDialog();
  };

  handleClose = () => {
    const { closeDialog } = this.props; // eslint-disable-line no-shadow
    closeDialog();
  };

  render() {
    const { t, city, closeDialog, ...props } = this.props; // eslint-disable-line no-shadow

    if (city.zendeskChatEnabled && process.env.REACT_APP_ENABLE_ZENDESK) {
      return (
        <Dialog
          title={t('RecordPanel.dialog_title_cancel_order_failed')}
          subtitle={t('RecordPanel.dialog_text_cancel_order_failed_live_chat')}
          primaryButtonText={t('RecordPanel.dialog_button_live_chat')}
          primaryAction={this.handleOpenChat}
          secondaryButtonText={t('RecordPanel.button_dismiss')}
          secondaryAction={this.handleClose}
          onRequestClose={this.handleClose}
          shouldCloseOnOverlayClick={false}
          {...props}
        />
      );
    }

    return (
      <Dialog
        title={t('RecordPanel.dialog_title_cancel_order_failed')}
        subtitle={
          <Trans
            i18nKey="RecordPanel.dialog_text_cancel_order_failed_hotline"
            values={{ hotline: city.csPhone }}
          >
            The cancellation period has expired. Please contact customer service
            to cancel. Our customer service number is
            <a href={`tel:${city.csPhone}`}>Hotline</a>.
          </Trans>
        }
        primaryButtonText={t('RecordPanel.dialog_button_ok')}
        primaryAction={this.handleClose}
        onRequestClose={this.handleClose}
        shouldCloseOnOverlayClick={false}
        {...props}
      />
    );
  }
}

const mapStateToProps = (state, { orderId }) => {
  const { status } = getOrder(state, orderId) || {};
  return {
    status,
    city: getCurrentCity(state),
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    closeDialog,
    startChat: zendeskActions.open,
  })
)(CancelOrderFailedDialog);
