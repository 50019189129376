import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, batch } from 'react-redux';
import { dismissMessage } from 'store/modules/message';
import {
  setChangeEmailStep,
  changeEmailSendSMSRequest,
  changeEmailVerify,
  RESET_CHANGE_EMAIL_STEPS,
} from 'interfaces/global/store/modules/settings/actions';
import {
  getChangeEmailStep,
  getUserEmail,
} from 'interfaces/global/store/modules/settings/selectors';

import {
  SpacedContainer,
  SubContent,
  ActionButton,
} from 'interfaces/global/containers/Settings/style';
import { useResponsiveMedia } from 'components/MediaQuery';
import { changeEmailStep } from 'utils/helpers';
import EmailChangeVerifySMS from './components/EmailChangeVerifySMS';
import EmailChangeForm from './components/EmailChangeForm';

const Email = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStep = useSelector(getChangeEmailStep);
  const currentEmail = useSelector(getUserEmail);
  const newEmail = useSelector(state => state.settings.emailSettings.newEmail);
  const { isMobile } = useResponsiveMedia();

  const resetSteps = useCallback(() => {
    batch(() => {
      dispatch({ type: RESET_CHANGE_EMAIL_STEPS });
      dispatch(dismissMessage('CHANGE_EMAIL_SMS'));
      dispatch(dismissMessage('CHANGE_EMAIL_VERIFY_PIN'));
      dispatch(setChangeEmailStep({ step: changeEmailStep['DEFAULT_STEP'] }));
    });
  }, [dispatch]);

  useEffect(() => {
    resetSteps();
  }, [resetSteps]);

  const handleSubmit = email => {
    dispatch(
      changeEmailSendSMSRequest({
        email,
      })
    );
  };

  const handleSendSMS = () => {
    handleSubmit(newEmail);
  };

  const handlePinChanged = smsCode => {
    dispatch(
      changeEmailVerify({
        code: smsCode,
      })
    );
  };
  switch (currentStep) {
    case changeEmailStep['DEFAULT_STEP']:
      return (
        <SpacedContainer>
          <div>{currentEmail}</div>
          <SubContent>
            <ActionButton
              variant={isMobile ? 'link' : 'primary'}
              solid
              onClick={() =>
                dispatch(
                  setChangeEmailStep({
                    step: changeEmailStep['CHANGE_EMAIL_STEP'],
                  })
                )
              }
            >
              {t('Settings.button_change_email')}
            </ActionButton>
          </SubContent>
        </SpacedContainer>
      );
    case changeEmailStep['VERIFY_SMS_CODE_STEP']:
      return (
        <EmailChangeVerifySMS
          handleSendSMS={handleSendSMS}
          handlePinChanged={handlePinChanged}
          onCancel={resetSteps}
        />
      );
    case changeEmailStep['CHANGE_EMAIL_STEP']:
      return <EmailChangeForm onSubmit={handleSubmit} onCancel={resetSteps} />;
    default:
      return null;
  }
};

export default Email;
