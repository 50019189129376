import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _merge from 'lodash/merge';
import { globalOrderStatusMap } from 'utils/helpers';
import {
  FETCH_RECORDS_SUCCESS,
  CANCEL_ORDER_SUCCESS,
  FETCH_CANCEL_REASONS_SUCCESS,
  REQUEST_LOGOUT,
  VOID_RECORDS,
  FETCH_RECORD_SUCCESS,
  FETCH_RECORD_REQUEST,
  FAVORITE_DRIVER_SUCCESS,
  BAN_DRIVER_SUCCESS,
  RATE_ORDER_SUCCESS,
} from './actions';
import orderReducer from './orders';

export const initState = {
  orders: {},
  order: {},
  routes: {},
  prices: {},
  drivers: {},
  ids: [],
  count: 0,
  appealStatus: 0,
  endPage: -1,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case FETCH_RECORD_SUCCESS: {
      const { order } = action;
      const { id, status, orderStatus } = order.order;

      return {
        ...state,
        drivers: driversReducer(state.drivers, action),
        orders: {
          ...state.orders,
          ...(state.orders[id] && {
            [id]: {
              ...state.orders[id],
              status: { id: orderStatus, parentID: status },
              driverId: order.driver.id,
            },
          }),
        },
        order,
      };
    }
    case FETCH_RECORDS_SUCCESS: {
      const { ids, lastId, newEndPage, prepend, orders: records } = action;
      const idList = prepend ? [...ids, ...state.ids] : [...state.ids, ...ids];

      const { orders, routes, prices, drivers } = records.reduce(
        (memo, { order, route, price, driver }) => ({
          orders: { ...memo.orders, [order.id]: order },
          routes: { ...memo.routes, [order.id]: route },
          prices: { ...memo.prices, [order.id]: price },
          drivers: { ...memo.drivers, ...(driver && { [driver.id]: driver }) },
        }),
        {}
      );

      return {
        ...state,
        endPage: newEndPage,
        ids: idList,
        lastRecordIDRef: lastId,
        count: idList.length,
        orders: { ...state.orders, ...orders },
        routes: { ...state.routes, ...routes },
        prices: { ...state.prices, ...prices },
        drivers: _merge(state.drivers, drivers),
      };
    }
    case CANCEL_ORDER_SUCCESS: {
      return {
        ...state,
        orders: orderReducer(state.orders, action),
        order: {
          ...state.order,
          order: {
            ...state.order.order,
            status: globalOrderStatusMap.CANCELLED.id,
          },
        },
      };
    }
    case FETCH_CANCEL_REASONS_SUCCESS: {
      return {
        ...state,
        cancelReasons: action.reason,
      };
    }
    case VOID_RECORDS:
    case REQUEST_LOGOUT:
      return initState;
    case FETCH_RECORD_REQUEST: {
      return {
        ...state,
        order: {},
      };
    }
    case RATE_ORDER_SUCCESS: {
      return {
        ...state,
        orders: orderReducer(state.orders, action),
        order: {
          ...state.order,
          order: {
            ...state.order.order,
            userRating: action.rating,
          },
        },
      };
    }
    case FAVORITE_DRIVER_SUCCESS:
    case BAN_DRIVER_SUCCESS:
      return {
        ...state,
        drivers: driversReducer(state.drivers, action),
      };

    default: {
      return state;
    }
  }
}

export function driversReducer(state = initState.drivers, action) {
  switch (action.type) {
    case FETCH_RECORD_SUCCESS: {
      const { driver } = action.order;
      const storedDriver = state[driver.id];

      return {
        ...state,
        ...(driver.id && {
          [driver.id]: storedDriver
            ? { ...storedDriver, ..._omitBy(driver, _isNil) }
            : driver,
        }),
      };
    }
    case FAVORITE_DRIVER_SUCCESS: {
      return {
        ...state,
        [action.driverId]: {
          ...state[action.driverId],
          favorited: true,
        },
      };
    }
    case BAN_DRIVER_SUCCESS: {
      return {
        ...state,
        [action.driverId]: {
          ...state[action.driverId],
          banned: true,
        },
      };
    }
    default: {
      return state;
    }
  }
}
