import { arrayOf, shape, func, string, number } from 'prop-types';
import { noop } from 'utils/helpers';
import React, { Component } from 'react';

import styled from 'styled-components';
import { RadioGroup } from '@lalamove/karang';
import { orange, lightBlack, lightGray } from 'styles/colors';

const Container = styled.div``;

const TabContainer = styled.div`
  padding: 0px 24px;
  border: 1px solid ${({ checked }) => (checked ? orange : lightGray)};
  margin: 12px 0;
  border-radius: 4px;
  background: ${({ checked }) => (checked ? 'rgba(250, 206, 188, 0.2)' : '')};
`;
const RadioGroupContainer = styled(RadioGroup)`
  padding-bottom: 16px;
`;
const Tab = styled.div`
  padding: 16px 0;
  cursor: pointer;
  color: ${({ checked }) => (checked ? orange : '#282828')};
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
`;
const RadioItem = styled.div`
  padding: 12px 0;
  color: #303030;
  font-size: 14px;
  line-height: 16px;

  > label {
    width: 100%;

    > :last-child {
      width: calc(100% - 16px - 0.5em);
    }
  }
`;

export const RadioLabel = styled.span`
  color: ${({ checked }) => (checked ? orange : lightBlack)};
`;

class CancelReasons extends Component {
  static defaultProps = {
    onChange: noop,
    selectedText: '',
  };

  static propTypes = {
    cancelReasons: arrayOf(
      shape({
        id: number,
        name: string,
        sub_reason: arrayOf(shape({ id: number, sub_name: string })),
      })
    ).isRequired,
    onChange: func,
    selectedText: string,
  };

  state = {
    currentTabId: '',
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    // Select the first reason by default
    if (!prevProps.cancelReasons.length && this.props.cancelReasons.length) {
      this.clickTab(this.props.cancelReasons[0].id);
    }
  }

  clickTab = currentTabId => {
    this.setState({ currentTabId });
  };

  render() {
    const { cancelReasons, onChange, selectedText } = this.props;
    const { currentTabId } = this.state;

    return (
      <Container data-cy="panel-cancel-reasons">
        {cancelReasons.map(item => (
          <TabContainer key={item.id} checked={item.id === currentTabId}>
            <Tab
              onClick={() => {
                this.clickTab(item.id);
              }}
            >
              {item.name}
            </Tab>
            {item.id === currentTabId && (
              <RadioGroupContainer
                name={`reasonsList${item.id}`}
                onChange={onChange}
                value={selectedText}
                defaultValue={selectedText}
              >
                {Radio =>
                  item.sub_reason.map(subItem => (
                    <RadioItem key={subItem.id}>
                      <Radio key={subItem.id} value={subItem.sub_name}>
                        <RadioLabel checked={subItem.sub_name === selectedText}>
                          {subItem.sub_name}
                        </RadioLabel>
                      </Radio>
                    </RadioItem>
                  ))
                }
              </RadioGroupContainer>
            )}
          </TabContainer>
        ))}
      </Container>
    );
  }
}

export default CancelReasons;
