import React, { Fragment } from 'react';
import { func, arrayOf, shape, string, bool, instanceOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@lalamove/karang';
import {
  message as MessageIcon,
  email as EmailIcon,
  tel as TelIcon,
  search as SearchIcon,
  filter as InsuranceIcon,
} from '@lalamove/karang/dist/components/Icon/icons';

import { actions } from 'store/modules/zendesk';
import { offWhite } from 'styles/colors';
import { noop } from 'utils/helpers';
import Heading from 'components/SliderPanel/Heading';
import Footer from 'components/SliderPanelFooter';
import {
  getCurrentCity,
  getCurrentCountry,
  getCurrentLocale,
} from 'store/modules/region/selectors';
import { actions as genesysAction } from 'store/modules/genesys';
import Section from './components/Section';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  height: auto;
  padding: 0 1.5em;
`;

const StyledSection = styled(Section)`
  margin: 1em -1.5em;
  & > div:first-child {
    padding-left: 1.5em;
  }
  & > div:last-child {
    padding-right: 1.5em;
    border-bottom: 1px solid ${offWhite};
  }
  &:last-child > div:last-child {
    border-bottom: 0;
  }
  & ul,
  & p {
    margin: 0 0 1em 0;
    line-height: 1.5;
  }
  & ul {
    padding: 0;
    list-style: none;
  }
`;

const ICON_SIZE = 24;

const Help = ({
  t,
  onClose,
  city,
  country,
  locale,
  isScriptLoaded,
  openWidget,
  closeWidget,
  configGenesys,
  genesysEnable,
  zendeskEnable,
  startChat,
  isOpen,
}) => {
  const workingHours =
    city.csWorkingHours && city.csWorkingHours.find(item => item.id === locale)
      ? city.csWorkingHours.find(item => item.id === locale).value
      : '';
  const priceUrl =
    city.priceUrl && city.priceUrl.find(item => item.id === locale)
      ? city.priceUrl.find(item => item.id === locale).value
      : '';
  const insuranceForm =
    city.insuranceForm && city.insuranceForm.find(item => item.id === locale)
      ? city.insuranceForm.find(item => item.id === locale).value
      : '';
  const insurancePolicy =
    country.insurancePolicy &&
    country.insurancePolicy.find(item => item.id === locale)
      ? country.insurancePolicy.find(item => item.id === locale).value
      : '';

  const getCookie = name => {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(';');

    // Loop through the array elements
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split('=');

      /* Removing whitespace at the beginning of the cookie name
          and compare it with the given string */
      if (name === cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if not found
    return null;
  };

  const registerWebChatOpen = () => {
    if (
      !getCookie('_genesys.widgets.webchat.state.purecloud-v2-sockets.JWtoken')
    ) {
      window.customPlugin.command('WebChat.open', configGenesys).done(() => {
        openWidget();
      });
    } else {
      openWidget();
    }
  };

  const openWebChat = () => {
    if (!isScriptLoaded) {
      window.widgetLoad = window.CXBus.loadPlugin('widgets-core');
      window.widgetLoad.done(() => {
        window.customPlugin = window.CXBus.registerPlugin('Custom');
        registerWebChatOpen();

        // Web chat Service closed
        window.customPlugin.subscribe('WebChat.closed', e => {
          closeWidget();
        });
      });
    } else {
      registerWebChatOpen();
    }
  };

  const renderChatSection = () => {
    const showChatWidget = genesysEnable || zendeskEnable;
    const disabled = genesysEnable ? isOpen : false;
    return (
      showChatWidget && (
        <StyledSection
          title={t('Help.legend_live_chat')}
          icon={<MessageIcon size={ICON_SIZE} />}
        >
          <p>{t('Help.msg_live_chat')}</p>
          <p>
            <Button
              disabled={disabled}
              variant="primary"
              onClick={genesysEnable ? openWebChat : startChat}
              solid
            >
              {t('Help.button_start_chat')}
            </Button>
          </p>
        </StyledSection>
      )
    );
  };
  return (
    <Container>
      <Content>
        <Heading title={t('Help.heading_help_center')} />
        {renderChatSection()}
        <StyledSection
          title={t('Help.legend_phone')}
          icon={<TelIcon size={ICON_SIZE} />}
        >
          <ul>
            <li>
              <a name="phone" href={`tel:${city.csPhone}`}>
                {city.csPhone}
              </a>
            </li>
            <li>
              {workingHours.split('\\n').map(text => (
                <Fragment key={text}>
                  {text}
                  <br />
                </Fragment>
              ))}
            </li>
          </ul>
        </StyledSection>
        <StyledSection
          title={t('Help.legend_email')}
          icon={<EmailIcon size={ICON_SIZE} />}
        >
          <ul>
            <li>
              <a name="email" href={`mailto:${city.csEmail}`}>
                {city.csEmail}
              </a>
            </li>
          </ul>
        </StyledSection>
        {(city.insurancePhone || insuranceForm || insurancePolicy) && (
          <StyledSection
            title={t('Help.legend_insurance')}
            icon={<InsuranceIcon size={ICON_SIZE} />}
          >
            <ul>
              {city.insurancePhone && (
                <li>
                  <Trans
                    i18nKey="Help.msg_insurance_hotline"
                    values={{ hotline: city.insurancePhone }}
                  >
                    Hotline:
                    <a
                      name="insurance_hotline"
                      href={`tel:${city.insurancePhone}`}
                    >
                      Hotline
                    </a>
                  </Trans>
                </li>
              )}
              {insuranceForm && (
                <li>
                  <a
                    name="insurance_form"
                    href={insuranceForm}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Help.link_view_claim_form')}
                  </a>
                </li>
              )}
              {insurancePolicy && (
                <li>
                  <a
                    name="insurance_policy"
                    href={insurancePolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Help.link_insurance_policy')}
                  </a>
                </li>
              )}
            </ul>
          </StyledSection>
        )}
        <StyledSection
          title={t('Help.legend_resources')}
          icon={<SearchIcon size={ICON_SIZE} />}
        >
          <ul>
            <li>
              <a
                name="pricing"
                href={priceUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Help.link_pricing')}
              </a>
            </li>
            <li>
              <a
                name="faq"
                href={t('url:FAQ_URL')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Help.link_faq')}
              </a>
            </li>
          </ul>
        </StyledSection>
      </Content>
      <Footer closeAction={onClose} closeButtonText={t('Help.button_close')} />
    </Container>
  );
};

Help.defaultProps = {
  onClose: noop,
  startChat: noop,
};

Help.propTypes = {
  t: func.isRequired,
  onClose: func,
  locale: string.isRequired,
  city: shape({
    csPhone: string,
    priceUrl: arrayOf(shape({ id: string, value: string })),
  }).isRequired,
  country: shape({}).isRequired,
  isScriptLoaded: bool.isRequired,
  configGenesys: instanceOf(Object).isRequired,
  genesysEnable: bool.isRequired,
  zendeskEnable: bool.isRequired,
  closeWidget: func.isRequired,
  openWidget: func.isRequired,
  startChat: func,
  isOpen: bool.isRequired,
};

const mapStateToProps = state => ({
  city: getCurrentCity(state),
  country: getCurrentCountry(state),
  locale: getCurrentLocale(state),
  isScriptLoaded: state.genesys.isScriptLoaded,
  configGenesys: state.genesys.config,
  genesysEnable: state.genesys.genesysEnable,
  zendeskEnable: state.genesys.zendeskEnable,
  isOpen: state.genesys.isOpen,
});
export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    startChat: actions.open,
    openWidget: genesysAction.openWidget,
    closeWidget: genesysAction.closeWidget,
  })
)(Help);
