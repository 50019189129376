import {
  call,
  select,
  takeEvery,
  put,
  debounce,
  fork,
  race,
  take,
  delay,
  takeLatest,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { sessionService } from 'redux-react-session';

import { UApiError } from 'utils/helpers';
import {
  ENABLE_ACTIVE_FETCH_PROFILE,
  DISABLE_ACTIVE_FETCH_PROFILE,
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  SET_PROFILE_TYPE_REQUEST,
  SET_PROFILE_TYPE_SUCCESS,
  SET_PROFILE_TYPE_FAILURE,
  UPDATE_PROFILE_FLAG_REQUEST,
  UPDATE_PROFILE_FLAG_SUCCESS,
  UPDATE_PROFILE_FLAG_FAILURE,
  PROMPT_ADD_BUSINESS_PROFILE,
  PROMPT_REMOVE_BUSINESS_PROFILE,
  ADD_BUSINESS_PROFILE,
  REMOVE_BUSINESS_PROFILE,
  addBusinessProfile,
  promptAddBusinessProfile,
  promptRemoveBusinessProfile,
  setProfileType,
  PROFILE_SWITCHED,
} from 'interfaces/global/store/modules/auth/actions';
import { User, ProfileType } from 'interfaces/global/store/modules/auth/types';
import { mergeProfile } from 'interfaces/global/store/modules/auth/helpers';
import { getUser } from 'interfaces/global/store/modules/auth/selectors';
import { getUserInfo, updateUserFlag } from 'api/uAPI';
import { openDialog } from 'store/modules/ui';
import { ERROR_BUSINESS_PROFILE_REMOVED_RET } from 'utils/apiHelper';

const ACTIVE_FETCH_PROFILE_INTERVAL = 3 * 60 * 1000; // 3 mins

export function* updateUserProfile({
  profileType,
  isEp,
}: {
  profileType?: ProfileType;
  isEp?: ProfileType;
} = {}): Generator {
  const user = (yield select(getUser)) as User;
  const { access_token: accessToken } = user;
  const newProfileType =
    profileType !== undefined ? profileType : user.profile_type;
  const newIsEp = isEp !== undefined ? isEp : user.is_ep;

  // save is_ep and profile_type first to prevent race condition that
  // sessionService.saveUser is called with out-dated is_ep and profile_type
  // in other running sagas
  if (newProfileType !== user.profile_type || newIsEp !== user.is_ep) {
    yield call([sessionService, 'saveUser'], {
      ...user,
      is_ep: newIsEp,
      profile_type: newProfileType,
    });
  }

  const newProfile = yield call(getUserInfo, accessToken, newProfileType);
  const mergedProfile = mergeProfile(user.profile, newProfile);

  yield call([sessionService, 'saveUser'], {
    ...((yield select(getUser)) as User),
    profile: mergedProfile,
  });
  return mergedProfile;
}

export function* onFetchProfile(): Generator {
  try {
    const profile = yield call(updateUserProfile);
    yield put({ type: FETCH_PROFILE_SUCCESS, profile });
  } catch (error) {
    let errorMessage = '';
    if (error instanceof UApiError) {
      if (error.errorCode === ERROR_BUSINESS_PROFILE_REMOVED_RET) {
        yield put(promptRemoveBusinessProfile());
      }
      errorMessage = error.message;
    }
    yield put({
      type: FETCH_PROFILE_FAILURE,
      meta: {
        type: 'error',
        message: errorMessage || 'Common.internal_error',
      },
    });
  }
}

export function* onSetProfileType({
  profileType,
  isEp,
}: {
  type: typeof SET_PROFILE_TYPE_REQUEST;
  profileType?: ProfileType;
  isEp?: ProfileType;
}): Generator {
  try {
    yield call(updateUserProfile, { profileType, isEp });
    yield put({ type: SET_PROFILE_TYPE_SUCCESS, profileType });
  } catch (error) {
    let errorMessage = '';
    if (error instanceof UApiError) {
      if (error.errorCode === ERROR_BUSINESS_PROFILE_REMOVED_RET) {
        yield put(promptRemoveBusinessProfile());
      }
      errorMessage = error.message;
    }
    yield put({
      type: SET_PROFILE_TYPE_FAILURE,
      meta: {
        type: 'error',
        message: errorMessage || 'Common.internal_error',
      },
    });
  }
}

export function* onUpdateProfileFlag({
  key,
}: {
  type: typeof UPDATE_PROFILE_FLAG_REQUEST;
  key: string;
}): Generator {
  try {
    yield call(updateUserFlag, key);
    yield call(updateUserProfile);
    yield put({ type: UPDATE_PROFILE_FLAG_SUCCESS, key });
  } catch (error) {
    let errorMessage = '';
    if (error instanceof UApiError) {
      if (error.errorCode === ERROR_BUSINESS_PROFILE_REMOVED_RET) {
        yield put(promptRemoveBusinessProfile());
      }
      errorMessage = error.message;
    }
    yield put({
      type: UPDATE_PROFILE_FLAG_FAILURE,
      meta: {
        type: 'error',
        message: errorMessage || 'Common.internal_error',
      },
    });
  }
}

export function* onPromptRemoveBusinessProfile(): Generator {
  yield put(openDialog('PROFILE_BUSINESS_ROLE_REMOVED'));
  yield take(REMOVE_BUSINESS_PROFILE);
  const user = (yield select(getUser)) as User;
  const isSwitch = user.profile_type !== ProfileType.PERSONAL;
  yield put(
    setProfileType({
      profileType: ProfileType.PERSONAL,
      isEp: ProfileType.PERSONAL,
    })
  );
  if (isSwitch) {
    yield put({ type: PROFILE_SWITCHED });
    yield put(replace('/'));
  }
}

export function* onPromptAddBusinessProfile(): Generator {
  yield put(openDialog('PROFILE_BUSINESS_ROLE_ADDED'));
  const { isSwitch } = (yield take(
    ADD_BUSINESS_PROFILE
  ) as unknown) as ReturnType<typeof addBusinessProfile>;
  if (isSwitch) {
    yield put(
      setProfileType({
        profileType: ProfileType.BUSINESS,
        isEp: ProfileType.BUSINESS,
      })
    );
    yield put({ type: PROFILE_SWITCHED });
    yield put(replace('/'));
  } else {
    yield put(
      setProfileType({
        isEp: ProfileType.BUSINESS,
      })
    );
  }
}

export function* activeFetchProfileTask(): Generator {
  while (yield take(ENABLE_ACTIVE_FETCH_PROFILE)) {
    yield race([call(activeFetchProfile), take(DISABLE_ACTIVE_FETCH_PROFILE)]);
  }
}

export function* fetchBusinessProfile(): Generator {
  const user = (yield select(getUser)) as User;
  const {
    access_token: accessToken,
    is_ep: isEp,
    profile_type: profileType,
  } = user;

  if (profileType === ProfileType.BUSINESS) {
    yield put({ type: FETCH_PROFILE_REQUEST });
    yield take([FETCH_PROFILE_SUCCESS, FETCH_PROFILE_FAILURE]);
    return;
  }

  try {
    const businessProfile = yield call(
      getUserInfo,
      accessToken,
      ProfileType.BUSINESS
    );
    if (isEp === ProfileType.PERSONAL && businessProfile) {
      yield put(promptAddBusinessProfile());
    }
  } catch (error) {
    if (
      isEp === ProfileType.BUSINESS &&
      error.errorCode === ERROR_BUSINESS_PROFILE_REMOVED_RET
    ) {
      yield put(promptRemoveBusinessProfile());
    }
  }
}

export function* activeFetchProfile(): Generator {
  while (true) {
    yield call(fetchBusinessProfile);
    yield delay(ACTIVE_FETCH_PROFILE_INTERVAL);
  }
}

export function* profileSaga(): Generator {
  yield debounce(500, [FETCH_PROFILE_REQUEST], onFetchProfile);
  yield takeEvery(SET_PROFILE_TYPE_REQUEST, onSetProfileType);
  yield takeEvery(UPDATE_PROFILE_FLAG_REQUEST, onUpdateProfileFlag);
  yield takeLatest(PROMPT_ADD_BUSINESS_PROFILE, onPromptAddBusinessProfile);
  yield takeLatest(
    PROMPT_REMOVE_BUSINESS_PROFILE,
    onPromptRemoveBusinessProfile
  );
  yield fork(activeFetchProfileTask);
}
