import moment from 'moment';

export const MAX_ROWS = 10;
export const REFRESH_RECORDS_LIST_INTERVAL = 30 * 1000; // 30 seconds
export const REFRESH_ENABLED_PAGE_RANGE = 10;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_MIN_DATE = moment()
  .subtract(30, 'days')
  .format(DATE_FORMAT);
export const MIN_DATE = moment().subtract(90, 'days').format(DATE_FORMAT);
export const MAX_DATE = moment().add(30, 'days').format(DATE_FORMAT);

export const MOBILE_RECORDS_PAGE_MAX_RECORDS = 100;
