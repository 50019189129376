import styled from 'styled-components';
import { Form as RawForm, Heading } from '@lalamove/karang';
import { success as SuccessIcon } from '@lalamove/karang/dist/components/Icon/icons';

import { silver, green, gray } from 'styles/colors';
import { fontWeight, fontSize } from 'styles/fonts';
import { GROUND } from 'styles/zIndex';

const MAX_WIDTH = 48;
const MIN_WIDTH = 32;
const ICON_SIZE = 24;

export const Wrapper = styled.div`
  width: 100%;
  min-width: ${MIN_WIDTH}rem;
  max-width: ${MAX_WIDTH}rem;
  padding-top: 1rem;
  padding-bottom: 4rem;
  margin-right: auto;
  margin-left: auto;
`;

export const Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
  margin: 1em;
  @media (min-width: ${MIN_WIDTH}rem) {
    margin: 1em 0;
  }
`;

export const Table = styled.div`
  display: table;
  width: 100%;
`;

export const FormItem = styled(RawForm.Item)`
  display: table-row;
  width: 100%;
`;

export const LabelCol = styled.div`
  display: table-cell;
  width: 30%;
  padding-right: 1em;
  vertical-align: baseline;

  & > label {
    color: ${silver};
    font-size: ${fontSize.small};
    font-weight: ${fontWeight.bold};
    text-transform: uppercase;
  }
`;

export const Content = styled.div`
  position: relative;
  display: table-cell;
  width: 70%;
  padding: 1em 0;
  vertical-align: baseline;
`;

export const ToggleInfo = styled.div`
  margin-top: 1em;
  color: ${gray};
`;

export const Form = styled(RawForm)`
  display: block;
  width: 100%;
`;

export const CheckIcon = styled(SuccessIcon).attrs(() => ({
  size: ICON_SIZE,
  color: green,
}))`
  ${/* sc-selector */ Content} & {
    position: absolute;
    z-index: ${GROUND + 1};
    top: 50%;
    right: 1em;
    margin-top: ${-(ICON_SIZE / 2)}px;
  }
`;

export const PaddedHeading = styled(Heading)`
  && {
    margin-left: 0.5rem;
  }
`;

export const SpacedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
