import { apply, call, put, takeEvery, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  getUserEmailOrMobile,
  getPendingSmsUserByUuid,
  setNewPasswordBySms,
  setNewPasswordByEmail,
} from 'api/authAPI';
import validator from 'utils/validator';

// action-types
import {
  VERIFY_PIN_REQUEST,
  VERIFY_PIN_SUCCESS,
  VERIFY_PIN_FAILURE,
  RESEND_PIN_REQUEST,
  RESEND_PIN_SUCCESS,
  RESEND_PIN_FAILURE,
  RESEND_PIN_RELEASE,
  RESET_PW_REQUEST,
  RESET_PW_SUCCESS,
  RESET_PW_FAILURE,
} from './actions';

// state
export const initState = {
  username: '',
  uuid: '',
  smsCode: '',
  isWaiting: false,
  status: '',
};

// reducer
export default function forgotPasswordReducer(state = initState, action) {
  switch (action.type) {
    case RESEND_PIN_SUCCESS: {
      const { username, uuid, status } = action;
      return {
        ...state,
        isWaiting: true,
        username,
        uuid,
        status,
      };
    }
    case RESEND_PIN_RELEASE:
      return {
        ...state,
        isWaiting: false,
      };
    case RESET_PW_SUCCESS: {
      const { status } = action;
      return {
        ...state,
        status,
      };
    }
    case VERIFY_PIN_SUCCESS: {
      const { status, smsCode } = action;
      return {
        ...state,
        status,
        smsCode,
      };
    }
    case RESET_PW_FAILURE:
      return {
        ...state,
        status: '',
      };
    case '@@router/LOCATION_CHANGE': {
      const { payload } = action;
      if (payload.location.pathname === '/login') return initState;
      return state;
    }
    default:
      return state;
  }
}

// side effects
export function* handleResendPin({ username, areaCode, lang, waitDuration }) {
  try {
    const { valid, message } = yield apply(validator, validator.email, [
      username,
    ]);

    if (!/^\d+$/.test(username) && !valid) {
      throw new Error(message);
    }

    const { uuid, code } = yield call(
      getUserEmailOrMobile,
      username,
      areaCode,
      lang
    );
    yield put({
      type: RESEND_PIN_SUCCESS,
      username,
      uuid,
      status: code,
    });
    yield delay(waitDuration);
    yield put({ type: RESEND_PIN_RELEASE });
  } catch (error) {
    yield put({
      type: RESEND_PIN_FAILURE,
      meta: {
        type: 'error',
        message: error.message,
        data: 'llm.getUserEmailOrMobile',
      },
    });
  }
}

export function* handleSubmitPin({ smsCode, uuid, username, areaCode }) {
  try {
    const { code } = yield call(
      getPendingSmsUserByUuid,
      uuid,
      smsCode,
      username,
      areaCode
    );
    yield put({
      type: VERIFY_PIN_SUCCESS,
      status: code,
      smsCode,
    });
    yield put(push('/reset-password'));
  } catch (error) {
    yield put({
      type: VERIFY_PIN_FAILURE,
      meta: {
        type: 'error',
        message: error.message,
        data: 'llm.getPendingSmsUserByUuid',
      },
    });
  }
}

export function* handleResetPw({
  smsCode,
  uuid,
  username,
  password,
  areaCode,
  emailCode,
}) {
  try {
    let response;
    if (emailCode) {
      response = yield call(setNewPasswordByEmail, emailCode, password);
    } else {
      response = yield call(
        setNewPasswordBySms,
        uuid,
        smsCode,
        username,
        password,
        areaCode
      );
    }
    yield put({ type: RESET_PW_SUCCESS, status: response.code });
  } catch (error) {
    yield put({
      type: RESET_PW_FAILURE,
      meta: {
        type: 'error',
        message: error.message,
        data: 'llm.setNewPasswordByEmail',
      },
    });
  }
}

export function* forgotPasswordSaga() {
  yield takeEvery(VERIFY_PIN_REQUEST, handleSubmitPin);
  yield takeEvery(RESEND_PIN_REQUEST, handleResendPin);
  yield takeEvery(RESET_PW_REQUEST, handleResetPw);
}
