import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { SearchInput } from '@lalamove/karang';
import { maxPageWidth } from 'styles/dimensions';
import styled from 'styled-components';
import { noop } from 'utils/helpers';

import { SearchForm } from '../../style';

const ArchivedOrdersLink = styled.a`
  margin-right: 2em;

  @media (min-width: ${maxPageWidth}rem) {
    margin-right: 0;
  }
`;

const TopControls = ({
  search: { placeholder, defaultValue = '', onSubmit = noop },
  archivedOrders: { text = '', url = '', show = false },
}) => (
  <>
    <SearchForm onSubmit={onSubmit}>
      <SearchInput defaultValue={defaultValue} placeholder={placeholder} />
    </SearchForm>
    {show && (
      <ArchivedOrdersLink
        name="archived_orders"
        target="_blank"
        href={url}
        rel="noopener noreferrer"
      >
        {text}
      </ArchivedOrdersLink>
    )}
  </>
);

export default TopControls;

TopControls.propTypes = {
  search: shape({
    defaultValue: string,
    placeholder: string.isRequired,
    onSubmit: func,
  }).isRequired,
  archivedOrders: shape({
    show: bool,
    url: string,
    text: string,
  }).isRequired,
};
