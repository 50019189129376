import _some from 'lodash/some';
import { makeWaypointDescription } from 'interfaces/global/store/modules/routing/helpers';
import { FETCH_LOCATION_SEARCH_HISTORY_SUCCESS } from './actions';

export const INIT_STATE = {
  placing: {},
};

const mapHistory = historyList =>
  historyList.map(item => ({
    name: item.name,
    address: item.addressStr,
    description: makeWaypointDescription({
      name: item.name,
      address: item.addressStr,
    }),
    lat: item.lat,
    lng: item.lng,
    placeId: item.placeId,
    contact: _some(item.contactPerson) ? item.contactPerson : undefined,
    addressDetails: _some(item.addressDetails)
      ? item.addressDetails
      : undefined,
    cityId: item.cityId,
  }));

const onFetchLocationSearchHistorySuccess = (state, { history }) => ({
  ...state,
  placing: {
    sending: mapHistory(history[0]),
    receiving: mapHistory(history[1]),
  },
});

export default function reducer(state = INIT_STATE, act) {
  switch (act.type) {
    case FETCH_LOCATION_SEARCH_HISTORY_SUCCESS:
      return onFetchLocationSearchHistorySuccess(state, act);
    default:
      return state;
  }
}
