import React from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { createLoadingSelector } from 'store/modules/loading';
import { setMarketingOptIn } from 'interfaces/global/store/modules/settings/actions';

import Dialog from 'components/Dialog';
import { noop } from 'utils/helpers';

const MarketingOptInDialog = ({
  t,
  isSubmitting,
  setMarketingOptIn: set,
  ...props
}) => (
  <Dialog
    title={t('PlaceOrder.dialog_title_marketing_opt_in')}
    subtitle={t('PlaceOrder.dialog_text_marketing_opt_in')}
    primaryButtonText={t('PlaceOrder.dialog_button_agree')}
    primaryAction={() => set(true)}
    disablePrimaryButton={isSubmitting}
    isLoadingPrimaryButton={isSubmitting}
    secondaryButtonText={t('PlaceOrder.dialog_button_disagree')}
    secondaryAction={() => set(false)}
    disableSecondaryButton={isSubmitting}
    isLoadingSecondaryButton={isSubmitting}
    shouldCloseOnOverlayClick={false}
    {...props}
  />
);

MarketingOptInDialog.defaultProps = {
  t: noop,
  isSubmitting: false,
  setMarketingOptIn: noop,
};

MarketingOptInDialog.propTypes = {
  t: func,
  isSubmitting: bool,
  setMarketingOptIn: func,
};

const loadingSelector = createLoadingSelector(['SET_MARKETING_OPT_IN']);

const mapStateToProps = state => ({
  isSubmitting: loadingSelector(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    setMarketingOptIn,
  })
)(MarketingOptInDialog);
