import React from 'react';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';

import { noop } from 'utils/helpers';
import EmptyState from 'components/EmptyState';
import notFoundSVG from 'assets/svg/notfound_404.svg';

export const NotFound = ({ t, historyReplace }) => {
  const image = (
    <img src={notFoundSVG} alt={t('NotFound.title')} width={300} height={300} />
  );
  return (
    <EmptyState
      image={image}
      title={t('NotFound.title')}
      text={t('NotFound.text')}
      actionText={t('NotFound.button_go_home')}
      action={() => historyReplace('/')}
    />
  );
};

NotFound.defaultProps = {
  t: noop,
};

NotFound.propTypes = {
  t: func,
  historyReplace: func.isRequired,
};

export default withTranslation()(
  connect(null, { historyReplace: replace })(NotFound)
);
