import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { arrayOf, func, string, shape, number } from 'prop-types';
import { getWaypoints } from 'interfaces/global/store/modules/routing/selectors';
import {
  rearrangeRoute,
  newWaypt,
  removeWaypt,
} from 'interfaces/global/store/modules/routing/actions';
import { DRAFT_ORDER_ID } from 'interfaces/global/config';
import { getPlacingLocationHistory } from 'interfaces/global/store/modules/searchHistory/selectors';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { LocationShape } from './propTypes';
import StopList from './components/StopList';

export class Editor extends Component {
  static defaultProps = {
    orderId: DRAFT_ORDER_ID,
    placingHistory: {},
  };

  static propTypes = {
    orderId: string,
    rearrangeRoute: func.isRequired,
    newWaypt: func.isRequired,
    removeWaypt: func.isRequired,
    waypoints: arrayOf(LocationShape).isRequired,
    placingHistory: shape({
      receiving: arrayOf(
        shape({
          id: string,
          description: string,
          placeId: string,
          lat: number,
          lng: number,
        })
      ),
      sending: arrayOf(
        shape({
          id: string,
          description: string,
          placeId: string,
          lat: number,
          lng: number,
        })
      ),
    }),
    track: func.isRequired,
  };

  handleReorder = (from, to) => {
    const { orderId } = this.props;
    this.props.rearrangeRoute(
      {
        sourceIndex: from,
        destinationIndex: to,
      },
      orderId
    );
  };

  handleNewWaypt = () => {
    // eslint-disable-next-line no-shadow
    const { orderId, track } = this.props;
    this.props.newWaypt(orderId);

    track('add_destination_tapped');
  };

  handleRemoveWaypt = id => {
    const { orderId } = this.props;
    this.props.removeWaypt(id, orderId);
  };

  render() {
    const { waypoints, orderId, placingHistory } = this.props;

    return (
      <StopList
        isEditMode={orderId !== DRAFT_ORDER_ID}
        history={placingHistory}
        waypoints={waypoints}
        onRemove={this.handleRemoveWaypt}
        onReorder={this.handleReorder}
        onCreate={this.handleNewWaypt}
      />
    );
  }
}

const mapState = (state, ownProps) => ({
  waypoints: getWaypoints(state, ownProps.orderId),
  placingHistory: getPlacingLocationHistory(state),
});

export default compose(
  connect(mapState, {
    rearrangeRoute,
    newWaypt,
    removeWaypt,
    track,
  })
)(Editor);
