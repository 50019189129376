import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import { bool, func, shape } from 'prop-types';
import { Tab } from '@lalamove/karang';

import {
  SEGMENT_CLICK_WALLET,
  SEGMENT_CLICK_CREDIT_HISTORY,
  SEGMENT_CLICK_REWARDS_HISTORY,
  SEGMENT_CLICK_CASH_HISTORY,
  SEGMENT_CLICK_STATEMENT,
} from 'store/modules/wallet/tracking';
import { getUser } from 'store/modules/auth/selectors';
import { getCurrentCountryCode } from 'store/modules/region/selectors';
import Transaction from 'models/Transaction';
import { noop } from 'utils/helpers';
import Segment from 'utils/segment';

import { STATEMENT } from './config';
import { TabGroup } from './style';
import TransactionTable from './components/TransactionTable';
import Statement from './components/Statement';

const {
  METHOD_CREDIT: CREDIT,
  METHOD_REWARD: REWARD,
  METHOD_CASH: CASH,
} = Transaction;

class Wallet extends Component {
  static defaultProps = {
    t: noop,
  };

  static propTypes = {
    location: shape({}).isRequired,
    t: func,
    historyReplace: func.isRequired,
    shouldShowStatement: bool.isRequired,
  };

  componentDidMount() {
    // tracking
    Segment.createTrack(SEGMENT_CLICK_WALLET);
  }

  handleTabChange = method => {
    const { historyReplace } = this.props;
    historyReplace({
      pathname: `/wallet/${method}`,
    });

    // tracking
    const eventsMap = {
      [CREDIT]: SEGMENT_CLICK_CREDIT_HISTORY,
      [REWARD]: SEGMENT_CLICK_REWARDS_HISTORY,
      [CASH]: SEGMENT_CLICK_CASH_HISTORY,
      [STATEMENT]: SEGMENT_CLICK_STATEMENT,
    };
    Segment.createTrack(eventsMap[method]);
  };

  renderTabs = () => {
    const { location, shouldShowStatement, t } = this.props;
    const [, , method] = location.pathname.split('/');
    return (
      <TabGroup
        activeTab={method}
        onTabChange={this.handleTabChange}
        variant="rounded"
      >
        <Tab name={CREDIT}>{t('Wallet.tab_credit')}</Tab>
        <Tab name={REWARD}>{t('Wallet.tab_reward')}</Tab>
        <Tab name={CASH}>{t('Wallet.tab_cash')}</Tab>
        {shouldShowStatement && (
          <Tab name={STATEMENT}>{t('Wallet.tab_statement')}</Tab>
        )}
      </TabGroup>
    );
  };

  render() {
    return (
      <Switch>
        <Route
          path={`/wallet/${CREDIT}`}
          render={() => (
            <TransactionTable method={CREDIT} Tabs={this.renderTabs} />
          )}
        />
        <Route
          path={`/wallet/${REWARD}`}
          render={() => (
            <TransactionTable method={REWARD} Tabs={this.renderTabs} />
          )}
        />
        <Route
          path={`/wallet/${CASH}`}
          render={() => (
            <TransactionTable method={CASH} Tabs={this.renderTabs} />
          )}
        />
        {this.props.shouldShowStatement && (
          <Route
            path={`/wallet/${STATEMENT}`}
            render={() => <Statement Tabs={this.renderTabs} />}
          />
        )}
        <Redirect from="/wallet" to={`/wallet/${CREDIT}`} />
      </Switch>
    );
  }
}

const mapState = state => {
  const { corporate_id: corporateId, corporation } = getUser(state);
  return {
    shouldShowStatement:
      corporateId &&
      corporation &&
      corporation.country &&
      corporation.country.toUpperCase() === getCurrentCountryCode(state),
  };
};

export default compose(
  withTranslation(),
  connect(mapState, { historyReplace: replace })
)(Wallet);
