import {
  REGISTER_SUCCESS,
  VERIFY_SUCCESS,
  RESEND_SUCCESS,
  RESEND_LOCKING,
  RESEND_RELEASE,
  RESEND_PIN_SUCCESS,
  RESEND_PIN_RELEASE,
  RESET_PW_SUCCESS,
  RESET_PW_FAILURE,
  VERIFY_PIN_SUCCESS,
  FB_LOGIN_CANCEL,
  FB_LOGIN_SUCCESS,
  FB_USER_DATA_RECEIVED,
  GOOGLE_LOGIN_PROFILE_RECEIVED,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILURE,
  STORE_SIGNED_PROFILE,
  SET_CHANGE_PW_STEP,
  RESET_CHANGE_PW_STEPS,
} from './actions';

import { BEGINNING_STEP } from './helpers';

export const registerInitState = {
  // for social media login (Facebook)
  firstName: '',
  lastName: '',
  email: '',
  socialLoginId: null,
  socialLoginSource: null,
};

export const passwordInitState = {
  username: '',
  uuid: '',
  smsCode: '',
  isWaiting: false,
  status: '',
  changePwStep: BEGINNING_STEP,
};

export const changePasswordInitState = {
  isWaiting: false,
  status: '',
};

export const verificationInitState = {
  pin: '',
  isWaiting: false,
};

export function passwordReducer(state = passwordInitState, action) {
  switch (action.type) {
    case RESEND_PIN_SUCCESS: {
      const { status, username } = action;
      return {
        ...state,
        isWaiting: true,
        status,
        username,
      };
    }
    case RESEND_PIN_RELEASE:
      return {
        ...state,
        isWaiting: false,
      };
    case RESET_PW_SUCCESS: {
      const { status } = action;
      return {
        ...state,
        status,
      };
    }
    case VERIFY_PIN_SUCCESS: {
      const { verifyToken } = action;
      return {
        ...state,
        verifyToken,
      };
    }
    case RESET_PW_FAILURE:
      return {
        ...state,
        status: '',
      };
    case SET_CHANGE_PW_STEP:
      return {
        ...state,
        changePwStep: action.step,
      };
    case RESET_CHANGE_PW_STEPS:
      return passwordInitState;

    case '@@router/LOCATION_CHANGE': {
      const { payload } = action;
      if (payload.location.pathname === '/login') return passwordInitState;
      return state;
    }
    default:
      return state;
  }
}

export function verificationReducer(state = verificationInitState, action) {
  switch (action.type) {
    case VERIFY_SUCCESS:
      return verificationInitState;
    case RESEND_SUCCESS:
    case RESEND_LOCKING:
      return {
        ...state,
        isWaiting: true,
      };
    case RESEND_RELEASE:
      return {
        ...state,
        isWaiting: false,
      };
    default:
      return state;
  }
}

export function registerReducer(state = registerInitState, action) {
  switch (action.type) {
    case REGISTER_SUCCESS:
    case FB_LOGIN_CANCEL:
    case FB_LOGIN_SUCCESS:
    case GOOGLE_LOGIN_SUCCESS:
    case GOOGLE_LOGIN_FAILURE:
      return registerInitState;
    case FB_USER_DATA_RECEIVED: {
      const { fbUser } = action;
      // eslint-disable-next-line camelcase
      const { first_name, last_name, email, id, socialLoginSource } = fbUser;
      return {
        ...state,
        firstName: first_name,
        lastName: last_name,
        email,
        socialLoginId: id,
        socialLoginSource,
      };
    }
    case GOOGLE_LOGIN_PROFILE_RECEIVED: {
      const { profile } = action;
      return {
        ...state,
        firstName: profile.first_name,
        lastName: profile.last_name,
        email: profile.email,
        socialLoginId: profile.social_id,
        socialLoginSource: profile.socialSource,
      };
    }
    case STORE_SIGNED_PROFILE:
      return {
        ...state,
        signedProfile: action.signedProfile,
      };
    default:
      return state;
  }
}

export const initState = {
  password: passwordInitState,
  register: registerInitState,
  verification: verificationInitState,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case RESEND_PIN_SUCCESS:
    case RESEND_PIN_RELEASE:
    case RESET_PW_SUCCESS:
    case VERIFY_PIN_SUCCESS:
    case RESET_PW_FAILURE:
    case SET_CHANGE_PW_STEP:
    case RESET_CHANGE_PW_STEPS:
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        password: passwordReducer(state.password, action),
      };
    case REGISTER_SUCCESS:
    case FB_LOGIN_CANCEL:
    case FB_LOGIN_SUCCESS:
    case FB_USER_DATA_RECEIVED:
    case GOOGLE_LOGIN_SUCCESS:
    case GOOGLE_LOGIN_PROFILE_RECEIVED:
    case STORE_SIGNED_PROFILE:
      return {
        ...state,
        register: registerReducer(state.register, action),
      };
    case VERIFY_SUCCESS:
    case RESEND_SUCCESS:
    case RESEND_LOCKING:
    case RESEND_RELEASE:
      return {
        ...state,
        verification: verificationReducer(state.verification, action),
      };
    default:
      return state;
  }
}
