import _transform from 'lodash/transform';
import _isObject from 'lodash/isObject';

import { toLLMUpper } from 'utils/locale';

import { blue, darkBlue, gold, green, red, darkRed } from 'styles/colors';

const ORDER_MATCHING = 0;
const ORDER_ONGOING = 1;
const ORDER_COMPLETED = 2;
const ORDER_CANCELLED = 3;
const ORDER_OUTSTANDING = 4;

export const LOCALE_REGEX = /^([a-z]{2})(-[A-Z]{2})?$/;
export const LOCATION_REGEX = /^([A-Z]{2})(_[A-Z]{3})?$/;

export const statusMap = {
  ASSIGNING: 0,
  ONGOING: 1,
  COMPLETED: 2,
  CANCELLED: 3,
  REJECTED: 4,
  EXPIRED: 5,
  PICKED_UP: 6,
};

export const trackingStatusMap = {
  [ORDER_MATCHING]: 'assigning',
  [ORDER_ONGOING]: 'ongoing',
  [ORDER_COMPLETED]: 'completed',
  [ORDER_CANCELLED]: 'cancelled',
  [ORDER_OUTSTANDING]: 'outstanding',
};

/**
 * ## How our frontend statusFilter maps to the Huolala backend order_status
 *
 * | filter      | status_filter | order_status            |
 * |:-----------:|:-------------:|:------------------------|
 * |             | 1             | 0, 1, 7, 13, 14, 15, 16 |
 * |             | 2             | 2, 10, 11, 12           |
 * | Cancelled   | 3             | 3, 4, 5, 8, 9           |
 * | Completed   | 4             | 2, 11, 12               |
 * |             | 7             | 0, 1, 7, 15, 16         |
 * | On-going    | 8             | 1, 7, 15, 16            |
 * | Assigning   | 9             | 0                       |
 * | Outstanding | 10            | 10, 13, 14              |
 * |             | 13            | 13, 14                  |
 * |             | 30            | 2                       |
 */
const globalOrderStatus = {
  MATCHING: {
    id: 0,
    parentID: ORDER_MATCHING,
  },
  ON_GOING: {
    id: 1,
    parentID: ORDER_ONGOING,
  },
  COMPLETED: {
    id: 2,
    parentID: ORDER_COMPLETED,
  },
  CANCELLED: {
    id: 3,
    parentID: ORDER_CANCELLED,
  },
  DRIVER_REJECTED: {
    id: 4,
    parentID: ORDER_CANCELLED,
  },
  ORDER_TIMEOUT: {
    id: 5,
    parentID: ORDER_CANCELLED,
  },
  ORDER_NOPAY: {
    id: 6,
    parentID: ORDER_CANCELLED,
  },
  ORDER_LOADED: {
    id: 7,
    parentID: ORDER_ONGOING,
  },
  ORDER_CANCELLED_FIVE_DAYS: {
    id: 8,
    parentID: ORDER_CANCELLED,
  },
  ORDER_CANCELLED_TEN_DAYS: {
    id: 9,
    parentID: ORDER_CANCELLED,
  },
  DRIVER_COMPLETED: {
    id: 10,
    parentID: ORDER_OUTSTANDING,
  },
  ABNORMAL_COMPLETED: {
    id: 11,
    parentID: ORDER_COMPLETED,
  },
  SETTLEMENT_CASH: {
    id: 12,
    parentID: ORDER_COMPLETED,
  },
  SEND_BILL: {
    id: 13,
    parentID: ORDER_OUTSTANDING,
  },
  COMPLAIN_BILL: {
    id: 14,
    parentID: ORDER_OUTSTANDING,
  },
  LOADING: {
    id: 15,
    parentID: ORDER_ONGOING,
  },
  UNLOADING: {
    id: 16,
    parentID: ORDER_ONGOING,
  },
};

export const globalOrderStatusMap = globalOrderStatus;

export const statusFilterMap = {
  [globalOrderStatusMap.MATCHING.id]: 9,
  [globalOrderStatusMap.ON_GOING.id]: 8,
  [globalOrderStatusMap.COMPLETED.id]: 4,
  [globalOrderStatusMap.CANCELLED.id]: 3,
  [globalOrderStatusMap.SEND_BILL.id]: 10,
};

export const globalOrderStatusColors = {
  [globalOrderStatusMap.MATCHING.id]: gold,
  [globalOrderStatusMap.ON_GOING.id]: blue,
  [globalOrderStatusMap.COMPLETED.id]: green,
  [globalOrderStatusMap.CANCELLED.id]: red,
  [globalOrderStatusMap.DRIVER_REJECTED.id]: red,
  [globalOrderStatusMap.ORDER_TIMEOUT.id]: red,
  [globalOrderStatusMap.ORDER_NOPAY.id]: red,
  [globalOrderStatusMap.ORDER_LOADED.id]: darkBlue,
  [globalOrderStatusMap.ORDER_CANCELLED_FIVE_DAYS.id]: red,
  [globalOrderStatusMap.ORDER_CANCELLED_TEN_DAYS.id]: red,
  [globalOrderStatusMap.DRIVER_COMPLETED.id]: darkRed,
  [globalOrderStatusMap.ABNORMAL_COMPLETED.id]: green,
  [globalOrderStatusMap.SETTLEMENT_CASH.id]: green,
  [globalOrderStatusMap.SEND_BILL.id]: darkRed,
  [globalOrderStatusMap.COMPLAIN_BILL.id]: darkRed,
  [globalOrderStatusMap.LOADING.id]: blue,
  [globalOrderStatusMap.UNLOADING.id]: blue,
};

export const globalOrderStatusLabels = {
  [globalOrderStatus.MATCHING.id]: 'Records.label_status_assigning',
  [globalOrderStatus.ON_GOING.id]: 'Records.label_status_ongoing',
  [globalOrderStatus.COMPLETED.id]: 'Records.label_status_completed',
  [globalOrderStatus.CANCELLED.id]: 'Records.label_status_cancelled',
  [globalOrderStatus.DRIVER_REJECTED.id]: 'Records.label_status_cancelled',
  [globalOrderStatus.ORDER_TIMEOUT.id]: 'Records.label_status_cancelled',
  [globalOrderStatus.ORDER_NOPAY.id]: 'Records.label_status_cancelled',
  [globalOrderStatus.ORDER_LOADED.id]: 'Records.label_status_picked_up',
  [globalOrderStatus.ORDER_CANCELLED_FIVE_DAYS.id]:
    'Records.label_status_cancelled',
  [globalOrderStatus.ORDER_CANCELLED_TEN_DAYS.id]:
    'Records.label_status_cancelled',
  [globalOrderStatus.DRIVER_COMPLETED.id]: 'Records.label_status_outstanding',
  [globalOrderStatus.ABNORMAL_COMPLETED.id]: 'Records.label_status_completed',
  [globalOrderStatus.SETTLEMENT_CASH.id]: 'Records.label_status_completed',
  [globalOrderStatus.SEND_BILL.id]: 'Records.label_status_outstanding',
  [globalOrderStatus.COMPLAIN_BILL.id]: 'Records.label_status_outstanding',
  [globalOrderStatus.LOADING.id]: 'Records.label_status_ongoing',
  [globalOrderStatus.UNLOADING.id]: 'Records.label_status_picked_up',
};

export const globalCompletedOrderStatusIDs = Object.entries(
  globalOrderStatus
).reduce((previousValue, currentValue) => {
  if (currentValue[1].parentID === ORDER_COMPLETED) {
    previousValue.push(currentValue[1].id);
  }
  return previousValue;
}, []);

export const globalCancelledOrderStatusIDs = Object.entries(
  globalOrderStatus
).reduce((previousValue, currentValue) => {
  if (currentValue[1].parentID === ORDER_CANCELLED) {
    previousValue.push(currentValue[1].id);
  }
  return previousValue;
}, []);

export const ratingMap = {
  TERRIBLE: 1,
  BAD: 2,
  NORMAL: 3,
  GOOD: 4,
  GREAT: 5,
};

export const deliveryStatus = {
  READY_FOR_PICKUP: 'READY_FOR_PICKUP',
  PICK_UP_DONE: 'PICK_UP_DONE',
  PICK_UP_FAILED: 'PICK_UP_FAILED',
  DROP_OFF_DONE: 'DROP_OFF_DONE',
  DROP_OFF_FAILED: 'DROP_OFF_FAILED',
};

export const PODStatus = {
  PENDING: 'PENDING',
  SIGNED: 'SIGNED',
  DELIVERED: 'DELIVERED',
  FAILED: 'FAILED',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

export const daylightSavingsStatus = {
  DEFAULT: 0,
  CDT: 1,
  CST: 2,
  SPECIAL_CST: 3,
};
export const daylightSavingsTimeMomentMap = {
  [daylightSavingsStatus.DEFAULT]: '',
  [daylightSavingsStatus.CDT]: '[(CDT)]',
  [daylightSavingsStatus.CST]: '[(CST)]',
  [daylightSavingsStatus.SPECIAL_CST]: '',
};

export const changeEmailStep = {
  DEFAULT_STEP: 0,
  CHANGE_EMAIL_STEP: 1,
  VERIFY_SMS_CODE_STEP: 2,
};

export const priceRowType = {
  PRICE_DISCOUNT_TYPE: 9,
  PRICE_IGNORE_TYPE: 30,
  PRICE_COUPON_TYPE: 3,
};

export const redeemCouponErrorCodeMap = {
  20001: 'code_invalid',
  20003: 'code_invalid',
  20004: 'code_redeemed',
  20005: 'code_no_quota',
  50006: 'code_inapplicable',
};

export class LalamoveApiError extends Error {
  constructor(message, data) {
    super(message);
    this.data = data;
  }
}

export class UApiError extends Error {
  constructor(message, errorCode) {
    super(message);
    this.errorCode = errorCode;
  }
}

export class UMETAapiError extends Error {
  constructor(message, errorCode) {
    super(message);
    this.errorCode = errorCode;
  }
}

export const capitalize = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const formatLocaleString = string => {
  const r = /^([a-z]{2})_([a-zA-Z]{2})$/;
  const isLocale = r.test(string);
  if (!isLocale) return string;
  // eslint-disable-next-line no-unused-vars
  return toLLMUpper(string);
};

export const noop = () => {};

export const compose = (...fns) =>
  fns.reduceRight(
    (prevFn, nextFn) => (...args) => nextFn(prevFn(...args)),
    value => value
  );

export const convertLocaleInKey = obj =>
  _transform(obj, (result, value, key) => {
    const updatedKey = formatLocaleString(key);
    result[updatedKey] = _isObject(value) ? convertLocaleInKey(value) : value; // eslint-disable-line no-param-reassign
  });

export const encodeQueryData = data => {
  const ret = Object.entries(data).map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  );
  return ret.join('&');
};

export const parseUrlParams = string =>
  string.trim() === ''
    ? {}
    : string
        .slice(1)
        .split('&')
        .map(p => p.split('='))
        .reduce(
          (obj, [key, value]) => ({ ...obj, [key]: decodeURIComponent(value) }),
          {}
        );

export const parseInt10 = s => parseInt(s, 10);

export const triggerDownload = (url, name = '') => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.download = name;
  a.href = url;
  a.target = '_blank';
  a.click();
  document.body.removeChild(a);
};

export const zip = (ar1, ar2) => {
  const len = Math.min(ar1.length, ar2.length);
  const ar = [];
  for (let i = 0; i < len; i += 1) {
    ar[i] = [ar1[i], ar2[i]];
  }
  return ar;
};
