import storage from 'utils/storage';

export const KEY = 'prefer_favorite_driver';

export const loadFavDriverPreference = (): boolean =>
  storage.getItem(KEY) === 'true';

export const saveFavDriverPreference = (prefer: boolean): void =>
  storage.setItem(KEY, prefer.toString());

export const clearFavDriverPreference = (): void => storage.removeItem(KEY);
