// selectors
export function getUser(state) {
  return state.session.user;
}

export function checkAuth(state) {
  return (
    !!state.session.user.user_fid &&
    state.session.checked &&
    state.session.authenticated
  );
}

export function getIsEnterprise(state) {
  return state.session.user?.is_ep === 1;
}

export function getIsFavDriverOnly(state) {
  return state.session.user.profile.enable_my_fleet_only;
}

export function getProfileFlags(state) {
  return state.session.user?.profile?.flags || [];
}

export function getReferrer({ router }) {
  const locationState = router.location && router.location.state;
  return locationState && locationState.referrer ? locationState.referrer : '/';
}

export function getChangePwStep(state) {
  return state.auth.password.changePwStep;
}
