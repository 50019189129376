import React, { ReactNode } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import styled from 'styled-components';

const TRANS_DURATION_MS = 200;
const TRANS_NAME = 'llm-crossfade';

const LLMCrossFade = styled(ReactCSSTransitionReplace)`
  .${TRANS_NAME}-enter {
    opacity: 0;
  }

  .${TRANS_NAME}-enter.${TRANS_NAME}-enter-active {
    opacity: 1;
    transition: opacity ${TRANS_DURATION_MS}ms cubic-bezier(0.65, 0, 0.35, 1);
  }

  .${TRANS_NAME}-leave {
    opacity: 1;
  }

  .${TRANS_NAME}-leave.${TRANS_NAME}-leave-active {
    opacity: 0;
    transition: opacity ${TRANS_DURATION_MS}ms cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

interface Props {
  children: ReactNode;
}

const CrossFade: React.FC<Props> = ({ children }) => (
  <LLMCrossFade
    transitionEnterTimeout={TRANS_DURATION_MS}
    transitionLeaveTimeout={TRANS_DURATION_MS}
    transitionName={TRANS_NAME}
  >
    {children}
  </LLMCrossFade>
);

export default CrossFade;
