import React from 'react';
import { IconButton } from '@lalamove/karang';
import { close as CloseIcon } from '@lalamove/karang/dist/components/Icon/icons';

const CloseButton = props => (
  <IconButton variant="default" {...props}>
    <CloseIcon size={24} />
  </IconButton>
);

export default CloseButton;
