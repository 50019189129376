import React from 'react';
import { render } from 'react-dom';
import { ConnectedComponent, Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ConnectedRouter } from 'connected-react-router';
import { BaseApp } from '@lalamove/karang';
import configureDynamicStore from 'store/configureDynamicStore';
import { createBrowserHistory } from 'history';
import smoothScroll from 'smoothscroll-polyfill';

import i18n from 'utils/i18n';
import { Provider as ZendeskProvider } from 'store/modules/zendesk/context';

import setupLogging from './setupLogging';

import './styles';
import Main from './Main';
import { runLocalStorageMigration, runSessionMigration } from './migrations';

smoothScroll.polyfill();
setupLogging();

const history = createBrowserHistory();
runLocalStorageMigration();
// configureStore block render as userSession is required for PrivateRoute
// EXTEND: use spinning animation to allow async render
configureDynamicStore(history).then(store => {
  runSessionMigration(store);
  const rootDOM = document.querySelector('#🚐');
  const rootRender = (AppComp: ConnectedComponent<any, any>) => (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ZendeskProvider>
          <ConnectedRouter history={history}>
            <BaseApp>
              <AppComp />
            </BaseApp>
          </ConnectedRouter>
        </ZendeskProvider>
      </I18nextProvider>
    </Provider>
  );
  render(rootRender(Main), rootDOM);

  // expose store when run in Cypress
  if (window.Cypress) {
    window.store = store;
  }
});
