import { Button } from '@lalamove/karang';
import styled from 'styled-components';
import { transparentize, darken } from 'polished';

import { orange } from 'styles/colors';

// For backward compatibility to support old design only
const GhostButton = styled(Button).attrs({
  variant: 'link',
})`
  padding-right: 0;
  padding-left: 0;

  &:hover:enabled,
  &:active:enabled {
    background-color: transparent;
    color: ${transparentize(0.3, orange)};
    text-decoration: underline;
  }

  &:active:enabled,
  &:focus:enabled {
    box-shadow: none;
    color: ${darken(0.2, orange)};
  }
`;

export default GhostButton;
