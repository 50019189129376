import { call, put, select, throttle } from 'redux-saga/effects';
import moment from 'moment';

import { priceCal } from 'api/mobileAPI';

import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { CHANGE_LOCATION_REQUEST } from 'store/modules/region/actions';
import {
  SET_SERVICE,
  ADD_SPECIAL_REQUEST,
  REMOVE_SPECIAL_REQUEST,
  SET_SUB_REQUEST,
} from 'store/modules/services/actionTypes';
import {
  ROUTE_REARRANGE,
  WAYPOINT_REMOVE,
  WAYPOINT_UPDATE,
  IMPORT_SUCCESS,
  IMPORT_FAILURE,
  ROUTE_WAYPOINTS_SET,
} from 'store/modules/routing/actionTypes';
import { getFilledWaypts, getOrderIdByWayptId } from 'store/modules/routing';
import { DRAFT_ORDER_ID } from 'store/modules/routing/config';
import {
  PLACE_ORDER_SUCCESS,
  DATE_CHANGE,
  // PROMO_CODE_APPLY,
  PROMO_CODE_UPDATE,
  PLACE_ORDER_FAILURE,
  getPromoCode,
  getDeliveryDatetime,
  getCheckoutDirty,
} from 'store/modules/checkout';
import { TOPUP_DONE } from 'store/modules/wallet/actionTypes';

export const FETCH_PRICE_REQUEST = 'FETCH_PRICE_REQUEST';
export const FETCH_PRICE_SUCCESS = 'FETCH_PRICE_SUCCESS';
export const FETCH_PRICE_FAILURE = 'FETCH_PRICE_FAILURE';

export const fetchPrice = () => ({
  type: FETCH_PRICE_REQUEST,
});

export const initialState = {
  price: {
    fee: 0,
    services: 0,
    surcharge: 0,
    discount: 0,
    tips: 0,
    rewards: 0,
    total: 0,
  },
  error: '',
  balance: 0,
};

export const getBalance = state => state.pricing.balance;

export const getSelectedService = state => {
  // TODO: Code cleanup
  const selectedIndex = state.services.selectedService;
  return [{ service_type: state.services.servicesOrder[selectedIndex] }];
};

export const getSelectedSpecialRequests = state => {
  const svc = state.services;
  const specialReq = [];
  svc.selectedSpecialRequests.forEach(item => {
    const subRequest = svc.selectedSubRequests[item];
    specialReq.push({
      option: svc.specialRequests[item].trimId,
      ...(subRequest && {
        sub_option: svc.subSpecialRequests[subRequest].trimId,
      }),
    });
  });
  return specialReq;
};

export function getPrice({ pricing: { price } }) {
  return price;
}

export function getPricingError(state) {
  return state.pricing.error;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCATION_REQUEST:
    case REQUEST_LOGOUT:
    case PLACE_ORDER_SUCCESS:
    case IMPORT_FAILURE:
      return initialState;
    case FETCH_PRICE_SUCCESS:
      return {
        ...state,
        price: action.price,
        error: '',
        balance: action.wallet.balance,
      };
    case FETCH_PRICE_FAILURE:
      return { ...state, error: action.meta.message };
    default:
      return state;
  }
}

export function constructLatLngStr(waypoints) {
  let latlong = '';
  waypoints.forEach(item => {
    latlong += latlong ? ',' : '';
    latlong += `${item.lat}|${item.lng}`;
  });
  return latlong;
}

export function createPrice(data) {
  const price = {
    fee: 0,
    services: 0,
    surcharge: 0,
    discount: 0,
    tips: 0, // always zero in place order, just following PriceShape
    rewards: 0,
    total: 0,
  };
  if (data) {
    price.fee = data.basic;
    price.services = data.special_req_total;
    price.surcharge = data.surcharge_total;
    price.discount = data.discount_total && -Math.abs(data.discount_total);
    price.rewards =
      data.payment_server && data.payment_server.free_credit_used
        ? -data.payment_server.free_credit_used
        : 0;
    price.total = data.total + price.rewards; // total price deducted Rewards
  }
  return price;
}

export function extractPromo(data, promoCode = '') {
  const { promo_code_is_valid: valid, code: error } = data;
  return { promoCode, valid, error };
}

export function extractWallet(data) {
  const wallet = { canUseWallet: false, balance: 0 };
  if (data && data.payment_server) {
    const { payment_server } = data; // eslint-disable-line camelcase
    wallet.canUseWallet = payment_server.prepayment_possible;
    wallet.balance = payment_server.user_prepaid_credits;
  }
  return wallet;
}

export function* onFetchPrice({ type, orderId, method, source, id: wayptId }) {
  let id;
  if (type.startsWith('ROUTE_') || type === WAYPOINT_REMOVE) {
    id = orderId;
  } else if (type.startsWith('WAYPOINT_')) {
    id = yield select(getOrderIdByWayptId, wayptId);
  } else {
    id = DRAFT_ORDER_ID; // other actions are being triggered in place order only
  }
  if (id !== DRAFT_ORDER_ID) return; // pricecal api support place order quotes only

  yield put(fetchPrice());
  try {
    const waypoints = yield select(getFilledWaypts, id);
    if (waypoints.length < 2) return;

    const latlong = constructLatLngStr(waypoints);
    const normalReq = yield select(getSelectedService);
    const specialReq = yield select(getSelectedSpecialRequests);
    const promoCode = yield select(getPromoCode);
    const deliveryDatetime = yield select(getDeliveryDatetime);
    const checkoutDirty = yield select(getCheckoutDirty);
    let timestamp = 0;
    if (checkoutDirty.deliveryDatetime)
      timestamp = moment(deliveryDatetime).unix();

    const data = yield call(priceCal, {
      promoCode,
      latlong,
      normalReq,
      specialReq,
      timestamp,
    });

    const price = createPrice(data);
    const promo = extractPromo(data, promoCode);
    const wallet = extractWallet(data);

    yield put({
      type: FETCH_PRICE_SUCCESS,
      price,
      promo,
      wallet,
      // for tracking:
      trigger: type,
      method, // address selection method, see /checkout/tracking
      source, // vehicle selection source, see /checkout/tracking
      id: wayptId, // special request id, see /checkout/tracking
    });
  } catch (error) {
    yield put({
      type: FETCH_PRICE_FAILURE,
      meta: {
        message: error,
      },
    });
  }
}

export function* watchFetchPrice() {
  // eliminate excessive calls #57
  yield throttle(
    500,
    [
      ROUTE_WAYPOINTS_SET,
      ROUTE_REARRANGE,
      WAYPOINT_REMOVE,
      WAYPOINT_UPDATE,
      IMPORT_SUCCESS,
      SET_SERVICE,
      ADD_SPECIAL_REQUEST,
      REMOVE_SPECIAL_REQUEST,
      SET_SUB_REQUEST,
      DATE_CHANGE,
      PROMO_CODE_UPDATE,
      TOPUP_DONE,
      PLACE_ORDER_FAILURE,
    ],
    onFetchPrice
  );
}
