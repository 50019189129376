/* eslint-disable lines-between-class-members */
// interface StatusProgressStruct {
//   createdAt: string; // ISO Z
//   status: string;
// }

export default class StatusProgress {
  createdAt = '';
  status = '';

  constructor(s = {}) {
    Object.assign(this, s);
  }

  static New(s) {
    return new StatusProgress(s);
  }
}
