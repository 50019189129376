import styled from 'styled-components';
import { IconButton } from '@lalamove/karang';

const ClickArea = styled(IconButton).attrs(() => ({
  shape: 'square',
  type: 'button',
}))`
  margin: -5px;
  vertical-align: top;
`;

export default ClickArea;
