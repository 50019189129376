import { LalamoveApiError } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Request {
//   access_token: String;
//   client_id: Number | String;
//   country: String; // HK_HKG
//   ref_id: Number | String;
//   target: 'servicer';
//   event: 'ADD_BAN';
// }
// interface Response {
//   success: Boolean;
//   code?: String;
//   footprint: String;
// }
/**
 * Ban Driver by Driver Id
 * @param {string} driverId driver ID
 * @return {promise} resolve to a response object
 * @throws ERROR_INVALID_SESSION
 */
export default async function banDriver(driverId) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, city } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    country: city,
    ref_id: driverId,
    target: 'servicer',
    event: 'ADD_BAN',
  };
  const res = await fetcher.post('vanmyfleet', params);
  const { success, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return res;
}
