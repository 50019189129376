import styled from 'styled-components';
import { orange, silver } from 'styles/colors';
import { GROUND } from 'styles/zIndex';

const leftMargin = 28;
const stopBulletSize = 7;
const lineThickness = 1;

export const List = styled.ul`
  position: relative;
  list-style: none;
  counter-reset: stop-item;
  padding: 0;

  &:before {
    content: '';
    position: absolute;
    top: 1.5em;
    bottom: 1.5em;
    left: 10px;
    width: 1px;
    border-left: ${lineThickness}px dotted ${orange};
  }
`;

export const ListItem = styled.li`
  position: relative;
  box-sizing: border-box;
  padding-left: ${leftMargin}px;
  /*
  &:first-child {
    background: linear-gradient(#fff, #fff 50%, transparent 50%, transparent);
  }

  &:last-child {
    background: linear-gradient(transparent, transparent 50%, #fff 50%, #fff);
  }
*/
  /* stop bullet */
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    box-sizing: border-box;
    width: ${stopBulletSize}px;
    height: ${stopBulletSize}px;
    border: 1px solid ${silver};
    background: #fff;
    box-shadow: 0 0 0 2px #fff;
    border-radius: 50%;
    transform-origin: center;
    transform: translate(
      ${-leftMargin + 10 - stopBulletSize / 2 + lineThickness / 2}px,
      ${-stopBulletSize / 2}px
    );
  }

  &:first-child:before {
    width: ${stopBulletSize + 2}px;
    height: ${stopBulletSize + 2}px;
    border-color: ${orange};
    transform: translate(
      ${-leftMargin + 10 - (stopBulletSize + 2) / 2 + lineThickness / 2}px,
      ${-(stopBulletSize + 2) / 2}px
    );
  }

  &:last-child:before {
    width: ${stopBulletSize + 2}px;
    height: ${stopBulletSize + 2}px;
    border: 2px solid ${orange};
    margin-left: -1px;
  }
  &:last-child:after {
    content: '';
    position: absolute;
    top: 50%;
    width: ${stopBulletSize + 1}px;
    height: ${stopBulletSize + 1}px;
    border-radius: 50% 100% 50% 10%;
    background: transparent;
    box-shadow: inset 0.1px -0.1px 0 1.7px ${orange};
    transform: scaleY(1.4) translate(-21.5px, -2px) rotate(-45deg);
  }
`;

export const DragHandler = styled.span`
  position: absolute;
  z-index: ${GROUND + 2};
  top: 0;
  left: 0;
  width: ${leftMargin}px;
  height: 100%;
`;
