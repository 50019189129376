import { ALL_PAYMENT_METHOD_IDS } from 'interfaces/global/store/modules/checkout/types';
import {
  arrayOf,
  bool,
  number,
  oneOf,
  shape,
  string,
  instanceOf,
} from 'prop-types';
import Big from 'big.js';

export const ProofOfDeliveryShape = shape({
  status: string.isRequired,
  signedBy: string,
  images: arrayOf(string),
  remarks: string,
  time: string.isRequired,
});

export const WaypointShape = shape({
  name: string,
  lat: number,
  lng: number,
  addressLanguage: string,
  pod: ProofOfDeliveryShape,
});

export const DeliveryInfoShape = shape({
  name: string,
  phone: string,
  addressDetails: string,
});

export const RouteShape = shape({
  waypoints: arrayOf(WaypointShape),
  deliveryInfo: arrayOf(DeliveryInfoShape),
});

export const PriceBreakDownShape = shape({
  total: instanceOf(Big),
  items: arrayOf(shape({})),
  originalPrice: instanceOf(Big),
  tips: instanceOf(Big),
});

export const StatusShape = shape({
  id: oneOf(Array.from({ length: 17 }, (v, i) => i)),
  parentID: oneOf(Array.from({ length: 17 }, (v, i) => i)),
});

export const DriverShape = shape({
  id: string,
  name: string,
  phone: string,
  vehicleTypeId: string,
  licensePlate: string,
  avgRating: number,
  photoUri: string,
  canTrackDriver: bool,
  favorited: bool,
  banned: bool,
});

export const OrderShape = shape({
  id: string,
  refId: string,
  shareId: string,
  status: StatusShape,
  deliveryDatetime: shape({ time: string, daylightSavingsTimeType: number }),
  creationDatetime: string, // ISO: YYYY-MM-DDTHH:mm:ss
  driverId: string,
  canShowDriverInfo: bool,
  creatorName: string,
  creatorPhone: string,
  noteToDriver: string,
  serviceTypeId: string,
  paymentMethod: oneOf(ALL_PAYMENT_METHOD_IDS),
  podSignedBy: string,
  podDatetime: string,
  userRating: number,
  editable: bool,
  edited: bool,
  specialRequests: arrayOf(string),
  waypoints: arrayOf(WaypointShape),
  price: PriceBreakDownShape,
  driver: DriverShape,
  collectionFen: string,
});

export const OrderDetailShape = shape({
  id: string,
  refId: string,
  shareId: string,
  status: number,
  deliveryDatetime: shape({ time: string, daylightSavingsTimeType: number }),
  creationDatetime: string, // ISO: YYYY-MM-DDTHH:mm:ss
  driverId: number,
  canShowDriverInfo: bool,
  creatorName: string,
  creatorPhone: string,
  noteToDriver: string,
  serviceTypeId: string,
  paymentMethod: oneOf(ALL_PAYMENT_METHOD_IDS),
  userRating: number,
  editable: bool,
  edited: bool,
  specialRequests: arrayOf(string),
  collectionFen: string,
  podEnabled: bool,
  currentNode: number,
});
