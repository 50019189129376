import { call, put, select, takeEvery, throttle } from 'redux-saga/effects';

import { getDirections, getPlaceDetails } from 'api/restAPI';
import { bulkGeocode, GeocoderStatus } from 'api/restAPI/geocode';
import validator from 'utils/validator';
import { getServiceIdByOrderId } from 'store/modules/services';
import { SET_SERVICE } from 'store/modules/services/actionTypes';
import {
  getCurrentLocale,
  getCurrentCountryCode,
} from 'store/modules/region/selectors';
import {
  getFilledWaypts,
  newWaypt,
  updateWaypt,
  toggleOptimize,
  setWaypointsOrder,
  removeWaypt,
  getOrderIdByWayptId,
  getShouldOptimize,
} from './index';
import { updateDeliveryInfo } from './deliveryInfo';
import {
  MAX_NUM_WAYPOINTS,
  DELIVERY_INFO_NAME_LIMIT,
  DRAFT_ORDER_ID,
} from './config';

// action-types
import {
  ROUTE_REARRANGE,
  WAYPOINT_REMOVE,
  WAYPOINT_UPDATE,
  ROUTE_OPTIMIZE_TOGGLE,
  GOOGLE_DIRECTION_RESULT_RECEIVED,
  IMPORT_REQUEST,
  IMPORT_FAILURE,
  IMPORT_SUCCESS,
} from './actionTypes';

import {
  ERROR_INVALID_ADDR,
  ERROR_IMPORT_FAILED,
  ERROR_IMPORT_INVALID_PHONE_NUMBER,
  ERROR_IMPORT_MIN,
  ERROR_IMPORT_MAX,
  ImportError,
} from './errorTypes';

// side effects
export function* watchWaypointUpdate(payload) {
  if ((!payload.lat || !payload.lng) && payload.placeId) {
    try {
      const {
        geometry: { location },
      } = yield call(getPlaceDetails, payload.placeId);
      yield put(
        updateWaypt({
          lat: location.lat,
          lng: location.lng,
          id: payload.id,
        })
      );
    } catch (e) {
      // handle error
    }
  }
}

export function* onEdits({ type, orderId, id: wayptId }) {
  let id;
  if (type.startsWith('ROUTE_') || type === WAYPOINT_REMOVE) {
    id = orderId;
  } else if (type.startsWith('WAYPOINT_')) {
    id = yield select(getOrderIdByWayptId, wayptId);
  } else {
    id = DRAFT_ORDER_ID; // other actions are being triggered in place order only
  }

  const serviceId = yield select(getServiceIdByOrderId, id);
  const shouldOptimize = yield select(getShouldOptimize, id);

  if (type !== ROUTE_OPTIMIZE_TOGGLE && shouldOptimize) {
    // just uncheck in the checkbox whenever there is a change
    yield put(toggleOptimize(false, id));
  }

  if (type === ROUTE_OPTIMIZE_TOGGLE && !shouldOptimize) {
    yield put(setWaypointsOrder(undefined, id)); // undo and revert route order
  }
  const waypoints = yield select(getFilledWaypts, id);
  if (waypoints.length >= 2) {
    const origin = waypoints[0];
    const stopovers = waypoints.slice(1, -1);
    const destination = waypoints[waypoints.length - 1];

    const nextShouldOptimize = yield select(getShouldOptimize, id);
    try {
      const result = yield call(getDirections, {
        origin,
        destination,
        stopovers,
        serviceType: serviceId,
        optimize: nextShouldOptimize,
      });
      yield put({
        type: GOOGLE_DIRECTION_RESULT_RECEIVED,
        result,
        orderId: id,
      });

      if (nextShouldOptimize) {
        const orderMapping = result.routes[0].waypoint_order;
        yield put(setWaypointsOrder(orderMapping, id));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`googleDirectionsSaga error fetching directions`, e);
    }
  }
}

export function parseBulkGeocodeResults(bulkGeocodeResults) {
  return bulkGeocodeResults.map(bulkGeocodeResult => {
    const { results, status } = bulkGeocodeResult;

    if (status !== GeocoderStatus.OK || results.length === 0) {
      return {};
    }

    const result = results[0];

    return {
      placeId: result.place_id,
      name: result.formatted_address,
      lat: result.geometry.location.lat,
      lng: result.geometry.location.lng,
    };
  });
}

export function* doImport(
  wayptsToImport,
  orderId = DRAFT_ORDER_ID,
  method = '' // for tracking
) {
  // waypt in wayptsToImport has to have either name or placeId
  // return array of updated ids
  // get waypoints from store
  const { routing } = yield select();
  const existingWayptIds = routing.order[orderId]
    ? routing.order[orderId].sequence.slice()
    : [];

  const ids = [];
  // loop waypts
  for (let i = 0; i < existingWayptIds.length; i += 1) {
    // pop first element from geocoded_waypoints
    if (wayptsToImport.length > 0) {
      const { placeId, name, lat, lng } = wayptsToImport.shift();
      const id = existingWayptIds[i];
      if (placeId) {
        // update waypt with placeId
        yield put(updateWaypt({ id, placeId, name, lat, lng, method }));
      } else {
        yield put(
          updateWaypt({
            id,
            placeId: '',
            name,
            lat: null,
            lng: null,
            method,
          })
        );
      }
    } else {
      // remove left overs
      yield put(removeWaypt(existingWayptIds[i]));
    }
  }

  // if wayptsToImport still has item
  for (let i = 0; i < wayptsToImport.length; i += 1) {
    const { placeId, name, lat, lng } = wayptsToImport[i];
    const action = newWaypt(orderId);
    yield put(action);
    const { id } = action;

    if (placeId) {
      // update waypt with placeId
      yield put(updateWaypt({ id, placeId, name, lat, lng, method }));
    } else {
      yield put(
        updateWaypt({
          id,
          placeId: '',
          name,
          lat: null,
          lng: null,
          method,
        })
      );
    }
    ids.push(id);
  }

  return [...existingWayptIds, ...ids];
}

export function* importDeliveryInfos(csvData, waypointIds) {
  const idsContainsInvalidPhone = [];
  for (let i = 0; i < csvData.length; i += 1) {
    const id = waypointIds[i];
    const { name, phone, addressDetails } = csvData[i];
    const { valid } = validator.phone(phone, true);
    phone && !valid && idsContainsInvalidPhone.push(id);

    yield put(
      updateDeliveryInfo(id, {
        name: name.slice(0, DELIVERY_INFO_NAME_LIMIT),
        phone: phone.trim(),
        addressDetails,
      })
    );
  }
  return idsContainsInvalidPhone;
}

export function* onFileUpload({ payload }) {
  // filter out instructions in the 1st line and empty addresses:
  const csvData = payload.slice(1).filter(item => item.address !== '');
  const language = yield select(getCurrentLocale);
  const region = yield select(getCurrentCountryCode);

  try {
    if (csvData.length < 2) throw new ImportError('MIN');
    if (csvData.length > MAX_NUM_WAYPOINTS) throw new ImportError('MAX');

    const result = yield call(
      bulkGeocode,
      csvData.map(data => data.address),
      {
        language,
        region,
      }
    );

    const updatedWayptIds = yield call(
      doImport,
      parseBulkGeocodeResults(result.results),
      DRAFT_ORDER_ID,
      'import'
    );

    const idsContainsInvalidPhone = yield call(
      importDeliveryInfos,
      csvData,
      updatedWayptIds
    );

    if (idsContainsInvalidPhone.length > 0) {
      throw new ImportError('INVALID_PHONE', idsContainsInvalidPhone);
    }

    yield put({
      type: IMPORT_SUCCESS,
      meta: {
        type: 'success',
        title: 'PlaceOrder.title_import_success',
        message: 'PlaceOrder.msg_import_success',
      },
    });
  } catch (e) {
    console.error('error getting locations', e); // eslint-disable-line no-console
    if (e instanceof ImportError) {
      switch (e.message) {
        case 'INVALID_PHONE': {
          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'warning',
              title: 'PlaceOrder.title_import_failed_invalid_phone_number',
              message: ERROR_IMPORT_INVALID_PHONE_NUMBER,
              // data: e.data,
            },
          });
          break;
        }
        case 'MIN': {
          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'error',
              title: ERROR_IMPORT_FAILED,
              message: ERROR_IMPORT_MIN,
            },
          });
          break;
        }
        case 'MAX': {
          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'error',
              title: ERROR_IMPORT_FAILED,
              message: ERROR_IMPORT_MAX,
            },
          });
          break;
        }
        default: {
          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'error',
              message: ERROR_IMPORT_FAILED,
            },
          });
        }
      }
    } else {
      switch (e.status) {
        // bulk geocode error
        case GeocoderStatus.NOT_FOUND: {
          // remove non-OK stops and retry
          const indexes = []; // indexes with non-OK
          const stopsToRetry = e.results
            .map((__, index) => csvData[index])
            .filter((__, index) => {
              const isOk = e.results[index].status === GeocoderStatus.OK;
              !isOk && indexes.push(index); // remember not found index
              return isOk;
            });

          let wayptsToImport = [];

          if (stopsToRetry.length > 0) {
            const result = yield call(
              bulkGeocode,
              stopsToRetry.map(data => data.address),
              {
                language,
                region,
              }
            );

            // put notfound waypt back
            wayptsToImport = parseBulkGeocodeResults(result.results);
          }

          indexes.forEach(i => {
            // add id back to order
            wayptsToImport.splice(i, 0, {
              name: csvData[i].address,
            });
          });

          const updatedWayptIds = yield call(
            doImport,
            wayptsToImport,
            DRAFT_ORDER_ID,
            'import'
          );

          yield call(importDeliveryInfos, csvData, updatedWayptIds);

          const idsContainsInvalidAddress = updatedWayptIds.filter((__, i) =>
            indexes.includes(i)
          );

          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'error',
              title: ERROR_IMPORT_FAILED,
              message: ERROR_INVALID_ADDR,
              ids: idsContainsInvalidAddress,
            },
          });
          break;
        }
        default: {
          yield put({
            type: IMPORT_FAILURE,
            meta: {
              type: 'error',
              message: ERROR_IMPORT_FAILED,
            },
          });
        }
      }
    }
  }
}

export function* watchAllEdits() {
  yield throttle(
    500,
    [
      ROUTE_REARRANGE,
      WAYPOINT_REMOVE,
      WAYPOINT_UPDATE,
      ROUTE_OPTIMIZE_TOGGLE,
      SET_SERVICE,
    ],
    onEdits
  );
}

export function* watchRouting() {
  yield takeEvery(IMPORT_REQUEST, onFileUpload);
  yield takeEvery(WAYPOINT_UPDATE, watchWaypointUpdate);
  yield watchAllEdits();
}
