import Fetcher from './Fetcher';

class FetcherFactory {
  fetchers = {};

  store;

  bindStore(store) {
    this.store = store;
    return this;
  }

  make(Ins) {
    if (!(Ins.prototype instanceof Fetcher)) {
      throw new Error("Can't construct non Fetcher instances");
    }
    if (this.fetchers[Ins.uniqueName]) return this.fetchers[Ins.uniqueName];

    this.fetchers[Ins.uniqueName] = new Ins(this.store);
    return this.fetchers[Ins.uniqueName];
  }
}

export default new FetcherFactory();
