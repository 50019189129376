import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { func, string, bool, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';

import { forgotPasswordResetPwRequest } from 'interfaces/global/store/modules/auth/actions';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';
import { getCurrentCountry } from 'store/modules/region/selectors';

import HtmlTitle from 'components/HtmlTitle';

import ResetForm from './ResetForm';
import ResetComplete from './ResetComplete';

export const CODE_PASSWORD_RESET = 'CODE_PASSWORD_RESET';

export class ResetPasswordContainer extends Component {
  static mounted = false;

  static defaultProps = {
    apiError: {},
  };

  static propTypes = {
    resetPwRequest: func.isRequired,
    t: func.isRequired,
    username: string.isRequired,
    verifyToken: string.isRequired,
    apiError: shape({ message: string }),
    loading: bool.isRequired,
    status: string.isRequired,
    country: shape({ areaCode: string }).isRequired,
  };

  state = {
    invalidCode: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSubmit = password => {
    const { verifyToken, username, country } = this.props;
    this.props.resetPwRequest({
      verifyToken,
      username,
      password,
      areaCode: country.areaCode,
    });
  };

  render() {
    const { loading, apiError, status, t, verifyToken } = this.props;
    const { invalidCode } = this.state;

    if (!verifyToken) {
      return <Redirect to="/forgot-password" />;
    }

    let content;
    if (invalidCode) {
      content = (
        <ResetComplete
          title={t('ForgotPassword.title_invalid_link')}
          text={t('ForgotPassword.text_invalid_link')}
          buttonLabel={t('ForgotPassword.back_to_login')}
        />
      );
    } else if (status === CODE_PASSWORD_RESET) {
      content = (
        <ResetComplete
          title={t('ForgotPassword.password_updated')}
          text={t('ForgotPassword.password_changed')}
          buttonLabel={t('ForgotPassword.back_to_login')}
        />
      );
    } else {
      content = (
        <ResetForm
          onSubmit={this.handleSubmit}
          isSubmitting={loading}
          error={!loading ? apiError.message : ''}
        />
      );
    }

    return (
      <Fragment>
        <HtmlTitle>{t('Title.reset_password')}</HtmlTitle>
        {content}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  username: state.auth.password.username,
  verifyToken: state.auth.password.verifyToken,
  status: state.auth.password.status,
  loading: createLoadingSelector(['RESET_PW'])(state),
  apiError: makeMessageSelector(['RESET_PW'])(state),
  country: getCurrentCountry(state),
});

export default connect(mapStateToProps, {
  resetPwRequest: forgotPasswordResetPwRequest,
})(withTranslation()(ResetPasswordContainer));
