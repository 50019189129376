import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { bool, func, number, string } from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Button, Flag, Rating } from '@lalamove/karang';
import {
  dropoff as DropoffIcon,
  star as StarIcon,
  starFilled as StarFilledIcon,
} from '@lalamove/karang/dist/components/Icon/icons';

import { gold } from 'styles/colors';
import { fontSize } from 'styles/fonts';

import { favoriteDriver, banDriver } from 'store/modules/records';
import { getServiceName, getServiceIcon } from 'store/modules/services';
import { createLoadingSelector } from 'store/modules/loading';

import { noop, statusMap } from 'utils/helpers';

import avatarUrl from 'assets/svg/users-avatar.svg';
import defaultIcon from 'assets/generic.png';

import {
  Wrapper,
  PhotoFrame,
  BannedPhotoOverlay,
  Name,
  Label,
  AuxInfo,
  AuxInfoItem,
  DriverDetails,
  DetailContent,
  Text,
  LinkButton,
  Separator,
  ConfirmDialog,
  Message,
  DialogButtons,
} from './style';

const cancelled = [statusMap.CANCELLED, statusMap.REJECTED, statusMap.EXPIRED];
const mdash = '\u2014';

class DriverInfo extends Component {
  static defaultProps = {
    id: null,
    name: null,
    avgRating: null,
    vehicleTypeId: null,
    photoUri: '',
    licensePlate: '',
    phone: '',
    favorited: false,
    banned: false,
    serviceName: '',
    serviceIcon: '',
    userRating: 0,
    showTrackBtn: true,
    isVIPOrder: false,
    isLoading: false,
    onRate: noop,
    onTrack: noop,
    onToggleDialog: noop,
    banDriver: noop,
    favoriteDriver: noop,
    t: noop,
  };

  static propTypes = {
    id: number,
    name: string,
    avgRating: number,
    vehicleTypeId: string,
    photoUri: string,
    licensePlate: string,
    phone: string,
    favorited: bool,
    banned: bool,
    status: number.isRequired,
    serviceName: string,
    serviceIcon: string,
    userRating: number,
    showTrackBtn: bool,
    isVIPOrder: bool,
    isLoading: bool,
    onRate: func,
    onTrack: func,
    onToggleDialog: func,
    banDriver: func,
    favoriteDriver: func,
    t: func,
  };

  state = {
    currentDialog: '',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentDialog: '' });
      this.props.onToggleDialog(false);
    }
  }

  handleFallBack = e => {
    e.target.src = avatarUrl;
  };

  handleBanClick = () => {
    this.setState({ currentDialog: 'ban' });
    this.props.onToggleDialog(true);
  };

  handleFavoriteClick = () => {
    this.setState({ currentDialog: 'favorite' });
    this.props.onToggleDialog(true);
  };

  handleDismissClick = () => {
    this.setState({ currentDialog: '' });
    this.props.onToggleDialog(false);
  };

  handleConfirmBanClick = () => {
    this.props.banDriver(this.props.id);
  };

  handleConfirmFavoriteClick = () => {
    this.props.favoriteDriver(this.props.id);
  };

  renderDriverDetails = items => (
    <DriverDetails>
      {items.map(item => (
        <span key={item.title || Math.random()}>
          {item.title && <Label>{item.title}</Label>}
          <DetailContent>{item.content}</DetailContent>
        </span>
      ))}
    </DriverDetails>
  );

  renderConfirmDialog = ({ currentDialog, t }) => {
    const isBan = currentDialog === 'ban';
    return (
      <ConfirmDialog>
        <Label>
          {isBan
            ? t('RecordPanel.msg_order_not_assign_to_driver_again')
            : t('RecordPanel.msg_order_assign_to_driver_again')}
        </Label>
        <Message>
          {isBan
            ? t('RecordPanel.msg_confirm_ban_driver')
            : t('RecordPanel.msg_confirm_favorite_driver')}
        </Message>
        <DialogButtons>
          <Button
            variant="link"
            disabled={this.props.isLoading}
            onClick={this.handleDismissClick}
          >
            {t('RecordPanel.button_dismiss')}
          </Button>
          <Button
            variant="primary"
            solid
            disabled={this.props.isLoading}
            isLoading={this.props.isLoading}
            onClick={
              isBan
                ? this.handleConfirmBanClick
                : this.handleConfirmFavoriteClick
            }
          >
            {isBan
              ? t('RecordPanel.button_ban_driver')
              : t('RecordPanel.button_favorite_driver')}
          </Button>
        </DialogButtons>
      </ConfirmDialog>
    );
  };

  renderInfo = ({
    status,
    licensePlate,
    phone,
    favorited,
    banned,
    userRating,
    t,
  }) => {
    if (status === statusMap.ONGOING || status === statusMap.PICKED_UP) {
      let info = [
        {
          title: t('RecordPanel.label_license_plate'),
          content: licensePlate || mdash,
        },
        {
          title: t('RecordPanel.label_mobile_number'),
          content: phone || mdash,
        },
      ];
      if (this.props.showTrackBtn) {
        info = [
          ...info,
          {
            content: (
              <Button icon={<DropoffIcon />} onClick={this.props.onTrack}>
                {t('RecordPanel.button_track_order')}
              </Button>
            ),
          },
        ];
      }
      return this.renderDriverDetails(info);
    }

    if (status === statusMap.COMPLETED) {
      const { currentDialog } = this.state;
      if (currentDialog) {
        return this.renderConfirmDialog({ currentDialog, t });
      }

      const driverMgmtButtons = (
        <span>
          <LinkButton variant="link" onClick={this.handleBanClick}>
            {t('RecordPanel.button_ban_driver')}
          </LinkButton>
          <Separator>{t('RecordPanel.msg_or')}</Separator>
          <LinkButton variant="link" onClick={this.handleFavoriteClick}>
            {t('RecordPanel.button_favorite_driver')}
          </LinkButton>
        </span>
      );

      const driverStatus = favorited
        ? t('RecordPanel.label_driver_favorited')
        : t('RecordPanel.label_driver_banned');

      const driverRating = userRating ? (
        <Rating value={userRating} size={7} />
      ) : (
        <Button onClick={this.props.onRate} icon={<StarIcon />}>
          {t('RecordPanel.button_rate_driver')}
        </Button>
      );

      return this.renderDriverDetails([
        {
          title: t('RecordPanel.label_manage_driver'),
          content: !favorited && !banned ? driverMgmtButtons : driverStatus,
        },
        {
          title: userRating !== 0 && t('RecordPanel.label_driver_rated'),
          content: driverRating,
        },
      ]);
    }
    return null;
  };

  render() {
    const {
      id,
      name,
      phone,
      licensePlate,
      avgRating,
      vehicleTypeId,
      photoUri,
      favorited,
      banned,
      status,
      serviceName,
      serviceIcon,
      userRating,
      isVIPOrder,
      t,
    } = this.props;

    let vehicleIcon = serviceIcon || defaultIcon;
    let vehicleName = serviceName || vehicleTypeId;
    if (isVIPOrder || vehicleTypeId === 'VIP') {
      vehicleIcon = defaultIcon;
      vehicleName = 'VIP';
    }

    if (cancelled.includes(status) && !id) return null;

    return (
      <Wrapper>
        {!this.state.currentDialog && (
          <AuxInfo>
            {Boolean(avgRating) && (
              <AuxInfoItem>
                <StarFilledIcon color={gold} size={12} />
                {'\u00A0' /* nbsp */}
                <Text>{avgRating}</Text>
              </AuxInfoItem>
            )}
            <AuxInfoItem>
              <img
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  maxHeight: fontSize.small,
                }}
                src={vehicleIcon}
                alt={vehicleName}
              />
              {'\u00A0'}
              <Text>{vehicleName}</Text>
            </AuxInfoItem>
          </AuxInfo>
        )}
        <Flag
          variant="top"
          image={
            <div style={{ position: 'relative' }}>
              <PhotoFrame
                style={{ boxShadow: photoUri ? '0 0 0 1px silver' : 'none' }}
                src={photoUri || avatarUrl}
                alt={name}
                onError={this.handleFallBack}
              />
              {banned && <BannedPhotoOverlay />}
            </div>
          }
        >
          <Label>{t('Records.table_column_title_driver')}</Label>
          <Name>{name || t('RecordPanel.label_driver_matching')}</Name>
          {this.renderInfo({
            status,
            licensePlate,
            phone,
            favorited,
            banned,
            userRating,
            t,
          })}
        </Flag>
      </Wrapper>
    );
  }
}

const mapState = (state, { serviceTypeId }) => ({
  serviceName: getServiceName(state, serviceTypeId),
  serviceIcon: getServiceIcon(state, serviceTypeId, 'color'),
  isLoading: createLoadingSelector(['FAVORITE_DRIVER', 'BAN_DRIVER'])(state),
});

export default compose(
  withTranslation(),
  connect(mapState, { favoriteDriver, banDriver })
)(DriverInfo);
