import storage from 'utils/storage';

import { ProfileType } from 'interfaces/global/store/modules/auth/types';

type SelectedProfileTypeMap = {
  [userFid: string]: ProfileType;
};

export const KEY = 'selected_profile_type';

export const loadSelectedProfileTypes = (): SelectedProfileTypeMap => {
  const json: string = storage.getItem(KEY);
  return JSON.parse(json) || {};
};

export const loadSelectedProfileType = (userFid: string): ProfileType => {
  const profileTypes: SelectedProfileTypeMap = loadSelectedProfileTypes();
  return profileTypes[userFid] || null;
};

export const saveSelectedProfileType = (
  userFid: string,
  profileType: ProfileType
): void => {
  const profileTypes: SelectedProfileTypeMap = loadSelectedProfileTypes();
  profileTypes[userFid] = profileType;
  storage.setItem(KEY, JSON.stringify(profileTypes));
};
