import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bool, func, string, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  forgotPasswordResendPinRequest,
  forgotPasswordVerifyPinRequest,
} from 'interfaces/global/store/modules/auth/actions';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';
import {
  selectors as regionSelector,
  actions as regionAction,
} from 'store/modules/region';
import HtmlTitle from 'components/HtmlTitle';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { noop } from 'utils/helpers';

import UsernameForm from './UsernameForm';
import PinForm from './PinForm';

export const waitDuration = 60000;
const CODE_SMS_SENT = 'CODE_SMS_SENT';
export const SMS_CODE_RESENT = 'SMS_CODE_RESENT';
export const ERROR_MAX_RESET = 'ERROR_MAX_RESET';

export class ForgotPasswordContainer extends Component {
  static defaultProps = {
    apiError: {},
    track: noop,
  };

  static propTypes = {
    resendPinRequest: func.isRequired,
    verifyPinRequest: func.isRequired,
    t: func.isRequired,
    username: string.isRequired,
    isWaiting: bool.isRequired,
    status: string.isRequired,
    loading: bool.isRequired,
    apiError: shape({ message: string }),
    currentCountry: shape({ areaCode: string }).isRequired,
    currentCity: shape({ csPhone: string }).isRequired,
    countryDict: shape({}).isRequired,
    changeLocation: func.isRequired,
    track: func,
  };

  handleSubmitUsername = username => {
    const { currentCountry } = this.props;
    this.props.resendPinRequest({
      username,
      areaCode: currentCountry.areaCode,
      waitDuration,
    });
  };

  handleSubmitPin = smsCode => {
    const {
      currentCountry: { areaCode },
    } = this.props;
    const { username } = this.props;
    this.props.verifyPinRequest({
      smsCode,
      username,
      areaCode,
    });
  };

  changeCountry = country => {
    const { currentCountry, changeLocation } = this.props;
    if (country === currentCountry.id) {
      return;
    }

    changeLocation({
      country,
    });
  };

  renderFormContent = () => {
    const { currentCountry, currentCity, countryDict } = this.props;
    const { username, loading, isWaiting, apiError, status, t } = this.props;
    const smsSent = status === CODE_SMS_SENT || status === SMS_CODE_RESENT;
    const maxReset = apiError.message === ERROR_MAX_RESET;

    const helpLink = (
      <a href={`tel:${currentCity.csPhone}`}>
        {t('ForgotPassword.contact_cs', { hotline: currentCity.csPhone })}
      </a>
    );

    if (!smsSent)
      return (
        <UsernameForm
          countries={Object.values(countryDict)}
          country={currentCountry}
          onSubmit={this.handleSubmitUsername}
          loading={loading}
          error={!loading ? apiError.message : ''}
          onCountryChange={this.changeCountry}
        />
      );

    return (
      <PinForm
        onSubmitPin={this.handleSubmitPin}
        onResendRequest={this.handleSubmitUsername}
        loading={loading}
        isWaiting={isWaiting}
        waitDuration={waitDuration}
        maxReset={maxReset}
        username={username}
        label={t('ForgotPassword.resend')}
        error={!loading ? apiError.message : ''}
        helpLink={helpLink}
        track={this.props.track}
      />
    );
  };

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <HtmlTitle>{t('Title.forgot_password')}</HtmlTitle>
        {this.renderFormContent()}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  username: state.auth.password.username,
  isWaiting: state.auth.password.isWaiting,
  status: state.auth.password.status,
  loading: createLoadingSelector(['RESEND_PIN', 'VERIFY_PIN'])(state),
  apiError: makeMessageSelector(['RESEND_PIN', 'VERIFY_PIN'])(state),
  countryDict: regionSelector.getCountryDict(state),
  currentCity: regionSelector.getCurrentCity(state),
  currentCountry: regionSelector.getCurrentCountry(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    resendPinRequest: forgotPasswordResendPinRequest,
    verifyPinRequest: forgotPasswordVerifyPinRequest,
    changeLocation: regionAction.changeLocation,
    track,
  })
)(ForgotPasswordContainer);
