import { toLLMLower } from 'utils/locale';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import { UApiError } from 'utils/helpers';

export default async function payAppeal(item) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    cityId,
    countryId,
    locale,
  } = await fetcher.getState();
  const { orderId, amount } = item;
  const params = {
    args: JSON.stringify({
      order_uuid: orderId,
      pay_fee_fen: amount,
      pay_type: 31,
      order_terminal: 3,
    }),
    // order_uuid: orderId,
    // pay_fee_fen: payFen,
    // pay_type: 31,
    // order_terminal: 3,
    city_id: cityId,
    token: accessToken,
    is_ep: profileType,
    os: 'web',
    hlang: toLLMLower(locale),
    hcountry: countryId,
  };

  const { data, ret, msg } = await fetcher.get(
    'index.php?_m=confirm_bill&_a=index',
    params
  );

  if (ret !== 0) throw new UApiError(msg, ret);

  return data;
}
