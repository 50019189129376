import styled, { css } from 'styled-components';
import { Dropdown } from '@lalamove/karang';
import WrappedTable from 'components/WrappedTable';
import { silver, nobel, mineShaft, primary } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { fadeTransition } from 'styles/transitions';

// space at bottom for not overlay with genesys button
export const Layout = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 73rem;
  max-width: 110rem;
  padding-bottom: 5em;
  margin-right: auto;
  margin-left: auto;

  ${fadeTransition}
`;

export const SearchForm = styled.form`
  margin-left: 2em;

  @media (min-width: 110rem) {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const StatusDropdown = styled(Dropdown)`
  & button {
    height: 100%;
    padding: 0.25em 1em;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  display: none;
`;

const activeRowStyles = css`
  background: rgba(241, 102, 34, 0.05);
  > td:first-of-type {
    border-left: 4px solid ${primary.main};
  }
`;

const hoverStyles = css`
  :hover {
    background: ${mineShaft['200']};
  }
`;

export const RecordsTable = styled(WrappedTable)`
  table-layout: fixed;

  & th:nth-of-type(1),
  & td:nth-of-type(1) {
    width: 10em;
  }

  & th:nth-of-type(2),
  & td:nth-of-type(2) {
    width: 10em;
  }

  & th:nth-of-type(4),
  & td:nth-of-type(4) {
    width: 10em;
  }

  & th:nth-of-type(5),
  & td:nth-of-type(5) {
    width: 10em;
  }

  & th:nth-of-type(6),
  & td:nth-of-type(6) {
    width: 7em;
  }

  & th:nth-of-type(7),
  & td:nth-of-type(7) {
    width: 4.5em;
  }

  & tr {
    cursor: pointer;
    ${hoverStyles}

    /* stylelint-disable-next-line no-descending-specificity*/
    td {
      border-bottom: 1px solid ${nobel['200']};
    }

    :nth-child(even) {
      background: ${mineShaft['050']};

      ${hoverStyles}
    }
  }

  & tr:hover ${/* sc-selector */ StyledDropdown} {
    display: inline-block;
    background: ${mineShaft['200']};
  }

  & tr:active {
    ${activeRowStyles}
  }

  & tbody {
    ${({ activeRow }) =>
      activeRow > -1 &&
      css`
        & tr:nth-of-type(${activeRow + 1}) {
          ${activeRowStyles}
        }
      `}
  }

  & thead th:first-of-type,
  & td:first-of-type {
    text-align: center;
  }
`;

export const SupportText = styled.div`
  color: ${silver};
  font-size: ${fontSize.small};
  white-space: nowrap;
`;

export const Truncate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
