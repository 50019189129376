import { LalamoveApiError, parseInt10 } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Charge {
//   charge: string;
//   option: string;
// }
// interface Response {
//   addressDetails: {
//     block: string,
//     floor: string,
//     room: string,
//   }[];
//   addressStr: string[];
//   canTrackDriver: boolean;
//   // cancel: boolean;
//   // cancellationExpiredTime: number;
//   // charge_price: string;
//   city: string;
//   // comment_by_user: string;
//   // completed: boolean;
//   contactPersons: {
//     name: string,
//     phone: string,
//   }[];
//   // cost: string;
//   // coupon_held: number; // boolean
//   created: number;
//   driver: string;
//   driverRating: string;
//   // driverRating_count: number;
//   // driver_called_user: number; // boolean
//   // driver_is_user: boolean;
//   driver_photo: string;
//   drivertel: string;
//   // extra: string | null;
//   fleet_state: string; // FAVOURITED | BANNED | NONE
//   // free_credit_held: number;
//   // history_canberated: boolean;
//   // history_type: string;
//   // hour: string;
//   id: string;
//   // interest: string;
//   // isRead: boolean;
//   // is_favorite: boolean;
//   // lastModify: string;
//   latlong: string;
//   name: string;
//   // orderTimeEstimation: string;
//   order_editable: boolean;
//   order_edited: boolean;
//   order_status: number;
//   // order_type_id: number;
//   // passengerseat: string;
//   path: string; // we just need the length .....
//   paymentMethod: string;
//   place_id: string;
//   plate: string;
//   podDetails: null | { signedBy: string, signedTime: number };
//   // prepayment_held: number;
//   // promo_easyvan: number;
//   // rank: string;
//   rating_by_user: string;
//   // rating_to: string;
//   ref: string;
//   // rejected: boolean;
//   // reroute: boolean;
//   road_fee: string;
//   // serivcer_name: string;
//   service_type: string;
//   servicer_id: number;
//   // servicer_vehiclenum: string;
//   // shareByEmail: boolean;
//   // show_completeOrderButton: boolean;
//   special_req: {
//     option: string,
//     subname?: string,
//     price: number,
//   }[];
//   surcharge: {
//     DISCOUNT?: Charge[],
//     SURCHARGE?: Charge[],
//     USER_TIPS?: Charge[],
//   };
//   // take_order_type_id: number;
//   tel: string;
//   text: string;
//   time: number; // orderDateTime | scheduledAt
//   // timeline: { timestamp: number, action: string }[];
//   tips: string;
//   // total_transport_price: string;
//   // tunnel: string;
//   // txInterestModify: number;
//   // txModify: string;
//   // txRead: boolean;
//   vehicle_type: string;
// }

/**
 * Get order details by ID
 * @param {string} orderId order ID
 * @return {promise} ...
 * @throws ERROR_INVALID_SESSION, ERROR_INVALID_PARAMETERS, ...
 */
export default async function fetchOrderById(orderId) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, city } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    country: city,
    args: JSON.stringify({ id: orderId }),
  };
  const { success, data, code } = await fetcher.get('vanhistory', params);
  if (!success) throw new LalamoveApiError(code);
  return parseRecord(data);
}

function parseRecord(record) {
  const specialRequests = [];
  const subRequests = {};
  record.special_req.forEach(item => {
    const { option, subname } = item;
    const specialRequestKey = `${record.service_type}-${option}`;
    specialRequests.push(specialRequestKey);
    if (subname)
      subRequests[specialRequestKey] = `${specialRequestKey}-${subname}`;
  });
  const { SURCHARGE, DISCOUNT, USER_TIPS } = record.surcharge;
  return {
    order: {
      id: record.id,
      refId: record.ref,
      status: parseInt10(record.order_status),
      city: record.city,
      deliveryDatetime: new Date(record.time * 1000).toISOString(), // unix to ISO
      creationDatetime: new Date(record.created * 1000).toISOString(),
      editRevision: '', // not available from old api
      driverId: record.servicer_id,
      canShowDriverInfo: !!record.drivertel,
      creatorName: record.name,
      creatorPhone: record.tel,
      noteToDriver: record.text,
      serviceTypeId: record.service_type,
      paymentMethod: record.paymentMethod,
      podSignedBy: record.podDetails ? record.podDetails.signedBy : null, // proof of delivery
      podDatetime: record.podDetails
        ? new Date(record.podDetails.signedTime * 1000).toISOString()
        : null,
      userRating: parseInt10(record.rating_by_user),
      // vanHistoryList is not returning these 2 items yet, mocked for testing purposes:
      edited: record.order_edited !== undefined ? record.order_edited : false,
      editable:
        record.order_editable !== undefined ? record.order_editable : true,
      specialRequests,
      subRequests,
    },
    route: {
      pathList: record.path.split(','),
      latlngList: record.latlong.split(','),
      placeIdList: record.place_id.split(','),
      addressList: record.addressStr,
      recipientContactList: record.recipient_info || record.contactPersons,
      recipientAddressList: record.recipient_address || record.addressDetails,
    },
    price: {
      fee: parseInt10(record.road_fee),
      services: record.special_req.reduce((sum, item) => sum + item.price, 0),
      surcharge: SURCHARGE
        ? SURCHARGE.reduce((sum, item) => sum + parseInt10(item.charge), 0)
        : 0,
      discount: DISCOUNT
        ? DISCOUNT.reduce((sum, item) => sum + parseInt10(item.charge), 0)
        : 0,
      tips: USER_TIPS
        ? USER_TIPS.reduce((sum, item) => sum + parseInt10(item.charge), 0)
        : parseInt10(record.tips),
      rewards: -record.free_credit_held,
    },
    driver: {
      id: record.servicer_id,
      name: record.driver,
      phone: record.drivertel,
      vehicleTypeId: record.vehicle_type,
      licensePlate: record.plate,
      avgRating: parseFloat(record.driverRating),
      photoUri: record.driver_photo,
      canTrackDriver: record.canTrackDriver,
      favorited: record.fleet_state === 'FAVOURITED', // note the British spelling in API response
      banned: record.fleet_state === 'BANNED',
    },
  };
}
