import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { closeDialog } from 'store/modules/ui';

import Dialog from 'components/Dialog';
import { noop } from 'utils/helpers';

class EditOrderConfirmDialog extends Component {
  static defaultProps = {
    t: noop,
    closeDialog: noop,
    onClose: noop,
    onSubmit: noop,
    onDiscard: noop,
    nextPath: '',
  };

  static propTypes = {
    t: func,
    closeDialog: func,
    onClose: func,
    onSubmit: func,
    onDiscard: func,
    nextPath: string,
  };

  handleClose = () => {
    // eslint-disable-next-line no-shadow
    const { closeDialog, onClose } = this.props;
    onClose();
    closeDialog();
  };

  handleSubmit = () => {
    // eslint-disable-next-line no-shadow
    const { closeDialog, onSubmit } = this.props;
    closeDialog();
    onSubmit();
  };

  render() {
    const { t, onClose, onDiscard, nextPath, ...props } = this.props;
    return (
      <Dialog
        title={t('EditOrder.dialog_title_confirm_changes')}
        subtitle={t('EditOrder.dialog_text_confirm_changes')}
        primaryButtonText={t('EditOrder.dialog_button_submit')}
        primaryAction={this.handleSubmit}
        secondaryButtonText={t('EditOrder.dialog_button_back')}
        secondaryAction={this.handleClose}
        onRequestClose={this.handleClose}
        shouldCloseOnOverlayClick={false}
        {...props}
      />
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    closeDialog,
  })
)(EditOrderConfirmDialog);
