import { put, select, takeEvery, take } from 'redux-saga/effects';
import {
  SENSORS_LOGOUT,
  track,
} from 'interfaces/global/store/modules/tracking/actions';
import { mobileQueryList } from 'components/MediaQuery';
import { globalOrderStatusMap as statusMap } from 'utils/helpers';
import storage from 'utils/storage';
import { City } from 'interfaces/global/containers/CitySelector/types';
import {
  getCurrentCity,
  getCurrentLocale,
} from 'store/modules/region/selectors';
import { changeLocation } from 'store/modules/region/actions';

import {
  REQUEST_LOGOUT,
  SET_PROFILE_TYPE_SUCCESS,
  setProfileType,
  AUTH_SUCCESS,
} from '../auth/actions';
import {
  PLACE_ORDER_SUCCESS,
  toggleFavDriver,
  updatePaymentMethod,
} from '../checkout/actions';
import { CLOSE_ORDER_DETAIL_PANEL } from './actions';

import {
  getSelectedPaymentMethodId,
  getPreferFavorite,
} from '../checkout/selectors';
import { getUser } from '../auth/selectors';

import {
  clearFavDriverPreference,
  loadFavDriverPreference,
  saveFavDriverPreference,
} from './favoriteDriver';
import {
  loadDefaultPaymentMethodOption,
  saveDefaultPaymentMethodOption,
} from './lastUsedPaymentMethod';
import {
  setOpenOrderDetailPanelAfterCheckout,
  KEY as OPEN_ORDER_DETAIL_PANEL_AFTER_CHECKOUT,
} from './closeOrderDetailPanel';
import {
  loadSelectedProfileType,
  saveSelectedProfileType,
} from './profileType';

import { User, ProfileType } from '../auth/types';
import { loadRegisterLocation, saveRegisterLocation } from './registerLocation';
import { loadLastUsedLocation } from './lastUsedLocation';

export function* onLogin(action: { type: string }): Generator {
  const devicePreferFavorite: boolean = loadFavDriverPreference();
  yield put(toggleFavDriver(devicePreferFavorite));

  const {
    user_fid: userFid,
    profile_type: profileType,
    is_ep: isEp,
  } = (yield select(getUser)) as User;
  const userLastPaymentMethodName: number = loadDefaultPaymentMethodOption(
    userFid
  );
  yield put(updatePaymentMethod(userLastPaymentMethodName));

  // only business profile user can change profile type
  if (isEp === ProfileType.BUSINESS) {
    const loadedProfileType = loadSelectedProfileType(userFid);
    if (loadedProfileType && loadedProfileType !== profileType) {
      yield put(setProfileType({ profileType: loadedProfileType }));
    }
  }

  if (action.type.endsWith('LOGIN_SUCCESS')) {
    const { datacenter: lastUsedDC, ...rest } = loadLastUsedLocation();
    const { datacenter: currentDC, id: location } = (yield select(
      getCurrentCity
    )) as City;

    const locale = (yield select(getCurrentLocale)) as string;
    saveRegisterLocation({ location, locale, datacenter: currentDC });

    if (lastUsedDC === currentDC) {
      yield put(changeLocation(rest));
    }
  }
}

export function* onPlaceOrderSuccess(): Generator {
  const preferFavorite = (yield select(getPreferFavorite)) as boolean;
  saveFavDriverPreference(preferFavorite);

  const lastPaymentMethod = (yield select(
    getSelectedPaymentMethodId
  )) as number;
  const { user_fid: userFid } = (yield select(getUser)) as User;
  saveDefaultPaymentMethodOption(userFid, lastPaymentMethod);
  setOpenOrderDetailPanelAfterCheckout();
}

export function* onSetProfileType(): Generator {
  const { user_fid: userFid, profile_type: profileType } = (yield select(
    getUser
  )) as User;

  saveSelectedProfileType(userFid, profileType);
}

export function* onLogout(): Generator {
  clearFavDriverPreference();

  const { location, locale, datacenter: registerDC } = loadRegisterLocation();
  const { datacenter: currentDC } = (yield select(getCurrentCity)) as City;

  if (currentDC === registerDC) {
    // REQUEST_LOGOUT is also taken in auth module login saga,
    // wait for it to delete user session and logout sensors first
    yield take(SENSORS_LOGOUT);
    yield put(changeLocation({ location, locale }));
  }
}

export function* onCloseOrderDetailPanel({
  status,
}: {
  type: typeof CLOSE_ORDER_DETAIL_PANEL;
  status: number;
}): Generator {
  if (
    status === statusMap.MATCHING.id &&
    storage.getItem(OPEN_ORDER_DETAIL_PANEL_AFTER_CHECKOUT)
  ) {
    yield put(
      track(
        mobileQueryList.matches
          ? 'return_button_tapped'
          : 'close_button_tapped',
        {
          page: 'matching_driver_page',
        }
      )
    );
  }
}

export default function* storageSaga(): Generator {
  yield takeEvery(AUTH_SUCCESS, onLogin);
  yield takeEvery(PLACE_ORDER_SUCCESS, onPlaceOrderSuccess);
  yield takeEvery(SET_PROFILE_TYPE_SUCCESS, onSetProfileType);
  yield takeEvery(REQUEST_LOGOUT, onLogout);
  yield takeEvery(CLOSE_ORDER_DETAIL_PANEL, onCloseOrderDetailPanel);
}
