import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { ResponsiveCard } from 'components/Container';

import { fontSize } from 'styles/fonts';
import { black, silver } from 'styles/colors';
import { LinkButton } from 'components/Button';

const CardHeader = styled.h1`
  padding-bottom: 2rem;
  margin: 0;
  color: ${black};
  font-size: ${fontSize.large};
  text-align: center;
`;

const CardContent = styled.div`
  color: ${silver};
  font-size: ${fontSize.regular};
  text-align: center;
`;

const ResetComplete = ({ title, text, buttonLabel }) => (
  <ResponsiveCard>
    <CardHeader>{title}</CardHeader>
    <CardContent>{text}</CardContent>
    <LinkButton to="/login" variant="primary" size="large" solid block replace>
      {buttonLabel}
    </LinkButton>
  </ResponsiveCard>
);

ResetComplete.defaultProps = {
  title: '',
  text: '',
  buttonLabel: '',
};

ResetComplete.propTypes = {
  title: string,
  text: string,
  buttonLabel: string,
};

export default ResetComplete;
