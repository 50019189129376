import Transaction from 'models/Transaction';
import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';

import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

/**
 * Fetches wallet history
 *
 * @param {Object} options
 * @param {string} options.type - history type, i.e. [HISTORY, REWARDS, CASH]
 * @param {number} options.current - current page number
 * @param {number} options.max - number of rows per page
 * @param {string} options.start - filter start date (ISO YYYY-MM-DD)
 * @param {string} options.end - filter end date (ISO YYYY-MM-DD)
 * @return {Promise<Response>} result - resolves to
 * @throws ERROR_INVALID_SESSION, ERROR_INVALID_PARAMETERS, ERROR_OUTOFF_DATERANGE, ...
 */
export default async function fetchWalletRecords({
  method = 'credit',
  current = 1,
  max = 10,
  start = new Date().toISOString().split('T')[0],
  end = new Date().toISOString().split('T')[0],
  search = '',
}) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const methodMap = {
    [Transaction.METHOD_CREDIT]: 1,
    [Transaction.METHOD_CASH]: 2,
    // [Transaction.METHOD_CREDIT]: 'HISTORY',
    // [Transaction.METHOD_CASH]: 'CASH',
    // [Transaction.METHOD_REWARD]: 'REWARDS',
  };
  await FetcherLockService.langMutex.release();
  await FetcherLockService.accessTokenMutex.release();
  const {
    locale,
    accessToken,
    clientId,
    profileType,
    countryId,
  } = await fetcher.getState();
  const params = {
    is_ep: profileType,
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    client_id: clientId,
    args: JSON.stringify({
      end_time: end,
      start_time: start,
      // filter_by_date: 1,
      page_no: current,
      page_size: max,
      // user_name: real_name,
      trans_type: methodMap[method],
      ...(search && { search: [search] }),
    }),

    // is_driver: false,
    // access_token: accessToken,
    // client_id: clientId,
    // country: city,
    // lang: locale,
    // type: methodMap[method],
    // offset: (current - 1) * max,
    // require: max,
    // startdate: start,
    // enddate: end,
    // filter_by_date: 1,
    // ...(search && { search: [search] }),
  };

  const { data, ret, msg } = await fetcher.get('?_m=wallet_flow_list', params);
  // const { data, ret } = {
  //   ret: 0,
  //   data: {
  //     total: 20,
  //     flow_item_arr: [
  //       {
  //         flow_type: 1,
  //         amount_desc: '20.20',
  //         date_time: new Date().getTime(),
  //         name: 'Top up',
  //         order_display_id: '293929399233',
  //         user_name: 'eli.lin',
  //       },
  //       {
  //         flow_type: 1,
  //         amount_desc: '-20.20',
  //         date_time: new Date().getTime(),
  //         name: 'Pending',
  //         order_display_id: '293929399233',
  //         user_name: 'eli.lin',
  //       },
  //     ],
  //   },
  //   ...params,
  // };

  if (ret !== 0) throw new UApiError(msg, ret);

  return formatHistoryList(data);
}

function formatHistoryList(data) {
  const { flow_item_arr: originHistory, ...others } = data;

  const history = originHistory.map(item => ({
    amountDesc: item.amount_desc,
    dateTime: item.date_time,
    name: item.name,
    orderDisplayId: item.order_display_id,
    payName: item.pay_name,
    userName: item.user_name,
  }));

  return {
    ...others,
    history,
  };
}
