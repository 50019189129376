import styled from 'styled-components';
import { mineShaft } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;

  > :not(:first-child):not(:last-child) {
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const Heading = styled.span`
  margin-top: 8px;
  color: ${mineShaft[500]};
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
`;
