import Big from 'big.js';
import { call, put, select } from 'redux-saga/effects';
import { getServiceInfoByCity } from 'api/uAPI';
import { getCurrentCountry } from 'store/modules/region/selectors';
import { DEFAULT_CURRENCY_RATE } from 'interfaces/global/config';
import { VEHICLE_STD_PREFIX } from 'interfaces/global/store/modules/services/helpers';
import { UApiError } from 'utils/helpers';
import { FETCH_SERVICES_FAILURE, FETCH_SERVICES_SUCCESS } from '../actions';

const REQ = 'SPECIAL_REQUEST_';

const normalizeData = (json, { currencyRate }) => {
  const services = {};
  const specialRequests = {};
  const subSpecialRequests = {};
  const servicesOrder = json.services.map(service => String(service.id));
  const { cityInfoRevision: revision, enablePOD, welcomeMessage } = json;

  json.services.forEach((service, index) => {
    const {
      id: serviceId,
      vehicleStdArr,
      vehiclePriceTextItem,
      imgUrlHighLight,
      // imgUrlOffLight, 404
      price,
    } = service;
    const subSpecialRequestIds = vehicleStdArr.map((std, index1) => {
      const subSpecialRequestId = `${serviceId}-${index1}`;
      const subSpecialRequest = {
        id: subSpecialRequestId,
        trimId: `${index1}`,
        fullId: `${REQ}${serviceId}`,
        name: std.name,
        description: std.description,
        price: { amount: Big(std.price).div(currencyRate) },
        stdTagId: std.stdTagId,
      };
      subSpecialRequests[subSpecialRequestId] = subSpecialRequest;
      return subSpecialRequest.id;
    });
    let specialRequestId = null;
    if (subSpecialRequestIds.length !== 0) {
      specialRequestId = `${VEHICLE_STD_PREFIX}${serviceId}`;
      specialRequests[specialRequestId] = {
        id: specialRequestId,
        name: 'Vehicle Standard', // hardcode name for 车型要求
        displayName: 'Vehicle Standard',
        subSpecialRequests: subSpecialRequestIds,
      };
    }

    const commonSpecialRequestIds = service.specialRequests.map(
      (serviceToMap, specReqIndex) => {
        const { id, name, displayName } = serviceToMap;

        const key = `${serviceId}-${id}`;

        const specialRequest = {
          sortingPriority: specReqIndex,
          id: key,
          trimId: id,
          fullId: `${REQ}${id}`,
          name,
          displayName,
          // price: { amount: price }, prices should be filled by pricing
        };

        specialRequests[key] = specialRequest;

        return key;
      }
    );

    services[serviceId] = {
      sortingPriority: index,
      id: serviceId,
      trimId: serviceId,
      parentTrimId: serviceId,
      fullId: serviceId,
      name: service.name,
      dimensions: `${vehiclePriceTextItem.dimensions}, ${vehiclePriceTextItem.weight}`,
      imgUri: {
        background: imgUrlHighLight,
        selected: imgUrlHighLight,
        default: imgUrlHighLight,
      },
      price: { amount: price },
      specialRequests: specialRequestId
        ? [specialRequestId, ...commonSpecialRequestIds]
        : commonSpecialRequestIds,
    };
  });

  return {
    services,
    specialRequests,
    subSpecialRequests,
    servicesOrder,
    revision,
    enablePOD,
    welcomeMessage,
  };
};

export default function* onFetchServices({ locale }) {
  try {
    const { currencyRate = DEFAULT_CURRENCY_RATE } = yield select(
      getCurrentCountry
    );
    const json = yield call(getServiceInfoByCity, locale);
    const allServices = normalizeData(json, { currencyRate });
    yield put({
      type: FETCH_SERVICES_SUCCESS,
      payload: allServices,
    });
  } catch (error) {
    if (error instanceof UApiError) {
      yield put({ type: FETCH_SERVICES_FAILURE, error: error.message });
    } else throw error;
  }
}
