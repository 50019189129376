import { getCurrentCountry } from 'store/modules/region/selectors';
import { getCreditBalance, getTopUpAmount } from 'store/modules/wallet';
import { TOPUP_INIT, TOPUP_DONE } from 'store/modules/wallet/actionTypes';
import Segment from 'utils/segment';

export const SEGMENT_CLICK_WALLET = 'Wallet Tapped';
export const SEGMENT_CLICK_CREDIT_HISTORY = 'Credit History Tapped';
export const SEGMENT_CLICK_REWARDS_HISTORY = 'Rewards History Tapped';
export const SEGMENT_CLICK_CASH_HISTORY = 'Cash History Tapped';
export const SEGMENT_CLICK_STATEMENT = 'Statement Tapped';
export const SEGMENT_DOWNLOAD_STATEMENT_PDF = 'Download PDF Statement';
export const SEGMENT_DOWNLOAD_STATEMENT_XLS = 'Download XLS Statement';
export const SEGMENT_CLICK_TOPUP = 'Top Up Tapped';
export const SEGMENT_TOPUP_SUCCESS = 'Top Up Success';

const walletTracking = (prevState, action, nextState) => {
  switch (action.type) {
    case TOPUP_INIT: {
      const { origin } = action;
      if (origin === 'retry') return;
      const hasBalance = !!getCreditBalance(prevState);
      Segment.createTrack(SEGMENT_CLICK_TOPUP, {
        source: origin, // header, wallet, checkout_banner
        has_balance: hasBalance,
      });
      break;
    }
    case TOPUP_DONE: {
      const { isoCurrencyCode } = getCurrentCountry(prevState);
      const amount = getTopUpAmount(prevState);
      Segment.createTrack(SEGMENT_TOPUP_SUCCESS, {
        currency_code: isoCurrencyCode,
        topup_amount_local: amount,
      });
      break;
    }
    default:
      break;
  }
};

export default walletTracking;
