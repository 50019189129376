import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bool, func } from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import CsvParse from '@vtex/react-csv-parse';
import styled from 'styled-components';
import { Button, Checkbox, Heading, HeadingGroup } from '@lalamove/karang';
import { info as InfoIcon } from '@lalamove/karang/dist/components/Icon/icons';

import {
  getFilledWaypts,
  getShouldOptimize,
  trimWaypoints,
  toggleOptimize,
  receiveFileUpload,
} from 'store/modules/routing';
import {
  SEGMENT_DOWNLOAD_CSV_TEMPLATE,
  SEGMENT_UPLOAD_CSV,
} from 'store/modules/placeOrder/tracking';
import optRouteJPG from 'assets/route.opt-icon.jpg';
import ClickArea from 'components/ClickArea';
import Modal from 'components/Modal';
import Editor from 'containers/Editor';
import { noop } from 'utils/helpers';
import Segment from 'utils/segment';
import { Box } from './style';
import ConnectedAdditionalServices from './components/AdditionalServices';
import VehicleSelection from './components/VehicleSelection';

const ImportButton = styled(Button)`
  margin-left: 0.5rem;
`;

const ModalImg = styled.div`
  text-align: center;
`;

const ModalContent = styled.div`
  margin: 0 2rem;
`;

let csvData = [];

const constructCsvData = t => {
  const tc = key => t(`CSV.${key}`);
  const data = [
    [
      tc('csv_title_address'),
      tc('csv_title_name'),
      tc('csv_title_phone'),
      tc('csv_title_block_floor'),
    ],
    [
      tc('csv_sample_address'),
      tc('csv_sample_name'),
      tc('csv_sample_phone'),
      tc('csv_sample_block_floor'),
    ],
  ];
  const csvPlaceholders = Array(4).fill(tc('csv_placeholder'));
  data.push(csvPlaceholders);
  return data;
};

class PlaceOrder extends Component {
  static defaultProps = {
    allowOptimize: false,
    shouldOptimize: false,
    shouldTrim: false,
    trimWaypoints: noop,
  };

  static propTypes = {
    t: func.isRequired,
    toggleOptimize: func.isRequired,
    receiveFileUpload: func.isRequired,
    trimWaypoints: func,
    allowOptimize: bool,
    shouldOptimize: bool,
    shouldTrim: bool,
  };

  state = {
    optimizeRouteModal: false,
  };

  csvKeys = ['address', 'name', 'phone', 'addressDetails'];

  componentDidUpdate() {
    csvData = constructCsvData(this.props.t);
  }

  componentWillUnmount() {
    if (this.props.shouldTrim) {
      this.props.trimWaypoints();
    }
  }

  handleModalClose = () => {
    this.setState({
      optimizeRouteModal: false,
    });
  };

  handleEnquireOptimizeRoute = () => {
    this.setState({
      optimizeRouteModal: true,
    });
  };

  handleOptimizeRouteToggle = e => {
    // eslint-disable-next-line no-shadow
    const { toggleOptimize, shouldOptimize } = this.props;
    toggleOptimize(!shouldOptimize);
  };

  handleImport = () => {
    this.fileInput.value = '';
    this.fileInput.click();

    // tracking
    Segment.createTrack(SEGMENT_UPLOAD_CSV);
  };

  handleDownloadTracking = () => {
    Segment.createTrack(SEGMENT_DOWNLOAD_CSV_TEMPLATE);
  };

  handleFileError = () => {
    // eslint-disable-next-line no-console
    console.error('error parsing file');
  };

  handleFileLoaded = data => {
    this.props.receiveFileUpload(data);
  };

  render() {
    const { t, allowOptimize, shouldOptimize } = this.props;

    return (
      <Fragment>
        <VehicleSelection />
        <HeadingGroup>
          <Heading htmlTag="h3">{t('PlaceOrder.heading_route')}</Heading>
          {/* Safari bug on downloading blob URL: https://bugs.webkit.org/show_bug.cgi?id=190351 */}
          {/* A working get-around: https://github.com/react-csv/react-csv/issues/123 */}
          <CSVLink data={csvData} filename="template.csv" target="_self">
            <Button
              onClick={this.handleDownloadTracking}
              data-tip={t('PlaceOrder.button_tip_download_template')}
              data-place="bottom"
              data-effect="solid"
              data-for="global"
            >
              {t('PlaceOrder.button_download_template')}
            </Button>
          </CSVLink>
          <ImportButton
            onClick={this.handleImport}
            data-tip={t('PlaceOrder.button_tip_import_address')}
            data-place="bottom"
            data-effect="solid"
            data-for="global"
          >
            {t('PlaceOrder.button_import_address')}
          </ImportButton>
          <CsvParse
            keys={this.csvKeys}
            onDataUploaded={this.handleFileLoaded}
            onError={this.handleFileError}
            render={onChange => (
              <input
                type="file"
                accept=".csv"
                ref={ref => {
                  this.fileInput = ref;
                }}
                style={{ display: 'none' }}
                onChange={onChange}
              />
            )}
          />
        </HeadingGroup>
        <Editor />
        {allowOptimize && (
          <Box>
            <Checkbox
              checked={shouldOptimize}
              name="optimize"
              label={t('PlaceOrder.optimize_route')}
              onChange={this.handleOptimizeRouteToggle}
            />
            <ClickArea onClick={this.handleEnquireOptimizeRoute}>
              <InfoIcon />
            </ClickArea>
          </Box>
        )}
        <ConnectedAdditionalServices />
        <Modal
          title={t('PlaceOrder.modal_optimize_route_title')}
          name="optimize_route"
          isOpen={this.state.optimizeRouteModal}
          onClose={this.handleModalClose}
        >
          <ModalImg>
            <img src={optRouteJPG} alt={t('PlaceOrder.optimize_route')} />
          </ModalImg>
          <ModalContent>
            <Trans i18nKey="PlaceOrder.modal_optimize_route_content">
              <p>
                Use <strong>Optimize Route</strong> and we will calculate the
                shortest way to each location from the starting point.
              </p>
              <p>
                When you tick the box, the order of the stops and the final
                price will be updated to reflect the new path.
              </p>
            </Trans>
          </ModalContent>
        </Modal>
      </Fragment>
    );
  }
}

const mapState = state => ({
  allowOptimize: getFilledWaypts(state).length >= 3,
  shouldOptimize: getShouldOptimize(state),
  shouldTrim: getFilledWaypts(state).length >= 2,
});

export default compose(
  withTranslation(),
  connect(mapState, {
    toggleOptimize,
    receiveFileUpload,
    trimWaypoints,
  })
)(PlaceOrder);
