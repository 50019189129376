import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { shape, arrayOf, func, bool, string } from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Form } from '@lalamove/karang';
import { ResponsiveCard } from 'components/Container';

import PhoneInput from 'components/PhoneInput';
import { noop } from 'utils/helpers';
import { fontSize } from 'styles/fonts';
import { black, silver } from 'styles/colors';

const FormItem = Form.Item;

const CardHeader = styled.h1`
  padding-bottom: 2rem;
  margin: 0;
  color: ${black};
  font-size: ${fontSize.large};
  text-align: center;
`;

const CardContent = styled.div`
  color: ${silver};
  font-size: ${fontSize.regular};
  text-align: center;
`;

const CardFooter = styled.div`
  padding-top: 2rem;
  color: ${silver};
  text-align: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

export class UsernameForm extends Component {
  static defaultProps = {
    onCountryChange: noop,
    onSubmit: noop,
    loading: false,
    error: '',
    t: noop,
  };

  static propTypes = {
    country: shape({}).isRequired,
    countries: arrayOf(shape({})).isRequired,
    loading: bool,
    onCountryChange: func,
    onSubmit: func,
    error: string,
    t: func,
  };

  state = {
    username: '',
    showError: false,
  };

  handleCountryChange = value => {
    this.setState({
      showError: false,
    });
    this.props.onCountryChange(value);
  };

  handleUsernameChange = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value,
      showError: false,
    });
  };

  handleBlur = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value.trim(),
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ showError: true });
    const { username } = this.state;
    this.props.onSubmit(username);
  };

  render() {
    const { country, countries, loading, error, t } = this.props;
    const { username, showError } = this.state;

    return (
      <ResponsiveCard>
        <CardHeader>{t('ForgotPassword.forgot_password')}</CardHeader>
        <form onSubmit={this.handleSubmit}>
          <FormItem>
            <PhoneInput
              label={t('ForgotPassword.mobile')}
              name="username"
              value={username}
              error={showError && !loading ? t(error) : ''}
              onChange={this.handleUsernameChange}
              onBlur={this.handleBlur}
              currentCountry={country}
              countries={countries}
              onCountryChange={this.handleCountryChange}
              showAreaCode
            />
          </FormItem>
          <FormItem>
            <CardContent>{t('ForgotPassword.enter_mobile')}</CardContent>
          </FormItem>
          <FormItem>
            <SubmitButton
              type="submit"
              variant="primary"
              size="large"
              block
              solid
              disabled={!username || loading}
              isLoading={loading}
            >
              {t('ForgotPassword.submit') || ''}
            </SubmitButton>
          </FormItem>
        </form>
        <CardFooter>
          <Trans i18nKey="ForgotPassword.login">
            Already have an account?
            <Link to="/login" replace>
              Log in
            </Link>
            .
          </Trans>
        </CardFooter>
      </ResponsiveCard>
    );
  }
}

export default withTranslation()(UsernameForm);
