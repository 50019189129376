import React from 'react';
import ArchivedOrdersIcon from 'assets/svg/archived-orders.svg';
import MobileHeader from 'components/MobileHeader';
import { globalOrderStatusMap, globalOrderStatusLabels } from 'utils/helpers';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HEADER } from 'styles/zIndex';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersWithInfo } from 'interfaces/global/store/modules/records/selectors';
import { RecordsPageOrderListItem } from 'interfaces/global/views/Records/types';
import { MOBILE_HEADER_HEIGHT } from 'components/MobileHeader/styles';
import { useResetScrollOnMount } from 'components/ResetScrollOnMount';
import { Dropdown, Spinner } from '@lalamove/karang';
import {
  getCurrentCountry,
  getCurrentCity,
  getCurrentLocale,
} from 'store/modules/region/selectors';
import { getUser } from 'store/modules/auth/selectors';
import { createLoadingSelector } from 'store/modules/loading';
import EmptyState from 'components/EmptyState';
import noRecordSVG from 'assets/svg/table_no_record.svg';
import noResultSVG from 'assets/svg/table_no_result.svg';
import ScrollUpButton from 'react-scroll-up-button';
import BackToTopSvg from 'assets/svg/back_to_top.svg';
import MobileRecordThumbnail from './MobileRecordThumbnail';
import useIsScrollToTopVisible from './useIsScrollToTopVisible';

const ArchivedOrdersLink = styled.a`
  margin-right: 2px;
`;

const RecordsList = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const StickyOnTopBox = styled.div`
  position: sticky;
  z-index: ${HEADER};
  top: ${MOBILE_HEADER_HEIGHT}px;
  height: 40px;
  padding: 8px 16px;
  background-color: white;
`;

const MobileStatusDropdown = styled(Dropdown)`
  width: 100%;

  > ul {
    width: 100%;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 20px;
`;

const EmptyStateContainer = styled.div`
  padding: 20px;
`;

const BackToTop = styled.img`
  position: fixed;
  z-index: ${HEADER};
  right: 16px;
  bottom: 16px;
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition: bottom 0.1s ease-in-out;
`;

interface Props {
  onFilterChange: ({ value }: { value: string }) => void;
  status: string;
  isCustomFilters: boolean;
}

const MobileRecordsPage: React.FC<Props> = ({
  onFilterChange,
  status,
  isCustomFilters,
}) => {
  useResetScrollOnMount();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const orders: RecordsPageOrderListItem[] = useSelector(getOrdersWithInfo);
  const { countryId } = useSelector(getCurrentCountry);
  const {
    urls: { archivedOrders },
    cityId,
  } = useSelector(getCurrentCity);
  const { access_token: accessToken } = useSelector(getUser);
  const locale = useSelector(getCurrentLocale);
  const isLoading = useSelector(createLoadingSelector(['FETCH_RECORDS']));

  const {
    MATCHING,
    ON_GOING,
    COMPLETED,
    CANCELLED,
    SEND_BILL,
  } = globalOrderStatusMap;
  const filters = [
    { value: '', label: t('Records.option_all') },
    ...[MATCHING, ON_GOING, COMPLETED, CANCELLED, SEND_BILL].map(s => ({
      value: Object.keys(globalOrderStatusMap)[s.id],
      label: t(globalOrderStatusLabels[s.id]),
    })),
  ];
  const selectedFilter = filters.find(option => option.value === status);

  // India ban workaround link
  const getArchivedOrderURL = () => {
    if (
      countryId === 10000 &&
      (process.env.REACT_APP_HOST_ENV === 'production' ||
        process.env.REACT_APP_HOST_ENV === 'staging')
    )
      return archivedOrders;

    return process.env.REACT_APP_ARCHIVED_ORDERS_URL;
  };

  const onBack = () => {
    dispatch(push('/'));
  };

  const { isScrollToTopVisible } = useIsScrollToTopVisible();

  return (
    <>
      <MobileHeader
        onClickLeft={onBack}
        center={t('Records.title_records')}
        right={
          <ArchivedOrdersLink
            href={`${getArchivedOrderURL()}?city-id=${cityId}&token=${accessToken}&locale=${locale}&hcountry=${countryId}`}
            name="archived_orders"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ArchivedOrdersIcon}
              alt={t('Records.archived_orders_link')}
            />
          </ArchivedOrdersLink>
        }
      />
      <StickyOnTopBox>
        <MobileStatusDropdown
          items={filters}
          selectedItem={selectedFilter}
          onChange={onFilterChange}
        />
      </StickyOnTopBox>
      <RecordsList>
        {isLoading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {!isLoading &&
          orders.length > 0 &&
          orders.map((order: RecordsPageOrderListItem) => (
            <MobileRecordThumbnail key={order.id} order={order} />
          ))}
        {!isLoading && orders.length === 0 && (
          <EmptyStateContainer>
            <EmptyState
              image={
                <img
                  src={isCustomFilters ? noResultSVG : noRecordSVG}
                  alt={t(
                    isCustomFilters
                      ? 'Records.msg_no_result'
                      : 'Records.msg_no_record'
                  )}
                />
              }
              text={t(
                isCustomFilters
                  ? 'Records.msg_no_result'
                  : 'Records.msg_no_record'
              )}
            />
          </EmptyStateContainer>
        )}
      </RecordsList>
      {isScrollToTopVisible && (
        <ScrollUpButton
          StopPosition={0}
          EasingType="easeOutCubic"
          AnimationDuration={500}
        >
          <BackToTop src={BackToTopSvg} />
        </ScrollUpButton>
      )}
    </>
  );
};
export default MobileRecordsPage;
