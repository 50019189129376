import _get from 'lodash/get';
import { LalamoveApiError } from 'utils/helpers';

import RestAPINoDefaultHeadersFetcher from 'api/RestAPINoDefaultHeadersFetcher';
import FetcherFactory from 'api/FetcherFactory';

export default async function getGeoIpLocationCountryCode() {
  const fetcher = FetcherFactory.make(RestAPINoDefaultHeadersFetcher);
  const { data, error, errors } = await fetcher.get(
    `maps/geoip-locations/self`
  );
  if (error) throw new LalamoveApiError(error.code, error.message);
  if (errors && errors[0].status === '404') {
    // If IP or location is not found, response null
    return null;
  }
  if (errors && errors[0].status !== '404') {
    throw new LalamoveApiError(errors[0].code, errors[0].message);
  }
  return _get(data, 'attributes.countryCode', null);
}
