import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@lalamove/karang';
import { info as InfoIcon } from '@lalamove/karang/dist/components/Icon/icons';
import ClickArea from 'components/ClickArea';
import { darkGray } from 'styles/colors';
import { whenMobile } from 'components/MediaQuery';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modules/ui';

interface Props {
  allowOptimize: boolean;
  shouldOptimize: boolean;
  onToggle: () => void;
}

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid ${darkGray};
  margin-bottom: 2rem;

  ${whenMobile} {
    margin-top: 16px;
    margin-bottom: 0;
  }
`;

const OptimizeRoute: React.FC<Props> = ({
  allowOptimize,
  shouldOptimize,
  onToggle,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openOptRouteModal = useCallback(
    () => dispatch(openModal('OPTIMIZE_ROUTE')),
    [dispatch]
  );

  return (
    <>
      {allowOptimize && (
        <Box>
          <Checkbox
            checked={shouldOptimize}
            name="optimize"
            label={t('PlaceOrder.optimize_route')}
            onChange={onToggle}
          />
          <ClickArea onClick={openOptRouteModal}>
            <InfoIcon />
          </ClickArea>
        </Box>
      )}
    </>
  );
};

export default OptimizeRoute;
