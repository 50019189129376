import React from 'react';
import { withTranslation } from 'react-i18next';
import { arrayOf, bool, func, shape } from 'prop-types';
import styled from 'styled-components';
import { Rating } from '@lalamove/karang';
import { group as GroupIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { CloseButton } from 'components/Button';
import EmptyState from 'components/EmptyState';
import WrappedTable from 'components/WrappedTable';
import { offWhite } from 'styles/colors';
import { noop } from 'utils/helpers';

const StyledTable = styled(WrappedTable)`
  table-layout: fixed;

  & thead th:first-of-type {
    padding-left: 2em;

    > div {
      padding-left: 0;
    }
  }

  & td:first-of-type {
    overflow: hidden;
    padding-left: 2em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & thead th:last-of-type,
  & td:last-of-type {
    text-align: right;
  }
`;

const DriverTable = ({ t, drivers, loading, onRemoveDriver }) => {
  const columns = [
    {
      key: 'name',
      label: t('Drivers.table_column_title_name'),
    },
    {
      key: 'physics_vehicle_name',
      label: t('Drivers.table_column_title_vehicle'),
    },
    {
      key: 'avg_rating',
      label: t('Drivers.table_column_title_rating'),
      render: avgRating => <Rating value={Math.round(avgRating)} size={7} />,
    },
    {
      key: 'driver_fid',
      label: '',
      render: id => <CloseButton onClick={() => onRemoveDriver(id)} />,
    },
  ];

  const emptyState = (
    <EmptyState
      image={<GroupIcon size={200} color={offWhite} />}
      text={t(`Drivers.msg_no_favorite`)}
    />
  );

  return (
    <StyledTable
      hoverable
      data={drivers}
      columns={columns}
      loading={loading}
      emptyState={emptyState}
    />
  );
};

DriverTable.defaultProps = {
  t: noop,
  drivers: [],
  loading: false,
  onRemoveDriver: noop,
};

DriverTable.propTypes = {
  t: func,
  drivers: arrayOf(shape()),
  loading: bool,
  onRemoveDriver: func,
};

export default withTranslation()(DriverTable);
