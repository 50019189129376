import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bool, func, string, number, shape } from 'prop-types';
import styled from 'styled-components';
import { Checkbox, RadioGroup, TextArea } from '@lalamove/karang';
import { star as StarIcon } from '@lalamove/karang/dist/components/Icon/icons';

import { rateOrder, favoriteDriver, banDriver } from 'store/modules/records';
import { createLoadingSelector } from 'store/modules/loading';
import { getRatingReasons } from 'store/modules/region/selectors';
import Heading from 'components/SliderPanel/Heading';
import SliderPanelFooter from 'components/SliderPanelFooter';

import { noop, statusMap, ratingMap } from 'utils/helpers';

import Emotion from './components/Emotion';
import { Container, Content, Wrapper } from './style';

const StyledEmotion = styled(Emotion)`
  margin: 2em 0;
`;

const TextAreaWrapper = styled.div`
  margin: 1.3em 0;
  > div {
    display: block;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  display: block;
  margin: 1em 0;
`;

const initState = {
  rating: null,
  reason: null,
  comment: '',
  favorited: false,
  banned: false,
};

class Rating extends Component {
  static defaultProps = {
    id: null,
    status: null,
    rated: false,
    isLoading: false,
    rateOrder: noop,
    favoriteDriver: noop,
    banDriver: noop,
    onBack: noop,
    onClose: noop,
  };

  static propTypes = {
    t: func.isRequired,
    id: string,
    status: number,
    driverId: number.isRequired,
    rated: bool,
    isLoading: bool,
    rateOrder: func,
    favoriteDriver: func,
    banDriver: func,
    onBack: func,
    onClose: func,
    ratingReasons: shape({}).isRequired,
  };

  state = initState;

  componentDidMount() {
    if (this.props.status !== statusMap.COMPLETED || this.props.rated) {
      this.props.onBack();
    }
  }

  componentDidUpdate() {
    if (this.props.rated) {
      this.props.onBack();
    }
  }

  handleRating = e => {
    const rating = parseInt(e.target.value, 10);
    if ([ratingMap.TERRIBLE, ratingMap.BAD].includes(rating)) {
      this.setState({ ...initState, rating, banned: true });
    } else if ([ratingMap.GOOD, ratingMap.GREAT].includes(rating)) {
      this.setState({ ...initState, rating, favorited: true });
    } else {
      this.setState({ ...initState, rating });
    }
  };

  handleReason = value => {
    this.setState({ reason: value, comment: '' });
  };

  handleComment = e => {
    this.setState({ comment: e.target.value });
  };

  handleToggle = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  onSubmit = () => {
    const { rating, reason, comment, favorited, banned } = this.state;
    this.props.rateOrder(this.props.id, rating, reason, comment);
    if (favorited) {
      this.props.favoriteDriver(this.props.driverId);
    } else if (banned) {
      this.props.banDriver(this.props.driverId);
    }
  };

  render() {
    const { rating, reason, comment, favorited, banned } = this.state;
    const { t, id, onBack, onClose, isLoading, ratingReasons } = this.props;
    if (!id) return null;
    const reasons = ratingReasons[rating] || [];
    const selectedOtherReason = reason === 'BAD_RATING_OTHERS';

    return (
      <Container>
        <Content>
          <Heading
            icon={<StarIcon size={24} />}
            title={t('RecordPanel.title_rating')}
            description={t('RecordPanel.msg_rating')}
          />
          <Wrapper>
            <StyledEmotion
              name="rating"
              selected={rating}
              onChange={this.handleRating}
            />
            <RadioGroup
              name="rating_reason"
              value={reason}
              onChange={this.handleReason}
              variant="toggle"
            >
              {Radio =>
                reasons.map(item => (
                  <Radio key={item} value={item} block>
                    {t(`rating_reasons:${item}`)}
                  </Radio>
                ))
              }
            </RadioGroup>
            {selectedOtherReason && (
              <TextAreaWrapper>
                <TextArea
                  row={3}
                  label={t(`rating_reasons:${reason}`)}
                  value={comment}
                  onChange={this.handleComment}
                  maxLength={1000}
                  autoFocus
                />
              </TextAreaWrapper>
            )}
            {[ratingMap.TERRIBLE, ratingMap.BAD].includes(rating) && (
              <StyledCheckbox
                name="banned"
                label={t('RecordPanel.label_ban_driver')}
                checked={banned}
                onChange={this.handleToggle}
              />
            )}
            {[ratingMap.NORMAL, ratingMap.GOOD, ratingMap.GREAT].includes(
              rating
            ) && (
              <StyledCheckbox
                name="favorited"
                label={t('RecordPanel.label_favorite_driver')}
                checked={favorited}
                onChange={this.handleToggle}
              />
            )}
          </Wrapper>
        </Content>
        <SliderPanelFooter
          primaryAction={this.onSubmit}
          primaryButtonText={t('RecordPanel.button_submit')}
          isLoadingPrimaryButton={isLoading}
          disablePrimaryButton={
            isLoading ||
            !rating ||
            (rating && reasons.length && !reason) ||
            (selectedOtherReason && !comment)
          }
          secondaryAction={onBack}
          secondaryButtonText={t('RecordPanel.button_back')}
          closeAction={onClose}
          closeButtonText={t('RecordPanel.button_close_panel')}
        />
      </Container>
    );
  }
}

const mapState = state => ({
  isLoading: createLoadingSelector([
    'RATE_ORDER',
    'FAVORITE_DRIVER',
    'BAN_DRIVER',
  ])(state),
  ratingReasons: getRatingReasons(state),
});

export default compose(
  withTranslation(),
  connect(mapState, { rateOrder, favoriteDriver, banDriver })
)(Rating);
