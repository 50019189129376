import { all, takeLatest } from 'redux-saga/effects';

import onFetchServices from './fetchServices';
import { onChangeAdditionalServices } from './additionalServices';

import {
  FETCH_SERVICES_REQUEST,
  ADD_SPECIAL_REQUEST,
  REMOVE_SPECIAL_REQUEST,
  SET_SUB_SPECIAL_REQUEST,
} from '../actions';

export default function* servicesSaga() {
  yield all([
    takeLatest(FETCH_SERVICES_REQUEST, onFetchServices),
    // Vehicle standard changes under three conditions:
    // 1) User checks the `Vehicle Standard` checkbox and the first sub-special request is auto-selected
    // 2) User unchecks the `Vehicle Standard` checkbox and the selected sub-special request is deselected
    // 3) User selects another sub-special request through changing the radio button selection
    // So, onChangeVehicleStandard is triggered on all 3 actions
    takeLatest(
      [ADD_SPECIAL_REQUEST, REMOVE_SPECIAL_REQUEST, SET_SUB_SPECIAL_REQUEST],
      onChangeAdditionalServices
    ),
  ]);
}
