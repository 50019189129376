import styled from 'styled-components';
import { Button } from '@lalamove/karang';
import { fontSize } from 'styles/fonts';
import { GROUND } from 'styles/zIndex';
import { black, darkGray, silver } from 'styles/colors';

export const Wrapper = styled.div`
  position: relative;
  padding: 1em 0;
  border-bottom: 1px ${darkGray} solid;
  margin: 0 1.25em 0 2.25em;
`;

export const PhotoFrame = styled.img`
  display: block;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  object-fit: cover;
`;

export const BannedPhotoOverlay = styled.div`
  position: absolute;
  z-index: ${GROUND + 1};
  top: -2px;
  left: -2px;
  width: 3em;
  height: 3em;
  border: 2px solid ${black};
  border-radius: 50%;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) calc(50% - 2px),
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0.2) calc(50% + 2px),
    rgba(0, 0, 0, 0.2) 100%
  );
`;

export const Name = styled.div`
  padding: 0.4em 0 0;
`;

export const Label = styled.span`
  display: inline-block;
  color: ${silver};
  font-size: ${fontSize.small};
  line-height: ${fontSize.small};
`;

export const AuxInfo = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
  padding: 0;
  margin: 0;
`;

export const DetailContent = styled.div`
  padding-top: 0.4em;
`;

export const DriverDetails = styled.div`
  width: 100%;

  & ${Label} {
    padding-top: 2em;
  }
`;

export const Text = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-bottom: -3px;
`;

export const LinkButton = styled(Button)`
  padding: 0.5em 0;
`;

export const Separator = styled.span`
  padding: 0 0.66em;
  color: ${silver};
  font-size: 0.8em;
`;

export const ConfirmDialog = styled.div`
  padding-top: 1em;
  border-top: 1px ${darkGray} solid;
  margin-top: 1em;
`;

export const Message = styled.div`
  padding-top: 0.4em;
`;

export const DialogButtons = styled.span`
  float: right;
  padding: 0.5em 1em;

  & button {
    margin-left: 2em;
  }
`;

export const TrackButton = styled(Button)`
  max-width: 10em;
  height: 3em;
  margin: 1em 1.25em 1em 2.25em;
  color: ${silver};
`;
