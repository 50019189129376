import React from 'react';
import { useDispatch, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addBusinessProfile } from 'interfaces/global/store/modules/auth/actions';

import { closeDialog } from 'store/modules/ui';

import Dialog from 'components/Dialog';

const BusinessRoleAddedDialog: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClose = () => {
    batch(() => {
      dispatch(addBusinessProfile());
      dispatch(closeDialog());
    });
  };

  const onClickSwitch = () => {
    batch(() => {
      dispatch(addBusinessProfile({ isSwitch: true }));
      dispatch(closeDialog());
    });
  };

  return (
    <Dialog
      shouldCloseOnOverlayClick={false}
      title={t('Profile.dialog_title_business_role_added')}
      subtitle={t('Profile.dialog_text_business_role_added')}
      primaryButtonText={t('Profile.dialog_button_business_role_added_ok')}
      secondaryButtonText={t(
        'Profile.dialog_button_business_role_added_cancel'
      )}
      primaryAction={onClickSwitch}
      secondaryAction={onClose}
      onRequestClose={onClose}
      {...props}
    />
  );
};

export default BusinessRoleAddedDialog;
