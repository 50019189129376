import { LalamoveApiError } from 'utils/helpers';

import RestAPIFetcher from 'api/RestAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

/**
 * Fetches Google Directions results via Sanctuary
 *
 * @param {Object} params
 * @param {Object} params.origin - origin waypoint object
 * @param {Object} params.destination - destination waypoint object
 * @param {array<Object>} params.stopovers - array of stopover waypoint objects
 * @param {string} params.serviceType - service type key with prefix (e.g. SERVICE_VAN, SUB_SERVICE_FOOD)
 * @param {bool} params.optimize - whether to optimize route or not (default: false)
 * @param {string} params.mode - travel mode [driving|walking|bicycling|transit] (default: driving)
 * @return {Promise} result - resolves to
 * ```
 * {
 *   routes: {array<Object>},
 *   status: {string},
 *   ...
 * }
 * ```
 * @throws Forbidden, BadRequest
 */
export default async function getDirections({
  origin,
  destination,
  serviceType,
  stopovers = [],
  optimize = false,
  mode = 'driving',
}) {
  const fetcher = FetcherFactory.make(RestAPIFetcher);
  const optimizeRoute = optimize ? 'optimize:true|' : '';
  const waypoints = stopovers.reduce(
    (concat, stop) => `${concat}${concat ? '|' : ''}${stop.lat},${stop.lng}`,
    ''
  );
  const params = {
    origin: `${origin.lat},${origin.lng}`,
    destination: `${destination.lat},${destination.lng}`,
    ...(waypoints && { waypoints: `${optimizeRoute}${waypoints}` }),
    serviceType,
    mode,
  };
  const response = await fetcher.get('maps/directions', params);
  const { error } = response;
  if (error) throw new LalamoveApiError(error.code, error.fields);
  return response;
}
