// https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6

export default function loadingReducer(state = {}, action) {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE|CANCEL)$/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE / GET_TODOS_CANCEL
    [requestName]: requestState === 'REQUEST',
  };
}

export const createLoadingSelector = actions => state =>
  // returns false only when all actions is not loading or undefined
  actions.some(action =>
    state.loading && state.loading[action] === undefined
      ? false
      : state.loading[action]
  );

export const getIsFetchingCities = (countryIds = []) => state => {
  if (!state.loading) return false;

  if (countryIds.length > 0) {
    return countryIds.some(cid => state.loading[`FETCH_${cid}_CITIES`]);
  }
  const actions = Object.keys(state.loading);
  return actions.some(
    action => /FETCH_([A-Z]{2})_CITIES/.test(action) && state.loading[action]
  );
};
