import Big from 'big.js';
import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { CHANGE_LOCATION_REQUEST } from 'store/modules/region/actions';
import { IMPORT_FAILURE } from 'store/modules/routing/actionTypes';
import { PLACE_ORDER_SUCCESS } from 'store/modules/checkout';
import { PaymentMethods } from 'interfaces/global/store/modules/checkout/types';
import { PROFILE_SWITCHED } from 'interfaces/global/store/modules/auth/actions';
import { CouponPayTypes, CouponStatuses } from './types.ts';

import {
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
  SET_COUPON,
  DISABLE_ONLINE_PAYMENT_COUPON_TOOLTIP,
  REDEEM_CHECKOUT_COUPON_SUCCESS,
  CLEAR_REDEEM_COUPON_HISTORY,
} from './actions';

export const initialState = {
  price: {
    items: [],
    couponSavings: Big(0),
    total: Big(0),
  },
  selectedOnlineCoupon: null,
  selectedCashCoupon: null,
  hasUserSetOnlineCoupon: false,
  hasUserSetCashCoupon: false,
  orderCouponList: [],
  priceRaw: [],
  error: '',
  enableOnlinePaymentTooltip: true,
  redeemedCouponIds: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCATION_REQUEST:
    case REQUEST_LOGOUT:
    case PLACE_ORDER_SUCCESS:
    case PROFILE_SWITCHED:
    case IMPORT_FAILURE:
      return { ...initialState };
    case FETCH_PRICE_SUCCESS: {
      const {
        price,
        priceRaw,
        orderCouponList,
        paymentMethodIds,
        selectedPaymentMethodId,
        shouldResetCoupon,
      } = action;

      const common = {
        price,
        priceRaw,
        orderCouponList,
        paymentMethodIds,
        error: '',
        ...(shouldResetCoupon && {
          hasUserSetOnlineCoupon: false,
          hasUserSetCashCoupon: false,
        }),
      };

      if (selectedPaymentMethodId === PaymentMethods.ONLINE.id) {
        const shouldApplyOnlineCoupon =
          shouldResetCoupon || !state.hasUserSetOnlineCoupon;
        const bestCoupon =
          orderCouponList.find(
            coupon => coupon.status === CouponStatuses.AVAILABLE
          ) ?? null;

        return {
          ...state,
          ...common,
          ...(shouldApplyOnlineCoupon && { selectedOnlineCoupon: bestCoupon }),
        };
      }

      if (selectedPaymentMethodId === PaymentMethods.CASH.id) {
        const shouldApplyCashCoupon =
          shouldResetCoupon || !state.hasUserSetCashCoupon;
        const bestCoupon =
          orderCouponList.find(
            coupon =>
              coupon.pay_type === CouponPayTypes.UNLIMITED &&
              coupon.status === CouponStatuses.AVAILABLE
          ) ?? null;

        return {
          ...state,
          ...common,
          ...(shouldApplyCashCoupon && { selectedCashCoupon: bestCoupon }),
        };
      }

      return { ...state, ...common };
    }
    case SET_COUPON: {
      const { selectedPaymentMethodId, coupon } = action;

      return {
        ...state,
        ...(selectedPaymentMethodId === PaymentMethods.CASH.id && {
          hasUserSetCashCoupon: true,
          selectedCashCoupon: coupon,
        }),
        ...(selectedPaymentMethodId === PaymentMethods.ONLINE.id && {
          hasUserSetOnlineCoupon: true,
          selectedOnlineCoupon: coupon,
        }),
      };
    }
    case FETCH_PRICE_FAILURE:
      return { ...state, error: action.meta.message };
    case DISABLE_ONLINE_PAYMENT_COUPON_TOOLTIP:
      return { ...state, enableOnlinePaymentTooltip: false };
    case REDEEM_CHECKOUT_COUPON_SUCCESS:
      if (!action.newlyRedeemedCouponId) return state;
      return {
        ...state,
        redeemedCouponIds: [
          action.newlyRedeemedCouponId,
          ...state.redeemedCouponIds,
        ],
      };
    case CLEAR_REDEEM_COUPON_HISTORY:
      return { ...state, redeemedCouponIds: [] };
    default:
      return state;
  }
}
