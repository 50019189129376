import _omit from 'lodash/omit';
import { CHANGE_LOCALE_REQUEST } from 'store/modules/region/actions';
import { PLACE_ORDER_SUCCESS } from 'store/modules/checkout';
import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { getOrder } from 'store/modules/records';
import { DRAFT_ORDER_ID } from 'store/modules/routing/config';
import {
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
  SET_SERVICE,
  RESET_SERVICES,
  ADD_SPECIAL_REQUEST,
  REMOVE_SPECIAL_REQUEST,
  SET_SUB_REQUEST,
} from './actionTypes';

export const fetchServices = () => ({
  type: FETCH_SERVICES_REQUEST,
});

export const setService = (serviceIndex, source = '') => ({
  type: SET_SERVICE,
  serviceIndex,
  source, // event source for tracking
});

export const addSpecialRequest = id => ({
  type: ADD_SPECIAL_REQUEST,
  id,
});

export const removeSpecialRequest = id => ({
  type: REMOVE_SPECIAL_REQUEST,
  id,
});

export const setSubRequest = (id, subid) => ({
  type: SET_SUB_REQUEST,
  id,
  subid,
});

export const resetServices = () => ({
  type: RESET_SERVICES,
});

export const initialState = {
  services: {},
  specialRequests: {},
  subSpecialRequests: {},
  servicesOrder: [],
  isLoading: false,
  selectedService: 0,
  selectedSpecialRequests: [],
  selectedSubRequests: {},
};

export const getServiceNames = state => {
  const svc = state.services;
  const serviceNames = {};
  const keys = Object.keys(svc.services);
  if (keys.length > 0) {
    keys.forEach(key => {
      serviceNames[key] = svc.services[key].name;
    });
  }
  return serviceNames;
};

export const getServiceName = (state, serviceTypeId) => {
  const service = state.services.services[serviceTypeId];
  return service ? service.name : '';
};

export const getServiceIcon = (state, serviceTypeId, type) => {
  const service = state.services.services[serviceTypeId];
  return service ? service.imgUri[type] : '';
};

export const getServices = state => {
  const svc = state.services;
  return svc.servicesOrder.map(service => svc.services[service]);
};

export const getSelectedService = state => {
  const { services, servicesOrder, selectedService } = state.services;
  return services[servicesOrder[selectedService]];
};

export const getServiceIdByOrderId = (state, orderId) => {
  if (orderId === DRAFT_ORDER_ID) {
    return getSelectedService(state).fullId;
  }
  const order = getOrder(state, orderId);
  return `SERVICE_${order.serviceTypeId}`;
};

export const getSelectedServiceAndSubServiceIds = state => {
  const selectedService = getSelectedService(state);
  let serviceId = '';
  let subServiceId = '';
  if (selectedService) {
    const { parentTrimId, trimId } = selectedService;
    serviceId = parentTrimId;
    subServiceId = parentTrimId !== trimId ? trimId : '';
  }
  return { serviceId, subServiceId };
};

export const getSelectedSubRequestIdByRequestId = (state, requestId) => {
  const subRequestId = state.services.selectedSubRequests[requestId];
  return subRequestId ? getSubSpecialRequest(state, subRequestId).trimId : '';
};

export const getSelectedSubSpecialRequests = state => {
  const selectedSubSpecialRequests = {};
  for (const [requestId, subRequestId] of Object.entries(
    state.services.selectedSubRequests
  )) {
    selectedSubSpecialRequests[requestId] =
      state.services.subSpecialRequests[subRequestId];
  }
  return selectedSubSpecialRequests;
};

export const getSpecialRequest = (state, id) =>
  state.services.specialRequests[id];

export const getSpecialRequests = (state, serviceId) => {
  const svc = state.services;
  return svc.services[serviceId] && svc.services[serviceId].specialRequests
    ? svc.services[serviceId].specialRequests.map(id => svc.specialRequests[id])
    : [];
};

export const getSubSpecialRequest = (state, id) =>
  state.services.subSpecialRequests[id];

export const getSubSpecialRequests = (state, specialRequestId) => {
  const svc = state.services;
  return svc.specialRequests[specialRequestId] &&
    svc.specialRequests[specialRequestId].subSpecialRequests
    ? svc.specialRequests[specialRequestId].subSpecialRequests.map(
        id => svc.subSpecialRequests[id]
      )
    : [];
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SERVICES_REQUEST:
      return { ...state, isLoading: true };

    case FETCH_SERVICES_SUCCESS: {
      const {
        services,
        specialRequests,
        subSpecialRequests,
        servicesOrder,
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        services,
        specialRequests,
        subSpecialRequests,
        servicesOrder,
        selectedService: 0,
      };
    }

    case FETCH_SERVICES_FAILURE:
      return { ...state, isLoading: false };

    case SET_SERVICE:
      return {
        ...state,
        selectedService: action.serviceIndex,
        selectedSpecialRequests: [],
      };

    case ADD_SPECIAL_REQUEST: {
      const { id } = action;
      const { subSpecialRequests } = state.specialRequests[id];
      return {
        ...state,
        selectedSpecialRequests: [...state.selectedSpecialRequests, id],
        ...(subSpecialRequests && {
          selectedSubRequests: {
            ...state.selectedSubRequests,
            [id]: subSpecialRequests[0],
          },
        }),
      };
    }

    case REMOVE_SPECIAL_REQUEST: {
      const updatedSelectedSubRequests = _omit(
        state.selectedSubRequests,
        action.id
      );
      return {
        ...state,
        selectedSpecialRequests: state.selectedSpecialRequests.filter(
          id => id !== action.id
        ),
        selectedSubRequests: updatedSelectedSubRequests,
      };
    }

    case SET_SUB_REQUEST:
      return {
        ...state,
        selectedSubRequests: {
          ...state.selectedSubRequests,
          [action.id]: action.subid,
        },
      };

    case PLACE_ORDER_SUCCESS:
    case REQUEST_LOGOUT: {
      return {
        ...state,
        selectedService: 0,
        selectedSpecialRequests: [],
      };
    }

    case CHANGE_LOCALE_REQUEST:
    case RESET_SERVICES:
      return initialState;
    default:
      return state;
  }
}
