import { call, put, takeEvery, delay, take, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  requestSMSCode,
  verifyForgotPasswordSMScode,
  changePassword,
} from 'api/uAPI';
import { SMS_CODE_RESENT } from 'interfaces/global/containers/ForgotPassword';
import { CODE_PASSWORD_RESET } from 'interfaces/global/containers/ResetPassword';
import { getChangePwStep } from 'interfaces/global/store/modules/auth/selectors';
import {
  VERIFY_PIN_REQUEST,
  RESEND_PIN_REQUEST,
  VERIFY_PIN_SUCCESS,
  VERIFY_PIN_FAILURE,
  RESEND_PIN_FAILURE,
  RESEND_PIN_SUCCESS,
  RESEND_PIN_RELEASE,
  RESET_PW_REQUEST,
  RESET_PW_SUCCESS,
  RESET_PW_FAILURE,
  FORGOT_PW_VERIFY_PIN_INIT,
  FORGOT_PW_RESEND_PIN_INIT,
  FORGOT_PW_RESET_PW_INIT,
  CHANGE_PW_RESEND_PIN_INIT,
  CHANGE_PW_VERIFY_PIN_INIT,
  CHANGE_PW_RESET_PW_INIT,
  RESET_CHANGE_PW_STEPS,
  verifyPinRequest,
  resendPinRequest,
  resetPwRequest,
  setChangePwStep,
} from 'interfaces/global/store/modules/auth/actions';
import {
  SEND_SMS_STEP,
  VERIFY_SMS_CODE_STEP,
  CHANGE_PW_STEP,
  BEGINNING_STEP,
} from 'interfaces/global/store/modules/auth/helpers';
import { RESET_PASSWORD_MODE } from 'api/uAPI/requestSMSCode';
import { track } from '../../tracking/actions';

// SMS PIN handlers
// (re)send pin SMS
export function* handleResendPin({
  username,
  phoneNumber,
  areaCode,
  waitDuration,
}) {
  try {
    let phone = phoneNumber;

    if (!phone) {
      if (/^\d+$/.test(username)) {
        phone = `+${areaCode}${username}`;
      } else {
        phone = username;
      }
    }

    yield call(requestSMSCode, {
      phoneNumber: phone,
      mode: RESET_PASSWORD_MODE,
    });

    yield put({
      type: RESEND_PIN_SUCCESS,
      status: SMS_CODE_RESENT,
      username,
    });
    yield delay(waitDuration);
    yield put({ type: RESEND_PIN_RELEASE });
  } catch (error) {
    yield put({
      type: RESEND_PIN_FAILURE,
      meta: {
        type: 'error',
        message: error.message,
        data: 'llm.getUserEmailOrMobile',
      },
    });
  }
}

// submit pin
export function* handleSubmitPin({ phoneNumber, username, smsCode, areaCode }) {
  let phone = phoneNumber;

  if (!phone) {
    if (/^\d+$/.test(username)) {
      phone = `+${areaCode}${username}`;
    } else {
      phone = username;
    }
  }

  try {
    const verifyToken = yield call(verifyForgotPasswordSMScode, {
      phoneNumber: phone,
      smsCode,
    });

    yield put({
      type: VERIFY_PIN_SUCCESS,
      verifyToken,
    });
  } catch (error) {
    yield put({
      type: VERIFY_PIN_FAILURE,
      meta: {
        type: 'error',
        message: error.message,
        data: 'llm.getPendingSmsUserByUuid',
      },
    });
  }
}

// reset password
export function* handleResetPw({
  verifyToken,
  phoneNumber,
  username,
  password,
  areaCode,
}) {
  let phone = phoneNumber;

  if (!phone) {
    if (/^\d+$/.test(username)) {
      phone = `+${areaCode}${username}`;
    } else {
      phone = username;
    }
  }

  try {
    yield call(changePassword, {
      phoneNumber: phone,
      verifyToken,
      password,
    });

    yield put({
      type: RESET_PW_SUCCESS,
      status: CODE_PASSWORD_RESET,
      meta: {
        type: 'success',
        message: 'ForgotPassword.password_changed',
      },
    });
  } catch (error) {
    yield put({
      type: RESET_PW_FAILURE,
      meta: {
        type: 'error',
        message: error.message,
        data: 'llm.setNewPasswordByEmail',
      },
    });
  }
}

// change password handlers
// to reuse the SMS PIN flow, create handlers for XXX_VERIFY_PIN_INIT, XXX_RESEND_PIN_INIT, XXX_RESET_PW_INIT
// that will trigger VERIFY_PIN_REQUEST, RESEND_PIN_REQUEST, RESET_PW_REQUEST to init the SMS PIN flow
// UI components can create loading selectors for 'VERIFY_PIN', 'RESEND_PIN', 'RESET_PW'
export function* handleChangePwResendPin({ phoneNumber, waitDuration }) {
  yield put(
    resendPinRequest({
      phoneNumber,
      waitDuration,
    })
  );
  const action = yield take([RESEND_PIN_SUCCESS, RESEND_PIN_FAILURE]);
  if (action.type === RESEND_PIN_SUCCESS) {
    const step = yield select(getChangePwStep);
    if (step === SEND_SMS_STEP)
      yield put(setChangePwStep({ step: VERIFY_SMS_CODE_STEP }));
  }
}

export function* handleChangePwSubmitPin({ phoneNumber, smsCode }) {
  yield put(
    verifyPinRequest({
      phoneNumber,
      smsCode,
    })
  );
  const action = yield take([VERIFY_PIN_SUCCESS, VERIFY_PIN_FAILURE]);
  if (action.type === VERIFY_PIN_SUCCESS) {
    yield put(setChangePwStep({ step: CHANGE_PW_STEP }));
  }
}

export function* handleChangePwResetPw({ verifyToken, phoneNumber, password }) {
  yield put(resetPwRequest({ verifyToken, phoneNumber, password }));
  const action = yield take([RESET_PW_SUCCESS, RESET_PW_FAILURE]);
  if (action.type === RESET_PW_SUCCESS) {
    yield put({ type: RESET_CHANGE_PW_STEPS });
    yield put(setChangePwStep({ step: BEGINNING_STEP }));
    yield put(track('password_updated'));
  }
}

// forgot password handlers
export function* handleForgotPwResendPin({ username, areaCode, waitDuration }) {
  yield put(
    resendPinRequest({
      username,
      areaCode,
      waitDuration,
    })
  );
}

export function* handleForgotPwSubmitPin({ username, smsCode, areaCode }) {
  yield put(verifyPinRequest({ username, smsCode, areaCode }));
  const action = yield take([VERIFY_PIN_SUCCESS, VERIFY_PIN_FAILURE]);
  if (action.type === VERIFY_PIN_SUCCESS) {
    yield put(push('/reset-password'));
  }
}

export function* handleForgotPwResetPw({
  verifyToken,
  username,
  password,
  areaCode,
}) {
  yield put(resetPwRequest({ verifyToken, username, password, areaCode }));
}

// password saga
export function* passwordSaga() {
  // SMS PIN flow
  yield takeEvery(VERIFY_PIN_REQUEST, handleSubmitPin);
  yield takeEvery(RESEND_PIN_REQUEST, handleResendPin);
  yield takeEvery(RESET_PW_REQUEST, handleResetPw);

  // triggers for SMS PIN flow
  // init change password
  yield takeEvery(CHANGE_PW_VERIFY_PIN_INIT, handleChangePwSubmitPin);
  yield takeEvery(CHANGE_PW_RESEND_PIN_INIT, handleChangePwResendPin);
  yield takeEvery(CHANGE_PW_RESET_PW_INIT, handleChangePwResetPw);
  // init forgot password
  yield takeEvery(FORGOT_PW_VERIFY_PIN_INIT, handleForgotPwSubmitPin);
  yield takeEvery(FORGOT_PW_RESEND_PIN_INIT, handleForgotPwResendPin);
  yield takeEvery(FORGOT_PW_RESET_PW_INIT, handleForgotPwResetPw);
}
