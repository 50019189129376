import React, { useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Button } from '@lalamove/karang';

import { noop } from 'utils/helpers';
import { BREAKPOINT } from 'components/MediaQuery';

const Container = styled.footer`
  position: ${({ sticky }) => (sticky === true ? `sticky` : `static`)};
  bottom: ${({ sticky }) => (sticky === true ? `0` : `auto`)};
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 1.7em 1.2em 1.7em 1.2em;
  @media screen and (max-width: ${BREAKPOINT - 1}px) {
    flex-direction: column-reverse;
    padding: 1.14em 1.14em 1.71em 1.14em;
  }
  background-color: ${({ sticky }) =>
    sticky === true && `rgba(250, 250, 250, 1)`};

  & > span.SliderPanelFooter__span-close {
    margin-right: auto;
    margin-left: 0;
  }

  & > span:last-child {
    margin-right: 0;
    margin-left: 14px;
  }

  @media screen and (max-width: ${BREAKPOINT - 1}px) {
    span {
      width: 100%;
    }

    span > button {
      width: 100%;
      height: 3.71em;
      margin-top: 8px;
    }

    & > span:last-child {
      margin-left: 0;
    }
  }
`;

const SliderPanelFooter = ({
  primaryButtonText,
  primaryButtonTooltip,
  secondaryButtonText,
  secondaryButtonTooltip,
  closeButtonText,
  closeButtonTooltip,
  primaryAction,
  secondaryAction,
  closeAction,
  disablePrimaryButton,
  disableSecondaryButton,
  disableCloseButton,
  isLoadingPrimaryButton,
  ...props
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Container {...props}>
      {closeAction !== noop && (
        <span
          className="SliderPanelFooter__span-close"
          data-tip={closeButtonTooltip}
          data-place="top"
          data-effect="solid"
          data-for="global"
        >
          <Button
            variant="link"
            onClick={closeAction}
            disabled={disableCloseButton}
          >
            {closeButtonText}
          </Button>
        </span>
      )}
      {secondaryAction !== noop && (
        <span
          className="SliderPanelFooter__span-secondary"
          data-tip={secondaryButtonTooltip}
          data-place="top"
          data-effect="solid"
          data-for="global"
        >
          <Button
            variant="primary"
            onClick={secondaryAction}
            disabled={disableSecondaryButton}
          >
            {secondaryButtonText}
          </Button>
        </span>
      )}
      {primaryAction !== noop && (
        <span
          className="SliderPanelFooter__span-primary"
          data-tip={primaryButtonTooltip}
          data-place="top"
          data-effect="solid"
          data-for="global"
        >
          <Button
            variant="primary"
            onClick={primaryAction}
            disabled={disablePrimaryButton}
            isLoading={isLoadingPrimaryButton}
            solid
          >
            {primaryButtonText}
          </Button>
        </span>
      )}
    </Container>
  );
};

SliderPanelFooter.propTypes = {
  primaryAction: func,
  secondaryAction: func,
  closeAction: func,
  primaryButtonText: string,
  primaryButtonTooltip: string,
  secondaryButtonText: string,
  secondaryButtonTooltip: string,
  closeButtonText: string,
  closeButtonTooltip: string,
  disablePrimaryButton: bool,
  disableSecondaryButton: bool,
  disableCloseButton: bool,
  isLoadingPrimaryButton: bool,
};

SliderPanelFooter.defaultProps = {
  primaryAction: noop,
  secondaryAction: noop,
  closeAction: noop,
  primaryButtonText: 'Primary',
  primaryButtonTooltip: '',
  secondaryButtonText: 'Secondary',
  secondaryButtonTooltip: '',
  closeButtonText: 'Close',
  closeButtonTooltip: '',
  disablePrimaryButton: false,
  disableSecondaryButton: false,
  disableCloseButton: false,
  isLoadingPrimaryButton: false,
};

export default SliderPanelFooter;
