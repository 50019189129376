import React, { useState, useEffect } from 'react';
import Downshift from 'downshift';
import { CSSTransition } from 'react-transition-group';

import { caret as CaretIcon } from 'components/Icons';

import { PickerButton, Label, Icon } from './styles';

import ProfileList from './ProfileList';
import useProfileItems, {
  ProfileType,
  ProfileTypeItem,
} from './useProfileItems';

interface ProfilePickerProps {
  isLoading?: boolean;
  profileType: ProfileType;
  onClick: () => void;
  onChange: (profile: ProfileType) => void;
  onLogout: () => void;
}

const ProfilePicker: React.FC<ProfilePickerProps> = ({
  isLoading,
  profileType,
  onChange,
  onClick,
  onLogout,
}) => {
  const profileItems = useProfileItems();
  const [currentProfileType, setCurrentProfileType] = useState(profileType);

  const onSelectItem = (profileItem: ProfileTypeItem | null) => {
    if (!profileItem) {
      return;
    }
    const profile = profileItem.value;
    setCurrentProfileType(profile);
    onChange(profile);
  };

  // update/use internal state currentProfileType for performance
  useEffect(() => {
    setCurrentProfileType(profileType);
  }, [profileType]);

  return (
    <Downshift
      selectedItem={profileItems.find(
        item => currentProfileType === item.value
      )}
      onChange={onSelectItem}
      itemToString={item => item?.label || ''}
    >
      {({
        getRootProps,
        isOpen,
        selectedItem,
        selectItem,
        toggleMenu,
        closeMenu,
      }) => (
        <PickerButton
          {...getRootProps()}
          disabled={isLoading}
          onClick={() => toggleMenu(undefined, onClick)}
        >
          <Icon>{selectedItem?.icon}</Icon>
          <Label>{selectedItem?.label}</Label>
          <CaretIcon size={12} />

          <CSSTransition unmountOnExit in={isOpen} timeout={200}>
            <ProfileList
              profileItems={profileItems}
              profile={currentProfileType}
              onSelectItem={item => selectItem(item, undefined, closeMenu)}
              onLogout={onLogout}
            />
          </CSSTransition>
        </PickerButton>
      )}
    </Downshift>
  );
};

export default ProfilePicker;
