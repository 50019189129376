import React from 'react';
import createSVGIcon from '@lalamove/karang/dist/components/Icon/icons/utils/createSVGIcon';

const business = createSVGIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.18446 3.26795C6.41895 3.03346 6.73857 2.91675 7.08325 2.91675H12.9166C13.2613 2.91675 13.5809 3.03346 13.8154 3.26795C14.0499 3.50244 14.1666 3.82206 14.1666 4.16675V5.41675H17.4999C17.73 5.41675 17.9166 5.6033 17.9166 5.83342V8.75008V16.6667C17.9166 16.8969 17.73 17.0834 17.4999 17.0834H2.49992C2.2698 17.0834 2.08325 16.8969 2.08325 16.6667V8.75008V5.83342C2.08325 5.6033 2.2698 5.41675 2.49992 5.41675H5.83325V4.16675C5.83325 3.82206 5.94997 3.50244 6.18446 3.26795ZM2.91659 10.9332V16.2501H17.0833V10.9332C16.468 11.6344 15.569 12.0834 14.5833 12.0834H12.0833V12.5001C12.0833 12.7302 11.8967 12.9167 11.6666 12.9167H8.33325C8.10313 12.9167 7.91659 12.7302 7.91659 12.5001V12.0834H5.41659C4.43087 12.0834 3.53189 11.6344 2.91659 10.9332ZM14.5833 11.2501H12.0833V10.8334C12.0833 10.6033 11.8967 10.4167 11.6666 10.4167H8.33325C8.10313 10.4167 7.91659 10.6033 7.91659 10.8334V11.2501H5.41659C4.06337 11.2501 2.91659 10.1033 2.91659 8.75008V6.25008H17.0833V8.75008C17.0833 10.1033 15.9365 11.2501 14.5833 11.2501ZM6.77371 3.85721C6.71654 3.91438 6.66658 4.01143 6.66658 4.16675V5.41675H13.3333V4.16675C13.3333 4.01143 13.2833 3.91438 13.2261 3.85721C13.1689 3.80003 13.0719 3.75008 12.9166 3.75008H7.08325C6.92794 3.75008 6.83089 3.80003 6.77371 3.85721ZM8.74992 11.2501H11.2499V12.0834H8.74992V11.2501Z"
      fill="#585858"
    />
  </g>,
  'BusinessProfile',
  '0 0 20 20'
);

export default business;
