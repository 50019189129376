import Transaction from 'models/Transaction';

import { LalamoveApiError } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface DigitalCurrencyTransaction {
//   amount: number;
//   balance: number;
//   orderId?: string;
//   orderSubset?: string;
//   orderby: string | null;
//   ordertime: number | null; // unix time
//   time: number; // unix time
//   title: string;
//   transactionID: string;
//   type: string;
// }
// interface DigitalCurrencyOrder {
//   time: number; // unix time
//   transactions: DigitalCurrencyTransaction[];
// }

// interface CashOrder {
//   order_amount: number;
//   order_by: string;
//   order_date: string; // unix time
//   order_id: string;
//   order_status: string;
// }

// interface Response {
//   credit_remain: number;
//   filter: Record<string, string>;
//   in_progress_count: number;
//   order: DigitalCurrencyOrder[] | CashOrder[];
//   record_count: number;
//   reward_remain: number;
// }

// interface Params {
//   type?: string; // Transaction.METHOD
//   current?: number; // current page number
//   max?: number; // number of rows per page
//   start?: string; // filter start date (ISO YYYY-MM-DD)
//   end?: string; // filter end date (ISO YYYY-MM-DD)
//   search?: string; // filter by client name
// }
/**
 * Fetches wallet history
 *
 * @param {Object} options
 * @param {string} options.type - history type, i.e. [HISTORY, REWARDS, CASH]
 * @param {number} options.current - current page number
 * @param {number} options.max - number of rows per page
 * @param {string} options.start - filter start date (ISO YYYY-MM-DD)
 * @param {string} options.end - filter end date (ISO YYYY-MM-DD)
 * @return {Promise<Response>} result - resolves to
 * @throws ERROR_INVALID_SESSION, ERROR_INVALID_PARAMETERS, ERROR_OUTOFF_DATERANGE, ...
 */
export default async function fetchWalletRecords({
  method = 'credit',
  current = 1,
  max = 10,
  start = new Date().toISOString().split('T')[0],
  end = new Date().toISOString().split('T')[0],
  search = '',
}) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  const methodMap = {
    [Transaction.METHOD_CREDIT]: 'HISTORY',
    [Transaction.METHOD_REWARD]: 'REWARDS',
    [Transaction.METHOD_CASH]: 'CASH',
  };
  await FetcherLockService.langMutex.release();
  await FetcherLockService.accessTokenMutex.release();
  const { city, locale, accessToken, clientId } = await fetcher.getState();
  const params = {
    is_driver: false,
    access_token: accessToken,
    client_id: clientId,
    country: city,
    lang: locale,
    type: methodMap[method],
    offset: (current - 1) * max,
    require: max,
    startdate: start,
    enddate: end,
    filter_by_date: 1,
    ...(search && { search: [search] }),
  };
  const { success, data, code } = await fetcher.get('vanwalletrecords', params);

  if (!success) throw new LalamoveApiError(code);
  return data;
}
