import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import LightBulb from 'assets/svg/discount-light-bulb.svg';
import CloseTooltipX from 'assets/svg/discount-tooltip-close.svg';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { disableOnlineCouponTooltip } from 'interfaces/global/store/modules/pricing/actions';
import { Coupon } from 'interfaces/global/store/modules/pricing/types';
import useDescription from './useDescription';

export const TOOLTIP_CLOSE_TIMEOUT = 4000; // in milliseconds

// CSS hack to move the tooltip's "tip" or triangle pointing to the target HTML element to the left to satisfy the PRD appearance requirements
const StyledToolTip = styled(ReactTooltip)`
  padding: 0.8rem;

  ::after,
  ::before {
    left: 20% !important;
    @media only screen and (max-width: 1167px) {
      transform: rotate(90deg);
      top: -5px !important;
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.3rem;
    pointer-events: auto;

    & :not(:last-child) {
      padding-right: 0.8rem;
    }
  }
`;

const Container = styled.div``;

export const Description = styled.span`
  font-size: 1rem;
`;

const CloseButton = styled.span`
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  background-image: url(${CloseTooltipX});
  background-position: center;
  background-size: 0.8rem;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export interface OrderCouponTooltipProps {
  tooltipTargetHTMLElement: HTMLElement | null;
  bestOnlinePaymentOnlyCoupon: Coupon | null;
  noAvailableCouponForCash: boolean;
}

const OrderCouponTooltip: React.FC<OrderCouponTooltipProps> = ({
  tooltipTargetHTMLElement,
  bestOnlinePaymentOnlyCoupon,
  noAvailableCouponForCash,
}) => {
  // Suggested way to make a custom close button in the tooltip work: https://github.com/wwayne/react-tooltip/issues/449
  const tooltipRef = useRef<{ tooltipRef: null } | null>(null);

  const timer = useRef(0);

  const {
    enableOnlinePaymentTooltip,
  }: {
    enableOnlinePaymentTooltip: boolean;
  } = useSelector((state: any) => state.pricing);
  const dispatch = useDispatch();

  const clearTimeout = useCallback(() => {
    window.clearTimeout(timer.current);
    timer.current = 0;
  }, []);

  const hideTooltip = useCallback(() => {
    clearTimeout();

    const { current } = tooltipRef;
    if (current) {
      current.tooltipRef = null;
    }
    ReactTooltip.hide();
  }, [clearTimeout]);

  const onClose = useCallback(() => {
    hideTooltip();
    dispatch(disableOnlineCouponTooltip());
  }, [dispatch, hideTooltip]);

  // Logic to show/hide tooltip
  useEffect(() => {
    if (
      enableOnlinePaymentTooltip &&
      noAvailableCouponForCash &&
      bestOnlinePaymentOnlyCoupon &&
      tooltipTargetHTMLElement
    ) {
      // No need to show if already shown
      if (!timer.current) {
        ReactTooltip.show(tooltipTargetHTMLElement);
        timer.current = window.setTimeout(onClose, TOOLTIP_CLOSE_TIMEOUT);
      }
    } else {
      hideTooltip();
    }
  }, [
    noAvailableCouponForCash,
    enableOnlinePaymentTooltip,
    bestOnlinePaymentOnlyCoupon,
    onClose,
    tooltipTargetHTMLElement,
    hideTooltip,
  ]);

  // Clean up tooltip timer when componentDidUnmount
  useEffect(() => clearTimeout, [clearTimeout]);

  const description = useDescription(bestOnlinePaymentOnlyCoupon);

  const overridePosition = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { y, height } = tooltipTargetHTMLElement!.getBoundingClientRect();
    return { left: 70, top: y + height }; // TODO: change 100 into the left position of Coupon label + its width
  };

  return (
    <StyledToolTip
      id="checkout-tool-tip"
      effect="solid"
      event="untriggerable" // Hack to disable the mouseover and click events from showing the tooltip ("untriggerable" is not an actual event)
      place="bottom"
      overridePosition={overridePosition}
      ref={tooltipRef}
    >
      <Container>
        <img src={LightBulb} alt="Lightbulb" />
        <Description>{description}</Description>
        <CloseButton onClick={onClose} />
      </Container>
    </StyledToolTip>
  );
};
export default OrderCouponTooltip;
