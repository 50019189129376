import { LalamoveApiError } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Response {
//   data: {
//     id: String,
//   };
//   footprint: String;
//   success: Boolean;
//   tips: Number;
// }
/**
 * Update Priority Fee by Order Id
 * @param {string} orderId order ID
 * @param {integer} amount accumulated amounts added for priority fee
 * @return {promise} resolve to a response object
 * @throws ERROR_INVALID_SESSION, ERROR_INVALID_PARAMETERS, ERROR_INVALID_STATUS, ERROR_INVALID_TIPS...
 */
export default async function updatePriorityFee(orderId, amount) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, city } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    country: city,
    order_id: orderId,
    tips: amount,
  };
  const res = await fetcher.post('vanaddtips', params);
  const { success, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return res;
}
