import React, { PureComponent } from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Dropdown } from '@lalamove/karang';
import { noop } from 'utils/helpers';

const NUM_DAYS = 30;

const SCDropdown = styled(Dropdown)`
  > button {
    height: 100%;
  }
  > ul {
    overflow: scroll;
    max-height: 264px;
  }
`;

function generateDays(datetime) {
  return [...Array(NUM_DAYS).keys()].map((_, i) => {
    const m = moment(datetime).add(i, 'days');
    return {
      label: m.calendar(),
      value: m.toISOString(true) || '',
    };
  });
}

function parseDateOnly(datetime) {
  return datetime && datetime.split('T')[0];
}

class OrderDatePicker extends PureComponent {
  static defaultProps = {
    defaultDatetime: '',
    selectedDate: '',
    onChange: noop,
  };

  static propTypes = {
    defaultDatetime: string,
    selectedDate: string,
    onChange: func,
  };

  state = { items: generateDays(this.props.defaultDatetime) };

  componentDidUpdate(prevProps) {
    // syncing time part
    const { selectedDate, defaultDatetime } = this.props;
    if (prevProps.selectedDate !== selectedDate) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ items: generateDays(defaultDatetime) });
    }
  }

  handleChange = ({ value }) => {
    // changing date always makes the order become scheduled:
    this.props.onChange(value, true);
  };

  render() {
    const { selectedDate } = this.props;
    const { items } = this.state;

    function parseSelected(date) {
      return items.reduce((cur, next) =>
        parseDateOnly(cur.value) === parseDateOnly(date) ? cur : next
      );
    }

    return (
      <SCDropdown
        block
        selectedItem={parseSelected(selectedDate)}
        items={items}
        onChange={this.handleChange}
      />
    );
  }
}

export default OrderDatePicker;
