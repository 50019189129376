import { DELIVERY_INFO_UPDATE, IMPORT_SUCCESS, WAYPOINT_NEW } from '../actions';

// state
export const initState = {};

// reducer
export default function reducer(state = initState, action) {
  switch (action.type) {
    case WAYPOINT_NEW: {
      return {
        ...state,
        [action.id]: {
          name: '',
          phone: '',
          addressDetails: '',
        },
      };
    }
    case IMPORT_SUCCESS: {
      return {
        ...state,
        ...action.deliveryInfo,
      };
    }
    case DELIVERY_INFO_UPDATE: {
      const info = state[action.id] || {};
      const updatedInfo = {
        ...info,
        ...action.info,
      };
      return { ...state, [action.id]: updatedInfo };
    }

    default:
      return state;
  }
}
