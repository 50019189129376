import { toLLMLower } from 'utils/locale';
import { UMETAapiError } from 'utils/helpers';
import moment from 'moment';
import UMETAFetcher from 'api/UMETAFetcher';
import FetcherFactory from 'api/FetcherFactory';

const getCityMetadata = async () => {
  const fetcher = FetcherFactory.make(UMETAFetcher);
  const { cityId, countryId, locale } = await fetcher.getState();

  const params = {
    args: JSON.stringify({}),
    os: 'web',
    _t: moment().unix(),
    city_id: cityId,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    revision: 6501,
    version: '6.5.1',
  };

  const { ret, data, msg } = await fetcher.get('android_user.php', params);

  if (ret !== 0) throw new UMETAapiError(msg, ret);

  return data;
};
export default getCityMetadata;
