import React from 'react';
import { arrayOf, bool } from 'prop-types';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { darkGray, orange, silver, white, gray } from 'styles/colors';
import { WaypointShape, DeliveryInfoShape } from 'views/Records/propTypes';

const List = styled.ul`
  position: relative;
  padding: 0;
  list-style: none;
  counter-reset: stop-item;

  &:before {
    content: '';
    position: absolute;
    top: 1.5em;
    bottom: 1.5em;
    left: 9px;
    border-left: 2px dotted ${orange};
  }
`;

const ListItem = styled.li`
  position: relative;
  padding-left: 28px;
`;

const ItemContainer = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid ${darkGray};
  line-height: 1.4;

  &:before {
    counter-increment: stop-item;
    content: counter(stop-item);
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    background-color: ${({ cancelled }) => (cancelled ? darkGray : orange)};
    color: ${({ cancelled }) => (cancelled ? gray : white)};
    font-size: 0.75em;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 0 2px ${white};
    transform: translate(-28px, -10px);
  }
`;

const DeliveryInfo = styled.div`
  color: ${silver};
  font-size: 0.8em;
`;

const Route = ({ waypoints, deliveryInfo: infos, cancelled }) => (
  <List>
    {waypoints.map((waypoint, i) => (
      <ListItem key={uuid()}>
        <ItemContainer cancelled={cancelled}>
          <div>{waypoint.name}</div>
          {Boolean(infos) && Boolean(infos[i]) && (
            <DeliveryInfo>
              {[infos[i].name, infos[i].phone, infos[i].addressDetails]
                .filter(Boolean)
                .join(' | ')}
            </DeliveryInfo>
          )}
        </ItemContainer>
      </ListItem>
    ))}
  </List>
);

Route.propTypes = {
  waypoints: arrayOf(WaypointShape).isRequired,
  deliveryInfo: arrayOf(DeliveryInfoShape),
  cancelled: bool,
};

Route.defaultProps = {
  deliveryInfo: null,
  cancelled: false,
};

export default Route;
