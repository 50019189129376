import forgotPasswordReducer, {
  initState as forgotPasswordInitState,
} from './forgotPassword';
import registerReducer, { initState as registerInitState } from './register';
import verificationReducer, {
  initState as verificationInitState,
} from './verification';

// action-types
import * as actions from './actions';

export { actions };

// state
export const initState = {
  forgotPassword: forgotPasswordInitState,
  register: registerInitState,
  verification: verificationInitState,
};

// reducer
export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.RESEND_PIN_SUCCESS:
    case actions.RESEND_PIN_RELEASE:
    case actions.RESET_PW_SUCCESS:
    case actions.VERIFY_PIN_SUCCESS:
    case actions.RESET_PW_FAILURE:
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        forgotPassword: forgotPasswordReducer(state.forgotPassword, action),
      };
    case actions.REGISTER_SUCCESS:
    case actions.FB_LOGIN_CANCEL:
    case actions.FB_LOGIN_SUCCESS:
    case actions.FB_USER_DATA_RECEIVED:
      return {
        ...state,
        register: registerReducer(state.register, action),
      };
    case actions.VERIFY_SUCCESS:
    case actions.RESEND_SUCCESS:
    case actions.RESEND_LOCKING:
    case actions.RESEND_RELEASE:
      return {
        ...state,
        verification: verificationReducer(state.verification, action),
      };
    default:
      return state;
  }
}
