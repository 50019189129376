import React from 'react';
import { node, string } from 'prop-types';
import styled from 'styled-components';

import { orange } from 'styles/colors';

const StyledSection = styled.section`
  display: flex;
  margin: 1em 0;
`;

const IconWrapper = styled.div`
  margin: 0 0.5em 0 0;
  color: ${orange};
`;

const Content = styled.div`
  flex-shrink: 1;
  width: 100%;
`;

const Heading = styled.h3`
  margin: 0 0 0.5em 0;
`;

const Section = ({ icon, title, children, ...props }) => (
  <StyledSection {...props}>
    {icon && <IconWrapper>{icon}</IconWrapper>}
    <Content>
      <Heading>{title}</Heading>
      {children}
    </Content>
  </StyledSection>
);

Section.defaultProps = {
  icon: null,
  children: null,
};

Section.propTypes = {
  icon: node,
  title: string.isRequired,
  children: node,
};

export default Section;
