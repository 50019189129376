import Segment from 'utils/segment';

import { PREFERENCES_UPDATE_SUCCESS } from './actionTypes';

export const SEGMENT_CLICK_SETTING = 'Settings Tapped';
export const SEGMENT_POD_UPDATED = 'POD Updated';

const settingsTracking = (prevState, action, nextState) => {
  switch (action.type) {
    case PREFERENCES_UPDATE_SUCCESS: {
      const { pod } = action;
      if (typeof pod !== 'undefined') {
        Segment.createTrack(SEGMENT_POD_UPDATED, { status: pod });
      }
      break;
    }
    default:
  }
};

export default settingsTracking;
