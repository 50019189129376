import { take, put, takeLatest, call, select } from 'redux-saga/effects';
import { sessionService } from 'redux-react-session';
import { login, loginEmail, getUserInfo, socialLogin } from 'api/uAPI';
import {
  GOOGLE_LOGIN_SECRET_TYPE,
  GOOGLE_LOGIN_SOURCE,
  FACEBOOK_LOGIN_SOURCE,
  FACEBOOK_LOGIN_SECRET_TYPE,
} from 'api/uAPI/socialLogin';
import { replace } from 'connected-react-router';
import validator from 'utils/validator';

import {
  login as loginTrack,
  logout,
  setProfile,
  track,
} from 'interfaces/global/store/modules/tracking/actions';

// action-types
import {
  REQUEST_LOGOUT,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REFRESH_LOGIN_SESSION_REQUEST,
  refreshLoginSessionSuccess,
  refreshLoginSessionFailure,
} from 'store/modules/auth/actions';

import {
  FB_LOGIN_REQUEST,
  FB_LOGIN_SUCCESS,
  FB_LOGIN_FAILURE,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_FAILURE,
  GOOGLE_LOGIN_SUCCESS,
  fbUserDataReceived,
  googleLoginProfileReceived,
  storeSignedProfile,
  enableActiveFetchProfile,
  disableActiveFetchProfile,
} from 'interfaces/global/store/modules/auth/actions';
import { mergeProfile } from 'interfaces/global/store/modules/auth/helpers';

import { UPDATE_CITY_SETTING } from 'store/modules/region/actions';

import { branchLogEvent, UApiError } from 'utils/helpers';
import { getUser } from '../selectors';

const { REACT_APP_DEBUG } = process.env;

// side effects
export function* onLogin({ username, password, areaCode }) {
  try {
    // Validate email
    if (!/^\d+$/.test(username)) {
      const { valid, message } = validator.email(username);
      if (!valid) {
        throw new UApiError(message, 30001);
      }
    }

    const data = yield call(authenticate, {
      username,
      password,
      areaCode,
    });

    yield call([sessionService, 'saveSession'], data.access_token);
    yield call([sessionService, 'saveUser'], data);
    yield put(enableActiveFetchProfile());

    // sensors tracking
    yield put(loginTrack());
    yield put(setProfile());
    yield put(track('logged_in', { type: 'normal' }));
    branchLogEvent('LOGIN');

    // this will trigger dynamic redux modules remounts,
    // which must place after all actions
    yield put({ type: LOGIN_SUCCESS });
  } catch (err) {
    const { errorCode, message } = err;

    yield put({
      type: LOGIN_FAILURE,
      meta: {
        type: 'error',
        message,
        errorCode,
      },
    });
  }
}

export function* onGoogleLogin({ areaCode, authInstance }) {
  try {
    const { code } = yield call(authInstance.grantOfflineAccess, {
      scope: 'profile email openid',
    });

    const response = yield call(socialLogin, {
      socialSource: GOOGLE_LOGIN_SOURCE,
      socialSecret: code,
      socialSecretType: GOOGLE_LOGIN_SECRET_TYPE,
    });

    // Account do not exists
    if (response.result === 1) {
      yield put(storeSignedProfile({ signedProfile: response.signed_profile }));

      const profile = JSON.parse(response.signed_profile)[0];
      profile.email = response.email;
      profile.socialSource = GOOGLE_LOGIN_SOURCE;
      yield put(googleLoginProfileReceived(profile));

      yield put({ type: GOOGLE_LOGIN_FAILURE });
      yield put(replace('/account'));
    } else {
      // eslint-disable-next-line camelcase
      const { token, is_ep, fid, sig } = response;

      const profile = yield call(getUserInfo, token, is_ep);

      const dataToSave = {
        access_token: token,
        account_type: 'GOOGLE',
        is_ep,
        profile_type: is_ep,
        user_fid: fid,
        sig,
        profile: {
          ...profile,
          country_code: areaCode,
        },
      };

      yield call([sessionService, 'saveSession'], token);
      yield call([sessionService, 'saveUser'], dataToSave);
      yield put(enableActiveFetchProfile());

      yield put(replace('/'));

      // sensors tracking
      yield put(loginTrack());
      yield put(setProfile());
      yield put(track('logged_in', { type: 'google' }));
      branchLogEvent('LOGIN');

      // this will trigger dynamic redux modules remounts,
      // which must place after all actions
      yield put({ type: GOOGLE_LOGIN_SUCCESS });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    REACT_APP_DEBUG && console.error(e);
    yield put({ type: GOOGLE_LOGIN_FAILURE });
  }
}

export function* onFBLoginAccountVerification(user) {
  yield put({ type: FB_LOGIN_FAILURE });
  if (user) {
    // no llm account is associated with this fb account
    const userWithSource = {
      ...user,
      socialLoginSource: FACEBOOK_LOGIN_SOURCE,
    };

    yield put(fbUserDataReceived(userWithSource));
    yield put(replace('/account'));
  }
}

export function* onFBLogin({ areaCode, accessToken, user }) {
  try {
    const data = yield call(socialLogin, {
      socialSecretType: FACEBOOK_LOGIN_SECRET_TYPE,
      socialSecret: accessToken,
      socialSource: FACEBOOK_LOGIN_SOURCE,
    });

    if (data.result === 1) {
      // Then this is a new user that must first register
      yield put(storeSignedProfile({ signedProfile: data.signed_profile }));
      yield call(onFBLoginAccountVerification, user);
    } else {
      // Then result is 0, which means the user is logged in
      // eslint-disable-next-line camelcase
      const { token, is_ep, fid, sig } = data;

      const profile = yield call(getUserInfo, token, is_ep);

      const dataToSave = {
        access_token: token,
        account_type: 'FACEBOOK',
        social_login_id: user.id,
        is_ep,
        profile_type: is_ep,
        user_fid: fid,
        sig,
        profile: {
          ...profile,
          country_code: areaCode,
        },
      };

      yield call([sessionService, 'saveSession'], token);
      yield call([sessionService, 'saveUser'], dataToSave);
      yield put(enableActiveFetchProfile());

      yield put(replace('/'));

      // sensors tracking
      yield put(loginTrack());
      yield put(setProfile());
      yield put(track('logged_in', { type: 'facebook' }));
      branchLogEvent('LOGIN');

      // this will trigger dynamic redux modules remounts,
      // which must place after all actions
      yield put({ type: FB_LOGIN_SUCCESS });
    }
  } catch (err) {
    yield call(onFBLoginAccountVerification, user);
  }
}

export function* authenticate({ username, password, areaCode }) {
  const isEmailLogin = !/^\d{2,}$/.test(username);
  let data;

  if (isEmailLogin) {
    data = yield call(loginEmail, username, password);
  } else {
    data = yield call(login, username, password, areaCode);
  }

  const profile = yield call(getUserInfo, data.token, data.is_ep);

  return {
    ...data,
    access_token: data.token,
    profile_type: data.is_ep,
    profile: {
      ...profile,
      country_code: areaCode,
    },
  };
}

export function* onRefreshLoginSession() {
  const user = yield select(getUser);
  const { access_token: accessToken = '', profile_type: profileType } =
    user || {};
  if (!accessToken) {
    yield put(refreshLoginSessionFailure());
    return;
  }

  try {
    const newProfile = yield call(getUserInfo, accessToken, profileType);
    yield call([sessionService, 'saveUser'], {
      ...user,
      profile: mergeProfile(user.profile, newProfile),
    });
    yield put(refreshLoginSessionSuccess());
    yield put(enableActiveFetchProfile());

    // For tracking, wait for umeta returns datacenter field first
    yield take(UPDATE_CITY_SETTING);
    yield put(loginTrack());
    yield put(setProfile());
  } catch (error) {
    yield put(refreshLoginSessionFailure());
    console.error('Refresh Login Session: ', error); // eslint-disable-line no-console
  }
}

function* onLogout() {
  yield call([sessionService, 'deleteSession']);
  yield call([sessionService, 'deleteUser']);
  yield put(disableActiveFetchProfile());
  yield put(logout());
}

export function* loginSaga() {
  yield takeLatest(LOGIN_REQUEST, onLogin);
  yield takeLatest(FB_LOGIN_REQUEST, onFBLogin);
  yield takeLatest(REFRESH_LOGIN_SESSION_REQUEST, onRefreshLoginSession);
  yield takeLatest([REQUEST_LOGOUT, LOGIN_FAILURE], onLogout);
  yield takeLatest(GOOGLE_LOGIN_REQUEST, onGoogleLogin);
}
