import React from 'react';
import { bool, func, number } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Heading } from '@lalamove/karang';
import {
  cashHKD as CashIcon,
  wallet as WalletIcon,
} from '@lalamove/karang/dist/components/Icon/icons';
import { PriceBreakDownShape } from 'interfaces/global/views/Records/propTypes';
import { PaymentMethods } from 'interfaces/global/store/modules/checkout/types';
import { orange } from 'styles/colors';
import formatter from 'utils/formatter';
import { noop } from 'utils/helpers';

const Container = styled.div`
  padding: 0;
`;

const MajorRow = styled.div`
  padding: 8px 0;
`;

export const Row = styled.div`
  padding: 4px 0;
`;

const Label = styled.span`
  display: inline-block;
  width: 70%;
  text-align: left;
`;

const Value = styled.span`
  display: inline-block;
  width: 30%;
  text-align: right;
`;

const Payment = styled(Value)`
  font-size: 10px;
`;

const StyledIcon = c => styled(c)`
  &[style] {
    vertical-align: bottom !important;
  }
  margin-right: 4px;
`;

const TotalValue = styled(Value)`
  font-size: 18px;
  font-weight: bold;
`;

const TotalLabel = styled(Label)`
  font-size: 18px;
  font-weight: bold;
`;

const paymentIcons = {
  [PaymentMethods.CASH.id]: StyledIcon(CashIcon),
  [PaymentMethods.ONLINE.id]: StyledIcon(WalletIcon),
};

const paymentTranslations = {
  [PaymentMethods.CASH.id]: 'RecordPanel.label_payment_cash',
  [PaymentMethods.ONLINE.id]: 'RecordPanel.label_payment_online',
};

const renderBreakdownDetails = items =>
  items.map(item => (
    <Row key={`row.${item.name}`}>
      <Label>{item.name}</Label>
      <Value>{formatter.currency(item.value)}</Value>
    </Row>
  ));

export const Price = ({
  t,
  paymentMethodId,
  price,
  hideZeroItems,
  ...remain
}) => {
  const PaymentIcon = paymentIcons[paymentMethodId];
  const paymentMethodTranslation = paymentTranslations[paymentMethodId];

  return (
    <Container {...remain}>
      <MajorRow>
        <Label>
          <Heading htmlTag="h3">{t(`RecordPanel.heading_price`)}</Heading>
        </Label>
        <Payment>
          <PaymentIcon color={orange} size={12} />
          {t(paymentMethodTranslation)}
        </Payment>
      </MajorRow>
      {renderBreakdownDetails(price.items)}
      {price.total !== undefined && (
        <MajorRow>
          <TotalLabel>{t('Wallet.table_column_title_total_charge')}</TotalLabel>
          <TotalValue>{formatter.currency(price.total)}</TotalValue>
        </MajorRow>
      )}
    </Container>
  );
};

Price.propTypes = {
  t: func,
  paymentMethodId: number.isRequired,
  price: PriceBreakDownShape,
  hideZeroItems: bool,
};

Price.defaultProps = {
  t: noop,
  price: null,
  hideZeroItems: false,
};

export default withTranslation()(Price);
