import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  setChangePwStep,
  changePasswordResendPinRequest,
  changePasswordVerifyPinRequest,
  changePasswordResetPwRequest,
  RESET_CHANGE_PW_STEPS,
} from 'interfaces/global/store/modules/auth/actions';
import {
  getChangePwStep,
  getUser,
} from 'interfaces/global/store/modules/auth/selectors';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import {
  BEGINNING_STEP,
  SEND_SMS_STEP,
  VERIFY_SMS_CODE_STEP,
  CHANGE_PW_STEP,
} from 'interfaces/global/store/modules/auth/helpers';

import { waitDuration } from 'interfaces/global/containers/ForgotPassword';
import { ActionButton } from 'interfaces/global/containers/Settings/style';
import { dismissMessage } from 'store/modules/message';
import SendSMS from './components/SendSMS';
import VerifySMS from './components/VerifySMS';
import PasswordChangeForm from './components/PasswordChangeForm';

const Password = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStep = useSelector(getChangePwStep);
  const verifyToken = useSelector(state => state.auth.password.verifyToken);
  const phoneNumber = useSelector(getUser).profile.phone_no;

  const resetSteps = useCallback(() => {
    dispatch({ type: RESET_CHANGE_PW_STEPS });
    dispatch(dismissMessage('RESET_PW'));
    dispatch(dismissMessage('RESEND_PIN'));
    dispatch(dismissMessage('VERIFY_PIN'));
    dispatch(setChangePwStep({ step: BEGINNING_STEP }));
  }, [dispatch]);

  useEffect(() => {
    resetSteps();
  }, [resetSteps]);

  const handleSubmit = password => {
    dispatch(
      changePasswordResetPwRequest({
        verifyToken,
        phoneNumber,
        password,
      })
    );
  };

  const handleSendSMS = () => {
    dispatch(
      changePasswordResendPinRequest({
        phoneNumber,
        waitDuration,
      })
    );
  };

  const handlePinChanged = smsCode => {
    if (smsCode.length === 4) {
      dispatch(
        changePasswordVerifyPinRequest({
          phoneNumber,
          smsCode,
        })
      );
    }
  };

  const beginChangePassword = () =>
    batch(() => {
      dispatch(setChangePwStep({ step: SEND_SMS_STEP }));
      dispatch(track('change_password_tapped'));
    });

  switch (currentStep) {
    case BEGINNING_STEP:
      return (
        <ActionButton variant="link" onClick={beginChangePassword}>
          {t('Settings.button_change_password')}
        </ActionButton>
      );
    case SEND_SMS_STEP:
      return <SendSMS handleSendSMS={handleSendSMS} />;
    case VERIFY_SMS_CODE_STEP:
      return (
        <VerifySMS
          handleSendSMS={handleSendSMS}
          handlePinChanged={handlePinChanged}
        />
      );
    case CHANGE_PW_STEP:
      return (
        <PasswordChangeForm onSubmit={handleSubmit} onCancel={resetSteps} />
      );
    default:
      return null;
  }
};

export default Password;
