import { UApiError } from 'utils/helpers';

import FetcherFactory from '../FetcherFactory';
import UAPIFetcher from '../UAPIFetcher';

const fetchDriverLocation = async shareId => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const { countryId } = await fetcher.getState();

  const params = {
    hcountry: countryId,
    args: JSON.stringify({ share_id: shareId }),
  };

  const { ret, data, msg } = await fetcher.get('?_m=order_tracking', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data.driverLatestLocation;
};

export default fetchDriverLocation;
