import React from 'react';
import { bool, func, node, shape, string } from 'prop-types';
import styled from 'styled-components';
import Modal from 'react-modal';

import { Button } from '@lalamove/karang';

import { gray, black } from 'styles/colors';
import { ALERT } from 'styles/zIndex';
import { noop } from 'utils/helpers';

const Title = styled.h3`
  margin: 0;
  color: ${black};
  font-size: 1.3em;
  font-weight: bold;
`;

const Subtitle = styled.p`
  margin: 1em 0 2em 0;
  color: ${gray};
  font-size: 1em;
`;

const Panel = styled.div`
  float: right;
  button {
    margin: 0 0.5em;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Dialog = ({
  title,
  subtitle,
  primaryButtonText,
  secondaryButtonText,
  primaryAction,
  secondaryAction,
  disablePrimaryButton,
  disableSecondaryButton,
  isLoadingPrimaryButton,
  isLoadingSecondaryButton,
  shouldCloseOnOverlayClick,
  onRequestClose,
  isOpen,
  style,
  ...props
}) => (
  <Modal
    isOpen={isOpen}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    onRequestClose={onRequestClose}
    style={style}
    appElement={document.querySelector('#🚐')}
    {...props}
  >
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <Panel>
      {secondaryButtonText && (
        <Button
          variant="primary"
          onClick={secondaryAction}
          isLoading={isLoadingSecondaryButton}
          disabled={disableSecondaryButton}
        >
          {secondaryButtonText}
        </Button>
      )}
      <Button
        variant="primary"
        onClick={primaryAction}
        isLoading={isLoadingPrimaryButton}
        disabled={disablePrimaryButton}
        solid
      >
        {primaryButtonText}
      </Button>
    </Panel>
  </Modal>
);

Dialog.propTypes = {
  title: string,
  subtitle: node,
  primaryButtonText: string,
  secondaryButtonText: string,
  primaryAction: func,
  secondaryAction: func,
  onRequestClose: func,
  shouldCloseOnOverlayClick: bool,
  isOpen: bool,
  disablePrimaryButton: bool,
  disableSecondaryButton: bool,
  isLoadingPrimaryButton: bool,
  isLoadingSecondaryButton: bool,
  style: shape({}),
};

Dialog.defaultProps = {
  title: '',
  subtitle: '',
  primaryButtonText: '',
  secondaryButtonText: '',
  primaryAction: noop,
  secondaryAction: noop,
  onRequestClose: noop,
  shouldCloseOnOverlayClick: true,
  isOpen: false,
  disablePrimaryButton: false,
  disableSecondaryButton: false,
  isLoadingPrimaryButton: false,
  isLoadingSecondaryButton: false,
  style: {
    overlay: {
      zIndex: ALERT,
    },
    content: {
      border: 'none',
      borderRadius: '5px',
      padding: '2em',
    },
  },
};

export default Dialog;
