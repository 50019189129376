import routing from './reducer';
import saga from './saga';
import * as selectors from './selectors';
import * as actions from './actions';

const routingModule = {
  id: 'routing',
  reducerMap: { routing },
  sagas: [saga],
};

export { routingModule as default, selectors, actions };
