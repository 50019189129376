import React from 'react';
import { number, string, func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import Bar from 'components/StatusBar';
import { blue, darkBlue, gold, green, red } from 'styles/colors';
import { noop, statusMap } from 'utils/helpers';

const statusColors = {
  [statusMap.ASSIGNING]: gold,
  [statusMap.ONGOING]: blue,
  [statusMap.COMPLETED]: green,
  [statusMap.CANCELLED]: red,
  [statusMap.REJECTED]: red,
  [statusMap.EXPIRED]: red,
  [statusMap.PICKED_UP]: darkBlue,
};

const statusTexts = {
  [statusMap.ASSIGNING]: 'Records.label_status_assigning',
  [statusMap.ONGOING]: 'Records.label_status_in_process',
  [statusMap.COMPLETED]: 'Records.label_status_completed',
  [statusMap.CANCELLED]: 'Records.label_status_cancelled',
  [statusMap.REJECTED]: 'Records.label_status_cancelled',
  [statusMap.EXPIRED]: 'Records.label_status_cancelled',
  [statusMap.PICKED_UP]: 'Records.label_status_picked_up',
};

const middot = '\u00b7';

const StatusBar = ({ status, refId, dateTime, t }) => (
  <Bar
    status={t(statusTexts[status])}
    color={statusColors[status]}
    refId={refId}
    dateTime={moment(dateTime).format(
      `${t('DateTime.date_full')} ${middot} ${t('DateTime.time_24')}`
    )}
  />
);

StatusBar.propTypes = {
  status: number.isRequired,
  refId: string.isRequired,
  dateTime: string.isRequired,
  t: func,
};

StatusBar.defaultProps = {
  t: noop,
};

export default withTranslation()(StatusBar);
