import styled from 'styled-components';

import { CloseButton as LLMCloseButton } from 'components/Button';
import { Input, Button } from '@lalamove/karang';
import { silver, mineShaft } from 'styles/colors';

export const Header = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
`;

export const CloseButton = styled(LLMCloseButton)`
  margin-right: 3px;
`;

export const CouponCodeInput = styled(Input)`
  flex-grow: 1;
  div {
    border-radius: 2px;

    input {
      padding: 7px;
    }
  }
`;

export const NoCouponYet = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h3`
  margin-top: 8px;
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 24px;
`;

export const Description = styled.span`
  max-width: 310px;
  color: ${silver};
  text-align: center;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 40px 24px 40px;

  > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const CancelButton = styled(Button).attrs({ variant: 'link' })``;

export const ApplyButton = styled(Button).attrs({
  variant: 'primary',
  solid: true,
})``;

export const Coupons = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 40px 26px 40px;

  > div {
    margin-top: 16px;
  }
`;

export const CouponsTitle = styled.h4`
  margin: 8px 40px 0 40px;
  color: ${mineShaft[700]};
  font-size: 12px;
`;

export const RedeemCouponContainer = styled.div`
  position: relative;
  padding: 16px 40px 32px 40px;
`;
