import React, { useLayoutEffect } from 'react';

export const useResetScrollOnMount = (): void => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export const withResetScrollOnMount = <P,>(
  Comp: React.ComponentType<P>
): React.FC<P> => (props: P) => {
  useResetScrollOnMount();
  return <Comp {...props} />;
};
