import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';
import { mineShaft, nobel } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { deliveryStatus as status, noop, triggerDownload } from 'utils/helpers';
import signaturePlaceholder from 'assets/signature-placeholder.png';

const { READY_FOR_PICKUP, DROP_OFF_DONE } = status;

const Container = styled.div`
  margin: 1em 0;
  color: ${mineShaft['500']};
  font-size: ${fontSize.small};
`;

const Text = styled.div`
  color: ${mineShaft['700']};
`;

const Image = styled.img`
  max-width: 88px;
  max-height: 50px;
  padding: 1em 0.5em;
  border: 1px solid ${nobel['200']};
  margin: 0.25em 0;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
`;

const POD = ({
  deliveryId,
  deliveryStatus,
  showSignature,
  receiverName,
  signature,
  t,
}) => {
  const handleClick = () =>
    showSignature && triggerDownload(signature, `PoD_${deliveryId}`);
  return (
    <Container>
      {deliveryStatus !== DROP_OFF_DONE && (
        <div>{t('Deliveries.label_signature_requested')}</div>
      )}
      {deliveryStatus === DROP_OFF_DONE && (
        <Fragment>
          <Text>{`${t('RecordPanel.heading_signed_by')} ${receiverName}`}</Text>
          <Image
            src={showSignature ? signature : signaturePlaceholder}
            onClick={handleClick}
            clickable={showSignature}
          />
        </Fragment>
      )}
    </Container>
  );
};

POD.defaultProps = {
  deliveryId: '',
  deliveryStatus: READY_FOR_PICKUP,
  showSignature: false,
  receiverName: '',
  signature: '',
  t: noop,
};

POD.propTypes = {
  deliveryId: string,
  deliveryStatus: string,
  showSignature: bool,
  receiverName: string,
  signature: string,
  t: func,
};

export default withTranslation()(POD);
