import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { arrayOf, bool, func, number, shape } from 'prop-types';
import styled from 'styled-components';
import { Pagination, TabBar, Tab } from '@lalamove/karang';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import { replace } from 'connected-react-router';

import {
  fetchFavoriteDrivers,
  fetchBannedDrivers,
  getFavoriteDrivers,
  getBannedDrivers,
  getFavoriteDriverCount,
  getBannedDriverCount,
} from 'store/modules/drivers';
import {
  SEGMENT_CLICK_DRIVERS,
  SEGMENT_CLICK_FAVORITE_DRIVERS,
  SEGMENT_CLICK_BANNED_DRIVERS,
} from 'store/modules/drivers/tracking';
import { createLoadingSelector } from 'store/modules/loading';
import { openDialog } from 'store/modules/ui';
import { noop, parseUrlParams, encodeQueryData } from 'utils/helpers';
import Segment from 'utils/segment';

import PageHeader from 'components/PageHeader';
import DriverTable from './components/DriverTable';

export const TYPES = {
  FAVORITE: 'favorite',
  BANNED: 'banned',
};
const DEFAULT_LIST_TYPE = TYPES.FAVORITE;
const MAX_ROWS = 10;

// space at bottom for not overlay with zendesk button
const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 73rem;
  max-width: 110rem;
  padding-bottom: 5em;
  margin-right: auto;
  margin-left: auto;
`;

const TabGroup = styled(TabBar)`
  margin: 0 auto 0 2em;
`;

class Drivers extends Component {
  static defaultProps = {
    t: noop,
    drivers: [],
    count: 0,
    fetchFavoriteDrivers: noop,
    fetchBannedDrivers: noop,
    openDialog: noop,
    isLoading: false,
  };

  static propTypes = {
    location: shape({}).isRequired,
    t: func,
    drivers: arrayOf(shape()),
    count: number,
    fetchFavoriteDrivers: func,
    fetchBannedDrivers: func,
    openDialog: func,
    isLoading: bool,
    historyReplace: func.isRequired,
  };

  state = {
    type: DEFAULT_LIST_TYPE,
    current: 1,
    max: MAX_ROWS,
  };

  componentDidMount() {
    const { historyReplace, location } = this.props;
    const { current, max } = parseUrlParams(location.search);
    const [, , type] = location.pathname.split('/');

    // driven state by URL params
    const updatedState = {
      type: Object.values(TYPES).includes(type) ? type : DEFAULT_LIST_TYPE,
      current: current ? +current : 1,
      max: max && max <= MAX_ROWS ? +max : MAX_ROWS,
    };
    this.setState(updatedState);

    if (!location.search || _isEqual(this.state, updatedState)) {
      historyReplace({
        pathname: `/drivers/${updatedState.type}`,
        search: encodeQueryData(_omit(updatedState, ['type'])),
      });
      this.fetchDrivers(updatedState.type);
    }

    // tracking
    Segment.createTrack(SEGMENT_CLICK_DRIVERS);
  }

  componentDidUpdate(prevProps, prevState) {
    const { historyReplace } = this.props;
    const { type, ...state } = this.state;
    if (!_isEqual(prevState, this.state)) {
      historyReplace({
        pathname: `/drivers/${type}`,
        search: encodeQueryData(state),
      });
      this.fetchDrivers(type);
    }
  }

  fetchDrivers = type => {
    const { current, max } = this.state;
    if (type === 'favorite') {
      this.props.fetchFavoriteDrivers({ current, max });
    } else {
      this.props.fetchBannedDrivers({ current, max });
    }
  };

  handleTypeChange = name => {
    this.setState({
      type: name,
      current: 1,
    });

    // tracking
    const eventsMap = {
      [TYPES.FAVORITE]: SEGMENT_CLICK_FAVORITE_DRIVERS,
      [TYPES.BANNED]: SEGMENT_CLICK_BANNED_DRIVERS,
    };
    Segment.createTrack(eventsMap[name]);
  };

  handlePagination = nextPage => {
    this.setState({
      current: nextPage,
    });
  };

  handleRemoveDriver = id => {
    const { type } = this.state;
    this.props.openDialog('DRIVER_REMOVAL', { id, type });
  };

  render() {
    const { t, drivers, count: total, isLoading } = this.props;
    const { type, current, max } = this.state;

    const fromIndex = total ? current * max - max + 1 : 0;
    let toIndex = total ? current * max : 0;
    if (toIndex > total) toIndex = total;

    const pagination = (
      <Pagination
        current={current}
        pageSize={max}
        total={total}
        description={t('Records.pagination', { fromIndex, toIndex, total })}
        onChange={this.handlePagination}
        loading={isLoading}
      />
    );

    return (
      <Container>
        <PageHeader
          title={t('Drivers.title_drivers')}
          tabs={
            <TabGroup
              activeTab={type}
              onTabChange={this.handleTypeChange}
              variant="rounded"
            >
              <Tab name={TYPES.FAVORITE}>{t('Drivers.tab_favorite')}</Tab>
              <Tab name={TYPES.BANNED}>{t('Drivers.tab_banned')}</Tab>
            </TabGroup>
          }
          headerEnd={pagination}
        />
        <DriverTable
          type={type}
          drivers={drivers}
          loading={isLoading}
          onRemoveDriver={this.handleRemoveDriver}
        />
      </Container>
    );
  }
}

const loadingSelector = createLoadingSelector([
  'FETCH_FAVORITE_DRIVERS',
  'FETCH_BANNED_DRIVERS',
]);

const mapState = (state, { match }) => {
  const favorite = match.params.type === 'favorite';
  return {
    drivers: favorite ? getFavoriteDrivers(state) : getBannedDrivers(state),
    count: favorite
      ? getFavoriteDriverCount(state)
      : getBannedDriverCount(state),
    isLoading: loadingSelector(state),
  };
};

export default compose(
  withTranslation(),
  connect(mapState, {
    fetchFavoriteDrivers,
    fetchBannedDrivers,
    openDialog,
    historyReplace: replace,
  })
)(Drivers);
