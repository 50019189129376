import Big from 'big.js';
import { toLLMLower } from 'utils/locale';
import { UApiError, globalOrderStatusMap } from 'utils/helpers';
import { getUser } from 'store/modules/auth/selectors';
import {
  getCurrentCountry,
  getCurrentCity,
  getCurrentLocale,
} from 'store/modules/region/selectors';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// eslint-disable-next-line import/no-anonymous-default-export
export default async function getRecords({
  status,
  max,
  lastId,
  searchOrderDisplayId,
  start,
  end,
}) {
  const fetcher = FetcherFactory.make(UAPIFetcher);

  const state = await fetcher.getRootState();
  const { access_token: accessToken, profile_type: profileType } = getUser(
    state
  );
  const { cityZone } = getCurrentCity(state);
  const { countryId, currencyRate, areaCode } = getCurrentCountry(state);
  const locale = getCurrentLocale(state);

  const params = {
    hcountry: countryId,
    hlang: toLLMLower(locale),
    args: JSON.stringify({
      status,
      last_id: lastId,
      page_size: max,
      search_order_display_id: searchOrderDisplayId,
      daylight_zone: cityZone,
      start_order_datetime: `${start} 00:00:00`,
      end_order_datetime: `${end} 23:59:59`,
    }),
    token: accessToken,
    is_ep: profileType,
    os: 'web',
  };

  const res = await fetcher.get('?_m=order_list_new', params);
  const { ret, msg, data } = res;

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return {
    lastId: data.last_id,
    isEnd: data.is_end,
    orders: Object.values(data.order_base_info).map(rawRecord =>
      parseRecord(rawRecord, currencyRate, areaCode)
    ),
  };
}

function parseRecord(record, currencyRate, areaCode) {
  return {
    order: {
      id: record.order_uuid,
      refId: String(record.order_display_id),
      status: {
        id: record.order_status,
        parentID:
          globalOrderStatusMap[
            Object.keys(globalOrderStatusMap).find(
              key => globalOrderStatusMap[key].id === record.order_status
            )
          ].parentID,
      },
      deliveryDatetime: {
        time: new Date(record.order_datetime_hts).toISOString(),
        daylightSavingsTimeType: record.daylight_type,
      },
      serviceTypeId: record.order_tag,
      userRating: record.user_rating,
      driverId: record.driver?.id,
    },
    route: {
      waypoints: record.addr_info.map(address => ({
        name: address.name,
        placeId: address.place_id,
        lat: address.lat_lon.lat,
        lng: address.lat_lon.lon,
        cityId: address.city_id,
        address: address.addr,
      })),
      deliveryInfo: record.addr_info.map(address => ({
        name: address.contacts_name,
        phone: address.contacts_phone_no,
        addressDetails: address.house_number,
      })),
    },
    price: {
      total: Big(record.price_total_fen).div(currencyRate),
    },
    driver: {
      ...(record.driver?.id && {
        id: record.driver.id,
        name: record.driver.name,
        phone: record.driver.phone_no.replace(`+${areaCode}`, ''),
      }),
    },
  };
}
