import React from 'react';
import { string, node } from 'prop-types';
import styled from 'styled-components';

import { orange, black } from 'styles/colors';

const Wrapper = styled.div`
  display: flex;
  margin: 1.5em 0;
`;

const IconContainer = styled.div`
  margin: 0 4px 0 0;
  color: ${orange};
`;

const Title = styled.h3`
  margin: 0;
  color: ${black};
  font-size: 1.3em;
  font-weight: bold;
`;

const Description = styled.p`
  margin: 0.5em 0;
`;

const Heading = ({ title, description, icon }) => (
  <Wrapper>
    <div>{icon && <IconContainer>{icon}</IconContainer>}</div>
    <div>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
    </div>
  </Wrapper>
);

Heading.propTypes = {
  title: string.isRequired,
  description: string,
  icon: node,
};

Heading.defaultProps = {
  description: null,
  icon: null,
};

export default Heading;
