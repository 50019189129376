import { useDispatch, useSelector } from 'react-redux';

import { createLoadingSelector } from 'store/modules/loading';
import { getShowFooter } from 'interfaces/global/store/modules/pricing/selectors';
import { proceedOrder } from 'interfaces/global/store/modules/checkout/actions';

type OrderType = 'scheduled' | 'immediate';

interface Props {
  showFooter: boolean;
  isLoading: boolean;
  onNext: (orderType: OrderType) => void;
}

const useFooter = (): Props => {
  const showFooter = useSelector(getShowFooter);
  const isLoading = useSelector(
    createLoadingSelector(['PLACE_ORDER', 'VALIDATE_SERVICE_AREA'])
  );

  const dispatch = useDispatch();
  const onNext = (orderType: OrderType) => {
    dispatch(proceedOrder(orderType));
  };

  return { showFooter, isLoading, onNext };
};

export default useFooter;
