import { toLLMLower } from 'utils/locale';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import { UApiError } from 'utils/helpers';
import moment from 'moment';

export default async function getDaylightSavingsTime({ time }) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    cityId,
    countryId,
    locale,
    cityZone,
  } = await fetcher.getState();
  const params = {
    args: JSON.stringify({
      timestamp: time,
      daylight_zone: cityZone,
    }),
    city_id: cityId,
    token: accessToken,
    is_ep: profileType,
    os: 'web',
    hlang: toLLMLower(locale),
    hcountry: countryId,
    _t: moment().unix(),
  };

  const { data, ret, msg } = await fetcher.get(
    'index.php?_m=daylight_zone',
    params
  );

  if (ret !== 0) throw new UApiError(msg, ret);

  return data;
}
