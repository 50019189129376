import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

export default async function fetchUpdateCoupon({ code }) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    countryId,
    locale,
    timezone,
  } = await fetcher.getState();

  const params = {
    os: 'web',
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
    exchange_code: code,
    htzone: timezone,
  };
  const res = await fetcher.get('?_m=coupon_exchange_web', params);
  const { ret, msg, data } = res;

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data;
}
