import _reduce from 'lodash/reduce';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';

import { CHANGE_LOCALE_REQUEST } from 'store/modules/region/actions';
import { PLACE_ORDER_SUCCESS } from 'interfaces/global/store/modules/checkout/actions';
import { FETCH_PRICE_SUCCESS } from 'interfaces/global/store/modules/pricing/actions';
import {
  REQUEST_LOGOUT,
  PROFILE_SWITCHED,
} from 'interfaces/global/store/modules/auth/actions';

import {
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
  SET_SERVICE,
  RESET_SERVICES,
  ADD_SPECIAL_REQUEST,
  REMOVE_SPECIAL_REQUEST,
  SET_SUB_SPECIAL_REQUEST,
} from './actions';

export const initState = {
  services: {},
  specialRequests: {},
  subSpecialRequests: {},
  servicesOrder: [],
  isLoading: false,
  selectedService: 0,
  selectedSpecialRequests: [],
  selectedSubRequests: {},
  revision: 0,
  enablePOD: 0,
  welcomeMessage: {
    message: '',
    banner: '',
  },
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case FETCH_SERVICES_REQUEST:
      return { ...state, isLoading: true };

    case FETCH_SERVICES_SUCCESS: {
      const {
        services,
        specialRequests,
        subSpecialRequests,
        servicesOrder,
        revision,
        enablePOD,
        welcomeMessage,
      } = action.payload;

      // we wouldn't want uneccessary resets to selectedService
      // unless there are underlying changes
      const shouldKeepSelectedService =
        _isEqual(services, state.services) &&
        _isEqual(servicesOrder, state.servicesOrder);
      return {
        ...state,
        isLoading: false,
        services,
        specialRequests,
        subSpecialRequests,
        servicesOrder,
        selectedService: shouldKeepSelectedService ? state.selectedService : 0,
        revision,
        enablePOD,
        welcomeMessage: {
          ...state.welcomeMessage,
          ...welcomeMessage,
        },
      };
    }

    case FETCH_SERVICES_FAILURE:
      return { ...state, isLoading: false };

    case FETCH_PRICE_SUCCESS: {
      const { specialRequests: prevSpecialRequests } = state;
      const { specialRequests: specialRequestsPrices } = action;
      const specialRequests = _reduce(
        prevSpecialRequests,
        (result, item, key) => ({
          ...result,
          [key]: {
            ...item,
            ...(specialRequestsPrices[key]
              ? { price: specialRequestsPrices[key].price }
              : {}),
          },
        }),
        {}
      );
      return {
        ...state,
        specialRequests,
      };
    }

    case SET_SERVICE:
      return {
        ...state,
        selectedService: action.serviceIndex,
        selectedSpecialRequests: [],
      };

    case ADD_SPECIAL_REQUEST: {
      const { id } = action;
      const { subSpecialRequests } = state.specialRequests[id];
      return {
        ...state,
        selectedSpecialRequests: [...state.selectedSpecialRequests, id],
        ...(subSpecialRequests && {
          selectedSubRequests: {
            ...state.selectedSubRequests,
            [id]: subSpecialRequests[0],
          },
        }),
      };
    }

    case REMOVE_SPECIAL_REQUEST: {
      const updatedSelectedSubRequests = _omit(
        state.selectedSubRequests,
        action.id
      );
      return {
        ...state,
        selectedSpecialRequests: state.selectedSpecialRequests.filter(
          id => id !== action.id
        ),
        selectedSubRequests: updatedSelectedSubRequests,
      };
    }

    case SET_SUB_SPECIAL_REQUEST:
      return {
        ...state,
        selectedSubRequests: {
          ...state.selectedSubRequests,
          [action.id]: action.nextSubId,
        },
      };

    case PLACE_ORDER_SUCCESS:
    case PROFILE_SWITCHED:
    case REQUEST_LOGOUT: {
      return {
        ...state,
        selectedService: 0,
        selectedSpecialRequests: [],
      };
    }

    case CHANGE_LOCALE_REQUEST:
    case RESET_SERVICES:
      return initState;
    default:
      return state;
  }
}
