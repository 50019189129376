import { mobileQueryList } from 'components/MediaQuery';
import {
  SENSORS_TRACK_EVENT,
  SENSORS_SET_PROFILE,
  SENSORS_LOGIN,
  SENSORS_LOGOUT,
} from 'interfaces/global/store/modules/tracking/actions';

import { getDistinctId } from 'interfaces/global/store/modules/tracking/selectors';
import { getUser } from 'interfaces/global/store/modules/auth/selectors';

import {
  getCurrentLocation,
  getCurrentLocale,
  getCurrentDatacenter,
  getCurrentCountryCode,
} from 'store/modules/region/selectors';

export const trackingMiddleware = store => next => action => {
  const { sensors } = window;
  if (!sensors) {
    return next(action);
  }

  switch (action.type) {
    case SENSORS_TRACK_EVENT: {
      const { name, payload: args } = action;
      const state = store.getState();
      const isMobile = mobileQueryList.matches;
      const commonArgs = {
        llm_source: isMobile ? 'web_lite' : 'web_desktop',
        trigger_time: new Date().toISOString(),
        city: getCurrentLocation(state),
        language: getCurrentLocale(state).slice(0, 2),
        data_center: getCurrentDatacenter(state),
      };
      sensors.track(name, { ...commonArgs, ...args });
      break;
    }
    case SENSORS_SET_PROFILE: {
      const { payload: args } = action;
      const state = store.getState();
      const { profile_type: profileType, profile } = getUser(state);
      const isCorporate = {
        2: 0, // non-corporate user
        1: 1, // corporate user
      };
      const commonArgs = {
        data_center: getCurrentDatacenter(state),
        is_corporate: isCorporate[profileType],
        city: getCurrentLocation(state),
        country_login: getCurrentCountryCode(state),
        language: getCurrentLocale(state).slice(0, 2),
        email_billing: profile.contact_email || profile.email,
        email_registered: profile.email,
        firstName: profile.first_name,
        lastName: profile.last_name,
        phone: profile.phone_no,
      };
      sensors.setProfile({ ...commonArgs, ...args });
      break;
    }
    case SENSORS_LOGIN: {
      const state = store.getState();
      sensors.login(getDistinctId(state));
      break;
    }
    case SENSORS_LOGOUT: {
      sensors.logout();
      break;
    }
    default:
      break;
  }

  return next(action);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 'tracking',
    middlewares: [trackingMiddleware],
  },
];
