import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bool, func, string, number } from 'prop-types';
import styled, { css } from 'styled-components';
import { Checkbox, TextArea } from '@lalamove/karang';
import { rgba } from 'polished';
import { star as StarIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { nobel, primary } from 'styles/colors';
import { rateOrder } from 'interfaces/global/store/modules/records/actions';
import { createLoadingSelector } from 'store/modules/loading';
import Heading from 'components/SliderPanel/Heading';
import SliderPanelFooter from 'components/SliderPanelFooter';
import { RateDriverOptions } from 'api/uAPI/rateOrder.ts';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { noop, statusMap, ratingMap } from 'utils/helpers';

import Emotion from './components/Emotion';
import { Container, Content, Wrapper } from './style';

const AVERAGE_ORDER_RATING = 3;

export const StyledEmotion = styled(Emotion)`
  margin: 2em 0;
`;

const TextAreaWrapper = styled.div`
  margin: 1.3em 0;
  > div {
    display: block;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  display: block;
  margin: 1em 0;
`;

const Radio = styled.span.attrs(() => ({
  'aria-hidden': 'true',
}))`
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  padding: 2px;
  border: 1px solid ${nobel.main};
  border-radius: 50%;
  vertical-align: middle;
`;

const ButtonText = styled.span`
  display: inline-block;
  font-family: Noto Sans, 'Noto Sans TC', sans-serif;
  ${/* sc-selector */ Radio} + & {
    ${({ theme: { rtl } }) =>
      css`
        margin-${rtl ? 'right' : 'left'}: 0.5em;
    `}
  }
`;

const Button = styled.label`
  display: inline-block;
  padding: 1em;
  border: 2px solid ${nobel['200']};
  margin: 0.8em 0;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;

  input {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
  }

  span {
    vertical-align: middle;
  }

  /* layout */
  ${({ block }) =>
    block &&
    css`
      display: flex;
    `};

  & ${/* sc-selector */ ButtonText} {
    color: ${nobel.main};
    font-weight: 700;
  }

  &:hover,
  &:active {
    background-color: ${nobel['100']};
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 4px ${rgba(nobel.main, 0.2)};
  }

  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid ${primary.main};

      & ${/* sc-selector */ ButtonText} {
        color: ${primary.main};
      }

      &:hover,
      &:active {
        background-color: ${primary.main};
      }

      &:hover > ${/* sc-selector */ ButtonText} {
        color: #fff;
      }

      &:active,
      &:focus {
        box-shadow: 0 0 0 4px ${rgba(primary.main, 0.2)};
      }
    `};
`;

const initState = {
  rating: null,
  selectedReasons: [],
  comment: '',
  favorited: false,
  banned: false,
};

export class Rating extends Component {
  static defaultProps = {
    id: null,
    status: null,
    rated: false,
    isLoading: false,
    previouslyFavorited: false,
    previouslyBanned: false,
    rateOrder: noop,
    onBack: noop,
    onClose: noop,
  };

  static propTypes = {
    t: func.isRequired,
    id: string,
    status: number,
    driverId: string.isRequired,
    rated: bool,
    isLoading: bool,
    previouslyFavorited: bool,
    previouslyBanned: bool,
    rateOrder: func,
    onBack: func,
    onClose: func,
    track: func.isRequired,
  };

  state = initState;

  componentDidMount() {
    if (this.props.status !== statusMap.COMPLETED || this.props.rated) {
      this.props.onBack();
    }
  }

  componentDidUpdate() {
    if (this.props.rated) {
      this.props.onBack();
    }
  }

  handleRating = e => {
    const rating = parseInt(e.target.value, 10);
    if ([ratingMap.TERRIBLE, ratingMap.BAD].includes(rating)) {
      this.setState({ ...initState, rating, banned: true });
    } else if ([ratingMap.GOOD, ratingMap.GREAT].includes(rating)) {
      this.setState({ ...initState, rating, favorited: true });
    } else {
      this.setState({ ...initState, rating });
    }
  };

  handleComment = e => {
    this.setState({ comment: e.target.value });
  };

  handleToggle = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  getRateDriverOption = () => {
    const { favorited, banned } = this.state;
    const { previouslyBanned, previouslyFavorited } = this.props;

    if (favorited && !previouslyFavorited)
      return RateDriverOptions.addToFavoriteList;

    if (banned && !previouslyBanned) return RateDriverOptions.addToBanList;

    return RateDriverOptions.doNothing;
  };

  onSubmit = () => {
    const { rating, selectedReasons, comment } = this.state;
    const { id: orderId, driverId } = this.props;

    this.props.rateOrder({
      orderId,
      driverId,
      rating,
      reasons: selectedReasons,
      suggestions: comment,
      option: this.getRateDriverOption(),
    });

    this.props.track('order_rated', {
      driver_rating: rating,
    });

    this.props.onBack();
  };

  getReasons = () => {
    const { rating } = this.state;
    const { t } = this.props;

    // If a rating face has been selected, show reasons
    if (rating) {
      // Bad or terrible face has been selected
      if (rating < AVERAGE_ORDER_RATING)
        return [
          t('RecordPanel.driver_rating_bad_attitude'),
          t('RecordPanel.driver_rating_bad_late'),
          t('RecordPanel.driver_rating_bad_false_claim'),
          t('RecordPanel.driver_rating_bad_illegal_charges'),
          t('RecordPanel.driver_rating_bad_harassment'),
          t('RecordPanel.driver_rating_bad_goods_lost'),
        ];

      // Good or average face has been selected
      return [
        t('RecordPanel.driver_rating_good_attitude'),
        t('RecordPanel.driver_rating_good_vehicle'),
        t('RecordPanel.driver_rating_good_punctual'),
        t('RecordPanel.driver_rating_good_help'),
        t('RecordPanel.driver_rating_good_route'),
        t('RecordPanel.driver_rating_good_price'),
      ];
    }

    // No rating face has been selected yet
    return [];
  };

  toggleReason = reason => {
    const { selectedReasons } = this.state;

    const indexOfReason = selectedReasons.findIndex(
      selectedReason => selectedReason === reason
    );

    const newReasons = [...selectedReasons];

    indexOfReason >= 0
      ? newReasons.splice(indexOfReason, 1) // Remove the existing reason to "unselect" it
      : newReasons.push(reason); // Or add it to "select" it

    this.setState({ selectedReasons: newReasons });
  };

  renderReasons = reasons =>
    reasons.map(reason => (
      <Button
        key={reason}
        selected={this.state.selectedReasons.includes(reason)}
        block
      >
        <input
          id={reason}
          type="button"
          name={reason}
          onClick={() => this.toggleReason(reason)}
          value={reason}
        />
        <ButtonText>{reason}</ButtonText>
      </Button>
    ));

  render() {
    const { rating, comment, favorited, banned } = this.state;
    const {
      t,
      id,
      onBack,
      onClose,
      isLoading,
      previouslyFavorited,
      previouslyBanned,
    } = this.props;
    if (!id) return null;

    const reasons = this.getReasons();

    return (
      <Container>
        <Content>
          <Heading
            icon={<StarIcon size={24} />}
            title={t('RecordPanel.title_rating')}
            description={t('RecordPanel.msg_rating')}
          />
          <Wrapper>
            <StyledEmotion
              name="rating"
              selected={rating}
              onChange={this.handleRating}
            />
            {this.renderReasons(reasons)}
            {reasons.length > 0 && (
              <TextAreaWrapper>
                <TextArea
                  row={3}
                  label={t(`RecordPanel.driver_rating_other_suggestions`)}
                  value={comment}
                  onChange={this.handleComment}
                  maxLength={1000}
                  autoFocus
                />
              </TextAreaWrapper>
            )}
            {[ratingMap.TERRIBLE, ratingMap.BAD].includes(rating) && (
              <StyledCheckbox
                name="banned"
                label={t('RecordPanel.label_ban_driver')}
                checked={previouslyBanned || banned}
                onChange={this.handleToggle}
                disabled={!!previouslyBanned}
              />
            )}
            {[ratingMap.NORMAL, ratingMap.GOOD, ratingMap.GREAT].includes(
              rating
            ) && (
              <StyledCheckbox
                name="favorited"
                label={t('RecordPanel.label_favorite_driver')}
                checked={previouslyFavorited || favorited}
                onChange={this.handleToggle}
                disabled={!!previouslyFavorited}
              />
            )}
          </Wrapper>
        </Content>
        <SliderPanelFooter
          primaryAction={this.onSubmit}
          primaryButtonText={t('RecordPanel.button_submit')}
          isLoadingPrimaryButton={isLoading}
          disablePrimaryButton={isLoading || !rating}
          secondaryAction={onBack}
          secondaryButtonText={t('RecordPanel.button_back')}
          closeAction={onClose}
          closeButtonText={t('RecordPanel.button_close_panel')}
        />
      </Container>
    );
  }
}

const mapState = state => ({
  isLoading: createLoadingSelector([
    'RATE_ORDER',
    'FAVORITE_DRIVER',
    'BAN_DRIVER',
  ])(state),
});

export default compose(
  withTranslation(),
  connect(mapState, { rateOrder, track })
)(Rating);
