import _pick from 'lodash/pick';
import { v4 as uuid } from 'uuid';

import { DRAFT_ORDER_ID } from 'interfaces/global/config';

export const ROUTE_REARRANGE = 'ROUTE_REARRANGE';
export const WAYPOINT_NEW = 'WAYPOINT_NEW';
export const WAYPOINT_REMOVE = 'WAYPOINT_REMOVE';
export const WAYPOINT_UPDATE = 'WAYPOINT_UPDATE';
export const DELIVERY_INFO_UPDATE = 'DELIVERY_INFO_UPDATE';
export const ROUTE_OPTIMIZE_TOGGLE = 'ROUTE_OPTIMIZE_TOGGLE';
export const ROUTE_WAYPOINTS_SET = 'ROUTE_WAYPOINTS_SET';
export const ROUTE_WAYPOINTS_TRIM = 'ROUTE_WAYPOINTS_TRIM';
export const GOOGLE_DIRECTION_RESULT_RECEIVED =
  'GOOGLE_DIRECTION_RESULT_RECEIVED';
export const IMPORT_REQUEST = 'IMPORT_REQUEST';
export const IMPORT_SUCCESS = 'IMPORT_SUCCESS';
export const IMPORT_FAILURE = 'IMPORT_FAILURE';
export const WAYPOINT_ERROR_TOGGLE = 'WAYPOINT_ERROR_TOGGLE';
export const SESSION_TOKEN_UPDATE = 'SESSION_TOKEN_UPDATE';
export const VALIDATE_SERVICE_AREA_REQUEST = 'VALIDATE_SERVICE_AREA_REQUEST';
export const VALIDATE_SERVICE_AREA_SUCCESS = 'VALIDATE_SERVICE_AREA_SUCCESS';
export const VALIDATE_SERVICE_AREA_FAILURE = 'VALIDATE_SERVICE_AREA_FAILURE';
export const NO_SERVICE_FOUND_FAILURE = 'NO_VEHICLE_FOUND_FAILURE';

export const rearrangeRoute = (
  { sourceIndex, destinationIndex },
  orderId = DRAFT_ORDER_ID
) => ({
  type: ROUTE_REARRANGE,
  from: sourceIndex,
  to: destinationIndex,
  orderId,
});

export const newWaypt = (orderId = DRAFT_ORDER_ID) => ({
  type: WAYPOINT_NEW,
  id: uuid(),
  orderId,
});

export const removeWaypt = (id, orderId = DRAFT_ORDER_ID) => ({
  type: WAYPOINT_REMOVE,
  id,
  orderId,
});

export const updateWaypt = ({ id, ...rest }) => {
  if (!id) throw new Error();
  return {
    type: WAYPOINT_UPDATE,
    id,
    ...rest,
  };
};

export const toggleOptimize = (toggle, orderId = DRAFT_ORDER_ID) => ({
  type: ROUTE_OPTIMIZE_TOGGLE,
  toggle,
  orderId,
});

export const setWaypointsOrder = (mapping, orderId = DRAFT_ORDER_ID) => ({
  type: ROUTE_WAYPOINTS_SET,
  mapping,
  orderId,
});

export const trimWaypoints = (orderId = DRAFT_ORDER_ID) => ({
  type: ROUTE_WAYPOINTS_TRIM,
  orderId,
});

export const receiveFileUpload = payload => ({
  type: IMPORT_REQUEST,
  payload,
});

export const toggleWayptError = (id, errorMsg, bool) => {
  const args = { id, errorMsg, bool };
  for (const field in args) {
    if (args[field] === undefined)
      throw new Error(`missing arg: ${field} in ${JSON.stringify(args)}`);
  }
  return {
    type: WAYPOINT_ERROR_TOGGLE,
    errorMsg,
    id,
    bool,
  };
};

export const updateSessionToken = sessiontoken => ({
  type: SESSION_TOKEN_UPDATE,
  sessiontoken,
});

export const updateDeliveryInfo = (wayptId, payload) => ({
  type: DELIVERY_INFO_UPDATE,
  id: wayptId,
  info: _pick(payload, ['name', 'phone', 'addressDetails']),
});

export const validateArea = payload => ({
  type: VALIDATE_SERVICE_AREA_REQUEST,
  payload,
});
