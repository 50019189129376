// eslint-disable-next-line import/prefer-default-export
type PaymentMethodsType = {
  [methodName: string]: {
    id: number;
    name: string;
  };
};
export const PaymentMethods: PaymentMethodsType = {
  CASH: { id: 0, name: 'cash' },
  ONLINE: { id: 31, name: 'online' },
};

export const ALL_PAYMENT_METHOD_IDS = [
  PaymentMethods.ONLINE.id,
  PaymentMethods.CASH.id,
];

export const CheckoutSteps = {
  SELECT_VEHICLE: 0,
  SELECT_ROUTE: 1,
};

export const paymentMethodEventMap = {
  [PaymentMethods.CASH.id]: 'cash',
  [PaymentMethods.ONLINE.id]: 'online_payment',
};
