import React from 'react';
import { arrayOf, oneOfType, node, func } from 'prop-types';
import { Button } from '@lalamove/karang';
import { google as GoogleIcon } from '@lalamove/karang/dist/components/Icon/icons';
import styled from 'styled-components';
import { rgba, darken } from 'polished';
import { google } from 'styles/colors';

const GoogleBtn = styled(Button)`
  background-color: ${google};
  border-color: ${google};

  &:hover:enabled,
  &:active:enabled {
    background-color: ${darken(0.15, google)};
    border-color: ${darken(0.15, google)};
  }

  &:active:enabled,
  &:focus:enabled {
    box-shadow: 0 0 0 4px ${rgba(google, 0.2)};
  }
`;

const Icon = styled(GoogleIcon)`
  margin: -0.5em 0.5em;
  vertical-align: middle;
`;

const BtnText = styled.span`
  vertical-align: middle;
`;

const GoogleButton = ({ children, ...remainProps }) => (
  <GoogleBtn {...remainProps} variant="primary" size="large" block solid>
    <Icon size={24} /> <BtnText>{children}</BtnText>
  </GoogleBtn>
);

// Only show Google Button for lalamove.com
GoogleButton.componentDisabled =
  window.location.hostname.endsWith('in.lalamove.com') ||
  (!window.location.hostname.endsWith('lalamove.com') &&
    window.location.hostname !== 'localhost'); // for ldev

GoogleButton.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  onClick: func.isRequired,
};

GoogleButton.defaultProps = {
  children: null,
};

export default GoogleButton;
