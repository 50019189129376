import React from 'react';
import { useDispatch } from 'react-redux';
import MobileHeader from 'components/MobileHeader';
import { useResetScrollOnMount } from 'components/ResetScrollOnMount';
import { setCheckoutStep } from 'interfaces/global/store/modules/checkout/actions';
import { CheckoutSteps } from 'interfaces/global/store/modules/checkout/types';
import useWelcomeMessage from './useWelcomeMessage';
import useVehicleServices from './useVehicleServices';
import Footer from '../PlaceOrderFooter';

import {
  LalamoveLogo,
  Container,
  Welcome,
  Message,
  BeforeHeading,
  Heading,
  Spacer,
} from './styles';
import VehicleList from './VehicleList';
import useCashOnly from './useCashOnly';

const SelectVehicle: React.FC = () => {
  useResetScrollOnMount();
  useCashOnly();

  const dispatch = useDispatch();
  const { firstName, message, banner } = useWelcomeMessage();
  const {
    services,
    selectedService,
    selectedIndex,
    onChange,
  } = useVehicleServices();

  return (
    <Container>
      <MobileHeader left="drawer" center={<LalamoveLogo />} />
      <Welcome banner={banner}>
        <Message>
          <BeforeHeading>{message}</BeforeHeading>
          <Heading>{firstName}</Heading>
        </Message>
      </Welcome>
      {selectedService && (
        <>
          <VehicleList
            vehicles={services}
            selectedIndex={selectedIndex}
            onChange={onChange}
          />
          <Spacer />
          <Footer
            onNext={() => dispatch(setCheckoutStep(CheckoutSteps.SELECT_ROUTE))}
          />
        </>
      )}
    </Container>
  );
};

export default SelectVehicle;
