import * as Sentry from '@sentry/react';

function setupLogging(): void {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_HOST_ENV,
  });
}

export default setupLogging;
