import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import moment from 'moment';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

const verifyForgotPasswordSMScode = async ({ phoneNumber, smsCode }) => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const { countryId, locale } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      phone_no: phoneNumber,
      sms_code: smsCode,
      type: 1,
    }),
    hcountry: countryId,
    os: 'web',
    hlang: toLLMLower(locale),
    _t: moment().unix(),
  };

  const { ret, data, msg } = await fetcher.get('?_m=verify_sms_code', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data.verify_token;
};
export default verifyForgotPasswordSMScode;
