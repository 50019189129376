import React, { Component, Fragment } from 'react';
import { bool, func, number, string } from 'prop-types';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  question as QuestionIcon,
  settings as SettingIcon,
} from '@lalamove/karang/dist/components/Icon/icons';

import { WalletButton } from 'components/Button';

import { invalidSessionErrors } from 'store/modules/auth/helpers';
import { hasMessage } from 'store/modules/message';
import { openPanel, closePanel, isPanelOpen } from 'store/modules/ui';
import {
  fetchBalance,
  getCreditBalance,
  initTopup,
} from 'store/modules/wallet';

import { compose, noop } from 'utils/helpers';
import {
  HeaderContainer,
  LeftContainer,
  RightContainer,
  ListItem,
  StyledButton as SButton,
  StyledLink as SLink,
} from './style';
import RoundLogo from './components/RoundLogo';

const StyledLink = SLink.withComponent(NavLink);
const StyledButton = SButton.withComponent(NavLink);

const ICON_SIZE = 24;

// active logic for Place Order and Records
function checkActive(match, location) {
  if (!match) {
    return false;
  }
  if (match.url === '') {
    return location.pathname === '' || location.pathname === '/confirmation';
  }
  if (match.url === '/orders') {
    return !location.pathname.endsWith('/edit');
  }
  return true;
}

export class Header extends Component {
  static propTypes = {
    t: func,
    userName: string,
    walletBalance: number,
    hasSessionErrors: bool,
    isHelpOpen: bool,
    fetchBalance: func,
    initTopup: func,
    openPanel: func,
    closePanel: func,
  };

  static defaultProps = {
    t: noop,
    userName: null,
    walletBalance: null,
    hasSessionErrors: false,
    isHelpOpen: false,
    fetchBalance: noop,
    initTopup: noop,
    openPanel: noop,
    closePanel: noop,
  };

  componentDidMount() {
    this.props.fetchBalance();
    this.interval = setInterval(() => {
      if (!this.props.hasSessionErrors) this.props.fetchBalance();
    }, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onWalletClick = () => {
    this.props.initTopup('header'); // pass action origin for tracking
  };

  onHelpClick = () => {
    // eslint-disable-next-line no-shadow
    const { isHelpOpen, openPanel, closePanel } = this.props;
    if (isHelpOpen) closePanel();
    else openPanel('HELP');
  };

  renderLeftItem = ({ path, title }) => {
    const { t } = this.props;
    return (
      <ListItem key={path}>
        <StyledLink isActive={checkActive} to={path}>
          {t(title)}
        </StyledLink>
      </ListItem>
    );
  };

  render() {
    const { t, userName, walletBalance, isHelpOpen } = this.props;
    const leftHeaderItems = [
      { path: '/', title: 'Header.place_order' },
      { path: '/orders', title: 'Header.orders' },
      { path: '/wallet', title: 'Header.wallet' },
      { path: '/drivers', title: 'Header.drivers' },
      // { path: '/statement', title: 'Header.statement' },
    ];

    return (
      <Fragment>
        <HeaderContainer>
          <LeftContainer>
            <Link to="/">
              <RoundLogo />
            </Link>
            {leftHeaderItems.map(this.renderLeftItem)}
          </LeftContainer>
          <RightContainer>
            <WalletButton
              data-for="global"
              data-tip={t('Header.button_tip_topup')}
              data-effect="solid"
              data-place="bottom"
              name={userName}
              balance={walletBalance}
              onClick={this.onWalletClick}
            />
            <StyledButton
              data-for="global"
              data-tip={t('Header.settings')}
              data-effect="solid"
              data-event="mouseover"
              data-event-off="mouseleave click"
              data-place="bottom"
              to="/settings"
            >
              <SettingIcon size={ICON_SIZE} />
            </StyledButton>
            <SButton
              className={isHelpOpen && 'active'}
              data-for="global"
              data-tip={t('Header.help')}
              data-effect="solid"
              data-event="mouseover"
              data-event-off="mouseleave click"
              data-place="bottom"
              onClick={this.onHelpClick}
            >
              <QuestionIcon size={ICON_SIZE} />
            </SButton>
          </RightContainer>
        </HeaderContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userName: state.session.user.name,
  walletBalance: getCreditBalance(state),
  hasSessionErrors: hasMessage(['SESSION'], invalidSessionErrors)(state),
  isHelpOpen: isPanelOpen('HELP')(state),
});

export default withRouter(
  compose(
    withTranslation(),
    connect(mapStateToProps, { fetchBalance, initTopup, openPanel, closePanel })
  )(Header)
);
