import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { arrayOf, func, string } from 'prop-types';
import styled from 'styled-components';
import { mineShaft, red, mountainMeadow } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { noop, triggerDownload, PODStatus } from 'utils/helpers';
import FailedIcon from 'assets/svg/failed.svg';
import ImageIcon from 'assets/svg/image.svg';
import SignIcon from 'assets/svg/sign.svg';
import LessIcon from 'assets/svg/up.svg';
import moment from 'moment';
import MoreIcon from 'assets/svg/down.svg';

const Container = styled.div`
  margin: 1em 0;
  color: ${mineShaft['500']};
  font-size: ${fontSize.small};
`;

const PODFailedContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 4px;
  background: rgba(216, 83, 79, 0.1);
  .icon {
    align-self: center;
    margin-right: 8px;
  }

  .podInfo {
    align-self: center;
    height: 32px;
    margin: 8px 0px;
    color: ${red};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`;

const PODWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(25, 188, 155, 0.08);
`;

const PODSuccessContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 4px;
  .icon {
    align-self: center;
    margin-right: 8px;
  }

  .loadMore {
    align-self: center;
    margin-left: auto;
    cursor: pointer;
  }

  .podInfo {
    align-self: center;
    height: 32px;
    margin: 8px 0px;
    color: ${mountainMeadow.main};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`;

const PODDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  min-height: 128px;
  padding: 4px;
  .signature {
    width: 128px;
    height: 112px;
    margin: 8px 28px;
    border-radius: 4px;
    background: ${({ image }) => `url(${image}) center center/cover no-repeat`};
  }
  .remark {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding: 8px 0px;
    .remarkHead {
      flex: none;
      order: 0;
      align-self: flex-start;
      margin: 0px 8px;
      color: ${mineShaft[500]};
      font-weight: bold;
      letter-spacing: 0.16px;
    }
    .remarkContent {
      flex: none;
      order: 1;
      align-self: flex-start;
      margin: 0px 8px;
      color: ${mineShaft['900']};
      line-height: 16px;
    }
  }
`;

const Text = styled.div`
  font-weight: bold;
  line-height: 16px;
  span {
    font-weight: normal;
  }
`;

const SubText = styled.div`
  line-height: 16px;
`;

const POD = ({ status, signedBy, images, remarks, time, t }) => {
  const [expanded, setExpanded] = useState(false);
  const handleClick = () => triggerDownload(images[0], `PoD_${time}`);
  const toggleDetail = () => {
    setExpanded(!expanded);
  };
  return (
    <Container>
      {status === PODStatus.FAILED && (
        <PODFailedContainer>
          <img src={FailedIcon} alt="" className="icon" />
          <div className="podInfo">
            <SubText>{t('Deliveries.label_unable_to_deliver')}</SubText>
            <SubText>{moment(time).format('YYYY-MM-DD HH:mm')}</SubText>
          </div>
        </PODFailedContainer>
      )}
      {(status === PODStatus.SIGNED || status === PODStatus.DELIVERED) && (
        <PODWrapper>
          <PODSuccessContainer>
            <div className="icon">
              <img
                src={status === PODStatus.SIGNED ? SignIcon : ImageIcon}
                alt=""
              />
            </div>
            <div className="podInfo">
              {status === PODStatus.SIGNED ? (
                <Text>
                  {`${t('RecordPanel.heading_signed_by')} `}{' '}
                  <span>{signedBy}</span>
                </Text>
              ) : (
                <Text>{t('Deliveries.label_dropped_off')}</Text>
              )}
              <SubText>{moment(time).format('YYYY-MM-DD HH:mm')}</SubText>
            </div>
            <div
              className="loadMore"
              onClick={toggleDetail}
              role="presentation"
            >
              <img src={expanded ? LessIcon : MoreIcon} alt="" />
            </div>
          </PODSuccessContainer>
          {expanded && (
            <PODDetails image={images[0]}>
              <div
                className="signature"
                onClick={handleClick}
                role="presentation"
              />
              <div className="remark">
                <div className="remarkHead">{t('Deliveries.label_remark')}</div>
                <div className="remarkContent">{remarks}</div>
              </div>
            </PODDetails>
          )}
        </PODWrapper>
      )}
    </Container>
  );
};

POD.defaultProps = {
  signedBy: '',
  images: null,
  remarks: '',
  t: noop,
};

POD.propTypes = {
  status: string.isRequired,
  signedBy: string,
  images: arrayOf(string),
  remarks: string,
  time: string.isRequired,
  t: func,
};

export default withTranslation()(POD);
