import moment from 'moment';
import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import { createSu } from 'utils/apiHelper';
import UAPIFetcher from '../UAPIFetcher';
import FetcherFactory from '../FetcherFactory';

export default async function updateUserFlag(key: string): Promise<void> {
  const fetcher = FetcherFactory.make(UAPIFetcher);

  const {
    accessToken,
    profileType,
    cityId,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({ key }),
    city_id: cityId,
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
    os: 'web',
    _t: moment().unix(),
    _su: createSu(),
  };

  const { ret, msg } = await fetcher.get('?_m=update_user_flag', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }
}
