import React from 'react';
import { useMediaQuery } from 'react-responsive';

export const BREAKPOINT = 768;

interface ResponsiveMedia {
  isDesktop: boolean;
  isMobile: boolean;
}

export const useResponsiveMedia: () => ResponsiveMedia = () => {
  const isDesktop = useMediaQuery({ minWidth: BREAKPOINT });
  return { isDesktop, isMobile: !isDesktop };
};

export const withResponsiveMedia = <P,>(
  Comp: React.ComponentType<P & ResponsiveMedia>
): React.FC<P> => (props: P) => {
  const { isDesktop, isMobile } = useResponsiveMedia();
  return <Comp {...props} isDesktop={isDesktop} isMobile={isMobile} />;
};

export const Mobile: React.FC<any> = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT - 1 });
  return isMobile ? children : null;
};

export const Desktop: React.FC<any> = ({ children }) => {
  const { isDesktop } = useResponsiveMedia();
  return isDesktop ? children : null;
};

export const whenMobile = `@media screen and (max-width: ${BREAKPOINT - 1}px)`;

export const whenDesktop = `@media screen and (min-width: ${BREAKPOINT}px)`;

export const mobileQueryList = window.matchMedia(
  `(max-width: ${BREAKPOINT - 1}px)`
);
export const desktopQueryList = window.matchMedia(
  `(min-width: ${BREAKPOINT}px)`
);
