import React, { Fragment } from 'react';
import { bool, node, string } from 'prop-types';
import { connect } from 'react-redux';

import { checkAuth } from 'store/modules/auth/selectors';
import { getLoginBgImage } from 'store/modules/region/selectors';
import Container from 'components/Container';
import Header from 'views/Header'; // eslint-disable-line import/no-named-as-default
import Zendesk from 'containers/Zendesk';
import ChatWidget from 'containers/ChatWidget'; // eslint-disable-line import/no-named-as-default

const Layout = ({ authenticated, children, bgImage }) =>
  authenticated ? (
    <Fragment>
      <Header />
      {children}
      <Zendesk />
      <ChatWidget />
    </Fragment>
  ) : (
    <Container bgImage={bgImage}>{children}</Container>
  );

Layout.propTypes = {
  authenticated: bool.isRequired,
  children: node.isRequired,
  bgImage: string,
};

Layout.defaultProps = {
  bgImage: null,
};

const mapState = state => ({
  authenticated: checkAuth(state),
  bgImage: getLoginBgImage(state),
});

export default connect(mapState)(Layout);
