import checkout from './reducer';
import saga from './saga';
import * as actions from './actions';
import * as selectors from './selectors';

const checkoutModule = {
  id: 'checkout',
  reducerMap: { checkout },
  sagas: [saga],
};
export { checkoutModule as default, saga, actions, selectors };
