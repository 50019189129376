import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { RadioGroup } from '@lalamove/karang';

import formatter from 'utils/formatter';
import { getSubSpecialRequests } from 'interfaces/global/store/modules/services/selectors';
import { setSubRequest } from 'interfaces/global/store/modules/services/actions';

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 0 2rem 2rem;
`;

const ListItem = styled.li`
  margin: 1.5em 0;
`;

const ListItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 0.5em;
`;

class SubAdditionalServices extends Component {
  handleRadio = nextSubId => {
    const { specialRequestId, selectedSubRequests } = this.props;
    const prevSubId = selectedSubRequests[specialRequestId];
    this.props.setSubRequest(specialRequestId, prevSubId, nextSubId);
  };

  renderPrice = item => {
    const { specialRequestId, selectedSubRequests, t } = this.props;

    let price = '';
    if (selectedSubRequests[specialRequestId] === item.id) {
      if (item.isQuoteByDriver) {
        price = t('PlaceOrder.label_quote_by_driver');
      } else if (item.price.amount === 0) {
        price = t('PlaceOrder.free');
      } else {
        price = `+${formatter.currency(item.price.amount)}`;
      }
    }
    return price;
  };

  render() {
    const {
      specialRequestId,
      subSpecialRequests,
      selectedSubRequests,
    } = this.props;
    return (
      <RadioGroup
        name={selectedSubRequests[specialRequestId]}
        value={selectedSubRequests[specialRequestId]}
        onChange={this.handleRadio}
      >
        {Radio => (
          <List>
            {subSpecialRequests.map(item => (
              <ListItem key={item.id}>
                <ListItemContent>
                  <Radio value={item.id}>{item.name}</Radio>
                  {this.renderPrice(item)}
                </ListItemContent>
              </ListItem>
            ))}
          </List>
        )}
      </RadioGroup>
    );
  }
}

SubAdditionalServices.propTypes = {
  specialRequestId: PropTypes.string.isRequired,
  subSpecialRequests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSubRequests: PropTypes.shape({}).isRequired,
  setSubRequest: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  subSpecialRequests: getSubSpecialRequests(state, ownProps.specialRequestId),
  selectedSubRequests: state.services.selectedSubRequests,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, { setSubRequest })
)(SubAdditionalServices);
