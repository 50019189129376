import auth from './auth';
import browserSession from './browserSession';
import checkout from './checkout';
import drivers from './drivers';
import form from './form';
import pricing from './pricing';
import quotation from './quotation';
import records from './records';
import routing from './routing';
import searchHistory from './searchHistory';
import services from './services';
import settings from './settings';
import ui from './ui';
import wallet from './wallet';
import zendesk from './zendesk';
import genesys from './genesys';
import api from '../middlewares/api';
import tracking from '../middlewares/tracking';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  tracking,
  api,
  browserSession,
  checkout,
  drivers,
  form,
  pricing,
  quotation,
  records,
  routing,
  searchHistory,
  services,
  settings,
  ui,
  wallet,
  zendesk,
  genesys,
  auth,
];
