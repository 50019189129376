import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileType } from 'interfaces/global/store/modules/auth/types';

import {
  business as BusinessIcon,
  personal as PersonalIcon,
} from 'components/Icons';

export { ProfileType };

export type ProfileTypeItem = {
  value: ProfileType;
  label: string;
  icon: JSX.Element;
};

const useProfileItems = (): Array<ProfileTypeItem> => {
  const { t } = useTranslation();
  const profileItems: Array<ProfileTypeItem> = [
    {
      value: ProfileType.BUSINESS,
      label: t('Profile.business'),
      icon: <BusinessIcon />,
    },
    {
      value: ProfileType.PERSONAL,
      label: t('Profile.personal'),
      icon: <PersonalIcon />,
    },
  ];
  return profileItems;
};

export default useProfileItems;
