import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  fetchRecord,
  getOrder,
  getRouteByOrderId,
  getDriverById,
  getPriceByOrderId,
} from 'store/modules/records';
import { SEGMENT_OPEN_ORDER_DETAIL } from 'store/modules/records/tracking';
import { createLoadingSelector } from 'store/modules/loading';
import { openPanel } from 'store/modules/ui';
import { noop, statusMap } from 'utils/helpers';
import Segment from 'utils/segment';

import notFoundSVG from 'assets/svg/notfound_404.svg';
import {
  OrderShape,
  RouteShape,
  DriverShape,
  PriceShape,
} from 'views/Records/propTypes';
import EmptyState from 'components/EmptyState';
import Spinner from 'components/Spinner';
import Alert from 'containers/Alert'; // eslint-disable-line import/no-named-as-default
import StatusBar from './components/StatusBar';
import DriverInfo from './components/DriverInfo';
import AddPriorityFee from './AddPriorityFee';
import Cancel from './Cancel';
import OrderDetails from './Details';
import Rating from './Rating';
import Tracking from './Tracking';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`;

class Order extends Component {
  static defaultProps = {
    t: noop,
    isLoading: false,
    currentType: null,
    order: {},
    route: {},
    driver: {},
    price: {},
    openPanel: noop,
    onClose: noop,
  };

  static propTypes = {
    t: func,
    isLoading: bool,
    orderId: string.isRequired,
    currentType: string,
    order: OrderShape,
    route: RouteShape,
    driver: DriverShape,
    price: PriceShape,
    openPanel: func,
    onClose: func,
    fetchRecord: func.isRequired,
  };

  state = {
    showDetailsOverlay: false,
  };

  segmentMap = {
    [statusMap.ASSIGNING]: 'assigning',
    [statusMap.ONGOING]: 'matched',
    [statusMap.PICKED_UP]: 'picked up',
    [statusMap.COMPLETED]: 'completed',
    [statusMap.CANCELLED]: 'cancelled',
    [statusMap.EXPIRED]: 'cancelled',
    [statusMap.REJECTED]: 'rejected',
  };

  componentDidMount() {
    const { orderId } = this.props;
    this.props.fetchRecord(orderId);
  }

  componentDidUpdate(prevProps) {
    const { isLoading, order, orderId } = this.props;
    if (prevProps.isLoading && !isLoading && Object.keys(order).length) {
      // fetched and found the order
      Segment.createTrack(SEGMENT_OPEN_ORDER_DETAIL, {
        order_id: orderId,
        order_status: this.segmentMap[order.status],
      });
    }
  }

  toggleDetailsOverlay = show => {
    this.setState({
      showDetailsOverlay: show,
    });
  };

  onAddPriorityFee = () => {
    const { orderId } = this.props;
    this.props.openPanel('ORDER', { orderId, currentType: 'addPriorityFee' });
  };

  onCancel = () => {
    const { orderId } = this.props;
    this.props.openPanel('ORDER', { orderId, currentType: 'cancel' });
  };

  onTrack = () => {
    const { orderId } = this.props;
    this.props.openPanel('ORDER', { orderId, currentType: 'tracking' });
  };

  onRate = () => {
    const { orderId } = this.props;
    this.props.openPanel('ORDER', { orderId, currentType: 'rating' });
  };

  onBack = () => {
    const { orderId } = this.props;
    this.props.openPanel('ORDER', { orderId, currentType: null });
  };

  renderComp(currentType) {
    const { orderId, order, route, driver, price, onClose } = this.props;
    switch (currentType) {
      case 'addPriorityFee':
        return (
          <AddPriorityFee
            id={orderId}
            status={order.status}
            current={price.total}
            priorityFee={price.tips}
            paymentMethod={order.paymentMethod}
            onBack={this.onBack}
            onClose={onClose}
          />
        );
      case 'cancel':
        return (
          <Cancel
            id={orderId}
            status={order.status}
            onBack={this.onBack}
            onClose={onClose}
          />
        );
      case 'rating':
        return (
          <Rating
            id={orderId}
            status={order.status}
            driverId={driver.id}
            rated={Boolean(order.userRating)}
            onBack={this.onBack}
            onClose={onClose}
          />
        );
      case 'tracking':
        return (
          <Tracking
            status={order.status}
            id={orderId}
            shareId={order.shareId}
            serviceTypeId={order.serviceTypeId}
            vehicleTypeId={driver.vehicleTypeId}
            waypoints={route.waypoints}
            onBack={this.onBack}
            onClose={onClose}
          />
        );
      default:
        return (
          <OrderDetails
            id={orderId}
            status={order.status}
            editable={order.editable}
            edited={order.edited}
            noteToDriver={order.noteToDriver}
            podSignedBy={order.podSignedBy}
            podDatetime={order.podDatetime}
            podSignature={order.podSignature}
            paymentMethod={order.paymentMethod}
            specialRequests={order.specialRequests}
            subRequests={order.subRequests}
            route={route}
            price={price}
            onAddPriorityFee={this.onAddPriorityFee}
            onCancel={this.onCancel}
            onClose={onClose}
            showOverlay={this.state.showDetailsOverlay}
            showDeliveryStatus={Boolean(order.deliveryStatusFlow)}
            showPod={Boolean(order.shouldShowPod)}
          />
        );
    }
  }

  render() {
    const { t, onClose, currentType, order, driver, isLoading } = this.props;

    if (isLoading && Object.keys(order).length === 0) {
      return (
        <Wrapper>
          <Center>
            <Spinner big />
          </Center>
        </Wrapper>
      );
    }

    if (!Object.keys(order).length) {
      const imgNotFound = (
        <img
          src={notFoundSVG}
          alt={t('NotFound.title')}
          width={300}
          height={300}
        />
      );
      return (
        <EmptyState
          image={imgNotFound}
          title={t('NotFound.title')}
          text={t('NotFound.text')}
          actionText={t('NotFound.button_close')}
          action={onClose}
        />
      );
    }

    return (
      <Wrapper>
        <Alert messageSelector={['EDIT_ORDER']} persist />
        {(!currentType || currentType === 'tracking') && (
          <StatusBar
            status={order.status}
            refId={order.refId}
            dateTime={order.deliveryDatetime}
          />
        )}
        {(!currentType || currentType === 'tracking') &&
          (order.status === statusMap.ASSIGNING ||
            Object.keys(driver).length > 0) && (
            <DriverInfo
              id={driver.id}
              name={driver.name}
              phone={driver.phone}
              licensePlate={driver.licensePlate}
              avgRating={driver.avgRating}
              vehicleTypeId={driver.vehicleTypeId}
              photoUri={driver.photoUri}
              favorited={driver.favorited}
              banned={driver.banned}
              serviceTypeId={order.serviceTypeId}
              status={order.status}
              userRating={order.userRating}
              showTrackBtn={currentType !== 'tracking'}
              isVIPOrder={order.noteToDriver.indexOf('#1234') > -1}
              onRate={this.onRate}
              onTrack={this.onTrack}
              onToggleDialog={this.toggleDetailsOverlay}
            />
          )}
        {this.renderComp(currentType)}
      </Wrapper>
    );
  }
}

const mapState = (state, ownProps) => {
  const order = getOrder(state, ownProps.orderId);

  return {
    order,
    route: getRouteByOrderId(state, ownProps.orderId),
    driver: order ? getDriverById(state, order.driverId) : {},
    price: getPriceByOrderId(state, ownProps.orderId),
    isLoading: createLoadingSelector(['FETCH_RECORD'])(state),
  };
};

export default compose(
  withTranslation(),
  connect(mapState, { fetchRecord, openPanel })
)(Order);
