import { toLLMLower } from 'utils/locale';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import { UApiError } from 'utils/helpers';

export default async function fetchUnpaidOrder() {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    cityId,
    countryId,
    locale,
  } = await fetcher.getState();
  const params = {
    args: JSON.stringify({
      type: 1,
      city_id: cityId,
    }),
    token: accessToken,
    is_ep: profileType,
    os: 'web',
    hlang: toLLMLower(locale),
    hcountry: countryId,
  };

  const { data, ret, msg } = await fetcher.get(
    'index.php?_m=get_bill_unpay_order&_a=index',
    params
  );

  if (ret !== 0) throw new UApiError(msg, ret);

  return data;
}
