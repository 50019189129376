import { call, put, select, throttle, takeLatest } from 'redux-saga/effects';

import { createQuotation } from 'api/restAPI';

import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { CHANGE_LOCATION_SUCCESS } from 'store/modules/region/actions';
import { getFilledWaypts, getOrderIdByWayptId } from 'store/modules/routing';
import { DRAFT_ORDER_ID } from 'store/modules/routing/config';
import {
  ROUTE_REARRANGE,
  WAYPOINT_REMOVE,
  WAYPOINT_UPDATE,
  ROUTE_WAYPOINTS_SET,
} from 'store/modules/routing/actionTypes';
import { getOrder } from 'store/modules/records';
import { EDIT_ORDER_SUCCESS } from 'store/modules/records/actionTypes';
import { getCurrentLocale } from 'store/modules/region/selectors';

import { newFromWayPoints } from 'models/Delivery';

import Quotation from 'models/Quotation';

// action
export const RESET_QUOTATION = 'RESET_QUOTATION';
export const FETCH_QUOTATION_REQUEST = 'FETCH_QUOTATION_REQUEST';
export const FETCH_QUOTATION_SUCCESS = 'FETCH_QUOTATION_SUCCESS';
export const FETCH_QUOTATION_FAILURE = 'FETCH_QUOTATION_FAILURE';
export const resetQuotation = () => ({
  type: RESET_QUOTATION,
});
export const fetchQuotation = orderId => ({
  type: FETCH_QUOTATION_REQUEST,
  orderId,
});

export const initialState = {};

export const getLatestQuotation = state => state.quotation;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_ORDER_SUCCESS:
    case CHANGE_LOCATION_SUCCESS:
    case REQUEST_LOGOUT:
    case FETCH_QUOTATION_FAILURE:
    case RESET_QUOTATION:
      return initialState;
    case FETCH_QUOTATION_SUCCESS: {
      const { data } = action;
      const quote = new Quotation({
        id: data.id,
        ...data.attributes,
      });
      return quote;
    }
    default:
      return state;
  }
}

export function* onFetchQuotation({ orderId }) {
  try {
    const waypoints = yield select(getFilledWaypts, orderId);
    const { city, isProofOfDeliveryRequired } = yield select(getOrder, orderId);
    // TODO: assume all waypoints is using the same locale of the application
    const locale = yield select(getCurrentLocale);
    if (waypoints.length < 2) return;
    const response = yield call(createQuotation, {
      cityCode: city,
      orderId,
      deliveries: newFromWayPoints(
        waypoints,
        isProofOfDeliveryRequired,
        locale
      ),
    });
    yield put({
      type: FETCH_QUOTATION_SUCCESS,
      data: response,
    });
  } catch ({ message }) {
    yield put({
      type: FETCH_QUOTATION_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* onRouteChange({ orderId }) {
  if (orderId === DRAFT_ORDER_ID) return; // quotes api support edit order quotes only
  yield put(fetchQuotation(orderId));
}

// TODO: should instead select the edit order form for needed state
// remove waypoint provide orderId
export function* onWayptChange({ id, orderId }) {
  // TODO: remove waypoint will remove state so orderId = undefined
  const orderIdFromState = yield select(getOrderIdByWayptId, id);
  const targetOrder = orderId || orderIdFromState;
  if (targetOrder === DRAFT_ORDER_ID) return; // quotes api support edit order quotes only
  yield put(fetchQuotation(targetOrder));
}

export function* quotationSaga() {
  yield takeLatest(FETCH_QUOTATION_REQUEST, onFetchQuotation);
  yield throttle(500, [ROUTE_WAYPOINTS_SET, ROUTE_REARRANGE], onRouteChange);
  yield throttle(500, [WAYPOINT_REMOVE, WAYPOINT_UPDATE], onWayptChange);
}
