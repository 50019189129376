import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { bool, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { Button, Card, Input, Form, Checkbox } from '@lalamove/karang';

import { actions as RegisterActions } from 'store/modules/auth';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';
import {
  selectors as regionSelector,
  actions as regionAction,
} from 'store/modules/region';

import loadFacebookSDK from 'utils/facebookApi';

import PhoneInput from 'components/PhoneInput';
import { FacebookButton } from 'components/Button';
import HtmlTitle from 'components/HtmlTitle';

import logoPNG from 'assets/lalamove-logo.png';
import logoSVG from 'assets/svg/logo.svg';

import i18n from 'utils/i18n';
import { silver, lightSilver, red } from 'styles/colors';
import { fontSize } from 'styles/fonts';

const Logo = styled.div`
  width: 198px;
  height: 55px;
  margin: 0 auto 28px;
  background-image: url(${logoPNG});
  background-image: url(${logoSVG});
  background-position: center;
  background-size: 198px 55px;
`;

const StyledInput = styled(Input)`
  flex: 1;
`;

StyledInput.displayName = 'StyledInput';

const FormItem = styled(Form.Item)`
  & ${/* sc-selector */ StyledInput}:nth-child(2) {
    margin-left: 4px;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

const FromActionSeparator = styled.div`
  padding: 1rem 0;
  color: ${lightSilver};
  font-size: ${fontSize.small};
  text-align: center;
`;

const CardFooter = styled.div`
  padding-top: 2rem;
  color: ${silver};
  text-align: center;
`;

const ErrorMessage = styled.span`
  display: block;
  margin-left: 2em;
  color: ${red};
  font-size: ${fontSize.small};
`;

const StyledCheckbox = styled(Checkbox)`
  display: block;
  margin: 0.5em 0;
  font-size: ${fontSize.regular};
  & input {
    position: absolute;
  }
  & span:last-child {
    float: right;
  }
`;

export class Register extends Component {
  static defaultProps = {
    loading: false,
    apiError: null,
  };

  static propTypes = {
    registerSubmit: func.isRequired,
    facebookLogin: func.isRequired,
    cancelFacebookLogin: func.isRequired,
    loading: bool,
    t: func.isRequired,
    apiError: shape({ message: string }),
    currentCountry: shape({
      id: string,
      areaCode: string,
      isEmailRequiredForSignUp: bool,
    }).isRequired,
    countryDict: shape({}).isRequired,
    changeLocation: func.isRequired,
    language: string.isRequired,
  };

  state = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    password: '',
    terms: true,
    marketingOptIn: true,
    showError: false,
    showErrorTerms: false,
    loadingFacebookSDK: true,
  };

  componentDidMount = async () => {
    const { currentCountry, language } = this.props;

    this.FB = await loadFacebookSDK(currentCountry.id, language);
    this.facebookLoginResponse = await this.FB.getLoginStatus();
    this.setState({ loadingFacebookSDK: false });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { currentCountry } = this.props;
    const {
      firstName,
      lastName,
      email,
      mobile,
      password,
      terms,
      marketingOptIn,
    } = this.state;

    this.setState({ showErrorTerms: !terms });
    if (!terms) {
      return;
    }

    this.setState({ showError: true });
    this.props.registerSubmit({
      firstName,
      lastName,
      email,
      country: currentCountry.id,
      countryCode: currentCountry.areaCode,
      mobile,
      password,
      lang: i18n.language,
      marketingOptIn,
    });
  };

  handleToggle = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleSubmitFB = async () => {
    const {
      facebookLogin,
      cancelFacebookLogin,
      currentCountry: { areaCode },
    } = this.props;

    if (
      !this.facebookLoginResponse.status ||
      this.facebookLoginResponse.status !== 'connected'
    ) {
      const secondLoginResponse = await this.FB.login({
        scope: 'email,public_profile',
        return_scopes: true,
      });

      // User cancelled login or did not fully authorize.
      if (
        !secondLoginResponse.status ||
        secondLoginResponse.status !== 'connected'
      ) {
        cancelFacebookLogin();
        return;
      }
    }

    const user = await this.FB.api('/me', {
      fields: 'first_name, last_name, name, email',
    });

    facebookLogin({
      user,
      areaCode,
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value, showError: false });
  };

  handleBlur = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value.trim(),
    });
  };

  handleCountryChange = country => {
    const { currentCountry, changeLocation } = this.props;
    if (country === currentCountry.id) {
      return;
    }

    changeLocation({
      country,
    });
  };

  isFormReady() {
    const {
      currentCountry: { isEmailRequiredForSignUp },
    } = this.props;
    const { firstName, lastName, email, mobile, password } = this.state;
    return (
      !(firstName.trim() && lastName.trim() && mobile && password) ||
      (isEmailRequiredForSignUp && !email)
    );
  }

  renderError(name) {
    const { apiError } = this.props;
    if (!apiError) return null;

    const errors = {
      email: [
        'ERROR_INVALID_EMAIL',
        'ERROR_EMAIL_ALREADY_EXISTS',
        'ERROR_SOCIAL_ALREADY_EXISTS',
      ],
      mobile: ['ERROR_INVALID_PHONE_NUMBER', 'ERROR_PHONE_ALREADY_EXISTS'],
      password: ['ERROR_PASSWORD_TOO_SHORT', 'ERROR_INVALID_PASSWORD_FORMAT'],
    };

    return errors[name].find(msg => msg === apiError.message);
  }

  render() {
    const { loading, t, currentCountry, countryDict } = this.props;
    const {
      firstName,
      lastName,
      email,
      mobile,
      password,
      terms,
      marketingOptIn,
      showError,
      showErrorTerms,
      loadingFacebookSDK,
    } = this.state;

    return (
      <Fragment>
        <HtmlTitle>{t('Title.sign_up')}</HtmlTitle>
        <Card>
          <Logo />
          <form onSubmit={this.handleSubmit} noValidate>
            <FormItem>
              <StyledInput
                type="text"
                label={t('Register.first_name')}
                name="firstName"
                value={firstName}
                onChange={this.handleChange}
              />
              <StyledInput
                type="text"
                label={t('Register.last_name')}
                name="lastName"
                value={lastName}
                onChange={this.handleChange}
              />
            </FormItem>
            <FormItem>
              <StyledInput
                type="email"
                label={t('Register.email')}
                name="email"
                value={email}
                error={
                  showError && !loading ? t(this.renderError('email')) : null
                }
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </FormItem>
            <FormItem>
              <PhoneInput
                label={t('Register.mobile')}
                name="mobile"
                value={mobile}
                error={
                  showError && !loading ? t(this.renderError('mobile')) : null
                }
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                currentCountry={currentCountry}
                countries={Object.values(countryDict)}
                onCountryChange={this.handleCountryChange}
                showAreaCode
              />
            </FormItem>
            <FormItem>
              <StyledInput
                type="password"
                label={t('Register.password_req')}
                name="password"
                value={password}
                error={
                  showError && !loading ? t(this.renderError('password')) : null
                }
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </FormItem>
            <FormItem>
              <SubmitButton
                type="submit"
                variant="primary"
                size="large"
                block
                isLoading={loading}
                disabled={loading || this.isFormReady()}
                solid
              >
                {t('Register.sign_up')}
              </SubmitButton>
            </FormItem>

            <StyledCheckbox
              name="terms"
              label={
                <Trans i18nKey="Register.checkbox_terms">
                  I have read, understood and accept the
                  <a
                    href={t('url:ETIQUTTE_URL')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </a>
                  and
                  <a
                    href={t('url:PRIVACY_URL')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Trans>
              }
              checked={terms}
              value={terms}
              onChange={this.handleToggle}
            />
            {showErrorTerms && (
              <ErrorMessage>{t('Register.checkbox_terms_error')}</ErrorMessage>
            )}

            <StyledCheckbox
              name="marketingOptIn"
              label={
                <Trans i18nKey="Register.checkbox_marketing">
                  I agree the use of my personal data for direct marketing in
                  accordance with the stated
                  <a
                    href={t('url:PRIVACY_URL')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Trans>
              }
              value={marketingOptIn}
              checked={marketingOptIn}
              onChange={this.handleToggle}
            />
          </form>

          <FromActionSeparator>{t('Register.or')}</FromActionSeparator>
          <FacebookButton
            onClick={this.handleSubmitFB}
            solid
            disabled={loadingFacebookSDK || loading}
          >
            {t('Register.sign_up_fb')}
          </FacebookButton>

          <CardFooter>
            <Trans i18nKey="Register.login">
              Already have an account?
              <Link to="/login" replace>
                Log in
              </Link>
            </Trans>
          </CardFooter>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: createLoadingSelector(['REGISTER', 'FB_LOGIN'])(state),
  apiError: makeMessageSelector(['REGISTER', 'FB_LOGIN'])(state),
  currentCountry: regionSelector.getCurrentCountry(state),
  countryDict: regionSelector.getCountryDict(state),
  language: regionSelector.getCurrentLocale(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    registerSubmit: RegisterActions.registerSubmit,
    facebookLogin: RegisterActions.facebookLogin,
    cancelFacebookLogin: RegisterActions.cancelFacebookLogin,
    changeLocation: regionAction.changeLocation,
  })
)(Register);
