// ===== action ====
const DISABLE_BROWSER_WARNING = 'DISABLE_BROWSER_WARNING';
export const disableBrowserWarning = () => ({
  type: DISABLE_BROWSER_WARNING,
});

export const initState = {
  hasShownBrowserWarning: false,
};

// ===== selector =====
export const getHasShowBrowserWarning = state =>
  state.browserSession.hasShownBrowserWarning;

// ===== reducer =====
export default function reducer(state = initState, action) {
  switch (action.type) {
    case DISABLE_BROWSER_WARNING:
      return { ...state, hasShownBrowserWarning: true };
    default:
      return state;
  }
}
