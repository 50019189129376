import {
  takeLatest,
  put,
  call,
  take,
  cancel,
  fork,
  all,
  select,
  throttle,
} from 'redux-saga/effects';
import {
  getLocationSearchHistory as fetchHistoryAPI,
  saveAddressHistory,
} from 'api/uAPI';
import { CHANGE_LOCATION_SUCCESS } from 'store/modules/region/actions';
import { getCurrentCity } from 'store/modules/region/selectors';
import {
  getFilledWaypts,
  getDeliveryInfo,
} from 'interfaces/global/store/modules/routing/selectors';
import {
  fetchLocationSearchHistorySuccess,
  fetchLocationSearchHistory,
  ENABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY,
  DISABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY,
  FETCH_LOCATION_SEARCH_HISTORY_REQUEST,
  SAVE_LOCATION_SEARCH_HISTORY_REQUEST,
  SAVE_LOCATION_SEARCH_HISTORY_SUCCESS,
} from './actions';

function* handleFetchLocationSearchHistory() {
  try {
    const data = yield all([
      call(fetchHistoryAPI, 2),
      call(fetchHistoryAPI, 1), // 1-发货地址  2-收货地址
    ]);
    yield put(fetchLocationSearchHistorySuccess(data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in fetchLocationSearchHistory', e);
  }
}

function* handleLocationChange() {
  yield put(fetchLocationSearchHistory());
}

function* handleActiveFetchLocationSearchHistory() {
  while (true) {
    yield take(ENABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY);
    yield put(fetchLocationSearchHistory());
    const task = yield takeLatest(
      CHANGE_LOCATION_SUCCESS,
      handleLocationChange
    );
    yield take(DISABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY);
    yield cancel(task);
  }
}

function* onSaveAddressHistory() {
  const wayPointData = yield select(getFilledWaypts);
  const deliveryInfo = yield select(getDeliveryInfo);
  const { cityId } = yield select(getCurrentCity);

  const callFunctions = [];
  wayPointData.forEach((waypoint, index) => {
    const { address, lat, lng, name, id, placeId } = waypoint;
    const mappedWaypoint = {
      poi_id: placeId,
      house_number: deliveryInfo[id].addressDetails,
      contacts_name: deliveryInfo[id].name,
      contacts_phone_no: deliveryInfo[id].phone,
      city_id: cityId,
      addr: address,
      lat_lon: {
        lon: lng,
        lat,
      },
      place_type: '',
      name,
    };
    callFunctions.push(
      call(saveAddressHistory, placeId, index === 0 ? 1 : 2, mappedWaypoint)
    );
  });

  yield all(callFunctions);
  yield put({ type: 'SAVE_LOCATION_SEARCH_HISTORY_SUCCESS' });
}

export default function* saga() {
  yield throttle(
    500,
    [
      FETCH_LOCATION_SEARCH_HISTORY_REQUEST,
      SAVE_LOCATION_SEARCH_HISTORY_SUCCESS,
    ],
    handleFetchLocationSearchHistory
  );
  yield takeLatest(SAVE_LOCATION_SEARCH_HISTORY_REQUEST, onSaveAddressHistory);
  yield fork(handleActiveFetchLocationSearchHistory);
}
