import { LalamoveApiError } from 'utils/helpers';

import RestAPIFetcher from 'api/RestAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

export default async function createStatement({
  corporateId,
  countryCode,
  fromDate,
  toDate,
}) {
  const fetcher = FetcherFactory.make(RestAPIFetcher);
  const params = {
    type: 'statements',
    attributes: {
      corporateId,
      countryCode,
      fromDate,
      toDate,
    },
  };
  const { data, error } = await fetcher.post('statements', params);
  if (error) throw new LalamoveApiError(error.code, error.message);
  return { id: data.id, attributes: data.attributes };
}
