import { LalamoveApiError } from 'utils/helpers';
import { toLLMLower } from 'utils/locale';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Request {
//   country: String;
//   country_code: String;
//   password: String;
//   name: String;
//   last_name: String;
//   phone_number: String;
//   lang: String; // LLM format lower case
//   account_type: String; // 'registration' | 'facebook'
//   email?: String;
//   social_login_id?: String; // required if facebook
//   social_login_data?: String; // required if facebook
// }
// interface Response {
//   account_type: Number;
//   client_id: Number;
//   email_verified_status: Boolean;
//   login_status: Boolean;
//   phone_verified_status: Boolean;
//   secret: String;
//   verification_mode: String; // 'phone' | 'email'
// }
// AuthAPIResponse<Response>
// register/ sign up for auth api
export default async function register({
  countryCode,
  password,
  firstName,
  lastName,
  mobile,
  lang,
  email,
  socialLoginId,
}) {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  const { country } = await fetcher.getState();
  const params = {
    // auth-service only take two-characters country code
    country,
    country_code: countryCode,
    password,
    name: firstName,
    last_name: lastName,
    phone_number: mobile,
    lang: toLLMLower(lang),
    account_type: 'registration',
    ...(email && { email }),
  };
  if (socialLoginId) {
    params.social_login_id = socialLoginId;
    params.social_login_data = '';
    params.account_type = 'facebook';
  }
  const res = await fetcher.post('register', params);
  const { success, code, msg } = res;
  if (!success) throw new LalamoveApiError(msg || code);

  return res;
}
