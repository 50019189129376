import _partial from 'lodash/partial';
import _omit from 'lodash/omit';
import { compose } from 'utils/helpers';
import { MAX_NUM_WAYPOINTS } from 'interfaces/global/config';
import { CHANGE_LOCATION_REQUEST } from 'store/modules/region/actions';
import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { PLACE_ORDER_SUCCESS } from 'store/modules/checkout';
import { INIT_EDIT_REQUEST } from 'store/modules/records/actionTypes';
import { PROFILE_SWITCHED } from 'interfaces/global/store/modules/auth/actions';
import stopItemReducer from './stopItem/reducer';
import deliveryInfoReducer, {
  initState as deliveryInfoInitState,
} from './deliveryInfo/reducer';

// action-types
import {
  ROUTE_REARRANGE,
  WAYPOINT_NEW,
  WAYPOINT_REMOVE,
  WAYPOINT_UPDATE,
  ROUTE_OPTIMIZE_TOGGLE,
  ROUTE_WAYPOINTS_SET,
  ROUTE_WAYPOINTS_TRIM,
  GOOGLE_DIRECTION_RESULT_RECEIVED,
  IMPORT_REQUEST,
  IMPORT_FAILURE,
  WAYPOINT_ERROR_TOGGLE,
  SESSION_TOKEN_UPDATE,
  VALIDATE_SERVICE_AREA_REQUEST,
  VALIDATE_SERVICE_AREA_SUCCESS,
  VALIDATE_SERVICE_AREA_FAILURE,
  newWaypt,
} from './actions';
import { getFilledWaypts } from './selectors';
import { ERROR_INVALID_ADDR } from './errors';

// state
export const initState = {
  waypoints: {},
  order: {},
  waypointsMap: {},
  deliveryInfo: deliveryInfoInitState,
  errors: {},
  sessiontoken: '',
  isValidServiceArea: false,
  serviceAreaError: false,
};

export const genNewState = () => {
  // add 2 stops for initState
  const stopOne = _partial(reducer, _partial.placeholder, newWaypt());
  const stopTwo = _partial(reducer, _partial.placeholder, newWaypt());
  return compose(stopOne, stopTwo)(initState);
};

let orderMemo = {};
// reducer
export default function reducer(state = genNewState(), action) {
  switch (action.type) {
    case CHANGE_LOCATION_REQUEST:
    case IMPORT_REQUEST:
    case REQUEST_LOGOUT:
    case PROFILE_SWITCHED:
    case PLACE_ORDER_SUCCESS:
      return genNewState();
    case INIT_EDIT_REQUEST: {
      const { orderId } = action;
      const updatedState = _omit(state.order, [orderId]);
      return {
        ...state,
        order: {
          ...updatedState,
        },
      };
    }
    case GOOGLE_DIRECTION_RESULT_RECEIVED: {
      const { result, orderId } = action;
      return {
        ...state,
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            googleDirectionsResult: result,
          },
        },
      };
    }
    case ROUTE_WAYPOINTS_SET: {
      const { mapping, orderId } = action;
      if (!mapping) {
        return {
          ...state,
          order: {
            ...state.order,
            [orderId]: {
              ...state.order[orderId],
              sequence: orderMemo[orderId] || state.order[orderId].sequence,
            },
          },
        };
      }

      // faking the rootState.
      const orderIdArray = getFilledWaypts({ routing: state }, orderId).map(
        wypt => wypt.id
      );

      orderMemo = { ...orderMemo, [orderId]: orderIdArray }; // remember order
      const start = orderIdArray[0];

      const newWayptsOrder = mapping.map(i => orderIdArray[i + 1]);
      return {
        ...state,
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            sequence: [start, ...newWayptsOrder],
          },
        },
      };
    }
    case ROUTE_OPTIMIZE_TOGGLE: {
      const { orderId } = action;
      return {
        ...state,
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            shouldOptimize: action.toggle,
          },
        },
      };
    }
    case WAYPOINT_REMOVE: {
      const { orderId } = action;
      const { [action.id]: _, ...rest } = state.waypoints; // remove the specific waypoint
      const { [action.id]: __, ...restMap } = state.waypointsMap;
      const newSequence = state.order[orderId].sequence.filter(
        id => id !== action.id
      );
      orderMemo = { ...orderMemo, [orderId]: newSequence };
      const nextState = {
        ...state,
        waypoints: rest,
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            sequence: newSequence,
          },
        },
        waypointsMap: restMap,
        errors: errorsReducer(state.errors, action),
      };
      const { length } = getFilledWaypts({ routing: nextState }, orderId);

      if (length < 2) {
        nextState.order[orderId].googleDirectionsResult = null;
      }

      return nextState;
    }
    case WAYPOINT_NEW: {
      const { id, orderId } = action;
      const { order } = state;
      if (
        order[orderId] &&
        order[orderId].sequence.length === MAX_NUM_WAYPOINTS
      ) {
        return state;
      }

      if (order[orderId]) {
        return {
          ...state,
          waypoints: {
            ...state.waypoints,
            [id]: stopItemReducer(null, action),
          },
          order: {
            ...state.order,
            [orderId]: {
              ...order[orderId],
              sequence: [...order[orderId].sequence, id],
            },
          },
          waypointsMap: {
            ...state.waypointsMap,
            [id]: orderId,
          },
          deliveryInfo: deliveryInfoReducer(state.deliveryInfo, action),
        };
      }

      return {
        ...state,
        waypoints: {
          ...state.waypoints,
          [action.id]: stopItemReducer(null, action),
        },
        order: {
          ...state.order,
          [orderId]: {
            sequence: [id],
            googleDirectionsResult: null,
            shouldOptimize: false,
          },
        },
        waypointsMap: {
          ...state.waypointsMap,
          [id]: orderId,
        },
        deliveryInfo: deliveryInfoReducer(state.deliveryInfo, action),
      };
    }
    case ROUTE_REARRANGE: {
      const { from, to, orderId } = action;
      const { order } = state;
      // remove
      let newSequence = [...order[orderId].sequence];
      const waypoint = newSequence.splice(from, 1);

      // insert
      newSequence = [
        ...newSequence.slice(0, to),
        ...waypoint,
        ...newSequence.slice(to),
      ];
      return {
        ...state,
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            sequence: newSequence,
          },
        },
      };
    }
    case WAYPOINT_UPDATE: {
      const { id, lat, lng, placeId } = action;
      const item = state.waypoints[id];
      if (!item) return state;

      const isValidAddr =
        !!(item.lat && item.lng) || !!((lat && lng) || placeId);

      return {
        ...state,
        waypoints: {
          ...state.waypoints,
          [id]: stopItemReducer(item, action),
        },
        errors: errorsReducer(state.errors, action, !isValidAddr),
        sessiontoken: '',
      };
    }
    case IMPORT_FAILURE:
    case WAYPOINT_ERROR_TOGGLE: {
      const { errors } = state;
      return {
        ...state,
        errors: errorsReducer(errors, action),
      };
    }
    case VALIDATE_SERVICE_AREA_REQUEST: {
      return {
        ...state,
        serviceAreaError: false,
        isValidServiceArea: false,
      };
    }
    case VALIDATE_SERVICE_AREA_FAILURE: {
      return {
        ...state,
        serviceAreaError: true,
        isValidServiceArea: false,
      };
    }
    case VALIDATE_SERVICE_AREA_SUCCESS: {
      return {
        ...state,
        serviceAreaError: false,
        isValidServiceArea: true,
      };
    }
    case ROUTE_WAYPOINTS_TRIM: {
      const { orderId } = action;
      const ids = [];
      // trim empty waypoints
      return {
        ...state,
        waypoints: Object.values(state.waypoints)
          .filter(waypt => {
            const isEmpty = waypt.lat === null || waypt.lng === null;
            if (isEmpty) ids.push(waypt.id);
            return !isEmpty;
          })
          .reduce(
            (newWaypts, waypt) => ({ ...newWaypts, [waypt.id]: waypt }),
            {}
          ),
        order: {
          ...state.order,
          [orderId]: {
            ...state.order[orderId],
            sequence: state.order[orderId].sequence.filter(
              id => !ids.includes(id)
            ),
          },
        },
      };
    }
    case SESSION_TOKEN_UPDATE: {
      return {
        ...state,
        sessiontoken: action.sessiontoken,
      };
    }
    default:
      return {
        ...state,
        deliveryInfo: deliveryInfoReducer(state.deliveryInfo, action),
      };
  }
}

export function errorsReducer(
  state = initState.errors,
  action,
  toggle = false
) {
  switch (action.type) {
    case IMPORT_FAILURE: {
      const { meta } = action;
      if (!meta.ids) return state;
      const errors = meta.ids.reduce(
        (map, id) => ({
          ...map,
          [id]: {
            ...map[id],
            [meta.message]: true,
          },
        }),
        {}
      );
      return {
        ...state,
        ...errors,
      };
    }
    case WAYPOINT_REMOVE: {
      const { [action.id]: __, ...rest } = state; // remove the specific error
      return rest;
    }
    case WAYPOINT_UPDATE: {
      const { id } = action;
      const errorsObj = state[id];
      return {
        ...state,
        [id]: { ...errorsObj, [ERROR_INVALID_ADDR]: toggle },
      };
    }
    case WAYPOINT_ERROR_TOGGLE: {
      const { id, errorMsg, bool } = action;
      const errorsObj = state[id];
      return {
        ...state,
        [id]: { ...errorsObj, [errorMsg]: bool },
      };
    }
    default:
      return state;
  }
}
