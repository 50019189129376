export const FETCH_LOCATION_SEARCH_HISTORY_REQUEST =
  'FETCH_LOCATION_SEARCH_HISTORY_REQUEST';
export const FETCH_LOCATION_SEARCH_HISTORY_SUCCESS =
  'FETCH_LOCATION_SEARCH_HISTORY_SUCCESS';
export const ENABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY =
  'ENABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY';
export const DISABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY =
  'DISABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY';
export const SAVE_LOCATION_SEARCH_HISTORY_REQUEST =
  'SAVE_LOCATION_SEARCH_HISTORY_REQUEST';
export const SAVE_LOCATION_SEARCH_HISTORY_SUCCESS =
  'SAVE_LOCATION_SEARCH_HISTORY_SUCCESS';

export const fetchLocationSearchHistory = () => ({
  type: FETCH_LOCATION_SEARCH_HISTORY_REQUEST,
});

export const fetchLocationSearchHistorySuccess = history => ({
  type: FETCH_LOCATION_SEARCH_HISTORY_SUCCESS,
  history,
});

export const enableActiveFetchLocationSearchHistory = () => ({
  type: ENABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY,
});

export const disableActiveFetchLocationSearchHistory = () => ({
  type: DISABLE_ACTIVE_FETCH_LOCATION_SEARCH_HISTORY,
});

export const saveRouteAddresses = () => ({
  type: SAVE_LOCATION_SEARCH_HISTORY_REQUEST,
});
