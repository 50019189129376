import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { arrayOf, bool, func, number, string, shape } from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import _isEqual from 'lodash/isEqual';
import {
  downloadPdf as PdfIcon,
  downloadXls as XlsIcon,
} from '@lalamove/karang/dist/components/Icon/icons';
import {
  fetchStatement,
  getCreditBalance,
  getRewardBalance,
  getStatement,
  getStatementTypes,
  initTopup,
  getChargeList,
} from 'interfaces/global/store/modules/wallet/actions';
import { createLoadingSelector } from 'store/modules/loading';
import DateRangePicker from 'components/DateRangePicker';
import PageHeader from 'components/PageHeader';
import {
  encodeQueryData,
  noop,
  parseUrlParams,
  // triggerDownload,
} from 'utils/helpers';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import WalletBanner from './WalletBanner';
import NumberedGroup from './NumberedGroup';
import DownloadButton from './DownloadButton';
import {
  MAX_RANGE_LIMIT_DAYS,
  PAST_DATE_LIMIT_DAYS,
  DATE_DATA_FORMAT,
} from '../config';
import { validateDates } from '../helpers';
import { Container } from '../style';

// statement specific config:
export const SELECTED_DATE_RANGE = 7;

const now = moment();
// latest end date is yesterday, so subtract `now` by 1:
const DEFAULT_END_DATE = now.subtract(1, 'days').format(DATE_DATA_FORMAT);
// `SELECTED_DATE_RANGE` represents the default selection range:
const DEFAULT_START_DATE = now
  .subtract(SELECTED_DATE_RANGE - 1, 'days')
  .format(DATE_DATA_FORMAT);

const Content = styled.div`
  padding: 0 2em;
`;

const Row = styled.div`
  margin: 0.5em 0;
`;

export const Types = {
  PDF: 'pdf',
  XLS: 'excel',
};

export class Statement extends Component {
  static defaultProps = {
    t: noop,
    fetchStatement: noop,
    loading: false,
    statementTypes: [],
    // generatedFile: {
    //   startDate: '',
    //   endDate: '',
    //   xls: '',
    //   pdf: '',
    //   expiredAt: '',
    // },
    creditBalance: 0,
    rewardBalance: 0,
    initTopup: func,
    getChargeList: func,
  };

  static propTypes = {
    t: func,
    fetchStatement: func,
    loading: bool,
    statementTypes: arrayOf(string),
    history: shape({}).isRequired,
    location: shape({}).isRequired,
    // generatedFile: shape({
    //   startDate: string,
    //   endDate: string,
    //   xls: string,
    //   pdf: string,
    //   expiredAt: string,
    // }),
    creditBalance: number,
    rewardBalance: number,
    Tabs: func.isRequired,
    initTopup: func,
    getChargeList: func,
    track: func.isRequired,
  };

  // driven state by URL params
  params = this.getStateFromUrlParams();

  state = {
    start: this.params.start,
    end: this.params.end,
  };

  componentDidMount() {
    const { history, location } = this.props;

    if (!location.search) {
      history.replace({
        pathname: '/wallet/statement',
        search: encodeQueryData(this.state),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState, this.state)) {
      this.props.history.replace({
        search: encodeQueryData(this.state),
      });
    }
  }

  getStateFromUrlParams() {
    const { location } = this.props;
    const { start, end } = parseUrlParams(location.search);
    const areValidDates = validateDates(start, end, 'statement');
    return {
      start: areValidDates ? start : DEFAULT_START_DATE,
      end: areValidDates ? end : DEFAULT_END_DATE,
    };
  }

  handleDateChange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) return;
    this.setState({
      start: startDate.toISOString(true).split('T')[0],
      end: endDate.toISOString(true).split('T')[0],
    });
  };

  handleDownload = type => {
    const { start, end } = this.state;
    const { loading, statementTypes } = this.props;

    if (loading && statementTypes.includes(type)) {
      return;
    }

    if (type === Types.PDF) this.props.track('download_pdf_statement');
    if (type === Types.XLS) this.props.track('download_xls_statement');

    this.props.fetchStatement(start, end, type);
  };

  initTopup = () => {
    this.props.initTopup('wallet');
    this.props.getChargeList();
  };

  render() {
    const {
      t,
      statementTypes,
      rewardBalance,
      creditBalance,
      Tabs,
    } = this.props;
    const { start, end } = this.state;
    return (
      <Container>
        <PageHeader
          topControls={
            <WalletBanner
              rewardBalance={rewardBalance}
              creditBalance={creditBalance}
              openTopUp={this.initTopup}
            />
          }
          title={t('Wallet.title_wallet')}
          tabs={<Tabs />}
        />
        <Content>
          <NumberedGroup
            numbering={1}
            title={t('Wallet.heading_select_date_range')}
            subtitle={t('Wallet.label_select_date_range', {
              days: MAX_RANGE_LIMIT_DAYS,
            })}
          >
            <Row>
              <DateRangePicker
                startDate={moment(start)}
                endDate={moment(end)}
                onDatesChange={this.handleDateChange}
                minDate={moment().subtract(PAST_DATE_LIMIT_DAYS, 'days')}
                maxDate={moment().subtract(1, 'days')}
                maxDays={MAX_RANGE_LIMIT_DAYS}
              />
            </Row>
          </NumberedGroup>
          <NumberedGroup
            numbering={2}
            title={t('Wallet.heading_select_report_type')}
          >
            <Row>
              <DownloadButton
                title={t('Wallet.button_title_statement')}
                description={t('Wallet.button_description_statement')}
                icon={<PdfIcon size={40} />}
                onClick={() => this.handleDownload(Types.PDF)}
                loading={statementTypes.includes(Types.PDF)}
              />
            </Row>
            <Row>
              <DownloadButton
                title={t('Wallet.button_title_transaction_details')}
                description={t('Wallet.button_description_transaction_details')}
                icon={<XlsIcon size={40} />}
                onClick={() => this.handleDownload(Types.XLS)}
                loading={statementTypes.includes(Types.XLS)}
              />
            </Row>
          </NumberedGroup>
        </Content>
      </Container>
    );
  }
}

const mapState = state => ({
  generatedFile: getStatement(state),
  statementTypes: getStatementTypes(state),
  loading: createLoadingSelector(['FETCH_STATEMENT'])(state),
  creditBalance: getCreditBalance(state),
  rewardBalance: getRewardBalance(state),
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapState, { fetchStatement, initTopup, getChargeList, track })
)(Statement);
