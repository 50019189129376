import {
  getCurrentLocation,
  getAuthApiDomain,
} from 'store/modules/region/selectors';

import Fetcher from './Fetcher';
import Schema from './schema';

const APPTYPE_HEADER = 'USER';
const { REACT_APP_VERSION, REACT_APP_AUTH_API, NODE_ENV } = process.env;

const commonHeader = {
  'X-LLM-APPTYPE': APPTYPE_HEADER,
};
// Check /CHANGELOG.md for revision number should be used
const commonParams = {
  version: REACT_APP_VERSION,
  revision: '36',
  os: 'webapp',
  app: 'user',
};

export default class AuthAPIFetcher extends Fetcher {
  static uniqueName = 'AuthAPIFetcher';

  prepareBaseUrl = state => {
    let authUrl = getAuthApiDomain(state)
      ? `//${getAuthApiDomain(state)}`
      : REACT_APP_AUTH_API;
    if (NODE_ENV === 'development') {
      authUrl = REACT_APP_AUTH_API;
    }
    return `${authUrl}/user`;
  };

  prepareHeaders = (method, state, options) => {
    let headers = {
      'X-LLM-LOCATION': getCurrentLocation(state),
      ...commonHeader,
      ...options.headers,
    };
    if (['POST', 'PATCH'].includes(method)) {
      headers = {
        ...headers,
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      };
    }
    return headers;
  };

  prepareQuery = params => ({
    ...commonParams,
    ...params,
  });

  prepareBody = params =>
    new URLSearchParams({
      ...commonParams,
      ...params,
    });

  validate(endpoint, response) {
    return Schema.validate(`${this.prepareBaseUrl()}/`, endpoint, response);
  }
}
