import React, { Component, Fragment } from 'react';
import Big from 'big.js';
import styled from 'styled-components';
import { bool, func, number, string, instanceOf } from 'prop-types';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  question as QuestionIcon,
  settings as SettingIcon,
} from '@lalamove/karang/dist/components/Icon/icons';

import { WalletButton } from 'components/Button';

import { invalidSessionErrors } from 'store/modules/auth/helpers';
import { hasMessage } from 'store/modules/message';
import {
  openPanel,
  closePanel,
  isPanelOpen,
} from 'interfaces/global/store/modules/ui/actions';
import { fetchBalance, getCreditBalance } from 'store/modules/wallet';
import { createLoadingSelector } from 'store/modules/loading';
import {
  initTopup,
  getChargeList,
} from 'interfaces/global/store/modules/wallet/actions';
import {
  getIsEnterprise,
  getProfileFlags,
} from 'interfaces/global/store/modules/auth/selectors';
import {
  updateProfileFlag,
  setProfileType,
  requestLogout,
} from 'interfaces/global/store/modules/auth/actions';
import { trackHelpCenterTapped } from 'interfaces/global/store/modules/records/actions';
import { WEB_BUSINESS_TOOLTIP } from 'interfaces/global/store/modules/auth/helpers';
import { ProfileType } from 'interfaces/global/store/modules/auth/types';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { actions as GenesysActions } from 'interfaces/global/store/modules/genesys/actions';
import { compose, noop } from 'utils/helpers';
import {
  HeaderContainer,
  LeftContainer,
  RightContainer,
  ListItem,
  StyledButton as SButton,
  StyledLink as SLink,
} from './style';
import RoundLogo from './components/RoundLogo';
import ProfilePicker from './components/ProfilePicker.tsx';
import ProfileTooltip from './components/ProfileTooltip.tsx';

const { logoutGenesys } = GenesysActions;

export const StyledLink = SLink.withComponent(NavLink);
export const StyledButton = SButton.withComponent(NavLink);

const ProfileContainer = styled.div`
  position: relative;
`;

const ICON_SIZE = 24;
// active logic for Place Order and Records
function checkActive(match, location) {
  if (!match) {
    return false;
  }
  if (match.url === '') {
    return location.pathname === '' || location.pathname === '/confirmation';
  }
  if (match.url === '/orders') {
    return !location.pathname.endsWith('/edit');
  }
  return true;
}

export class Header extends Component {
  state = { active: false };

  static propTypes = {
    t: func,
    userName: string,
    profileType: number,
    isProfileTypeLoading: bool,
    walletBalance: instanceOf(Big),
    isEnterprise: bool,
    shouldShowProfileTooltip: bool,
    hasSessionErrors: bool,
    isHelpOpen: bool,
    fetchBalance: func,
    openPanel: func,
    closePanel: func,
    initTopup: func,
    getChargeList: func,
    updateProfileFlag: func,
    setProfileType: func,
    logoutGenesys: func,
    requestLogout: func,
    track: func,
    trackHelpCenterTapped: func,
  };

  static defaultProps = {
    t: noop,
    userName: null,
    profileType: null,
    isProfileTypeLoading: false,
    walletBalance: null,
    isEnterprise: false,
    shouldShowProfileTooltip: false,
    hasSessionErrors: false,
    isHelpOpen: false,
    fetchBalance: noop,
    openPanel: noop,
    closePanel: noop,
    initTopup: noop,
    getChargeList: noop,
    updateProfileFlag: noop,
    setProfileType: noop,
    logoutGenesys: noop,
    requestLogout: noop,
    track: noop,
    trackHelpCenterTapped: noop,
  };

  componentDidMount() {
    this.props.fetchBalance();
    this.interval = setInterval(() => {
      if (!this.props.hasSessionErrors) this.props.fetchBalance();
    }, 60 * 1000);
    if (this.props.shouldShowProfileTooltip) {
      this.timeout = setTimeout(() => {
        this.setState({ active: true });
      }, 1000);
    }
  }

  componentDidUpdate(prevProps) {
    const shouldShowProfileTooltip =
      !prevProps.shouldShowProfileTooltip &&
      this.props.shouldShowProfileTooltip;
    if (shouldShowProfileTooltip) {
      this.timeout = setTimeout(() => {
        this.setState({ active: true });
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onWalletClick = () => {
    this.props.initTopup('header');
    this.props.getChargeList();
  };

  onSettingsClick = () => {
    this.props.track('settings_tapped');
  };

  onHelpClick = () => {
    /* eslint-disable no-shadow */
    const {
      isHelpOpen,
      openPanel,
      closePanel,
      trackHelpCenterTapped,
    } = this.props;
    /* eslint-enable no-shadow */

    trackHelpCenterTapped();

    if (isHelpOpen) closePanel();
    else openPanel('HELP');
  };

  onProfileTooltipClose = () => {
    if (this.props.shouldShowProfileTooltip) {
      this.props.updateProfileFlag({ key: WEB_BUSINESS_TOOLTIP });
    }
    this.setState({ active: false });
  };

  onChangeProfileType = profile => {
    this.props.setProfileType({ profileType: profile });
    this.props.track('profile_switch_tapped', {
      new_type: profile === ProfileType.BUSINESS ? 'business' : 'personal',
    });
  };

  onLogout = () => {
    this.props.logoutGenesys();
    this.props.requestLogout();
  };

  renderLeftItem = ({ path, title, onClick = noop }) => {
    const { t } = this.props;
    return (
      <ListItem key={path}>
        <StyledLink isActive={checkActive} to={path} onClick={onClick}>
          {t(title)}
        </StyledLink>
      </ListItem>
    );
  };

  render() {
    const {
      t,
      userName,
      isEnterprise,
      profileType,
      isProfileTypeLoading,
      walletBalance,
      isHelpOpen,
      track, // eslint-disable-line no-shadow
    } = this.props;
    const leftHeaderItems = [
      { path: '/', title: 'Header.place_order' },
      {
        path: '/orders',
        title: 'Header.orders',
        onClick: () => track('orders_tapped'),
      },
      {
        path: '/wallet',
        title: 'Header.wallet',
        onClick: () => track('wallet_tapped'),
      },
      {
        path: '/drivers',
        title: 'Header.drivers',
        onClick: () => track('my_drivers_tapped'),
      },
    ];

    return (
      <Fragment>
        <HeaderContainer data-cy="navbar">
          <LeftContainer>
            <Link to="/">
              <RoundLogo />
            </Link>
            {leftHeaderItems.map(this.renderLeftItem)}
          </LeftContainer>
          <RightContainer>
            <WalletButton
              data-for="global"
              data-tip={t('Header.button_tip_topup')}
              data-effect="solid"
              data-place="bottom"
              name={userName}
              balance={walletBalance}
              onClick={this.onWalletClick}
            />
            <StyledButton
              data-for="global"
              data-tip={t('Header.settings')}
              data-effect="solid"
              data-event="mouseover"
              data-event-off="mouseleave click"
              data-place="bottom"
              to="/settings"
              onClick={this.onSettingsClick}
            >
              <SettingIcon size={ICON_SIZE} />
            </StyledButton>
            <SButton
              className={isHelpOpen && 'active'}
              data-for="global"
              data-tip={t('Header.help')}
              data-effect="solid"
              data-event="mouseover"
              data-event-off="mouseleave click"
              data-place="bottom"
              onClick={this.onHelpClick}
            >
              <QuestionIcon size={ICON_SIZE} />
            </SButton>
            {isEnterprise && (
              <ProfileContainer>
                <ProfilePicker
                  isLoading={isProfileTypeLoading}
                  profileType={profileType}
                  onClick={this.onProfileTooltipClose}
                  onChange={this.onChangeProfileType}
                  onLogout={this.onLogout}
                />
                <ProfileTooltip
                  visible={this.state.active}
                  onClose={this.onProfileTooltipClose}
                />
              </ProfileContainer>
            )}
          </RightContainer>
        </HeaderContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userName: state.session.user.profile.first_name,
  profileType: state.session.user.profile_type,
  isEnterprise: getIsEnterprise(state),
  shouldShowProfileTooltip:
    getIsEnterprise(state) &&
    getProfileFlags(state).indexOf(WEB_BUSINESS_TOOLTIP) > -1,
  walletBalance: getCreditBalance(state),
  hasSessionErrors: hasMessage(['SESSION'], invalidSessionErrors)(state),
  isHelpOpen: isPanelOpen('HELP')(state),
  isProfileTypeLoading: createLoadingSelector(['SET_PROFILE_TYPE'])(state),
});

export default withRouter(
  compose(
    withTranslation(),
    connect(mapStateToProps, {
      updateProfileFlag,
      setProfileType,
      fetchBalance,
      openPanel,
      initTopup,
      getChargeList,
      closePanel,
      logoutGenesys,
      requestLogout,
      track,
      trackHelpCenterTapped,
    })
  )(Header)
);
