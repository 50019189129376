import React, { ChangeEvent, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Input, Button } from '@lalamove/karang';

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;

  height: 32px;
`;

const CouponCodeInput = styled(Input)`
  flex-grow: 1;
  div {
    border-radius: 2px;

    input {
      height: 16px;
      padding: 7px;
    }
  }
`;

const RedeemButton = styled(Button).attrs({ variant: 'primary' })`
  height: 32px;
  margin-left: 8px;
`;

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onRedeem: () => void;
  isLoading?: boolean;
  error?: string;
  placeholder?: string;
}

const RedeemCoupon: React.FC<Props> = ({
  value,
  onChange,
  onRedeem,
  error,
  placeholder,
  isLoading,
}) => {
  const { t } = useTranslation();

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (value && !isLoading && e.key === 'Enter') {
      onRedeem();
    }
  };

  return (
    <Container>
      <CouponCodeInput
        value={value}
        onKeyPress={onKeyPress}
        onChange={onChange}
        error={error}
        placeholder={placeholder}
        disabled={isLoading}
        autoFocus
      />
      <RedeemButton
        disabled={isLoading || !value}
        onClick={onRedeem}
        isLoading={isLoading}
      >
        {t('Coupon.add_coupon')}
      </RedeemButton>
    </Container>
  );
};

export default RedeemCoupon;
