import { LalamoveApiError } from 'utils/helpers';
import { toLLMLower } from 'utils/locale';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Request {
//   secret: String;
//   lang: String; // e.g. en_hk
// }
// interface Response {
//   account_type: Number;
//   client_id: Number;
//   login_status: Boolean;
//   phone_verified_status: 0 | 1;
//   secret: String;
// }
// AuthAPIResponse<Response>
// resend sms or try voice verification for register
export default async function resendVerificationCode(secret, lang, type) {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  const params = { secret, lang: toLLMLower(lang) };
  if (type) params.verification_type = type;
  const res = await fetcher.post('resendverificationcode', params);
  const { success, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return res;
}
