import React, { Component } from 'react';
import { compose } from 'redux';
import { func, string, array, number, bool } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import SliderPanelFooter from 'components/SliderPanelFooter';

import { noop, statusMap } from 'utils/helpers';
import { withResponsiveMedia } from 'components/MediaQuery';
import DriverMap from './components/DriverMap';

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

class Tracking extends Component {
  static defaultProps = {
    status: null,
    t: noop,
    onBack: noop,
    onClose: noop,
    waypoints: [],
  };

  static propTypes = {
    status: number,
    id: string.isRequired,
    serviceTypeId: string.isRequired,
    vehicleTypeId: string.isRequired,
    t: func,
    onBack: func,
    onClose: func,
    waypoints: array, // eslint-disable-line react/forbid-prop-types
    isMobile: bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.status !== this.props.status &&
      ![statusMap.ONGOING, statusMap.PICKED_UP].includes(this.props.status)
    ) {
      this.props.onClose();
    }
  }

  render() {
    const {
      id,
      serviceTypeId,
      vehicleTypeId,
      t,
      onBack,
      onClose,
      waypoints,
      isMobile,
    } = this.props;
    if (!id) return null;
    return (
      <Wrapper>
        <DriverMap
          orderId={id}
          waypoints={waypoints.map(w => ({ ...w, id: uuid() }))}
          serviceTypeId={
            vehicleTypeId === 'VIP' ? vehicleTypeId : serviceTypeId
          }
        />
        <SliderPanelFooter
          secondaryAction={onBack}
          secondaryButtonText={t('RecordPanel.button_back')}
          closeAction={isMobile ? undefined : onClose}
          closeButtonText={
            isMobile ? undefined : t('RecordPanel.button_close_panel')
          }
        />
      </Wrapper>
    );
  }
}

export default compose(withTranslation(), withResponsiveMedia)(Tracking);
