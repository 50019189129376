import { LalamoveApiError } from 'utils/helpers';
import { toLLMLower } from 'utils/locale';
import log from 'api/log';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Response {
//   data: {
//     id: String,
//   };
//   footprint: String;
//   success: Boolean;
// }
/**
 * Places order
 * @param {object} routing routing data, e.g. {
    latlong: 22.31|113.95,22.28|114.17',
    addressStr: ['HKG', 'HKCEC'],
    addressDetails: ['T1', '2F'],
    contactPersons: [
      { name: 'Wang', phone: '44443333' },
      { name: 'Huang', phone: '77778888' }
    ],
    placeId: 'ChIJnc,ChIJN0'
  }
  * @param {object} services selected services, e.g. {
    normalReq: [{ service_type: 'VAN' }],
    specialReq: [{ option: 'ENGLISH', sub_option: 'BRITISH' }]
  }
  * @param {number} totalPrice total calculated price (from pricecal API)
  * @param {object} checkoutData user inputs from Checkout page, e.g. {
    note: 'Remarks...',
    vehicle: '',
    promoCode: '',
    myFleet: 0,
    name: 'Huang',
    phone: '77778888',
    isUsingPrepayment: 0
  }
  * @param {object} uniformInvoice selected invoice type and info (TW only), e.g. {
    key: 'DUPLICATE',
    name: 'Chang',
    email: 'chang@nksp.com',
    address: '11 Sanchong Road',
  }
  * @param {number} timestamp scheduled order date & time represented in unix time
  * @return {promise} resolve to a response object, e.g. { success: true, data: { ... } }
  * @throws ERROR_INVALID_SESSION, ERROR_PRICE_UNMATCHED,
  */
export default async function vanRequest({
  routing,
  services,
  totalPrice,
  checkoutData,
  uniformInvoice,
  timestamp,
}) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, city, locale } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    country: city,
    lang: toLLMLower(locale),
    args: JSON.stringify({
      text: checkoutData.note, // note to driver
      redeem: checkoutData.promoCode,
      latlong: routing.latlong,
      normal_req: services.normalReq, // service type
      special_req: services.specialReq, // additional services
      my_fleet: checkoutData.myFleet ? 1 : 0, // assign to favorite drivers first
      name: checkoutData.name,
      tel: checkoutData.phone,
      vehicle: checkoutData.vehicle, // for VIP
      is_using_prepayment: checkoutData.isUsingPrepayment ? 1 : 0, // pay by wallet
      priceCheck: totalPrice,
      addressStr: routing.addressStr, // addresses
      addressDetails: routing.addressDetails, // delivery info (block, floor, room)
      contactPersons: routing.contactPersons, // delivery info (name, phone)
      place_id: routing.placeId, // google place ids
      ...(uniformInvoice && { uniformInvoice }), // TW uniform invoice info
      ...(timestamp && { timestamp }), // send timestamp for scheduled order
    }),
  };
  const res = await fetcher.post('vanrequest', params);
  const { success, code } = res;
  if (!success) {
    log.info(`vanrequest 200 fail: ${code}`, {
      debug: `JSON: Request: ${JSON.stringify(
        params
      )}, Response: ${JSON.stringify(res)}`,
    });
    throw new LalamoveApiError(code);
  }
  return res;
}
