import React from 'react';
import { withTranslation } from 'react-i18next';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { Rating } from '@lalamove/karang';
import {
  group as GroupIcon,
  groupBanned as GroupBannedIcon,
} from '@lalamove/karang/dist/components/Icon/icons';

import { CloseButton } from 'components/Button';
import EmptyState from 'components/EmptyState';
import WrappedTable from 'components/WrappedTable';
import { offWhite } from 'styles/colors';
import { noop } from 'utils/helpers';

const StyledTable = styled(WrappedTable)`
  table-layout: fixed;

  & thead th:first-of-type {
    padding-left: 2em;

    > div {
      padding-left: 0;
    }
  }

  & td:first-of-type {
    padding-left: 2em;
  }

  & thead th:last-of-type,
  & td:last-of-type {
    text-align: right;
  }
`;

const DriverTable = ({ t, type, drivers, loading, onRemoveDriver }) => {
  const columns = [
    {
      key: 'name',
      label: t('Drivers.table_column_title_name'),
    },
    {
      key: 'vehicle',
      label: t('Drivers.table_column_title_vehicle'),
    },
    {
      key: 'avgRating',
      label: t('Drivers.table_column_title_rating'),
      render: avgRating => <Rating value={Math.round(avgRating)} size={7} />,
    },
    {
      key: 'id',
      label: '',
      render: id => <CloseButton onClick={() => onRemoveDriver(id)} />,
    },
  ];

  const emptyState = (
    <EmptyState
      image={
        type === 'favorite' ? (
          <GroupIcon size={200} color={offWhite} />
        ) : (
          <GroupBannedIcon size={200} color={offWhite} />
        )
      }
      text={t(`Drivers.msg_no_${type}`)}
    />
  );

  return (
    <StyledTable
      hoverable
      data={drivers}
      columns={columns}
      loading={loading}
      emptyState={emptyState}
    />
  );
};

DriverTable.defaultProps = {
  t: noop,
  type: 'favorite',
  drivers: [],
  loading: false,
  onRemoveDriver: noop,
};

DriverTable.propTypes = {
  t: func,
  type: string,
  drivers: arrayOf(shape()),
  loading: bool,
  onRemoveDriver: func,
};

export default withTranslation()(DriverTable);
