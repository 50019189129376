import { channel } from 'redux-saga';
import { take, takeLatest, call, put } from 'redux-saga/effects';
import fetchIntercept from 'fetch-intercept';
import { GET_USER_SESSION_SUCCESS } from 'redux-react-session/dist/actionTypes';

import {
  invalidSessionErrors,
  isAllowBusinessProfileErrorHandle,
  ERROR_BUSINESS_PROFILE_REMOVED_RET,
} from 'utils/apiHelper';
import { REQUEST_LOGOUT, sessionFailure } from 'store/modules/auth/actions';
import { openDialog } from 'store/modules/ui';
import { removeBusinessProfile } from 'interfaces/global/store/modules/auth/actions';

const { REACT_APP_DEBUG } = process.env;

const API_MIDDLEWARE_INIT = 'API_MIDDLEWARE_INIT';
const API_MIDDLEWARE_DISPOSE = 'API_MIDDLEWARE_DISPOSE';

const apiMiddleware = store => next => async action => {
  // do this before next to avoid private route
  // being rendered before api is set
  if (action.type === GET_USER_SESSION_SUCCESS) {
    const { user } = action;
    REACT_APP_DEBUG && console.log('GET_USER_SESSION_SUCCESS', user); // eslint-disable-line no-console
  }

  const result = next(action);

  if (action.type === REQUEST_LOGOUT) {
    REACT_APP_DEBUG && console.log('REQUEST_LOGOUT'); // eslint-disable-line no-console
  }

  return result;
};

const sessionChannel = channel();

export function* watchSessionChannel() {
  while (true) {
    const action = yield take(sessionChannel);
    yield put(action);
  }
}

function register() {
  fetchIntercept.register({
    response: response => {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType === 'application/json') {
        const clone = response.clone();
        clone.json().then(data => {
          const { ret } = data;
          // mobile-api will return error under status code 200
          if (ret !== 0) {
            if (invalidSessionErrors.includes(ret)) {
              sessionChannel.put(sessionFailure(ret));
              sessionChannel.put(openDialog('SESSION_ERROR'));
            } else if (
              // Hack to skip interceptor
              // better use a config param to indicate the skipping, after we switch to axios
              // or similar HTTP client lib that support instance-wise interceptors and config param in response interceptor
              isAllowBusinessProfileErrorHandle(response.url) &&
              ret === ERROR_BUSINESS_PROFILE_REMOVED_RET
            ) {
              sessionChannel.put(removeBusinessProfile());
            }
          }
        });
      }
      return response;
    },
  });
}

function clear() {
  fetchIntercept.clear();
}

function* onAPIMiddlewareInit() {
  yield call(register);
}

function* onAPIMiddlewareDispose() {
  yield call(clear);
}

export function* apiSaga() {
  yield takeLatest(API_MIDDLEWARE_INIT, onAPIMiddlewareInit);
  yield takeLatest(API_MIDDLEWARE_DISPOSE, onAPIMiddlewareDispose);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 'api',
    middlewares: [apiMiddleware],
    sagas: [apiSaga, watchSessionChannel],
    initialActions: [{ type: API_MIDDLEWARE_INIT }],
    finalActions: [{ type: API_MIDDLEWARE_DISPOSE }],
  },
];
