import { LalamoveApiError } from 'utils/helpers';

import RestAPIFetcher from 'api/RestAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface MonetaryAmount {
//   language: string;
//   amount: number; // int
//   amountDisplay: string;
//   amountCurrency: string;
//   amountExponent: string;
// }
// interface MonetaryAmountDetail extends MonetaryAmount {
//   type: String;
//   name: String;
// }

// interface Delivery {
//   startAddressDistrict: string;
//   startAddressLanguage: string;
//   startAddress: string;
//   startLatitude: string;
//   startLongitude: string;

//   startAddressBlock?: string;
//   startAddressFloor?: string;
//   startAddressRoom?: string;
//   startContactName?: string;
//   startContactPhone?: string;

//   endAddressDistrict: string;
//   endAddressLanguage: string;
//   endAddress: string;
//   endLatitude: string;
//   endLongitude: string;

//   endAddressBlock?: string;
//   endAddressFloor?: string;
//   endAddressRoom?: string;
//   endContactName?: string;
//   endContactPhone?: string;

//   isProofOfPickupRequired?: boolean;
//   isProofOfDeliveryRequired?: boolean;

//   statusProgress?: StatusProgress[];
// }
// interface QuotePromoCode {
//   name: string;
// }

// interface QuotesSpecialRequest {
//   id: string;
// }

// interface QuotesAdditionalFee {
//   amount: number; // int
// }

// interface Response {
//   id: string;
//   attributes: {
//     cityCode: string,
//     serviceType?: string,
//     clientId?: string,
//     isImmediate?: boolean,
//     deliveries: Delivery[],
//     scheduledAt: string, // ISO Z
//     baseClientOrderId?: string,
//     promocode?: QuotePromoCode,
//     specialRequests?: QuotesSpecialRequest[], // get from client Order
//     priorityFee?: QuotesAdditionalFee,
//     adjustment?: QuotesAdditionalFee,
//     totalPayment?: MonetaryAmount,
//     totalPrice?: MonetaryAmount,
//     paymentBreakdown?: MonetaryAmountDetail[],
//     priceBreakdown?: MonetaryAmountDetail[],
//   };
// }
// interface KrakenResponse<T> {
//   data: T;
//   links: { self: String };
// }
export default async function createQuotation({
  cityCode,
  orderId,
  deliveries,
}) {
  const fetcher = FetcherFactory.make(RestAPIFetcher);
  const { clientId } = await fetcher.getState();
  const params = {
    type: 'quotes',
    attributes: {
      baseClientOrderId: orderId,
      cityCode,
      clientId,
      deliveries,
    },
  };
  const res = await fetcher.post('quotes', params);
  const { error, errors, data } = res;
  if (error) throw new LalamoveApiError(error.code, error.message);
  // TODO: Helper function to loop errors array for auth error
  if (errors) throw new LalamoveApiError(errors[0].code, errors[0].message);
  return data;
}
