import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { replace } from 'connected-react-router';
import styled from 'styled-components';
import { Badge, Button, HeadingGroup } from '@lalamove/karang';
import {
  onOff as OnOffIcon,
  userMark as UserIcon,
} from '@lalamove/karang/dist/components/Icon/icons';

import { orange } from 'styles/colors';
import { requestLogout } from 'interfaces/global/store/modules/auth/actions';
import { actions } from 'interfaces/global/store/modules/genesys/actions';
import { getUser } from 'interfaces/global/store/modules/auth/selectors';
import { useResponsiveMedia } from 'components/MediaQuery';
import MobileHeader from 'components/MobileHeader';
import { useResetScrollOnMount } from 'components/ResetScrollOnMount';

import {
  Wrapper,
  Fieldset,
  Table,
  FormItem,
  LabelCol,
  Content,
  PaddedHeading,
  SpacedContainer,
  SubContent,
} from './style';
import Preferences from './components/Preferences';
import LocLang from './components/LocLang';
import PasswordForm from './components/Password';
import Email from './components/Email';

const LabelColTop = styled(LabelCol)`
  vertical-align: top;
  padding-top: 21px;
`;

const StyledButton = styled(Button)`
  padding: 0.8em 1em;
`;

const Settings = () => {
  useResetScrollOnMount();

  const { t } = useTranslation();
  const { isMobile, isDesktop } = useResponsiveMedia();
  const dispatch = useDispatch();
  const { profile } = useSelector(getUser);
  const {
    phone_no: phoneNumber = '',
    first_name: firstName,
    last_name: lastName,
  } = profile;

  const handleLogout = () => {
    dispatch(actions.logoutGenesys());
    dispatch(requestLogout());
  };

  return (
    /* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
    <>
      {isMobile && (
        <MobileHeader
          left="back"
          center={t('Header.settings')}
          onClickLeft={() => dispatch(replace('/'))}
        />
      )}
      <Wrapper>
        <Fieldset>
          <HeadingGroup>
            <UserIcon size={24} color={orange} />
            <PaddedHeading size="large" htmlTag="h3">
              {t('Settings.form_legend_profile')}
            </PaddedHeading>
            {isDesktop && (
              <Button
                onClick={handleLogout}
                icon={<OnOffIcon />}
                variant="danger"
              >
                {t('Settings.logout')}
              </Button>
            )}
          </HeadingGroup>
          <Table>
            <FormItem>
              <LabelCol>
                <label>{t('Settings.form_label_name')}</label>
              </LabelCol>
              <Content>
                {firstName} {lastName}
              </Content>
            </FormItem>
            <FormItem>
              <LabelCol>
                <label>{t('Settings.form_label_mobile')}</label>
              </LabelCol>
              <Content>
                <SpacedContainer>
                  {phoneNumber}
                  {phoneNumber && (
                    <SubContent>
                      <Badge solid variant="mountainMeadow">
                        {t('Settings.tag_status_verified')}
                      </Badge>
                    </SubContent>
                  )}
                </SpacedContainer>
              </Content>
            </FormItem>
            <FormItem>
              <LabelCol>
                <label>{t('Settings.form_label_email')}</label>
              </LabelCol>
              <Content>
                <Email />
              </Content>
            </FormItem>
            <FormItem>
              <LabelColTop>
                <label>{t('Settings.form_label_password')}</label>
              </LabelColTop>
              <Content>
                <PasswordForm />
              </Content>
            </FormItem>
          </Table>
        </Fieldset>
        <Preferences />
        <LocLang />
        {isMobile && (
          <Fieldset>
            <StyledButton
              block
              onClick={handleLogout}
              icon={<OnOffIcon />}
              variant="danger"
            >
              {t('Settings.logout')}
            </StyledButton>
          </Fieldset>
        )}
      </Wrapper>
    </>
  );
};

export default Settings;
