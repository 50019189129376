import React from 'react';
import { bool, func, number, string, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Heading } from '@lalamove/karang';
import {
  cashHKD as CashIcon,
  wallet as WalletIcon,
} from '@lalamove/karang/dist/components/Icon/icons';
import _snakeCase from 'lodash/snakeCase';

import { orange } from 'styles/colors';
import formatter from 'utils/formatter';
import { noop } from 'utils/helpers';

const Container = styled.div`
  padding: 0;
`;

const Row = styled.div`
  margin: 0.5em 0;
  font-size: 1em;
`;

const Label = styled.span`
  display: inline-block;
  width: 70%;
  text-align: left;
`;

const Value = styled.span`
  display: inline-block;
  width: 30%;
  text-align: right;
`;

const Payment = styled(Value)`
  font-size: 0.7em;
`;

const StyledIcon = c => styled(c)`
  &[style] {
    vertical-align: bottom !important;
  }
  margin-right: 4px;
`;

const Total = styled(Value)`
  width: 100%;
  font-size: 1.3em;
  font-weight: bold;
`;

const paymentIcon = {
  CASH: StyledIcon(CashIcon),
  CREDITS: StyledIcon(WalletIcon),
};

const paymentMap = {
  CASH: 'RecordPanel.label_payment_cash',
  CREDITS: 'RecordPanel.label_payment_wallet',
};

const Price = ({ t, paymentMethod, price, hideZeroItems, ...remain }) => {
  const PaymentIcon = paymentIcon[paymentMethod];
  return (
    <Container {...remain}>
      <Row>
        <Label>
          <Heading htmlTag="h3">{t(`RecordPanel.heading_price`)}</Heading>
        </Label>
        {paymentMethod && (
          <Payment>
            <PaymentIcon color={orange} size={12} />
            {t(paymentMap[paymentMethod])}
          </Payment>
        )}
      </Row>
      {Object.keys(price).map(
        key =>
          key !== 'symbol' &&
          key !== 'total' &&
          (!hideZeroItems || price[key] !== 0) && (
            <Row key={`row.${key}`}>
              <Label key={`label.${key}`}>
                {t(`RecordPanel.label_${_snakeCase(key)}`)}
              </Label>
              <Value key={`value.${key}`}>
                {formatter.currency(price[key])}
              </Value>
            </Row>
          )
      )}
      {price.total !== undefined && (
        <Row key="row.total">
          <Total key="total.total">{formatter.currency(price.total)}</Total>
        </Row>
      )}
    </Container>
  );
};

Price.propTypes = {
  t: func,
  paymentMethod: string,
  price: shape({ current: number, priorityFee: number, total: number }),
  hideZeroItems: bool,
};

Price.defaultProps = {
  t: noop,
  paymentMethod: null,
  price: null,
  hideZeroItems: false,
};

export default withTranslation()(Price);
