import React, { Fragment } from 'react';
import { bool, node, string } from 'prop-types';
import { connect } from 'react-redux';

import { checkAuth } from 'interfaces/global/store/modules/auth/selectors';
import { getLoginBgImage } from 'store/modules/region/selectors';
import Container from 'components/Container';
import Header from 'interfaces/global/views/Header';
import SideMenu from 'interfaces/global/containers/SideMenu';
import ChatWidget from 'interfaces/global/containers/ChatWidget';
import { useResponsiveMedia } from 'components/MediaQuery';

export const Layout = ({ authenticated, children, bgImage }) => {
  const { isDesktop, isMobile } = useResponsiveMedia();

  return authenticated ? (
    <Fragment>
      {isDesktop && <Header />}
      {isMobile && <SideMenu />}
      {children}
      {isDesktop && <ChatWidget />}
    </Fragment>
  ) : (
    <Container bgImage={bgImage}>{children}</Container>
  );
};

Layout.propTypes = {
  authenticated: bool.isRequired,
  children: node.isRequired,
  bgImage: string,
};

Layout.defaultProps = {
  bgImage: null,
};

const mapState = state => ({
  authenticated: checkAuth(state),
  bgImage: getLoginBgImage(state),
});

export default connect(mapState)(Layout);
