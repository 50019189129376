import { LalamoveApiError } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Request {
//   country: String; // e.g. HK_HKG
//   access_token: String;
//   client_id: Number | String;
//   lang: String; // e.g. en_hk
// }
// interface Response {
//   balance: Number;
//   isLowBalance: Boolean;
//   rewards: Number;
// }
// MobileAPIResponse<Response>
// fetch wallet amount
export default async function fetchWalletAmount() {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.langMutex.release();
  await FetcherLockService.accessTokenMutex.release();
  const { city, locale, accessToken, clientId } = await fetcher.getState();
  const params = {
    country: city,
    access_token: accessToken,
    client_id: clientId,
    lang: locale,
  };
  if (accessToken === '') {
    throw new LalamoveApiError('not authorized');
  }

  const { success, data, code } = await fetcher.get('vanwalletamount', params);
  if (!success) throw code;
  return { creditBalance: data.balance, rewardBalance: data.rewards };
}
