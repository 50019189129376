import styled from 'styled-components';
import { TabBar } from '@lalamove/karang';

// space at bottom for not overlay with zendesk button
export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 73rem;
  max-width: 110rem;
  padding-bottom: 5em;
  margin-right: auto;
  margin-left: auto;
`;

export const TabGroup = styled(TabBar)`
  margin: 0 auto 0 2em;
`;

export const SearchForm = styled.form`
  margin-left: 2em;

  @media (min-width: 110rem) {
    margin-right: 0;
    margin-left: 0;
  }
`;
