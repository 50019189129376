import React from 'react';
import createSVGIcon from '@lalamove/karang/dist/components/Icon/icons/utils/createSVGIcon';

const caret = createSVGIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.64645 4.14645C2.84171 3.95118 3.15829 3.95118 3.35355 4.14645L6 6.79289L8.64645 4.14645C8.84171 3.95118 9.15829 3.95118 9.35355 4.14645C9.54882 4.34171 9.54882 4.65829 9.35355 4.85355L6.35355 7.85355C6.15829 8.04882 5.84171 8.04882 5.64645 7.85355L2.64645 4.85355C2.45118 4.65829 2.45118 4.34171 2.64645 4.14645Z"
      fill="#585858"
    />
  </g>,
  'caret',
  '0 0 12 12'
);

export default caret;
