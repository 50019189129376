import React, { useState, useEffect, useRef } from 'react';
import { arrayOf, number, func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { darkGray, silver } from 'styles/colors';
import {
  WaypointShape,
  DeliveryInfoShape,
} from 'interfaces/global/views/Records/propTypes';
import {
  globalOrderStatusMap as statusMap,
  globalCompletedOrderStatusIDs as completed,
  globalCancelledOrderStatusIDs as cancelled,
  PODStatus,
  noop,
} from 'utils/helpers';
import PODSuccessIcon from 'assets/svg/pod_success.svg';
import PODFailedIcon from 'assets/svg/pod_failed.svg';
import PinIcon from 'assets/svg/pin.svg';
import PickupIcon from 'assets/svg/pickup.svg';
import MidStopIcon from 'assets/svg/mid_stop.svg';
import POD from '../POD';

const matching = [statusMap.MATCHING.id, statusMap.LOADING.id];

const delivering = [
  statusMap.ON_GOING.id,
  statusMap.UNLOADING.id,
  statusMap.ORDER_LOADED.id,
];

const getIcon = (status, index, last, pod, currentNode) => {
  if (cancelled.includes(status) || matching.includes(status)) {
    if (index === 0) {
      return PickupIcon;
    }
    if (last) {
      return PinIcon;
    }
    return MidStopIcon;
  }
  if (delivering.includes(status) || completed.includes(status)) {
    if (index === 0) {
      if (currentNode > 1 || status === statusMap.ORDER_LOADED.id) {
        return PODSuccessIcon;
      }
      return PickupIcon;
    }
    if (
      pod &&
      (pod.status === PODStatus.SIGNED || pod.status === PODStatus.DELIVERED)
    ) {
      return PODSuccessIcon;
    }
    if (pod && pod.status === PODStatus.FAILED) {
      return PODFailedIcon;
    }
    if (last) {
      return PinIcon;
    }
    return MidStopIcon;
  }
  return MidStopIcon;
};

const List = styled.ul`
  position: relative;
  padding: 0;
  list-style: none;

  &:before {
    content: '';
    position: absolute;
    top: 1.3em;
    bottom: ${({ height }) => height};
    left: 0.7em;
    opacity: ${({ status }) => (cancelled.includes(status) ? 0.5 : 1)};
    border-left: 2px dotted ${darkGray};
  }
`;

const ListItem = styled.li`
  position: relative;
  padding-left: 28px;
`;

const ItemContainer = styled.div`
  padding-bottom: 24px;
  line-height: 1.4;

  &:before {
    content: url(${({ status, index, last, pod, currentNode }) =>
      getIcon(status, index, last, pod, currentNode)});
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    background: #ffffff;
    opacity: ${({ status }) => (cancelled.includes(status) ? 0.5 : 1)};
  }
`;

const DeliveryInfo = styled.div`
  color: ${silver};
  font-size: 0.8em;
`;

const DeliveryRoute = ({
  waypoints,
  deliveryInfo: infos,
  status,
  t,
  currentNode,
}) => {
  const [height, setHeight] = useState('5.2em');
  const ref = useRef(null);
  useEffect(() => {
    setHeight(`${ref.current.clientHeight}px`);
  }, []);
  return (
    <List height={height}>
      {waypoints.map((waypoint, i) => (
        <ListItem key={uuid()} ref={i === waypoints.length - 1 ? ref : null}>
          <ItemContainer
            pod={waypoint.pod}
            index={i}
            status={status}
            last={i === waypoints.length - 1}
            currentNode={currentNode}
          >
            <div>{waypoint.name}</div>
            {Boolean(infos) && Boolean(infos[i]) && (
              <DeliveryInfo>
                {[infos[i].name, infos[i].phone, infos[i].addressDetails]
                  .filter(Boolean)
                  .join(' | ')}
              </DeliveryInfo>
            )}
            {i > 0 &&
              waypoint.pod &&
              (waypoint.pod.status === PODStatus.SIGNED ||
                waypoint.pod.status === PODStatus.DELIVERED ||
                waypoint.pod.status === PODStatus.FAILED) && (
                <POD
                  status={waypoint.pod.status}
                  signedBy={waypoint.pod.signedBy}
                  images={waypoint.pod.images}
                  remarks={waypoint.pod.remarks}
                  time={waypoint.pod.time}
                  t={t}
                />
              )}
          </ItemContainer>
        </ListItem>
      ))}
    </List>
  );
};

DeliveryRoute.propTypes = {
  waypoints: arrayOf(WaypointShape).isRequired,
  deliveryInfo: arrayOf(DeliveryInfoShape),
  status: number.isRequired,
  t: func,
  currentNode: number,
};

DeliveryRoute.defaultProps = {
  deliveryInfo: null,
  t: noop,
  currentNode: 0,
};

export default withTranslation()(DeliveryRoute);
