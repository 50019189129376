import {
  getCurrentLocation,
  getMobileApiDomain,
} from 'store/modules/region/selectors';
import Fetcher from './Fetcher';
import Schema from './schema';

export const MOBILE_API_VER = 'v5';
const APPTYPE_HEADER = 'USER';
const {
  REACT_APP_VERSION,
  REACT_APP_MOBILE_API,
  REACT_APP_MOBILE_API_BACKUP,
  NODE_ENV,
} = process.env;

const commonHeader = {
  'X-LLM-APPTYPE': APPTYPE_HEADER,
};
// Check /CHANGELOG.md for revision number should be used
const commonParams = {
  version: REACT_APP_VERSION,
  revision: '36',
  os: 'webapp',
  app: 'user',
};

export default class MobileAPIFetcher extends Fetcher {
  static uniqueName = 'MobileAPIFetcher';

  prepareBaseUrl = state => {
    const mobileUrl = getMobileUrl(
      NODE_ENV,
      state,
      window.location.hostname,
      REACT_APP_MOBILE_API,
      REACT_APP_MOBILE_API_BACKUP
    );
    return `${mobileUrl}/api/${MOBILE_API_VER}`;
  };

  prepareHeaders = (method, state, options) => {
    let headers = {
      'X-LLM-LOCATION': getCurrentLocation(state),
      ...commonHeader,
      ...options.headers,
    };
    if (['POST', 'PATCH'].includes(method)) {
      headers = {
        ...headers,
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      };
    }
    return headers;
  };

  prepareQuery = params => ({
    ...commonParams,
    ...params,
  });

  prepareBody = params =>
    new URLSearchParams({
      ...commonParams,
      ...params,
    });

  validate(endpoint, response) {
    return Schema.validate(`${this.prepareBaseUrl()}/`, endpoint, response);
  }
}

export function getMobileUrl(
  nodeEnv,
  state,
  hostname,
  mobileApi,
  mobileApiBackup
) {
  if (nodeEnv !== 'development') {
    // Normal case for legacy system
    if (getMobileApiDomain(state)) {
      return `//${getMobileApiDomain(state)}`;
    }

    // Special case for backup site in other domains
    if (
      !hostname.endsWith('lalamove.com') &&
      !hostname.endsWith('lalamove.net.in') &&
      mobileApiBackup
    ) {
      return mobileApiBackup;
    }
  }

  // Normal case for global system OR localhost
  return mobileApi;
}
