/* eslint-disable camelcase */
import moment from 'moment-timezone';
import { Props } from 'components/Coupon';

interface TimePeriod {
  start_time: string;
  end_time: string;
}

export interface WalletCoupon {
  business_type: string; // hide it (only have delivery type now)
  city_names: string[];
  coupon_id: string;
  discount_amount_fen: number;
  discount_amount: string;
  discount_rate: string;
  discount_type: number;
  packet_name: string;
  order_vehicle_name: string;
  packet_id: string;
  pay_type: number;
  preferential: string;
  reach_fen: string;
  region_type: string;
  status: string;
  time_periods: null | TimePeriod[];
  time_type: string;
  unavailable_status: number;
  user_citys: number[];
  valid_date: string;
  areas: any;
  business_tag: string;
  business_type_str: string; // Business type
  city_available_str: string; // City
  client_type_str: string; // Platform, hide it (only have app now)
  client_type: string;
  coupon_code: string;
  coupon_price: number;
  create_time: string;
  disabled_type: number;
  end_time: string;
  filter_no: number;
  is_read: number;
  num_limit_str: string; // Number restriction
  num_limit: string;
  order_vehicle_id: string;
  order_vehicle_type: string;
  order_vehicles_str: string; // Vehicle type
  order_vehicles: any;
  pay_type_str: string; // Payment
  purpose_type_str: string;
  purpose_type: string;
  ref: string;
  sort_score: number;
  start_time: string;
  tag: string;
  use_time: string;
  valid_time_periods: string; // Time Slot
}

type CouponProps = Pick<
  Props,
  'coupon' | 'details' | 'unavailable' | 'showNewBadge'
>;

const PayType = {
  NO_LIMIT: 3,
};

const UnavailableStatus = {
  AVAILABLE: 0,
  EXPIRED: 2,
  USED: 3,
};

const RegionType = {
  NATIONWIDE: '0',
};

const tzHK = 'Asia/Hong_Kong';

const UTC8DateTimeStringToSecond = (dateTimeString: string) =>
  moment.tz(dateTimeString, tzHK).toDate().getTime() / 1000;

const isNew = (couponCreateTimeString: string): boolean => {
  const oneDayBefore = moment().subtract(1, 'days');
  return moment.tz(couponCreateTimeString, tzHK).isAfter(oneDayBefore);
};

const convertWalletCoupon = (walletCoupon: WalletCoupon): CouponProps => {
  const {
    coupon_code,
    discount_amount_fen,
    discount_rate,
    discount_type,
    packet_name,
    reach_fen,
    start_time,
    end_time,
    city_available_str,
    num_limit_str,
    order_vehicles_str,
    pay_type,
    pay_type_str,
    valid_time_periods,
    unavailable_status,
    use_time,
    create_time,
    region_type,
  } = walletCoupon;

  const start_ts = UTC8DateTimeStringToSecond(start_time);
  const end_ts = UTC8DateTimeStringToSecond(end_time);

  const coupon: Props['coupon'] = {
    start_ts,
    end_ts,
    name: packet_name,
    coupon_code,
    discount_rate: parseFloat(discount_rate),
    reach_fen: parseFloat(reach_fen),
    discount_amount_fen,
    discount_type,
  };

  if (unavailable_status === UnavailableStatus.USED) {
    coupon.used_ts = UTC8DateTimeStringToSecond(use_time);
  }

  const details = [
    region_type === RegionType.NATIONWIDE ? '' : city_available_str,
    pay_type === PayType.NO_LIMIT ? '' : pay_type_str,
    order_vehicles_str,
    num_limit_str,
    valid_time_periods,
  ].filter(Boolean);

  return {
    coupon,
    details,
    unavailable: unavailable_status !== UnavailableStatus.AVAILABLE,
    showNewBadge: isNew(create_time),
  };
};

export default convertWalletCoupon;
