let scrollPosition = 0;

let isEnabled = false;
let isDisabled = false;
let enableStackCount = 0;
let disableStackCount = 0;

// https://markus.oberlehner.net/blog/simple-solution-to-prevent-body-scrolling-on-ios/
export const enable = (): void => {
  if (isEnabled) {
    enableStackCount += 1;
  } else {
    scrollPosition = window.pageYOffset;
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition}px`;
    document.body.style.width = '100%';
    isDisabled = false;
    isEnabled = true;
    if (disableStackCount > 0) {
      disableStackCount -= 1;
      disable();
    }
  }
};

export const disable = (): void => {
  if (isDisabled) {
    disableStackCount += 1;
  } else {
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('top');
    document.body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
    isEnabled = false;
    isDisabled = true;
    if (enableStackCount > 0) {
      enableStackCount -= 1;
      enable();
    }
  }
};

// eslint-disable-next-line no-underscore-dangle
export const __reset__ = (): void => {
  isEnabled = false;
  isDisabled = false;
  enableStackCount = 0;
  disableStackCount = 0;
};
