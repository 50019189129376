import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bool, string, func, oneOf, shape } from 'prop-types';
import styled, { css } from 'styled-components';
import { Alert as LLMAlert } from '@lalamove/karang';
import _some from 'lodash/some';

import {
  MessageShape,
  makeMessageSelector,
  dismissMessage,
} from 'store/modules/message';
import { HEADER_HEIGHT } from 'views/Header/style';
import { ALERT } from 'styles/zIndex';
import { noop } from 'utils/helpers';
import { withTranslation } from 'react-i18next';

const DISMISS_TIMEOUT_MS = 3000;
const TRANSITION_TIME_MS = 300;

const StyledAlert = styled(LLMAlert)`
  position: absolute;
  z-index: ${ALERT};
  box-sizing: border-box;
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  visibility: ${({ isShown }) => (isShown ? `visible` : `hidden`)};
  transition: opacity ${TRANSITION_TIME_MS}ms;

  ${({ variant }) => {
    switch (variant) {
      case 'toast':
        return css`
          top: ${HEADER_HEIGHT + 1}rem;
          right: 1rem;
        `;
      default:
        return css`
          top: 0;
          width: 100%;
        `;
    }
  }};
`;

export class Alert extends Component {
  static propTypes = {
    t: func,
    id: string,
    type: MessageShape.type,
    title: string,
    message: string,
    variant: oneOf(['toast', 'default']),
    persist: bool,
    isShown: bool,
    dismissMessage: func,
    params: shape({}),
  };

  static defaultProps = {
    t: noop,
    id: null,
    type: 'error',
    title: null,
    message: '',
    variant: 'default',
    persist: false,
    isShown: false,
    dismissMessage: noop,
    params: {},
  };

  static dismissTimeout = null;

  componentDidMount() {
    const { persist, isShown } = this.props;
    if (isShown && !persist) {
      // dismiss alert after 3 secs
      this.dismissTimeout = setTimeout(() => {
        this.onDismiss();
      }, DISMISS_TIMEOUT_MS);
    }
  }

  componentDidUpdate(prevProps) {
    const { persist, isShown } = this.props;
    if (!prevProps.isShown && isShown && !persist) {
      // dismiss alert after 3 secs
      this.dismissTimeout = setTimeout(() => {
        this.onDismiss();
      }, DISMISS_TIMEOUT_MS);
    }
  }

  componentWillUnmount() {
    if (this.dismissTimeout) clearTimeout(this.dismissTimeout);
  }

  onDismiss = () => {
    const { id, dismissMessage } = this.props; // eslint-disable-line no-shadow
    dismissMessage(id);
  };

  render() {
    const { t, type, title, message, variant, isShown, params } = this.props;

    return (
      <StyledAlert
        type={type}
        isShown={isShown}
        variant={variant}
        message={title ? t(title, params) : t(message, params)}
        description={title ? t(message, params) : null}
        onDismiss={this.onDismiss}
      />
    );
  }
}

const mapState = (state, { messageSelector }) => {
  const { id, type, title, message } =
    makeMessageSelector(messageSelector)(state) || {};
  return {
    id,
    type,
    title,
    message,
    isShown: _some([title, message]),
  };
};

export default compose(
  withTranslation(),
  connect(mapState, { dismissMessage })
)(Alert);
