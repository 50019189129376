import React, { isValidElement } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { openSideMenu } from 'store/modules/ui';

import DrawerIcon from 'assets/svg/mobile-nav-drawer-icon.svg';
import BackIcon from 'assets/svg/mobile-nav-back-icon.svg';

import { Container, Button, Center, Heading } from './styles';

interface Props {
  left?: 'back' | 'drawer' | JSX.Element;
  onClickLeft?: () => void;
  center?: string | JSX.Element;
  right?: JSX.Element;
}

const MobileHeader: React.FC<Props> = ({
  left = 'back',
  onClickLeft,
  center = '',
  right,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Container>
      {renderLeftComp(left, onClickLeft, history, dispatch)}
      <Center>{renderCenterComp(center)}</Center>
      {renderRightComp(right)}
    </Container>
  );
};

const renderLeftComp = (
  left: Props['left'],
  customOnClick: Props['onClickLeft'],
  history: History,
  dispatch: Dispatch
) => {
  if (isValidElement(left)) {
    return left;
  }
  const defaultOnBack = () => history.goBack();
  const defaultOnOpenDrawer = () => dispatch(openSideMenu());

  if (left === 'drawer') {
    return (
      <Button
        icon={DrawerIcon}
        onClick={customOnClick ?? defaultOnOpenDrawer}
      />
    );
  }
  return <Button icon={BackIcon} onClick={customOnClick ?? defaultOnBack} />;
};

const renderCenterComp = (center: Props['center']) => {
  if (!center) {
    return null;
  }
  if (isValidElement(center)) {
    return center;
  }
  return <Heading>{center}</Heading>;
};

const renderRightComp = (right: Props['right']) => {
  if (isValidElement(right)) {
    return right;
  }
  return null;
};

export default MobileHeader;
