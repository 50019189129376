import React, { Component } from 'react';
import { bool, func, string, shape, number, instanceOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { getOrder } from 'store/modules/records';
import { closeDialog } from 'store/modules/ui';
import { getCurrentCity } from 'store/modules/region/selectors';
import { actions } from 'interfaces/global/store/modules/genesys/actions';
import openGenesysWebChat from 'interfaces/global/store/modules/genesys/helpers';
import {
  getIsScriptLoaded,
  getConfig,
} from 'interfaces/global/store/modules/genesys/selectors';

import Dialog from 'components/Dialog';
import { noop } from 'utils/helpers';

export class CancelOrderFailedDialog extends Component {
  static defaultProps = {
    t: noop,
    status: null,
    closeDialog: noop,
  };

  static propTypes = {
    t: func,
    city: shape({
      csPhone: string,
    }).isRequired,
    orderId: string.isRequired,
    status: number,
    isScriptLoaded: bool.isRequired,
    configGenesys: instanceOf(Object).isRequired,
    openWidget: func.isRequired,
    closeWidget: func.isRequired,
    closeDialog: func,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.handleClose();
    }
  }

  handleOpenChat = () => {
    const {
      isScriptLoaded,
      openWidget,
      closeWidget,
      closeDialog, // eslint-disable-line no-shadow
      configGenesys,
    } = this.props; // eslint-disable-line no-shadow
    openGenesysWebChat(isScriptLoaded, configGenesys, openWidget, closeWidget);
    closeDialog();
  };

  handleClose = () => {
    const { closeDialog } = this.props; // eslint-disable-line no-shadow
    closeDialog();
  };

  render() {
    const { t, city, ...props } = this.props; // eslint-disable-line no-shadow

    return (
      <Dialog
        title={t('RecordPanel.dialog_title_cancel_order_failed')}
        subtitle={t('RecordPanel.dialog_text_cancel_order_failed_live_chat')}
        primaryButtonText={t('RecordPanel.dialog_button_live_chat')}
        primaryAction={this.handleOpenChat}
        secondaryButtonText={t('RecordPanel.button_dismiss')}
        secondaryAction={this.handleClose}
        onRequestClose={this.handleClose}
        shouldCloseOnOverlayClick={false}
        {...props}
      />
    );
  }
}

const mapStateToProps = (state, { orderId }) => {
  const { status } = getOrder(state, orderId) || {};
  return {
    status,
    city: getCurrentCity(state),
    isScriptLoaded: getIsScriptLoaded(state),
    configGenesys: getConfig(state),
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    closeDialog,
    openWidget: actions.openWidget,
    closeWidget: actions.closeWidget,
  })
)(CancelOrderFailedDialog);
