import {
  CHANGE_LOCATION_SUCCESS,
  CHANGE_LOCALE_SUCCESS,
} from 'store/modules/region/actions';
import { LOCATION_REGEX } from 'utils/helpers';

const regionTracking = (prevState, action, nextState) => {
  switch (action.type) {
    case CHANGE_LOCATION_SUCCESS: {
      const { location } = action;
      const [, country] = LOCATION_REGEX.exec(location);
      window.analytics.identify({
        city: location,
        country,
      });
      break;
    }
    case CHANGE_LOCALE_SUCCESS: {
      const { locale } = action;
      window.analytics.identify({
        language: locale.slice(0, 2),
      });
      break;
    }
    default:
      break;
  }
};

export default regionTracking;
