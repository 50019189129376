import React, { Component } from 'react';
import { node } from 'prop-types';

import { noop } from 'utils/helpers';

import { zEInstance, zopimInstance, createZendeskScriptTag } from './instances';

const initContext = {
  zE: null,
  zopim: null,
  bindOnce: noop,
};

const zendeskContext = React.createContext();

class zendeskProvider extends Component {
  static propTypes = {
    children: node.isRequired,
  };

  static eventMap = {
    // CAUTION: dun use open / close event as it is unstable
    // open event will be trigger by js call open + native button
    open: 'open',
    // close event will be trigger by js call close + top right close
    // exclude Cancel button + Done button
    close: 'close',
    'chat:start': 'chat:start',
    'chat:unreadMessages': 'chat:unreadMessages',
  };

  state = {
    ...initContext,
    events: {},
  };

  async componentDidMount() {
    if (!process.env.REACT_APP_ENABLE_ZENDESK) return;

    createZendeskScriptTag();
    const zE = await zEInstance();
    zE('webWidget', 'hide');
    const zopim = await zopimInstance();
    this.setState({ zE, zopim });
  }

  // EXTEND: zendesk widget dun support unregister events
  bindOnce = (command, cb) => {
    const { zE, events } = this.state;
    const c = zendeskProvider.eventMap[command] || null;
    if (c) {
      if (events[c]) return;
      zE('webWidget:on', c, cb);
      this.setState(p => ({
        events: { ...p.events, [c]: true },
      }));
    }
  };

  render() {
    const { children } = this.props;
    const { zE, zopim } = this.state;
    return (
      <zendeskContext.Provider value={{ zE, zopim, bindOnce: this.bindOnce }}>
        {children}
      </zendeskContext.Provider>
    );
  }
}

const zendeskConsumer = zendeskContext.Consumer;

export {
  zendeskContext as default,
  zendeskProvider as Provider,
  zendeskConsumer as Consumer,
};
