import { Button, CountdownBar } from '@lalamove/karang';
import React from 'react';
import { waitDuration } from 'interfaces/global/containers/ForgotPassword';
import { bool, func } from 'prop-types';

// Using a PureComponent so the CountdownBar duration animation is not reset if
// ResendContent attempts to rerender.
export default class ResendContent extends React.PureComponent {
  static propTypes = {
    isWaiting: bool,
    loading: bool,
    t: func.isRequired,
    handleResendRequest: func.isRequired,
  };

  static defaultProps = {
    loading: false,
    isWaiting: true,
  };

  render() {
    const { isWaiting, loading, handleResendRequest, t } = this.props;

    if (isWaiting) {
      return (
        <CountdownBar
          label={t('Verification.resend_sms')}
          duration={`${waitDuration}ms`}
        />
      );
    }

    return (
      <Button
        type="button"
        variant="primary"
        solid
        onClick={handleResendRequest}
        size="large"
        isLoading={loading}
        block
      >
        {t('Verification.resend_sms')}
      </Button>
    );
  }
}
