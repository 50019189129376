import { LalamoveApiError } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Request {
//   access_token: String;
//   client_id: Number | String;
//   country: String; // HK_HKG
//   lang: String; // en_hk
//   args: String; // { offset:0, required: 10 }
// }
// interface Response {
//   data: {
//     addressDetails: { block: String, floor: String, room: String },
//     addressStr: String,
//     contactPerson: { name: String, phone: String },
//     id: String,
//     lat: Number,
//     lng: Number,
//     pathId: Number,
//     placeId: String,
//     txUpdate: Number, // unix time
//   }[];
//   success: Boolean;
//   footprint: String;
// }

export default async function fetchLocationSearchHistory() {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, city, locale } = await fetcher.getState();
  if (accessToken === '') {
    throw new LalamoveApiError('not authorized');
  }
  const { success, data, code } = await fetcher.get('vanrecentlocation', {
    access_token: accessToken,
    client_id: clientId,
    country: city,
    lang: locale,
    args: JSON.stringify({ offset: 0, require: 10 }),
  });

  if (!success) throw new LalamoveApiError(code);

  return data;
}
