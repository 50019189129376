import React from 'react';
import { bool, string, func, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { getPanel, closePanel } from 'store/modules/ui';
import { noop } from 'utils/helpers';
import SliderPanel from 'components/SliderPanel';
import Order from './Order';
import Help from './Help';

const COMPONENTS = {
  ORDER: Order,
  HELP: Help,
};

// Root Panel component
export const PanelRoot = ({ id, props, isOpen, onClose }) => {
  const Comp = COMPONENTS[id];
  if (!id || !Comp) return null;
  return (
    <SliderPanel isOpen={isOpen} onClose={onClose} shouldCloseOnClickOutside>
      <Comp {...props} onClose={onClose} />
    </SliderPanel>
  );
};

PanelRoot.defaultProps = {
  id: null,
  props: {},
  isOpen: false,
  onClose: noop,
};

PanelRoot.propTypes = {
  id: string,
  props: shape({}),
  isOpen: bool,
  onClose: func,
};

const mapStateToProps = state => {
  const { id, props, isOpen } = getPanel(state);
  return { id, props, isOpen };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, { onClose: closePanel })
)(PanelRoot);
