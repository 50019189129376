import styled from 'styled-components';
import defaultBg from 'assets/bg.jpg';
import { Card } from '@lalamove/karang';

export const ResponsiveCard = styled(Card)`
  width: 100vw;
  max-width: 480px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  min-height: 100vh;
  background-color: #e5e8ed;
  background-image: url(${({ bgImage }) => bgImage || defaultBg});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;

export default Container;
