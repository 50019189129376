import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { bool, func, number, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Flag, Rating } from '@lalamove/karang';
import {
  dropoff as DropoffIcon,
  star as StarIcon,
  starFilled as StarFilledIcon,
} from '@lalamove/karang/dist/components/Icon/icons';
import {
  OLD_RATE_ORDER_API_DAYS_RANGE,
  NEW_RATE_ORDER_API_DAYS_RANGE,
  NEW_RATE_ORDER_DEPLOYMENT_TIMESTAMP,
} from 'api/uAPI/rateOrder.ts';
import { gold } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import styled from 'styled-components';
import {
  favoriteDriver,
  banDriver,
} from 'interfaces/global/store/modules/records/actions';
import { openModal, closeModal, isModalOpen } from 'store/modules/ui';
import {
  getServiceName,
  getServiceIcon,
} from 'interfaces/global/store/modules/services/selectors';
import { createLoadingSelector } from 'store/modules/loading';
import { withResponsiveMedia } from 'components/MediaQuery';
import { noop, globalOrderStatusMap as statusMap } from 'utils/helpers';

import avatarUrl from 'assets/svg/users-avatar.svg';
import defaultIcon from 'assets/generic.png';

import {
  Wrapper,
  PhotoFrame,
  BannedPhotoOverlay,
  Name,
  Label,
  AuxInfo,
  DriverDetails,
  DetailContent,
  Text,
  LinkButton,
  Separator,
  TrackButton,
} from './style';

const cancelled = [
  statusMap.CANCELLED.id,
  statusMap.DRIVER_REJECTED.id,
  statusMap.ORDER_TIMEOUT.id,
  statusMap.ORDER_NOPAY.id,
  statusMap.ORDER_CANCELLED_FIVE_DAYS.id,
  statusMap.ORDER_CANCELLED_TEN_DAYS.id,
];
const mdash = '\u2014';

const ManageDriverDesktopSizeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export class DriverInfo extends Component {
  static defaultProps = {
    id: null,
    name: null,
    avgRating: null,
    vehicleTypeId: null,
    photoUri: '',
    licensePlate: '',
    phone: '',
    favorited: false,
    banned: false,
    serviceName: '',
    serviceIcon: '',
    userRating: 0,
    orderCompletionDatetime: 0,
    showTrackBtn: true,
    isVIPOrder: false,
    isLoading: false,
    onRate: noop,
    onTrack: noop,
    onToggleDialog: noop,
    banDriver: noop,
    favoriteDriver: noop,
    t: noop,
    openModal: noop,
    closeModal: noop,
    isFavBanModalOpen: false,
  };

  static propTypes = {
    id: string,
    name: string,
    avgRating: number,
    vehicleTypeId: string,
    photoUri: string,
    licensePlate: string,
    phone: string,
    favorited: bool,
    banned: bool,
    status: number.isRequired,
    serviceName: string,
    serviceIcon: string,
    userRating: number,
    orderCompletionDatetime: number,
    showTrackBtn: bool,
    isVIPOrder: bool,
    isLoading: bool,
    onRate: func,
    onTrack: func,
    onToggleDialog: func,
    banDriver: func,
    favoriteDriver: func,
    t: func,
    isMobile: bool.isRequired,
    openModal: func,
    closeModal: noop,
    isFavBanModalOpen: bool,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.props.closeModal();
      this.props.onToggleDialog(false);
    }
  }

  handleFallBack = e => {
    e.target.src = avatarUrl;
  };

  openFavDriverModal = () => {
    // eslint-disable-next-line no-shadow
    const { t, openModal, onToggleDialog } = this.props;
    openModal('FAVORITE_BAN', {
      label: t('RecordPanel.msg_order_assign_to_driver_again'),
      cancelText: t('Drivers.dialog_button_cancel'),
      confirmText: t('RecordPanel.label_favorite_driver'),
      onCancel: this.handleCancelClick,
      onConfirm: this.handleFavClick,
    });
    onToggleDialog(true);
  };

  handleFavClick = () => {
    // eslint-disable-next-line no-shadow
    const { favoriteDriver, id } = this.props;
    favoriteDriver(id);
  };

  openBanDriverModal = () => {
    // eslint-disable-next-line no-shadow
    const { t, openModal, onToggleDialog } = this.props;
    openModal('FAVORITE_BAN', {
      label: t('RecordPanel.msg_order_not_assign_to_driver_again'),
      cancelText: t('Drivers.dialog_button_cancel'),
      confirmText: t('RecordPanel.label_ban_driver'),
      onCancel: this.handleCancelClick,
      onConfirm: this.handleBanClick,
    });
    onToggleDialog(true);
  };

  handleBanClick = () => {
    // eslint-disable-next-line no-shadow
    const { banDriver, id } = this.props;
    banDriver(id);
  };

  handleCancelClick = () => {
    this.props.closeModal();
    this.props.onToggleDialog(false);
  };

  renderDriverDetails = items => (
    <DriverDetails>
      {items.map(item => (
        <span key={item.title || Math.random()}>
          {item.title && <Label>{item.title}</Label>}
          <DetailContent>
            {item.type === 'phone' && item.content !== mdash ? (
              <a href={`tel:${item.content}`}>{item.content}</a>
            ) : (
              item.content
            )}
          </DetailContent>
        </span>
      ))}
    </DriverDetails>
  );

  shouldShowDriverDetails = status =>
    status === statusMap.ON_GOING.id ||
    status === statusMap.ORDER_LOADED.id ||
    status === statusMap.LOADING.id ||
    status === statusMap.UNLOADING.id;

  getRateOrderComponents = () => {
    const { orderCompletionDatetime, userRating, t } = this.props;

    if (userRating) return <Rating value={userRating} size={7} />;

    // As the backend Redis database does not have up to 90 days of rate order history
    // immediately when we deploy this date range change, we need to gradually increase
    // the number of days into the past that orders can be rated up until we get to 90
    // days of history after NEW_RATE_ORDER_DEPLOYMENT_TIMESTAMP
    const orderCompleteMoment = moment(orderCompletionDatetime);
    const canStillBeRated =
      orderCompleteMoment.isAfter(
        moment().subtract(NEW_RATE_ORDER_API_DAYS_RANGE, 'days')
      ) &&
      orderCompleteMoment.isAfter(
        moment(NEW_RATE_ORDER_DEPLOYMENT_TIMESTAMP).subtract(
          OLD_RATE_ORDER_API_DAYS_RANGE,
          'days'
        )
      );

    if (canStillBeRated && !userRating)
      return (
        <Button onClick={this.props.onRate} icon={<StarIcon />}>
          {t('RecordPanel.button_rate_driver')}
        </Button>
      );

    return null;
  };

  renderInfo = ({
    status,
    licensePlate,
    phone,
    favorited,
    banned,
    userRating,
    t,
  }) => {
    if (this.shouldShowDriverDetails(status)) {
      const info = [
        {
          title: t('RecordPanel.label_license_plate'),
          content: licensePlate || mdash,
        },
        {
          title: t('RecordPanel.label_mobile_number'),
          content: phone || mdash,
          type: 'phone',
        },
      ];
      return this.renderDriverDetails(info);
    }

    if (
      status === statusMap.COMPLETED.id ||
      status === statusMap.DRIVER_COMPLETED.id ||
      status === statusMap.ABNORMAL_COMPLETED.id ||
      status === statusMap.SETTLEMENT_CASH.id ||
      status === statusMap.SEND_BILL.id ||
      status === statusMap.COMPLAIN_BILL.id
    ) {
      const driverMgmtButtons = (
        <span>
          <LinkButton variant="link" onClick={this.openBanDriverModal}>
            {t('RecordPanel.button_ban_driver')}
          </LinkButton>
          <Separator>{t('RecordPanel.msg_or')}</Separator>
          <LinkButton variant="link" onClick={this.openFavDriverModal}>
            {t('RecordPanel.button_favorite_driver')}
          </LinkButton>
        </span>
      );

      const driverStatus = favorited
        ? t('RecordPanel.label_driver_favorited')
        : t('RecordPanel.label_driver_banned');

      const driverRating = this.getRateOrderComponents();

      if (this.props.isMobile)
        return this.renderDriverDetails([
          {
            title: t('RecordPanel.label_manage_driver'),
            content: !favorited && !banned ? driverMgmtButtons : driverStatus,
          },
          {
            title: userRating !== 0 && t('RecordPanel.label_driver_rated'),
            content: driverRating,
          },
        ]);

      return (
        <ManageDriverDesktopSizeContainer>
          {this.renderDriverDetails([
            {
              title: t('RecordPanel.label_manage_driver'),
              content: !favorited && !banned ? driverMgmtButtons : driverStatus,
            },
          ])}
          {this.renderDriverDetails([
            {
              title: userRating !== 0 && t('RecordPanel.label_driver_rated'),
              content: driverRating,
            },
          ])}
        </ManageDriverDesktopSizeContainer>
      );
    }
    return null;
  };

  render() {
    const {
      id,
      name,
      phone,
      licensePlate,
      avgRating,
      vehicleTypeId,
      photoUri,
      favorited,
      banned,
      status,
      serviceName,
      serviceIcon,
      userRating,
      isVIPOrder,
      t,
      isFavBanModalOpen,
    } = this.props;

    let vehicleIcon = serviceIcon || defaultIcon;
    let vehicleName = serviceName || vehicleTypeId;
    if (isVIPOrder || vehicleTypeId === 'VIP') {
      vehicleIcon = defaultIcon;
      vehicleName = 'VIP';
    }

    if (cancelled.includes(status) && !id) return null;

    return (
      <>
        <Wrapper>
          {!isFavBanModalOpen && (
            <AuxInfo>
              {Boolean(avgRating) && (
                <>
                  <StarFilledIcon color={gold} size={12} />
                  {'\u00A0' /* nbsp */}
                  <Text>{avgRating}</Text>
                </>
              )}
            </AuxInfo>
          )}
          <Flag
            variant="top"
            image={
              <div style={{ position: 'relative' }}>
                <PhotoFrame
                  style={{ boxShadow: photoUri ? '0 0 0 1px silver' : 'none' }}
                  src={photoUri || avatarUrl}
                  alt={name}
                  onError={this.handleFallBack}
                />
                {banned && <BannedPhotoOverlay />}
              </div>
            }
          >
            <Label>
              <img
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  maxHeight: fontSize.xlarge,
                }}
                src={vehicleIcon}
                alt={vehicleName}
              />
              {'\u00A0'}
              <Text>{vehicleName}</Text>
            </Label>
            <Name>{name || t('RecordPanel.label_driver_matching')}</Name>
            {this.renderInfo({
              status,
              licensePlate,
              phone,
              favorited,
              banned,
              userRating,
              t,
            })}
          </Flag>
        </Wrapper>
        {this.shouldShowDriverDetails(status) && this.props.showTrackBtn && (
          <TrackButton icon={<DropoffIcon />} onClick={this.props.onTrack}>
            {t('RecordPanel.button_track_order')}
          </TrackButton>
        )}
      </>
    );
  }
}

const mapState = (state, { serviceTypeId }) => ({
  serviceName: getServiceName(state, serviceTypeId),
  serviceIcon: getServiceIcon(state, serviceTypeId, 'default'),
  isLoading: createLoadingSelector(['FAVORITE_DRIVER', 'BAN_DRIVER'])(state),
  isFavBanModalOpen: isModalOpen('FAVORITE_BAN')(state),
});

export default compose(
  withTranslation(),
  withResponsiveMedia,
  connect(mapState, { favoriteDriver, banDriver, openModal, closeModal })
)(DriverInfo);
