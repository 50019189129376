import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { arrayOf, func, string, shape } from 'prop-types';

import { SEGMENT_ADD_WAYPOINT } from 'store/modules/placeOrder/tracking';
import {
  getWaypoints,
  rearrangeRoute,
  newWaypt,
  removeWaypt,
} from 'store/modules/routing';
import { DRAFT_ORDER_ID } from 'store/modules/routing/config';
import { getPlacingLocationHistory } from 'store/modules/searchHistory';
import Segment from 'utils/segment';
import { LocationShape } from './propTypes';
import StopList from './components/StopList';

class Editor extends Component {
  static defaultProps = {
    orderId: DRAFT_ORDER_ID,
  };

  static propTypes = {
    orderId: string,
    rearrangeRoute: func.isRequired,
    newWaypt: func.isRequired,
    removeWaypt: func.isRequired,
    waypoints: arrayOf(LocationShape).isRequired,
    placingHistory: arrayOf(shape({})).isRequired,
  };

  handleReorder = (from, to) => {
    const { orderId } = this.props;
    this.props.rearrangeRoute(
      {
        sourceIndex: from,
        destinationIndex: to,
      },
      orderId
    );
  };

  handleNewWaypt = () => {
    const { orderId } = this.props;
    this.props.newWaypt(orderId);

    // tracking
    // only in Place Order page
    if (orderId === DRAFT_ORDER_ID) {
      Segment.createTrack(SEGMENT_ADD_WAYPOINT);
    }
  };

  handleRemoveWaypt = id => {
    const { orderId } = this.props;
    this.props.removeWaypt(id, orderId);
  };

  render() {
    const { waypoints, orderId, placingHistory } = this.props;

    return (
      <StopList
        isEditMode={orderId !== DRAFT_ORDER_ID}
        history={placingHistory}
        waypoints={waypoints}
        onRemove={this.handleRemoveWaypt}
        onReorder={this.handleReorder}
        onCreate={this.handleNewWaypt}
      />
    );
  }
}

const mapState = (state, ownProps) => ({
  waypoints: getWaypoints(state.routing, ownProps.orderId),
  placingHistory: getPlacingLocationHistory(state),
});

export default compose(
  connect(mapState, {
    rearrangeRoute,
    newWaypt,
    removeWaypt,
  })
)(Editor);
