import WebFont from 'webfontloader';
import { fontWeight, fontSize } from '@lalamove/karang/dist/styles/fonts';

WebFont.load({
  custom: {
    families: ['Noto Sans:n4,n7', 'Noto Sans TC:n4,n7'],
    urls: [`${process.env.PUBLIC_URL}/fonts/index.css`],
  },
  timeout: 3000,
});

export const primaryFonts = 'Noto Sans, "Noto Sans TC", sans-serif';

export { fontWeight, fontSize };
