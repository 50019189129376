import { getRestApiDomain } from 'store/modules/region/selectors';

import Fetcher from './Fetcher';

const { REACT_APP_REST_API, NODE_ENV } = process.env;

export const REST_API_VER = 'v3';

export default class RestAPINoDefaultHeadersFetcher extends Fetcher {
  static uniqueName = 'RestAPIFetcher';

  prepareBaseUrl = state => {
    let restUrl = getRestApiDomain(state)
      ? `//${getRestApiDomain(state)}`
      : REACT_APP_REST_API;
    if (NODE_ENV === 'development') {
      restUrl = REACT_APP_REST_API;
    }
    return `${restUrl}/${REST_API_VER}`;
  };

  prepareHeaders = (method, state, options) => ({
    ...options.headers,
  });

  prepareQuery = params => params;

  prepareBody = params => JSON.stringify({ data: params });
}
