import storage from 'utils/storage';

import { PaymentMethods } from 'interfaces/global/store/modules/checkout/types';

type UserPaymentMethods = {
  [userFid: string]: string;
};

export const KEY = 'last_used_payment_method';

export const loadDefaultPaymentMethodOptions = (): UserPaymentMethods => {
  const json: string = storage.getItem(KEY);
  return JSON.parse(json) || {};
};

export const loadDefaultPaymentMethodOption = (userFid: string): number => {
  const paymentMethods: UserPaymentMethods = loadDefaultPaymentMethodOptions();

  const loadedMethod = paymentMethods[userFid];

  if (loadedMethod) {
    const loadedMethodId = PaymentMethods[loadedMethod.toUpperCase()]?.id;

    if (loadedMethodId >= 0) return loadedMethodId;
  }

  return PaymentMethods.ONLINE.id;
};

export const saveDefaultPaymentMethodOption = (
  userFid: string,
  paymentMethodId: number
): void => {
  const paymentMethods: UserPaymentMethods = loadDefaultPaymentMethodOptions();

  const paymentMethod = Object.values(PaymentMethods).find(
    method => method.id === paymentMethodId
  );
  paymentMethods[userFid] = paymentMethod?.name || PaymentMethods.ONLINE.name;

  storage.setItem(KEY, JSON.stringify(paymentMethods));
};
