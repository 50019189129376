import styled from 'styled-components';
import { gray, darkGray, offWhite } from 'styles/colors';
import { whenDesktop, whenMobile } from 'components/MediaQuery';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (Component) {
  return styled(Component)`
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    padding: 1em;
    border: 1px solid ${darkGray};

    ${whenMobile} {
      height: 44px;
      padding: 12px;
    }

    ${whenDesktop} {
      border-left: 0;
    }
    cursor: pointer;
    &:hover,
    &:active {
      background-color: #f1f1f1;
    }

    input.rc-time-picker-input {
      height: auto;
      padding: 0;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      color: ${gray};
      font-size: 14px;
      ${whenMobile} {
        font-size: 16px;
      }
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }
    input.rc-time-picker-input::placeholder {
      color: ${gray};
    }

    .llm-time-picker-popup {
      width: 100%;
      max-width: inherit;
      .rc-time-picker-panel-inner {
        top: 3px;
        left: -1px;
        width: calc(100% + 1px);
        border: 1px solid ${darkGray};
        border-radius: 2px 2px 0 0;
        box-shadow: none;
      }
      .rc-time-picker-panel-input-wrap {
        padding: 1em;
        border-bottom: 1px solid ${darkGray};
        font-size: 14px;
        ${whenMobile} {
          font-size: 16px;
        }
        cursor: pointer;
        &:hover,
        &:active {
          background-color: #f1f1f1;
        }
      }
      .rc-time-picker-panel-input {
        height: 100%;
        min-height: 24px;
        background-color: transparent;
        color: ${gray};
        cursor: pointer;
      }
      .rc-time-picker-panel-input::placeholder {
        color: ${gray};
      }
      .rc-time-picker-panel-combobox {
        display: flex;
      }
      .rc-time-picker-panel-select {
        flex-grow: 1;
        li {
          padding: 0;
          border-bottom: 1px solid ${offWhite};
          text-align: center;
          &:hover {
            background-color: rgba(242, 242, 242, 1);
          }
        }
      }
      li.rc-time-picker-panel-select-option-selected {
        background-color: rgba(242, 242, 242, 1);
      }
    }
  `;
}
