import React, { Component } from 'react';
import { oneOf, string, func, bool } from 'prop-types';
import { RadioGroup } from '@lalamove/karang';
import {
  cash as CashIcon,
  wallet as WalletIcon,
  pricing as PricingIcon,
} from '@lalamove/karang/dist/components/Icon/icons';
import { orange, gray, green } from 'styles/colors';
import { noop } from 'utils/helpers';
import {
  Box,
  Con,
  Form,
  FormGroup,
  FormItem,
  ScInput,
  RadioLabel,
  CheckoutHeading,
  CheckIcon,
  Spinner,
} from '../style';

import { ICON_SIZE, ICON_COLOR } from '../config';

class Payment extends Component {
  static defaultProps = {
    title: '',
    method: 'cash',
    promoCode: '',
    promoCodeErrorMsg: '',
    labelPromoCode: '',
    labelWallet: 'Wallet',
    labelCash: 'Cash',
    onChange: noop,
    onPromoCodeChange: noop,
    isFetchingPrice: true,
  };

  static propTypes = {
    title: string,
    method: oneOf(['wallet', 'cash']),
    promoCode: string,
    promoCodeErrorMsg: string,
    labelPromoCode: string,
    labelWallet: string,
    labelCash: string,
    onChange: func,
    onPromoCodeChange: func,
    isFetchingPrice: bool,
  };

  handleCodeChange = ({ target: { value } }) => {
    this.props.onPromoCodeChange(value);
  };

  render() {
    const {
      title,
      method,
      promoCode,
      promoCodeErrorMsg,
      labelPromoCode,
      labelWallet,
      labelCash,
      onChange,
      isFetchingPrice,
    } = this.props;
    const options = [
      {
        icon: WalletIcon,
        label: labelWallet,
        value: 'wallet',
      },
      {
        icon: CashIcon,
        label: labelCash,
        value: 'cash',
      },
    ];

    return (
      <Form>
        <CheckoutHeading text={title}>
          <PricingIcon color={ICON_COLOR} size={ICON_SIZE} />
        </CheckoutHeading>
        <RadioGroup
          name="payment"
          value={method}
          onChange={onChange}
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          {RadioButton => (
            <FormGroup>
              {options.map(({ icon: IconCom, label, value }) => (
                <FormItem bordered key={value}>
                  <Box>
                    <RadioButton value={value}>
                      <Con>
                        <IconCom
                          color={method === value ? orange : gray}
                          size={24}
                        />
                      </Con>
                      <RadioLabel checked={method === value}>
                        {label}
                      </RadioLabel>
                    </RadioButton>
                  </Box>
                </FormItem>
              ))}
            </FormGroup>
          )}
        </RadioGroup>
        <FormItem>
          <ScInput
            label={labelPromoCode}
            name="code"
            value={promoCode}
            error={promoCodeErrorMsg}
            onChange={this.handleCodeChange}
          />
          {isFetchingPrice ? (
            <Spinner />
          ) : (
            !!promoCode &&
            !promoCodeErrorMsg && <CheckIcon size={ICON_SIZE} color={green} />
          )}
        </FormItem>
      </Form>
    );
  }
}

export default Payment;
