import { all, put, takeEvery, take, fork, cancel } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  WAYPOINT_REMOVE,
  WAYPOINT_UPDATE,
  ROUTE_REARRANGE,
  DELIVERY_INFO_UPDATE,
} from 'store/modules/routing/actionTypes';
import { INIT_EDIT_SUCCESS } from 'store/modules/records/actionTypes';

// ===== action =====
const MARK_DIRTY = 'MARK_DIRTY';
const RESET = 'FORM:RESET';

const markDirty = formName => ({
  type: MARK_DIRTY,
  formName,
});
const reset = () => ({ type: RESET });

// ===== selector =====
export const isFormDirty = formName => state => state.form[formName].isDirty;

// ===== reducer =====
const initState = {
  editOrder: { isDirty: false },
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case RESET:
      return initState;
    case MARK_DIRTY: {
      const { formName } = action;
      return {
        ...state,
        [formName]: { isDirty: true },
      };
    }
    default:
      return state;
  }
}

// ===== saga ======
function* watchEditOrderFormChange() {
  yield takeEvery(
    [WAYPOINT_REMOVE, WAYPOINT_UPDATE, ROUTE_REARRANGE, DELIVERY_INFO_UPDATE],
    function* markEditOrderDirty() {
      yield put(markDirty('editOrder'));
    }
  );
}

function* watchEditOrderForm() {
  while (true) {
    yield take(INIT_EDIT_SUCCESS);
    const job = yield fork(watchEditOrderFormChange);
    // assume location change = exit edit page = unmount edit form
    yield take([LOCATION_CHANGE]);
    yield put(reset());
    yield cancel(job);
  }
}

export function* saga() {
  yield all([watchEditOrderForm()]);
}
