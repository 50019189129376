import React, { Component } from 'react';
import { bool, string, func, shape } from 'prop-types';
import { userMark as UserIcon } from '@lalamove/karang/dist/components/Icon/icons';
import {
  SEGMENT_UPDATE_CONTACT_NAME,
  SEGMENT_UPDATE_CONTACT_NUMBER,
} from 'store/modules/checkout/tracking';
import validator from 'utils/validator';
import { noop } from 'utils/helpers';
import Segment from 'utils/segment';
import OrderDatePicker from './OrderDatePicker';
import OrderTimePicker from './OrderTimePicker';
import { Form, FormGroup, FormItem, ScInput, CheckoutHeading } from '../style';

import { ICON_SIZE, ICON_COLOR } from '../config';

const CONTACT_NAME_LIMIT = 128;

class DateContact extends Component {
  static defaultProps = {
    isScheduled: false,
    deliveryDatetime: '',
    defaultDatetime: '',
    name: '',
    phone: '',
    errors: {},
    title: '',
    labelName: '',
    labelPhone: '',
    nowButtonText: '',
    placeholderTime: '',
    onContactChange: noop,
    onDatetimeChange: noop,
    onError: noop,
  };

  static propTypes = {
    isScheduled: bool,
    deliveryDatetime: string,
    defaultDatetime: string,
    name: string,
    phone: string,
    errors: shape({ name: string, phone: string }),
    title: string,
    labelName: string,
    labelPhone: string,
    nowButtonText: string,
    placeholderTime: string,
    onContactChange: func,
    onDatetimeChange: func,
    onError: func,
  };

  handleTextChange = ({ target: { name, value } }) => {
    this.props.onError(name, ''); // TODO: clean up
    this.props.onContactChange({
      [name]: value,
    });
  };

  handleBlur = ({ target: { name, value } }) => {
    this.props.onContactChange({
      [name]: value.trim(),
    });
    this.validateContact(name);
  };

  validateContact = inputName => {
    const { name, phone } = this.props;
    const { message, valid } = validator.phone(phone, true);
    if (!valid) {
      this.props.onError('phone', message);
    }
    if (!name.trim()) {
      this.props.onError('name', 'Checkout.error_msg_name_required');
    }
    // tracking
    if (inputName === 'name') {
      Segment.createTrack(SEGMENT_UPDATE_CONTACT_NAME, {
        is_valid: Boolean(name.trim()),
      });
    } else if (inputName === 'phone') {
      Segment.createTrack(SEGMENT_UPDATE_CONTACT_NUMBER, {
        is_valid: valid,
      });
    }
  };

  render() {
    const {
      title,
      isScheduled,
      deliveryDatetime,
      defaultDatetime,
      name,
      phone,
      errors,
      labelName,
      labelPhone,
      nowButtonText,
      placeholderTime,
      onDatetimeChange,
    } = this.props;
    return (
      <Form>
        <CheckoutHeading text={title}>
          <UserIcon color={ICON_COLOR} size={ICON_SIZE} />
        </CheckoutHeading>
        <FormGroup>
          <FormItem>
            {deliveryDatetime && (
              <OrderDatePicker
                defaultDatetime={defaultDatetime}
                selectedDate={deliveryDatetime}
                isScheduled={isScheduled}
                onChange={onDatetimeChange}
              />
            )}
          </FormItem>
          <FormItem>
            {deliveryDatetime && (
              <OrderTimePicker
                placeholder={placeholderTime}
                nowButtonText={nowButtonText}
                defaultDatetime={defaultDatetime}
                selectedTime={deliveryDatetime}
                isScheduled={isScheduled}
                onChange={onDatetimeChange}
              />
            )}
          </FormItem>
        </FormGroup>
        <FormGroup>
          <FormItem>
            <ScInput
              label={labelName}
              name="name"
              value={name}
              error={errors.name}
              onChange={this.handleTextChange}
              onBlur={this.handleBlur}
              maxLength={CONTACT_NAME_LIMIT}
            />
          </FormItem>
          <FormItem>
            <ScInput
              label={labelPhone}
              name="phone"
              value={phone}
              error={errors.phone}
              onChange={this.handleTextChange}
              onBlur={this.handleBlur}
            />
          </FormItem>
        </FormGroup>
      </Form>
    );
  }
}

export default DateContact;
