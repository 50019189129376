import React from 'react';
import { bool, func, node } from 'prop-types';
import styled from 'styled-components';
import { darkGray, white } from 'styles/colors';
import { HEADER } from 'styles/zIndex';
import { StyledModalAdapter } from 'components/BaseModal';
import { HEADER_HEIGHT } from 'views/Header/style';
import { useResponsiveMedia, BREAKPOINT } from 'components/MediaQuery';
import { noop } from 'utils/helpers';

const TRANSITION_TIME_MS = 300;

const StyledModal = styled(StyledModalAdapter).attrs({
  modalClassName: 'LLM_SliderPanel', // For moving the position of zendesk widget
})`
  & .LLM_SliderPanel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: ${white};
    outline: 0;
    transform: translateX(100%);
    transition: transform ${TRANSITION_TIME_MS}ms;

    @media screen and (min-width: ${BREAKPOINT}px) {
      left: auto;
      width: 40%;
      min-width: 32rem; /* 440px */
      max-width: 100%;
      border-left: 1px solid ${darkGray};
    }

    @media screen and (max-width: ${BREAKPOINT - 1}px) {
      width: 100%;
    }
  }

  & .LLM_SliderPanel--after-open {
    transform: translateX(0);
  }

  & .ReactModal__Content--before-close {
    transform: translateX(100%);
  }
`;

const customStyles = isDesktop => ({
  overlay: {
    top: isDesktop ? `${HEADER_HEIGHT}rem` : 0,
    backgroundColor: 'transparent',
    zIndex: HEADER + 1,
  },
});

const SliderPanel = ({
  isOpen,
  shouldCloseOnClickOutside,
  onClose,
  children,
  onAfterOpen,
  onAfterClose,
}) => {
  const { isDesktop } = useResponsiveMedia();
  return (
    <StyledModal
      closeTimeoutMS={TRANSITION_TIME_MS}
      style={customStyles(isDesktop)}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnClickOutside}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
    >
      {children}
    </StyledModal>
  );
};

SliderPanel.defaultProps = {
  isOpen: false,
  shouldCloseOnClickOutside: false,
  onClose: noop,
  children: null,
  onAfterOpen: noop,
  onAfterClose: noop,
};
SliderPanel.propTypes = {
  isOpen: bool,
  shouldCloseOnClickOutside: bool,
  onClose: func,
  children: node,
  onAfterOpen: func,
  onAfterClose: func,
};

export default SliderPanel;
