import React, { Component } from 'react';
import { bool, number, string, func, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { HeadingGroup, Toggle } from '@lalamove/karang';
import { defaultStatus as OrderIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { defaultRule } from 'utils/validator';
import { orange } from 'styles/colors';
import { compose, noop } from 'utils/helpers';

import { requestLogout } from 'store/modules/auth/actions';
import { getUser } from 'store/modules/auth/selectors';
import { updatePreferences } from 'interfaces/global/store/modules/settings/actions';
// import { getCreditBalance } from 'store/modules/wallet';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';

import {
  Fieldset,
  Table,
  FormItem,
  LabelCol,
  Content,
  // ToggleInfo,
  PaddedHeading,
  SpacedBox,
  StyledEditableInput,
} from '../style';

const TICK_TIMEOUT = 3000;

class Preferences extends Component {
  static propTypes = {
    t: func,
    billingEmail: string,
    eReceipts: bool,
    role: number,
    statement: number,
    canSetPod: number,
    pod: number,
    // walletBalance: number,
    topupReminder: number,
    updatePreferences: func,
    isLoading: bool,
    apiError: shape({ message: string }),
  };

  static defaultProps = {
    t: noop,
    billingEmail: '',
    eReceipts: false,
    role: 0,
    canSetPod: 0,
    pod: 2,
    statement: 1,
    // walletBalance: 0,
    topupReminder: 1,
    updatePreferences: noop,
    isLoading: false,
    apiError: null,
  };

  state = { isShownTick: false, lastTry: Date.now(), billingEmailError: null };

  componentDidUpdate({ isLoading, apiError }, { isShownTick }) {
    if (!isLoading && this.props.isLoading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isShownTick: false });
    }
    if (isLoading && !this.props.isLoading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isShownTick: !this.props.apiError, lastTry: Date.now() });
    }
    if (!isShownTick && this.state.isShownTick) {
      this.timeout = setTimeout(
        () => this.setState({ isShownTick: false }),
        TICK_TIMEOUT
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleBillingEmailSave = billingEmail => {
    this.setState({ billingEmailError: null });
    this.props.updatePreferences({
      email: billingEmail,
      eReceipt: !!billingEmail,
      statement: !!billingEmail,
      topupReminder: !!billingEmail,
    });
  };

  handleBillingEmailError = () => {
    this.setState({ billingEmailError: 'Settings.input_error_invalid_email' });
  };

  handleBillingEmailCancel = () => {
    this.setState({ billingEmailError: null });
  };

  handleBillingEmailChange = () => {
    this.setState({ billingEmailError: null, isShownTick: false });
  };

  handleOnChecked = e => {
    const { name, checked } = e.target;

    switch (name) {
      case 'ereceipts':
        this.props.updatePreferences({ eReceipt: checked });
        break;
      case 'pod':
        this.props.updatePreferences({ pod: checked });
        break;
      case 'statement':
        this.props.updatePreferences({ statement: checked });
        break;
      case 'topupreminder':
        this.props.updatePreferences({ topupReminder: checked });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      t,
      billingEmail,
      eReceipts,
      topupReminder,
      role,
      pod,
      canSetPod,
      statement,
      // walletBalance,
      isLoading,
      apiError,
    } = this.props;
    const { isShownTick, lastTry } = this.state;
    // const isPodAvailable = walletBalance > 0;
    const validateEmail = value => {
      // default email regexp, allow empty:
      const regexp = new RegExp(`^$|${defaultRule.EMAIL.source}`);
      return regexp.test(value);
    };

    return (
      /* eslint-disable jsx-a11y/label-has-for */
      <Fieldset>
        <HeadingGroup>
          <OrderIcon color={orange} />
          <PaddedHeading size="large" htmlTag="h3">
            {t('Settings.form_legend_orders')}
          </PaddedHeading>
        </HeadingGroup>
        <Table>
          <FormItem>
            <LabelCol>
              <label htmlFor="billing_email">
                {t('Settings.form_label_billing_email')}
              </label>
            </LabelCol>
            <Content>
              <SpacedBox>
                <StyledEditableInput
                  key={`${lastTry}-${apiError ? apiError.message : 'none'}`}
                  name="billing_email"
                  error={t(this.state.billingEmailError || apiError || null)}
                  value={billingEmail}
                  placeholder={t('Settings.form_label_email')}
                  saveLabel={t('Settings.button_save')}
                  cancelLabel={t('Settings.button_cancel')}
                  validate={validateEmail}
                  onSave={this.handleBillingEmailSave}
                  onError={this.handleBillingEmailError}
                  onChange={this.handleBillingEmailChange}
                  onCancel={this.handleBillingEmailCancel}
                  isLoading={isLoading}
                  isSuccess={isShownTick}
                />
              </SpacedBox>
            </Content>
          </FormItem>
          <FormItem>
            <LabelCol>
              <label htmlFor="ereceipts">
                {t('Settings.form_label_ereceipts')}
              </label>
            </LabelCol>
            <Content>
              <SpacedBox>
                <label htmlFor="ereceipts">
                  {t('Settings.form_label_ereceipts_description')}
                </label>
                <Toggle
                  style={{ lineHeight: '1.7' }}
                  key={`${lastTry}-${apiError ? apiError.message : 'none'}`}
                  name="ereceipts"
                  disabled={!billingEmail || isLoading}
                  defaultChecked={eReceipts}
                  onChange={this.handleOnChecked}
                />
              </SpacedBox>
            </Content>
          </FormItem>

          {/* 1: Super admin */}
          {role === 1 && (
            <FormItem>
              <LabelCol>
                <label htmlFor="statement">
                  {t('Settings.form_label_statement')}
                </label>
              </LabelCol>
              <Content>
                <SpacedBox>
                  <label htmlFor="statement">
                    {t('Settings.form_label_statement_description')}
                  </label>
                  <Toggle
                    style={{ lineHeight: '1.7' }}
                    key={`${lastTry}-${apiError ? apiError.message : 'none'}`}
                    name="statement"
                    defaultChecked={statement === 1}
                    onChange={this.handleOnChecked}
                  />
                </SpacedBox>
              </Content>
            </FormItem>
          )}
          {role === 1 && (
            <FormItem>
              <LabelCol>
                <label htmlFor="topupreminder">
                  {t('Settings.form_label_topupreminder')}
                </label>
              </LabelCol>
              <Content>
                <SpacedBox>
                  <label htmlFor="topupreminder">
                    {t('Settings.form_label_topupreminder_description')}
                  </label>
                  <Toggle
                    style={{ lineHeight: '1.7' }}
                    key={`${lastTry}-${apiError ? apiError.message : 'none'}`}
                    name="topupreminder"
                    disabled={!billingEmail || isLoading}
                    defaultChecked={topupReminder === 1}
                    onChange={this.handleOnChecked}
                  />
                </SpacedBox>
              </Content>
            </FormItem>
          )}

          {canSetPod === 1 && (
            <FormItem>
              <LabelCol>
                <label htmlFor="pod">{t('Settings.form_label_pod')}</label>
              </LabelCol>
              <Content>
                <SpacedBox>
                  <label htmlFor="pod">
                    {t('Settings.form_label_pod_description')}
                  </label>
                  <Toggle
                    style={{ lineHeight: '1.7' }}
                    key={`${lastTry}-${apiError ? apiError.message : 'none'}`}
                    name="pod"
                    disabled={isLoading}
                    defaultChecked={pod === 1}
                    onChange={this.handleOnChecked}
                  />
                </SpacedBox>
                {/* {!isPodAvailable && pod !== 1 && (
                  <ToggleInfo>{t('Settings.form_label_pod_info')}</ToggleInfo>
                )} */}
              </Content>
            </FormItem>
          )}
        </Table>
      </Fieldset>
    );
  }
}

const mapState = state => {
  const { /* subscriptions, is_pod_enabled: pod, */ profile = {} } = getUser(
    state
  );
  // ep_role： 0: Non-corporate identity、1: admin、2: Staff
  // is_send_statement: 1: send statement、 2: don't send statement
  const {
    ep_role: role,
    is_send_statement: statement,
    is_proof_of_delivery_required: pod,
    is_send_topup_reminder: topupReminder,
  } = profile;

  return {
    billingEmail: profile.contact_email,
    eReceipts: Boolean(Number(profile.is_send_receipt) === 1),
    role,
    statement,
    pod,
    canSetPod: state.services.enablePOD, // config flag to decide whether to show enable pod checkbox or not
    // walletBalance: getCreditBalance(state),
    topupReminder,
    isLoading: createLoadingSelector(['PREFERENCES_UPDATE'])(state),
    apiError: makeMessageSelector(['PREFERENCES_UPDATE'])(state),
  };
};

export default compose(
  withTranslation(),
  connect(mapState, { updatePreferences, requestLogout })
)(Preferences);
