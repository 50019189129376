import React from 'react';
import { string, bool } from 'prop-types';
import styled from 'styled-components';
import OrderIdDisplay from 'components/OrderIdDisplay';
import { white } from 'styles/colors';
import { fontWeight } from 'styles/fonts';

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  border-radius: ${({ isMobileThumbnail }) =>
    isMobileThumbnail ? `4px 4px 0px 0px` : '0px'};
  border: ${({ solid, color }) => (solid ? `1px solid ${color}` : '0px')};
  background-color: ${({ solid, color }) => (solid ? `${white}` : color)};
  color: ${({ solid, color }) => (solid ? color : `${white}`)};
  font-weight: ${fontWeight.bold};
  line-height: 1;

  > span {
    font-size: ${({ isMobileThumbnail }) =>
      isMobileThumbnail ? `12px` : '14px'};
  }
`;

const middot = '\u00b7';

const StatusBar = ({
  status,
  color,
  refId,
  dateTime,
  isOrderIdChunked,
  solid,
  isMobileThumbnail,
}) => (
  <Bar color={color} solid={solid} isMobileThumbnail={isMobileThumbnail}>
    <span>
      {refId ? (
        <>
          <span>{`${status} ${middot} #`}</span>
          <OrderIdDisplay orderId={refId} chunked={isOrderIdChunked} />
        </>
      ) : (
        <span>{status}</span>
      )}
    </span>
    <span>{dateTime}</span>
  </Bar>
);

StatusBar.propTypes = {
  status: string.isRequired,
  color: string.isRequired,
  refId: string,
  dateTime: string.isRequired,
  isOrderIdChunked: bool,
  solid: bool,
  isMobileThumbnail: bool,
};

StatusBar.defaultProps = {
  refId: null,
  isOrderIdChunked: true,
  solid: false,
  isMobileThumbnail: false,
};

export default StatusBar;
