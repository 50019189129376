import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { Button, Input, Form } from '@lalamove/karang';
import { ResponsiveCard } from 'components/Container';

import { noop } from 'utils/helpers';
import { fontSize } from 'styles/fonts';
import { black, silver } from 'styles/colors';

const FormItem = Form.Item;

const StyledInput = styled(Input)`
  flex: 1;
`;

const CardHeader = styled.h1`
  padding-bottom: 2rem;
  margin: 0;
  color: ${black};
  font-size: ${fontSize.large};
  text-align: center;
`;

const CardFooter = styled.div`
  padding-top: 2rem;
  color: ${silver};
  text-align: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

class ResetForm extends Component {
  static defaultProps = {
    onSubmit: noop,
    isSubmitting: false,
    error: '',
    t: noop,
  };

  static propTypes = {
    onSubmit: func,
    isSubmitting: bool,
    error: string,
    t: func,
  };

  state = {
    password: '',
  };

  handlePasswordChange = ({ target: { value } }) => {
    this.setState({ password: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { password } = this.state;
    this.props.onSubmit(password);
  };

  render() {
    const { isSubmitting, error, t } = this.props;
    const { password } = this.state;
    return (
      <ResponsiveCard>
        <CardHeader>{t('ForgotPassword.reset_password')}</CardHeader>
        <form onSubmit={this.handleSubmit}>
          <FormItem>
            <StyledInput
              label={t('ForgotPassword.password_req')}
              type="password"
              name="password"
              value={password}
              error={t(error)}
              onChange={this.handlePasswordChange}
            />
          </FormItem>
          <FormItem>
            <SubmitButton
              type="submit"
              variant="primary"
              size="large"
              block
              solid
              disabled={!password || password.length < 6 || isSubmitting}
              isLoading={isSubmitting}
            >
              {t('ForgotPassword.reset') || ''}
            </SubmitButton>
          </FormItem>
        </form>
        <CardFooter>
          <Trans i18nKey="ForgotPassword.login">
            Already have an account?
            <Link to="/login" replace>
              Log in
            </Link>
            .
          </Trans>
        </CardFooter>
      </ResponsiveCard>
    );
  }
}

export default withTranslation()(ResetForm);
