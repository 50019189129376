import React from 'react';
import styled from 'styled-components';
import { Button } from '@lalamove/karang';
import BaseModal from 'components/BaseModal';
import { mineShaft, primary } from 'styles/colors';
import depreciateSVG from 'assets/svg/sandbox_depreciate.svg';

interface Props {
  isOpen: boolean;
}

const StyledModal = styled(BaseModal)`
  & .ReactModal__Content {
    border-radius: 2px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 508px;
  padding: 17px 112px 32px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 34px;
`;

const Title = styled.h3`
  margin: 0 0 30px;
  color: ${mineShaft['900']};
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
`;

const Content = styled.p`
  margin: 0;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

const OrangeBold = styled.span`
  color: ${primary['main']};
  font-weight: 700;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const OrderList = styled.ol`
  padding-left: 22px;
  margin: 0;
`;
const List = styled.ul`
  list-style-type: disc;
`;

export const CapitalizedButton = styled(Button).attrs({
  size: 'large',
  solid: true,
})`
  text-transform: capitalize;
`;

const DepreciateSandboxModal: React.FC<Props> = ({ isOpen }) => {
  return (
    <StyledModal isOpen={isOpen} name="depreciate-sandbox">
      <Container>
        <ImageContainer>
          <img
            width="150"
            height="150"
            src={depreciateSVG}
            alt="Sandbox Depreciate"
          />
        </ImageContainer>
        <Title>NEW PARTNER PORTAL & SANDBOX ENVIRONMENT</Title>
        <Content>
          Dear Valued Customer,
          <br />
          <br />
          As of <Bold>29th July 2021</Bold>, we will <Bold>deprecate</Bold> this
          particular tool to access our old Sandbox environment. Our API team
          has worked hard to build a brand new and improved tool (
          <OrangeBold>
            <a href="https://partnerportal.sandbox.lalamove.com">
              Partner Portal
            </a>
          </OrangeBold>
          ) to better help our valued clients onboard, test, and enjoy new
          features. Among some of these features are webhooks, proof of delivery
          (POD), and many more will be added in the near future.
          <br />
          <br />
          First of all, don't worry!{' '}
          <Bold>
            Your production credentials will remain unchanged and your
            production integration will be unaffected.
          </Bold>
          <br />
          <br />
          After 29th July 2021, when you want to access our Sandbox environment
          you <Bold>MUST</Bold> use the{' '}
          <OrangeBold>
            <a href="https://partnerportal.sandbox.lalamove.com">
              Partner Portal
            </a>
          </OrangeBold>
          . You will need new credentials if you haven't already received them
          from Lalamove. For those who haven't received new credentials please
          do one of the following:
          <br />
          <br />
          <OrderList>
            <li>Contact your local account manager or</li>
            <li>
              Reach out to our API support team (
              <OrangeBold>
                <a href="mailto:api-support@lalamove.com">
                  api-support@lalamove.com
                </a>
              </OrangeBold>
              ) email specifying the following information:
            </li>
            <List>
              <li>
                <Bold>Company name</Bold>
              </li>
              <li>
                <Bold>
                  Current user (with API key)’s phone and email address
                </Bold>
              </li>
            </List>
          </OrderList>
          <br />
          Upon receiving your new Sandbox credentials, you will be able to use
          <OrangeBold>
            {' '}
            <a href="https://partnerportal.sandbox.lalamove.com">
              Partner Portal
            </a>
          </OrangeBold>{' '}
          to simulate order status and
          <OrangeBold> https://rest.sandbox.lalamove.com</OrangeBold> as the new
          host name.
          <br />
          <br />
          We apologize for any inconvenience this may cause, and we greatly
          appreciate your understanding. As always, please don’t hestitate to
          reach out to us and tell us how we are doing. We will continuously
          commit to provide the best delivery service.
          <br />
          <br />
          Yours Sincerely,
          <br />
          Lalamove API Team
        </Content>
      </Container>
    </StyledModal>
  );
};

export default DepreciateSandboxModal;
