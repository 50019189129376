import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { IconButton } from '@lalamove/karang';

import { bulb as BulbIcon, close as CloseIcon } from 'components/Icons';

import { ALERT } from 'styles/zIndex';

const Container = styled.div`
  position: absolute;
  z-index: ${ALERT};
  top: calc(100% + 15px);
  right: calc(50% - 26px);
  display: flex;
  align-items: center;
  width: 374px;
  padding: 12px 0 12px 12px;
  background: black;
  color: #fff;
  transform: scale(1);
  opacity: 1;
  transform-origin: calc(100% - 26px) -12.1px;
  transition: transform 200ms cubic-bezier(0.34, 1.56, 0.64, 1),
    opacity 200ms cubic-bezier(0.65, 0, 0.35, 1);

  &.enter {
    opacity: 0;
    transform: scale(0.8);
  }

  &.enter-active {
    transform: scale(1);
    opacity: 1;
  }

  &.enter-done {
    transform: scale(1);
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transform: scale(1);
  }

  &.exit-done {
    opacity: 0;
    transform: scale(1);
  }
`;

// if you want to learn some geometry
// rotate: 180 * Math.acos(10 / Math.sqrt(10*10+12*12)) / Math.PI = 50.1944289077348
// angle: 2 * 180 * Math.asin(10 / Math.sqrt(10*10+12*12)) / Math.PI = 79.6111421845304
// skew: 90 - 79.6111421845304 = 10.388857815469606

const Arrow = styled.div`
  transform: rotate(50.1944deg) skewX(10.3889deg);
  position: absolute;
  z-index: ${ALERT - 1};
  top: -7.81px;
  right: 16px;
  width: 16px;
  height: 16px;
  border-top-left-radius: 2.5px;
  background-color: black;
  text-align: left;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 0 8px;
`;

const Content = styled.p`
  margin: 8px 0 3px;
`;

export const CloseButton = styled(IconButton)`
  padding: 12px;
  border: none;
  background: transparent !important;
`;

interface Props {
  visible: boolean;
  onClose: () => void;
}

const ProfileTooltip: React.FC<Props> = ({ visible, onClose }) => {
  const { t } = useTranslation();
  return (
    <CSSTransition unmountOnExit in={visible} timeout={200}>
      <Container>
        <Arrow />
        <BulbIcon />
        <ContentContainer>
          <Title>{t('Profile.tooltip_title')}</Title>
          <Content>{t('Profile.tooltip_text')}</Content>
        </ContentContainer>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </Container>
    </CSSTransition>
  );
};

export default ProfileTooltip;
