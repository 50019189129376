import React from 'react';
import { func, number, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import EmotionIcon from 'components/Emotion';
import { noop, ratingMap } from 'utils/helpers';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Button = styled.label`
  display: block;
  flex: 1;
  cursor: pointer;
  text-align: center;

  input {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
  }

  ${({ active }) =>
    active &&
    css`
      font-weight: bold;
    `};
`;

const ratingList = [
  {
    value: ratingMap.GREAT,
    text: 'RecordPanel.label_great',
    icon: 'love',
  },
  {
    value: ratingMap.GOOD,
    text: 'RecordPanel.label_good',
    icon: 'like',
  },
  {
    value: ratingMap.NORMAL,
    text: 'RecordPanel.label_normal',
    icon: 'neutral',
  },
  {
    value: ratingMap.BAD,
    text: 'RecordPanel.label_bad',
    icon: 'dislike',
  },
  {
    value: ratingMap.TERRIBLE,
    text: 'RecordPanel.label_terrible',
    icon: 'hate',
  },
];

const Emotion = ({ t, name, selected, onChange, ...remain }) => (
  <Container {...remain}>
    {ratingList.map(item => {
      const active = selected === item.value;
      return (
        <Button key={item.value} active={active}>
          <input
            type="radio"
            name={name}
            value={item.value}
            checked={active}
            onChange={onChange}
          />
          <EmotionIcon
            type={item.icon}
            description={t(item.text)}
            active={active}
          />
        </Button>
      );
    })}
  </Container>
);

Emotion.defaultProps = {
  t: noop,
  name: null,
  selected: null,
  onChange: noop,
};

Emotion.propTypes = {
  t: func,
  name: string,
  selected: number,
  onChange: func,
};

export default withTranslation()(Emotion);
