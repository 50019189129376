const { REACT_APP_ZENDESK_CHAT_API_KEY } = process.env;

// interface PropertyPromise<T> {
//   (src: Record<string, any>, property: string): Promise<T>;
// }
const PropertyPromise = (src, property) => {
  let temp;
  return new Promise(resolve => {
    if (src[property]) {
      return resolve(src[property]);
    }
    return Object.defineProperty(src, property, {
      enumerable: true,
      get() {
        return temp;
      },
      set(val) {
        temp = val;
        resolve(temp);
      },
    });
  });
};

// interface zE {
//   (target: string, command: string, args: any): void;
//   show(): void;
//   hide(): void;
//   identify(Record<string, string>): void;
//   setLocale(locale: string): void;
// }
const zEPromise = PropertyPromise(window, 'zE').then(
  instance =>
    new Promise(resolve => {
      // eslint-disable-next-line no-undef
      instance(() => resolve(zE));
    })
);
export const zEInstance = () => zEPromise;

// interface zopim {
//   livechat: {
//     setPhone(phone: string): void,
//     departments: {
//       setVisitorDepartment(dep: string): void,
//     },
//     window: {
//       setTitle(title: string): void,
//     },
//   };
// }
const zopimPromise = PropertyPromise(window, '$zopim').then(
  instance =>
    new Promise(resolve => {
      // eslint-disable-next-line no-undef
      instance(() => resolve($zopim));
    })
);
export const zopimInstance = () => zopimPromise;

export function createZendeskScriptTag() {
  if (document.getElementById('ze-snippet')) return;
  const tag = document.createElement('script');
  tag.src = `https://static.zdassets.com/ekr/snippet.js?key=${REACT_APP_ZENDESK_CHAT_API_KEY}`;
  tag.id = 'ze-snippet';
  tag.async = true;
  // refer https://developer.zendesk.com/embeddables/docs/widget/csp#recommended-setup
  tag.setAttribute('nonce', btoa(new Date().toISOString()));
  document.getElementsByTagName('head')[0].append(tag);
}
