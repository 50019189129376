import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { arrayOf, bool, func, number, string, shape } from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import _isEqual from 'lodash/isEqual';
import {
  downloadPdf as PdfIcon,
  downloadXls as XlsIcon,
} from '@lalamove/karang/dist/components/Icon/icons';

import {
  SEGMENT_DOWNLOAD_STATEMENT_PDF,
  SEGMENT_DOWNLOAD_STATEMENT_XLS,
} from 'store/modules/wallet/tracking';
import {
  getCreditBalance,
  getRewardBalance,
  fetchStatement,
  getStatement,
  addStatementType,
  getStatementTypes,
} from 'store/modules/wallet';
import { createLoadingSelector } from 'store/modules/loading';
import DateRangePicker from 'components/DateRangePicker';
import PageHeader from 'components/PageHeader';
import {
  encodeQueryData,
  noop,
  parseUrlParams,
  triggerDownload,
} from 'utils/helpers';
import Segment from 'utils/segment';

import WalletBanner from './WalletBanner'; // eslint-disable-line import/no-named-as-default
import NumberedGroup from './NumberedGroup';
import DownloadButton from './DownloadButton';
import {
  MAX_RANGE_LIMIT_DAYS,
  PAST_DATE_LIMIT_DAYS,
  DATE_DATA_FORMAT,
} from '../config';
import { validateDates } from '../helpers';
import { Container } from '../style';

// statement specific config:
export const SELECTED_DATE_RANGE = 7;

const now = moment();
// latest end date is yesterday, so subtract `now` by 1:
const DEFAULT_END_DATE = now.subtract(1, 'days').format(DATE_DATA_FORMAT);
// `SELECTED_DATE_RANGE` represents the default selection range:
const DEFAULT_START_DATE = now
  .subtract(SELECTED_DATE_RANGE - 1, 'days')
  .format(DATE_DATA_FORMAT);

const Content = styled.div`
  padding: 0 2em;
`;

const Row = styled.div`
  margin: 0.5em 0;
`;

const Types = {
  PDF: 'pdf',
  XLS: 'xls',
};

class Statement extends Component {
  static defaultProps = {
    t: noop,
    fetchStatement: noop,
    addStatementType: noop,
    loading: false,
    statementTypes: [],
    generatedFile: {
      startDate: '',
      endDate: '',
      xls: '',
      pdf: '',
      expiredAt: '',
    },
    creditBalance: 0,
    rewardBalance: 0,
  };

  static propTypes = {
    t: func,
    fetchStatement: func,
    addStatementType: func,
    loading: bool,
    statementTypes: arrayOf(string),
    history: shape({}).isRequired,
    location: shape({}).isRequired,
    generatedFile: shape({
      startDate: string,
      endDate: string,
      xls: string,
      pdf: string,
      expiredAt: string,
    }),
    creditBalance: number,
    rewardBalance: number,
    Tabs: func.isRequired,
  };

  // driven state by URL params
  params = this.getStateFromUrlParams();

  state = {
    start: this.params.start,
    end: this.params.end,
  };

  componentDidMount() {
    const { history, location } = this.props;

    if (!location.search) {
      history.replace({
        pathname: '/wallet/statement',
        search: encodeQueryData(this.state),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState, this.state)) {
      this.props.history.replace({
        search: encodeQueryData(this.state),
      });
    }
  }

  getStateFromUrlParams() {
    const { location } = this.props;
    const { start, end } = parseUrlParams(location.search);
    const areValidDates = validateDates(start, end, 'statement');
    return {
      start: areValidDates ? start : DEFAULT_START_DATE,
      end: areValidDates ? end : DEFAULT_END_DATE,
    };
  }

  handleDateChange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) return;
    this.setState({
      start: startDate.toISOString(true).split('T')[0],
      end: endDate.toISOString(true).split('T')[0],
    });
  };

  handleDownload = type => {
    const { start, end } = this.state;
    const { generatedFile, loading } = this.props;
    if (loading) {
      this.props.addStatementType(type);
    } else if (
      generatedFile.startDate === start &&
      generatedFile.endDate === end &&
      new Date(generatedFile.expiredAt) > new Date()
    ) {
      triggerDownload(generatedFile[type]);
    } else {
      this.props.fetchStatement(start, end, type);
    }

    // tracking
    const eventsMap = {
      [Types.PDF]: SEGMENT_DOWNLOAD_STATEMENT_PDF,
      [Types.XLS]: SEGMENT_DOWNLOAD_STATEMENT_XLS,
    };
    Segment.createTrack(eventsMap[type]);
  };

  render() {
    const {
      t,
      loading,
      statementTypes,
      rewardBalance,
      creditBalance,
      Tabs,
    } = this.props;
    const { start, end } = this.state;
    return (
      <Container>
        <PageHeader
          topControls={
            <WalletBanner
              rewardBalance={rewardBalance}
              creditBalance={creditBalance}
            />
          }
          title={t('Wallet.title_wallet')}
          tabs={<Tabs />}
        />
        <Content>
          <NumberedGroup
            numbering={1}
            title={t('Wallet.heading_select_date_range')}
            subtitle={t('Wallet.label_select_date_range', {
              days: MAX_RANGE_LIMIT_DAYS,
            })}
          >
            <Row>
              <DateRangePicker
                startDate={moment(start)}
                endDate={moment(end)}
                onDatesChange={this.handleDateChange}
                minDate={moment().subtract(PAST_DATE_LIMIT_DAYS, 'days')}
                maxDate={moment().subtract(1, 'days')}
                maxDays={MAX_RANGE_LIMIT_DAYS}
              />
            </Row>
          </NumberedGroup>
          <NumberedGroup
            numbering={2}
            title={t('Wallet.heading_select_report_type')}
          >
            <Row>
              <DownloadButton
                title={t('Wallet.button_title_statement')}
                description={t('Wallet.button_description_statement')}
                icon={<PdfIcon size={40} />}
                onClick={() => this.handleDownload(Types.PDF)}
                loading={loading && statementTypes.includes(Types.PDF)}
              />
            </Row>
            <Row>
              <DownloadButton
                title={t('Wallet.button_title_transaction_details')}
                description={t('Wallet.button_description_transaction_details')}
                icon={<XlsIcon size={40} />}
                onClick={() => this.handleDownload(Types.XLS)}
                loading={loading && statementTypes.includes(Types.XLS)}
              />
            </Row>
          </NumberedGroup>
        </Content>
      </Container>
    );
  }
}

const mapState = state => ({
  generatedFile: getStatement(state),
  statementTypes: getStatementTypes(state),
  loading: createLoadingSelector(['FETCH_STATEMENT'])(state),
  creditBalance: getCreditBalance(state),
  rewardBalance: getRewardBalance(state),
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapState, { fetchStatement, addStatementType })
)(Statement);
