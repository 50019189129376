import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bool, string, func, oneOf, shape } from 'prop-types';
import styled, { css } from 'styled-components';
import { Alert as LLMAlert } from '@lalamove/karang';
import _some from 'lodash/some';

import {
  MessageShape,
  makeMessageSelector,
  dismissMessage,
} from 'store/modules/message';
import { HEADER_HEIGHT } from 'views/Header/style';
import { ALERT, HEADER } from 'styles/zIndex';
import { noop } from 'utils/helpers';
import { withTranslation } from 'react-i18next';
import { mountainMeadow, treePoppy, valencia, pictonBlue } from 'styles/colors';
import { whenMobile } from 'components/MediaQuery';

export const DISMISS_TIMEOUT_MS = 6000;
export const TRANSITION_TIME_MS = 300;

export const StyledAlert = styled(LLMAlert)`
  position: absolute;
  z-index: ${ALERT};
  box-sizing: border-box;
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  visibility: ${({ isShown }) => (isShown ? `visible` : `hidden`)};
  transition-duration: ${TRANSITION_TIME_MS}ms;
  transition-property: opacity, visibility;
  transition-delay: 0, ${({ isShown }) => (isShown ? 0 : TRANSITION_TIME_MS)}ms;

  ${({ variant }) => {
    switch (variant) {
      case 'toast':
        return css`
          top: ${HEADER_HEIGHT + 1}rem;
          right: 1rem;
          width: 320px;
        `;
      default:
        return css`
          top: 0;
          opacity: 1;
          width: 100%;
          z-index: ${HEADER - 1};

          transform: ${({ isShown }) =>
            isShown ? 'none' : 'translateY(-100%)'};
          transition-property: transform, visibility;
          transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);

          ${whenMobile} {
            position: fixed;
            z-index: ${ALERT};
          }
        `;
    }
  }};

  background-color: ${({ type }) => {
    switch (type) {
      case 'success':
        return mountainMeadow.main;
      case 'warning':
        return treePoppy.main;
      case 'error':
        return valencia.main;
      default:
        return pictonBlue.main;
    }
  }};

  > div {
    display: inline-flex;
    flex-flow: column;
    line-height: 20px;
    /* There are 2 span elements inside this div
    If title (named message here) is missing, the span is still there an takes up space
    Which messes up the layout */
    ${({ message }) =>
      !message &&
      `
        span {
          margin-top: 0;
          margin-bottom: 0;
        }
      `}
  }
`;

export class Alert extends Component {
  static propTypes = {
    t: func,
    id: string,
    type: MessageShape.type,
    title: string,
    message: string,
    variant: oneOf(['toast', 'default']),
    persist: bool,
    isShown: bool,
    params: shape({}),
    dismissMessage: func,
  };

  static defaultProps = {
    t: noop,
    id: null,
    type: 'error',
    title: null,
    message: '',
    variant: 'default',
    persist: false,
    isShown: false,
    params: {},
    dismissMessage: noop,
  };

  static dismissTimeout = null;

  state = {
    isTransitioningOut: false,
  };

  componentDidMount() {
    const { persist, isShown } = this.props;
    if (isShown && !persist) {
      // dismiss alert after 3 secs
      this.dismissTimeout = setTimeout(() => {
        this.onDismiss();
      }, DISMISS_TIMEOUT_MS);
    }
  }

  componentDidUpdate(prevProps) {
    const { persist, isShown } = this.props;
    if (!prevProps.isShown && isShown && !persist) {
      // dismiss alert after 3 secs
      this.dismissTimeout = setTimeout(() => {
        this.onDismiss();
      }, DISMISS_TIMEOUT_MS);
    }
  }

  componentWillUnmount() {
    if (this.dismissTimeout) clearTimeout(this.dismissTimeout);
  }

  onDismiss = () => {
    const { id, dismissMessage } = this.props; // eslint-disable-line no-shadow
    this.setState({ isTransitioningOut: true });

    setTimeout(() => {
      this.setState({ isTransitioningOut: false });
      dismissMessage(id);
    }, TRANSITION_TIME_MS);
  };

  render() {
    const { t, type, title, message, variant, isShown, params } = this.props;
    const { isTransitioningOut } = this.state;

    return (
      <StyledAlert
        type={type}
        isShown={isTransitioningOut ? false : isShown}
        variant={variant}
        message={title ? t(title, params) : ''}
        description={t(message, params)}
        onDismiss={this.onDismiss}
      />
    );
  }
}

const mapState = (state, { messageSelector, params }) => {
  const { id, type, title, message } =
    makeMessageSelector(messageSelector)(state) || {};
  return {
    id,
    type,
    title,
    message,
    params,
    isShown: _some([title, message]),
  };
};

export default compose(
  withTranslation(),
  connect(mapState, { dismissMessage })
)(Alert);
