import services from './reducer';
import saga from './sagas';
import * as actions from './actions';
import * as selectors from './selectors';

const servicesModule = {
  id: 'services',
  reducerMap: { services },
  sagas: [saga],
};

export { servicesModule as default, saga, actions, selectors };
