import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, func, number, bool } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Badge, Button, Flag, HeadingGroup } from '@lalamove/karang';
import {
  onOff as OnOffIcon,
  userMark as UserIcon,
} from '@lalamove/karang/dist/components/Icon/icons';

import { orange } from 'styles/colors';
import { compose, noop } from 'utils/helpers';
import Segment from 'utils/segment';
import { requestLogout } from 'store/modules/auth/actions';
import { actions } from 'store/modules/genesys';
import { getUser } from 'store/modules/auth/selectors';
import { getCountries } from 'store/modules/region/selectors';
import { SEGMENT_CLICK_SETTING } from 'store/modules/settings/tracking';

import {
  Wrapper,
  Fieldset,
  Table,
  FormItem,
  LabelCol,
  Content,
  PaddedHeading,
  SpacedContainer,
} from './style';
import Preferences from './containers/Preferences';
import LocLang from './containers/LocLang';
import PasswordForm from './containers/Password';

// const Legend = HeadingGroup.withComponent('legend');

const StyledFlag = styled(Flag)`
  ${({ image }) =>
    !image &&
    css`
      & figure {
        margin: 0;
      }
    `}
`;

/* eslint-disable react/prefer-stateless-function, jsx-a11y/label-has-for */
class Settings extends Component {
  static propTypes = {
    t: func,
    name: string,
    lastName: string,
    phone: string,
    phoneVerified: bool,
    email: string,
    emailVerified: bool,
    passwordExist: bool,
    countryCode: number,
    countryFlag: string,
    requestLogout: func,
    logoutGenesys: func.isRequired,
  };

  static defaultProps = {
    t: noop,
    name: '',
    lastName: '',
    phone: '',
    phoneVerified: false,
    email: '',
    emailVerified: false,
    passwordExist: true,
    countryCode: '',
    countryFlag: '',
    requestLogout: noop,
  };

  componentDidMount() {
    // tracking
    Segment.createTrack(SEGMENT_CLICK_SETTING);
  }

  handleLogout = () => {
    const { logoutGenesys, requestLogout: logout } = this.props;
    logoutGenesys();
    logout();
  };

  render() {
    const {
      t,
      name,
      lastName,
      email,
      phone,
      countryCode,
      countryFlag,
      phoneVerified,
      emailVerified,
      passwordExist,
    } = this.props;

    return (
      /* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
      <Wrapper>
        <Fieldset>
          <HeadingGroup>
            <UserIcon size={24} color={orange} />
            <PaddedHeading size="large" htmlTag="h3">
              {t('Settings.form_legend_profile')}
            </PaddedHeading>
            <Button
              onClick={this.handleLogout}
              icon={<OnOffIcon />}
              variant="danger"
            >
              {t('Settings.logout')}
            </Button>
          </HeadingGroup>
          <Table>
            <FormItem>
              <LabelCol>
                <label>{t('Settings.form_label_name')}</label>
              </LabelCol>
              <Content>
                {name} {lastName}
              </Content>
            </FormItem>
            <FormItem>
              <LabelCol>
                <label>{t('Settings.form_label_mobile')}</label>
              </LabelCol>
              <Content>
                <SpacedContainer>
                  <StyledFlag
                    image={
                      countryFlag && (
                        <img
                          src={countryFlag}
                          alt={`+${countryCode}`}
                          width="24"
                          height="24"
                          style={{ display: 'block' }}
                        />
                      )
                    }
                  >
                    {phone}
                  </StyledFlag>
                  {phone && phoneVerified && (
                    <Badge solid variant="mountainMeadow">
                      {t('Settings.tag_status_verified')}
                    </Badge>
                  )}
                </SpacedContainer>
              </Content>
            </FormItem>
            {email && (
              <FormItem>
                <LabelCol>
                  <label>{t('Settings.form_label_email')}</label>
                </LabelCol>
                <Content>
                  <SpacedContainer>
                    {email}
                    {email && emailVerified && (
                      <Badge solid variant="mountainMeadow">
                        {t('Settings.tag_status_verified')}
                      </Badge>
                    )}
                  </SpacedContainer>
                </Content>
              </FormItem>
            )}
            <FormItem>
              <LabelCol>
                <label>{t('Settings.form_label_password')}</label>
              </LabelCol>
              <Content>
                <PasswordForm passwordExist={passwordExist} />
              </Content>
            </FormItem>
          </Table>
        </Fieldset>
        <Preferences />
        <LocLang />
      </Wrapper>
    );
  }
}

const mapState = state => {
  let phone;
  let countryFlag = '';

  const {
    email,
    name,
    last_name: lastName,
    // TODO: FOX-1958 - Different data returned from /login and /getuserprofile in auth-service
    phone_number: phoneNumber, // eslint-disable-line camelcase
    country_code: countryCode,
    phone_verified_status: phoneVerified,
    email_verified_status: emailVerified,
    contact_info, // eslint-disable-line camelcase
    password_set: passwordExist,
  } = getUser(state);
  phone = phoneNumber || (contact_info[0] ? contact_info[0].contact_phone : '');
  phone = phone ? `+${countryCode} ${phone.replace(/^0/, '')}` : '\u2014'; // mdash

  const country = getCountries(state).find(
    c => parseInt(c.areaCode, 10) === countryCode
  );
  if (country) countryFlag = country.flag;

  return {
    email,
    emailVerified,
    name,
    lastName,
    phone,
    phoneVerified,
    countryCode,
    countryFlag,
    passwordExist,
  };
};

export default compose(
  withTranslation(),
  connect(mapState, { requestLogout, logoutGenesys: actions.logoutGenesys })
)(Settings);
