import React from 'react';
import { batch } from 'react-redux';
import moment from 'moment';
import { Rating } from '@lalamove/karang';
import _curry from 'lodash/curry';
import { arrayOf, func, bool, number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import EmptyState from 'components/EmptyState';
import Tag from 'components/Tag';

import noRecordSVG from 'assets/svg/table_no_record.svg';
import noResultSVG from 'assets/svg/table_no_result.svg';

import formatter from 'utils/formatter';
import {
  globalCompletedOrderStatusIDs,
  globalCancelledOrderStatusIDs,
  trackingStatusMap,
  globalOrderStatusMap as statusMap,
  globalOrderStatusLabels as statusLabels,
  daylightSavingsTimeMomentMap,
  daylightSavingsStatus,
} from 'utils/helpers';

import RouteList from '../RouteList';

import {
  StyledDropdown,
  RecordsTable as StyledRecordsTable,
  SupportText,
  Truncate,
} from '../../style';

const RecordsTable = ({
  max,
  current,
  orders,
  openedOrderId,
  isLoading,
  isCustomFilters,
  serviceNames,
  openPanel,
  track,
}) => {
  const { t } = useTranslation();
  const handleRowClick = ({ status, id, refId }) =>
    batch(() => {
      openPanel('ORDER', { orderId: id });
      track('orders_detail_tapped', {
        order_id: refId,
        order_status: trackingStatusMap[status.parentID],
      });
    });

  const paginate = (ordersList, pageSize, pageNumber) =>
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    ordersList.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

  const columns = [
    {
      key: 'status',
      label: t('Records.table_column_title_status'),
      render: (data, { refId }) => {
        // Let's group together completed and cancelled orders and use parentID and parent name of current order if its status is in this array
        const id = [
          ...globalCompletedOrderStatusIDs,
          ...globalCancelledOrderStatusIDs,
        ].includes(data.id)
          ? data.parentID
          : data.id;
        const orderStatus = data.id;

        const { DRIVER_COMPLETED, SEND_BILL, COMPLAIN_BILL } = statusMap;

        return (
          <>
            <Tag
              type={tagTypes[id]}
              solid={
                orderStatus === DRIVER_COMPLETED.id ||
                orderStatus === SEND_BILL.id ||
                orderStatus === COMPLAIN_BILL.id
              }
            >
              {t(statusLabels[id])}
            </Tag>
            <SupportText>
              <span>{refId}</span>
            </SupportText>
          </>
        );
      },
    },
    {
      key: 'deliveryDatetime',
      label: t('Records.table_column_title_date'),
      render: data => (
        <>
          <div>{renderTime(data, t('DateTime.time_24'))}</div>
          <SupportText>{renderDate(data, t('DateTime.date_full'))}</SupportText>
        </>
      ),
    },
    {
      key: 'waypoints',
      label: t('Records.table_column_title_route'),
      render: waypoints => (
        <RouteList
          t={_curry(t, 2)('Records.number_of_stops')}
          list={waypoints.map(stop => stop.name)}
        />
      ),
    },
    {
      key: 'driver',
      label: t('Records.table_column_title_driver'),
      render: (driver, { status, userRating }) => (
        <>
          <Truncate>
            {status.id === statusMap.MATCHING.id
              ? t('Records.label_driver_matching')
              : driver?.name || t('Records.label_driver_not_matched')}
          </Truncate>
          <SupportText>
            {status.parentID === statusMap.COMPLETED.parentID ? (
              <Rating value={userRating} size={7} />
            ) : (
              status.parentID !== statusMap.CANCELLED.parentID && driver?.phone
            )}
          </SupportText>
        </>
      ),
    },
    {
      key: 'serviceTypeId',
      label: t('Records.table_column_title_type'),
      render: (data, { driver, canShowDriverInfo }) => (
        <>
          {serviceNames[data] || data}
          <SupportText>
            {/* Show driver's license plate number when order is matched (next phase): */}
            {false && canShowDriverInfo && driver && driver.licensePlate}
          </SupportText>
        </>
      ),
    },
    {
      key: 'price',
      label: t('Records.table_column_title_price'),
      render: (data, { paymentMethod }) => (
        <>
          <div>{formatter.currency(data.total)}</div>
          <SupportText>{t(paymentMap[paymentMethod])}</SupportText>
        </>
      ),
    },
    {
      key: 'others',
      label: '',
      render: (_, { id, status }) => {
        const cancelOption = {
          value: 'cancel',
          label: t('Records.option_cancel_order'),
          onSelect: () =>
            openPanel('ORDER', {
              orderId: id,
              currentType: 'cancel',
            }),
        };
        const options = [
          ...([statusMap.MATCHING.id, statusMap.ON_GOING.id].includes(status.id)
            ? [cancelOption]
            : []),
        ];

        if (!options.length) {
          return null;
        }

        return (
          <StyledDropdown
            onClick={e => e.stopPropagation()}
            items={options}
            variant="compact"
            direction="left"
          />
        );
      },
    },
  ];

  const emptyState = (
    <EmptyState
      image={
        <img
          src={isCustomFilters ? noResultSVG : noRecordSVG}
          alt={t(
            isCustomFilters ? 'Records.msg_no_result' : 'Records.msg_no_record'
          )}
        />
      }
      text={t(
        isCustomFilters ? 'Records.msg_no_result' : 'Records.msg_no_record'
      )}
    />
  );

  const paginatedOrders = paginate(orders, max, current);
  const activeRowIndex = paginatedOrders.findIndex(
    ({ id }) => id === openedOrderId
  );

  return (
    <StyledRecordsTable
      hoverable
      data={paginatedOrders}
      columns={columns}
      activeRow={activeRowIndex}
      onRowClick={handleRowClick}
      loading={isLoading}
      emptyState={emptyState}
      data-cy="orders-table"
    />
  );
};

export default RecordsTable;

RecordsTable.propTypes = {
  max: number.isRequired,
  current: number.isRequired,
  orders: arrayOf(shape({ refId: string })).isRequired,
  openedOrderId: string.isRequired,
  isCustomFilters: bool.isRequired,
  isLoading: bool.isRequired,
  serviceNames: shape({}).isRequired,
  openPanel: func.isRequired,
  track: func.isRequired,
};

const renderTime = (datetime, format = 'H:mm') => {
  const daylightSavingsTimeType = Object.values(daylightSavingsStatus).includes(
    datetime.daylightSavingsTimeType
  )
    ? datetime.daylightSavingsTimeType
    : daylightSavingsStatus['DEFAULT'];
  const currentFormat =
    format + daylightSavingsTimeMomentMap[daylightSavingsTimeType];
  return moment(datetime.time).format(currentFormat);
};

const renderDate = (datetime, format = 'DD MMM YYYY') =>
  moment(datetime.time).format(format);

const tagTypes = {
  [statusMap.MATCHING.id]: 'yellow',
  [statusMap.ON_GOING.id]: 'blue',
  [statusMap.COMPLETED.id]: 'green',
  [statusMap.CANCELLED.id]: 'red',
  [statusMap.DRIVER_REJECTED.id]: 'red',
  [statusMap.ORDER_TIMEOUT.id]: 'red',
  [statusMap.ORDER_NOPAY.id]: 'red',
  [statusMap.ORDER_LOADED.id]: 'darkBlue',
  [statusMap.ORDER_CANCELLED_FIVE_DAYS.id]: 'red',
  [statusMap.ORDER_CANCELLED_TEN_DAYS.id]: 'red',
  [statusMap.DRIVER_COMPLETED.id]: 'darkRed',
  [statusMap.ABNORMAL_COMPLETED.id]: 'green',
  [statusMap.SETTLEMENT_CASH.id]: 'green',
  [statusMap.SEND_BILL.id]: 'darkRed',
  [statusMap.COMPLAIN_BILL.id]: 'darkRed',
  [statusMap.LOADING.id]: 'blue',
  [statusMap.UNLOADING.id]: 'blue',
};

const paymentMap = {
  CASH: 'Records.label_payment_cash',
  CREDITS: 'Records.label_payment_wallet',
};
