/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { bool, instanceOf, func, number, shape } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DROPDOWN } from 'styles/zIndex';
import { message as MessageIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { orange, white } from 'styles/colors';
import { darken } from 'polished';
import { actions } from 'interfaces/global/store/modules/genesys/actions';
import openGenesysWebChat from 'interfaces/global/store/modules/genesys/helpers';
import {
  genesysConfig,
  formField,
  WEB_CHAT_AVAILABLE_LANGUAGES,
} from 'utils/genesys.config';
import { track } from 'interfaces/global/store/modules/tracking/actions';

const ChatCanvas = styled.div`
  position: fixed;
  z-index: ${DROPDOWN};
  overflow: hidden;
  width: 52px;
  height: 52px;
  transition: right 0.3s;
`;

export const ChatButton = styled.button`
  position: absolute;
  bottom: ${props => (props.hide ? '-100%' : '0')};
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${orange};
  color: ${white};
  outline: none;
  transition: bottom 0.1s ease-in-out;

  &:hover {
    background-color: ${darken(0.1, orange)};
  }
`;

export class ChatWidget extends Component {
  static propTypes = {
    openWidget: func.isRequired,
    closeWidget: func.isRequired,
    isScriptLoaded: bool.isRequired,
    isOpen: bool.isRequired,
    user: instanceOf(Object).isRequired,
    region: instanceOf(Object).isRequired,
    advanceConfig: instanceOf(Object).isRequired,
    offset: shape({
      x: number.isRequired,
      y: number.isRequired,
    }).isRequired,
    track: func.isRequired,
  };

  componentDidMount() {
    this.regionUpdate();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.region.currentLocation !== this.props.region.currentLocation ||
      prevProps.region.currentLocale !== this.props.region.currentLocale
    ) {
      this.regionUpdate();
    }
  }

  regionUpdate = () => {
    const { advanceConfig } = this.props;
    const paramsData = this.getParams();
    const {
      widgets: { main, webchat },
    } = genesysConfig;

    // local file JSON
    const langFile = `../chatTranslations/${paramsData.language}.json`;

    // eslint-disable-next-line no-underscore-dangle
    window._genesys = {
      widgets: {
        main: { ...main, lang: paramsData.language, i18n: langFile },
        webchat: { ...webchat, userData: paramsData },
      },
    };
    // eslint-disable-line no-underscore-dangle
    window._genesys.widgets.webchat.transport.interactionData.routing.targetAddress =
      paramsData.queueName || 'WebChat';
    this.loadCxbus();
    advanceConfig(this.getAdvancedConfig());
  };

  loadCxbus = () => {
    const existingScript = document.getElementById('cxbus');

    if (!existingScript) {
      const { REACT_APP_WEB_CHAT_DATA_APP_URL } = process.env;
      const widgetBaseUrl = `${REACT_APP_WEB_CHAT_DATA_APP_URL}/9.0/`;
      const script = document.createElement('script');
      script.src = `${widgetBaseUrl}cxbus.min.js`;
      script.id = 'cxbus';
      document.head.appendChild(script);

      script.onload = () => {
        window.CXBus.configure({
          debug: false,
          pluginsPath: `${widgetBaseUrl}plugins/`,
        });
      };
    }
  };

  openWebChat = () => {
    // eslint-disable-next-line no-shadow
    const { openWidget, closeWidget, isScriptLoaded, track } = this.props;
    openGenesysWebChat(
      isScriptLoaded,
      this.getAdvancedConfig(),
      openWidget,
      closeWidget,
      track
    );
    track('cs_chat_tapped');
  };

  getParams = () => {
    const { user, region } = this.props;
    const { profile: userProfile } = user;
    const { currentLocation, currentLocale } = region;

    // eslint-disable-next-line react/prop-types
    const language = this.languageResolver(
      currentLocale.split('_')[0].toUpperCase()
    );

    const queueName = this.queueNameResolver(
      `${currentLocation}_${language}_USER_CHAT`
    );

    // eslint-disable-next-line camelcase
    const {
      phone_no: phoneNumber,
      email,
      first_name: firstName,
      last_name: lastName,
    } = userProfile;

    return {
      firstName,
      lastName,
      email,
      queueName,
      phoneNumber,
      language,
    };
  };

  // Fallback language depending on the project environment
  languageResolver = language => {
    let resolvedLanguage = language;

    if (!WEB_CHAT_AVAILABLE_LANGUAGES.includes(language)) {
      resolvedLanguage = 'EN';
    }

    return resolvedLanguage;
  };

  queueNameResolver = queueName => {
    const { region } = this.props;
    const { currentLocation } = region;

    if (queueName) {
      // Fallback system to IN_LEH one if not in production
      if (process.env.REACT_APP_WEB_CHAT_MODE !== 'production') {
        return queueName.replace(`${currentLocation}`, 'IN_LEH');
      }
      // TW shares the Taipei queue across other cities
      if (queueName.startsWith('TW_')) {
        return queueName.replace(`${currentLocation}`, 'TW_TPE');
      }
      // MY shares the Kuala Lumpur queue across other cities
      if (queueName.startsWith('MY_')) {
        return queueName.replace(`${currentLocation}`, 'MY_KUL');
      }
    }

    return queueName;
  };

  getAdvancedConfig = () => {
    const paramsData = this.getParams();

    const { firstName, lastName, email, phoneNumber, orderLink } = paramsData;

    const { form } = formField;
    const formInputField = [
      {
        id: 'cx_webchat_form_firstname',
        name: 'firstname',
        maxlength: '100',
        placeholder: 'First Name',
        label: 'First Name',
        value: firstName,
        readonly: !!firstName,
      },
      {
        id: 'cx_webchat_form_lastname',
        name: 'lastname',
        maxlength: '100',
        placeholder: 'Last Name',
        label: 'Last Name',
        value: lastName,
        readonly: !!lastName,
      },
      {
        id: 'cx_webchat_form_email',
        name: 'email',
        maxlength: '100',
        placeholder: 'Optional',
        label: 'Email',
        value: email,
        readonly: !!email,
      },
      {
        id: 'cx_webchat_form_subject',
        name: 'subject',
        maxlength: '100',
        placeholder: 'Optional',
        label: 'Subject',
        value: 'Web Chat',
      },
      {
        id: 'custom_field_1',
        name: 'customField1',
        maxlength: '100',
        placeholder: 'Custom Data',
        label: 'custom_field_1',
        value: `Order ID: ${orderLink}`,
        type: 'hidden',
      },
    ];
    const formData = {
      ...form,
      firstname: firstName,
      lastname: lastName,
      email,
      customField1: `Order ID: ${orderLink}`,
      phoneNumber,
    };
    return {
      form: formData,
      formJSON: {
        wrapper: '<table></table>',
        inputs: formInputField,
      },
    };
  };

  render() {
    const { isOpen, offset } = this.props;
    const { x: right, y: bottom } = offset;
    return (
      <ChatCanvas style={{ right, bottom }}>
        <ChatButton onClick={this.openWebChat} hide={isOpen}>
          <MessageIcon size={24} color={white} />
        </ChatButton>
      </ChatCanvas>
    );
  }
}
const mapStateToProps = ({ region, session, genesys }) => ({
  region: region || {},
  user: session.user,
  isOpen: genesys.isOpen,
  isScriptLoaded: genesys.isScriptLoaded,
  offset: genesys.offset,
});

const mapDispatchToProps = {
  openWidget: actions.openWidget,
  closeWidget: actions.closeWidget,
  advanceConfig: actions.genesysAdvanceConfig,
  track,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatWidget);
