/* eslint-disable lines-between-class-members */
// export interface MonetaryAmountStruct {
//   language: string;
//   amount: number; // int
//   amountDisplay: string;
//   amountCurrency: string;
//   amountExponent: string;
// }

export default class MonetaryAmount {
  language = '';
  amount = 0; // int
  currencySymbol = '';
  amountCurrency = '';
  amountExponent = 0;

  constructor(m = {}) {
    Object.assign(this, m);
  }

  get exactAmount() {
    return this.amount / Math.pow(10, this.amountExponent);
  }

  get amountDisplay() {
    return `${this.currencySymbol}${this.exactAmount.toFixed(
      this.amountExponent
    )}`;
  }

  set amountDisplay(ad) {
    [, this.currencySymbol] = ad.match(/^([^\d]*)\d/);
  }

  toPrecision(num) {
    if (num < this.amountExponent) {
      throw new Error('Precision downgrade is not allowed');
    }
    this.amount *= Math.pow(10, num - this.amountExponent);
    this.amountExponent = num;
    return this;
  }

  add(m) {
    const wantedPrecision = Math.max(this.amountExponent, m.amountExponent);
    const a = this.toPrecision(wantedPrecision);
    const b = m.toPrecision(wantedPrecision);
    // assume all money added has same lang, currency
    return new MonetaryAmount({
      language: a.language,
      amount: a.amount + b.amount,
      currencySymbol: a.currencySymbol,
      amountCurrency: a.amountCurrency,
      amountExponent: wantedPrecision,
    });
  }
}
