// Action types
export const FETCH_BALANCE_REQUEST = 'FETCH_BALANCE_REQUEST';
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS';
export const FETCH_BALANCE_FAILURE = 'FETCH_BALANCE_FAILURE';

export const FETCH_WALLET_HISTORY_REQUEST = 'FETCH_WALLET_HISTORY_REQUEST';
export const FETCH_WALLET_HISTORY_SUCCESS = 'FETCH_WALLET_HISTORY_SUCCESS';
export const FETCH_WALLET_HISTORY_FAILURE = 'FETCH_WALLET_HISTORY_FAILURE';

export const FETCH_STATEMENT_REQUEST = 'FETCH_STATEMENT_REQUEST';
export const FETCH_STATEMENT_SUCCESS = 'FETCH_STATEMENT_SUCCESS';
export const FETCH_STATEMENT_FAILURE = 'FETCH_STATEMENT_FAILURE';

export const TOPUP_INIT = 'TOPUP_INIT';
export const TOPUP_SUCCESS = 'TOPUP_SUCCESS';
export const SET_TOPUP_AMOUNT = 'SET_TOPUP_AMOUNT';

export const FETCH_CHARGELIST_REQUEST = 'FETCH_CHARGELIST_REQUEST';
export const FETCH_CHARGELIST_SUCCESS = 'FETCH_CHARGELIST_SUCCESS';
export const FETCH_CHARGELIST_FAILURE = 'FETCH_CHARGELIST_FAILURE';
export const RECHARGE_REQUEST = 'RECHARGE_REQUEST';
export const RECHARGE_SUCCESS = 'RECHARGE_SUCCESS';
export const RECHARGE_FAILURE = 'RECHARGE_FAILURE';

// coupon actions:
export const FETCH_COUPONLIST_REQUEST = 'FETCH_COUPONLIST_REQUEST';
export const FETCH_COUPONLIST_SUCCESS = 'FETCH_COUPONLIST_SUCCESS';
export const FETCH_COUPONEXCHANGE_REQUEST = 'FETCH_COUPONEXCHANGE_REQUEST';
export const FETCH_COUPONEXCHANGE_FAILURE = 'FETCH_COUPONEXCHANGE_FAILURE';
export const FETCH_COUPONEXCHANGE_SUCCESS = 'FETCH_COUPONEXCHANGE_SUCCESS';

export const REDEEM_WALLET_COUPON_SUCCESS = 'REDEEM_WALLET_COUPON_SUCCESS'; // for display Alert(Banner) only

// Action creators
export const fetchBalance = () => ({
  type: FETCH_BALANCE_REQUEST,
});

export const fetchStatement = (startDate, endDate, fileType) => ({
  type: FETCH_STATEMENT_REQUEST,
  startDate,
  endDate,
  fileType,
});
export const fetchCouponList = query => ({
  type: FETCH_COUPONLIST_REQUEST,
  query,
});
export const handleCouponExchange = ({ code }) => ({
  type: FETCH_COUPONEXCHANGE_REQUEST,
  code,
});
export const getChargeList = () => ({
  type: FETCH_CHARGELIST_REQUEST,
});
export const initTopup = source => ({
  type: TOPUP_INIT,
  source,
});
export const topUpSuccess = amount => ({
  type: TOPUP_SUCCESS,
  topupAmount: amount,
  meta: {
    type: 'success',
    title: 'Wallet.top_up_success_title',
    message: 'Wallet.top_up_success_message',
  },
});
export const recharge = ({ chargeId, discount, total }) => ({
  type: RECHARGE_REQUEST,
  chargeId,
  discount,
  total,
});

export const fetchWalletHistory = query => ({
  type: FETCH_WALLET_HISTORY_REQUEST,
  query,
});

export function getCreditBalance({ wallet }) {
  return wallet.creditBalance;
}

export function getRewardBalance({ wallet }) {
  return wallet.rewardBalance;
}

export function getStatement(state) {
  return state.wallet.statement;
}

export function getStatementTypes(state) {
  return state.wallet.statementTypes;
}
