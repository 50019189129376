import { changeEmailStep } from 'utils/helpers';
import {
  SET_CHANGE_EMAIL_STEP,
  CHANGE_EMAIL_SMS_SUCCESS,
  CHANGE_EMAIL_VERIFY_PIN_SUCCESS,
  RESET_CHANGE_EMAIL_STEPS,
} from './actions';

export const emailInitState = {
  smsCode: '',
  changeEmailStep: changeEmailStep['DEFAULT_STEP'],
  newEmail: '',
};

export function emailReducer(state = emailInitState, action) {
  switch (action.type) {
    case SET_CHANGE_EMAIL_STEP: {
      return {
        ...state,
        changeEmailStep: action.step,
      };
    }
    case CHANGE_EMAIL_SMS_SUCCESS: {
      return {
        ...state,
        newEmail: action.email,
      };
    }
    case RESET_CHANGE_EMAIL_STEPS: {
      return {
        ...state,
        newEmail: '',
      };
    }
    default:
      return state;
  }
}
export const initState = {
  emailSettings: emailInitState,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SET_CHANGE_EMAIL_STEP:
    case CHANGE_EMAIL_SMS_SUCCESS:
    case CHANGE_EMAIL_VERIFY_PIN_SUCCESS:
    case RESET_CHANGE_EMAIL_STEPS:
      return {
        ...state,
        emailSettings: emailReducer(state.emailSettings, action),
      };
    default:
      return state;
  }
}
