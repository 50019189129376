import saga from './saga';
import wallet from './reducer';
import * as actions from './actions';
import * as selectors from './selectors';

const walletModule = {
  id: 'wallet',
  reducerMap: { wallet },
  sagas: [saga],
};
export { walletModule as default, saga, actions, selectors };
