import { LalamoveApiError } from 'utils/helpers';

import RestAPIFetcher from 'api/RestAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

export default async function fetchStatement(id) {
  const fetcher = FetcherFactory.make(RestAPIFetcher);
  const { data, error } = await fetcher.get(`statements/${id}`);
  if (error) throw new LalamoveApiError(error.code, error.message);
  return { id: data.id, attributes: data.attributes };
}
