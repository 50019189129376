import React, { Component } from 'react';
import { bool, number, string, func, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { HeadingGroup, Toggle, EditableInput } from '@lalamove/karang';
import { defaultStatus as OrderIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { defaultRule } from 'utils/validator';
import { orange } from 'styles/colors';
import { compose, noop } from 'utils/helpers';

import { requestLogout } from 'store/modules/auth/actions';
import { getUser } from 'store/modules/auth/selectors';
import { updatePreferences } from 'store/modules/settings';
import { getCreditBalance } from 'store/modules/wallet';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';

import {
  Fieldset,
  Table,
  FormItem,
  LabelCol,
  Content,
  ToggleInfo,
  PaddedHeading,
  SpacedContainer,
} from '../style';

const TICK_TIMEOUT = 3000;

class Preferences extends Component {
  static propTypes = {
    t: func,
    billingEmail: string,
    eReceipts: bool,
    pod: bool,
    walletBalance: number,
    updatePreferences: func,
    isLoading: bool,
    apiError: shape({ message: string }),
  };

  static defaultProps = {
    t: noop,
    billingEmail: '',
    eReceipts: false,
    pod: false,
    walletBalance: 0,
    updatePreferences: noop,
    isLoading: false,
    apiError: null,
  };

  state = { isShownTick: false, lastTry: Date.now(), billingEmailError: null };

  componentDidUpdate({ isLoading, apiError }, { isShownTick }) {
    if (!isLoading && this.props.isLoading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isShownTick: false });
    }
    if (isLoading && !this.props.isLoading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isShownTick: !this.props.apiError, lastTry: Date.now() });
    }
    if (!isShownTick && this.state.isShownTick) {
      this.timeout = setTimeout(
        () => this.setState({ isShownTick: false }),
        TICK_TIMEOUT
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleBillingEmailSave = billingEmail => {
    this.setState({ billingEmailError: null });
    this.props.updatePreferences({ email: billingEmail, eReceipt: true });
  };

  handleBillingEmailError = () => {
    this.setState({ billingEmailError: 'ERROR_INVALID_EMAIL' });
  };

  handleBillingEmailCancel = () => {
    this.setState({ billingEmailError: null });
  };

  handleBillingEmailChange = () => {
    this.setState({ billingEmailError: null, isShownTick: false });
  };

  handleOnChecked = e => {
    if (e.target.name === 'ereceipts')
      this.props.updatePreferences({ eReceipt: e.target.checked });
    else if (e.target.name === 'pod')
      this.props.updatePreferences({ pod: e.target.checked });
  };

  render() {
    const {
      t,
      billingEmail,
      eReceipts,
      pod,
      walletBalance,
      isLoading,
      apiError,
    } = this.props;
    const { isShownTick, lastTry } = this.state;
    const isPodAvailable = walletBalance > 0;
    const validateEmail = value => {
      // default email regexp, allow empty:
      const regexp = new RegExp(`^$|${defaultRule.EMAIL.source}`);
      return regexp.test(value);
    };

    return (
      /* eslint-disable jsx-a11y/label-has-for */
      <Fieldset>
        <HeadingGroup>
          <OrderIcon color={orange} />
          <PaddedHeading size="large" htmlTag="h3">
            {t('Settings.form_legend_orders')}
          </PaddedHeading>
        </HeadingGroup>
        <Table>
          <FormItem>
            <LabelCol>
              <label htmlFor="billing_email">
                {t('Settings.form_label_billing_email')}
              </label>
            </LabelCol>
            <Content>
              <SpacedContainer>
                <EditableInput
                  key={`${lastTry}-${apiError ? apiError.message : 'none'}`}
                  name="billing_email"
                  error={t(this.state.billingEmailError || apiError || null)}
                  value={billingEmail}
                  placeholder={t('Settings.form_label_email')}
                  saveLabel={t('Settings.button_save')}
                  cancelLabel={t('Settings.button_cancel')}
                  validate={validateEmail}
                  onSave={this.handleBillingEmailSave}
                  onError={this.handleBillingEmailError}
                  onChange={this.handleBillingEmailChange}
                  onCancel={this.handleBillingEmailCancel}
                  isLoading={isLoading}
                  isSuccess={isShownTick}
                />
              </SpacedContainer>
            </Content>
          </FormItem>
          <FormItem>
            <LabelCol>
              <label htmlFor="ereceipts">
                {t('Settings.form_label_ereceipts')}
              </label>
            </LabelCol>
            <Content>
              <SpacedContainer>
                <label htmlFor="ereceipts">
                  {t('Settings.form_label_ereceipts_description')}
                </label>
                <Toggle
                  style={{ lineHeight: '1.7' }}
                  key={`${lastTry}-${apiError ? apiError.message : 'none'}`}
                  name="ereceipts"
                  disabled={!billingEmail || isLoading}
                  defaultChecked={eReceipts}
                  onChange={this.handleOnChecked}
                />
              </SpacedContainer>
            </Content>
          </FormItem>
          <FormItem>
            <LabelCol>
              <label htmlFor="pod">{t('Settings.form_label_pod')}</label>
            </LabelCol>
            <Content>
              <SpacedContainer>
                <label htmlFor="pod">
                  {t('Settings.form_label_pod_description')}
                </label>
                <Toggle
                  style={{ lineHeight: '1.7' }}
                  key={`${lastTry}-${apiError ? apiError.message : 'none'}`}
                  name="pod"
                  disabled={(!isPodAvailable && !pod) || isLoading}
                  defaultChecked={pod}
                  onChange={this.handleOnChecked}
                />
              </SpacedContainer>
              {!isPodAvailable && !pod && (
                <ToggleInfo>{t('Settings.form_label_pod_info')}</ToggleInfo>
              )}
            </Content>
          </FormItem>
        </Table>
      </Fieldset>
    );
  }
}

const mapState = state => {
  const { subscriptions, is_pod_enabled: pod } = getUser(state);

  const billingInfo = subscriptions[0] || {
    contact_email: '',
    type: '',
  };

  return {
    billingEmail: billingInfo.contact_email,
    eReceipts: Boolean(billingInfo.type),
    pod,
    walletBalance: getCreditBalance(state),
    isLoading: createLoadingSelector(['PREFERENCES_UPDATE'])(state),
    apiError: makeMessageSelector(['PREFERENCES_UPDATE'])(state),
  };
};

export default compose(
  withTranslation(),
  connect(mapState, { updatePreferences, requestLogout })
)(Preferences);
