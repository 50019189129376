export function getFavoriteDrivers(state) {
  return state.drivers.favoriteIds.map(id => state.drivers.drivers[id]);
}

export function getBannedDrivers(state) {
  return state.drivers.bannedIds.map(id => state.drivers.drivers[id]);
}

export function getFavoriteDriverCount(state) {
  return state.drivers.favoriteIds.length;
}

export function getBannedDriverCount(state) {
  return state.drivers.bannedCount;
}
