import { FACEBOOK_LOGIN_SOURCE } from 'api/uAPI/socialLogin';
import {
  takeLatest,
  put,
  call,
  take,
  delay,
  takeEvery,
  fork,
  select,
} from 'redux-saga/effects';
import {
  REGISTER_REQUEST,
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  VERIFY_REQUEST,
  RESEND_REQUEST,
  VERIFY_FAILURE,
  VERIFY_SUCCESS,
  RESEND_SUCCESS,
  RESEND_RELEASE,
  RESEND_FAILURE,
  RESEND_LOCKING,
  FB_LOGIN_SUCCESS,
  enableActiveFetchProfile,
} from 'interfaces/global/store/modules/auth/actions';
import { SET_MARKETING_OPT_IN_SUCCESS } from 'interfaces/global/store/modules/settings/actions';
import { getUser } from 'interfaces/global/store/modules/auth/selectors';

import { trackingSegmentRegistration } from 'interfaces/global/store/modules/tracking/segment';
import {
  track,
  login,
  setProfile,
} from 'interfaces/global/store/modules/tracking/actions';

import validator from 'utils/validator';
import {
  getCurrentCity,
  getCurrentCountryCode,
} from 'store/modules/region/selectors';
import { replace } from 'connected-react-router';
import {
  register,
  getUserInfo,
  getSocialAccountRegisterSMS,
  registerSocialAccount,
} from 'api/uAPI';
import { sessionService } from 'redux-react-session';
import storage from 'utils/storage';
import { LOGIN_SUCCESS } from 'store/modules/auth/actions';
import { branchLogEvent, UApiError } from 'utils/helpers';

const REQUEST_VERIFICATION_CODE_INDICATOR = '999999';
export const NEW_REGISTER_IS_EP = 2;

export function* handleSubmitPin({
  firstName,
  lastName,
  email,
  phoneNumber,
  password,
  smsCode,
  countryCode,
  socialSource,
  signedProfile,
}) {
  try {
    let registrationResponse;
    let createAccountType = 'normal';

    if (socialSource) {
      createAccountType =
        socialSource === FACEBOOK_LOGIN_SOURCE ? 'facebook' : 'google';
      registrationResponse = yield call(registerSocialAccount, {
        phoneNumber,
        smsCode,
        email,
        socialSource,
        signedProfile,
      });
    } else {
      registrationResponse = yield call(register, {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        smsCode,
      });
    }

    const { token } = registrationResponse;

    const profile = yield call(getUserInfo, token, NEW_REGISTER_IS_EP);

    const marketingOptin = storage.getItem('marketing_optin') === 'true';

    yield call([sessionService, 'saveSession'], token);
    yield call([sessionService, 'saveUser'], {
      ...registrationResponse,
      is_ep: NEW_REGISTER_IS_EP,
      profile_type: NEW_REGISTER_IS_EP,
      access_token: token,
      profile: {
        ...profile,
        country_code: countryCode,
        is_marketing_opt_in: +marketingOptin,
      },
    });

    // sensors tracking
    yield put(track('create_account_clicked', { type: createAccountType }));
    branchLogEvent('COMPLETE_REGISTRATION');
    const currentCountryCode = yield select(getCurrentCountryCode);
    yield put(login());
    yield put(
      setProfile({
        marketing_opt_in: +marketingOptin,
        source_registered: 'webapp',
        country_registered: currentCountryCode,
      })
    );

    // segment tracking
    const { user_fid: userFid } = yield select(getUser);
    const city = yield select(getCurrentCity);
    trackingSegmentRegistration(userFid, {
      city: city.id,
      country_code: countryCode,
      is_marketing_opt_in: +marketingOptin,
      client_id: userFid,
    });

    yield put({ type: SET_MARKETING_OPT_IN_SUCCESS, marketingOptin });

    yield put(replace('/'));
    yield put({ type: VERIFY_SUCCESS });
    yield put({ type: LOGIN_SUCCESS });

    yield put(enableActiveFetchProfile());

    return true;
  } catch ({ message, errorCode }) {
    yield put({
      type: VERIFY_FAILURE,
      meta: {
        type: 'error',
        message,
        errorCode,
      },
    });
    return false;
  }
}

export function* handleResendPin({
  firstName,
  lastName,
  email,
  phoneNumber,
  password,
  blockDuration,
  socialSource,
}) {
  try {
    if (socialSource)
      yield call(getSocialAccountRegisterSMS, {
        phoneNumber,
        email,
        socialSource,
      });
    else
      yield call(register, {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        smsCode: REQUEST_VERIFICATION_CODE_INDICATOR,
      });

    yield put({ type: RESEND_SUCCESS });
    yield delay(blockDuration);
    yield put({ type: RESEND_RELEASE });
  } catch ({ message }) {
    yield put({
      type: RESEND_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* handleSubmitRegister({
  firstName,
  lastName,
  email,
  countryCode,
  mobile,
  password,
  socialLoginId,
  socialSource,
  signedProfile,
  marketingOptIn,
}) {
  try {
    if (email) {
      const { valid, message } = validator.email(email);
      if (!valid) throw new UApiError(message, 30003);
    }

    if (mobile) {
      const valid = /^\d+$/.test(mobile);
      if (!valid) throw new UApiError('Register.invalid_phone', 30001);
    }

    const phone = `+${countryCode}${mobile}`;

    if (socialLoginId)
      yield call(getSocialAccountRegisterSMS, {
        phoneNumber: phone,
        email,
        socialSource,
      });
    else
      yield call(register, {
        firstName,
        lastName,
        email,
        phoneNumber: phone,
        password,
        smsCode: REQUEST_VERIFICATION_CODE_INDICATOR,
      });

    yield put({ type: REGISTER_SUCCESS });
    yield put(replace('/verification'));

    let success = false;
    storage.setItem('marketing_optin', marketingOptIn);

    while (!success) {
      const action = yield take([VERIFY_REQUEST, RESEND_REQUEST]);
      if (action.type === VERIFY_REQUEST) {
        const { pin } = action;

        const res = yield call(handleSubmitPin, {
          firstName,
          lastName,
          email,
          phoneNumber: phone,
          password,
          smsCode: pin,
          countryCode,
          socialSource,
          signedProfile,
        });

        if (socialLoginId && res) {
          yield put({ type: FB_LOGIN_SUCCESS });
        }

        success = res;
      } else if (action.type === RESEND_REQUEST) {
        const { blockDuration } = action;
        yield fork(handleResendPin, {
          firstName,
          lastName,
          email,
          phoneNumber: phone,
          password,
          blockDuration,
          socialSource,
        });
      }
    }
  } catch ({ message, errorCode }) {
    yield put({
      type: REGISTER_FAILURE,
      meta: {
        type: 'error',
        message,
        data: 'llm.register',
        errorCode,
      },
    });
  }
}

export function* registerSaga() {
  yield takeLatest(REGISTER_REQUEST, handleSubmitRegister);
}

export function* handleResendLock({ blockDuration }) {
  yield delay(blockDuration);
  yield put({ type: RESEND_RELEASE });
}

export function* verificationSaga() {
  yield takeEvery(RESEND_LOCKING, handleResendLock);
}
