import FetcherFactory from 'api/FetcherFactory';
import RestAPIFetcher from 'api/RestAPIFetcher';
import log from 'api/log';
import { getCurrentLocale } from 'store/modules/region/selectors';
import { getSessionToken } from 'store/modules/routing';
import { LalamoveApiError } from 'utils/helpers';
import { toIETF } from 'utils/locale';

// interface Request {
//   placeid: string;
//   language?: string;
//   sessiontoken?: string;
// }

// interface LatLng {
//   lat: number;
//   lng: number;
// }
// interface Response {
//   result: {
//     address_components: {
//       long_name: string;
//       short_name: string;
//       types: string[];
//     }[];
//     adr_address: string;
//     formatted_address: string;
//     geometry: {
//       bounds: {
//         northeast: LatLng;
//         southwest: LatLng;
//       };
//       location: LatLng;
//       location_type: string;
//       types: null | string[];
//       viewport: {
//         northeast: LatLng;
//         southwest: LatLng;
//       };
//       icon: string;
//       name: string;
//       place_id: string;
//       types: string[];
//       url: string;
//       utc_offset: number;
//     }
//   };
//   status: string;
// }

export default async function getPlaceDetails(placeId) {
  const fetcher = FetcherFactory.make(RestAPIFetcher);
  const state = await fetcher.getRootState();
  const locale = getCurrentLocale(state);
  const sessiontoken = getSessionToken(state);

  const { result, status } = await fetcher.get('maps/place/details', {
    placeid: placeId,
    language: toIETF(locale),
    ...(sessiontoken && { sessiontoken }),
  });

  if (status !== 'OK') {
    log.info('PlacesService.getDetails', {
      category: 'google-maps',
      status,
      response: `JSON: ${JSON.stringify(result)}`, // prevent log service parsing JSON string as object
    });
    throw new LalamoveApiError(status);
  }

  return result;
}
