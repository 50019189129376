export const ERROR_INVALID_ADDR = 'PlaceOrder.error_import_failed_no_place';
export const ERROR_INVALID_PHONE_NUMBER_WITH_PREFIX =
  'ERROR_INVALID_PHONE_NUMBER_WITH_PREFIX';
export const ERROR_IMPORT_INVALID_PHONE_NUMBER =
  'PlaceOrder.msg_import_failed_invalid_phone_number';
export const ERROR_IMPORT_FAILED = 'PlaceOrder.error_import_failed';
export const ERROR_IMPORT_MIN = 'PlaceOrder.error_import_failed_less_than_2';
export const ERROR_IMPORT_MAX = 'PlaceOrder.error_import_failed_more_than_20';
export const ERROR_IMPORT_NO_ROUTE = 'PlaceOrder.error_import_failed_no_route';

export function ImportError(message, data) {
  Error.call(this, message);
  this.message = message;
  this.data = data;
  // Error.captureStackTrace(this, ImportError);
}
