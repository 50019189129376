import _merge from 'lodash/merge';

// Steps for action SET_CHANGE_PW_STEP for passing to setChangePwStep()
export const BEGINNING_STEP = 0;
export const SEND_SMS_STEP = 1;
export const VERIFY_SMS_CODE_STEP = 2;
export const CHANGE_PW_STEP = 3;

export const CHANGE_PW_SUCCESS_MESSAGE_DURATION = 5000;

export const WEB_BUSINESS_TOOLTIP = 'WEB_BUSINESS_TOOLTIP';

// preserve keys that were added during login/register, e.g. country_code
// more merging strategy can be made by declaring key list for special handling
// like deepMergeKeys to deep merge certain keys but not the others
export function mergeProfile(originProfile, newProfile) {
  const directOverrideKeys = ['flags']; // directly override the old one with new one without merging
  const profile = _merge(originProfile, newProfile);
  directOverrideKeys.forEach(key => {
    // use the new one unless it doesn't exist
    profile[key] = newProfile[key] || profile[key];
  });
  return profile;
}
