import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { bool, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { Button, CountdownBar, PinInput } from '@lalamove/karang';
import { withResponsiveMedia } from 'components/MediaQuery';
import { ResponsiveCard } from 'components/Container';

import HtmlTitle from 'components/HtmlTitle';

import { createLoadingSelector } from 'store/modules/loading';
import {
  dismissAllMessages,
  makeMessageSelector,
  MessageShape,
} from 'store/modules/message';
import { getCurrentCity } from 'store/modules/region/selectors';
import {
  verifyRequest,
  resendRequest,
  resendLocking,
} from 'interfaces/global/store/modules/auth/actions';

import { track } from 'interfaces/global/store/modules/tracking/actions';

import { fontSize, fontWeight } from 'styles/fonts';
import { black, silver, orange, lightSilver } from 'styles/colors';

const CardHeader = styled.h1`
  padding-bottom: 2rem;
  margin: 0;
  color: ${black};
  font-size: ${fontSize.large};
  text-align: center;
`;

const PinRemark = styled.div`
  margin: 1rem auto 2rem auto;
  color: ${silver};
  font-size: ${fontSize.regular};
  text-align: center;
`;

const CardFooter = styled.div`
  padding-top: 2rem;
  color: ${silver};
  text-align: center;
`;

const PinContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FromActionSeparator = styled.div`
  padding: 1rem 0;
  color: ${lightSilver};
  font-size: ${fontSize.small};
  text-align: center;
`;

const CSLink = styled.div`
  color: ${orange};
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.bold};
  text-align: center;
`;

CSLink.displayName = 'CSLink';

const blockDuration = 60000;

export class Verification extends Component {
  static defaultProps = {
    verificationError: null,
  };

  static propTypes = {
    verifyRequest: func.isRequired,
    resendRequest: func.isRequired,
    resendLocking: func.isRequired,
    dismissAllMessages: func.isRequired,
    verificationError: shape(MessageShape),
    isSubmitting: bool.isRequired,
    isWaiting: bool.isRequired,
    t: func.isRequired,
    city: shape({ csPhone: string }).isRequired,
    track: func.isRequired,
    isMobile: bool.isRequired,
  };

  state = {
    pin: '',
    smsLeft: 3,
  };

  componentDidMount = () => {
    this.props.dismissAllMessages();
    this.props.resendLocking({ blockDuration });
  };

  handleChange = pin => {
    this.props.dismissAllMessages();

    if (pin.length === 4) {
      setTimeout(() => this.handleVerify(), 1);
    }
    this.setState({ pin });
  };

  handleResend = method => {
    // eslint-disable-next-line no-shadow
    const { resendRequest, track } = this.props;
    this.setState(prevState => ({ smsLeft: prevState.smsLeft - 1 }));

    resendRequest({ blockDuration });
    track('request_sms_verification_clicked', { source: 'create_account' });
  };

  handleVerify = () => {
    this.props.verifyRequest({ pin: this.state.pin });
  };

  getErrorMessage = verificationError => {
    const { t } = this.props;

    if (verificationError) {
      const { errorCode, message } = verificationError;

      if (errorCode === 30008) return t('Verification.incorrect_sms_code');
      return message;
    }

    return '';
  };

  render() {
    const {
      verificationError,
      isSubmitting,
      isWaiting,
      t,
      city,
      isMobile,
    } = this.props;
    const { pin, smsLeft } = this.state;

    const errorMsg = this.getErrorMessage(verificationError);

    const retryButton = label => {
      if (isWaiting) {
        return <CountdownBar label={label} duration={`${blockDuration}ms`} />;
      }
      return (
        <Button
          type="button"
          variant="primary"
          size="large"
          block
          solid
          onClick={() => this.handleResend()}
        >
          {label}
        </Button>
      );
    };

    return (
      <Fragment>
        <HtmlTitle>{t('Title.pin_verification')}</HtmlTitle>
        <ResponsiveCard>
          <CardHeader>{t('Verification.pin_verification')}</CardHeader>
          <form>
            <PinContainer>
              <PinInput
                type="tel"
                pins={pin.split('')}
                disabled={isSubmitting}
                error={errorMsg}
                onChange={this.handleChange}
                size={isMobile ? 'small' : 'large'}
              />
            </PinContainer>
            <PinRemark>{t('Verification.enter_pin')}</PinRemark>
          </form>
          {smsLeft > 0 && retryButton(t('Verification.resend_sms'))}
          {smsLeft === 0 && (
            <>
              <FromActionSeparator>{t('Verification.or')}</FromActionSeparator>
              <CSLink>
                <a href={`tel:${city.csPhone}`}>
                  {t('Verification.contact_cs', { hotline: city.csPhone })}
                </a>
              </CSLink>
            </>
          )}
          <CardFooter>
            <Trans i18nKey="Verification.login">
              Already have an account?
              <Link to="/login" replace>
                Log in
              </Link>
              .
            </Trans>
          </CardFooter>
        </ResponsiveCard>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  verificationError: makeMessageSelector(['VERIFY', 'RESEND'])(state),
  isSubmitting: createLoadingSelector(['VERIFY', 'RESEND'])(state),
  isWaiting: state.auth.verification.isWaiting,
  city: getCurrentCity(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    verifyRequest,
    resendRequest,
    resendLocking,
    dismissAllMessages,
    track,
  }),
  withResponsiveMedia
)(Verification);
