import saga from './saga';
import settings from './reducer';
import * as actions from './actions';
import * as selectors from './selectors';

const SettingsModule = {
  id: 'settings',
  reducerMap: { settings },
  sagas: [saga],
};

export { SettingsModule as default, saga, actions, selectors };
