import React, { Component } from 'react';
import { render } from 'react-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bool, shape, number, arrayOf, string, func } from 'prop-types';

import Maps from 'interfaces/global/views/Maps';
import { WaypointCreationMethods } from 'interfaces/global/store/modules/routing/saga';
// import { getFilledWaypts, getGoogleDirections } from 'store/modules/routing';
import { getFilledWaypts } from 'store/modules/routing';
import { updateWaypt } from 'interfaces/global/store/modules/routing/actions';
import { DRAFT_ORDER_ID } from 'store/modules/routing/config';
import {
  selectors as regionSelector,
  actions as regionAction,
} from 'store/modules/region';
import CountryPicker from 'views/Home/components/CountryPicker';
import { matchLLMLocation } from 'utils/location';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const $picker = document.createElement('div');
$picker.style.cssText = 'margin: 16px 16px 0 0;';

class RoutePreview extends Component {
  static defaultProps = {
    // directions: null,
    orderId: DRAFT_ORDER_ID,
    showCountryPicker: false,
    locale: 'en_hk',
  };

  static propTypes = {
    currentCity: shape({}).isRequired,
    // directions: shape({
    //   routes: arrayOf(
    //     shape({
    //       waypoint_order: arrayOf(number),
    //     })
    //   ),
    // }),
    waypoints: arrayOf(
      shape({
        id: string,
        name: string,
        lat: number,
        lng: number,
        placeId: string,
      })
    ).isRequired,
    // locale: string.isRequired,
    updateWaypt: func.isRequired,
    locale: string,
    countries: arrayOf(shape({})).isRequired,
    cityDict: shape({}).isRequired,
    changeLocation: func.isRequired,
    orderId: string, // eslint-disable-line react/no-unused-prop-types
    showCountryPicker: bool,
  };

  componentDidUpdate() {
    if (this.props.showCountryPicker) this.renderCountryPicker($picker);
    // let mapLang = mapConfig.language;
    // if (mapLang.includes('_')) {
    //   mapLang = convertToStandardLangCode(mapConfig.language); // legacy lang code using in webapp
    // }
    // if (mapLang !== this.props.locale) {
    //   // reload Google Maps JS API with locale switched
    //   window.location = window.location.pathname;
    // }
  }

  onDragEnd = ({ id, lat, lng, placeId, name, address }) => {
    this.props.updateWaypt({
      id,
      name,
      address,
      placeId,
      lat,
      lng,
      method: WaypointCreationMethods.map,
    });
  };

  getWaypoints() {
    const { waypoints, orderId } = this.props;
    return waypoints.map((waypoint, index) => ({
      ...waypoint,
      draggable: !(orderId !== DRAFT_ORDER_ID && index === 0),
    }));
  }

  injectControls = ({ map, maps }) => {
    if (this.props.showCountryPicker) {
      map.controls[maps.ControlPosition.TOP_RIGHT].push($picker);
      this.renderCountryPicker($picker);
    }
  };

  changeLocation = location => {
    const { changeLocation, locale } = this.props;
    const [country, code] = matchLLMLocation(location);
    if (code) {
      changeLocation({ location, locale });
    } else {
      changeLocation({ country, locale });
    }
  };

  renderCountryPicker(dom) {
    const { currentCity, countries, cityDict, locale } = this.props;
    render(
      <CountryPicker
        locale={locale}
        currentCity={currentCity}
        countries={countries}
        cityDict={cityDict}
        onChange={this.changeLocation}
      />,
      dom
    );
  }

  render() {
    // const { currentCity, directions } = this.props;
    const { currentCity } = this.props;
    return (
      <Container>
        <Maps
          // directions={directions}
          center={{ lat: currentCity.lat, lng: currentCity.lng }}
          zoomLevel={11}
          waypoints={this.getWaypoints()}
          onDragEnd={this.onDragEnd}
          onGoogleApiLoaded={this.injectControls}
          draggableMarkers
        />
      </Container>
    );
  }
}

const mapState = (state, { orderId }) => ({
  waypoints: getFilledWaypts(state, orderId),
  // directions: getGoogleDirections(state, orderId),
  locale: regionSelector.getCurrentLocale(state),
  countries: regionSelector.getCountries(state),
  cityDict: regionSelector.getCityDict(state),
});

export default connect(mapState, {
  updateWaypt,
  changeLocation: regionAction.changeLocation,
})(RoutePreview);
