import React from 'react';
import { Spinner } from '@lalamove/karang';
import styled from 'styled-components';
import { orange } from 'styles/colors';
import { ResponsiveCard } from 'components/Container';
import { Logo } from './LoginBox';

const CenterSpinnerBox = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 57px;
  padding-bottom: 112px;
`;

const LoadingBox: React.FC = () => (
  <ResponsiveCard>
    <Logo />
    <CenterSpinnerBox>
      <Spinner color={orange} size={80} />
    </CenterSpinnerBox>
  </ResponsiveCard>
);
export default LoadingBox;
