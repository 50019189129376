import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface OptionTranslation {
//   id: String; // e.g. en_HK
//   name: String;
//   description: String;
//   dimensions: String;
// }
// interface Option {
//   category: 'SERVICE' | 'SUB_SERVICE' | 'SPECIAL_REQUEST';
//   children?: {
//     [category_key: String]: Option, // SUB_SERVICE_DOCUMENT: Option
//   };
//   iconAerialUri: String;
//   iconSideGreyscaleUri: String;
//   iconSideUri: String;
//   image3dUri: String;
//   imageOffUri: String;
//   imageSlideUri: String;
//   imageUri: String;
//   price?: Number;
//   sortingPriority: Number;
//   translations: OptionTranslation[];
// }
// interface Response {
//   [SERVICE_key: String]: String;
//   footprint: String;
// }
// fetch all service options (service types & additional services)
export default async function fetchServiceOptions(city) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  const res = await fetcher.get('serviceoptions');
  return res;
}
