import styled from 'styled-components';
import { isSafari } from 'utils/userAgentHelper';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled.div`
  flex-grow: 1;
  height: auto;
  padding: ${isSafari(navigator.userAgent) ? `0 1.2em 100px` : `0 1.2em`};
`;

export const Wrapper = styled.div`
  margin: 0 0 0 2em;
`;
