import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Prompt, withRouter } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { Checkbox, Heading, HeadingGroup } from '@lalamove/karang';
import { info as InfoIcon } from '@lalamove/karang/dist/esm/components/Icon/icons/alert';
import { bool, func, number, shape, string } from 'prop-types';
import { push } from 'connected-react-router';

import { getCityDict, getCurrentCity } from 'store/modules/region/selectors';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector, MessageShape } from 'store/modules/message';
import { getLatestQuotation, resetQuotation } from 'store/modules/quotation';
import {
  initEdit,
  getOrder,
  editOrder,
  getPriceByOrderId,
} from 'store/modules/records';
import {
  getFilledWaypts,
  getFilledWayptsIds,
  getShouldOptimize,
  toggleOptimize,
} from 'store/modules/routing';
import { getDeliveryInfoByWayptIds } from 'store/modules/routing/deliveryInfo';
import { openDialog } from 'store/modules/ui';
import { isFormDirty } from 'store/modules/form';
import { darkGray } from 'styles/colors';

import Alert from 'containers/Alert'; // eslint-disable-line import/no-named-as-default
import Editor from 'containers/Editor';
import { Box } from 'containers/PlaceOrder/style';
import ClickArea from 'components/ClickArea';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import StatusBar from 'containers/Panel/Order/components/StatusBar';
import { OrderShape, PriceShape } from 'views/Records/propTypes';
import { noop } from 'utils/helpers';
import { HEADER_HEIGHT } from 'views/Header/style';
// TODO: move it to /containers/ (kept here to track changes)
import RoutePreview from 'views/Home/components/RoutePreview';
import optRouteJPG from 'assets/route.opt-icon.jpg';
import Footer from './components/Footer';

export const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: calc(100vh - ${HEADER_HEIGHT}rem);
  max-height: calc(100vh - ${HEADER_HEIGHT}rem);
`;

export const Left = styled.main`
  position: relative;
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  min-width: 36rem;
  max-width: 48rem;
  border-right: 1px solid ${darkGray};
`;

export const LeftContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`;

const ModalImage = styled.div`
  text-align: center;
`;

const ModalContent = styled.div`
  margin: auto 2rem;
`;

export const Right = styled.aside`
  flex: 1 1 auto;
`;

class EditOrder extends Component {
  static defaultProps = {
    t: noop,
    isFetching: false,
    isFetchingQuotation: false,
    isSubmitting: false,
    allowOptimize: false,
    shouldOptimize: false,
    order: {},
    orderPrice: {},
    quote: {},
    errorMsg: {},
    openDialog: noop,
  };

  static propTypes = {
    match: shape({
      params: shape({
        orderId: string.isRequired,
      }).isRequired,
    }).isRequired,
    t: func,
    isFetching: bool,
    isFetchingQuotation: bool,
    isSubmitting: bool,
    allowOptimize: bool,
    shouldOptimize: bool,
    order: OrderShape,
    orderPrice: PriceShape,
    quote: shape({
      id: string,
      price: PriceShape,
    }),
    errorMsg: shape(MessageShape),
    openDialog: func,
    initEdit: func.isRequired,
    editOrder: func.isRequired,
    cityDict: shape({}).isRequired,
    currentCity: shape({
      lat: number,
      lng: number,
    }).isRequired,
    toggleOptimize: func.isRequired,
    isDirty: bool.isRequired,
    historyPush: func.isRequired,
    resetQuotation: func.isRequired,
  };

  state = {
    confirmedNavigation: false,
    optimizeRouteModal: false,
  };

  componentDidMount() {
    const { orderId } = this.props.match.params;
    this.props.initEdit(orderId);
  }

  componentDidUpdate(prevProps) {
    const { isFetching, order, match, historyPush } = this.props;
    const { orderId } = match.params;
    if (prevProps.isFetching && !isFetching && !Object.keys(order).length) {
      historyPush(`/orders/${orderId}`);
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-shadow
    const { resetQuotation } = this.props;
    resetQuotation();
  }

  handleOptimizeRouteToggle = () => {
    const { shouldOptimize } = this.props;
    const { orderId } = this.props.match.params;
    this.props.toggleOptimize(!shouldOptimize, orderId);
  };

  handleModalClose = () => {
    this.setState({
      optimizeRouteModal: false,
    });
  };

  handleEnquireOptimizeRoute = () => {
    this.setState({
      optimizeRouteModal: true,
    });
  };

  handleRouteChanged = nextLocation => {
    // show confirmation message if route is changed
    const { isDirty } = this.props;
    const { confirmedNavigation } = this.state;

    if (isDirty && !confirmedNavigation) {
      this.props.openDialog('EDIT_ORDER_DISCARD', {
        nextPath: nextLocation,
        onDiscard: this.handleDiscard,
      });
      return false;
    }

    return true;
  };

  handleSave = () => {
    const { order, quote } = this.props;
    this.setState({ confirmedNavigation: true }, () => {
      this.props.openDialog('EDIT_ORDER_CONFIRM', {
        onSubmit: () => this.props.editOrder(order.id, quote.id),
        onClose: () => this.setState({ confirmedNavigation: false }),
      });
    });
  };

  handleDiscard = path => {
    const { historyPush } = this.props;
    this.setState({ confirmedNavigation: true }, () => {
      historyPush(path);
    });
  };

  handleBack = () => {
    const { historyPush, order } = this.props;
    const referer = new URLSearchParams(window.location.search).get('referer');
    historyPush(referer ? decodeURIComponent(referer) : `/orders/${order.id}`);
  };

  render() {
    const {
      t,
      match,
      allowOptimize,
      shouldOptimize,
      order,
      orderPrice,
      quote,
      errorMsg,
      isFetching,
      isFetchingQuotation,
      isSubmitting,
      cityDict,
      currentCity,
      isDirty,
    } = this.props;
    const { orderId } = match.params;

    if (!isFetching && !Object.keys(order).length) return null;

    return (
      <Container>
        <Left>
          {isFetching ? (
            <Wrapper>
              <Center>
                <Spinner big />
              </Center>
            </Wrapper>
          ) : (
            <Fragment>
              <StatusBar
                status={order.status}
                refId={order.refId}
                dateTime={order.deliveryDatetime}
              />
              <Alert
                messageSelector={['FETCH_QUOTATION', 'EDIT_ORDER']}
                persist
              />
              <LeftContent>
                <Prompt when={isDirty} message={this.handleRouteChanged} />
                <HeadingGroup>
                  <Heading size="large">
                    {t('EditOrder.heading_edit_order_route')}
                  </Heading>
                </HeadingGroup>
                <Heading htmlTag="h3">{t('PlaceOrder.heading_route')}</Heading>
                <Editor orderId={orderId} />
                {allowOptimize && (
                  <Box>
                    <Checkbox
                      checked={shouldOptimize}
                      name="optimize"
                      label={t('PlaceOrder.optimize_route')}
                      onChange={this.handleOptimizeRouteToggle}
                    />
                    <ClickArea onClick={this.handleEnquireOptimizeRoute}>
                      <InfoIcon />
                    </ClickArea>
                  </Box>
                )}
                <Modal
                  title={t('PlaceOrder.modal_optimize_route_title')}
                  name="optimize_route"
                  isOpen={this.state.optimizeRouteModal}
                  onClose={this.handleModalClose}
                >
                  <ModalImage>
                    <img
                      src={optRouteJPG}
                      alt={t('PlaceOrder.optimize_route')}
                    />
                  </ModalImage>
                  <ModalContent>
                    <Trans i18nKey="PlaceOrder.modal_optimize_route_content">
                      <p>
                        Use <strong>Optimize Route</strong> and we will
                        calculate the shortest way to each location from the
                        starting point.
                      </p>
                      <p>
                        When you tick the box, the order of the stops and the
                        final price will be updated to reflect the new path.
                      </p>
                    </Trans>
                  </ModalContent>
                </Modal>
              </LeftContent>
              <Footer
                errors={errorMsg}
                price={quote.price || orderPrice}
                onBack={this.handleBack}
                onSubmit={this.handleSave}
                isLoading={isSubmitting}
                isFormReady={!isFetchingQuotation && isDirty && !!quote.id}
              />
            </Fragment>
          )}
        </Left>
        <Right>
          <RoutePreview
            currentCity={cityDict[order.city] || currentCity}
            orderId={order.id}
          />
        </Right>
      </Container>
    );
  }
}

const mapState = (state, ownProps) => {
  const { orderId } = ownProps.match.params;
  const order = getOrder(state, orderId);
  const filledWaypoints = getFilledWaypts(state, orderId);
  const filledWaypointsIds = getFilledWayptsIds(state, orderId);

  return {
    order,
    orderPrice: getPriceByOrderId(state, orderId),
    deliveryInfos: getDeliveryInfoByWayptIds(state, filledWaypointsIds),
    allowOptimize: filledWaypoints.length >= 4,
    shouldOptimize: getShouldOptimize(state, orderId),
    quote: getLatestQuotation(state),
    isFetching: createLoadingSelector(['INIT_EDIT'])(state),
    errorMsg: makeMessageSelector(['FETCH_QUOTATION', 'EDIT_ORDER'])(state),
    isFetchingQuotation: createLoadingSelector(['FETCH_QUOTATION'])(state),
    isSubmitting: createLoadingSelector(['EDIT_ORDER'])(state),
    cityDict: getCityDict(state),
    currentCity: getCurrentCity(state),
    isDirty: isFormDirty('editOrder')(state),
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapState, {
    initEdit,
    editOrder,
    openDialog,
    toggleOptimize,
    historyPush: push,
    resetQuotation,
  })
)(EditOrder);
