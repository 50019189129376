import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import NoCouponYetSVG from 'assets/svg/no-coupon-modal-bg.svg';
import { Coupon as CouponType } from 'interfaces/global/store/modules/pricing/types';

import Alert from 'components/Modal/Alert';
import { clearRedeemCouponHistory } from 'interfaces/global/store/modules/pricing/actions';
import { dismissMessage } from 'store/modules/message';

import RedeemCoupon from 'components/RedeemCoupon';
import Coupon from 'components/Coupon';
import BaseModal from 'components/BaseModal';
import {
  Header,
  CloseButton,
  NoCouponYet,
  Title,
  Description,
  Footer,
  CancelButton,
  ApplyButton,
  Coupons,
  CouponsTitle,
  RedeemCouponContainer,
} from './styles';
import useRedeemCheckoutCoupon from './useRedeemCheckoutCoupon';

const StyledModal = styled(BaseModal)`
  & .ReactModal__Content {
    width: 488px;
    min-height: 530px;
  }
`;

export const ALERT_SUCCESS_TIMEOUT = 4000;
export const ALERT_WARNING_TIMEOUT = 8000;

interface Props {
  isOpen: boolean;
  onApply: (coupon: CouponType | null, index: number) => void;
  onClose: () => void;
  coupons: CouponType[];
  selectedCoupon: CouponType | null;
}

const SelectCouponModal: React.FC<Props> = ({
  isOpen,
  onApply,
  onClose,
  coupons,
  selectedCoupon,
}) => {
  const [activeCouponId, setActiveCouponId] = useState(
    selectedCoupon?.coupon_id
  );
  const {
    redeemCode,
    setRedeemCode,
    handleRedeem,
    isLoading,
    response,
    redeemedCoupons,
  } = useRedeemCheckoutCoupon(setActiveCouponId);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setActiveCouponId(selectedCoupon?.coupon_id);
    }
  }, [isOpen, selectedCoupon]);

  const sortedCoupons = useMemo(() => {
    const nonRedeemedCouponsOnly = (coupon: CouponType) =>
      redeemedCoupons.every(
        redeemedCoupon => redeemedCoupon.coupon_id !== coupon.coupon_id
      );

    const [bestCoupon, ...rest] = coupons.filter(nonRedeemedCouponsOnly);
    const restByExpiryDate = rest.sort(
      (a: CouponType, b: CouponType) => a.end_ts - b.end_ts
    );

    return [
      ...redeemedCoupons,
      ...(bestCoupon ? [bestCoupon] : []),
      ...restByExpiryDate,
    ];
  }, [coupons, redeemedCoupons]);

  const handleUserInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setRedeemCode(e.target.value.toUpperCase());
  };

  const toggleActiveCoupon = (newActiveCouponId: string): void =>
    activeCouponId === newActiveCouponId
      ? setActiveCouponId(undefined)
      : setActiveCouponId(newActiveCouponId);

  const handleApplyCoupon = () => {
    const activeCouponIndex = activeCouponId
      ? sortedCoupons.findIndex(c => c.coupon_id === activeCouponId)
      : -1;
    const activeCoupon = sortedCoupons[activeCouponIndex] ?? null;
    onApply(activeCoupon, activeCouponIndex);
    onClose();
  };

  const handleAfterClose = () => {
    const isActiveCouponIdRedeemed = redeemedCoupons.some(
      coupon => coupon.coupon_id === activeCouponId
    );

    if (!isActiveCouponIdRedeemed) {
      dispatch(clearRedeemCouponHistory());
    }
    dispatch(dismissMessage('REDEEM_CHECKOUT_COUPON'));
    setRedeemCode('');
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={handleAfterClose}
      parentSelector={() => document.querySelector('#🚐') as HTMLElement}
      name="coupon"
    >
      <Header>
        <CloseButton onClick={onClose} />
      </Header>

      <RedeemCouponContainer>
        <Alert
          messageSelector={['REDEEM_CHECKOUT_COUPON']}
          show={response.type && response.type !== 'error'}
          timeout={
            response.type === 'warning'
              ? ALERT_WARNING_TIMEOUT
              : ALERT_SUCCESS_TIMEOUT
          }
        />
        <RedeemCoupon
          value={redeemCode}
          onChange={handleUserInput}
          onRedeem={handleRedeem}
          isLoading={isLoading}
          placeholder={t('Coupon.enter_coupon_code')}
          {...(response.type === 'error' &&
            response.message && { error: response.message })}
        />
      </RedeemCouponContainer>

      {sortedCoupons.length ? (
        <>
          <CouponsTitle>{t('Coupon.available_coupons_title')}</CouponsTitle>
          <Coupons>
            {sortedCoupons.map((coupon: CouponType) => (
              <Coupon
                coupon={coupon}
                isSelected={coupon.coupon_id === activeCouponId}
                onClick={() => toggleActiveCoupon(coupon.coupon_id)}
                key={coupon.coupon_id}
              />
            ))}
          </Coupons>
        </>
      ) : (
        <NoCouponYet>
          <img
            src={NoCouponYetSVG}
            alt={t('Coupon.no_available_coupon_title')}
          />
          <Title>{t('Coupon.no_available_coupon_title')}</Title>
          <Description>
            {t('Coupon.no_available_coupon_description')}
          </Description>
        </NoCouponYet>
      )}

      <Footer>
        <CancelButton onClick={onClose}>
          {t('Coupon.select_cancel')}
        </CancelButton>
        <ApplyButton
          disabled={coupons.length === 0}
          onClick={handleApplyCoupon}
        >
          {t('Coupon.select_apply')}
        </ApplyButton>
      </Footer>
    </StyledModal>
  );
};

export default SelectCouponModal;
