import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { arrayOf, bool, func, oneOf, shape } from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import noResultSVG from 'assets/svg/table_no_result.svg';
import EmptyState from 'components/EmptyState';
import OrderIdDisplay from 'components/OrderIdDisplay';
import WrappedTable from 'components/WrappedTable';
import Transaction from 'models/Transaction';
import { black, green, silver } from 'styles/colors';
import formatter from 'utils/formatter';
import { noop } from 'utils/helpers';

const StyledTable = styled(WrappedTable)`
  table-layout: fixed;

  & th:first-of-type,
  & td:first-of-type {
    padding-left: 2em;
  }
`;

const SupportText = styled.div`
  color: ${silver};
  white-space: nowrap;
`;

const Truncate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ColoredText = styled.span`
  color: ${({ color }) => color};
  font-weight: bold;
`;

const methodMap = {
  [Transaction.METHOD_CREDIT]: {
    PENDING: 'Wallet.label_type_credit_pending',
    ORDER: 'Wallet.label_type_credit_order',
    TOPUP: 'Wallet.label_type_credit_topup',
    ADJUSTMENT: 'Wallet.label_type_credit_adjustment',
  },
  [Transaction.METHOD_REWARD]: {
    PENDING: 'Wallet.label_type_reward_pending',
    REDEEMED: 'Wallet.label_type_reward_redeemed',
    EARNED: 'Wallet.label_type_reward_earned',
    EXPIRED: 'Wallet.label_type_reward_expired',
    VOIDED: 'Wallet.label_type_reward_voided',
  },
  [Transaction.METHOD_CASH]: {
    PENDING: 'Wallet.label_type_cash_pending',
    ORDER: 'Wallet.label_type_cash_order',
  },
};

const renderAmount = amount => (
  <ColoredText color={amount > 0 ? green : black}>
    {formatter.currency(amount)}
  </ColoredText>
);

const WalletTable = ({ t, method, historyList, loading }) => {
  const columns = [
    {
      key: 'type',
      label: t('Wallet.table_column_title_transaction_type'),
      render: type => t(methodMap[method][type]),
    },
    {
      key: 'createdAt',
      label:
        method === Transaction.METHOD_CASH
          ? t('Wallet.table_column_title_order_time')
          : t('Wallet.table_column_title_transaction_time'),
      render: createdAt => (
        <Fragment>
          <div>{moment(createdAt).format(t('DateTime.date_full'))}</div>
          <SupportText>
            {moment(createdAt).format(t('DateTime.time_24'))}
          </SupportText>
        </Fragment>
      ),
    },
    {
      key: 'orderId',
      label: t('Wallet.table_column_title_order_id'),
      render: orderId => <OrderIdDisplay orderId={orderId} />,
    },
    {
      key: 'orderBy',
      label: t('Wallet.table_column_title_order_by'),
      render: orderBy => <Truncate title={orderBy}>{orderBy}</Truncate>,
    },
    {
      key: 'amount',
      label: t('Wallet.table_column_title_amount'),
      render: amount => renderAmount(amount),
    },
  ];

  return (
    <StyledTable
      hoverable
      data={historyList}
      columns={columns}
      loading={loading}
      emptyState={
        <EmptyState
          image={<img src={noResultSVG} alt={t('Wallet.msg_no_data')} />}
          text={t('Wallet.msg_no_data')}
        />
      }
    />
  );
};

WalletTable.defaultProps = {
  t: noop,
  method: Transaction.METHOD_CREDIT,
  historyList: [],
  loading: false,
};

WalletTable.propTypes = {
  t: func,
  method: oneOf(Transaction.ALL_METHODS),
  historyList: arrayOf(shape()),
  loading: bool,
};

export default withTranslation()(WalletTable);
