import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

export default async function updatePreferences({
  email,
  eReceipt,
  pod,
  statement,
  topupReminder,
}) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    os: 'web',
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
    args: JSON.stringify({
      ...(email !== undefined && { contact_email: email }),
      ...(eReceipt !== undefined && { is_send_receipt: eReceipt ? 1 : 2 }),
      ...(pod !== undefined && { is_proof_of_delivery_required: pod ? 1 : 2 }),
      ...(statement !== undefined && { is_send_statement: statement ? 1 : 2 }),
      ...(topupReminder !== undefined && {
        is_send_topup_reminder: topupReminder ? 1 : 2,
      }),
    }),
  };

  const res = await fetcher.get('?_m=update_preferences', params);
  const { ret, msg, data } = res;

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data;
}
