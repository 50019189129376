import React from 'react';
import { arrayOf, bool, func, shape, string, number } from 'prop-types';
import { Button, Pagination } from '@lalamove/karang';
import { repeat as RefreshIcon } from '@lalamove/karang/dist/components/Icon/icons';
import styled from 'styled-components';
import { white } from 'styles/colors';

import DateFilter from '../DateFilter';
import { StatusDropdown } from '../../style';

const Container = styled.div`
  display: flex;

  > :not(:last-child) {
    margin-right: 1rem;
  }
`;

const FilterControls = ({
  onRefresh,
  dateFilterOptions,
  statusFilterOptions: { filters, selectedFilter, onFilterChange },
  paginationOptions: { currentPage, pageSize, loading, total, onPageChange },
}) => (
  <Container>
    <Button variant="primary" onClick={onRefresh} solid>
      <RefreshIcon size={18} color={white} />
    </Button>
    <StatusDropdown
      items={filters}
      selectedItem={selectedFilter}
      onChange={onFilterChange}
    />
    <DateFilter {...dateFilterOptions} />
    <Pagination
      current={currentPage}
      pageSize={pageSize}
      onChange={onPageChange}
      loading={loading}
      total={total}
      description=""
    />
  </Container>
);

export default FilterControls;

const filterShape = shape({
  value: string.isRequired,
  label: string.isRequired,
});

FilterControls.defaultProps = {
  paginationOptions: { currentPage: 1, pageSize: 10, loading: false },
};

FilterControls.propTypes = {
  statusFilterOptions: shape({
    filters: arrayOf(filterShape),
    selectedFilter: filterShape,
    onFilterChange: func.isRequired,
  }).isRequired,
  dateFilterOptions: shape({
    start: string.isRequired,
    end: string.isRequired,
    min: string.isRequired,
    max: string.isRequired,
    onDatesChange: func.isRequired,
  }).isRequired,
  paginationOptions: shape({
    currentPage: number,
    pageSize: number,
    loading: bool,
    total: number.isRequired,
    onPageChange: func.isRequired,
  }),
  onRefresh: func.isRequired,
};
