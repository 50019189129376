import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import FetcherFactory from '../FetcherFactory';
import FetcherLockService from '../FetcherLockService';
import UAPIFetcher from '../UAPIFetcher';

export default async function fetchWalletDetails() {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  await FetcherLockService.langMutex.release();
  await FetcherLockService.accessTokenMutex.release();
  const {
    accessToken,
    profileType,
    cityId,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      city_id: cityId,
    }),
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
  };

  const { ret, data, msg } = await fetcher.get('?_m=wallet_balance', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return {
    balanceFen: data.balance_fen,
  };
}
