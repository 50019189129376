import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import moment from 'moment';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

export const CHANGE_PHONE_NUMBER_MODE = 1;
export const RESET_PASSWORD_MODE = 2;

const requestSMSCode = async ({ phoneNumber, mode }) => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const { countryId, locale } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      phone_no: phoneNumber,
      is_new: mode, // See CHANGE_PHONE_NUMBER_MODE and RESET_PASSWORD_MODE above
    }),
    hcountry: countryId,
    os: 'web',
    hlang: toLLMLower(locale),
    _t: moment().unix(),
  };

  const { ret, data, msg } = await fetcher.get('?_m=send_sms_code', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data;
};
export default requestSMSCode;
