import { Button } from '@lalamove/karang';
import styled from 'styled-components';
import { StyledModalAdapter } from 'components/BaseModal';
import { HEADER } from 'styles/zIndex';
import { nobel, mineShaft } from 'styles/colors';

interface IconProps {
  src: string;
}

export const Container = styled(StyledModalAdapter).attrs({
  overlayClassName: 'app-drawer-overlay',
  modalClassName: 'app-drawer',
})`
  & .app-drawer-overlay {
    position: fixed;
    z-index: ${HEADER};
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;

    background-color: transparent;
    transition: background-color 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  }

  & .app-drawer-overlay--after-open {
    background-color: rgba(0, 0, 0, 0.7);
  }

  & .ReactModal__Overlay--before-close {
    background-color: transparent;
  }

  & .app-drawer {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;

    outline: none;
    width: 70vw;
    max-width: 300px;
    height: 100%;
    background-color: #fff;
    transform: translateX(-100%);
    transition: transform 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }

  & .app-drawer--after-open {
    transform: translateX(0);
  }

  & .ReactModal__Content--before-close {
    pointer-events: none;
    transform: translateX(-100%);
  }
`;

export const Header = styled.div`
  padding: 32px 32px 16px;
  background-color: ${nobel['050']};
`;

export const Avatar = styled.img`
  width: 64px;
  height: 64px;
  padding: 5px;
`;

export const Heading = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.25px;
`;

export const Menu = styled.div`
  padding: 8px 0;
`;

export const Icon = styled.img.attrs(({ src }: IconProps) => ({
  src,
}))`
  width: 24px;
  height: 24px;
`;

export const MenuItem = styled(Button)`
  width: 100%;
  height: 56px;
  padding: 0 32px;
  border: none;
  color: ${mineShaft[900]};

  > span {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`;

export const MenuItemTitle = styled.span`
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const MenuItemSubtitle = styled.span`
  color: ${mineShaft[600]};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

export const LineBreak = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  margin: 0 0 8px 32px;
  background-color: ${mineShaft[200]};
`;
