import { createStore } from 'redux-dynamic-modules';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { sessionReducer, sessionService } from 'redux-react-session';

import { regionModule as region } from 'store/modules/region';
import loading from 'store/modules/loading';
import message from 'store/modules/message';
import FetcherFactory from 'api/FetcherFactory';
import Formatter from 'utils/formatter';
import Segment from 'utils/segment';
import validator from 'utils/validator';

export default async function configureStore(history) {
  const extensions = [getSagaExtension()];

  const store = createStore(
    {
      extensions,
    },
    {
      id: 'router',
      reducerMap: { router: connectRouter(history) },
      middlewares: [routerMiddleware(history)],
    },
    {
      id: 'session',
      reducerMap: { session: sessionReducer },
    },
    {
      id: 'loading',
      reducerMap: { loading },
    },
    {
      id: 'message',
      reducerMap: { message },
    }
  );

  FetcherFactory.bindStore(store);
  Formatter.bindStore(store);
  Segment.bindStore(store);
  validator.bindStore(store);
  // wait auth status settle to prevent flashing of login page
  await sessionService.initSessionService(store);
  store.addModule(region);

  return store;
}
