/* eslint-disable import/no-named-as-default */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MobileHeader from 'components/MobileHeader';
import OptimizeRoute from 'components/OptimizeRoute';
import { useResetScrollOnMount } from 'components/ResetScrollOnMount';
import Editor from 'interfaces/global/containers/Editor';
import AdditionalServices from 'interfaces/global/containers/PlaceOrder/components/AdditionalServices';

import { setCheckoutStep } from 'interfaces/global/store/modules/checkout/actions';
import { CheckoutSteps } from 'interfaces/global/store/modules/checkout/types';

import useOptimizeRoute from 'components/OptimizeRoute/useOptimizeRoute';
import useFooter from './useFooter';
import Footer from '../PlaceOrderFooter';
import { Container, Heading, Spacer } from './styles';
import useCashOnly from '../SelectVehicle/useCashOnly';

const SelectRoute: React.FC = () => {
  useResetScrollOnMount();
  useCashOnly();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { allowOptimize, shouldOptimize, onToggle } = useOptimizeRoute();
  const { showFooter, isLoading, onNext } = useFooter();

  return (
    <Container>
      <MobileHeader
        onClickLeft={() =>
          dispatch(setCheckoutStep(CheckoutSteps.SELECT_VEHICLE))
        }
      />
      <Heading>{t('PlaceOrder.heading_route')}</Heading>
      {/* When importing JS components, TS infers typings from PropTypes,
        but it errors when not passing in required props that actually comes from redux connect :(
        eslint-disable-next-line @typescript-eslint/ban-ts-comment
        @ts-ignore */}
      <Editor />
      <OptimizeRoute
        allowOptimize={allowOptimize}
        shouldOptimize={shouldOptimize}
        onToggle={onToggle}
      />
      <AdditionalServices />
      <Spacer />
      {showFooter && <Footer isLoading={isLoading} onNext={onNext} />}
    </Container>
  );
};

export default SelectRoute;
