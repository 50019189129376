import services from 'store/modules/services';
import servicesSaga from 'store/modules/services/saga';

const servicesModule = {
  id: 'services',
  reducerMap: { services },
  sagas: [servicesSaga],
};

export default servicesModule;
