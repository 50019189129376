import saga from './saga';

/**
 * Should handle all side-effects related to reading / writing from localStorage
 */
const localStorageModule = {
  id: 'storage',
  sagas: [saga],
};

export default localStorageModule;
