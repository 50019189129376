import Big from 'big.js';
import {
  call,
  put,
  select,
  fork,
  takeLatest,
  takeEvery,
  delay,
  take,
} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { getCurrentCountry } from 'store/modules/region/selectors';
import { checkAuth } from 'interfaces/global/store/modules/auth/selectors';
import {
  fetchWalletBalance,
  getChargeList,
  recharge,
  fetchWalletRecords,
  fetchCouponList,
  fetchUpdateCoupon,
  fetchStatement,
} from 'api/uAPI';
import { redeemCouponErrorCodeMap, UApiError } from 'utils/helpers';
import { ERROR_BUSINESS_PROFILE_REMOVED_RET } from 'utils/apiHelper';
import { CHANGE_LOCATION_SUCCESS } from 'store/modules/region/actions';
import { PLACE_ORDER_SUCCESS } from 'interfaces/global/store/modules/checkout/actions';
import { SET_PROFILE_TYPE_SUCCESS } from 'interfaces/global/store/modules/auth/actions';
import { closeModal, openModal } from 'store/modules/ui';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { DEFAULT_CURRENCY_RATE } from 'interfaces/global/config';
import {
  getTopUpSource,
  getStatementTypes,
  getCreditBalance,
} from './selectors';
import {
  FETCH_BALANCE_REQUEST,
  FETCH_BALANCE_SUCCESS,
  FETCH_STATEMENT_REQUEST,
  FETCH_STATEMENT_SUCCESS,
  FETCH_CHARGELIST_REQUEST,
  RECHARGE_REQUEST,
  FETCH_CHARGELIST_SUCCESS,
  FETCH_CHARGELIST_FAILURE,
  FETCH_WALLET_HISTORY_REQUEST,
  FETCH_WALLET_HISTORY_SUCCESS,
  FETCH_WALLET_HISTORY_FAILURE,
  TOPUP_INIT,
  TOPUP_SUCCESS,
  FETCH_COUPONLIST_REQUEST,
  FETCH_COUPONEXCHANGE_REQUEST,
  FETCH_COUPONEXCHANGE_FAILURE,
  FETCH_COUPONEXCHANGE_SUCCESS,
  REDEEM_WALLET_COUPON_SUCCESS,
  FETCH_COUPONLIST_SUCCESS,
  RECHARGE_FAILURE,
  RECHARGE_SUCCESS,
} from './actions';

export const STATEMENT_INTERVAL = 2000;

const topupChannel = channel();
export function* onFetchBalance() {
  const authenticated = yield select(checkAuth);
  if (!authenticated) return;

  try {
    const data = yield call(fetchWalletBalance);
    const { balanceFen } = data;
    const { currencyRate = DEFAULT_CURRENCY_RATE } = yield select(
      getCurrentCountry
    );
    const creditBalance = Big(balanceFen).div(currencyRate);
    yield put({
      type: FETCH_BALANCE_SUCCESS,
      creditBalance,
    });
  } catch (errorCode) {
    // eslint-disable-next-line no-console
    console.error('error on fetch balance', errorCode);
  }
}
export function* onTopupInit({ source }) {
  const authenticated = yield select(checkAuth);
  if (!authenticated) return;

  yield put(openModal('TOPUP'));

  // tracking
  if (source) {
    const balance = yield select(getCreditBalance);
    yield put(
      track('top_up_page_viewed', { source, has_balance: Boolean(balance) })
    );
  }
}
export function* onFetchChargeList() {
  try {
    const data = yield call(getChargeList);
    const chargeList = data.charge_list;
    yield put({
      type: FETCH_CHARGELIST_SUCCESS,
      chargeList,
    });
  } catch ({ errorCode }) {
    // eslint-disable-next-line no-console
    console.error('error on chargeList', errorCode);
    yield put({
      type: FETCH_CHARGELIST_FAILURE,
      errorCode,
    });
  }
}
export function* onRecharge({ chargeId, discount, total }) {
  const authenticated = yield select(checkAuth);
  if (!authenticated) return;

  try {
    const source = yield select(getTopUpSource);
    const data = yield call(recharge, { chargeId, discount, total });
    yield put({ type: RECHARGE_SUCCESS });
    yield put(closeModal());

    const payUrl = data.hpay_cashier_url;
    yield put(
      openModal('PAYMENT', {
        url: payUrl,
        intent: 'TOPUP',
        topupSource: source,
        amount: total,
      })
    );
  } catch ({ message, errorCode }) {
    if (errorCode === ERROR_BUSINESS_PROFILE_REMOVED_RET) {
      yield put(closeModal());
      yield put({ type: RECHARGE_FAILURE });
      return;
    }
    yield put({
      type: RECHARGE_FAILURE,
      meta: {
        type: 'error',
        message,
        data: 'llm.topup',
      },
    });
  }
}

export function* watchTopupChannel() {
  while (true) {
    const action = yield take(topupChannel);
    yield put(action);
  }
}

export function* onFetchStatement({ startDate, endDate, fileType, requestId }) {
  const {
    request_id: newRequestId,
    url: downloadUrl,
  } = yield call(fetchStatement, { startDate, endDate, fileType, requestId });
  if (downloadUrl === '') {
    yield delay(STATEMENT_INTERVAL);
    yield call(onFetchStatement, {
      startDate,
      endDate,
      fileType,
      requestId: newRequestId,
    });
    return;
  }
  window.location.href = downloadUrl;
  const statementTypes = yield select(getStatementTypes);
  yield put({
    type: FETCH_STATEMENT_SUCCESS,
    statementTypes: statementTypes.filter(item => item !== fileType),
  });
}

export function* onFetchWalletHistory({ query }) {
  try {
    const data = yield call(fetchWalletRecords, query);
    yield put({
      type: FETCH_WALLET_HISTORY_SUCCESS,
      method: query.method,
      ...data,
    });
  } catch ({ message }) {
    yield put({
      type: FETCH_WALLET_HISTORY_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}
export function* onFetchCouponList({ query }) {
  try {
    const data = yield call(fetchCouponList, query);
    const coupon = {
      couponNum: data.total_count,
      couponList: data.list ? data.list : [],
    };
    yield put({
      type: FETCH_COUPONLIST_SUCCESS,
      coupon,
    });
  } catch ({ message }) {
    /* continue regardless of error */
  }
}
export function* onFetchCouponExchange({ code }) {
  try {
    yield call(fetchUpdateCoupon, { code });
    yield put({
      type: FETCH_COUPONEXCHANGE_SUCCESS,
    });
    yield put({
      type: REDEEM_WALLET_COUPON_SUCCESS,
      meta: {
        type: 'success',
        message: 'Coupon.coupon_added_successfully',
      },
    });

    yield put(track('coupon_code_tapped', { source: 'wallet' }));
    yield put(track('coupon_code_redeemed', { source: 'wallet' }));
  } catch (error) {
    if (error instanceof UApiError) {
      yield put({
        type: FETCH_COUPONEXCHANGE_FAILURE,
        meta: {
          type: 'error',
          message: error.message,
          data: 'llm.couponExchange',
        },
      });

      yield put(
        track('coupon_code_tapped', {
          source: 'wallet',
          redeem_error: redeemCouponErrorCodeMap[error.errorCode],
        })
      );
    } else throw error;
  }
}

export function* onTopUpSuccess({ topupAmount }) {
  const { currencyRate, isoCurrencyCode } = yield select(getCurrentCountry);
  yield put(
    track('top_up_success', {
      currency_code: isoCurrencyCode,
      topup_amount: Big(topupAmount).div(currencyRate).toNumber(), // converted to a primitive number with a loss of precision
    })
  );
}

export default function* rootSaga() {
  yield takeLatest(
    [
      FETCH_BALANCE_REQUEST,
      TOPUP_SUCCESS,
      PLACE_ORDER_SUCCESS,
      CHANGE_LOCATION_SUCCESS,
      SET_PROFILE_TYPE_SUCCESS,
    ],
    onFetchBalance
  );
  yield takeEvery(FETCH_STATEMENT_REQUEST, onFetchStatement);
  yield takeLatest(TOPUP_INIT, onTopupInit);
  yield takeLatest(FETCH_CHARGELIST_REQUEST, onFetchChargeList);
  yield takeLatest(RECHARGE_REQUEST, onRecharge);
  yield takeLatest(FETCH_WALLET_HISTORY_REQUEST, onFetchWalletHistory);
  yield takeLatest(FETCH_COUPONLIST_REQUEST, onFetchCouponList);
  yield takeLatest(FETCH_COUPONEXCHANGE_REQUEST, onFetchCouponExchange);
  yield takeLatest(TOPUP_SUCCESS, onTopUpSuccess);
  yield fork(watchTopupChannel);
}
