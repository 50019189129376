import { LalamoveApiError } from 'utils/helpers';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Response {
//   account_type: String; // 'REGISTRATION'
//   client_id: Number;
//   contact_info: {
//     contact_email: String,
//     contact_name: String,
//     contact_phone: String,
//     oauth_client: Number,
//   }[];
//   corporate_info: {
//     address: String,
//     industry_ids: Number,
//     name: String,
//     size_id: null, // ??
//   };
//   country: String;
//   country_code: Number;
//   email: String;
//   email_verified_status: Boolean;
//   is_pod_enabled: Boolean;
//   last_name: String;
//   name: String;
//   partner_id: null;
//   password_set: Boolean;
//   phone_number: String;
//   phone_verified_status: Boolean;
//   registered_country: String;
//   selectors: {
//     industries: { id: Number, name: String }[],
//     sizes: { id: Number, name: String }[],
//   };
//   social_login_id: string | null;
//   subscriptions: any[];
// }
// AuthResponse<Response>
export default async function fetchUserProfile() {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { accessToken } = await fetcher.getState();
  const params = { access_token: accessToken };
  const { success, data, code } = await fetcher.get('getuserprofile', params);
  if (!success) throw new LalamoveApiError(code);
  return {
    ...data,
    client_id: String(data.client_id),
  };
}
