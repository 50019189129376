import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { checkAuth } from 'store/modules/auth/selectors';
import { compose } from 'utils/helpers';

const PrivateRoutes = ({
  location,
  children,
  checked,
  authenticated,
  ...rest
}) =>
  authenticated ? (
    <Route {...rest} render={() => children} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        search: location.search,
        state: {
          ...(location.pathname !== '/settings' && {
            referrer: location.pathname,
          }),
        },
      }}
    />
  );

PrivateRoutes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  checked: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

const mapState = state => ({
  checked: state.session.checked,
  authenticated: checkAuth(state),
});

export default compose(withRouter, connect(mapState))(PrivateRoutes);
