import { useEffect, useState } from 'react';
import Big from 'big.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import formatter from 'utils/formatter';
import convertPercentDiscountDisplay from 'utils/convertPercentDiscountDisplay';
import { selectors as regionSelectors } from 'store/modules/region';
import { getCurrencyRate } from 'store/modules/region/selectors';
import { Coupon } from '../../../../store/modules/pricing/types';

export default function useDescription(
  bestOnlinePaymentOnlyCoupon: Coupon | null
): string {
  const [description, setDescription] = useState('');
  const { t } = useTranslation();
  const currentLocale = useSelector(regionSelectors.getCurrentLocale);
  const currencyRate = useSelector(getCurrencyRate);

  useEffect(() => {
    if (bestOnlinePaymentOnlyCoupon) {
      setDescription(
        bestOnlinePaymentOnlyCoupon.discount_type === 1
          ? // amount off
            t('Coupon.tool_tip_amount_off', {
              amount: formatter.currency(
                Big(bestOnlinePaymentOnlyCoupon.discount_amount_fen).div(
                  currencyRate
                )
              ),
            })
          : // percent off
            t('Coupon.tool_tip_percentage_off', {
              percentage: convertPercentDiscountDisplay(
                100 - bestOnlinePaymentOnlyCoupon.discount_rate,
                currentLocale
              ),
            })
      );
    }
  }, [bestOnlinePaymentOnlyCoupon, currentLocale, t, currencyRate]);

  return description;
}
