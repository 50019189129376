import styled from 'styled-components';
import { List } from '@lalamove/karang';
import LalamoveLogoIcon from 'assets/svg/mobile-nav-lalamove-logo.svg';

interface BannerProps {
  banner: string;
}

interface IconProps {
  src: string;
  active?: boolean;
}

export const LalamoveLogo = styled.img.attrs({
  src: LalamoveLogoIcon,
  alt: 'Lalamove',
})``;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
`;

export const Welcome = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 33.3vw;
  min-height: 120px;
  max-height: 200px;

  background-image: ${({ banner }: BannerProps) => `url(${banner})`};
  background-position: top right;
  background-size: auto 100%;
  background-repeat: no-repeat;
`;

export const Message = styled.div`
  margin-left: 1.8em;
  & :not(:first-child) {
    margin-top: 4px;
  }
`;

export const BeforeHeading = styled.span``;

export const Heading = styled.h1`
  margin: 0;
`;

export const FlatList = styled(List)`
  box-shadow: none;
  > li > div {
    display: flex;
    align-items: center;
    padding-right: 14px;
    padding-left: 2px;
    border-bottom: none !important;
  }
`;

export const Icon = styled.img.attrs(({ src }: IconProps) => ({
  src,
}))`
  width: 24px;
  height: 24px;

  opacity: ${({ active = true }: IconProps) => (active ? 1 : 0)};
  transition: opacity 0.2s;
`;

export const Info = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 12px;

  line-height: 20px;
  letter-spacing: 0.216667px;
`;

export const Name = styled.span`
  font-weight: 700;
`;

export const Dimensions = styled.span``;

export const Spacer = styled.div`
  flex-grow: 1;
`;
