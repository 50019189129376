import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { shape, string, func, bool, number } from 'prop-types';
import styled from 'styled-components';
import { Button, Input, Form, Checkbox } from '@lalamove/karang';
import { ResponsiveCard } from 'components/Container';

import { registerSubmit as submitRegistration } from 'interfaces/global/store/modules/auth/actions';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';
import {
  selectors as regionSelector,
  actions as regionAction,
} from 'store/modules/region';

import PhoneInput from 'components/PhoneInput';
import HtmlTitle from 'components/HtmlTitle';

import md5 from 'md5';
import i18n from 'utils/i18n';
import { fontSize } from 'styles/fonts';
import { black, silver, red } from 'styles/colors';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { noop } from 'utils/helpersJS';

const FormItem = Form.Item;

const CardHeader = styled.h1`
  padding-bottom: 2rem;
  margin: 0;
  color: ${black};
  font-size: ${fontSize.large};
  text-align: center;
`;

const CardFooter = styled.div`
  padding-top: 2rem;
  color: ${silver};
  text-align: center;
`;

const FormInfo = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  color: ${silver};
  font-size: ${fontSize.regular};
  text-align: center;
`;

const StyledInput = styled(Input)`
  flex: 1;
`;

const ErrorMessage = styled.span`
  display: block;
  margin-left: 2em;
  color: ${red};
  font-size: ${fontSize.small};
`;

const StyledCheckbox = styled(Checkbox)`
  display: block;
  margin: 0.5em 0;
  font-size: ${fontSize.regular};
  & input {
    position: absolute;
  }
  & span:last-child {
    float: right;
  }
`;

StyledInput.displayName = 'StyledInput';

export class AccountVerification extends Component {
  static defaultProps = {
    socialLoginId: null,
    socialSource: null,
    signedProfile: null,
    loading: false,
    apiError: null,
    email: '',
    lastName: '',
    track: noop,
  };

  static propTypes = {
    t: func.isRequired,
    socialLoginId: string,
    socialSource: number,
    signedProfile: string,
    firstName: string.isRequired,
    lastName: string,
    email: string,
    loading: bool,
    apiError: shape({ message: string }),
    registerSubmit: func.isRequired,
    currentCountry: shape({
      id: string,
      areaCode: string,
      isEmailRequiredForSignUp: bool,
    }).isRequired,
    currentCity: shape({
      id: string,
      urls: shape({
        privacy: string,
        faq: string,
        terms: string,
        pricing: string,
      }),
    }).isRequired,
    countryDict: shape({}).isRequired,
    changeLocation: func.isRequired,
    track: func,
  };

  state = {
    email: this.props.email,
    mobile: '',
    terms: true,
    marketingOptIn: true,
    showError: false,
    showErrorTerms: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      socialLoginId,
      socialSource,
      signedProfile,
      registerSubmit,
      currentCountry,
    } = this.props;
    const { email, mobile, terms, marketingOptIn } = this.state;

    this.setState({ showErrorTerms: !terms });
    if (!terms) {
      return;
    }

    this.setState({ showError: true });
    registerSubmit({
      firstName,
      lastName,
      email,
      country: currentCountry.id,
      countryCode: currentCountry.areaCode,
      mobile,
      password: md5(socialLoginId),
      lang: i18n.language,
      socialLoginId,
      socialSource,
      signedProfile,
      marketingOptIn,
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value, showError: false });
  };

  handleBlur = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value.trim(),
    });
  };

  handleToggle = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleCountryChange = country => {
    const { currentCountry, changeLocation } = this.props;
    if (country === currentCountry.id) {
      return;
    }

    changeLocation({
      country,
    });
  };

  handleTermsClick = () => {
    this.props.track('tc_tapped', { source: 'social' });
  };

  isFormReady() {
    const {
      currentCountry: { isEmailRequiredForSignUp },
    } = this.props;
    const { mobile, email } = this.state;
    return !mobile || (isEmailRequiredForSignUp && !email);
  }

  renderError(name) {
    const { apiError } = this.props;

    if (!apiError) return null;

    if (!apiError.errorCode) {
      const errors = {
        email: [
          {
            msg: 'ERROR_INVALID_EMAIL',
            trans: 'Register.invalid_email',
          },
        ],
        mobile: [
          {
            msg: 'ERROR_INVALID_PHONE_NO',
            trans: 'Register.invalid_phone',
          },
        ],
      };

      if (!errors[name]) return null;

      const errObj = errors[name].find(
        errorObj => errorObj.msg === apiError.message
      );

      if (errObj) return errObj.trans;
      return null;
    }

    // display error per input. not two the same errors for each input
    // that's way `mobile` switch has no default case
    if (name === 'email') {
      switch (apiError.errorCode) {
        case 20006:
        case 30002:
        case 20003:
          return 'Register.email_exists';
        case 20001:
        default:
          return 'Common.internal_error';
      }
    }

    // eslint-disable-next-line default-case
    switch (apiError.errorCode) {
      case 20001:
      case 30001:
        return 'Register.invalid_phone';
      case 20004:
        return 'Register.already_sent_sms';
    }
    return null;
  }

  render() {
    const {
      loading,
      socialLoginId,
      t,
      currentCountry,
      currentCity,
      countryDict,
    } = this.props;

    const {
      email,
      mobile,
      terms,
      marketingOptIn,
      showError,
      showErrorTerms,
    } = this.state;

    if (!socialLoginId) return <Redirect to="/register" />;

    return (
      <Fragment>
        <HtmlTitle>{t('Title.account_verification')}</HtmlTitle>
        <ResponsiveCard>
          <CardHeader>{t('Register.account_verification')}</CardHeader>
          <form onSubmit={this.handleSubmit} noValidate>
            <FormItem>
              <StyledInput
                type="email"
                label={t('Register.email')}
                name="email"
                value={email}
                error={
                  showError && !loading ? t(this.renderError('email')) : ''
                }
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </FormItem>
            <FormItem>
              <PhoneInput
                label={t('Register.mobile')}
                name="mobile"
                value={mobile}
                error={
                  showError && !loading ? t(this.renderError('mobile')) : ''
                }
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                currentCountry={currentCountry}
                countries={Object.values(countryDict)}
                onCountryChange={this.handleCountryChange}
                showAreaCode
              />
            </FormItem>
            <FormItem>
              <FormInfo>{t('Register.verification_sms')}</FormInfo>
            </FormItem>
            <FormItem>
              <Button
                type="submit"
                variant="primary"
                size="large"
                block
                solid
                disabled={loading || this.isFormReady()}
                isLoading={loading}
              >
                {t('Register.send_verification')}
              </Button>
            </FormItem>

            <StyledCheckbox
              name="terms"
              label={
                <Trans i18nKey="Register.checkbox_terms">
                  I have read, understood and accept the
                  <a
                    href={currentCity.urls.terms}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.handleTermsClick}
                  >
                    Terms & Conditions
                  </a>
                  and
                  <a
                    href={currentCity.urls.privacy}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Trans>
              }
              checked={terms}
              value={terms}
              onChange={this.handleToggle}
            />
            {showErrorTerms && (
              <ErrorMessage>{t('Register.checkbox_terms_error')}</ErrorMessage>
            )}

            <StyledCheckbox
              name="marketingOptIn"
              label={
                <Trans i18nKey="Register.checkbox_marketing">
                  I agree the use of my personal data for direct marketing in
                  accordance with the stated
                  <a
                    href={currentCity.urls.privacy}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Trans>
              }
              value={marketingOptIn}
              checked={marketingOptIn}
              onChange={this.handleToggle}
            />
          </form>

          <CardFooter>
            <Trans i18nKey="Register.login">
              Already have an account?
              <Link to="/login" replace>
                Log in
              </Link>
              .
            </Trans>
          </CardFooter>
        </ResponsiveCard>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: createLoadingSelector(['REGISTER'])(state),
  apiError: makeMessageSelector(['REGISTER'])(state),
  firstName: state.auth.register.firstName,
  lastName: state.auth.register.lastName,
  email: state.auth.register.email,
  socialLoginId: state.auth.register.socialLoginId,
  socialSource: state.auth.register.socialLoginSource,
  signedProfile: state.auth.register.signedProfile,
  currentCountry: regionSelector.getCurrentCountry(state),
  currentCity: regionSelector.getCurrentCity(state),
  countryDict: regionSelector.getCountryDict(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    registerSubmit: submitRegistration,
    changeLocation: regionAction.changeLocation,
    track,
  })
)(AccountVerification);
