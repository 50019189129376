import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';
import { Button } from '@lalamove/karang';
import { facebook as FacebookIcon } from '@lalamove/karang/dist/components/Icon/icons';
import styled from 'styled-components';
import { rgba, darken } from 'polished';
import { facebook } from 'styles/colors';

const FbBtn = styled(Button)`
  padding: 0.7em 0.1em;
  background-color: ${facebook};
  border-color: ${facebook};

  &:hover:enabled,
  &:active:enabled {
    background-color: ${darken(0.15, facebook)};
    border-color: ${darken(0.15, facebook)};
  }

  &:active:enabled,
  &:focus:enabled {
    box-shadow: 0 0 0 4px ${rgba(facebook, 0.2)};
  }
`;

const Icon = styled(FacebookIcon)`
  margin: -0.5em 0.5em;
  vertical-align: middle;
`;

const BtnText = styled.span`
  vertical-align: middle;
`;

const FacebookButton = ({ children, ...remainProps }) => (
  <FbBtn {...remainProps} variant="primary" size="large" block solid>
    <Icon size={24} /> <BtnText>{children}</BtnText>
  </FbBtn>
);

FacebookButton.propTypes = {
  children: oneOfType([arrayOf(node), node]),
};

FacebookButton.defaultProps = {
  children: null,
};

export default FacebookButton;
