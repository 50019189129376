import { LalamoveApiError } from 'utils/helpers';

import RestAPIFetcher from 'api/RestAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

/**
 * Represents results from geocoding
 */
export const GeocoderStatus = Object.freeze({
  OK: 'OK',
  ERROR: 'ERROR',
  NOT_FOUND: 'NOT_FOUND',
});

async function performGeocode(params) {
  const fetcher = FetcherFactory.make(RestAPIFetcher);
  const response = await fetcher.get('maps/geocode', params);
  const { error } = response;
  if (error) throw new LalamoveApiError(error.code, error.fields);
  return response;
}

/**
 * Geocode via Sanctuary
 *
 * @param {Object} params
 * @param {string} params.address - the address string to perform geocoding for
 * @param {string} params.region - a two-character region code that biases the results to a particular region
 * @param {string} params.language - preferred language of the address to be shown
 * @return {Promise} result - resolves to
 * ```
 * {
 *   results: {array<Object>},
 *   status: {string},
 *   ...
 * }
 * ```
 * @throws Forbidden, BadRequest
 */
export async function geocode({ address, region, language }) {
  return performGeocode({ address, region, language });
}

/**
 * Perform bulk geocoding via Sanctuary
 * @param {array<string>} addresses - addresses to geocode
 * @param {Object} params
 * @param {string} params.region - a two-character region code that biases the results to a particular region
 * @param {string} params.language - preferred language of the address to be shown
 * @return {Promise} result - resolves to
 * ```
 * {
 *   results: {array<Object>},
 *   status: {string}
 * }
 * ```
 */
export async function bulkGeocode(addresses, { region, language }) {
  const params = { region, language };
  const resolutions = addresses.map(address =>
    Promise.resolve(geocode({ ...params, address }))
      .then(response => ({ status: response.status, response }))
      .catch(error => ({
        error,
        status: GeocoderStatus.ERROR,
        response: {
          status: GeocoderStatus.ERROR,
          results: [],
        },
      }))
  );

  return Promise.all(resolutions).then(assembleBulkGeocodeResults);
}

export function assembleBulkGeocodeResults(geocodeResults) {
  const response = {
    results: [],
    status: GeocoderStatus.OK,
  };

  for (const geocodeResult of geocodeResults) {
    if (geocodeResult.status !== GeocoderStatus.OK) {
      response.status = GeocoderStatus.NOT_FOUND;
    }

    response.results.push(geocodeResult.response);
  }

  if (response.status !== GeocoderStatus.OK) {
    throw response;
  }

  return response;
}

/**
 * Reverse geocode via Sanctuary
 *
 * @param {Object} params
 * @param {Object} params.latlng - coordinates of the location to be reverse geocoded
 * @param {number} params.latlng.lat - latitude of the location to be reverse geocoded
 * @param {number} params.latlng.lng - longitude of the location to be reverse geocoded
 * @param {string} params.language - preferred language of the address to be shown
 * @return {Promise} result - resolves to
 * ```
 * {
 *   results: {array<Object>},
 *   status: {string},
 *   ...
 * }
 * ```
 * @throws Forbidden, BadRequest
 */
export async function reverseGeocode({ latlng, language }) {
  const params = {
    latlng: `${latlng.lat},${latlng.lng}`,
    language,
  };
  return performGeocode(params);
}
