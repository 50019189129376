import React from 'react';
import { number, string, func, bool, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import Bar from 'components/StatusBar';
import {
  noop,
  globalOrderStatusColors as statusColors,
  globalOrderStatusLabels as statusTexts,
  daylightSavingsTimeMomentMap,
} from 'utils/helpers';

const middot = '\u00b7';

const StatusBar = ({
  status,
  refId,
  dateTime,
  t,
  solid,
  isMobileThumbnail,
}) => (
  <Bar
    status={t(statusTexts[status])}
    color={statusColors[status]}
    refId={refId}
    isOrderIdChunked={false}
    solid={solid}
    dateTime={moment(dateTime.time).format(
      `${
        isMobileThumbnail ? t('DateTime.date_month') : t('DateTime.date_full')
      } ${middot} ${
        t('DateTime.time_24') +
        daylightSavingsTimeMomentMap[dateTime.daylightSavingsTimeType]
      }`
    )}
    isMobileThumbnail={isMobileThumbnail}
  />
);

StatusBar.propTypes = {
  status: number.isRequired,
  refId: string,
  dateTime: shape({ time: string, daylightSavingsTimeType: number }),
  t: func,
  solid: bool,
  isMobileThumbnail: bool,
};

StatusBar.defaultProps = {
  t: noop,
  solid: false,
  refId: null,
  dateTime: {},
  isMobileThumbnail: false,
};

export default withTranslation()(StatusBar);
