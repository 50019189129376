import { toLLMUpper } from 'utils/locale';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Translation {
//   id: string; // e.g. en_HK
//   value: string;
// }
// interface Response {
//   data: {
//     areaCode: string;
//     cities: {
//       id: string;
//       lat: numbe;
//       lng: number;
//       translations: Translation[];
//     }[];
//     // domainAjax: string;
//     domainApp: string;
//     domainAuth: string;
//     domainLog: string;
//     domainRest: string;
//     defaultLanguage: string;
//     flag: string; // flag img src
//     id: string;
//     languages: {
//       id: string;
//       enable: boolean;
//       value: string;
//     }[];
//     lat: number;
//     lng: number;
//     samplePhone: string;
//     translations: Translation[];
//   }[];
//   footprint: string;
// }
export default async function fetchLocations(region) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  const { data } = await fetcher.get(
    'locations',
    {},
    {
      headers: {
        'X-LLM-LOCATION': 'HK_HKG',
        'X-LLM-REGION': region,
      },
      skipStartUpLock: true,
      fetchOption: { cache: 'no-store' },
    }
  );
  return data.map(cou => ({
    ...cou,
    cities: cou.cities.map(c => ({
      ...c,
      translations: c.translations.map(x => ({
        ...x,
        id: toLLMUpper(x.id),
      })),
    })),
    defaultLanguage: toLLMUpper(cou.defaultLanguage),
    translations: cou.translations.map(x => ({
      ...x,
      id: toLLMUpper(x.id),
    })),
  }));
}
