import { fork } from 'redux-saga/effects';
import { passwordSaga } from './password';
import { loginSaga } from './login';
import { registerSaga, verificationSaga } from './register';
import { profileSaga } from './profile.ts';

export default function* authSaga() {
  yield fork(passwordSaga);
  yield fork(loginSaga);
  yield fork(registerSaga);
  yield fork(verificationSaga);
  yield fork(profileSaga);
}
