import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Popover from 'components/Popover';
import { func, string, bool } from 'prop-types';
import styled from 'styled-components';
import { Checkbox, Heading } from '@lalamove/karang';
import {
  info as InfoIcon,
  remark as RemarkIcon,
} from '@lalamove/karang/dist/components/Icon/icons';
import ClickArea from 'components/ClickArea';
import { noop } from 'utils/helpers';
import { Box, Form, FormItem, ScTextArea, CheckoutHeading } from '../style';
import { ICON_SIZE, ICON_COLOR } from '../config';

const MAX_CHAR = 1000;

const PopoverContent = styled.div`
  width: 18rem;
`;

class Driver extends Component {
  static defaultProps = {
    title: '',
    note: '',
    placeholder: '',
    toolTipText: '',
    label: '',
    favDriverInfoText: '',
    limitMsgFunc: noop,
    errorMsgFunc: noop,
    hasFavorite: false,
    preferFavorite: false,
    onFavToggle: noop,
    onNoteChange: noop,
  };

  static propTypes = {
    title: string,
    note: string,
    placeholder: string,
    toolTipText: string,
    label: string,
    favDriverInfoText: string,
    limitMsgFunc: func,
    errorMsgFunc: func,
    hasFavorite: bool,
    preferFavorite: bool,
    onFavToggle: func,
    onNoteChange: func,
  };

  state = {
    shownInfo: false,
  };

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  toggleInfoPopover = () => {
    ReactTooltip.hide();
    this.setState(prevState => ({ shownInfo: !prevState.shownInfo }));
  };

  handleToggle = e => {
    this.props.onFavToggle(e.target.checked);
  };

  render() {
    const {
      title,
      note,
      placeholder,
      toolTipText,
      label,
      favDriverInfoText,
      limitMsgFunc,
      hasFavorite,
      preferFavorite,
      errorMsgFunc,
      onNoteChange,
    } = this.props;

    const infoIcon = (
      <ClickArea
        onClick={this.toggleInfoPopover}
        data-tip={toolTipText}
        data-place="top"
        data-effect="solid"
        data-for="global"
      >
        <InfoIcon />
      </ClickArea>
    );

    const favDriverInfo = (
      <PopoverContent>
        <Heading htmlTag="h3">{label}</Heading>
        {favDriverInfoText}
      </PopoverContent>
    );

    return (
      <Form>
        <CheckoutHeading text={title}>
          <RemarkIcon color={ICON_COLOR} size={ICON_SIZE} />
        </CheckoutHeading>
        <FormItem>
          <ScTextArea
            rows="3"
            placeholder={placeholder}
            characterLimitMsgGenerator={count => limitMsgFunc({ count })}
            exceedLimitMsgGenerator={count => errorMsgFunc({ count })}
            name="note"
            maxLength={MAX_CHAR}
            value={note}
            onChange={onNoteChange}
          />
        </FormItem>
        {hasFavorite && (
          <div>
            <FormItem bordered>
              <Box>
                <Checkbox
                  name="favorite-first"
                  label={label}
                  checked={preferFavorite}
                  onChange={this.handleToggle}
                />
                <Popover
                  isOpen={this.state.shownInfo}
                  preferPlace="above"
                  target={infoIcon}
                  onOuterAction={this.toggleInfoPopover}
                  body={favDriverInfo}
                >
                  {infoIcon}
                </Popover>
              </Box>
            </FormItem>
          </div>
        )}
      </Form>
    );
  }
}

export default Driver;
