import { createSu } from 'utils/apiHelper';
import { getUAPIDomain } from 'store/modules/region/selectors';
import Fetcher from './Fetcher';

export default class UAPIFetcher extends Fetcher {
  static uniqueName = 'UAPIFetcher';

  prepareBaseUrl = (state, { baseUrl } = {}) => {
    const { REACT_APP_UAPI_URL, REACT_APP_HOST_ENV } = process.env;
    const rawUAPIUrl = baseUrl || getUAPIDomain(state);

    let uapiUrl = rawUAPIUrl
      ? `//${rawUAPIUrl.replace(/http(s?):\/\//, '')}`
      : REACT_APP_UAPI_URL;

    if (REACT_APP_HOST_ENV === 'ldev' || REACT_APP_HOST_ENV === 'dev') {
      const matched = /(br|in)-uapi/.exec(rawUAPIUrl);
      const suffix = matched ? `${matched[1]}-uapi` : 'uapi';
      uapiUrl = REACT_APP_UAPI_URL.replace('uapi', suffix);
    }
    return uapiUrl;
  };

  prepareHeaders = (method, state, options) => {
    let headers = {
      ...options.headers,
    };
    if (['POST', 'PATCH'].includes(method)) {
      headers = {
        ...headers,
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      };
    }
    return headers;
  };

  prepareQuery = params => ({
    _su: createSu(),
    ...params,
  });

  prepareBody = params =>
    new URLSearchParams({
      ...params,
    });
}
