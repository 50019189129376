import React, { Component } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import ClickArea from 'components/ClickArea';
import Popover from 'components/Popover';
import { Button, Heading } from '@lalamove/karang';
import { info as InfoIcon } from '@lalamove/karang/dist/components/Icon/icons';
import _every from 'lodash/every';
import _pick from 'lodash/pick';
import _some from 'lodash/some';

import { SEGMENT_CLICK_PRICE_BREAKDOWN } from 'store/modules/placeOrder/tracking';
import formatter from 'utils/formatter';
import { noop } from 'utils/helpers';
import Segment from 'utils/segment';
import { gray } from 'styles/colors';
import { fontSize } from 'styles/fonts';

const Container = styled.div`
  padding: 8px;
  background: #fffaf7;
`;

const Price = styled.div`
  padding: 4px;
  color: #303030;
  font-weight: bold;
  text-align: right;
`;

const Amount = styled.span`
  margin: 0 6px;
  font-size: 2.6em;
  line-height: 0.8em;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

// TODO: move price breakdown to its own component, for FOX-1780
const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
  color: ${gray};
  font-size: 1em;
`;

const PriceLabel = styled.span`
  margin-right: 0.5em;
`;

const PriceValue = styled.span`
  margin-left: 0.5em;
`;

const ActionButton = styled(Button)`
  margin: auto 0.5rem;
  font-size: ${fontSize.regular};
`;

const matchPaths = ['/', '/confirmation'];

class Footer extends Component {
  static defaultProps = {
    price: {},
    t: noop,
    onBack: noop,
    onNext: noop,
    onSubmit: noop,
    isLoading: false,
    errors: {},
    // for tracking purposes:
    serviceIds: { serviceId: '', subServiceId: '' },
  };

  static propTypes = {
    location: shape({}).isRequired,
    price: shape({}),
    t: func,
    onBack: func,
    onNext: func,
    onSubmit: func,
    isLoading: bool,
    errors: shape({
      routing: bool,
      pricing: bool,
      checkout: bool,
    }),
    // for tracking purposes:
    serviceIds: shape({ serviceId: string, subServiceId: string }),
  };

  state = {
    shownBreakDown: false,
  };

  togglePriceBreakdown = () => {
    ReactTooltip.hide();
    this.setState(
      prevState => ({ shownBreakDown: !prevState.shownBreakDown }),
      () => {
        // tracking
        if (this.state.shownBreakDown) {
          const { pathname } = this.props.location;
          const { price, serviceIds } = this.props;
          Segment.createTrack(SEGMENT_CLICK_PRICE_BREAKDOWN, {
            source: pathname === matchPaths[1] ? 'checkout' : 'place_order',
            total_price_shown: price.total,
            vehicle_type: serviceIds.serviceId,
            vehicle_subtype: serviceIds.subServiceId,
          });
        }
      }
    );
  };

  handleNextClick = e => {
    this.props.onNext(e.target.name);
  };

  renderBreakdownDetails = () => {
    const { price, t } = this.props;
    return Object.keys(price).map(key =>
      key !== 'symbol' && key !== 'total' && price[key] ? (
        <PriceRow key={`row.${key}`}>
          <PriceLabel key={`label.${key}`}>{t(`PlaceOrder.${key}`)}</PriceLabel>
          <PriceValue key={`value.${key}`}>
            {formatter.currency(price[key])}
          </PriceValue>
        </PriceRow>
      ) : (
        ''
      )
    );
  };

  render() {
    const { price, t, errors, onBack, onSubmit, isLoading } = this.props;
    const { shownBreakDown } = this.state;
    const infoIcon = (
      <ClickArea
        onClick={this.togglePriceBreakdown}
        data-tip={t('PlaceOrder.tip_show_breakdown')}
        data-place="top"
        data-effect="solid"
        data-for="global"
      >
        <InfoIcon />
      </ClickArea>
    );

    const priceBreakDown = (
      <div>
        <Heading htmlTag="h3">{t('PlaceOrder.price_breakdown')}</Heading>
        {this.renderBreakdownDetails()}
      </div>
    );

    return (
      <Container>
        {/* show price if error is on checkout form */}
        {_every(_pick(errors, ['routing', 'pricing']), error => !error) && (
          <Price>
            <Amount>{formatter.currency(price.total)}</Amount>
            <Popover
              isOpen={shownBreakDown}
              preferPlace="above"
              target={infoIcon}
              onOuterAction={this.togglePriceBreakdown}
              body={priceBreakDown}
            >
              {infoIcon}
            </Popover>
          </Price>
        )}
        <Route
          exact
          path={matchPaths[0]}
          render={() => (
            <ButtonGroup>
              <ActionButton
                disabled={errors.routing || errors.pricing}
                name="scheduled"
                variant="secondary"
                size="large"
                block
                onClick={this.handleNextClick}
                solid
              >
                {t('PlaceOrder.advance_order')}
              </ActionButton>
              <ActionButton
                disabled={errors.routing || errors.pricing}
                name="immediate"
                variant="primary"
                size="large"
                block
                onClick={this.handleNextClick}
                solid
              >
                {t('PlaceOrder.deliver_now')}
              </ActionButton>
            </ButtonGroup>
          )}
        />
        <Route
          exact
          path={matchPaths[1]}
          render={() => (
            <ButtonGroup>
              <ActionButton
                onClick={onBack}
                variant="primary"
                size="large"
                block
                disabled={isLoading}
              >
                {t('Checkout.button_back')}
              </ActionButton>
              <ActionButton
                disabled={_some(errors) || isLoading}
                isLoading={isLoading}
                onClick={onSubmit}
                variant="primary"
                size="large"
                block
                solid
              >
                {t('Checkout.button_place_order')}
              </ActionButton>
            </ButtonGroup>
          )}
        />
      </Container>
    );
  }
}

export default withTranslation()(withRouter(Footer));
