export const GENESYS_WIDGET_OPEN = 'GENESYS_WIDGET_OPEN';
export const GENESYS_WIDGET_CLOSE = 'GENESYS_WIDGET_CLOSE';
export const GENESYS_ADVANCE_CONFIG = 'GENESYS_ADVANCE_CONFIG';
export const GENESYS_LOGOUT = 'GENESYS_LOGOUT';
export const GENESYS_MOVE_POSITION = 'GENESYS_MOVE_POSITION';

// export function createAction<P> = (type: string) => (payload: P = {}) => Action<string, P>;
const createAction = type => (payload = {}) => ({
  type,
  payload,
});

// Action
export const actions = {
  openWidget: createAction(GENESYS_WIDGET_OPEN),
  closeWidget: createAction(GENESYS_WIDGET_CLOSE),
  genesysAdvanceConfig: createAction(GENESYS_ADVANCE_CONFIG),
  logoutGenesys: createAction(GENESYS_LOGOUT),
  movePosition: createAction(GENESYS_MOVE_POSITION),
};
