export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_FAILURE = 'PASSWORD_UPDATE_FAILURE';
export const PREFERENCES_UPDATE_REQUEST = 'PREFERENCES_UPDATE_REQUEST';
export const PREFERENCES_UPDATE_SUCCESS = 'PREFERENCES_UPDATE_SUCCESS';
export const PREFERENCES_UPDATE_FAILURE = 'PREFERENCES_UPDATE_FAILURE';
export const SET_MARKETING_OPT_IN_REQUEST = 'SET_MARKETING_OPT_IN_REQUEST';
export const SET_MARKETING_OPT_IN_SUCCESS = 'SET_MARKETING_OPT_IN_SUCCESS';
export const SET_MARKETING_OPT_IN_FAILURE = 'SET_MARKETING_OPT_IN_FAILURE';

export const SET_CHANGE_EMAIL_STEP = 'SET_CHANGE_EMAIL_STEP';
export const CHANGE_EMAIL_SMS_REQUEST = 'CHANGE_EMAIL_SMS_REQUEST';
export const CHANGE_EMAIL_SMS_FAILURE = 'CHANGE_EMAIL_SMS_FAILURE';
export const CHANGE_EMAIL_SMS_SUCCESS = 'CHANGE_EMAIL_SMS_SUCCESS';
export const RESET_CHANGE_EMAIL_STEPS = 'RESET_CHANGE_EMAIL_STEPS';
export const CHANGE_EMAIL_VERIFY_PIN_REQUEST =
  'CHANGE_EMAIL_VERIFY_PIN_REQUEST';
export const CHANGE_EMAIL_VERIFY_PIN_SUCCESS =
  'CHANGE_EMAIL_VERIFY_PIN_SUCCESS';
export const CHANGE_EMAIL_VERIFY_PIN_FAILURE =
  'CHANGE_EMAIL_VERIFY_PIN_FAILURE';

export const updatePreferences = ({
  email,
  eReceipt,
  pod,
  statement,
  topupReminder,
}) => ({
  type: PREFERENCES_UPDATE_REQUEST,
  email,
  eReceipt,
  pod,
  statement,
  topupReminder,
});

export const updatePassword = ({ newPassword, oldPassword }) => ({
  type: PASSWORD_UPDATE_REQUEST,
  newPassword,
  oldPassword,
});

export const setMarketingOptIn = optIn => ({
  type: SET_MARKETING_OPT_IN_REQUEST,
  optIn,
});
export const setChangeEmailStep = ({ step }) => ({
  type: SET_CHANGE_EMAIL_STEP,
  step,
});
export const changeEmailSendSMSRequest = ({ email }) => ({
  type: CHANGE_EMAIL_SMS_REQUEST,
  email,
});
export const changeEmailVerify = ({ code }) => ({
  type: CHANGE_EMAIL_VERIFY_PIN_REQUEST,
  code,
});
