import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Alert from 'containers/Alert'; // eslint-disable-line import/no-named-as-default
import Dialog from 'containers/Dialog';
import Register from 'containers/Register'; // eslint-disable-line import/no-named-as-default
import Verification from 'containers/Register/Verification'; // eslint-disable-line import/no-named-as-default
import AccountVerification from 'containers/Register/AccountVerification'; // eslint-disable-line import/no-named-as-default
import Login from 'containers/Login'; // eslint-disable-line import/no-named-as-default
import Panel from 'containers/Panel';
import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/ResetPassword';
import VersionDisplay from 'components/VersionDisplay'; // eslint-disable-line import/no-named-as-default
import Modal from 'interfaces/global/containers/Modal/index.tsx';

import Home from 'views/Home';
import Orders from 'views/Records';
import EditOrder from 'views/EditOrder';
import Wallet from 'views/Wallet';
import Drivers from 'views/Drivers';
import Settings from 'views/Settings';
import ConnectedNotFound from 'views/NotFound';
import Layout from './Layout';
import PrivateRoutes from './PrivateRoutes';

const { REACT_APP_DEBUG } = process.env;

const App = () => (
  <div
    className="App"
    style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
  >
    <Layout>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:emailCode?" component={ResetPassword} />
        <Route path="/register" component={Register} />
        <Route path="/verification" component={Verification} />
        <Route path="/account" component={AccountVerification} />
        <PrivateRoutes>
          <Switch>
            <Route exact path="/(confirmation)?" component={Home} />
            <Route path="/orders/:orderId/edit" component={EditOrder} />
            <Route path="/orders" component={Orders} />
            <Route path="/wallet/:type?" component={Wallet} />
            <Route path="/drivers/:type?" component={Drivers} />
            <Route path="/settings" component={Settings} />
            <Route component={ConnectedNotFound} />
          </Switch>
        </PrivateRoutes>
      </Switch>
    </Layout>
    <ReactTooltip id="global" />
    <Alert
      variant="toast"
      messageSelector={[
        'CANCEL_ORDER',
        'RATE_ORDER',
        'UPDATE_PRIORITY_FEE',
        'FAVORITE_DRIVER',
        'BAN_DRIVER',
        'FETCH_STATEMENT',
      ]}
      persist={false}
    />
    <Dialog />
    <Panel />
    <Modal />
    {REACT_APP_DEBUG && <VersionDisplay />}
  </div>
);

export default App;
