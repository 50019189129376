/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { oneOf, func, shape, arrayOf, number, bool } from 'prop-types';
import { pricing as PricingIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import {
  getBestOnlinePaymentOnlyCoupon,
  getAvailableCoupons,
} from 'interfaces/global/store/modules/pricing/selectors';
import { getSelectedPaymentMethodId } from 'interfaces/global/store/modules/checkout/selectors';
import { updatePaymentMethod as changePaymentMethod } from 'interfaces/global/store/modules/checkout/actions';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import {
  PaymentMethods,
  paymentMethodEventMap,
  ALL_PAYMENT_METHOD_IDS,
} from 'interfaces/global/store/modules/checkout/types';
import { withResponsiveMedia } from 'components/MediaQuery';
import { noop } from 'utils/helpers';
import {
  Box,
  Con,
  Form,
  FormGroup,
  FormItem,
  RadioLabel,
  RadioDesc,
  CheckoutHeading,
  OnlinePaymentIcon,
  OnlinePaymentActiveIcon,
  CashIcon,
  CashActiveIcon,
  MobilePaymentItem,
  StyledRadioGroup,
} from '../style';
import OrderCouponTooltip from './OrderCouponTooltip/index.tsx';
import { ICON_SIZE, ICON_COLOR } from '../config';

const paymentMethodOptions = {
  ONLINE: {
    icon: OnlinePaymentIcon,
    iconActive: OnlinePaymentActiveIcon,
    label: 'Checkout.radio_online',
    desc: 'Checkout.radio_online_desc',
    name: PaymentMethods.ONLINE.name,
  },
  CASH: {
    icon: CashIcon,
    iconActive: CashActiveIcon,
    label: 'Checkout.radio_cash',
    desc: 'Checkout.radio_cash_desc',
    name: PaymentMethods.CASH.name,
  },
};

export class Payment extends Component {
  tooltipTargetHTMLElementRef = null; // ref for the HTML element the coupon online payment discount tooltip should appear under

  changePaymentMethodToCash = false;

  static defaultProps = {
    availableCoupons: [],
    bestOnlinePaymentOnlyCoupon: null,
    paymentMethodIds: [],
    updatePaymentMethod: noop,
  };

  static propTypes = {
    t: func.isRequired,
    availableCoupons: arrayOf(shape({})),
    bestOnlinePaymentOnlyCoupon: shape({}),
    paymentMethodIds: arrayOf(number),
    selectedPaymentMethodId: oneOf(ALL_PAYMENT_METHOD_IDS).isRequired,
    updatePaymentMethod: func,
    isMobile: bool.isRequired,
    track: func.isRequired,
  };

  componentDidMount() {
    const {
      paymentMethodIds,
      selectedPaymentMethodId,
      updatePaymentMethod,
    } = this.props;
    const isPaymentMethodAvailable = paymentMethodIds.some(
      pm => pm === selectedPaymentMethodId
    );

    if (!isPaymentMethodAvailable) {
      const fallbackPaymentMethodId = paymentMethodIds[0];
      updatePaymentMethod(fallbackPaymentMethodId);
    }
    this.onEffect({});
  }

  componentDidUpdate() {
    this.onEffect();
  }

  onEffect = () => {
    if (this.props.isMobile) {
      if (
        !this.changePaymentMethodToCash &&
        this.props.selectedPaymentMethodId !== PaymentMethods.CASH.id
      ) {
        this.onRadioButtonChanged(PaymentMethods.CASH.name);
        this.changePaymentMethodToCash = true;
      }
    } else {
      this.changePaymentMethodToCash = false;
    }
  };

  onRadioButtonChanged = preselectedPaymentMethodName => {
    const {
      selectedPaymentMethodId,
      paymentMethodIds,
      updatePaymentMethod,
    } = this.props;

    const paymentMethod = Object.keys(PaymentMethods).find(
      methodKey =>
        PaymentMethods[methodKey].name === preselectedPaymentMethodName
    );

    const preselectedPaymentMethodId = paymentMethod
      ? PaymentMethods[paymentMethod].id
      : PaymentMethods.ONLINE.id;

    const newPaymentMethodId = paymentMethodIds.some(
      method => method === preselectedPaymentMethodId
    )
      ? preselectedPaymentMethodId
      : PaymentMethods.ONLINE.id;

    updatePaymentMethod(newPaymentMethodId);

    if (
      selectedPaymentMethodId !== newPaymentMethodId &&
      paymentMethodEventMap[selectedPaymentMethodId] &&
      paymentMethodEventMap[newPaymentMethodId]
    ) {
      this.props.track('payment_method_updated', {
        original_payment_method: paymentMethodEventMap[selectedPaymentMethodId],
        new_payment_method: paymentMethodEventMap[newPaymentMethodId],
      });
    }
  };

  render() {
    const {
      t,
      selectedPaymentMethodId,
      paymentMethodIds,
      bestOnlinePaymentOnlyCoupon,
      availableCoupons,
      isMobile,
    } = this.props;

    const noAvailableCouponForCash =
      selectedPaymentMethodId === PaymentMethods.CASH.id &&
      availableCoupons.length === 0;

    const radioGroupValue =
      selectedPaymentMethodId === PaymentMethods.CASH.id
        ? PaymentMethods.CASH.name
        : PaymentMethods.ONLINE.name;

    return (
      <>
        <Form>
          <CheckoutHeading text={t('Checkout.heading_payment')}>
            <PricingIcon color={ICON_COLOR} size={ICON_SIZE} />
          </CheckoutHeading>
          {isMobile ? (
            <MobilePaymentItem>
              <Con>
                <paymentMethodOptions.CASH.icon />
              </Con>
              {t(paymentMethodOptions.CASH.desc)}
            </MobilePaymentItem>
          ) : (
            <StyledRadioGroup
              name="payment"
              value={radioGroupValue}
              onChange={this.onRadioButtonChanged}
              style={{
                marginLeft: 0,
                marginRight: 0,
                border: '1px solid #cfcfcf',
              }}
            >
              {RadioButton => (
                <FormGroup>
                  {paymentMethodIds.map(paymentMethodId => {
                    const {
                      icon: IconComp,
                      iconActive: IconActiveComp,
                      label,
                      name,
                      desc,
                    } =
                      paymentMethodId === PaymentMethods.CASH.id
                        ? paymentMethodOptions.CASH
                        : paymentMethodOptions.ONLINE;

                    return (
                      <FormItem
                        key={name}
                        style={{
                          flexDirection: 'column',
                          marginBottom: '0.5em',
                        }}
                      >
                        <Box>
                          <RadioButton
                            value={name}
                            data-cy={`payment-radio-${name}`}
                          >
                            <Con>
                              {selectedPaymentMethodId === paymentMethodId ? (
                                <IconActiveComp />
                              ) : (
                                <IconComp />
                              )}
                            </Con>
                            <RadioLabel
                              checked={
                                selectedPaymentMethodId === paymentMethodId
                              }
                            >
                              {t(label)}
                            </RadioLabel>
                          </RadioButton>
                        </Box>
                        <RadioDesc
                          {...(paymentMethodId === PaymentMethods.ONLINE.id && {
                            'data-for': 'checkout-tool-tip',
                            'data-tip': 'tooltip',
                            ref: ref => {
                              this.tooltipTargetHTMLElementRef = ref;
                            },
                          })}
                          checked={selectedPaymentMethodId === paymentMethodId}
                        >
                          {t(desc)}
                        </RadioDesc>
                      </FormItem>
                    );
                  })}
                </FormGroup>
              )}
            </StyledRadioGroup>
          )}
        </Form>
        <OrderCouponTooltip
          tooltipTargetHTMLElement={this.tooltipTargetHTMLElementRef}
          bestOnlinePaymentOnlyCoupon={bestOnlinePaymentOnlyCoupon}
          noAvailableCouponForCash={noAvailableCouponForCash}
        />
      </>
    );
  }
}

const mapState = state => ({
  availableCoupons: getAvailableCoupons(state),
  bestOnlinePaymentOnlyCoupon: getBestOnlinePaymentOnlyCoupon(state),
  paymentMethodIds: state.pricing.paymentMethodIds,
  selectedPaymentMethodId: getSelectedPaymentMethodId(state),
});

export default compose(
  withTranslation(),
  withResponsiveMedia,
  connect(mapState, { updatePaymentMethod: changePaymentMethod, track })
)(Payment);
