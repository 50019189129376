// Action types
export const DATE_INIT = 'DATE_INIT';
export const DATE_CHANGE = 'DATE_CHANGE';
export const CONTACT_UPDATE = 'CONTACT_UPDATE';
export const NOTE_UPDATE = 'NOTE_UPDATE';
export const FAVOURITE_DRIVER_TOGGLE = 'FAVOURITE_DRIVER_TOGGLE';
export const PAYMENT_METHOD_CHANGE = 'PAYMENT_METHOD_CHANGE';
export const SET_CHECKOUT_STEP = 'SET_CHECKOUT_STEP';
export const PROCEED_ORDER = 'PROCEED_ORDER';
export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const ONLINE_PAYMENT_INIT = 'ONLINE_PAYMENT_INIT';
export const ONLINE_PAYMENT_CLOSE = 'ONLINE_PAYMENT_CLOSE';
export const ONLINE_PAYMENT_FAIL = 'ONLINE_PAYMENT_FAIL';
export const FETCH_ONLINE_PAYMENT_STATUS_REQUEST =
  'FETCH_ONLINE_PAYMENT_STATUS_REQUEST';
export const FETCH_ONLINE_PAYMENT_STATUS_SUCCESS =
  'FETCH_ONLINE_PAYMENT_STATUS_SUCCESS';
export const FETCH_ONLINE_PAYMENT_STATUS_FAILURE =
  'FETCH_ONLINE_PAYMENT_STATUS_FAILURE';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const CHECKOUT_ERROR_TOGGLE = 'CHECKOUT_ERROR_TOGGLE';
export const CHECKOUT_RESET = 'CHECKOUT_RESET';
export const ONLINE_PAYMENT_MAX_RETRY_COUNT = 'ONLINE_PAYMENT_MAX_RETRY_COUNT';
export const SET_FIRST_TIME_PLACING_ORDER = 'SET_FIRST_TIME_PLACING_ORDER';
export const FETCH_DAYLIGHT_REQUEST = 'FETCH_DAYLIGHT_REQUEST';
export const FETCH_DAYLIGHT_SUCCESS = 'FETCH_DAYLIGHT_SUCCESS';
export const DAYLIGHT_DIALOG_CLOSE = 'DAYLIGHT_DIALOG_CLOSE';
export const FETCH_UNPAID_ORDER_REQUEST = 'FETCH_UNPAID_ORDER_REQUEST';

export const FETCH_UNPAID_ORDER_SUCCESS = 'FETCH_UNPAID_ORDER_SUCCESS';

// Action creators
export const submitOrder = () => ({
  type: PLACE_ORDER_REQUEST,
});

export const submitOrderSuccess = () => ({
  type: PLACE_ORDER_SUCCESS,
});

export const submitOrderFailure = () => ({
  type: PLACE_ORDER_FAILURE,
});

export const setCheckoutError = (name, message) => ({
  type: CHECKOUT_ERROR_TOGGLE,
  meta: { name, message },
});

export const initDate = defaultDate => ({
  type: DATE_INIT,
  defaultDate,
});

export const changeDate = (date, isDirty = true) => ({
  type: DATE_CHANGE,
  date,
  isDirty,
});

export const updateContact = (contact, isDirty = true) => ({
  type: CONTACT_UPDATE,
  contact,
  isDirty,
});

export const toggleFavDriver = toggle => ({
  type: FAVOURITE_DRIVER_TOGGLE,
  toggle,
});

export const updatePaymentMethod = paymentMethodId => ({
  type: PAYMENT_METHOD_CHANGE,
  paymentMethodId,
});

export const updateNote = text => ({
  type: NOTE_UPDATE,
  text,
});

export const fetchOnlinePaymentStatus = () => ({
  type: FETCH_ONLINE_PAYMENT_STATUS_REQUEST,
});

export const initOnlinePayment = ({ hpayCashierUrl, orderUuid }) => ({
  type: ONLINE_PAYMENT_INIT,
  hpayCashierUrl,
  orderUuid,
});

export const closeOnlinePayment = () => ({
  type: ONLINE_PAYMENT_CLOSE,
});

export const setOnlinePaymentMaxRetryCount = count => ({
  type: ONLINE_PAYMENT_MAX_RETRY_COUNT,
  count,
});
export const getUnpaidOrder = () => ({
  type: FETCH_UNPAID_ORDER_REQUEST,
});
export const getDaylightSavingsTime = ({ time }) => ({
  type: FETCH_DAYLIGHT_REQUEST,
  time,
});
export const closeDaylightSavingsTimeDialog = () => ({
  type: DAYLIGHT_DIALOG_CLOSE,
});

export const setCheckoutStep = step => ({
  type: SET_CHECKOUT_STEP,
  step,
});

export const proceedOrder = orderType => ({
  type: PROCEED_ORDER,
  orderType,
});

export const setFirstTimePlacingOrder = firstTimeFlag => ({
  type: SET_FIRST_TIME_PLACING_ORDER,
  firstTimeFlag,
});
