import { getCityRegexRules } from 'store/modules/region/selectors';

export const defaultRule = {
  // EMAIL: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()\-[\].,;:\s@"]+([^<>()[\].,;:\s@"][^<>()\-[\].,;:\s@"]+)*\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  EMAIL: /^[\w-+]+(\.[\w-+]+)*@[\w-]+(\.[\w-]+)+$/i, // from auth-service
  PHONE: /^((?!999)([23456789]{1}[0-9]{7}))$/, // default to HK
  TAX_ID: /^[0-9]{8}$/, // TW only
};

class Validator {
  bindStore(store) {
    this.store = store;
  }

  getRules(key) {
    if (this.store) {
      const cityRegex = getCityRegexRules(this.store.getState());
      return new RegExp((cityRegex || {})[key] || defaultRule[key]);
    }
    return defaultRule[key];
  }

  email(value) {
    const trimmed = value.trim();
    const valid = this.getRules('EMAIL').test(trimmed);
    return {
      valid,
      ...(!valid && { message: 'Settings.form_error_email_invalid' }),
    };
  }

  phone(value = '', withPrefix = false) {
    const trimmed = value.trim();
    const valid = this.getRules('PHONE').test(trimmed);
    return {
      valid,
      ...(!valid && {
        message: withPrefix
          ? 'ERROR_INVALID_PHONE_NUMBER_WITH_PREFIX'
          : 'ERROR_INVALID_PHONE_NUMBER',
      }),
    };
  }

  taxID(value) {
    const valid = this.getRules('TAX_ID').test(value);
    return {
      valid,
      ...(!valid && { message: 'TW_Invoice.error_msg_invalid_uniform_number' }),
    };
  }
}

export default new Validator();
