import {
  GENESYS_ADVANCE_CONFIG,
  GENESYS_LOGOUT,
  GENESYS_WIDGET_CLOSE,
  GENESYS_WIDGET_OPEN,
  GENESYS_MOVE_POSITION,
} from './actions';

export const initState = {
  isOpen: false,
  isScriptLoaded: false,
  config: {},
  offset: { x: 16, y: 8 },
};

const handleOpen = state => ({
  ...state,
  isScriptLoaded: true,
  isOpen: true,
});

const handleClose = state => ({
  ...state,
  isOpen: false,
});

const advanceConfig = (state, config) => ({
  ...state,
  config,
});

const handleLogout = state => ({
  ...state,
  isOpen: false,
});

const handleMovePosition = (state, { right, bottom }) => ({
  ...state,
  offset: { x: right, y: bottom },
});

export default function reducer(state = initState, action) {
  switch (action.type) {
    case GENESYS_WIDGET_OPEN:
      return handleOpen(state);
    case GENESYS_WIDGET_CLOSE:
      return handleClose(state);
    case GENESYS_ADVANCE_CONFIG:
      return advanceConfig(state, action.payload);
    case GENESYS_LOGOUT:
      return handleLogout(state);
    case GENESYS_MOVE_POSITION:
      return handleMovePosition(state, action.payload);
    default:
      return state;
  }
}
