import * as actions from './actions';
import { regionSaga as saga } from './saga';
import * as selectors from './selectors';
import region from './reducer';

const regionModule = {
  id: 'region',
  reducerMap: { region },
  sagas: [saga],
  initialActions: [actions.initRegion()],
};

export { region as default, saga, actions, selectors, regionModule };
