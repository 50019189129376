/* eslint-disable camelcase */

export enum CouponPayTypes {
  ONLINE_ONLY = 1,
  UNLIMITED = 3,
}

export enum CouponStatuses {
  AVAILABLE = 0,
}

export type Coupon = {
  start_ts: number;
  start_ts_hts: number;
  time_type: number;

  unavailable_status: number;
  // unavailable_status values:
  // 0 -> normal coupon that can be used
  // 2 -> Pay Type Incorrect
  // 3 -> Same Num Required and it is set to 0 (sameNum is sent from client, true means contact number match with login phone number)
  // 4 -> Not within usage time period (e.g. coupon can set to be only usable during 10:00~18:00)
  // 6 -> before valid datetime (If after valid datetime)
  // 7 -> discount is greater than total price
  // 8 -> vehicle type restriction not match
  // 9 -> Total price does not reach required amount
  // 10 -> client type (web, app) not match

  discount_amount: number;
  city_areas: any[];
  region_type: number;
  time_periods: any[];
  discount_rate: number;
  preferential: string;
  valid_date: string;
  coupon_id: string;
  coupon_code: string;
  packet_id: number;
  business_type: number;
  user_citys: number[];
  order_vehicle_name: string;
  pay_type: number; // 1 === online only, 3 === unlimited (1:所有线上支付,2:仅限个人余额支付或组合支付,3:不限)
  city_names: string[];
  reach_fen: number;
  read_type: number;
  discount_type: number; // 1-立减券;2-折扣券
  end_ts_hts: number;
  end_ts: number;
  name: string;
  max_discount_amount?: number;
  discount_amount_fen: number;
  status: number;
};
