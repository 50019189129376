import React from 'react';
import { func, node, string } from 'prop-types';
import styled from 'styled-components';
import { Button, Heading } from '@lalamove/karang';
import { silver } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { noop } from 'utils/helpers';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  margin-bottom: 2em;
  color: ${silver};
  font-size: ${fontSize.regular};
`;

const EmptyState = ({ image, title, text, action, actionText }) => (
  <Container>
    {image}
    {title && <Heading size="large">{title}</Heading>}
    <Text>{text}</Text>
    {action !== noop && (
      <Button variant="primary" onClick={action} solid>
        {actionText}
      </Button>
    )}
  </Container>
);

EmptyState.defaultProps = {
  image: null,
  title: '',
  text: '',
  action: noop,
  actionText: '',
};

EmptyState.propTypes = {
  image: node,
  title: string,
  text: node,
  action: func,
  actionText: string,
};

export default EmptyState;
