import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func, string, array, number } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { cloneOrder } from 'store/modules/records';
import SliderPanelFooter from 'components/SliderPanelFooter';

import { noop, statusMap } from 'utils/helpers';
import DriverMap from './components/DriverMap';

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
`;

class Tracking extends Component {
  static defaultProps = {
    status: null,
    t: noop,
    cloneOrder: noop,
    onBack: noop,
    onClose: noop,
    waypoints: [],
  };

  static propTypes = {
    status: number,
    id: string.isRequired,
    shareId: string.isRequired,
    serviceTypeId: string.isRequired,
    vehicleTypeId: string.isRequired,
    t: func,
    cloneOrder: func,
    onBack: func,
    onClose: func,
    waypoints: array, // eslint-disable-line react/forbid-prop-types
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.status !== this.props.status &&
      ![statusMap.ONGOING, statusMap.PICKED_UP].includes(this.props.status)
    ) {
      this.props.onClose();
    }
  }

  onCloneClick = () => {
    this.props.cloneOrder(this.props.id);
  };

  render() {
    const {
      id,
      shareId,
      serviceTypeId,
      vehicleTypeId,
      t,
      onBack,
      onClose,
      waypoints,
    } = this.props;
    if (!id) return null;
    return (
      <Wrapper>
        <DriverMap
          orderId={id}
          shareId={shareId}
          waypoints={waypoints.map(w => ({ ...w, id: uuid() }))}
          serviceTypeId={
            vehicleTypeId === 'VIP' ? vehicleTypeId : serviceTypeId
          }
        />
        <SliderPanelFooter
          primaryAction={this.onCloneClick}
          primaryButtonText={t('Records.option_clone_order')}
          secondaryAction={onBack}
          secondaryButtonText={t('RecordPanel.button_back')}
          closeAction={onClose}
          closeButtonText={t('RecordPanel.button_close_panel')}
        />
      </Wrapper>
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, { cloneOrder })
)(Tracking);
