/* eslint-disable no-unused-vars */
import { channel } from 'redux-saga';
import { take, takeLatest, call, put } from 'redux-saga/effects';
import fetchIntercept from 'fetch-intercept';
import { GET_USER_SESSION_SUCCESS } from 'redux-react-session/dist/actionTypes';

import log from 'api/log';

import { invalidSessionErrors } from 'store/modules/auth/helpers';
import { REQUEST_LOGOUT, sessionFailure } from 'store/modules/auth/actions';
import { openDialog } from 'store/modules/ui';

const API_MIDDLEWARE_INIT = 'API_MIDDLEWARE_INIT';
const API_MIDDLEWARE_DISPOSE = 'API_MIDDLEWARE_DISPOSE';

const apiMiddleware = store => next => async action => {
  // do this before next to avoid private route
  // being rendered before api is set
  if (action.type === GET_USER_SESSION_SUCCESS) {
    const { user } = action;
    log.changeClientId(user.client_id.toString());
  }

  const result = next(action);

  if (action.type === REQUEST_LOGOUT) {
    log.changeClientId(null);
  }

  return result;
};

const sessionChannel = channel();

export function* watchSessionChannel() {
  while (true) {
    const action = yield take(sessionChannel);
    yield put(action);
  }
}

function register() {
  fetchIntercept.register({
    response: response => {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType === 'application/json') {
        const clone = response.clone();
        clone.json().then(data => {
          const { success, code } = data;
          // mobile-api will return error under status code 200
          if (!success && code && invalidSessionErrors.includes(code)) {
            sessionChannel.put(sessionFailure(code));
            sessionChannel.put(openDialog('SESSION_ERROR'));
          }
        });
      }
      return response;
    },
  });
}

function clear() {
  fetchIntercept.clear();
}

function* onAPIMiddlewareInit() {
  yield call(register);
}

function* onAPIMiddlewareDispose() {
  yield call(clear);
}

export function* apiSaga() {
  yield takeLatest(API_MIDDLEWARE_INIT, onAPIMiddlewareInit);
  yield takeLatest(API_MIDDLEWARE_DISPOSE, onAPIMiddlewareDispose);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 'api',
    middlewares: [apiMiddleware],
    sagas: [apiSaga, watchSessionChannel],
    initialActions: [{ type: API_MIDDLEWARE_INIT }],
    finalActions: [{ type: API_MIDDLEWARE_DISPOSE }],
  },
];
