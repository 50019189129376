import { REMOVE_DRIVER_SUCCESS } from 'store/modules/drivers';
import Segment from 'utils/segment';

export const SEGMENT_CLICK_DRIVERS = 'Manage Drivers Tapped';
export const SEGMENT_CLICK_FAVORITE_DRIVERS = 'Favorited Tab Tapped';
export const SEGMENT_CLICK_BANNED_DRIVERS = 'Banned Tab Tapped';
export const SEGMENT_REMOVE_FAVORITE_DRIVER = 'Favorited Driver Removed';
export const SEGMENT_REMOVE_BANNED_DRIVER = 'Banned Driver Removed';

const driversTracking = (prevState, action, nextState) => {
  switch (action.type) {
    case REMOVE_DRIVER_SUCCESS: {
      Segment.createTrack(
        action.favorite
          ? SEGMENT_REMOVE_FAVORITE_DRIVER
          : SEGMENT_REMOVE_BANNED_DRIVER
      );
      break;
    }
    default:
      break;
  }
};

export default driversTracking;
