/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Big from 'big.js';
import { number, func, arrayOf, shape, instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { setSelectedCoupon } from 'interfaces/global/store/modules/pricing/actions';
import { noop } from 'utils/helpers';
import styled from 'styled-components';
import { mineShaft } from 'styles/colors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getAvailableCoupons,
  getPrice,
  getSelectedCashCoupon,
  getSelectedOnlineCoupon,
} from 'interfaces/global/store/modules/pricing/selectors';
import { CouponPayTypes } from 'interfaces/global/store/modules/pricing/types';
import {
  PaymentMethods,
  paymentMethodEventMap,
} from 'interfaces/global/store/modules/checkout/types';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import SelectCouponModal from './SelectCouponModal/index.tsx';
import OrderCoupon from './OrderCoupon.tsx';

const CouponTitle = styled.p`
  margin: 20px 0 8px;
  color: ${mineShaft[700]};
  font-size: 11px;
`;

export class SelectCoupon extends Component {
  static defaultProps = {
    selectedCashCoupon: null,
    selectedOnlineCoupon: null,
    setSelectedCoupon: noop,
    orderCouponList: [],
    selectedPaymentMethodId: PaymentMethods.ONLINE.id,
    t: noop,
  };

  static propTypes = {
    selectedCashCoupon: shape({}),
    selectedOnlineCoupon: shape({}),
    setSelectedCoupon: func,
    orderCouponList: arrayOf(shape()),
    selectedPaymentMethodId: number,
    t: func,
    couponSavings: instanceOf(Big).isRequired,
    track: func.isRequired,
  };

  state = {
    showCouponModal: false,
  };

  getAllCouponsOfPaymentMethod = () => {
    const { orderCouponList, selectedPaymentMethodId } = this.props;

    if (selectedPaymentMethodId === PaymentMethods.ONLINE.id)
      return orderCouponList;

    return orderCouponList.filter(
      coupon => coupon.pay_type === CouponPayTypes.UNLIMITED
    );
  };

  openCouponModal = () => {
    this.setState({ showCouponModal: true });
    this.props.track('more_coupon_viewed');
  };

  closeCouponModal = () => {
    this.setState({ showCouponModal: false });
  };

  handleApplyCoupon = (coupon, index) => {
    const {
      selectedPaymentMethodId,
      selectedCashCoupon,
      selectedOnlineCoupon,
    } = this.props;

    const selectedCouponApplied = Boolean(
      selectedPaymentMethodId === PaymentMethods.CASH.id
        ? selectedCashCoupon
        : selectedOnlineCoupon
    );
    this.props.setSelectedCoupon({
      coupon,
      selectedPaymentMethodId,
    });
    if (coupon) {
      this.props.track('coupon_changed', {
        original_coupon_applied: selectedCouponApplied,
        new_coupon_name: coupon.name,
        new_coupon_position: index + 1, // position = index + 1
      });
    } else {
      this.props.track('coupon_removed', {
        payment_type: paymentMethodEventMap[selectedPaymentMethodId],
      });
    }
  };

  render() {
    const {
      t,
      selectedPaymentMethodId,
      selectedCashCoupon,
      selectedOnlineCoupon,
      couponSavings,
    } = this.props;
    const { showCouponModal } = this.state;

    const selectedCoupon =
      selectedPaymentMethodId === PaymentMethods.CASH.id
        ? selectedCashCoupon
        : selectedOnlineCoupon;

    const orderCouponList = this.getAllCouponsOfPaymentMethod();

    return (
      <>
        <SelectCouponModal
          isOpen={showCouponModal}
          onApply={this.handleApplyCoupon}
          onClose={this.closeCouponModal}
          coupons={orderCouponList}
          selectedCoupon={selectedCoupon}
        />
        <CouponTitle>{t('Coupon.title')}</CouponTitle>
        <OrderCoupon
          openCouponModal={this.openCouponModal}
          orderCouponList={orderCouponList}
          selectedCoupon={selectedCoupon}
          couponSavings={couponSavings}
        />
      </>
    );
  }
}
const mapState = state => ({
  couponSavings: getPrice(state).couponSavings,
  orderCouponList: getAvailableCoupons(state),
  selectedPaymentMethodId: state.checkout.selectedPaymentMethodId,
  selectedCashCoupon: getSelectedCashCoupon(state),
  selectedOnlineCoupon: getSelectedOnlineCoupon(state),
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapState, { setSelectedCoupon, track })
)(SelectCoupon);
