import React, { Component } from 'react';
import { bool, string, func, shape } from 'prop-types';
import { fromEvent, from, of } from 'rxjs';
import {
  tap,
  map,
  debounceTime,
  filter,
  distinctUntilChanged,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { getPlaceAutocomplete } from 'api/restAPI';
import { noop } from 'utils/helpers';

import { Input } from '../StopItem/style';

Input.displayName = 'Input';

// eslint-disable-next-line camelcase
function parseSuggestion({ description, place_id }) {
  return {
    description,
    placeId: place_id,
    lat: null,
    lng: null,
  };
}

const emptyList = [];

class StopItemInput extends Component {
  static defaultProps = {
    placeholder: '',
    autofocus: false,
    disabled: false,
    getToggleButtonProps: props => props,
    getInputProps: props => props,
    onOpenDropdown: noop,
    onChange: noop,
    onBlur: noop,
    selectItemAtIndex: noop,
    showDeliveryInfo: noop,
    onSuggestions: noop,
  };

  static propTypes = {
    item: shape({ description: string }).isRequired,
    placeholder: string,
    autofocus: bool,
    disabled: bool,
    getToggleButtonProps: func,
    getInputProps: func,
    onOpenDropdown: func,
    onChange: func,
    onBlur: func,
    selectItemAtIndex: func,
    showDeliveryInfo: func,
    onSuggestions: func,
  };

  componentDidMount() {
    const { autofocus } = this.props;
    if (autofocus) {
      // hack to handle autofocus when importing CSV or clone order
      this.input.value.length === 0 && this.input.focus();
    }

    const keyups$ = fromEvent(this.input, 'input').pipe(
      debounceTime(500),
      map(e => e.target.value),
      tap(
        val =>
          val.trim() === '' && this.props.onSuggestions({ list: emptyList })
      ), // clear the list
      distinctUntilChanged(),
      filter(value => !!value && value.length > 1),
      switchMap(text =>
        // use config to set country and locale
        from(getPlaceAutocomplete(text)).pipe(
          catchError(_ => of({ predictions: [], sessiontoken: '' }))
        )
      ),
      map(({ predictions, sessiontoken }) => ({
        list: predictions.map(parseSuggestion),
        token: sessiontoken,
      }))
    );

    this.subscription = keyups$.subscribe(this.props.onSuggestions);
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleInputFocus = () => {
    const { item, showDeliveryInfo } = this.props;
    item.description && showDeliveryInfo();
    // prevent user from losing the first typed-in character
    // when she is quickly adding stops
    this.input.value.length > 1 && this.input.select();
  };

  handleClick = e => {
    const { onOpenDropdown } = this.props;
    if (!e.target.value) onOpenDropdown();
  };

  handleGrow = e => {
    this.props.onChange(e);
    if (this.input instanceof Element) {
      this.input.style.height = 'auto';
      this.input.style.height = `${this.input.scrollHeight}px`;
    }
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  render() {
    const { getInputProps, placeholder, disabled, onBlur } = this.props;
    return (
      <Input
        {...getInputProps({
          ref: ref => {
            this.input = ref;
          },
          style: {
            height: this.input ? this.input.scrollHeight : 'auto',
          },
          onChange: this.handleGrow,
          onFocus: this.handleInputFocus,
          onBlur,
          onClick: this.handleClick,
          onKeyPress: this.handleKeyPress,
          placeholder,
          disabled,
        })}
      />
    );
  }
}

export default StopItemInput;
