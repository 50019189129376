import { Component } from 'react';
import { string } from 'prop-types';

export default class HtmlTitle extends Component {
  static propTypes = {
    children: string.isRequired,
  };

  componentDidMount() {
    const { children } = this.props;
    document.title = `Lalamove - ${children}`;
  }

  componentDidUpdate() {
    const { children } = this.props;
    document.title = `Lalamove - ${children}`;
  }

  componentWillUnmount() {
    document.title = 'Lalamove';
  }

  render() {
    return null;
  }
}
