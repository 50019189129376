import React from 'react';
import { bool, string, number } from 'prop-types';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import * as Emoji from '@lalamove/karang/dist/components/Icon/icons/emoji';

const colorMap = {
  love: '#00bc9c',
  like: '#80ae64',
  neutral: '#ffa744',
  dislike: '#f07a40',
  hate: '#e15453',
};

// eslint-disable-next-line react/prop-types
const Icon = ({ type, ...rest }) => {
  const I = Emoji[type] || null;
  return <I {...rest} />;
};
const StyledIcon = styled(Icon).attrs(({ size }) => ({
  size,
}))`
  display: inline-block;
`;

const Container = styled.div`
  span {
    display: block;
    padding: 0.2em 0;
  }
  ${/* sc-selector */ StyledIcon} {
    color: #cacaca;
  }
  &:hover,
  &:focus {
    color: ${({ type }) => darken(0.1, colorMap[type])};
    ${/* sc-selector */ StyledIcon} {
      color: ${({ type }) => darken(0.1, colorMap[type])};
    }
  }
  ${({ active, type }) =>
    active &&
    css`
      font-weight: bold;
      color: ${colorMap[type]};

      ${/* sc-selector */ StyledIcon} {
        color: ${colorMap[type]};
      }
    `};
`;

const Emotion = ({ type, size, active, description, ...remain }) => (
  <Container type={type} active={active}>
    <StyledIcon type={type} size={size} {...remain} />
    {description && <span>{description}</span>}
  </Container>
);

Emotion.defaultProps = {
  size: 48,
  active: false,
  description: null,
};

Emotion.propTypes = {
  type: string.isRequired,
  size: number,
  active: bool,
  description: string,
};

export default Emotion;
