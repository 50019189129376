// possible error codes for invalid session from mobile-api
// eslint-disable-next-line import/prefer-default-export
export const invalidSessionErrors = [
  'ACCESS_TOKEN_REFRESH_FAILURE',
  'AUTH_ACCESS_TOKEN_REJECTED',
  'ERROR_AMBIGUOUS_TOKEN',
  'ERROR_INVALID_ACCESS_TOKEN',
  'ERROR_SESSION_CROSS_PLATFORM',
  'ERROR_INVALID_SESSION',
  'INVALID_CLIENT',
];
