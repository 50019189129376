import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { arrayOf, bool, func, number, shape, string, object } from 'prop-types';
import { Checkbox, Pagination, Rating, SearchInput } from '@lalamove/karang';
import moment from 'moment';
import _pickBy from 'lodash/pickBy';
import _curry from 'lodash/curry';
import _isEqual from 'lodash/isEqual';
import { push, replace } from 'connected-react-router';
import styled from 'styled-components';

import {
  getOrdersWithInfo,
  getRecordCount,
  fetchRecords,
  cloneOrder,
  mockDeliveryOrders,
} from 'store/modules/records';
import {
  SEGMENT_CLICK_ORDERS,
  SEGMENT_UPDATE_ORDERS_STATUS_FILTER,
  SEGMENT_UPDATE_ORDERS_DATE_FILTER,
  SEGMENT_SUBMIT_ORDERS_SEARCH,
  SEGMENT_CLICK_ORDER_DROPDOWN,
} from 'store/modules/records/tracking';
import { getCurrentCity } from 'store/modules/region/selectors';
import { getServiceNames } from 'store/modules/services';
import { createLoadingSelector } from 'store/modules/loading';
import { invalidSessionErrors } from 'store/modules/auth/helpers';
import { hasMessage } from 'store/modules/message';
import { openPanel, closePanel, isPanelOpen, getPanel } from 'store/modules/ui';

import DateRangePicker from 'components/DateRangePicker';
import EmptyState from 'components/EmptyState';
import OrderIdDisplay from 'components/OrderIdDisplay';
import HtmlTitle from 'components/HtmlTitle';
import PageHeader from 'components/PageHeader';
import Tag from 'components/Tag';

import {
  noop,
  parseUrlParams,
  encodeQueryData,
  statusMap,
} from 'utils/helpers';
import formatter from 'utils/formatter';
import Segment from 'utils/segment';

import noRecordSVG from 'assets/svg/table_no_record.svg';
import noResultSVG from 'assets/svg/table_no_result.svg';
import RouteList from './components/RouteList';
import NotesToDriverCell from './components/NotesToDriverCell';

import { OrderShape } from './propTypes';
import {
  Layout,
  SearchForm,
  StatusDropdown,
  StyledDropdown,
  RecordsTable,
  SupportText,
  Truncate,
} from './style';

import {
  MAX_RANGE_LIMIT_DAYS,
  SELECTED_DATE_RANGE,
  MAX_ROWS,
  DATE_DATA_FORMAT,
} from './config';

const today = moment();
const DEFAULT_START_DATE = today.format(DATE_DATA_FORMAT);
const DEFAULT_END_DATE = today
  .add(SELECTED_DATE_RANGE, 'days')
  .format(DATE_DATA_FORMAT);

function renderTime(datetime, format = 'H:mm') {
  return moment(datetime).format(format);
}
function renderDate(datetime, format = 'DD MMM YYYY') {
  return moment(datetime).format(format);
}

const StatusFilter = styled.div`
  margin-right: 1rem;
`;

const DateFilter = styled.div`
  margin-right: 2rem;
`;

class Records extends Component {
  static defaultProps = {
    t: noop,
    orders: [],
    recordCount: 0,
    serviceNames: {},
    fetchRecords: noop,
    cloneOrder: noop,
    openPanel: noop,
    closePanel: noop,
    isLoading: false,
    hasSessionErrors: false,
    isDetailsOpen: false,
    panel: {
      id: '',
      props: {},
    },
  };

  static propTypes = {
    match: object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: object.isRequired, // eslint-disable-line react/forbid-prop-types
    t: func,
    orders: arrayOf(OrderShape),
    recordCount: number,
    serviceNames: shape({ [string]: string }),
    fetchRecords: func,
    cloneOrder: func,
    openPanel: func,
    closePanel: func,
    isLoading: bool,
    hasSessionErrors: bool,
    isDetailsOpen: bool,
    panel: shape({
      id: string,
      props: shape({
        orderId: string,
        currentType: string,
      }),
    }),
    orderEditEnabled: bool.isRequired,
    historyPush: func.isRequired,
    historyReplace: func.isRequired,
  };

  // driven state by URL params
  params = this.getStateFromUrlParams();

  useMockData = false;

  state = {
    search: this.params.search,
    status: this.params.status,
    start: this.params.start,
    end: this.params.end,
    current: this.params.current,
    max: this.params.max,
  };

  tagTypes = {
    [statusMap.ASSIGNING]: 'yellow',
    [statusMap.ONGOING]: 'blue', // matched
    [statusMap.COMPLETED]: 'green',
    [statusMap.CANCELLED]: 'red',
    [statusMap.REJECTED]: 'red',
    [statusMap.EXPIRED]: 'red',
    [statusMap.PICKED_UP]: 'darkBlue',
  };

  labelMap = {
    [statusMap.ASSIGNING]: 'Records.label_status_assigning',
    [statusMap.ONGOING]: 'Records.label_status_in_process',
    [statusMap.COMPLETED]: 'Records.label_status_completed',
    [statusMap.CANCELLED]: 'Records.label_status_cancelled',
    [statusMap.REJECTED]: 'Records.label_status_cancelled',
    [statusMap.EXPIRED]: 'Records.label_status_cancelled',
    [statusMap.PICKED_UP]: 'Records.label_status_picked_up',
  };

  segmentMap = {
    [statusMap.ASSIGNING]: 'assigning',
    [statusMap.ONGOING]: 'matched',
    [statusMap.PICKED_UP]: 'picked up',
    [statusMap.COMPLETED]: 'completed',
    [statusMap.CANCELLED]: 'cancelled',
    [statusMap.EXPIRED]: 'cancelled',
    [statusMap.REJECTED]: 'rejected',
  };

  paymentMap = {
    CASH: 'Records.label_payment_cash',
    CREDITS: 'Records.label_payment_wallet',
  };

  componentDidMount() {
    const { historyReplace, location } = this.props;
    const [, , orderId, currentType] = location.pathname.split('/');
    if (orderId) {
      this.props.openPanel('ORDER', { orderId, currentType });
    }

    if (!location.search) {
      historyReplace({ search: encodeQueryData(_pickBy(this.state)) });
      this.fetchRecords();
    }

    this.interval = setInterval(this.fetchRecords, 10 * 1000);

    // tracking
    Segment.createTrack(SEGMENT_CLICK_ORDERS);
  }

  componentDidUpdate(prevProps, prevState) {
    const { historyReplace, panel, isDetailsOpen } = this.props;
    const { orderId, currentType } = panel.props;

    if (!_isEqual(prevState, this.state)) {
      historyReplace({ search: encodeQueryData(_pickBy(this.state)) });
      this.fetchRecords();
    }

    if (!prevProps.isDetailsOpen && isDetailsOpen) {
      this.updateUrlPath(orderId, currentType);
    }

    if (prevProps.isDetailsOpen && !isDetailsOpen) {
      this.updateUrlPath();
    }

    if (isDetailsOpen && prevProps.panel.props.currentType !== currentType) {
      this.updateUrlPath(orderId, currentType);
    }
  }

  componentWillUnmount() {
    if (this.props.isDetailsOpen) {
      this.props.closePanel();
    }

    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  getStateFromUrlParams() {
    const { location } = this.props;
    const { search, status, start, end, current, max } = parseUrlParams(
      location.search
    );
    return {
      search: search || '',
      status: status ? status.toUpperCase() : '',
      start: moment(start, DATE_DATA_FORMAT, true).isValid()
        ? start
        : DEFAULT_START_DATE,
      end: moment(end, DATE_DATA_FORMAT, true).isValid()
        ? end
        : DEFAULT_END_DATE,
      current: current ? +current : 1,
      max: max && max <= MAX_ROWS ? +max : MAX_ROWS,
    };
  }

  fetchRecords = () => {
    if (this.props.hasSessionErrors) return;
    if (this.useMockData) return;

    const { search, status, start, end, current, max } = this.state;
    let statusFilters;
    if (status === '') {
      statusFilters = null;
    } else if (status === 'CANCELLED') {
      // CANCELLED, REJECTED, EXPIRED all grouped together as CANCELLED
      statusFilters = ['CANCELLED', 'REJECTED', 'EXPIRED'];
    } else {
      statusFilters = [status];
    }
    this.props.fetchRecords({
      search,
      status: statusFilters,
      start,
      end,
      current,
      max,
    });
  };

  handleSearch = e => {
    e.preventDefault();
    this.setState({
      search: e.target.elements[0].value.trim(),
      current: 1,
    });

    // tracking
    Segment.createTrack(SEGMENT_SUBMIT_ORDERS_SEARCH);
  };

  handleStatusFilter = ({ value }) => {
    this.setState({
      status: value,
      current: 1,
    });

    // tracking
    const key = statusMap[value];
    Segment.createTrack(SEGMENT_UPDATE_ORDERS_STATUS_FILTER, {
      order_filter: this.segmentMap[key] || 'all',
    });
  };

  handleDateChange = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      this.setState({
        start: startDate.toISOString(true).split('T')[0],
        end: endDate.toISOString(true).split('T')[0],
        current: 1,
      });

      // tracking
      Segment.createTrack(SEGMENT_UPDATE_ORDERS_DATE_FILTER);
    }
  };

  handlePagination = nextPage => {
    this.setState({
      current: nextPage,
    });
  };

  handleRowClick = (_, id) => {
    this.props.openPanel('ORDER', { orderId: id });
  };

  handleDropdownClick = (e, id, status) => {
    e.stopPropagation();
    Segment.createTrack(SEGMENT_CLICK_ORDER_DROPDOWN, {
      order_id: id,
      order_status: this.segmentMap[status],
    });
  };

  isCustomFilters = () => {
    const { search, status, start, end, current, max } = this.state;
    return (
      search !== '' ||
      status !== '' ||
      start !== DEFAULT_START_DATE ||
      end !== DEFAULT_END_DATE ||
      current !== 1 ||
      max !== MAX_ROWS
    );
  };

  createStatusOption = statusId => {
    const { t } = this.props;
    return {
      value: Object.keys(statusMap)[statusId],
      label: t(this.labelMap[statusId]),
    };
  };

  renderFilterControls = () => {
    const { t, recordCount: total, isLoading } = this.props;
    const { status, start, end, current, max } = this.state;

    const { ASSIGNING, ONGOING, PICKED_UP, COMPLETED, CANCELLED } = statusMap;
    const options = [
      { value: '', label: t('Records.option_all') },
      this.createStatusOption(ASSIGNING),
      this.createStatusOption(ONGOING),
      this.createStatusOption(PICKED_UP),
      this.createStatusOption(COMPLETED),
      this.createStatusOption(CANCELLED),
    ];
    const selectedOption = options.find(option => option.value === status);

    const fromIndex = total ? current * max - max + 1 : 0;
    let toIndex = total ? current * max : 0;
    if (toIndex > total) toIndex = total;
    return (
      <Fragment>
        <StatusFilter>
          <StatusDropdown
            items={options}
            selectedItem={selectedOption}
            onChange={this.handleStatusFilter}
          />
        </StatusFilter>
        <DateFilter>
          <DateRangePicker
            startDate={moment(start)}
            endDate={moment(end)}
            onDatesChange={this.handleDateChange}
            minDate={moment(end).clone().subtract(MAX_RANGE_LIMIT_DAYS, 'days')}
            // include start date
            maxDate={moment(start)
              .clone()
              .add(MAX_RANGE_LIMIT_DAYS - 1, 'days')}
          />
        </DateFilter>
        <Pagination
          current={current}
          pageSize={max}
          total={total}
          description={t('Records.pagination', { fromIndex, toIndex, total })}
          onChange={this.handlePagination}
          loading={isLoading}
        />
      </Fragment>
    );
  };

  updateUrlPath = (orderId, currentType) => {
    const { match, location, historyPush } = this.props;
    let { url } = match;
    if (orderId) url = `${url}/${orderId}`;
    if (currentType) url = `${url}/${currentType}`;
    historyPush({ ...location, pathname: url });
  };

  render() {
    const {
      t,
      orders,
      serviceNames,
      isLoading,
      orderEditEnabled,
      historyPush,
      location,
    } = this.props;
    const { search } = this.state;
    const isCustom = this.isCustomFilters();

    const columns = [
      {
        key: 'status',
        label: t('Records.table_column_title_status'),
        render: (data, { refId }) => (
          <Fragment>
            <Tag type={this.tagTypes[data]}>{t(this.labelMap[data])}</Tag>
            <SupportText>
              <OrderIdDisplay orderId={refId} />
            </SupportText>
          </Fragment>
        ),
      },
      {
        key: 'deliveryDatetime',
        label: t('Records.table_column_title_date'),
        render: data => (
          <Fragment>
            <div>{renderTime(data, t('DateTime.time_24'))}</div>
            <SupportText>
              {renderDate(data, t('DateTime.date_full'))}
            </SupportText>
          </Fragment>
        ),
      },
      {
        key: 'waypoints',
        label: t('Records.table_column_title_route'),
        render: waypoints => (
          <RouteList
            t={_curry(t, 2)('Records.number_of_stops')}
            list={waypoints.map(stop => stop.name)}
          />
        ),
      },
      {
        key: 'driver',
        label: t('Records.table_column_title_driver'),
        render: (data, { status, canShowDriverInfo, userRating }) => (
          <Fragment>
            <Truncate>
              {status === statusMap.ASSIGNING
                ? t('Records.label_driver_matching')
                : (data && data.name) || t('Records.label_driver_not_matched')}
            </Truncate>
            <SupportText>
              {status === statusMap.COMPLETED ? (
                <Rating value={userRating} size={7} />
              ) : (
                canShowDriverInfo && data && data.phone
              )}
            </SupportText>
          </Fragment>
        ),
      },
      {
        key: 'serviceTypeId',
        label: t('Records.table_column_title_type'),
        render: (data, { driver, canShowDriverInfo }) => (
          <Fragment>
            {serviceNames[data] || data}
            <SupportText>
              {/* Show driver's license plate number when order is matched (next phase): */}
              {false && canShowDriverInfo && driver && driver.licensePlate}
            </SupportText>
          </Fragment>
        ),
      },
      {
        key: 'creatorName',
        label: t('Records.table_column_title_contact'),
        render: name => <Truncate>{name}</Truncate>,
      },
      {
        key: 'noteToDriver',
        label: t('Records.table_column_title_notes'),
        render: remark => <NotesToDriverCell text={remark} />,
      },
      {
        key: 'price',
        label: t('Records.table_column_title_price'),
        render: (data, { paymentMethod }) => (
          <Fragment>
            <div>{formatter.currency(data.total)}</div>
            <SupportText>{t(this.paymentMap[paymentMethod])}</SupportText>
          </Fragment>
        ),
      },
      {
        key: 'others',
        label: '',
        render: (_, { id, status, editable, deliveryStatusFlow }) => {
          const addPriorityFeeOption = {
            value: 'addPriorityFee',
            label: t('Records.option_add_priority_fee'),
            onSelect: () =>
              this.props.openPanel('ORDER', {
                orderId: id,
                currentType: 'addPriorityFee',
              }),
          };
          const cancelOption = {
            value: 'cancel',
            label: t('Records.option_cancel_order'),
            onSelect: () =>
              this.props.openPanel('ORDER', {
                orderId: id,
                currentType: 'cancel',
              }),
          };
          const trackOption = {
            value: 'tracking',
            label: t('Records.option_track_order'),
            onSelect: () =>
              this.props.openPanel('ORDER', {
                orderId: id,
                currentType: 'tracking',
              }),
          };
          const editOption = {
            value: 'edit',
            label: t('Records.option_edit_order'),
            disabled: !editable || deliveryStatusFlow,
            tooltip: deliveryStatusFlow
              ? t('Records.button_tooltip_contact_cs')
              : '',
            onSelect: () =>
              historyPush({
                pathname: `/orders/${id}/edit`,
                search: new URLSearchParams({
                  referer: `/orders/${id}${location.search}`,
                }).toString(),
              }),
          };
          const options = [
            ...(status === statusMap.ASSIGNING ? [addPriorityFeeOption] : []),
            ...([statusMap.ONGOING, statusMap.PICKED_UP].includes(status)
              ? [trackOption]
              : []),
            ...(orderEditEnabled && status === statusMap.ONGOING
              ? [editOption]
              : []),
            {
              value: 'clone',
              label: t('Records.option_clone_order'),
              disabled: deliveryStatusFlow,
              tooltip: deliveryStatusFlow
                ? t('Records.button_tooltip_contact_cs')
                : '',
              onSelect: () => this.props.cloneOrder(id),
            },
            ...([statusMap.ASSIGNING, statusMap.ONGOING].includes(status)
              ? [cancelOption]
              : []),
          ];

          return (
            <StyledDropdown
              onClick={e => this.handleDropdownClick(e, id, status)}
              items={options}
              variant="compact"
              direction="left"
            />
          );
        },
      },
    ];

    const emptyState = (
      <EmptyState
        image={
          <img
            src={isCustom ? noResultSVG : noRecordSVG}
            alt={t(
              isCustom ? 'Records.msg_no_result' : 'Records.msg_no_record'
            )}
          />
        }
        text={t(isCustom ? 'Records.msg_no_result' : 'Records.msg_no_record')}
      />
    );

    const handleChangeMock = ({ target }) => {
      this.useMockData = target.checked;
      if (target.checked) {
        this.props.mockDeliveryOrders(); // eslint-disable-line react/prop-types
      } else {
        this.fetchRecords();
      }
    };

    return (
      <Layout>
        <HtmlTitle>{t('Title.records')}</HtmlTitle>
        <PageHeader
          topControls={
            <SearchForm onSubmit={this.handleSearch}>
              <SearchInput
                defaultValue={search}
                placeholder={t('Records.placeholder_search')}
              />
            </SearchForm>
          }
          title={t('Records.title_records')}
          headerEnd={this.renderFilterControls()}
        />
        {process.env.REACT_APP_ENABLE_DELIVERY &&
          process.env.REACT_APP_DEBUG && (
            <Checkbox
              onChange={handleChangeMock}
              label="Mock Delivery Orders"
              style={{ marginLeft: 'auto' }}
            />
          )}
        <RecordsTable
          hoverable
          data={orders}
          columns={columns}
          onRowClick={this.handleRowClick}
          loading={isLoading}
          emptyState={emptyState}
        />
      </Layout>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_RECORDS']);

const mapState = state => ({
  orders: getOrdersWithInfo(state),
  recordCount: getRecordCount(state),
  serviceNames: getServiceNames(state),
  isLoading: loadingSelector(state),
  panel: getPanel(state),
  hasSessionErrors: hasMessage(['SESSION'], invalidSessionErrors)(state),
  isDetailsOpen: isPanelOpen('ORDER')(state),
  orderEditEnabled: getCurrentCity(state).orderEditEnabled,
});

export default compose(
  withTranslation(),
  connect(mapState, {
    fetchRecords,
    cloneOrder,
    openPanel,
    closePanel,
    historyPush: push,
    historyReplace: replace,
    mockDeliveryOrders,
  })
)(Records);
