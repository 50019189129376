import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bool, func, string } from 'prop-types';

import { createLoadingSelector } from 'store/modules/loading';
import { removeDriver } from 'store/modules/drivers';
import { closeDialog } from 'store/modules/ui';

import Dialog from 'components/Dialog';
import { noop } from 'utils/helpers';

const DriverRemovalDialog = ({
  t,
  id,
  type,
  onClose,
  isSubmitting,
  removeDriver, // eslint-disable-line no-shadow
  ...props
}) => (
  <Dialog
    title={t(`Drivers.dialog_title_remove_${type}`)}
    subtitle={t(`Drivers.dialog_text_remove_${type}`)}
    primaryButtonText={t('Drivers.dialog_button_ok')}
    primaryAction={() => removeDriver({ id, favorite: type === 'favorite' })}
    disablePrimaryButton={isSubmitting}
    isLoadingPrimaryButton={isSubmitting}
    secondaryButtonText={t('Drivers.dialog_button_cancel')}
    secondaryAction={onClose}
    disableSecondaryButton={isSubmitting}
    isLoadingSecondaryButton={isSubmitting}
    onRequestClose={onClose}
    {...props}
  />
);

DriverRemovalDialog.defaultProps = {
  t: noop,
  type: 'favorite',
  onClose: noop,
  isSubmitting: false,
  removeDriver: noop,
};

DriverRemovalDialog.propTypes = {
  t: func,
  id: string.isRequired,
  type: string,
  onClose: func,
  isSubmitting: bool,
  removeDriver: func,
};

const loadingSelector = createLoadingSelector(['REMOVE_DRIVER']);

const mapStateToProps = state => ({
  isSubmitting: loadingSelector(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    removeDriver,
    onClose: closeDialog,
  })
)(DriverRemovalDialog);
