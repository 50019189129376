import { call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchFavoriteDrivers as fetchFavDriversAPI,
  fetchBannedDrivers as fetchBanDriversAPI,
  removeDriver as removeDriverAPI,
} from 'api/mobileAPI';
import { resetDialog } from 'store/modules/ui';
import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { CHANGE_LOCATION_REQUEST } from 'store/modules/region/actions';

export const FETCH_FAVORITE_DRIVERS_REQUEST = 'FETCH_FAVORITE_DRIVERS_REQUEST';
export const FETCH_FAVORITE_DRIVERS_SUCCESS = 'FETCH_FAVORITE_DRIVERS_SUCCESS';
export const FETCH_FAVORITE_DRIVERS_FAILURE = 'FETCH_FAVORITE_DRIVERS_FAILURE';

export const FETCH_BANNED_DRIVERS_REQUEST = 'FETCH_BANNED_DRIVERS_REQUEST';
export const FETCH_BANNED_DRIVERS_SUCCESS = 'FETCH_BANNED_DRIVERS_SUCCESS';
export const FETCH_BANNED_DRIVERS_FAILURE = 'FETCH_BANNED_DRIVERS_FAILURE';

export const REMOVE_DRIVER_REQUEST = 'REMOVE_DRIVER_REQUEST';
export const REMOVE_DRIVER_SUCCESS = 'REMOVE_DRIVER_SUCCESS';
export const REMOVE_DRIVER_FAILURE = 'REMOVE_DRIVER_FAILURE';

export const fetchFavoriteDrivers = query => ({
  type: FETCH_FAVORITE_DRIVERS_REQUEST,
  query,
});

export const fetchBannedDrivers = query => ({
  type: FETCH_BANNED_DRIVERS_REQUEST,
  query,
});

export const removeDriver = ({ id, favorite }) => ({
  type: REMOVE_DRIVER_REQUEST,
  id,
  favorite,
});

export const initState = {
  drivers: {},
  favoriteIds: [],
  bannedIds: [],
  favoriteCount: 0,
  bannedCount: 0,
};

export function getFavoriteDrivers(state) {
  return state.drivers.favoriteIds.map(id => state.drivers.drivers[id]);
}

export function getBannedDrivers(state) {
  return state.drivers.bannedIds.map(id => state.drivers.drivers[id]);
}

export function getFavoriteDriverCount(state) {
  return state.drivers.favoriteCount;
}

export function getBannedDriverCount(state) {
  return state.drivers.bannedCount;
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case CHANGE_LOCATION_REQUEST:
    case REQUEST_LOGOUT:
      return initState;
    case FETCH_FAVORITE_DRIVERS_SUCCESS: {
      const { drivers, count, ids } = action;
      return {
        ...state,
        drivers: {
          ...state.drivers,
          ...drivers,
        },
        favoriteIds: ids,
        favoriteCount: count,
      };
    }
    case FETCH_BANNED_DRIVERS_SUCCESS: {
      const { drivers, count, ids } = action;
      return {
        ...state,
        drivers: {
          ...state.drivers,
          ...drivers,
        },
        bannedIds: ids,
        bannedCount: count,
      };
    }
    case REMOVE_DRIVER_SUCCESS: {
      const { [action.id]: __, ...rest } = state.drivers;
      return {
        ...state,
        drivers: rest,
        ...(action.favorite && {
          favoriteIds: state.favoriteIds.filter(id => id !== action.id),
          favoriteCount: state.favoriteCount - 1,
        }),
        ...(!action.favorite && {
          bannedIds: state.bannedIds.filter(id => id !== action.id),
          bannedCount: state.bannedCount - 1,
        }),
      };
    }
    default: {
      return state;
    }
  }
}

export function* onFetchFavoriteDrivers({ query }) {
  try {
    const { drivers, count, ids } = yield call(fetchFavDriversAPI, query);
    yield put({ type: FETCH_FAVORITE_DRIVERS_SUCCESS, drivers, count, ids });
  } catch ({ message }) {
    yield put({
      type: FETCH_FAVORITE_DRIVERS_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* onFetchBannedDrivers({ query }) {
  try {
    const { drivers, count, ids } = yield call(fetchBanDriversAPI, query);
    yield put({ type: FETCH_BANNED_DRIVERS_SUCCESS, drivers, count, ids });
  } catch ({ message }) {
    yield put({
      type: FETCH_BANNED_DRIVERS_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* onRemoveDriver({ id, favorite }) {
  try {
    yield call(removeDriverAPI, id, favorite);
    yield put({ type: REMOVE_DRIVER_SUCCESS, id, favorite });
    yield put(resetDialog());
  } catch ({ message }) {
    yield put({
      type: REMOVE_DRIVER_FAILURE,
      meta: {
        type: 'error',
        message,
      },
    });
  }
}

export function* driverSaga() {
  yield takeLatest(FETCH_FAVORITE_DRIVERS_REQUEST, onFetchFavoriteDrivers);
  yield takeLatest(FETCH_BANNED_DRIVERS_REQUEST, onFetchBannedDrivers);
  yield takeLatest(REMOVE_DRIVER_REQUEST, onRemoveDriver);
}
