import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func, number, bool } from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import { message as MessageIcon } from '@lalamove/karang/dist/components/Icon/icons';

import { DROPDOWN } from 'styles/zIndex';
import { actions } from 'store/modules/zendesk';
import { SEGMENT_START_CHAT } from 'store/modules/zendesk/tracking';
import withZendesk from 'store/modules/zendesk/withZendesk';
import { orange, white, red } from 'styles/colors';
import Segment from 'utils/segment';

const ChatCanvas = styled.div`
  position: fixed;
  z-index: ${DROPDOWN};
  overflow: hidden;
  width: 52px;
  height: 52px;
  transition: right 0.3s;
`;

const ChatButton = styled.button`
  position: absolute;
  bottom: ${props => (props.hide ? '-100%' : '0')};
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${orange};
  color: ${white};
  outline: none;
  transition: bottom 0.1s ease-in-out;

  &:hover {
    background-color: ${darken(0.1, orange)};
  }
`;

const MessageBubble = styled.span`
  position: absolute;
  top: -6px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid ${white};
  background-color: ${red};
  border-radius: 50%;
`;

class ZendeskButton extends Component {
  static propTypes = {
    unread: number.isRequired,
    isOpen: bool.isRequired,
    // TODO: already fixed eslint-plugin-react since v7.8
    offsetX: number.isRequired, // eslint-disable-line react/no-unused-prop-types
    offsetY: number.isRequired, // eslint-disable-line react/no-unused-prop-types
    bindOnce: func.isRequired,
    updateUnreadCount: func.isRequired,
    open: func.isRequired,
    movePosition: func.isRequired,
  };

  state = {
    right: 16,
    bottom: 8,
  };

  launcherObserver = null;

  static getDerivedStateFromProps(props) {
    return {
      // 52 - 48 = 4
      right: props.offsetX - 4,
      bottom: props.offsetY,
    };
  }

  componentDidMount() {
    const { bindOnce, updateUnreadCount } = this.props;
    bindOnce('chat:unreadMessages', num => {
      updateUnreadCount(num);
    });
    bindOnce('chat:start', () => {
      // tracking
      Segment.createTrack(SEGMENT_START_CHAT);
    });
  }

  handleClick = () => {
    const { open } = this.props;
    open();
  };

  handleDragEnd = e => {
    const { movePosition } = this.props;
    const offset = {
      right: window.innerWidth - e.clientX - 24,
      bottom: window.innerHeight - e.clientY - 24,
    };
    this.setState(offset);
    movePosition(offset);
  };

  render() {
    const { right, bottom } = this.state;
    const { unread, isOpen } = this.props;
    return (
      <ChatCanvas
        onDragEnd={this.handleDragEnd}
        // draggable="true"
        style={{
          right: `${right}px`,
          bottom: `${bottom}px`,
        }}
      >
        <ChatButton onClick={this.handleClick} hide={isOpen}>
          <MessageIcon size={24} color={white} />
          {unread > 0 && (
            <MessageBubble>{unread > 9 ? '9+' : unread}</MessageBubble>
          )}
        </ChatButton>
      </ChatCanvas>
    );
  }
}

const mapStateToProps = state => ({
  unread: state.zendesk.unread,
  offsetX: state.zendesk.offset.x,
  offsetY: state.zendesk.offset.y,
  isOpen: state.zendesk.isOpen,
});

const mapDispatchToProps = {
  updateUnreadCount: actions.updateUnreadCount,
  open: actions.open,
  movePosition: actions.movePosition,
};

export default compose(
  withZendesk,
  connect(mapStateToProps, mapDispatchToProps)
)(ZendeskButton);
