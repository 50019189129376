import React, { useState } from 'react';
import { Button, Input } from '@lalamove/karang';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'store/modules/loading';
import { dismissMessage, makeMessageSelector } from 'store/modules/message';
import { Form } from 'interfaces/global/containers/Settings/style';
import { whenMobile } from 'components/MediaQuery';

const StyledInput = styled(Input)`
  display: block;
  margin-bottom: 1rem;

  ${whenMobile} {
    input {
      font-size: 16px;
    }
  }
`;

const ButtonGroup = styled.div`
  text-align: right;
`;

const CancelButton = styled(Button)`
  margin-right: 1rem;
`;

const EmailChangeForm = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state =>
    createLoadingSelector(['CHANGE_EMAIL_SMS'])(state)
  );
  const apiError = useSelector(state =>
    makeMessageSelector(['CHANGE_EMAIL_SMS'])(state)
  );

  const [formState, setFormState] = useState({
    newEmail: '',
  });

  const handleInputChange = e => {
    const { name, value } = e.target;

    setFormState(state => ({ ...state, [name]: value }));
    if (apiError && apiError.message)
      dispatch(dismissMessage('CHANGE_EMAIL_SMS'));
  };

  const sendEmailSMS = e => {
    e.preventDefault();
    onSubmit(formState.newEmail);
  };

  return (
    <Form onSubmit={sendEmailSMS}>
      <StyledInput
        disabled={loading}
        name="newEmail"
        label={t('Settings.new_email')}
        value={formState.newEmail}
        onChange={handleInputChange}
        error={apiError && apiError.message ? t(apiError.message) : null}
      />
      <ButtonGroup>
        <CancelButton
          disabled={loading}
          type="reset"
          variant="link"
          onClick={onCancel}
        >
          {t('Settings.button_cancel')}
        </CancelButton>
        <Button
          disabled={loading || formState.newEmail.length === 0}
          isLoading={loading}
          type="submit"
          variant="primary"
          solid
        >
          {t('Settings.button_email_send_sms')}
        </Button>
      </ButtonGroup>
    </Form>
  );
};

EmailChangeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EmailChangeForm;
