import React from 'react';
import createSVGIcon from '@lalamove/karang/dist/components/Icon/icons/utils/createSVGIcon';

const checked = createSVGIcon(
  <g>
    <path
      d="M10 18.3334C14.6024 18.3334 18.3333 14.6025 18.3333 10.0001C18.3333 5.39771 14.6024 1.66675 10 1.66675C5.39762 1.66675 1.66666 5.39771 1.66666 10.0001C1.66666 14.6025 5.39762 18.3334 10 18.3334Z"
      fill="#F16622"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8883 7.04522C14.2317 7.35175 14.2615 7.87854 13.9549 8.22185L9.78828 12.8885C9.63563 13.0595 9.41932 13.16 9.19022 13.1665C8.96112 13.173 8.73947 13.0848 8.57741 12.9228L6.07741 10.4228C5.75197 10.0973 5.75197 9.56969 6.07741 9.24425C6.40284 8.91881 6.93048 8.91881 7.25592 9.24425L9.13236 11.1207L12.7117 7.11182C13.0182 6.76852 13.545 6.7387 13.8883 7.04522Z"
      fill="white"
    />
  </g>,
  'checked',
  '0 0 20 20'
);

export default checked;
