import { put, select, take, call } from 'redux-saga/effects';
import { getSubSpecialRequest } from 'interfaces/global/store/modules/services/selectors';
import {
  getFilledWaypts,
  getAreaValidationStatus,
} from 'interfaces/global/store/modules/routing/selectors';
import { getPrice } from 'interfaces/global/store/modules/pricing/selectors';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { FETCH_PRICE_SUCCESS } from 'interfaces/global/store/modules/pricing/actions';

export default function* onChangeVehicleStandard(
  vehicle,
  prevSubId,
  nextSubId
) {
  const filledWaypoints = yield select(getFilledWaypts);
  const isValidServiceArea = yield select(getAreaValidationStatus);

  const defer = isValidServiceArea && filledWaypoints.length >= 2;
  // If enough information is provided from the user to call price_calculate,
  // Tracking should be deferred until updated price is fetched
  if (defer) yield take(FETCH_PRICE_SUCCESS);
  const { total } = yield select(getPrice);

  yield call(
    trackVehicleSpecification,
    'vehicle_specification_removed',
    prevSubId,
    vehicle,
    total
  );
  yield call(
    trackVehicleSpecification,
    'vehicle_specification_added',
    nextSubId,
    vehicle,
    total
  );
}

function* trackVehicleSpecification(event, subId, vehicle, total) {
  const subSpecialRequest =
    subId && (yield select(getSubSpecialRequest, subId));

  if (subSpecialRequest) {
    const { name: spec } = subSpecialRequest;
    yield put(
      track(event, {
        addon_vehicle_spec: spec,
        vehicle_type: vehicle,
        order_amount: total.toNumber(),
      })
    );
  }
}
