import React, { Component } from 'react';
import { func, number, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { closeDialog } from 'store/modules/ui';
import Dialog from 'components/Dialog';
import { noop, daylightSavingsStatus } from 'utils/helpers';

class DaylightSavingsTimeDialog extends Component {
  static defaultProps = {
    t: noop,
    onClose: noop,
    TimeDialogSubmit: noop,
    daylightSavingsTimeType: daylightSavingsStatus['CDT'],
    selectedTime: '',
    CDT: '',
  };

  static propTypes = {
    t: func,
    onClose: func,
    TimeDialogSubmit: func,
    daylightSavingsTimeType: number,
    selectedTime: string,
    CDT: string,
  };

  handleClose = () => {
    this.props.onClose();
    this.props.TimeDialogSubmit(daylightSavingsStatus['CST']);
  };

  handleSubmit = () => {
    this.props.onClose();
    this.props.TimeDialogSubmit(daylightSavingsStatus['CDT']);
  };

  render() {
    const {
      t,
      selectedTime,
      CDT,
      daylightSavingsTimeType,
      ...props
    } = this.props;
    const message =
      daylightSavingsTimeType === daylightSavingsStatus['CDT']
        ? t('DateTime.CDT_message', { selectedTime, CDT })
        : t('DateTime.CST_message', { selectedTime });
    return (
      <Dialog
        title=""
        subtitle={message}
        secondaryButtonText={
          daylightSavingsTimeType === daylightSavingsStatus['CST']
            ? t('DateTime.CDT_dialog_button')
            : null
        }
        primaryButtonText={t('DateTime.CST_dialog_button')}
        primaryAction={this.handleSubmit}
        secondaryAction={this.handleClose}
        shouldCloseOnOverlayClick={false}
        {...props}
      />
    );
  }
}
const mapState = state => ({});
export default compose(
  withTranslation(),
  connect(mapState, {
    onClose: closeDialog,
  })
)(DaylightSavingsTimeDialog);
