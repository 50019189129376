import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bool, func, string, number, oneOf } from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import CsvParse from '@vtex/react-csv-parse';
import styled from 'styled-components';
import { Button, Heading, HeadingGroup } from '@lalamove/karang';

import {
  trimWaypoints,
  toggleOptimize,
  receiveFileUpload,
} from 'interfaces/global/store/modules/routing/actions';
import {
  getFilledWaypts,
  getShouldOptimize,
} from 'interfaces/global/store/modules/routing/selectors';
import { getSelectedService } from 'interfaces/global/store/modules/services/selectors';
import { getCheckoutStep } from 'interfaces/global/store/modules/checkout/selectors';
import optRouteJPG from 'assets/route.opt-icon.jpg';
import { withResponsiveMedia } from 'components/MediaQuery';
import OptimizeRoute from 'components/OptimizeRoute';
import Modal from 'components/Modal';
import { noop } from 'utils/helpers';
import Editor from 'interfaces/global/containers/Editor';
import SelectVehicle from 'interfaces/global/containers/SelectVehicle';
import SelectRoute from 'interfaces/global/containers/SelectRoute';

import { track } from 'interfaces/global/store/modules/tracking/actions';
import { CheckoutSteps } from 'interfaces/global/store/modules/checkout/types';

import ConnectedAdditionalServices from './components/AdditionalServices';
import VehicleSlider from './components/VehicleSlider';

const ImportButton = styled(Button)`
  margin-left: 0.5rem;
`;

const ModalImg = styled.div`
  text-align: center;
`;

const ModalContent = styled.div`
  margin: 0 2rem;
`;

const constructCsvData = t => {
  const tc = key => t(`CSV.${key}`);
  const data = [
    [
      tc('csv_title_address'),
      tc('csv_title_name'),
      tc('csv_title_phone'),
      tc('csv_title_block_floor'),
    ],
    [
      tc('csv_sample_address'),
      tc('csv_sample_name'),
      tc('csv_sample_phone'),
      tc('csv_sample_block_floor'),
    ],
  ];
  const csvPlaceholders = Array(4).fill(tc('csv_placeholder'));
  data.push(csvPlaceholders);
  return data;
};

export class PlaceOrder extends Component {
  static defaultProps = {
    allowOptimize: false,
    shouldOptimize: false,
    shouldTrim: false,
    trimWaypoints: noop,
    currentServiceName: '',
    totalStops: 0,
  };

  static propTypes = {
    t: func.isRequired,
    toggleOptimize: func.isRequired,
    receiveFileUpload: func.isRequired,
    trimWaypoints: func,
    allowOptimize: bool,
    shouldOptimize: bool,
    shouldTrim: bool,
    currentServiceName: string,
    totalStops: number,
    track: func.isRequired,
    isMobile: bool.isRequired,
    checkoutStep: oneOf(Object.values(CheckoutSteps)).isRequired,
  };

  state = {
    optimizeRouteModal: false,
  };

  csvKeys = ['address', 'name', 'phone', 'addressDetails'];

  componentWillUnmount() {
    if (this.props.shouldTrim) {
      this.props.trimWaypoints();
    }
  }

  handleModalClose = () => {
    this.setState({
      optimizeRouteModal: false,
    });
  };

  handleEnquireOptimizeRoute = () => {
    this.setState({
      optimizeRouteModal: true,
    });
  };

  handleOptimizeRouteToggle = () => {
    const {
      // eslint-disable-next-line no-shadow
      toggleOptimize,
      shouldOptimize,
      currentServiceName,
      totalStops,
      // eslint-disable-next-line no-shadow
      track,
    } = this.props;
    if (!shouldOptimize) {
      track('optimize_route_tapped', {
        vehicle_type: currentServiceName,
        stop_total: totalStops,
      });
    }
    toggleOptimize(!shouldOptimize);
  };

  handleImport = () => {
    this.fileInput.value = '';
    this.fileInput.click();

    this.props.track('upload_csv_tapped');
  };

  handleFileError = () => {
    // eslint-disable-next-line no-console
    console.error('error parsing file');
  };

  handleFileLoaded = data => {
    this.props.receiveFileUpload(data);
  };

  handleDownloadCsvClicked = () => {
    this.props.track('download_csv_tapped');
  };

  render() {
    const {
      t,
      allowOptimize,
      shouldOptimize,
      isMobile,
      checkoutStep,
    } = this.props;

    if (isMobile) {
      return checkoutStep === CheckoutSteps.SELECT_VEHICLE ? (
        <SelectVehicle />
      ) : (
        <SelectRoute />
      );
    }

    const csvData = constructCsvData(t);
    return (
      <>
        <VehicleSlider />
        <HeadingGroup>
          <Heading htmlTag="h3">{t('PlaceOrder.heading_route')}</Heading>
          {/* Safari bug on downloading blob URL: https://bugs.webkit.org/show_bug.cgi?id=190351 */}
          {/* A working get-around: https://github.com/react-csv/react-csv/issues/123 */}
          <CSVLink data={csvData} filename="template.csv" target="_self">
            <Button
              data-tip={t('PlaceOrder.button_tip_download_template')}
              data-place="bottom"
              data-effect="solid"
              data-for="global"
              onClick={this.handleDownloadCsvClicked}
            >
              {t('PlaceOrder.button_download_template')}
            </Button>
          </CSVLink>
          <ImportButton
            onClick={this.handleImport}
            data-tip={t('PlaceOrder.button_tip_import_address')}
            data-place="bottom"
            data-effect="solid"
            data-for="global"
          >
            {t('PlaceOrder.button_import_address')}
          </ImportButton>
          <CsvParse
            keys={this.csvKeys}
            onDataUploaded={this.handleFileLoaded}
            onError={this.handleFileError}
            render={onChange => (
              <input
                type="file"
                accept=".csv"
                ref={ref => {
                  this.fileInput = ref;
                }}
                style={{ display: 'none' }}
                onChange={onChange}
              />
            )}
          />
        </HeadingGroup>
        <Editor />
        <OptimizeRoute
          allowOptimize={allowOptimize}
          shouldOptimize={shouldOptimize}
          onToggle={this.handleOptimizeRouteToggle}
        />
        <ConnectedAdditionalServices />
        <Modal
          title={t('PlaceOrder.modal_optimize_route_title')}
          name="optimize_route"
          isOpen={this.state.optimizeRouteModal}
          onClose={this.handleModalClose}
        >
          <ModalImg>
            <img src={optRouteJPG} alt={t('PlaceOrder.optimize_route')} />
          </ModalImg>
          <ModalContent>
            <Trans i18nKey="PlaceOrder.modal_optimize_route_content">
              <p>
                Use <strong>Optimize Route</strong> and we will calculate the
                shortest way to each location from the starting point.
              </p>
              <p>
                When you tick the box, the order of the stops and the final
                price will be updated to reflect the new path.
              </p>
            </Trans>
          </ModalContent>
        </Modal>
      </>
    );
  }
}

const mapState = state => ({
  allowOptimize: getFilledWaypts(state).length >= 3,
  shouldOptimize: getShouldOptimize(state),
  shouldTrim: getFilledWaypts(state).length >= 2,
  currentServiceName: getSelectedService(state)?.name,
  totalStops: getFilledWaypts(state).length,
  checkoutStep: getCheckoutStep(state),
});

export default compose(
  withTranslation(),
  withResponsiveMedia,
  connect(mapState, {
    toggleOptimize,
    receiveFileUpload,
    trimWaypoints,
    track,
  })
)(PlaceOrder);
