import React from 'react';
import { bool, func, node, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { lighten, rgba } from 'polished';
import { Spinner } from '@lalamove/karang';

import { black, gray, offWhite, orange, silver } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { noop } from 'utils/helpers';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 33rem;
  border: 1px solid ${offWhite};
  border-radius: 0.5em;
`;

const Label = styled.div`
  width: 75%;
  padding: 1.5em 1.75em;
  border-right: 1px solid ${offWhite};
  margin: 0.5em 0;
  color: ${gray};

  & > h4 {
    margin: 0;
  }

  & > p {
    margin: 0.2em 0 0 0;
    color: ${black};
    font-size: ${fontSize.small};
  }
`;

const Button = styled.button`
  align-items: center;
  align-self: normal;
  width: 25%;
  padding: 0.5em 1.25em;
  border: none;
  border-radius: 0 0.5em 0.5em 0;
  outline: 0;
  background-color: transparent;
  color: ${({ loading }) => (loading ? silver : orange)};
  cursor: ${({ loading }) => (loading ? 'not-allowed' : 'pointer')};

  ${({ loading }) =>
    !loading &&
    css`
      &:hover:enabled,
      &:active:enabled {
        background-color: ${lighten(0.4, orange)};
      }
      &:active:enabled,
      &:focus:enabled {
        box-shadow: 0 0 0 4px ${rgba(lighten(0.4, orange), 0.4)};
      }
    `}

  & span {
    margin-top: 0.2em;
    font-size: ${fontSize.small};
  }
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

// eslint-disable-next-line no-shadow
const DownloadButton = ({ t, title, description, icon, loading, onClick }) => (
  <Container>
    <Label>
      <h4>{title}</h4>
      <p>{description}</p>
    </Label>
    <Button loading={loading} onClick={onClick}>
      {loading ? (
        <ButtonContent>
          <Spinner size={40} color={silver} />
          <span>{t('Wallet.button_creating_file')}</span>
        </ButtonContent>
      ) : (
        <ButtonContent>
          {icon}
          <span>{t('Wallet.button_download')}</span>
        </ButtonContent>
      )}
    </Button>
  </Container>
);

DownloadButton.propTypes = {
  t: func,
  title: string.isRequired,
  description: string,
  icon: node,
  loading: bool,
  onClick: func,
};

DownloadButton.defaultProps = {
  t: noop,
  description: '',
  icon: null,
  loading: false,
  onClick: noop,
};

export default withTranslation()(DownloadButton);
