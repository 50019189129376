import React, { Component } from 'react';
import { func, node, shape, string, bool, arrayOf, number } from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Button, Input, Form } from '@lalamove/karang';
import { FacebookButton, GoogleButton } from 'components/Button';
import storage from 'utils/storage';
import PhoneInput from 'components/PhoneInput';
import logoPNG from 'assets/lalamove-logo.png';
import logoSVG from 'assets/svg/logo.svg';
import { noop } from 'utils/helpers';
import { silver, lightSilver } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { ResponsiveCard } from 'components/Container';
import { whenMobile } from 'components/MediaQuery';

export const Logo = styled.div`
  width: 198px;
  height: 55px;
  margin: 0 auto 28px;
  background-image: url(${logoPNG}); /* fallback */
  background-image: url(${logoSVG});
  background-position: center;
  background-size: 198px 55px;
`;

const StyledInput = styled(Input)`
  flex: 1;

  ${whenMobile} {
    input {
      font-size: 16px;
    }
  }
`;

StyledInput.displayName = 'StyledInput';

const FormItem = styled(Form.Item)`
  justify-content: flex-end;
`;

const ForgotPasswordContainer = styled(FormItem)`
  margin-bottom: 2rem;
`;

const FromActionSeparator = styled.div`
  padding: 1rem 0;
  color: ${lightSilver};
  font-size: ${fontSize.small};
  text-align: center;
`;

const ButtonsSeparator = styled.div`
  margin: 1rem 0;
`;

const CardFooter = styled.div`
  padding-top: 2rem;
  color: ${silver};
  text-align: center;
`;

export class Login extends Component {
  static defaultProps = {
    t: noop,
    loading: false,
    loadingFacebookLoginButton: true,
    loadingGoogleLoginButton: true,
    countries: [],
    forgotPasswordLink: null,
    registerLink: null,
    apiError: null,
    onCountryChange: noop,
    onSubmit: noop,
    onFBLogin: noop,
    onGoogleLogin: noop,
  };

  static propTypes = {
    t: func,
    loading: bool,
    loadingFacebookLoginButton: bool,
    loadingGoogleLoginButton: bool,
    currentCountry: shape({
      areaCode: string.isRequired,
    }).isRequired,
    countries: arrayOf(shape({})),
    forgotPasswordLink: node,
    registerLink: node,
    apiError: shape({ message: string, errorCode: number }),
    onCountryChange: func,
    onSubmit: func,
    onFBLogin: func,
    onGoogleLogin: func,
  };

  state = {
    username: '',
    password: '',
    showError: { username: false, password: false },
  };

  componentDidMount() {
    const username = storage.getItem('username');
    if (username) {
      this.setState({ username });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    const { areaCode } = this.props.currentCountry;
    this.setState({ showError: { username: true, password: true } });
    this.props.onSubmit({
      username,
      password,
      areaCode,
    });
  };

  handleChange = ({ target }) => {
    const { name, value } = target;
    this.setState(
      prevState => ({
        [name]: value,
        showError: { ...prevState.showError, [name]: false },
      }),
      () => {
        const { username } = this.state;
        storage.setItem('username', username || '');
      }
    );
  };

  handleBlur = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value.trim(),
    });
  };

  isFormReady() {
    const { username, password } = this.state;
    return !!(username && password);
  }

  render() {
    const {
      t,
      currentCountry,
      countries,
      onFBLogin,
      onGoogleLogin,
      registerLink,
      forgotPasswordLink,
      loading,
      loadingFacebookLoginButton,
      loadingGoogleLoginButton,
      onCountryChange,
      apiError,
    } = this.props;
    const { username, password, showError } = this.state;
    const showAreaCode = !!username && /^\d{2,}$/.test(username.trim());

    return (
      <ResponsiveCard>
        <Logo />
        <form onSubmit={this.handleSubmit}>
          <FormItem>
            <PhoneInput
              name="username"
              showAreaCode={showAreaCode}
              label={t('Login.email_or_mobile')}
              value={username}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              countries={countries}
              currentCountry={currentCountry}
              onCountryChange={onCountryChange}
            />
          </FormItem>
          <FormItem>
            <StyledInput
              type="password"
              name="password"
              label={t('Login.password')}
              value={password}
              error={
                showError.password && !loading && apiError
                  ? t(apiError.message)
                  : ''
              }
              onChange={this.handleChange}
            />
          </FormItem>
          <ForgotPasswordContainer>
            {forgotPasswordLink}
          </ForgotPasswordContainer>
          <Button
            type="submit"
            variant="primary"
            size="large"
            block
            disabled={loading || !this.isFormReady()}
            isLoading={loading}
            solid
          >
            {t('Login.log_in')}
          </Button>
        </form>
        <>
          <FromActionSeparator>{t('Login.or')}</FromActionSeparator>
          <FacebookButton
            onClick={onFBLogin}
            disabled={loadingFacebookLoginButton || loading}
            solid
          >
            {t('Login.log_in_with_facebook')}
          </FacebookButton>
          {!GoogleButton.componentDisabled && (
            <>
              <ButtonsSeparator />
              <GoogleButton
                onClick={onGoogleLogin}
                disabled={loadingGoogleLoginButton || loading}
              >
                {t('Login.google_sign_in')}
              </GoogleButton>
            </>
          )}
          <CardFooter>{registerLink}</CardFooter>
        </>
      </ResponsiveCard>
    );
  }
}

export default withTranslation()(Login);
