import React, { Component } from 'react';
import styled from 'styled-components';
import { string, func, arrayOf, shape, bool } from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { HeadingGroup, RadioGroup } from '@lalamove/karang';
import { language as LangIcon } from '@lalamove/karang/dist/components/Icon/icons';

import CountryPicker from 'views/Home/components/CountryPicker';
import { withResponsiveMedia, whenMobile } from 'components/MediaQuery';
import { orange } from 'styles/colors';
import { compose, noop } from 'utils/helpers';
import { matchLLMLocation } from 'utils/location';
import { createLoadingSelector } from 'store/modules/loading';
import {
  selectors as regionSelector,
  actions as regionAction,
} from 'store/modules/region';

import { setProfile as setProfileAction } from 'interfaces/global/store/modules/tracking/actions';

import {
  Fieldset,
  Table,
  FormItem,
  LabelCol,
  Content,
  ContentBox,
  PaddedHeading,
} from '../style';

const LanguageContent = styled(ContentBox)`
  padding: 1em 0;
  ${whenMobile} {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 0 3em;
  }
`;

export class LocLang extends Component {
  static propTypes = {
    t: func,
    lng: string.isRequired,
    countries: arrayOf(shape({})),
    currentCity: shape({}),
    languages: arrayOf(shape({})),
    isLoading: bool,
    changeLocation: func.isRequired,
    changeLocale: func.isRequired,
    setProfile: func.isRequired,
    cityDict: shape({}).isRequired,
    isDesktop: bool.isRequired,
  };

  static defaultProps = {
    t: noop,
    countries: [],
    currentCity: null,
    languages: [],
    isLoading: false,
  };

  handleLocationChange = location => {
    const { changeLocation, lng } = this.props;
    const [country, code] = matchLLMLocation(location);
    if (code) {
      changeLocation({ location, locale: lng });
    } else {
      changeLocation({ country, locale: lng });
    }
  };

  handleLanguageChange = locale => {
    const { changeLocale, setProfile } = this.props;
    setProfile({ language: locale.substring(0, 2) });
    changeLocale({ locale });
  };

  render() {
    const {
      t,
      lng,
      countries,
      languages,
      currentCity,
      isLoading,
      cityDict,
      isDesktop,
    } = this.props;

    if (!isDesktop && languages.length <= 1) {
      return null;
    }
    return (
      /* eslint-disable jsx-a11y/label-has-for */
      <Fieldset>
        <HeadingGroup>
          <LangIcon color={orange} />
          <PaddedHeading size="large" htmlTag="h3">
            {isDesktop
              ? t('Settings.form_legend_lang_location')
              : t('Settings.form_label_language')}
          </PaddedHeading>
        </HeadingGroup>
        <Table>
          {isDesktop && (
            <FormItem>
              <LabelCol>
                <label htmlFor="location">
                  {t('Settings.form_label_city')}
                </label>
              </LabelCol>
              <Content>
                <CountryPicker
                  block
                  disabled={isLoading}
                  name="location"
                  onChange={this.handleLocationChange}
                  currentCity={currentCity}
                  countries={countries}
                  cityDict={cityDict}
                  locale={lng}
                />
              </Content>
            </FormItem>
          )}
          {languages.length > 1 && (
            <FormItem>
              {isDesktop && (
                <LabelCol>
                  <label htmlFor="languages">
                    {t('Settings.form_label_language')}
                  </label>
                </LabelCol>
              )}
              <LanguageContent>
                <RadioGroup
                  name="languages"
                  onChange={this.handleLanguageChange}
                  value={lng}
                  disabled={isLoading}
                >
                  {Radio =>
                    languages.map(({ id: locale, value: label }) => (
                      <Radio key={locale} value={locale}>
                        {label}
                      </Radio>
                    ))
                  }
                </RadioGroup>
              </LanguageContent>
            </FormItem>
          )}
        </Table>
      </Fieldset>
      /* eslint-enable jsx-a11y/label-has-for */
    );
  }
}

const mapState = state => ({
  countries: regionSelector.getCountries(state),
  currentCity: regionSelector.getCurrentCity(state),
  cityDict: regionSelector.getCityDict(state),
  languages: regionSelector
    .getCurrentCountry(state)
    .languages.filter(({ enable }) => enable),
  lng: regionSelector.getCurrentLocale(state),
  isLoading: createLoadingSelector(['FETCH_SERVICES'])(state), // HACK: piggyback on FETCH_SERVICES
});

export default compose(
  withTranslation(),
  withResponsiveMedia,
  connect(mapState, {
    setProfile: setProfileAction,
    changeLocation: regionAction.changeLocation,
    changeLocale: regionAction.changeLocale,
  })
)(LocLang);
