import Big from 'big.js';
import {
  call,
  put,
  race,
  select,
  take,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import moment from 'moment';
import { calculatePrice, fetchUpdateCoupon } from 'api/uAPI';
import {
  UApiError,
  priceRowType,
  redeemCouponErrorCodeMap,
} from 'utils/helpers';
import _flatten from 'lodash/flatten';
import { desktopQueryList } from 'components/MediaQuery';
import {
  ADD_SPECIAL_REQUEST,
  REMOVE_SPECIAL_REQUEST,
  SET_SUB_SPECIAL_REQUEST,
} from 'interfaces/global/store/modules/services/actions';
import { getCurrentCountry } from 'store/modules/region/selectors';
import {
  WAYPOINT_REMOVE,
  VALIDATE_SERVICE_AREA_SUCCESS,
} from 'interfaces/global/store/modules/routing/actions';
import {
  getFilledWaypts,
  getOrderIdByWayptId,
  getAreaValidationStatus,
} from 'interfaces/global/store/modules/routing/selectors';
import {
  getSelectedService,
  getSelectedServiceVehicleStd,
  getRevision,
} from 'interfaces/global/store/modules/services/selectors';
import { VEHICLE_STD_PREFIX } from 'interfaces/global/store/modules/services/helpers';
import {
  DATE_CHANGE,
  PLACE_ORDER_FAILURE,
  CONTACT_UPDATE,
  PAYMENT_METHOD_CHANGE,
} from 'interfaces/global/store/modules/checkout/actions';
import {
  getDeliveryDatetime,
  getCheckoutDirty,
  getContact,
  getSelectedPaymentMethodId,
} from 'interfaces/global/store/modules/checkout/selectors';
import { getImmediateDeliveryUnix } from 'interfaces/global/store/modules/checkout/helpers';
import { TOPUP_SUCCESS } from 'interfaces/global/store/modules/wallet/actions';
import { getCreditBalance } from 'interfaces/global/store/modules/wallet/selectors';
import { getUser } from 'interfaces/global/store/modules/auth/selectors';
import { DEFAULT_CURRENCY_RATE } from 'interfaces/global/config';

import {
  PaymentMethods,
  ALL_PAYMENT_METHOD_IDS,
} from 'interfaces/global/store/modules/checkout/types';
import {
  fetchPrice,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
  SET_COUPON,
  REDEEM_CHECKOUT_COUPON_REQUEST,
  REDEEM_CHECKOUT_COUPON_FAILURE,
  REDEEM_CHECKOUT_COUPON_SUCCESS,
  UPDATE_CHECKOUT_COUPONS,
} from './actions';

import refetchServicesAndCall from '../refetchServicesAndCall';

import { BASIC_PRICE_ITEM_TYPE, EXCEED_PRICE_ITEM_TYPE } from './helpers';
import { getAvailableCoupons } from './selectors';
import { track } from '../tracking/actions';

export const getSelectedSpecialRequests = state => {
  const svc = state.services;
  return svc.selectedSpecialRequests.map(item => svc.specialRequests[item].id);
};

export function constructLatLngStr(waypoints) {
  let latlong = '';
  waypoints.forEach(item => {
    latlong += latlong ? ',' : '';
    latlong += `${item.lat}|${item.lng}`;
  });
  return latlong;
}

export function extractPriceItems(data, currencyRate = DEFAULT_CURRENCY_RATE) {
  const itemsDict = {
    [BASIC_PRICE_ITEM_TYPE]: 'basic',
    [EXCEED_PRICE_ITEM_TYPE]: 'exceed',
  };

  return data.price_item
    .filter(item => typeof itemsDict[item.type] !== 'undefined')
    .reduce(
      (result, item) => ({
        ...result,
        [itemsDict[item.type]]: Big(item.value_fen).div(currencyRate),
      }),
      {}
    );
}

export const parsePriceItems = (currencyRate = DEFAULT_CURRENCY_RATE) => data =>
  data.map(item => ({
    name: item.name, // TODO: should use translated names from name_hlang
    value: Big(item.value_fen).div(currencyRate),
  }));

export function createPrice(data, currencyRate = DEFAULT_CURRENCY_RATE) {
  const price = {
    items: [],
    total: Big(0),
    couponSavings: Big(0),
  };
  const {
    price_info: priceInfo,
    price_item: priceItem,
    coupon_total: couponSavings,
  } = data;
  const { paid, refunding, refund, appeal } = priceInfo;
  let { unpaid } = priceInfo;
  // it is only required for pricing, because the previous pricing is a separately processed coupon
  if (couponSavings !== undefined) {
    unpaid = unpaid.filter(
      item => item.type !== priceRowType.PRICE_COUPON_TYPE
    );
  }
  price.items = _flatten([unpaid, paid, refunding, refund, appeal]).map(
    ({ title, amount, type, discount_amount: discountAmount }) => ({
      name: title,
      value: Big(amount).div(currencyRate),
      type,
      discount:
        discountAmount / currencyRate
          ? Big(discountAmount).div(currencyRate)
          : 0,
    })
  );
  price.total = Big(priceInfo.final_price).div(currencyRate);
  if (priceInfo.original_price) {
    price.originalPrice = Big(priceInfo.original_price).div(currencyRate);
  }
  if (couponSavings) price.couponSavings = Big(couponSavings).div(currencyRate);

  return {
    price,
    priceRaw: priceItem,
  };
}

export function createSpecialRequests(
  data,
  service,
  currencyRate = DEFAULT_CURRENCY_RATE
) {
  // eslint-disable-next-line camelcase
  const { spec_req_price_item } = data;
  const specialRequests = spec_req_price_item.reduce(
    (result, item) => ({
      ...result,
      [`${service.id}-${item.type}`]: {
        price: {
          amount: Big(item.value_fen).div(currencyRate),
        },
        name: item.name,
      },
    }),
    {}
  );
  return specialRequests;
}

export function prepareWallet(price, balance) {
  const wallet = {
    canUseWallet: price.total < balance, // need backend check
    balance,
  };
  return wallet;
}

const prepareAddressInfo = waypoints =>
  waypoints.map(waypoint => ({
    name: waypoint.name,
    lat_lon: { lat: waypoint.lat, lon: waypoint.lng },
    city_id: waypoint.cityId,
  }));

/**
 * Determines what to send the API based on what is stored in selectedCoupon & hasUserSetCoupon
 *
 * selectedOnlineCoupon and selectedCashCoupon first start out as null in the store,
 * and then are later set to be first the best coupon of its type after the price calculate calls,
 * and then subsequently as the coupon that the user manually selects
 *
 * But we need to send undefined (don't send the coupon ID at all) in some cases to the API:
 * undefined: API is being called for the first time, or user performed actions that could change the price
 * a string: the selected coupon ID
 * 0: the user explicitly deselected a coupon
 *
 * @param {number | null} paymentMethodId See the PaymentMethods enum in
 * @param {boolean} shouldResetCoupon Whether the triggering action could change the price
 * src/interfaces/global/store/modules/checkout/helpers.js
 * @return {string | 0 | undefined} undefined for initial API calls or 0 when user deselected coupon, or the selected coupon ID
 */
export function* getCouponId(paymentMethodId, shouldResetCoupon) {
  if (shouldResetCoupon) return undefined;

  if (paymentMethodId === PaymentMethods.CASH.id) {
    const { hasUserSetCashCoupon, selectedCashCoupon } = yield select(
      state => state.pricing
    );
    if (hasUserSetCashCoupon && !selectedCashCoupon) {
      return 0;
    }
    return selectedCashCoupon?.coupon_id;
  }

  if (paymentMethodId === PaymentMethods.ONLINE.id) {
    const { hasUserSetOnlineCoupon, selectedOnlineCoupon } = yield select(
      state => state.pricing
    );
    if (hasUserSetOnlineCoupon && !selectedOnlineCoupon) {
      return 0;
    }
    return selectedOnlineCoupon?.coupon_id;
  }

  return 0; // for unknown payment methods, don't apply coupons until we handle it properly
}

/**
 * Check whether reset best coupon is needed
 *
 * Only reset coupon when device is desktop and
 * 1. type is one of these action (VALIDATE_SERVICE_AREA_SUCCESS,
 *    ADD_SPECIAL_REQUEST, REMOVE_SPECIAL_REQUEST, SET_SUB_SPECIAL_REQUEST), or
 * 2. PLACE_ORDER_FAILURE for online payment
 *
 * For case 2, users selected online coupon will be eaten by the backend for a
 * while if the place order process failed, set `shouldResetCoupon` to `true` to
 * set the best coupon again
 *
 * @param {string} type action type
 * @param {number} selectedPaymentMethodId selected payment method id
 */
const checkShouldResetCoupon = (type, selectedPaymentMethodId) => {
  const isDesktop = desktopQueryList.matches;
  if (isDesktop) {
    return (
      [
        VALIDATE_SERVICE_AREA_SUCCESS,
        ADD_SPECIAL_REQUEST,
        REMOVE_SPECIAL_REQUEST,
        SET_SUB_SPECIAL_REQUEST,
      ].includes(type) ||
      (type === PLACE_ORDER_FAILURE &&
        selectedPaymentMethodId === PaymentMethods.ONLINE.id)
    );
  }
  return false;
};

export function* onFetchPrice(args = {}) {
  const { retryCount = 0, type, orderId, id: wayptId } = args;
  let id;

  const validationStatus = yield select(getAreaValidationStatus);
  if (!validationStatus) return;

  if (
    type.startsWith('ROUTE_') ||
    type === WAYPOINT_REMOVE ||
    type === VALIDATE_SERVICE_AREA_SUCCESS
  ) {
    id = orderId;
  } else if (type.startsWith('WAYPOINT_')) {
    id = yield select(getOrderIdByWayptId, wayptId);
  }

  yield put(fetchPrice());
  try {
    const waypoints = yield select(getFilledWaypts, id);
    if (waypoints.length < 2) return;
    const selectedService = yield select(getSelectedService);
    if (!selectedService) return;

    const selectedSpecialRequests = yield select(getSelectedSpecialRequests);

    // remove VEHICLE_STD from the selected special requests
    const specialReqWithoutVehicleStd = selectedSpecialRequests.filter(
      item => !item.startsWith(VEHICLE_STD_PREFIX)
    );

    // Remove the vehicle service before the special request id
    const specialReq = specialReqWithoutVehicleStd.map(req =>
      req.slice(req.indexOf('-') + 1)
    );

    const deliveryDatetime = yield select(getDeliveryDatetime);
    const checkoutDirty = yield select(getCheckoutDirty);
    let timestamp = getImmediateDeliveryUnix();
    if (checkoutDirty.deliveryDatetime) {
      timestamp = moment(deliveryDatetime).unix();
    }
    const vehicleStd = yield select(getSelectedServiceVehicleStd);
    const vehicleStdNames = vehicleStd ? [vehicleStd.name] : [];
    const vehicleStdTagIds = vehicleStd ? [vehicleStd.stdTagId] : [];

    const cityInfoRevision = yield select(getRevision);

    const addressInfo = prepareAddressInfo(waypoints);

    let sameNum = 1;
    const contact = yield select(getContact);
    const userInfo = yield select(getUser);
    if (
      contact.phone &&
      userInfo.profile.phone_no !==
        `+${userInfo.profile.country_code}${contact.phone}`
    ) {
      sameNum = 0;
    }

    const selectedPaymentMethodId = yield select(getSelectedPaymentMethodId);
    const shouldResetCoupon = checkShouldResetCoupon(
      type,
      selectedPaymentMethodId
    );

    const couponId = yield call(
      getCouponId,
      selectedPaymentMethodId,
      shouldResetCoupon
    );

    const data = yield call(calculatePrice, {
      vehicleId: selectedService.id,
      specialReq,
      latLon: addressInfo.map(address => address.lat_lon),
      orderTime: timestamp,
      vehicleStdNames,
      addressInfo,
      cityInfoRevision,
      sameNum,
      vehicleStdTagIds,
      selectedPaymentMethodId,
      couponId,
    });

    const { currencyRate } = yield select(getCurrentCountry);
    const specialRequests = createSpecialRequests(data, selectedService);
    const { price, priceRaw } = createPrice(data, currencyRate);
    const balance = yield select(getCreditBalance);
    const wallet = prepareWallet(price, balance);

    const paymentMethodIds = ALL_PAYMENT_METHOD_IDS.filter(methodId =>
      data.pay_option.some(p => p.id === methodId)
    );

    yield put({
      type: FETCH_PRICE_SUCCESS,
      price,
      priceRaw,
      wallet,
      specialRequests,
      orderTime: timestamp,
      orderCouponList: data.coupon_item,
      hasFavDriver: !!data.fleet_accessable,
      paymentMethodIds,
      selectedPaymentMethodId,
      shouldResetCoupon,
    });
  } catch (error) {
    if (error instanceof UApiError) {
      if (error.errorCode === 10012 && retryCount < 1) {
        yield refetchServicesAndCall(onFetchPrice, {
          ...args,
          retryCount: retryCount + 1,
        });
      } else {
        yield put({
          type: FETCH_PRICE_FAILURE,
          meta: { type: 'error', message: error.message },
        });
      }
    } else throw error;
  }
}

export function* onRedeemCouponRequest({ redeemCode }) {
  try {
    const { coupon_id: couponIdStr } = yield call(fetchUpdateCoupon, {
      code: redeemCode,
    });
    const redeemedCouponId = parseInt(couponIdStr, 10);

    yield put({ type: UPDATE_CHECKOUT_COUPONS });

    const { failure } = yield race({
      success: take(FETCH_PRICE_SUCCESS),
      failure: take(FETCH_PRICE_FAILURE),
    });

    if (failure) throw new UApiError('Common.internal_error');

    const coupons = yield select(getAvailableCoupons);
    const isRedeemedCouponApplicable = coupons.some(
      coupon => coupon.coupon_id === redeemedCouponId
    );

    if (isRedeemedCouponApplicable) {
      yield put({
        type: REDEEM_CHECKOUT_COUPON_SUCCESS,
        newlyRedeemedCouponId: redeemedCouponId,
        meta: {
          type: 'success',
          message: 'Coupon.coupon_added_successfully',
        },
      });
    } else {
      yield put({
        type: REDEEM_CHECKOUT_COUPON_SUCCESS,
        meta: {
          type: 'warning',
          message: 'Coupon.coupon_not_applicable',
        },
      });
    }

    yield put(track('coupon_code_tapped', { source: 'place_order' }));
    yield put(track('coupon_code_redeemed', { source: 'place_order' }));
  } catch (error) {
    if (error instanceof UApiError) {
      yield put({
        type: REDEEM_CHECKOUT_COUPON_FAILURE,
        meta: { type: 'error', message: error.message },
      });
      yield put(
        track('coupon_code_tapped', {
          source: 'place_order',
          redeem_error: redeemCouponErrorCodeMap[error.errorCode],
        })
      );
    } else throw error;
  }
}

export default function* pricingSaga() {
  const sagas = [
    ADD_SPECIAL_REQUEST,
    REMOVE_SPECIAL_REQUEST,
    SET_SUB_SPECIAL_REQUEST,
    DATE_CHANGE,
    TOPUP_SUCCESS,
    PLACE_ORDER_FAILURE,
    VALIDATE_SERVICE_AREA_SUCCESS,
    CONTACT_UPDATE,
    SET_COUPON,
    PAYMENT_METHOD_CHANGE,
    UPDATE_CHECKOUT_COUPONS,
  ];

  yield throttle(500, sagas, onFetchPrice);
  yield takeLatest(REDEEM_CHECKOUT_COUPON_REQUEST, onRedeemCouponRequest);
}
