import styled from 'styled-components';
import { Button } from '@lalamove/karang';
import { fontSize } from 'styles/fonts';
import { GROUND } from 'styles/zIndex';
import { black, darkGray, silver } from 'styles/colors';

export const Wrapper = styled.div`
  position: relative;
  padding: 1.5em 0 1.5em 2.25em;
`;

export const PhotoFrame = styled.img`
  display: block;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  object-fit: cover;
`;

export const BannedPhotoOverlay = styled.div`
  position: absolute;
  z-index: ${GROUND + 1};
  top: 0;
  width: 3em;
  height: 3em;
  border: 2px solid ${black};
  border-radius: 50%;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) calc(50% - 2px),
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0.2) calc(50% + 2px),
    rgba(0, 0, 0, 0.2) 100%
  );
`;

export const Name = styled.div`
  padding: 0.4em 0 0;
`;

export const Label = styled.span`
  display: inline-block;
  color: ${silver};
  font-size: ${fontSize.small};
  line-height: ${fontSize.small};
`;

export const AuxInfo = styled.ul`
  position: absolute;
  top: 1em;
  right: 1em;
  padding: 0;
  margin: 0;
`;

// use `as` on the component after v4
// https://www.styled-components.com/docs/api#as-polymorphic-prop
export const AuxInfoItem = styled(Label.withComponent('li'))`
  margin-left: 1em;
`;

export const DetailContent = styled.div`
  padding: 0.4em 0 0;
`;

export const DriverDetails = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  border-top: 1px ${darkGray} solid;
  margin-top: 1em;

  & > span {
    margin-right: 2em;
  }
  & > span:last-child {
    flex-grow: 1;
    margin-right: 1em;
  }

  & ${Label} {
    padding: 1em 0 0;
  }
`;

export const Text = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

export const LinkButton = styled(Button)`
  padding: 0.5em 1em;
`;

export const Separator = styled.span`
  color: ${silver};
  font-size: 0.8em;
`;

export const ConfirmDialog = styled.div`
  padding-top: 1em;
  border-top: 1px ${darkGray} solid;
  margin-top: 1em;
`;

export const Message = styled.div`
  padding-top: 0.4em;
`;

export const DialogButtons = styled.span`
  float: right;
  padding: 0.5em 1em;

  & button {
    margin-left: 2em;
  }
`;
