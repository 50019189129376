import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes, { number } from 'prop-types';
import Downshift from 'downshift';
import styled from 'styled-components';
import _every from 'lodash/every';
import _isEqual from 'lodash/isEqual';
import { getPlaceDetails } from 'api/uAPI';
import {
  getWaypointById,
  getWaypoints,
  getFilledWaypts,
  getErrorByStopItemId,
  getOrderIdByWayptId,
} from 'interfaces/global/store/modules/routing/selectors';
import {
  updateWaypt,
  updateSessionToken,
} from 'interfaces/global/store/modules/routing/actions';
import { getCurrentCity } from 'store/modules/region/selectors';
import {
  updateDeliveryInfo,
  getDeliveryInfoByWayptId,
} from 'store/modules/routing/deliveryInfo';
import { orange, gray, nobel } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { noop } from 'utils/helpers';
import { withResponsiveMedia } from 'components/MediaQuery';
import { WaypointCreationMethods } from 'interfaces/global/store/modules/routing/saga';
import AutocompleteList from '../AutocompleteList';
import { LocationShape, DeliveryInfoShape } from '../../propTypes';
import { BtnRemove, Container, Content } from './style';
import DeliveryInfoForm from '../DeliveryInfo';
import StopItemInput from '../StopItemInput';

const { string, func, bool, arrayOf, shape } = PropTypes;

function isFilled(item) {
  return (!!item.lat && !!item.lng) || !!item.placeId;
}

const StyledContactLabel = styled.div`
  color: ${nobel[500]};
  font-size: ${fontSize.small};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export class StopItem extends Component {
  static defaultProps = {
    t: noop,
    indexColor: gray,
    placeholder: 'Stop Location',
    history: [],
    deliveryInfo: null,
    onRemove: noop,
    updateWaypt: noop,
    updateDeliveryInfo: noop,
    updateSessionToken: noop,
    isRemovable: false,
    disabled: false,
    autofocus: false,
    errors: {},
    placeType: 0,
    index: 0,
  };

  static propTypes = {
    t: func,
    id: string.isRequired,
    indexColor: string,
    waypoint: LocationShape.isRequired,
    placeholder: string,
    history: arrayOf(shape({ description: string, placeId: string })),
    onRemove: func,
    isRemovable: bool,
    disabled: bool,
    updateWaypt: func,
    updateDeliveryInfo: func,
    updateSessionToken: func,
    deliveryInfo: DeliveryInfoShape,
    autofocus: bool,
    errors: shape({}),
    placeType: number,
    index: number,
    cityId: number.isRequired,
    isMobile: bool.isRequired,
    isDesktop: bool.isRequired,
  };

  state = {
    searchText: this.props.waypoint.description,
    suggestions: [],
    showDesktopDeliveryInfo: false,
    showMobileDeliveryInfo: false,
  };

  nodeRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (prevProps.waypoint !== this.props.waypoint) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ searchText: this.props.waypoint.description });
    }
  }

  componentWillUnmount() {
    clearTimeout(this._onBlurTimeout);
  }

  onBlur = ({ currentTarget, target }) => {
    clearTimeout(this._onBlurTimeout);
    // setTimeout here moves the code block to the end of the execution queue,
    // so that delivery info popover won't be hidden when delivery info form is active:
    this._onBlurTimeout = setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.hideDesktopDeliveryInfo();
      }
    });
  };

  onMobileItemClick = () => {
    if (this.props.isMobile) {
      clearTimeout(this._onBlurTimeout);
      // similar to onBlur() above, setImmediate delays the input blur to next tick
      // so the onBlur event of StopItemInput is triggered appropriately,
      // which launches DeliveryInfo modal. When modal is closed,
      // the focus will not be back to this StopItemInput again
      const focusedEl = document.activeElement;
      this._onBlurTimeout = setImmediate(() => focusedEl.blur());
    }
  };

  handleInputChange = e => {
    // https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js/28046731
    e.persist();
    const { value } = e.target;
    this.setState({ searchText: value });
    this.hideDesktopDeliveryInfo();
  };

  handleWaypointUpdate = async location => {
    let { lat, lng } = location;
    const {
      id,
      waypoint,
      deliveryInfo,
      updateWaypt, // eslint-disable-line no-shadow
      updateDeliveryInfo, // eslint-disable-line no-shadow
      cityId,
    } = this.props;

    if (_isEqual(location, waypoint)) {
      return this.setState({
        suggestions: [],
        searchText: waypoint.description,
      });
    }

    if (location.placeId && lat === 0 && lng === 0) {
      const { location: loc } = await getPlaceDetails(location.placeId);

      lat = loc.lat;
      lng = loc.lon;
    }

    updateWaypt({
      id,
      address: location.address,
      name: location.name,
      // description: location.description || location.address || 'Loading...', // TODO: proper loading style
      placeId: location.placeId,
      lat,
      lng,
      method:
        location.type === WaypointCreationMethods.history
          ? WaypointCreationMethods.history
          : WaypointCreationMethods.search,
      cityId,
    });

    function serializeAddr(addrObj) {
      return addrObj ? Object.values(addrObj).filter(Boolean).join('/') : '';
    }
    if (location.type === WaypointCreationMethods.history && location.contact) {
      updateDeliveryInfo(id, {
        ...location.contact,
        addressDetails: serializeAddr(location.addressDetails),
      });
    } else {
      // check if all fields are empty
      _every(deliveryInfo, d => !d) && this.showDesktopDeliveryInfo();
    }

    return this.setState({
      searchText: location.description || '',
      suggestions: [],
    });
  };

  handleRemove = e => {
    const { id, onRemove } = this.props;
    e.preventDefault();
    onRemove(id);
  };

  showDesktopDeliveryInfo = () =>
    this.setState({ showDesktopDeliveryInfo: true });

  hideDesktopDeliveryInfo = () =>
    this.setState({ showDesktopDeliveryInfo: false });

  showMobileDeliveryInfo = () =>
    this.setState({ showMobileDeliveryInfo: true });

  hideMobileDeliveryInfo = () =>
    this.setState({ showMobileDeliveryInfo: false });

  handleInputBlur = e => {
    const { waypoint, deliveryInfo, isMobile } = this.props;
    const { suggestions, searchText } = this.state;
    if (!e.target.value) {
      this.setState({ searchText: waypoint.description });
    } else {
      this.handleWaypointUpdate(
        // pick first in AutocompleteList
        suggestions[0] || (waypoint.name && waypoint) || { address: searchText }
      );
    }
    const { name, phone, addressDetails } = deliveryInfo;

    // mobile DeliveryInfo should popup only when there is input value but no delivery info filled
    if (isMobile && e.target.value && !name && !phone && !addressDetails) {
      this.showMobileDeliveryInfo();
    }
  };

  handleSuggestions = pois => {
    this.setState({ suggestions: pois });
  };

  hasErrors = () => {
    const { errors } = this.props;
    const errMsg = Object.keys(errors).reduce(
      (msg, error) => msg || (errors[error] && error),
      ''
    );
    return errMsg;
  };

  generateListItems(inputValue) {
    const { suggestions } = this.state;
    const history = inputValue ? [] : this.props.history;
    return [
      ...suggestions,
      ...history.map(h => ({ ...h, type: WaypointCreationMethods.history })),
    ];
  }

  renderContact() {
    const { deliveryInfo, disabled, isDesktop } = this.props;
    if (!deliveryInfo) return null;
    const { name, phone, addressDetails } = deliveryInfo;
    return (
      <StyledContactLabel
        role="button"
        disabled={disabled}
        {...(!disabled && {
          onClick: isDesktop
            ? this.showDesktopDeliveryInfo
            : this.showMobileDeliveryInfo,
        })}
      >
        {[name, phone, addressDetails].filter(Boolean).join(' | ')}
      </StyledContactLabel>
    );
  }

  render() {
    const {
      t,
      disabled,
      isRemovable,
      placeholder,
      waypoint,
      indexColor,
      autofocus,
      placeType,
      index,
      cityId,
      isDesktop,
    } = this.props;
    const { showDesktopDeliveryInfo, showMobileDeliveryInfo } = this.state;

    const isOpenForm = isDesktop
      ? showDesktopDeliveryInfo
      : showMobileDeliveryInfo;

    const onCloseForm = isDesktop
      ? this.hideDesktopDeliveryInfo
      : this.hideMobileDeliveryInfo;

    return (
      <div onBlur={this.onBlur} ref={this.nodeRef}>
        <Downshift
          inputValue={this.state.searchText}
          defaultHighlightedIndex={0}
          selectedItem={waypoint}
          onChange={this.handleWaypointUpdate}
          itemToString={item => (item ? item.description : '')}
        >
          {({
            getToggleButtonProps,
            getInputProps,
            getItemProps,
            isOpen,
            highlightedIndex,
            selectedItem,
            inputValue,
            openMenu,
            selectItemAtIndex,
          }) => (
            <div>
              <DeliveryInfoForm
                parent={this.nodeRef.current}
                isOpen={isOpenForm}
                waypointId={waypoint.id}
                indexColor={indexColor}
                onClose={onCloseForm}
              >
                {({ isDirty }) => (
                  <Container
                    warning={isDirty}
                    error={this.hasErrors()}
                    shouldShowIndex={isFilled(selectedItem)}
                    indexColor={indexColor}
                    {...(disabled && {
                      'data-tip': t('EditOrder.tooltip_origin_cannot_edit'),
                      'data-place': 'bottom',
                      'data-for': 'global',
                    })}
                  >
                    <Content disabled={disabled}>
                      <StopItemInput
                        onBlur={this.handleInputBlur}
                        item={selectedItem}
                        placeholder={placeholder}
                        onChange={this.handleInputChange}
                        onSuggestions={this.handleSuggestions}
                        onOpenDropdown={openMenu}
                        getInputProps={getInputProps}
                        getToggleButtonProps={getToggleButtonProps}
                        selectItemAtIndex={selectItemAtIndex}
                        showDesktopDeliveryInfo={this.showDesktopDeliveryInfo}
                        autofocus={autofocus}
                        disabled={disabled}
                        placeType={placeType}
                        index={index}
                        cityId={cityId}
                      />
                      {this.renderContact()}
                    </Content>
                    {isRemovable && <BtnRemove onClick={this.handleRemove} />}

                    {isOpen && (
                      <AutocompleteList
                        items={this.generateListItems(inputValue)}
                        getItemProps={getItemProps}
                        highlightedIndex={highlightedIndex}
                        onItemClick={this.onMobileItemClick}
                      />
                    )}
                  </Container>
                )}
              </DeliveryInfoForm>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

const getIndexColor = (filledWaypts, id) => {
  const index = filledWaypts.map(wp => wp.id).indexOf(id) + 1;
  const lastIndex = filledWaypts.length;
  // CAUTION - 1 based index
  if (index === 1 || index === lastIndex) return orange;
  return gray;
};

const getAutofocus = (waypts, id) => {
  const index = waypts.map(wp => wp.id).indexOf(id) + 1;
  return index !== 2; // prevent auto-focus to drop off point
};

const mapState = (state, { id }) => {
  const orderId = getOrderIdByWayptId(state, id);

  return {
    waypoint: getWaypointById(state.routing, id),
    cityId: getCurrentCity(state).cityId,
    deliveryInfo: getDeliveryInfoByWayptId(state, id),
    indexColor: getIndexColor(getFilledWaypts(state, orderId), id),
    errors: getErrorByStopItemId(state, id),
    autofocus: getAutofocus(getWaypoints(state, orderId), id),
    orderId,
  };
};

export default compose(
  withTranslation(),
  withResponsiveMedia,
  connect(mapState, {
    updateWaypt,
    updateDeliveryInfo,
    updateSessionToken,
  })
)(StopItem);
