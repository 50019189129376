export const getRecordCount = state => state.records.count;
export const getRecordHash = state => state.records.hash;
export const getOrderDetails = state => state.records.order;
export const getOrder = (state, id) => state.records.orders[id];
export const getOpenedOrderId = ({ ui: { panel } }) => {
  if (panel.id !== 'ORDER' || !panel.isOpen) return undefined;
  return panel.props.orderId;
};
export const getOrders = state =>
  state.records.ids.map(id => state.records.orders[id]);
export const getOrdersWithInfo = state =>
  state.records.ids.map(id => ({
    ...state.records.orders[id],
    waypoints: getWaypointsByOrderId(state, id),
    price: getPriceByOrderId(state, id),
    driver: getDriverById(state, state.records.orders[id].driverId),
  }));
export const getOrdersPaginationParams = state => ({
  lastRecordIDRef: state.records.lastRecordIDRef,
  endPage: state.records.endPage,
});
export const getWaypointsByOrderId = (state, orderId) =>
  state.records.routes[orderId].waypoints;
export const getPriceByOrderId = (state, orderId) =>
  state.records.prices[orderId];
export const getDriverById = (state, id) => state.records.drivers[id];

export const getRouteByOrderId = (state, orderId) => {
  if (!state.records.routes[orderId]) return {};

  const { waypoints, deliveryInfo, deliveries } = state.records.routes[orderId];

  return {
    waypoints,
    deliveryInfo,
    ...(deliveries && {
      deliveries: deliveries.map(delivery => ({
        ...delivery,
        parcels: delivery.parcels.reduce(
          (acc, parcel) => ({
            ...acc,
            [parcel.parcelStatus]: [
              ...(acc[parcel.parcelStatus] || []),
              {
                id: parcel.id,
                name: parcel.name,
                parcelStatus: parcel.parcelStatus,
                failedReason: parcel.failedReason,
              },
            ],
          }),
          {}
        ),
      })),
    }),
  };
};

export const getCancelReasons = state => state.records.cancelReasons || [];
export const getPriorityFee = state => state.records.order.priorityFee;
