import React from 'react';
import formatter from 'utils/formatter';
import styled from 'styled-components';
import CouponAppliedTagImage from 'assets/svg/coupon-applied-tag.svg';
import NoCouponAppliedTagImage from 'assets/svg/no-coupon-applied-tag.svg';
import NoCouponsAvailableTagImage from 'assets/svg/no-coupons-available-tag.svg';
import { mineShaft } from 'styles/colors';
import { useTranslation } from 'react-i18next';
import { Coupon } from 'interfaces/global/store/modules/pricing/types';

const CouponItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 67px;
  padding: 0.75em 1em;
  border: 1px solid ${mineShaft[400]};
  outline: none;
  margin-bottom: 12px;
`;

const CouponItemLeftContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  height: 35px;
`;

export const CouponItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 11px;
  color: ${mineShaft[900]};
`;

const CouponIcon = styled.img`
  width: 18px;
`;

export const CouponDescription = styled.div`
  margin-top: 4px;
  color: ${mineShaft[700]};
  font-size: 12px;
`;

const ChangeCoupon = styled.p`
  color: #f16622ff;
  font-size: 12px;
  cursor: pointer;
`;

interface CouponItemProps {
  openCouponModal: () => void;
  orderCouponList: Coupon[];
  selectedCoupon: Coupon | null;
  couponSavings: number;
}

export const CouponItem: React.FC<CouponItemProps> = ({
  openCouponModal,
  orderCouponList,
  selectedCoupon,
  couponSavings,
}) => {
  if (!!selectedCoupon && orderCouponList.length > 0)
    return (
      <SelectedOrderCoupon
        openCouponModal={openCouponModal}
        couponSavings={couponSavings}
      />
    );

  if (!selectedCoupon && orderCouponList.length > 0)
    return (
      <AvailableButNoSelectedOrderCoupon
        openCouponModal={openCouponModal}
        orderCouponList={orderCouponList}
      />
    );

  return <NoAvailableOrderCoupons openCouponModal={openCouponModal} />;
};

interface SelectOrderCouponProps {
  openCouponModal: () => void;
  couponSavings: number;
}

const SelectedOrderCoupon: React.FC<SelectOrderCouponProps> = ({
  openCouponModal,
  couponSavings,
}) => {
  const { t } = useTranslation();

  return (
    <CouponItemContainer>
      <CouponItemLeftContainer>
        <CouponIcon src={CouponAppliedTagImage} alt="Coupon Applied Tag" />
        <CouponItemInfo>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('Coupon.saving_discounted_amount', {
                discountAmount: formatter.currency(couponSavings),
              }),
            }}
          />
          <CouponDescription>{t('Coupon.coupon_applied')}</CouponDescription>
        </CouponItemInfo>
      </CouponItemLeftContainer>
      <ChangeCoupon onClick={openCouponModal}>
        {t('Coupon.edit_coupon')}
      </ChangeCoupon>
    </CouponItemContainer>
  );
};

interface AvailableButNoSelectedOrderCouponProps {
  openCouponModal: () => void;
  orderCouponList: Coupon[];
}

const AvailableButNoSelectedOrderCoupon: React.FC<AvailableButNoSelectedOrderCouponProps> = ({
  openCouponModal,
  orderCouponList,
}) => {
  const { t } = useTranslation();

  return (
    <CouponItemContainer>
      <CouponItemLeftContainer>
        <CouponIcon src={NoCouponAppliedTagImage} alt="Available Coupon Tag" />
        <CouponItemInfo>
          {t('Coupon.no_coupons_applied')}
          <CouponDescription>
            {t('Coupon.coupons_available', { amount: orderCouponList.length })}
          </CouponDescription>
        </CouponItemInfo>
      </CouponItemLeftContainer>
      <ChangeCoupon onClick={openCouponModal}>
        {t('Coupon.apply_coupon')}
      </ChangeCoupon>
    </CouponItemContainer>
  );
};

interface NoAvailableOrderCouponsProps {
  openCouponModal: () => void;
}

const NoAvailableOrderCoupons: React.FC<NoAvailableOrderCouponsProps> = ({
  openCouponModal,
}) => {
  const { t } = useTranslation();

  return (
    <CouponItemContainer>
      <CouponItemLeftContainer>
        <CouponIcon
          src={NoCouponsAvailableTagImage}
          alt="No Available Coupons"
        />
        <CouponItemInfo>
          <ChangeCoupon onClick={openCouponModal}>
            {t('Coupon.add_coupon')}
          </ChangeCoupon>
        </CouponItemInfo>
      </CouponItemLeftContainer>
    </CouponItemContainer>
  );
};

export default CouponItem;
