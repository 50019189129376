export const FETCH_PRICE_REQUEST = 'FETCH_PRICE_REQUEST';
export const FETCH_PRICE_SUCCESS = 'FETCH_PRICE_SUCCESS';
export const FETCH_PRICE_FAILURE = 'FETCH_PRICE_FAILURE';
export const SET_COUPON = 'SET_COUPON';
export const DISABLE_ONLINE_PAYMENT_COUPON_TOOLTIP =
  'DISABLE_ONLINE_PAYMENT_COUPON_TOOLTIP';
export const REDEEM_CHECKOUT_COUPON_REQUEST = 'REDEEM_CHECKOUT_COUPON_REQUEST';
export const REDEEM_CHECKOUT_COUPON_SUCCESS = 'REDEEM_CHECKOUT_COUPON_SUCCESS';
export const REDEEM_CHECKOUT_COUPON_FAILURE = 'REDEEM_CHECKOUT_COUPON_FAILURE';
export const UPDATE_CHECKOUT_COUPONS = 'UPDATE_CHECKOUT_COUPONS';
export const CLEAR_REDEEM_COUPON_HISTORY = 'CLEAR_REDEEM_COUPON_HISTORY';

export const fetchPrice = () => ({
  type: FETCH_PRICE_REQUEST,
});

export const setSelectedCoupon = ({ selectedPaymentMethodId, coupon }) => ({
  type: SET_COUPON,
  selectedPaymentMethodId,
  coupon,
});

export const disableOnlineCouponTooltip = () => ({
  type: DISABLE_ONLINE_PAYMENT_COUPON_TOOLTIP,
});

export const redeemCheckoutCouponRequest = redeemCode => ({
  type: REDEEM_CHECKOUT_COUPON_REQUEST,
  redeemCode,
});

export const clearRedeemCouponHistory = () => ({
  type: CLEAR_REDEEM_COUPON_HISTORY,
});
