import { getUser } from 'store/modules/auth/selectors';
import {
  getCurrentLocation,
  getCurrentCountryCode,
  getCurrentLocale,
} from 'store/modules/region/selectors';

class Segment {
  store;

  bindStore(store) {
    this.store = store;
  }

  createTrack(name, properties = {}) {
    if (!window.analytics) return null;
    const state = this.store.getState();
    const { client_id: clientId } = getUser(state);
    const country = getCurrentCountryCode(state);
    const locale = getCurrentLocale(state);

    return window.analytics.track(name, {
      ...properties,
      city: getCurrentLocation(state),
      country,
      language: locale,
      llm_source: 'webapp',
      trigger_time: new Date().toISOString(),
      client_id: clientId || '',
    });
  }
}

export default new Segment();
