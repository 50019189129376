import drivers from './reducer';
import saga from './saga';
import * as actions from './actions';
import * as selectors from './selectors';

const driversModule = {
  id: 'drivers',
  reducerMap: { drivers },
  sagas: [saga],
};

export { driversModule as default, saga, actions, selectors };
