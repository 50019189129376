/* eslint-disable lines-between-class-members */
import MonetaryAmount from './MonetaryAmount';

// interface PaymentBreakdownStruct extends MonetaryAmount {
//   type: string;
//   name: string;
// }

// interface PaymentSummaryStruct extends MonetaryAmount {}

export class PaymentBreakdown extends MonetaryAmount {
  type = '';
  name = '';

  constructor(pb = {}) {
    super(pb);
    Object.assign(this, pb);
  }

  static New(pb) {
    return new PaymentBreakdown(pb);
  }
}

export class PaymentSummary extends MonetaryAmount {}
