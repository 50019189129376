import { toLLMUpper } from 'utils/locale';

/* Code adapted from Facebook Docs: https://developers.facebook.com/docs/facebook-login/web */

const {
  REACT_APP_FACEBOOK_APPID_SEA,
  REACT_APP_FACEBOOK_APPID_IND,
  REACT_APP_FACEBOOK_APPID_US,
} = process.env;

let FBAPI;

function promisifyLogin(fn) {
  return (...args) =>
    new Promise((resolve, reject) => {
      fn(resolve, ...args);
    });
}

function promisifyApi(fn, callback) {
  return (...args) =>
    new Promise((resolve, reject) => {
      fn(...args, resolve);
    });
}

function initFB(country) {
  const sdk = FBAPI || window.FB;

  const dedicatedAppIds = {
    IN: REACT_APP_FACEBOOK_APPID_IND,
    US: REACT_APP_FACEBOOK_APPID_US,
  };

  return sdk.init({
    appId: dedicatedAppIds[country] || REACT_APP_FACEBOOK_APPID_SEA,
    cookie: true, // enable cookies to allow the server to access the session
    xfbml: false, // parse social plugins on this page
    version: 'v2.12',
    status: true,
  });
}

export default function createClient(country, lang) {
  // Facebook locale codes: https://developers.facebook.com/docs/accountkit/languages/
  const locale = toLLMUpper(lang);
  return new Promise(resolve => {
    window.fbAsyncInit = () => {
      FBAPI = window.FB;
      initFB(country);
      FBAPI.login = promisifyLogin(window.FB.login);
      FBAPI.api = promisifyApi(window.FB.api);
      FBAPI.getLoginStatus = promisifyLogin(window.FB.getLoginStatus);
      resolve(FBAPI);
      delete window.fbAsyncInit;
      delete window.FB;
    };

    const d = document;
    const s = 'script';
    const id = 'facebook-jssdk';
    let js = d.getElementsByTagName(s)[0];
    const fjs = d.getElementsByTagName(s)[0];
    js = d.createElement(s);
    js.id = id;
    js.src = `https://connect.facebook.net/${locale}/sdk.js`;
    fjs.parentNode.insertBefore(js, fjs);
  });
}
