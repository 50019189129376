import moment from 'moment';
import {
  MAX_RANGE_LIMIT_DAYS,
  PAST_DATE_LIMIT_DAYS,
  DATE_DATA_FORMAT,
  STATEMENT,
} from './config';

/* eslint-disable import/prefer-default-export */
export const validateDates = (startStr, endStr, type) => {
  const startDate = moment(startStr, DATE_DATA_FORMAT, true);
  const endDate = moment(endStr, DATE_DATA_FORMAT, true);
  const earliestAllowedDate = moment().subtract(PAST_DATE_LIMIT_DAYS, 'days');
  const latestAllowedDate = moment();
  if (type === STATEMENT) latestAllowedDate.subtract(1, 'days');
  return (
    startDate.isValid() &&
    endDate.isValid() &&
    !startDate.isAfter(endDate, 'day') &&
    !startDate.isAfter(latestAllowedDate, 'day') &&
    !endDate.isAfter(latestAllowedDate, 'day') &&
    !endDate.isAfter(
      startDate.clone().add(MAX_RANGE_LIMIT_DAYS, 'days'),
      'day'
    ) &&
    startDate.isAfter(earliestAllowedDate) &&
    endDate.isAfter(earliestAllowedDate)
  );
};
