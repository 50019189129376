import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Heading } from '@lalamove/karang';
import {
  assigning as AssigningIcon,
  cancelled as CancelledIcon,
  completed as CompletedIcon,
  defaultStatus as DefaultIcon,
} from '@lalamove/karang/dist/components/Icon/icons';
import {
  mineShaft,
  mountainMeadow,
  nobel,
  pictonBlue,
  valencia,
} from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { deliveryStatus, noop } from 'utils/helpers';

const {
  READY_FOR_PICKUP,
  PICK_UP_DONE,
  PICK_UP_FAILED,
  DROP_OFF_DONE,
  DROP_OFF_FAILED,
} = deliveryStatus;

const labelMap = {
  [READY_FOR_PICKUP]: 'Deliveries.label_status_ready_for_pickup',
  [PICK_UP_DONE]: 'Deliveries.label_status_pick_up_done',
  [PICK_UP_FAILED]: 'Deliveries.label_status_pick_up_failed',
  [DROP_OFF_DONE]: 'Deliveries.label_status_drop_off_done',
  [DROP_OFF_FAILED]: 'Deliveries.label_status_drop_off_failed',
};

const iconMap = {
  [READY_FOR_PICKUP]: DefaultIcon,
  [PICK_UP_DONE]: AssigningIcon,
  [PICK_UP_FAILED]: CancelledIcon,
  [DROP_OFF_DONE]: CompletedIcon,
  [DROP_OFF_FAILED]: CancelledIcon,
};

const colorMap = {
  [READY_FOR_PICKUP]: nobel['500'],
  [PICK_UP_DONE]: pictonBlue['800'],
  [PICK_UP_FAILED]: valencia['500'],
  [DROP_OFF_DONE]: mountainMeadow['500'],
  [DROP_OFF_FAILED]: valencia['500'],
};

const Container = styled.div`
  margin: 1em 0;
`;

const Title = styled(Heading)`
  && {
    color: ${({ failure }) => (failure ? valencia.main : mineShaft['500'])};
    font-size: ${fontSize.micro};
  }
`;

const Item = styled.div`
  display: flex;
  padding: 0.5em 0.75em;
  margin: 2px 0;
  background-color: ${({ failure }) =>
    failure ? valencia['050'] : mineShaft['100']};
  border-radius: 0.5em;
  font-size: ${fontSize.small};
`;

const ItemText = styled.div`
  flex: 1;
  margin: auto;

  /* failed reason: */
  > div:not(:first-child) {
    margin-top: 4px;
    color: ${valencia.main};
  }
`;

const ParcelList = ({ status, list, t }) => {
  const failure = [PICK_UP_FAILED, DROP_OFF_FAILED].includes(status);
  const Icon = iconMap[status];
  return (
    <Container>
      <Title failure={failure}>{t(labelMap[status])}</Title>
      {list
        .sort((a, b) => a.failedReason.localeCompare(b.failedReason))
        .map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Item failure={failure} key={`${i}-${item.id}`}>
            <ItemText>
              <div>{`${t('Deliveries.label_track_id')} #${item.id}`}</div>
              {item.failedReason && <div>{item.failedReason}</div>}
            </ItemText>
            <Icon color={colorMap[status]} size={24} />
          </Item>
        ))}
    </Container>
  );
};

ParcelList.defaultProps = {
  status: READY_FOR_PICKUP,
  list: [],
  t: noop,
};

ParcelList.propTypes = {
  status: string,
  list: arrayOf(
    shape({
      id: string,
      name: string,
      parcelStatus: string,
      failedReason: string,
    })
  ),
  t: func,
};

export default withTranslation()(ParcelList);
