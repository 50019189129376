let isFirstMessage = true;

export default function openGenesysWebChat(
  isScriptLoaded,
  config,
  openWidget,
  closeWidget,
  track
) {
  if (!isScriptLoaded) {
    window.widgetLoad = window.CXBus.loadPlugin('widgets-core');
    window.widgetLoad.done(() => {
      window.customPlugin = window.CXBus.registerPlugin('Custom');

      window.customPlugin.command('WebChat.open', config);
      openWidget();

      // Web chat Service closed
      window.customPlugin.subscribe('WebChat.closed', e => {
        closeWidget();
      });

      // When a message is "added" to the chat conversation
      window.customPlugin.subscribe(
        'WebChat.messageAdded',
        ({
          data: {
            message: { type, restoring },
          },
        }) => {
          // restoring: when true indicates messages previously sent are being restored in the chat app
          //   Example: if you start a chat session, send messages, but do not end the session, refresh the web browser,
          //   and reopen the chat widget, it will restore your earlier conversation messages
          // type: source from which the message is coming from. "Client" is the user, "External" is the service itself,
          //   "Agent" is customer service sending the message to the user
          //   Example: "External" sends the message "Chat Started" when the widget is opened
          // https://developer.mypurecloud.com/forum/t/web-chat-v2-questions/7705/5
          if (type === 'Client') {
            if (!restoring)
              track('cs_chat_submitted', { is_first_msg: isFirstMessage });

            isFirstMessage = false;
          }
        }
      );
    });
  } else {
    window.customPlugin.command('WebChat.open', config);
    openWidget();
  }
}
