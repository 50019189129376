import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bool, func, string, number, shape, arrayOf } from 'prop-types';
import { question as QuestionIcon } from '@lalamove/karang/dist/components/Icon/icons';

import {
  cancelOrder,
  fetchCancelReasons,
} from 'interfaces/global/store/modules/records/actions';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { createLoadingSelector } from 'store/modules/loading';
import { getCancelReasons } from 'interfaces/global/store/modules/records/selectors';
import Heading from 'components/SliderPanel/Heading';
import SliderPanelFooter from 'components/SliderPanelFooter';

import { globalOrderStatusMap, noop, trackingStatusMap } from 'utils/helpers';

import { withResponsiveMedia } from 'components/MediaQuery';
import { Container, Content, Wrapper } from './style';
import CancelReasons from './components/CancelReasons';

const cancelReasonsCategoryMap = {
  1001: 'platform_dependent',
  1002: 'related_to_driver',
  1003: 'own_reason',
  1004: 'other',
};

export class Cancel extends Component {
  static defaultProps = {
    id: null,
    refId: null,
    status: null,
    cancelOrder: noop,
    onClose: noop,
    onBack: noop,
    fetchCancelReasons: noop,
    isLoading: false,
    track: noop,
  };

  static propTypes = {
    t: func.isRequired,
    id: string,
    refId: string,
    status: number,
    isLoading: bool,
    cancelOrder: func,
    onBack: func,
    onClose: func,
    fetchCancelReasons: func,
    cancelReasons: arrayOf(
      shape({
        id: number,
        name: string,
        sub_reason: arrayOf(shape({ id: number, sub_name: string })),
      })
    ).isRequired,
    isMobile: bool.isRequired,
    track: func,
  };

  state = {
    selected: null,
  };

  componentDidMount() {
    const {
      fetchCancelReasons: onfetchCancelReasons,
      cancelReasons,
    } = this.props;

    if (!cancelReasons || !cancelReasons.length) {
      onfetchCancelReasons();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.props.onClose();
    }
  }

  onReasonChange = value => {
    this.setState({ selected: value });
  };

  getCancelReasonCateogoryId = subReasonName => {
    const { cancelReasons } = this.props;
    return cancelReasons.find(r =>
      r.sub_reason.some(sr => sr.sub_name === subReasonName)
    )?.id;
  };

  getParentStatusId = childStatus =>
    Object.values(globalOrderStatusMap).find(
      status => status.id === childStatus
    )?.parentID;

  cancelOrder = () => {
    const { selected: subReasonName } = this.state;
    const { status, id, refId } = this.props;
    const reasonId = this.getReasonId(subReasonName);
    this.props.cancelOrder(id, subReasonName, status, reasonId);

    const categoryId = this.getCancelReasonCateogoryId(subReasonName);
    const parentStatusId = this.getParentStatusId(status);
    this.props.track('order_cancelled', {
      cancellation_reason: cancelReasonsCategoryMap[categoryId] || 'unknown',
      order_id: refId,
      order_status: trackingStatusMap[parentStatusId],
    });
  };

  getReasonId = reason => {
    const { cancelReasons } = this.props;
    const reasonItem = cancelReasons
      .map(item => item.sub_reason)
      .flat(1)
      .find(i => i.sub_name === reason);
    return reasonItem ? reasonItem.id : null;
  };

  render() {
    const { selected } = this.state;
    const {
      t,
      id,
      // status,
      onBack,
      onClose,
      isLoading,
      cancelReasons,
      isMobile,
    } = this.props;

    if (!id) return null;

    const selectedOtherReason = [
      'OTHER_BEFORE_MATCH',
      'OTHER_AFTER_MATCH',
    ].includes(selected);

    return (
      <Container>
        <Content>
          <Heading
            icon={<QuestionIcon size={24} />}
            title={t('RecordPanel.title_cancel_order')}
          />
          <Wrapper>
            <CancelReasons
              cancelReasons={cancelReasons}
              selectedText={selected}
              onChange={this.onReasonChange}
            />
          </Wrapper>
        </Content>
        <SliderPanelFooter
          primaryAction={this.cancelOrder}
          primaryButtonText={t('RecordPanel.button_cancel_order')}
          isLoadingPrimaryButton={isLoading}
          disablePrimaryButton={
            isLoading ||
            !selected ||
            !cancelReasons.length ||
            selectedOtherReason
          }
          secondaryAction={isMobile ? onBack : undefined}
          secondaryButtonText={
            isMobile ? t('RecordPanel.button_close_panel') : undefined
          }
          closeAction={isMobile ? undefined : onClose}
          closeButtonText={
            isMobile ? undefined : t('RecordPanel.button_close_panel')
          }
        />
      </Container>
    );
  }
}

const mapState = state => ({
  isLoading: createLoadingSelector(['CANCEL_ORDER'])(state),
  cancelReasons: getCancelReasons(state),
});

export default compose(
  withTranslation(),
  withResponsiveMedia,
  connect(mapState, { cancelOrder, fetchCancelReasons, track })
)(Cancel);
