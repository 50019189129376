class Storage {
  constructor() {
    this._tempStorage = {};
    // for debug use
    window._tempStorage = this._tempStorage; // eslint-disable-line no-underscore-dangle
  }

  static isSupported() {
    try {
      const TEST_KEY = 'LLM_TEST_KEY';
      window.localStorage.setItem(TEST_KEY, '');
      window.localStorage.removeItem(TEST_KEY);
      return true;
    } catch (e) {
      return false;
    }
  }

  key(index) {
    if (Storage.isSupported()) {
      return window.localStorage.key(index);
    }
    return Object.keys(this._tempStorage)[index];
  }

  getItem(name) {
    if (Storage.isSupported()) {
      return window.localStorage.getItem(name);
    }
    return this._tempStorage[name] || null;
  }

  setItem(name, value = '') {
    if (Storage.isSupported()) {
      window.localStorage.setItem(name, value);
    } else {
      this._tempStorage[name] = String(value);
    }
  }

  removeItem(name) {
    if (Storage.isSupported()) {
      window.localStorage.removeItem(name);
    } else {
      delete this._tempStorage[name];
    }
  }

  clear() {
    if (Storage.isSupported()) {
      window.localStorage.clear();
    } else {
      this._tempStorage = {};
    }
  }
}

export default new Storage();
