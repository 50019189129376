import { getFilledWaypts } from 'store/modules/routing';
import { WAYPOINT_UPDATE } from 'store/modules/routing/actionTypes';
import { DRAFT_ORDER_ID } from 'store/modules/routing/config';
import { getPrice } from 'store/modules/pricing';
import {
  getSpecialRequest,
  getSelectedSubRequestIdByRequestId,
  getSelectedServiceAndSubServiceIds,
} from 'store/modules/services';
import {
  SET_SERVICE,
  ADD_SPECIAL_REQUEST,
  SET_SUB_REQUEST,
  REMOVE_SPECIAL_REQUEST,
} from 'store/modules/services/actionTypes';
import Segment from 'utils/segment';

export const SEGMENT_SET_SERVICE = 'Vehicle Selected';
export const SEGMENT_SELECT_ADDRESS = 'Address Selected';
export const SEGMENT_ADD_SPECIAL_REQUEST = 'Additional Service Added';
export const SEGMENT_REMOVE_SPECIAL_REQUEST = 'Additional Service Removed';
export const SEGMENT_ADD_WAYPOINT = 'Add Destination Tapped';
export const SEGMENT_DELIVERY_INFO_UPDATE = 'Delivery Info Updated';
export const SEGMENT_CLICK_OPTIMIZE_ROUTE = 'Optimize Route Tapped';
export const SEGMENT_CLICK_MORE_SERVICES = 'More Services Tapped';
export const SEGMENT_CLICK_SPECIAL_REQUEST_INFO =
  'Additional Service Info Tapped';
export const SEGMENT_CLICK_PRICE_BREAKDOWN = 'Price Breakdown Tapped';
export const SEGMENT_DOWNLOAD_CSV_TEMPLATE = 'Download CSV Tapped';
export const SEGMENT_UPLOAD_CSV = 'Upload CSV Tapped';

const placeOrderTracking = (prevState, action, nextState) => {
  const { length } = getFilledWaypts(nextState, DRAFT_ORDER_ID);
  switch (action.type) {
    case SET_SERVICE: {
      const { source } = action;
      if (!source || length > 1) return;
      const { total } = getPrice(prevState);
      const { serviceId, subServiceId } = getSelectedServiceAndSubServiceIds(
        nextState
      );
      Segment.createTrack(SEGMENT_SET_SERVICE, {
        total_price_shown: total,
        vehicle_type: serviceId,
        vehicle_subtype: subServiceId,
        source,
      });
      break;
    }
    case WAYPOINT_UPDATE: {
      const isEditOrder = window.location.pathname.match(/\/orders\/\d+\/edit/);
      const { method } = action;
      if (!isEditOrder && method && length < 2) {
        const methodMap = {
          suggestion: 'input',
          history: 'recent',
          import: 'import_address',
        };
        const { total } = getPrice(prevState);
        const { serviceId, subServiceId } = getSelectedServiceAndSubServiceIds(
          prevState
        );
        Segment.createTrack(SEGMENT_SELECT_ADDRESS, {
          method: methodMap[method] || method,
          total_price_shown: total,
          vehicle_type: serviceId,
          vehicle_subtype: subServiceId,
        });
      }
      break;
    }
    case ADD_SPECIAL_REQUEST:
    case SET_SUB_REQUEST: {
      const isCloneOrder = window.location.pathname.match(/\/orders/);
      const { id } = action;
      if (isCloneOrder || length > 1) return;
      const { serviceId, subServiceId } = getSelectedServiceAndSubServiceIds(
        prevState
      );
      const { total } = getPrice(prevState);
      Segment.createTrack(SEGMENT_ADD_SPECIAL_REQUEST, {
        addon_service_type: getSpecialRequest(prevState, id).trimId,
        addon_subservice_type: getSelectedSubRequestIdByRequestId(
          nextState,
          id
        ),
        vehicle_type: serviceId,
        vehicle_subtype: subServiceId,
        total_price_shown: total,
      });
      break;
    }
    case REMOVE_SPECIAL_REQUEST: {
      if (length > 1) return;
      const { id } = action;
      const { serviceId, subServiceId } = getSelectedServiceAndSubServiceIds(
        prevState
      );
      const { total } = getPrice(prevState);
      Segment.createTrack(SEGMENT_REMOVE_SPECIAL_REQUEST, {
        addon_service_type: getSpecialRequest(prevState, id).trimId,
        addon_subservice_type: getSelectedSubRequestIdByRequestId(
          prevState,
          id
        ),
        vehicle_type: serviceId,
        vehicle_subtype: subServiceId,
        total_price_shown: total,
      });
      break;
    }
    default:
      break;
  }
};

export default placeOrderTracking;
