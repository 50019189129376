import { refreshLoginSession } from 'store/modules/auth/actions';
import authSaga from './sagas';
import auth from './reducer';

const authModule = {
  id: 'auth',
  reducerMap: { auth },
  sagas: [authSaga],
  initialActions: [refreshLoginSession()],
};

export default authModule;
