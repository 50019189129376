import React, { Component } from 'react';
import Big from 'big.js';
import {
  getPrice,
  getSelectedCashCoupon,
  getSelectedOnlineCoupon,
} from 'interfaces/global/store/modules/pricing/selectors';
import { getPricingError } from 'store/modules/pricing';
import { getRouteErrors } from 'store/modules/routing';
import { getServiceAreaError } from 'interfaces/global/store/modules/routing/selectors';
import { bool, func, shape, number } from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import ClickArea from 'components/ClickArea';
import Popover from 'components/Popover';
import { whenMobile, withResponsiveMedia } from 'components/MediaQuery';
import { Button, Heading } from '@lalamove/karang';
import { info as InfoIcon } from '@lalamove/karang/dist/components/Icon/icons';
import _every from 'lodash/every';
import _pick from 'lodash/pick';
import _some from 'lodash/some';
import formatter from 'utils/formatter';
import { noop, priceRowType } from 'utils/helpers';
import { gray, lightGray } from 'styles/colors';
import { fontSize } from 'styles/fonts';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import {
  getCheckoutStep,
  getCheckoutErrors,
  getSelectedPaymentMethodId,
} from 'interfaces/global/store/modules/checkout/selectors';
import { getSelectedService } from 'interfaces/global/store/modules/services/selectors';
import { PriceBreakDownShape } from 'interfaces/global/views/Records/propTypes';
import { createLoadingSelector } from 'store/modules/loading';
import {
  PaymentMethods,
  CheckoutSteps,
} from 'interfaces/global/store/modules/checkout/types';

import CrossFade from 'components/CrossFade';

const Container = styled.div`
  padding: 8px;
  background: #fffaf7;
  ${whenMobile} {
    position: sticky;
    bottom: 0;
    padding: 24px 16px;
  }
`;

const Price = styled.div`
  padding: 4px;
  color: #303030;
  font-weight: bold;
  text-align: right;
`;

const Amount = styled.span`
  margin: 0 6px;
  font-size: 2.6em;
  line-height: 0.8em;
`;
const OriginAmount = styled.span`
  margin: 0 6px;
  font-size: 12px;
  text-decoration: line-through;
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

// TODO: move price breakdown to its own component, for FOX-1780
const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
  color: ${gray};
  font-size: 1em;
`;

const PriceLabel = styled.span`
  margin-right: 0.5em;
`;

const PriceValue = styled.span`
  margin-left: 0.5em;
`;

const PriceDiscount = styled.span`
  margin-right: 0.5em;
  color: ${lightGray};
  font-size: 0.8em;
  text-decoration-line: line-through;
`;

export const ActionButton = styled(Button)`
  margin: auto 0.5rem;
  font-size: ${fontSize.regular};
  transition: 0.2s opacity;
`;

const matchPaths = ['/', '/confirmation'];

export class FooterClass extends Component {
  static defaultProps = {
    t: noop,
    price: {},
    onBack: noop,
    onNext: noop,
    onSubmit: noop,
    isLoading: false,
    isFetchingPrice: false,
    selectedPaymentMethodId: PaymentMethods.ONLINE.id,
    hasRoutingErrors: false,
    hasServiceAreaError: false,
    hasPricingErrors: false,
    hasCheckoutErrors: false,
    track: noop,
    selectedService: {},
    location: {},
    selectedCashCoupon: null,
    selectedOnlineCoupon: null,
  };

  static propTypes = {
    price: PriceBreakDownShape,
    t: func,
    onBack: func,
    onNext: func,
    onSubmit: func,
    isLoading: bool,
    isFetchingPrice: bool,
    selectedPaymentMethodId: number,
    hasRoutingErrors: bool,
    hasServiceAreaError: bool,
    hasPricingErrors: bool,
    hasCheckoutErrors: bool,
    track: func,
    selectedService: shape({}),
    location: shape({}),
    isMobile: bool.isRequired,
    isSelectingVehicle: bool.isRequired,
    selectedCashCoupon: shape({}),
    selectedOnlineCoupon: shape({}),
  };

  state = {
    shownBreakDown: false,
  };

  togglePriceBreakdown = () => {
    // eslint-disable-next-line no-shadow
    const { track, selectedService, price, location } = this.props;

    ReactTooltip.hide();

    if (!this.state.shownBreakDown) {
      track('price_breakdown_tapped', {
        source:
          location.pathname === matchPaths[0] ? 'place_order' : 'checkout',
        vehicle_type: selectedService.name,
        order_amount: price.total.toNumber(),
      });
    }

    this.setState(prevState => ({ shownBreakDown: !prevState.shownBreakDown }));
  };

  handleNextClick = e => this.props.onNext(e.currentTarget.name);

  renderBreakdownDetails = () => {
    const { price, t } = this.props;
    if (!price.items || !price.items.length) {
      return null;
    }
    return price.items
      .filter(item => item.type !== priceRowType.PRICE_IGNORE_TYPE)
      .map(({ name, value, discount, type }) => (
        <PriceRow key={`row.${name}`}>
          <PriceLabel key={`label.${name}`}>{t(name)}</PriceLabel>
          <PriceValue key={`value.${name}`}>
            {discount > 0 && (
              <PriceDiscount>{formatter.currency(value)}</PriceDiscount>
            )}
            {formatter.currency(Big(value).minus(discount))}
          </PriceValue>
        </PriceRow>
      ));
  };

  getPaymentMethodSelectedCoupon = () => {
    const {
      selectedPaymentMethodId,
      selectedCashCoupon,
      selectedOnlineCoupon,
    } = this.props;
    if (selectedPaymentMethodId === PaymentMethods.CASH.id) {
      return selectedCashCoupon;
    }

    return selectedOnlineCoupon;
  };

  isShowPrice = errors => {
    const { isMobile, price, isSelectingVehicle } = this.props;
    const isErrorFree = _every(
      _pick(errors, ['routing', 'pricing']),
      error => !error
    );
    if (isMobile) {
      const hasPrice = price.total > 0 || price.items.length > 0;
      return isErrorFree && !isSelectingVehicle && hasPrice;
    }
    return isErrorFree;
  };

  isCashPaymentInMobile = () => {
    const { selectedPaymentMethodId, isMobile } = this.props;
    if (!isMobile) {
      return true;
    }
    return selectedPaymentMethodId === PaymentMethods.CASH.id && isMobile;
  };

  render() {
    const {
      price,
      t,
      onBack,
      onSubmit,
      isLoading,
      isFetchingPrice,
      selectedPaymentMethodId,
      isMobile,
      hasRoutingErrors,
      hasServiceAreaError,
      hasPricingErrors,
      hasCheckoutErrors,
      isSelectingVehicle,
    } = this.props;
    const { shownBreakDown } = this.state;
    const errors = {
      routing: hasRoutingErrors || hasServiceAreaError,
      pricing: hasPricingErrors,
      checkout: hasCheckoutErrors,
    };
    const selectedCoupon = this.getPaymentMethodSelectedCoupon();
    const infoIcon = (
      <ClickArea
        onClick={this.togglePriceBreakdown}
        data-tip={t('PlaceOrder.tip_show_breakdown')}
        data-place="top"
        data-effect="solid"
        data-for="global"
      >
        <InfoIcon />
      </ClickArea>
    );

    const priceBreakDown = (
      <div>
        <Heading htmlTag="h3">{t('PlaceOrder.price_breakdown')}</Heading>
        {this.renderBreakdownDetails()}
        {selectedCoupon && (
          <PriceRow>
            <PriceLabel>{t('Coupon.coupon_price')}</PriceLabel>
            <PriceValue>-{formatter.currency(price.couponSavings)}</PriceValue>
          </PriceRow>
        )}
      </div>
    );

    const homeButtonGroup = (
      <ButtonGroup>
        <ActionButton
          disabled={errors.routing || errors.pricing || isLoading}
          name="scheduled"
          variant="secondary"
          size="large"
          block
          onClick={this.handleNextClick}
          solid
        >
          {t('PlaceOrder.advance_order')}
        </ActionButton>
        <ActionButton
          disabled={errors.routing || errors.pricing || isLoading}
          name="immediate"
          variant="primary"
          size="large"
          block
          onClick={this.handleNextClick}
          solid
          data-cy="footer-deliver-now-button"
        >
          {t('PlaceOrder.deliver_now')}
        </ActionButton>
      </ButtonGroup>
    );

    const continueButtonGroup = (
      <ButtonGroup>
        <ActionButton
          disabled={isLoading}
          name="continue"
          variant="primary"
          size="large"
          block
          onClick={this.handleNextClick}
          solid
        >
          {t('PlaceOrder.select_route_continue')}
        </ActionButton>
      </ButtonGroup>
    );

    const confirmationButtonGroup = (
      <ButtonGroup>
        {!isMobile && (
          <ActionButton
            onClick={onBack}
            variant="primary"
            size="large"
            block
            disabled={isLoading}
          >
            {t('Checkout.button_back')}
          </ActionButton>
        )}
        <ActionButton
          disabled={
            _some(errors) ||
            isLoading ||
            isFetchingPrice ||
            !this.isCashPaymentInMobile()
          }
          isLoading={isLoading}
          onClick={onSubmit}
          variant="primary"
          size="large"
          block
          solid
          data-cy="footer-continue-button"
        >
          {selectedPaymentMethodId === PaymentMethods.ONLINE.id
            ? t('Checkout.button_continue_payment')
            : t('Checkout.button_place_order')}
        </ActionButton>
      </ButtonGroup>
    );

    return (
      <Container>
        {this.isShowPrice(errors) && (
          <CrossFade>
            <Price key={`${price.originalPrice} - ${price.couponSavings}`}>
              {selectedCoupon && (
                <OriginAmount>
                  {formatter.currency(price.originalPrice)}
                </OriginAmount>
              )}
              <Amount>{formatter.currency(price.total)}</Amount>
              <Popover
                isOpen={shownBreakDown}
                preferPlace="above"
                target={infoIcon}
                onOuterAction={this.togglePriceBreakdown}
                body={priceBreakDown}
              >
                {infoIcon}
              </Popover>
            </Price>
          </CrossFade>
        )}
        <Route
          exact
          path={matchPaths[0]}
          render={() =>
            isSelectingVehicle && isMobile
              ? continueButtonGroup
              : homeButtonGroup
          }
        />
        <Route
          exact
          path={matchPaths[1]}
          render={() => confirmationButtonGroup}
        />
      </Container>
    );
  }
}

const mapState = state => ({
  selectedService: getSelectedService(state),
  isFetchingPrice: createLoadingSelector(['FETCH_PRICE'])(state),
  isSelectingVehicle: getCheckoutStep(state) === CheckoutSteps.SELECT_VEHICLE,
  // errors
  hasRoutingErrors: _some(getRouteErrors(state), errorCollection =>
    _some(errorCollection)
  ),
  hasServiceAreaError: getServiceAreaError(state),
  hasPricingErrors: !!getPricingError(state),
  hasCheckoutErrors: _some(getCheckoutErrors(state)),
  price: getPrice(state),
  selectedPaymentMethodId: getSelectedPaymentMethodId(state),
  selectedCashCoupon: getSelectedCashCoupon(state),
  selectedOnlineCoupon: getSelectedOnlineCoupon(state),
});

export default compose(
  withTranslation(),
  withRouter,
  withResponsiveMedia,
  connect(mapState, {
    track,
  })
)(FooterClass);
