import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { closeDialog } from 'store/modules/ui';
import Dialog from 'components/Dialog';
import { noop } from 'utils/helpers';
import { push } from 'connected-react-router';

export class UnpaidOrderDialog extends Component {
  static defaultProps = {
    t: noop,
    onClose: noop,
    historyPush: noop,
    title: '',
    orderId: '',
  };

  static propTypes = {
    t: func,
    onClose: func,
    historyPush: func,
    title: string,
    orderId: string,
  };

  handleClose = () => {
    const { orderId } = this.props;
    const path = orderId ? `/orders/${orderId}` : '/orders';
    this.props.historyPush(path);
    this.props.onClose();
  };

  render() {
    const { t, title, ...props } = this.props;
    return (
      <Dialog
        title={title}
        primaryButtonText={t('RecordPanel.dialog_button_pay')}
        primaryAction={this.handleClose}
        {...props}
      />
    );
  }
}
export default compose(
  withTranslation(),
  connect(null, {
    onClose: closeDialog,
    historyPush: push,
  })
)(UnpaidOrderDialog);
