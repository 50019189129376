import authTracking from 'store/modules/auth/tracking';
import checkoutTracking from 'store/modules/checkout/tracking';
import placeOrderTracking from 'store/modules/placeOrder/tracking';
import recordsTracking from 'store/modules/records/tracking';
import regionTracking from 'store/modules/region/tracking';
import settingsTracking from 'store/modules/settings/tracking';
import walletTracking from 'store/modules/wallet/tracking';
import driversTracking from 'store/modules/drivers/tracking';

const trackingMiddleware = store => next => action => {
  if (!window.analytics) return next(action);
  const prevState = store.getState();
  const result = next(action);
  const nextState = store.getState();
  authTracking(prevState, action, nextState);
  checkoutTracking(prevState, action, nextState);
  placeOrderTracking(prevState, action, nextState);
  recordsTracking(prevState, action, nextState);
  regionTracking(prevState, action, nextState);
  settingsTracking(prevState, action, nextState);
  walletTracking(prevState, action, nextState);
  driversTracking(prevState, action, nextState);
  return result;
};

export default trackingMiddleware;
