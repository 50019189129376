import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Response {
//   data: {
//     bearing: Number,
//     driverID: Number,
//     latitude: String,
//     longitude: String,
//     speed: String,
//     time: Number, // unix time
//   };
//   footprint: String;
//   success: Boolean;
// }
/**
 * Locate driver by order ID
 * @param {string} orderId order ID
 * @return {promise} ...
 * @throws NO_RECORD_FOUND, TIMEOUT_ORDER, ERROR_INVALID_SESSION, ERROR_INVALID_PARAMETERS, ...
 */
export default async function locateDriver(orderId) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, country } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    country,
    order: orderId,
  };
  const { success, data, code } = await fetcher.get('vanlocatedriver', params);
  if (!success) throw code;
  // API returns lat-lng as strings:
  return {
    ...data,
    latitude: parseFloat(data.latitude),
    longitude: parseFloat(data.longitude),
  };
}
