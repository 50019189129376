export function getCreditBalance({ wallet }) {
  return wallet.creditBalance;
}

export function getRewardBalance({ wallet }) {
  return wallet.rewardBalance;
}

export function getWalletHistory(state) {
  return state.wallet.history;
}

export function getWalletHistoryCount(state) {
  return state.wallet.historyCount;
}

export function getStatement(state) {
  return state.wallet.statement;
}

export function getStatementTypes(state) {
  return state.wallet.statementTypes;
}

export function getTopUpAmount(state) {
  return state.wallet.topUpAmount;
}
export function getTopUpSource(state) {
  return state.wallet.initTopUpInfo.source;
}
export function getTopUpInfo(state) {
  return state.wallet.initTopUpInfo;
}
