import { call, put, select, takeLatest } from 'redux-saga/effects';
import { sessionService } from 'redux-react-session';

import { getUser } from 'interfaces/global/store/modules/auth/selectors';
import { mergeProfile } from 'interfaces/global/store/modules/auth/helpers';
import { resetDialog } from 'store/modules/ui';

import {
  setProfile,
  track,
} from 'interfaces/global/store/modules/tracking/actions';

import {
  setMarketingOptIn as setMarketingOptInAPI,
  updatePassword as updatePasswordAPI,
} from 'api/authAPI';
import {
  updatePreferences,
  getUserInfo,
  fetchChangeEmailSendSMS,
  changeEmailVerify,
} from 'api/uAPI';

import validator from 'utils/validator';
import { UApiError, changeEmailStep } from 'utils/helpers';

import {
  PASSWORD_UPDATE_REQUEST,
  PREFERENCES_UPDATE_REQUEST,
  SET_MARKETING_OPT_IN_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAILURE,
  PREFERENCES_UPDATE_SUCCESS,
  PREFERENCES_UPDATE_FAILURE,
  SET_MARKETING_OPT_IN_SUCCESS,
  SET_MARKETING_OPT_IN_FAILURE,
  CHANGE_EMAIL_SMS_REQUEST,
  CHANGE_EMAIL_SMS_FAILURE,
  SET_CHANGE_EMAIL_STEP,
  CHANGE_EMAIL_SMS_SUCCESS,
  CHANGE_EMAIL_VERIFY_PIN_REQUEST,
  CHANGE_EMAIL_VERIFY_PIN_SUCCESS,
  CHANGE_EMAIL_VERIFY_PIN_FAILURE,
  RESET_CHANGE_EMAIL_STEPS,
} from './actions';
import { getChangeEmailNewEmail } from './selectors';

export function* onPasswordUpdate({ newPassword, oldPassword }) {
  try {
    yield call(updatePasswordAPI, { newPassword, oldPassword });
    yield put({ type: PASSWORD_UPDATE_SUCCESS });
  } catch (e) {
    yield put({
      type: PASSWORD_UPDATE_FAILURE,
      meta: {
        type: 'error',
        message: e.message,
        data: 'llm.updatePassword',
      },
    });
  }
}

export function* onPreferencesUpdate({
  email,
  eReceipt,
  pod,
  statement,
  topupReminder,
}) {
  try {
    yield call(updatePreferences, {
      email,
      eReceipt,
      pod,
      statement,
      topupReminder,
    });

    const user = yield select(getUser);
    const { access_token: accessToken, profile_type: profileType } = user;

    const newProfile = yield call(getUserInfo, accessToken, profileType);
    yield call([sessionService, 'saveUser'], {
      ...user,
      profile: mergeProfile(user.profile, newProfile),
    });

    // if billing email is changed, track it
    // if the new billing email is empty, we default back to the registration email
    if (email !== undefined) {
      yield put(setProfile({ email_billing: email || newProfile.email }));
    }

    yield put({
      type: PREFERENCES_UPDATE_SUCCESS,
      email,
      eReceipt,
      pod,
      statement,
      topupReminder,
    });

    if (statement !== undefined) {
      yield put(track('statement_updated', { status: statement }));
    }
    if (topupReminder !== undefined) {
      yield put(track('low_balance_updated', { status: topupReminder }));
    }
    if (pod !== undefined) {
      yield put(track('pod_updated', { status: pod }));
    }
    if (eReceipt !== undefined) {
      yield put(track('billing_updated', { status: eReceipt }));
    }
  } catch (e) {
    yield put({
      type: PREFERENCES_UPDATE_FAILURE,
      meta: {
        type: 'error',
        message: e.message,
        data: 'llm.updatePrerferences',
      },
    });
  }
}

export function* onSetMarketingOptIn({ optIn }) {
  try {
    yield call(setMarketingOptInAPI, optIn);

    const userProfile = yield select(getUser);
    yield call([sessionService, 'saveUser'], {
      ...userProfile,
      is_marketing_opt_in: +optIn,
    });

    yield put({ type: SET_MARKETING_OPT_IN_SUCCESS, optIn });
    yield put(resetDialog());
  } catch (e) {
    yield put({
      type: SET_MARKETING_OPT_IN_FAILURE,
      meta: {
        type: 'error',
        message: e.message,
        data: 'llm.setMarketingOptin',
      },
    });
  }
}

export function* onChangeEmailSendSMSRequest({ email }) {
  try {
    const { valid, message } = validator.email(email);
    if (!valid) {
      throw new UApiError(message, 30001);
    }
    yield call(fetchChangeEmailSendSMS, { email });
    yield put({
      type: SET_CHANGE_EMAIL_STEP,
      step: changeEmailStep['VERIFY_SMS_CODE_STEP'],
    });
    yield put({
      type: CHANGE_EMAIL_SMS_SUCCESS,
      email,
    });
  } catch (e) {
    yield put({
      type: CHANGE_EMAIL_SMS_FAILURE,
      meta: {
        type: 'error',
        message: e.message,
        data: 'llm.changeEmailSendSMS',
      },
    });
  }
}
export function* onChangeEmailVerify({ code }) {
  try {
    const email = yield select(getChangeEmailNewEmail);
    yield call(changeEmailVerify, { email, code });
    yield put({
      type: SET_CHANGE_EMAIL_STEP,
      step: changeEmailStep['DEFAULT_STEP'],
    });
    yield put({
      type: CHANGE_EMAIL_VERIFY_PIN_SUCCESS,
      meta: {
        type: 'success',
        message: 'Settings.update_email_success',
      },
    });
    const user = yield select(getUser);
    const { access_token: accessToken, profile_type: profileType } = user;
    const newProfile = yield call(getUserInfo, accessToken, profileType);
    yield call([sessionService, 'saveUser'], {
      ...user,
      profile: mergeProfile(user.profile, newProfile),
    });
    yield put({
      type: RESET_CHANGE_EMAIL_STEPS,
    });
  } catch (e) {
    yield put({
      type: CHANGE_EMAIL_VERIFY_PIN_FAILURE,
      meta: {
        type: 'error',
        message: e.message,
        data: 'llm.changeEmailVerify',
      },
    });
  }
}
export default function* rootSaga() {
  yield takeLatest(PASSWORD_UPDATE_REQUEST, onPasswordUpdate);
  yield takeLatest(PREFERENCES_UPDATE_REQUEST, onPreferencesUpdate);
  yield takeLatest(SET_MARKETING_OPT_IN_REQUEST, onSetMarketingOptIn);
  yield takeLatest(CHANGE_EMAIL_SMS_REQUEST, onChangeEmailSendSMSRequest);
  yield takeLatest(CHANGE_EMAIL_VERIFY_PIN_REQUEST, onChangeEmailVerify);
}
