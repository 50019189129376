import searchHistory, {
  saga as searchHistorySaga,
} from 'store/modules/searchHistory';

const searchHistoryModule = {
  id: 'searchHistory',
  reducerMap: { searchHistory },
  sagas: [searchHistorySaga],
};

export default searchHistoryModule;
