import { sessionService } from 'redux-react-session';
import { call, select, put, takeLatest } from 'redux-saga/effects';
import _merge from 'lodash/merge';

import {
  updatePreferences as updatePreferencesAPI,
  setMarketingOptIn as setMarketingOptInAPI,
  fetchUserProfile,
  updatePassword as updatePasswordAPI,
} from 'api/authAPI';
import { getUser } from 'store/modules/auth/selectors';
import { resetDialog } from 'store/modules/ui';
import {
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAILURE,
  PREFERENCES_UPDATE_REQUEST,
  PREFERENCES_UPDATE_SUCCESS,
  PREFERENCES_UPDATE_FAILURE,
  SET_MARKETING_OPT_IN_REQUEST,
  SET_MARKETING_OPT_IN_SUCCESS,
  SET_MARKETING_OPT_IN_FAILURE,
} from './actionTypes';

export const updatePreferences = ({ email, eReceipt, pod }) => ({
  type: PREFERENCES_UPDATE_REQUEST,
  email,
  eReceipt,
  pod,
});

export const updatePassword = ({ newPassword, oldPassword }) => ({
  type: PASSWORD_UPDATE_REQUEST,
  newPassword,
  oldPassword,
});

export const setMarketingOptIn = optIn => ({
  type: SET_MARKETING_OPT_IN_REQUEST,
  optIn,
});

export function* onPasswordUpdate({ newPassword, oldPassword }) {
  try {
    yield call(updatePasswordAPI, { newPassword, oldPassword });
    yield put({ type: PASSWORD_UPDATE_SUCCESS });
  } catch (e) {
    yield put({
      type: PASSWORD_UPDATE_FAILURE,
      meta: {
        type: 'error',
        message: e.message,
        data: 'llm.updatePassword',
      },
    });
  }
}

export function* onPreferencesUpdate({ email, eReceipt, pod }) {
  try {
    yield call(updatePreferencesAPI, { email, eReceipt, pod });

    // TODO: FOX-1958 - Different data returned from /login and /getuserprofile in auth-service
    // eslint-disable-next-line no-unused-vars, camelcase
    const { country, phone_number, ...newProfile } = yield call(
      fetchUserProfile
    );
    const userProfile = yield select(getUser);
    userProfile.subscriptions = newProfile.subscriptions; // hack
    // merge
    yield call([sessionService, 'saveUser'], _merge(userProfile, newProfile));

    yield put({
      type: PREFERENCES_UPDATE_SUCCESS,
      email,
      eReceipt,
      pod,
    });
  } catch (e) {
    yield put({
      type: PREFERENCES_UPDATE_FAILURE,
      meta: {
        type: 'error',
        message: e.message,
        data: 'llm.updatePrerferences',
      },
    });
  }
}

export function* onSetMarketingOptIn({ optIn }) {
  try {
    yield call(setMarketingOptInAPI, optIn);

    const userProfile = yield select(getUser);
    yield call([sessionService, 'saveUser'], {
      ...userProfile,
      is_marketing_opt_in: +optIn,
    });

    yield put({ type: SET_MARKETING_OPT_IN_SUCCESS, optIn });
    yield put(resetDialog());
  } catch (e) {
    yield put({
      type: SET_MARKETING_OPT_IN_FAILURE,
      meta: {
        type: 'error',
        message: e.message,
        data: 'llm.setMarketingOptin',
      },
    });
  }
}

export function* settingsSaga() {
  yield takeLatest(PASSWORD_UPDATE_REQUEST, onPasswordUpdate);
  yield takeLatest(PREFERENCES_UPDATE_REQUEST, onPreferencesUpdate);
  yield takeLatest(SET_MARKETING_OPT_IN_REQUEST, onSetMarketingOptIn);
}
