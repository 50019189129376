import React, { Fragment } from 'react';
import { arrayOf, bool, node, shape } from 'prop-types';
import styled from 'styled-components';
import { Table } from '@lalamove/karang';

import Spinner from 'components/Spinner';

export const StyledSpinner = styled(Spinner)`
  display: block;
  margin: 2rem auto;
`;

StyledSpinner.displayName = 'Spinner';

const WrappedTable = ({ data, loading, emptyState, ...props }) => (
  <Fragment>
    <Table data={data} {...props} />
    {loading ? <StyledSpinner /> : data.length === 0 && emptyState}
  </Fragment>
);

WrappedTable.defaultProps = {
  data: [],
  loading: false,
  emptyState: null,
};

WrappedTable.propTypes = {
  data: arrayOf(shape()),
  loading: bool,
  emptyState: node,
};

export default WrappedTable;
