// register
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

// login
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REFRESH_LOGIN_SESSION_REQUEST = 'REFRESH_LOGIN_SESSION_REQUEST';
export const REFRESH_LOGIN_SESSION_SUCCESS = 'REFRESH_LOGIN_SESSION_SUCCESS';
export const REFRESH_LOGIN_SESSION_FAILURE = 'REFRESH_LOGIN_SESSION_FAILURE';

// logout
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';

// facebook
export const FB_LOGIN_REQUEST = 'FB_LOGIN_REQUEST';
export const FB_LOGIN_SUCCESS = 'FB_LOGIN_SUCCESS';
export const FB_LOGIN_CANCEL = 'FB_LOGIN_CANCEL';
export const FB_LOGIN_FAILURE = 'FB_LOGIN_FAILURE';
export const FB_USER_DATA_RECEIVED = 'FB_USER_DATA_RECEIVED';

// forgot password
export const VERIFY_PIN_REQUEST = 'VERIFY_PIN_REQUEST';
export const VERIFY_PIN_SUCCESS = 'VERIFY_PIN_SUCCESS';
export const VERIFY_PIN_FAILURE = 'VERIFY_PIN_FAILURE';

export const RESEND_PIN_REQUEST = 'RESEND_PIN_REQUEST';
export const RESEND_PIN_SUCCESS = 'RESEND_PIN_SUCCESS';
export const RESEND_PIN_FAILURE = 'RESEND_PIN_FAILURE';
export const RESEND_PIN_RELEASE = 'RESEND_PIN_RELEASE';

export const RESET_PW_REQUEST = 'RESET_PW_REQUEST';
export const RESET_PW_SUCCESS = 'RESET_PW_SUCCESS';
export const RESET_PW_FAILURE = 'RESET_PW_FAILURE';

// verification
export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'VERIFY_FAILURE';

export const RESEND_REQUEST = 'RESEND_REQUEST';
export const RESEND_SUCCESS = 'RESEND_SUCCESS';
export const RESEND_FAILURE = 'RESEND_FAILURE';
export const RESEND_LOCKING = 'RESEND_LOCKING'; // lock resend for 60 secs
export const RESEND_RELEASE = 'RESEND_RELEASE'; // release the 60 secs resend lock

// session
export const SESSION_FAILURE = 'SESSION_FAILURE';

// action creator
export const registerSubmit = ({
  firstName,
  lastName,
  email,
  country,
  countryCode,
  mobile,
  password,
  lang,
  socialLoginId,
  marketingOptIn,
}) => ({
  type: REGISTER_REQUEST,
  firstName,
  lastName,
  email,
  country,
  countryCode,
  mobile,
  password,
  lang,
  socialLoginId,
  marketingOptIn,
});

export const loginSubmit = ({ username, password, areaCode }) => ({
  type: LOGIN_REQUEST,
  username,
  password,
  areaCode,
});

export const refreshLoginSession = () => ({
  type: REFRESH_LOGIN_SESSION_REQUEST,
});
export const refreshLoginSessionSuccess = () => ({
  type: REFRESH_LOGIN_SESSION_SUCCESS,
});
export const refreshLoginSessionFailure = () => ({
  type: REFRESH_LOGIN_SESSION_FAILURE,
});

export const requestLogout = () => ({
  type: REQUEST_LOGOUT,
});

export const facebookLogin = ({ areaCode, user }) => ({
  type: FB_LOGIN_REQUEST,
  user,
  areaCode,
});

export const cancelFacebookLogin = () => ({
  type: FB_LOGIN_CANCEL,
});

export const fbUserDataReceived = fbUser => ({
  type: FB_USER_DATA_RECEIVED,
  fbUser,
});

export const sessionFailure = code => ({
  type: SESSION_FAILURE,
  meta: { message: code },
});

export const verifyPinRequest = ({ smsCode, uuid, username, areaCode }) => ({
  type: VERIFY_PIN_REQUEST,
  smsCode,
  uuid,
  username,
  areaCode,
});

export const resendPinRequest = ({
  username,
  areaCode,
  lang,
  waitDuration,
}) => ({
  type: RESEND_PIN_REQUEST,
  username,
  areaCode,
  lang,
  waitDuration,
});

export const resetPwRequest = ({
  uuid,
  username,
  password,
  areaCode,
  smsCode,
  emailCode,
}) => ({
  type: RESET_PW_REQUEST,
  uuid,
  username,
  password,
  areaCode,
  smsCode,
  emailCode,
});

export const verifyRequest = ({ pin }) => ({
  type: VERIFY_REQUEST,
  pin,
});

export const resendRequest = ({ blockDuration, method }) => ({
  type: RESEND_REQUEST,
  blockDuration,
  method,
});

export const resendLocking = ({ blockDuration }) => ({
  type: RESEND_LOCKING,
  blockDuration,
});
