import { LalamoveApiError } from 'utils/helpers';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// login for auth api
// interface MobileAPIResponse<T> {
//   data: T;
//   success: Boolean;
// }
// interface Response {
//   access_token: String;
//   account_type: String; // REGISTRATION |
//   client_id: Number;
//   contact_info: {
//     oauth_client: Number,
//     contact_email: String,
//     contact_name: String,
//     contact_phone: String,
//   }[];
//   corporate_code: String | null;
//   corporate_id: Number | null;
//   corporate_invitations: any[]; // ??
//   corporation: null | {
//     corporate_address: String,
//     corporate_code: String,
//     corporate_tax_id: String,
//     country: String, // lowercase
//     country_code: String,
//     id: Number,
//     name: String,
//     role: Number,
//   };
//   country: String;
//   country_code: Number;
//   email: String;
//   email_verified_status: Boolean;
//   expires_in: Number; // second
//   is_kiosk: Boolean;
//   is_marketing_opt_in: Number; // 0, 1
//   is_pod_enabled: Boolean;
//   last_name: String;
//   login_status: Boolean;
//   name: String;
//   partner_id: Number | null;
//   password_set: Boolean;
//   phone_number: String;
//   phone_verified_status: true;
//   social_login_id: String | null;
//   subscriptions: any[];
// };
// AuthAPIResponse<Response>
export default async function login(username, password, areaCode) {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  const { country, city } = await fetcher.getState();
  const params = {
    username,
    password,
    country,
    city,
    country_code: areaCode,
  };
  const { success, data, code } = await fetcher.post('login', params);
  if (!success) throw new LalamoveApiError(code);

  return {
    ...data,
    client_id: String(data.client_id),
    corporate_id: data.corporate_id ? String(data.corporate_id) : null,
  };
}
