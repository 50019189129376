// ===== Action types =====
export const REGION_INIT = 'REGION_INIT';
export const REGION_INIT_SUCCESS = 'REGION_INIT_SUCCESS';
export const REGION_INIT_FAILURE = 'REGION_INIT_FAILURE';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const UPDATE_COUNTRY_SETTING = 'UPDATE_COUNTRY_SETTING';
export const UPDATE_CITY_SETTING = 'UPDATE_CITY_SETTING';
export const CHANGE_LOCATION_REQUEST = 'REGION_CHANGE_LOCATION_REQUEST';
export const CHANGE_LOCATION_SUCCESS = 'REGION_CHANGE_LOCATION_SUCCESS';
export const CHANGE_LOCALE_REQUEST = 'REGION_CHANGE_LOCALE_REQUEST';
export const CHANGE_LOCALE_SUCCESS = 'REGION_CHANGE_LOCALE_SUCCESS';
export const FETCH_REGION_CONFIG_REQUEST = 'FETCH_REGION_CONFIG_REQUEST';
export const FETCH_REGION_CONFIG_SUCCESS = 'FETCH_REGION_CONFIG_SUCCESS';
export const FETCH_REGION_CONFIG_FAILURE = 'FETCH_REGION_CONFIG_FAILURE';
export const UPDATE_CITY_NAMES = 'UPDATE_CITY_NAMES';

// action creators
export const initRegion = () => ({
  type: REGION_INIT,
});
export const initRegionDone = ({ location, isGlobal }) => ({
  type: REGION_INIT_SUCCESS,
  location,
  isGlobal,
});
export const initRegionFailure = message => ({
  type: REGION_INIT_FAILURE,
  meta: {
    type: 'error',
    message,
  },
});
export const fetchLocationSuccess = ({ countries, cities }) => ({
  type: FETCH_LOCATION_SUCCESS,
  countries,
  cities,
});
export const updateCountrySetting = ({ country }) => ({
  type: UPDATE_COUNTRY_SETTING,
  country,
});
export const updateCitySetting = ({ city }) => ({
  type: UPDATE_CITY_SETTING,
  city,
});
// must be LLM Upper
/**
 * Creates CHANGE_LOCATION_REQUEST redux action
 * @param {Object} params
 * @param {string} [params.country]
 * @param {Object} [params.latlng]
 * @param {string} [params.location]
 * @param {string} [params.locale]
 */
export const changeLocation = ({ country, latLng, location, locale }) => ({
  type: CHANGE_LOCATION_REQUEST,
  country,
  latLng,
  location,
  locale,
});
export const changeLocationSuccess = ({ country, location }) => ({
  type: CHANGE_LOCATION_SUCCESS,
  country,
  location,
});
// must be LLM Upper
export const changeLocale = ({ locale, saveLocation }) => ({
  type: CHANGE_LOCALE_REQUEST,
  locale,
  saveLocation,
});
// must be LLM Upper
export const changeLocaleSuccess = ({ locale }) => ({
  type: CHANGE_LOCALE_SUCCESS,
  locale,
});

export const fetchRegionConfig = () => ({
  type: FETCH_REGION_CONFIG_REQUEST,
});

export const fetchRegionConfigSuccess = countries => ({
  type: FETCH_REGION_CONFIG_SUCCESS,
  countries,
});

export const fetchRegionConfigFailure = ({ message }) => ({
  type: FETCH_REGION_CONFIG_FAILURE,
  meta: {
    type: 'error',
    message,
  },
});

export const fetchCities = country => ({
  type: `FETCH_${country.id}_CITIES_REQUEST`,
  country,
});

export const fetchCitiesSuccess = (cities, countryId) => ({
  type: `FETCH_${countryId}_CITIES_SUCCESS`,
  cities,
  countryId,
});

export const fetchCitiesFailure = ({ message }, countryId) => ({
  type: `FETCH_${countryId}_CITIES_FAILURE`,
  meta: {
    type: 'error',
    message,
  },
});

export const updateCityNames = cities => ({
  type: UPDATE_CITY_NAMES,
  cities,
});
