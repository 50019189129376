import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bool, func, node, number, string } from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Card, Form, PinInput } from '@lalamove/karang';

import { fontSize } from 'styles/fonts';
import { black, silver } from 'styles/colors';
import { noop } from 'utils/helpers';

import ResendContent from './ResendContent'; // eslint-disable-line import/no-named-as-default

const FormItem = styled(Form.Item)`
  justify-content: center;
`;

const CardHeader = styled.h1`
  padding-bottom: 2rem;
  margin: 0;
  color: ${black};
  font-size: ${fontSize.large};
  text-align: center;
`;

const CardContent = styled.div`
  color: ${silver};
  font-size: ${fontSize.regular};
  text-align: center;
`;

const CardFooter = styled.div`
  padding-top: 2rem;
  color: ${silver};
  text-align: center;
`;

class PinForm extends Component {
  static defaultProps = {
    onSubmitPin: noop,
    onResendRequest: noop,
    loading: false,
    isWaiting: true,
    waitDuration: 60000,
    maxReset: false,
    username: '',
    label: '',
    helpLink: null,
    error: '',
    t: noop,
  };

  static propTypes = {
    onSubmitPin: func,
    onResendRequest: func,
    loading: bool,
    isWaiting: bool,
    waitDuration: number,
    maxReset: bool,
    username: string,
    label: string,
    helpLink: node,
    error: string,
    t: func,
  };

  state = {
    pin: '',
    showError: false,
  };

  handlePinChange = pin => {
    this.setState({ pin, showError: false });
    if (pin.length === 4) {
      this.setState({ showError: true });
      this.props.onSubmitPin(pin);
    }
  };

  handleResendRequest = () => {
    const { username } = this.props;
    this.props.onResendRequest(username);
  };

  render() {
    const {
      loading,
      isWaiting,
      waitDuration,
      maxReset,
      label,
      helpLink,
      error,
      t,
    } = this.props;
    const { pin, showError } = this.state;

    return (
      <Card>
        <CardHeader>{t('ForgotPassword.reset_password')}</CardHeader>
        <form>
          <FormItem>
            <PinInput
              type="tel"
              pins={pin.split('')}
              disabled={loading || maxReset}
              error={showError || maxReset ? t(error) : ''}
              onChange={this.handlePinChange}
            />
          </FormItem>
          <FormItem>
            <CardContent>{t('ForgotPassword.enter_pin')}</CardContent>
          </FormItem>
          <ResendContent
            isSubmitting={loading}
            isWaiting={isWaiting}
            waitDuration={waitDuration}
            maxReset={maxReset}
            label={label}
            onResendRequest={this.handleResendRequest}
            helpLink={helpLink}
          />
        </form>
        <CardFooter>
          <Trans i18nKey="ForgotPassword.login">
            Already have an account?
            <Link to="/login" replace>
              Log in
            </Link>
            .
          </Trans>
        </CardFooter>
      </Card>
    );
  }
}

export default withTranslation()(PinForm);
