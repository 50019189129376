export const FETCH_SAVED_DRIVERS_REQUEST = 'FETCH_SAVED_DRIVERS_REQUEST';
export const FETCH_SAVED_DRIVERS_SUCCESS = 'FETCH_SAVED_DRIVERS_SUCCESS';
export const FETCH_SAVED_DRIVERS_FAILURE = 'FETCH_SAVED_DRIVERS_FAILURE';

export const REMOVE_DRIVER_REQUEST = 'REMOVE_DRIVER_REQUEST';
export const REMOVE_DRIVER_SUCCESS = 'REMOVE_DRIVER_SUCCESS';
export const REMOVE_DRIVER_FAILURE = 'REMOVE_DRIVER_FAILURE';

export const fetchSavedDrivers = query => ({
  type: FETCH_SAVED_DRIVERS_REQUEST,
  query,
});

export const removeDriver = ({ driverId }) => ({
  type: REMOVE_DRIVER_REQUEST,
  driverId,
});
