import { REQUEST_LOGOUT } from 'store/modules/auth/actions';
import { FETCH_PRICE_SUCCESS } from 'interfaces/global/store/modules/pricing/actions';
import { daylightSavingsStatus } from 'utils/helpers';
import {
  CheckoutSteps,
  PaymentMethods,
} from 'interfaces/global/store/modules/checkout/types';
import { CHANGE_LOCATION_REQUEST } from 'store/modules/region/actions';
import { WAYPOINT_UPDATE } from 'interfaces/global/store/modules/routing/actions';
import { PROFILE_SWITCHED } from 'interfaces/global/store/modules/auth/actions';
import {
  DATE_INIT,
  DATE_CHANGE,
  CONTACT_UPDATE,
  NOTE_UPDATE,
  FAVOURITE_DRIVER_TOGGLE,
  PAYMENT_METHOD_CHANGE,
  ONLINE_PAYMENT_INIT,
  PLACE_ORDER_SUCCESS,
  CHECKOUT_ERROR_TOGGLE,
  CHECKOUT_RESET,
  FETCH_ONLINE_PAYMENT_STATUS_SUCCESS,
  FETCH_ONLINE_PAYMENT_STATUS_FAILURE,
  ONLINE_PAYMENT_CLOSE,
  ONLINE_PAYMENT_MAX_RETRY_COUNT,
  FETCH_DAYLIGHT_SUCCESS,
  DAYLIGHT_DIALOG_CLOSE,
  SET_CHECKOUT_STEP,
  SET_FIRST_TIME_PLACING_ORDER,
} from './actions';

export const contactInitState = {
  name: '',
  phone: '',
};

export const dirtyInitState = {
  deliveryDatetime: false,
  name: false,
  phone: false,
  selectedPaymentMethodId: false,
};

export const errorInitState = {
  name: '',
  phone: '',
  uniformInvoice: '',
};

export const onlinePaymentInfoInitState = {
  hpayCashierUrl: null,
  orderUuid: null,
  isOnlinePaymentOpen: false,
  onlinePaymentStatusMaxRtryCount: 2,
};

export const initState = {
  defaultDatetime: '',
  deliveryDatetime: '',
  contact: contactInitState,
  note: '',
  selectedPaymentMethodId: PaymentMethods.ONLINE.id,
  canUseWallet: true,
  hasFavDriver: false,
  preferFavorite: false,
  dirty: dirtyInitState,
  error: errorInitState,
  onlinePaymentInfo: onlinePaymentInfoInitState,
  daylightSavingsTimeType: daylightSavingsStatus['DEFAULT'],
  step: CheckoutSteps.SELECT_VEHICLE,
  hasCheckedIsFirstTimePlacingOrder: false,
  firstTimePlacingOrder: false,
};

export function contactReducer(state = contactInitState, action) {
  switch (action.type) {
    case CONTACT_UPDATE: {
      return { ...state, ...action.contact };
    }
    default:
      return state;
  }
}

export function dirtyReducer(state = dirtyInitState, action) {
  switch (action.type) {
    case CONTACT_UPDATE: {
      const { isDirty, contact } = action;

      return {
        ...state,
        ...Object.keys(contact).reduce(
          (obj, key) => ({ ...obj, [key]: isDirty }),
          {}
        ),
      };
    }
    case DATE_CHANGE: {
      return { ...state, deliveryDatetime: action.isDirty };
    }
    case PAYMENT_METHOD_CHANGE: {
      return { ...state, selectedPaymentMethodId: action.isDirty };
    }
    default:
      return state;
  }
}

export function errorReducer(state = errorInitState, action) {
  switch (action.type) {
    case CHECKOUT_ERROR_TOGGLE: {
      return { ...state, [action.meta.name]: action.meta.message };
    }
    default:
      return state;
  }
}

export function onlinePaymentInfoReducer(
  state = onlinePaymentInfoInitState,
  action
) {
  switch (action.type) {
    case ONLINE_PAYMENT_INIT: {
      return {
        ...state,
        orderUuid: action.orderUuid,
        hpayCashierUrl: action.hpayCashierUrl,
        isActive: true,
      };
    }
    case FETCH_ONLINE_PAYMENT_STATUS_SUCCESS:
    case FETCH_ONLINE_PAYMENT_STATUS_FAILURE: {
      return {
        ...state,
        hpayCashierUrl: '',
        orderUuid: '',
        isActive: false,
      };
    }
    case ONLINE_PAYMENT_CLOSE: {
      return {
        ...state,
        isActive: false,
      };
    }
    case ONLINE_PAYMENT_MAX_RETRY_COUNT: {
      return {
        ...state,
        onlinePaymentStatusMaxRtryCount: action.count,
      };
    }
    default:
      return state;
  }
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case CHANGE_LOCATION_REQUEST:
    case CHECKOUT_RESET:
    case PLACE_ORDER_SUCCESS:
    case PROFILE_SWITCHED:
    case REQUEST_LOGOUT:
      return {
        ...initState,
        preferFavorite: state.preferFavorite,
        selectedPaymentMethodId: state.selectedPaymentMethodId,
      };

    case FETCH_PRICE_SUCCESS: {
      return {
        ...state,
        hasFavDriver: action.hasFavDriver,
        error: errorReducer(state.error, action),
        dirty: dirtyReducer(state.dirty, action),
      };
    }
    case PAYMENT_METHOD_CHANGE: {
      return {
        ...state,
        selectedPaymentMethodId: action.paymentMethodId,
        dirty: dirtyReducer(state.dirty, { ...action, isDirty: true }),
      };
    }
    case DATE_INIT: {
      return {
        ...state,
        defaultDatetime: action.defaultDate,
      };
    }
    case FETCH_DAYLIGHT_SUCCESS: {
      return {
        ...state,
        daylightSavingsTimeType: action.daylightSavingsTimeType,
      };
    }
    case DAYLIGHT_DIALOG_CLOSE: {
      return {
        ...state,
        daylightSavingsTimeType: daylightSavingsStatus['DEFAULT'],
      };
    }
    case DATE_CHANGE: {
      const { date } = action;
      return {
        ...state,
        deliveryDatetime: date,
        dirty: dirtyReducer(state.dirty, action),
      };
    }
    case NOTE_UPDATE: {
      return { ...state, note: action.text };
    }
    case FAVOURITE_DRIVER_TOGGLE: {
      return { ...state, preferFavorite: action.toggle };
    }
    case ONLINE_PAYMENT_INIT:
    case FETCH_ONLINE_PAYMENT_STATUS_SUCCESS:
    case FETCH_ONLINE_PAYMENT_STATUS_FAILURE:
    case ONLINE_PAYMENT_CLOSE:
    case ONLINE_PAYMENT_MAX_RETRY_COUNT: {
      return {
        ...state,
        onlinePaymentInfo: onlinePaymentInfoReducer(
          state.onlinePaymentInfo,
          action
        ),
      };
    }
    case WAYPOINT_UPDATE:
      return {
        ...state,
        step: CheckoutSteps.SELECT_ROUTE,
      };
    case SET_CHECKOUT_STEP:
      return {
        ...state,
        step: action.step,
      };
    case SET_FIRST_TIME_PLACING_ORDER:
      return {
        ...state,
        hasCheckedIsFirstTimePlacingOrder: true,
        firstTimePlacingOrder: !action.firstTimeFlag,
      };
    default:
      return {
        ...state,
        dirty: dirtyReducer(state.dirty, action),
        contact: contactReducer(state.contact, action),
        error: errorReducer(state.error, action),
      };
  }
}
