import React from 'react';
import styled from 'styled-components';
import { Heading, HeadingGroup, Input, TextArea } from '@lalamove/karang';
import { success as SuccessIcon } from '@lalamove/karang/dist/components/Icon/icons';

import { GROUND } from 'styles/zIndex';
import { orange, darkGray, gray } from 'styles/colors';
import spinnerImg from 'assets/loader.gif';
import { ICON_SIZE } from './config';

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75em 1em;
`;

export const Con = styled.span`
  display: inline-block;
  margin-right: 0.5em;
`;

export const Form = styled.fieldset`
  /*margin: 0 0 0 calc(24px + 0.5em);*/
  padding: 0;
  border: 0;
  margin: 0;
`;

export const FormGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
`;

export const FormItem = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  border: ${({ bordered }) => (bordered ? '1px' : 0)} solid ${darkGray};
  margin-bottom: 1em;
  > * {
    flex: 1 1 auto;
  }
  ${/* sc-selector */ FormGroup} & {
    width: 50%;
  }
  &:not(:nth-child(1)) {
    border-left: 0;
  }
`;

export const ScInput = styled(Input)`
  > * {
    box-sizing: border-box;
  }

  ${/* sc-selector */ FormItem} & {
    position: relative;
    width: 100%;

    &:focus-within {
      z-index: ${GROUND + 1}; /* to show active collapse border */
    }
  }
  ${/* sc-selector */ FormItem}:not(:nth-child(1)) & {
    margin-left: -1px; /* collapse border */
  }
`;

export const ScTextArea = styled(TextArea)`
  width: 100%;
`;

export const RadioLabel = styled.span`
  color: ${({ checked }) => (checked ? orange : gray)};
  font-weight: bold;
`;

const StyledHeadingGroup = styled(HeadingGroup)`
  margin-bottom: 0.5em;
`;

// eslint-disable-next-line react/prop-types
export const CheckoutHeading = ({ children, text }) => (
  <StyledHeadingGroup>
    <Con>{children}</Con>
    <Heading size="large" htmlTag="h2">
      <legend>{text}</legend>
    </Heading>
  </StyledHeadingGroup>
);

export const CheckIcon = styled(SuccessIcon)`
  ${/* sc-selector */ FormItem} & {
    position: absolute;
    z-index: ${GROUND + 1};
    top: 50%;
    right: 1em;
    margin-top: ${-(ICON_SIZE / 2)}px;
  }
`;

export const Spinner = styled.span`
  position: absolute;
  z-index: ${GROUND + 1};
  top: 1em; /* hack to avoid error msg*/
  right: 1em;
  width: ${ICON_SIZE * 0.8}px;
  height: ${ICON_SIZE * 0.8}px;
  background-image: url(${spinnerImg});
  background-position: center;
  background-size: cover;
`;
