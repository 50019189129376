import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { arrayOf, bool, func, number, shape } from 'prop-types';
import styled from 'styled-components';
import { Pagination } from '@lalamove/karang';
import { fetchSavedDrivers } from 'interfaces/global/store/modules/drivers/actions';
import {
  getFavoriteDrivers,
  getFavoriteDriverCount,
} from 'interfaces/global/store/modules/drivers/selectors';
import { createLoadingSelector } from 'store/modules/loading';
import { openDialog } from 'store/modules/ui';
import { noop } from 'utils/helpers';
import { fadeTransition } from 'styles/transitions';
import PageHeader from 'components/PageHeader';
import DriverTable from './components/DriverTable';

const MAX_ROWS = 10;
const MAX_DRIVERS = 100;

// space at bottom for not overlay with genesys button
const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 73rem;
  max-width: 110rem;
  padding-bottom: 5em;
  margin-right: auto;
  margin-left: auto;

  ${fadeTransition}
`;

export class Drivers extends Component {
  static defaultProps = {
    t: noop,
    drivers: [],
    count: 0,
    fetchSavedDrivers: noop,
    openDialog: noop,
    isLoading: false,
  };

  static propTypes = {
    location: shape({}).isRequired,
    t: func,
    drivers: arrayOf(shape()),
    count: number,
    fetchSavedDrivers: func,
    openDialog: func,
    isLoading: bool,
  };

  state = {
    current: 1,
  };

  componentDidMount() {
    const { location } = this.props;

    if (!location.search) {
      this.props.fetchSavedDrivers({ current: 1, max: MAX_DRIVERS });
    }
  }

  handlePagination = nextPage => {
    this.setState({
      current: nextPage,
    });
  };

  handleRemoveDriver = id => {
    this.props.openDialog('DRIVER_REMOVAL', { id });
  };

  render() {
    const { t, drivers, count: total, isLoading } = this.props;
    const { current } = this.state;

    const fromIndex = total ? current * MAX_ROWS - MAX_ROWS + 1 : 0;
    let toIndex = total ? current * MAX_ROWS : 0;
    if (toIndex > total) toIndex = total;

    const pagination = (
      <Pagination
        current={current}
        pageSize={MAX_ROWS}
        total={total}
        description={t('Records.pagination', { fromIndex, toIndex, total })}
        onChange={this.handlePagination}
        loading={isLoading}
      />
    );

    const renderedDrivers = drivers.slice(
      current * MAX_ROWS - MAX_ROWS,
      current * MAX_ROWS
    );

    return (
      <Container>
        <PageHeader
          title={t('Drivers.title_global_drivers')}
          headerEnd={pagination}
        />
        <DriverTable
          drivers={renderedDrivers}
          loading={isLoading}
          onRemoveDriver={this.handleRemoveDriver}
        />
      </Container>
    );
  }
}

const loadingSelector = createLoadingSelector([
  'FETCH_FAVORITE_DRIVERS',
  'FETCH_BANNED_DRIVERS',
]);

const mapState = state => ({
  drivers: getFavoriteDrivers(state),
  count: getFavoriteDriverCount(state),
  isLoading: loadingSelector(state),
});

export default compose(
  withTranslation(),
  connect(mapState, {
    fetchSavedDrivers,
    openDialog,
  })
)(Drivers);
