import {
  takeEvery,
  call,
  put,
  select,
  take,
  all,
  retry,
  race,
} from 'redux-saga/effects';
import _pickBy from 'lodash/pickBy';
import FetcherLockService from 'api/FetcherLockService';
import log from 'api/log';
import * as Sentry from '@sentry/react';
import {
  fetchLocations,
  fetchUserLocationSettings,
  vanLookup,
} from 'api/mobileAPI';
import { getGeoIpLocationCountryCode } from 'api/restAPI';
import { getRegionConfig } from 'api/regionConfigAPI';
import { getCityList } from 'api/uAPI';
import { getCityMetadata } from 'api/umetaAPI';

import { statusMap, ratingMap, LOCATION_REGEX, UApiError } from 'utils/helpers';
import i18n from 'utils/i18n';
import {
  getNearestCountryFromGeolocation,
  getNearestLatLng,
} from 'utils/geoHelper';
import storage from 'utils/storage';
import { toLLMUpper, getParentLocale, toIETF } from 'utils/locale';

import {
  REFRESH_LOGIN_SESSION_SUCCESS,
  REFRESH_LOGIN_SESSION_FAILURE,
} from 'store/modules/auth/actions';

import { saveLastUsedLocation } from 'interfaces/global/store/modules/localStorage/lastUsedLocation';
import { fetchServices } from 'interfaces/global/store/modules/services/actions';
import {
  initRegionDone,
  initRegionFailure,
  fetchLocationSuccess,
  updateCountrySetting,
  updateCitySetting,
  changeLocale,
  changeLocationSuccess,
  changeLocation,
  changeLocaleSuccess,
  fetchRegionConfigSuccess,
  fetchRegionConfigFailure,
  REGION_INIT,
  CHANGE_LOCATION_REQUEST,
  CHANGE_LOCALE_REQUEST,
  updateCityNames,
  fetchCities,
  fetchCitiesSuccess,
  fetchCitiesFailure,
} from './actions';
import {
  getCountry,
  getCurrentCountryCode,
  getCurrentLocation,
  getCurrentLocale,
  getCountryDict,
  getCityDict,
  getIsGlobal,
  getLogApiDomain,
  getCurrentCity,
  getCurrentCountry,
} from './selectors';
import { getIsFetchingCities } from '../loading';

const {
  REACT_APP_REGION_SEA,
  REACT_APP_ENABLE_FORCE_GLOBAL,
  REACT_APP_REGION_CONFIG_API,
  REACT_APP_INDIA_REGION_CONFIG_APIS,
} = process.env;
const isForceGlobal = REACT_APP_ENABLE_FORCE_GLOBAL && isGlobalFromURL();

/**
 * Fetch legacy countries & cities list
 * Reduced as `countries` & `cities` objects in state.region
 */
export function* fetchLocation() {
  const countries = yield call(fetchLocations, REACT_APP_REGION_SEA);

  const response = {
    countries: countries.reduce(
      (memo, cou) => ({
        ...memo,
        [cou.id]: {
          ...cou,
          cities: cou.cities.map(c => c.id),
        },
      }),
      {}
    ),
    cities: countries
      .map(x => x.cities)
      .reduce((memo, ar) => [...memo, ...ar], [])
      .reduce(
        (memo, city) => ({
          ...memo,
          [city.id]: { ...city, legacy: true },
        }),
        {}
      ),
  };
  yield put(fetchLocationSuccess(response));
}

/**
 * Given a country object, determine whether if it's global-ready,
 * by considering url, region-config API and hardcoded config.
 *
 * @param {object} country
 * @returns {boolean} `true`: country is global-ready; `false` otherwise.
 */
export const isCountryGlobalReady = ({ id, countryId, isOpen }) => {
  if (!id) return false;
  if (isForceGlobal) return true;

  return Boolean(countryId && isOpen);
};

export function isGlobalFromURL() {
  const search = new URLSearchParams(window.location.search);
  const isGlobal = search.get('isGlobal');
  if (isGlobal === 'true') return true;
  if (isGlobal === 'false') return false;
  return undefined;
}

// eslint-disable-next-line consistent-return
export function* fetchRegionConfigWithFallback() {
  const domains = [
    REACT_APP_REGION_CONFIG_API,
    ...(REACT_APP_INDIA_REGION_CONFIG_APIS?.split(',') || []),
  ];

  for (const domain of domains) {
    try {
      const results = yield call(getRegionConfig, domain);
      return results;
    } catch (error) {
      if (domain === domains[domains.length - 1]) {
        throw error;
      }
    }
  }
}

/**
 * Fetch all global countries and cities from region-config and ?_m=city_list endpoints
 * Reduced as `countries` & `cities` objects in state.region
 */
export function* fetchGlobalLocation() {
  try {
    const countries = (yield call(fetchRegionConfigWithFallback)).filter(
      isCountryGlobalReady
    );
    const fetchCitiesEffects = countries.map(c => put(fetchCities(c)));
    yield all(fetchCitiesEffects);

    yield put(fetchRegionConfigSuccess(countries));
  } catch (error) {
    yield put(fetchRegionConfigFailure(error));
  }
}

// eslint-disable-next-line consistent-return
export function* handleFetchCities({ country }) {
  const RETRY_TIMES = 3;
  const DELAY_MS = 200;
  const { id, countryId, defaultLanguage, uapi } = country;
  try {
    const cities = yield retry(
      RETRY_TIMES,
      DELAY_MS,
      getCityList,
      uapi,
      countryId,
      defaultLanguage
    );
    yield put(fetchCitiesSuccess(cities, id));
  } catch (error) {
    if (error instanceof UApiError) {
      yield put(fetchCitiesFailure(error, id));
    } else {
      Sentry.captureException(error); // should not throw error directly as failed calls to 1 country should not affect the others
    }
  }
}

// depends country / city for LLM header
export function* syncCitySetting(location) {
  const data = yield call(fetchUserLocationSettings, location);
  const { country, city } = data;
  const { cities, languages, ...remains } = country;
  yield put(updateCountrySetting({ country: remains }));
  yield put(updateCitySetting({ city }));
}

// interface ValidRegion {
//   country: string; // ISO 3166 alpha2
//   location: string; // HK_HKG
//   locale: string; // LLM Upper
// }
export function* extractValidRegion(location, locale) {
  const defaultCountry = yield select(getCurrentCountryCode);
  const defaultLocation = yield select(getCurrentLocation);
  let targetCountry = null;
  let targetLocation = null;
  let targetLocale = null;
  if (location && LOCATION_REGEX.test(location)) {
    const [, country] = LOCATION_REGEX.exec(location);
    targetCountry = country;
    const { defaultLanguage, cities } = yield select(getCountry(country));
    targetLocation = location === country ? cities[0] : location;
    targetLocale = toLLMUpper(defaultLanguage);
  }
  if (locale) {
    const searchCountry = targetCountry || defaultCountry;
    const { languages } = yield select(getCountry(searchCountry));
    if (languages.some(x => x.enable && x.id === toLLMUpper(locale))) {
      targetLocale = toLLMUpper(locale);
    }
  }
  if (targetLocale) {
    return {
      country: targetCountry || defaultCountry,
      location: targetLocation || defaultLocation,
      locale: targetLocale,
    };
  }
  return null;
}

export function* getLocationFromStorageOrURL() {
  const search = new URLSearchParams(window.location.search);
  const locationFromURL = search.get('location');
  const localeFromURL = search.get('locale');
  const regionFromURL = yield call(
    extractValidRegion,
    locationFromURL,
    localeFromURL
  );
  if (regionFromURL) return regionFromURL;

  const locationFromStorage = storage.getItem('userLocation');
  const localeFromStorage = storage.getItem('userLang');
  const regionFromStorage = yield call(
    extractValidRegion,
    locationFromStorage,
    localeFromStorage
  );
  if (regionFromStorage) return regionFromStorage;

  return null;
}

export function* changeLocationByUserLocation() {
  let country;
  let latLng;

  const countryDict = yield select(getCountryDict);
  // step 1: get country code with Geolocation by requesting permission
  try {
    const data = yield call(
      getNearestCountryFromGeolocation,
      Object.values(countryDict)
    );
    country = data.country;
    latLng = data.latLng;
  } catch {
    // do nothing
  }

  // step 2: get country code with GeoIP fallback
  if (!country) {
    const countryCode = yield call(getGeoIpLocationCountryCode);
    if (countryCode && countryDict[countryCode]) {
      country = countryCode;
    }
  }

  // country is found and supported
  if (country) {
    yield put(changeLocation({ country, latLng }));
    return;
  }

  // country is not supported, fallback to (HK, en_HK)
  const defaultLocation = yield select(getCurrentLocation);
  const defaultLocale = yield select(getCurrentLocale);
  yield put(
    changeLocation({ location: defaultLocation, locale: defaultLocale })
  );
}

// obj: { [key: string]: { [locale: string]: string } }
export const keyByLocale = obj => {
  const resources = {};
  Object.keys(obj).forEach(key => {
    Object.keys(obj[key]).forEach(locale => {
      if (!resources[locale]) resources[locale] = {};
      resources[locale][key] = obj[key][locale];
    });
  });
  return resources;
};

export function* resolveValidCity(country, latLng, location) {
  const cityDict = yield select(getCityDict);
  const { cities = [] } = yield select(getCountry(country));
  const [defaultCity] = cities;
  let result = location;
  if (latLng && cities.length) {
    const citiesWithinCountry = _pickBy(cityDict, (_, city) =>
      cities.includes(city)
    );
    const closestCity = getNearestLatLng(
      Object.values(citiesWithinCountry),
      latLng
    );
    result = closestCity.id;
  } else {
    result = location || defaultCity;
  }
  return cityDict[result];
}

/**
 * Given a city object, determine whether the app should be initialized as global.
 * @param {object} city
 */
export const isCityGlobalReady = city => {
  // User forced `isGlobal=true` or `isGlobal=false`, return directly
  if (isForceGlobal !== undefined) return isForceGlobal;
  // Otherwise we check if city is global-ready
  return !!city.globalEnabled;
};

export function* handleChangeLocation(action) {
  const { latLng, location, locale } = action;
  const countryCode = action.country || action.location.substr(0, 2);
  FetcherLockService.lockAuthSession();
  FetcherLockService.lockLocale();

  const isFetchingCities = yield select(getIsFetchingCities([countryCode]));
  if (isFetchingCities) {
    yield take([
      `FETCH_${countryCode}_CITIES_SUCCESS`,
      `FETCH_${countryCode}_CITIES_FAILURE`,
    ]);
  }
  const city = yield call(resolveValidCity, countryCode, latLng, location);

  if (!city) {
    yield put(
      initRegionFailure('Initialization.error_dialog_message_unresolved_city')
    );
    return;
  }

  const country = yield select(getCountry(countryCode));
  if (
    isForceGlobal === undefined &&
    !isCityGlobalReady(city) &&
    isCountryGlobalReady(country)
  ) {
    yield put(
      initRegionFailure('Initialization.error_dialog_message_network_error')
    );
    return;
  }

  storage.setItem('userLocation', city.id);
  log.changeLocation(city.id);
  const logDomain = yield select(getLogApiDomain);
  log.changeUrl(logDomain);

  const isGlobal = isCityGlobalReady(city);
  yield put(initRegionDone({ location: city.id, isGlobal }));

  const { success } = yield race({
    success: take(REFRESH_LOGIN_SESSION_SUCCESS),
    failure: take(REFRESH_LOGIN_SESSION_FAILURE),
  });

  FetcherLockService.unlockAuthSession();

  if (!isGlobal) {
    yield call(syncCitySetting, city.id);
  }
  yield put(changeLocationSuccess({ country: country.id, location: city.id }));

  const { languages, defaultLanguage } = country;
  const currentLocale = yield select(getCurrentLocale);
  let targetLocale = defaultLanguage;
  if (locale && languages.some(x => x.enable && x.id === toLLMUpper(locale))) {
    targetLocale = locale;
  } else if (
    languages.some(
      x => x.enable && getParentLocale(x.id) === getParentLocale(currentLocale)
    )
  ) {
    targetLocale = languages.find(
      x => x.enable && getParentLocale(x.id) === getParentLocale(currentLocale)
    ).id;
  }
  yield put(
    changeLocale({ locale: toLLMUpper(targetLocale), saveLocation: !!success })
  );
}

export function* handleChangeLocale({ locale, saveLocation = true }) {
  storage.setItem('userLang', locale);
  log.changeLocale(locale);

  const ietfLocale = toIETF(locale);
  yield i18n.changeLanguage(ietfLocale);

  const { id } = yield select(getCurrentCity) || {};
  const isGlobal = yield select(getIsGlobal);

  if (isGlobal) {
    yield put(fetchServices(locale));
    // call umeta to get locale-specific urls
    try {
      const { id: countryPrefix, countryId, uapi } = yield select(
        getCurrentCountry
      );
      const [metadata, cities] = yield all([
        call(getCityMetadata),
        call(getCityList, uapi, countryId, locale),
      ]);

      const {
        state_register_url: {
          terms_user_privacy: privacy,
          terms_faq: faq,
          terms_service: terms,
          cnuser_price: pricing,
        },
        customer_tel: csPhone,
        data_center: datacenter,
        call_cs_enabled: callCsEnabled,
      } = metadata;

      // India ban workaround
      const shouldOverrideDomains =
        countryPrefix === 'IN' &&
        ['production', 'staging'].includes(process.env.REACT_APP_HOST_ENV);

      yield put(
        updateCitySetting({
          city: {
            id,
            csPhone,
            callCsEnabled,
            datacenter,
            urls: {
              privacy,
              faq,
              terms,
              pricing,
              ...(shouldOverrideDomains && {
                sharePage: metadata.share_url,
                archivedOrders: metadata.user_order_url,
              }),
            },
          },
        })
      );
      yield put(updateCityNames(cities));
      if (saveLocation) {
        saveLastUsedLocation({ location: id, locale, datacenter });
      }
    } catch (error) {
      // In case a CORS error occurs and the getCityMetadata request fails
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
  } else {
    yield loadLegacyTranslations({ locale, ietfLocale, city: id });
  }

  yield put(changeLocaleSuccess({ locale }));
  FetcherLockService.unlockLocale();
}

export function* loadLegacyTranslations({ locale, ietfLocale, city }) {
  const country = yield select(getCurrentCountryCode);

  const {
    ERROR_MSG,
    REASON: {
      USER_CANCELLATION_ASSIGNING,
      USER_CANCELLATION_MATCHED,
      USER_1_RATING = {},
      USER_2_RATING = {},
      USER_3_RATING = {},
      USER_4_RATING = {},
      USER_5_RATING = {},
    },
    TIPS: { amount: options, min_amount: min, max_amount: max },
    URL: { USER_TOPUP_URL, ETIQUTTE_URL, PRIVACY_URL, USER_FAQ, PRICE_URL },
    REG_EXPR,
    UNIFORM_INVOICE: { enable: invoiceEnable, donation_list: donationList },
  } = yield call(vanLookup, city);

  const etiqutteUrl =
    ETIQUTTE_URL[locale] && ETIQUTTE_URL[locale].replace(/\[APP\]/, 'user');

  yield Promise.all([
    i18n.addResources(
      ietfLocale,
      'translation',
      keyByLocale(ERROR_MSG)[locale]
    ),
    i18n.addResources(
      ietfLocale,
      'cancel_reasons',
      keyByLocale({
        ...USER_CANCELLATION_ASSIGNING,
        ...USER_CANCELLATION_MATCHED,
      })[locale] || {}
    ),
    i18n.addResources(
      ietfLocale,
      'rating_reasons',
      keyByLocale({
        ...USER_1_RATING,
        ...USER_2_RATING,
        ...USER_3_RATING,
        ...USER_4_RATING,
        ...USER_5_RATING,
      })[locale]
    ),
    i18n.addResources(ietfLocale, 'url', {
      ETIQUTTE_URL: etiqutteUrl,
      PRIVACY_URL: PRIVACY_URL[locale],
      FAQ_URL: USER_FAQ[locale],
      PRICING_URL: PRICE_URL[locale],
    }),
  ]);

  if (Object.values(USER_TOPUP_URL || {}).length !== 0) {
    yield put(
      updateCountrySetting({
        country: {
          id: country,
          topUpDomain: new URL(Object.values(USER_TOPUP_URL)[0]).origin,
        },
      })
    );
  }

  yield put(
    updateCitySetting({
      city: {
        id: city,
        cancelReasons: {
          [statusMap.ASSIGNING]: Object.keys(USER_CANCELLATION_ASSIGNING),
          [statusMap.ONGOING]: Object.keys(USER_CANCELLATION_MATCHED),
        },
        ratingReasons: {
          [ratingMap.GREAT]: Object.keys(USER_5_RATING),
          [ratingMap.GOOD]: Object.keys(USER_4_RATING),
          [ratingMap.NORMAL]: Object.keys(USER_3_RATING),
          [ratingMap.BAD]: Object.keys(USER_2_RATING),
          [ratingMap.TERRIBLE]: Object.keys(USER_1_RATING),
        },
        priorityFee: { options, min, max },
        donationList: invoiceEnable ? donationList : [],
        regex: REG_EXPR,
      },
    })
  );
}

export function* handleInit() {
  yield all([call(fetchLocation), call(fetchGlobalLocation)]);
  FetcherLockService.unlockApplicationStartUpLock();

  const region = yield call(getLocationFromStorageOrURL);

  // default flow: user previously used the app or specified location in URL
  if (region) {
    const { location, locale } = region;
    yield put(changeLocation({ location, locale }));
    return;
  }
  // alternative flow: for FTUs
  yield call(changeLocationByUserLocation);
}

export function* regionSaga() {
  yield takeEvery(REGION_INIT, handleInit);
  yield takeEvery(
    action => /FETCH_([A-Z]{2})_CITIES_REQUEST/.test(action.type), // matches FETCH_XX_CITIES_REQUEST
    handleFetchCities
  );
  yield takeEvery(CHANGE_LOCATION_REQUEST, handleChangeLocation);
  yield takeEvery(CHANGE_LOCALE_REQUEST, handleChangeLocale);
}
