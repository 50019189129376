import styled from 'styled-components';
import Popover from '@lalamove/react-popover';
import { DROPDOWN } from 'styles/zIndex';
import { white } from 'styles/colors';

const StyledPopover = styled(Popover)`
  z-index: ${DROPDOWN};
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.22));

  & .Popover-tip {
    fill: ${white};
  }

  & .Popover-body {
    min-width: 18rem;
    padding: 16px;
    background: ${white};
    border-radius: 2px;
  }
`;

export default StyledPopover;
