import storage from 'utils/storage';

export type Region = {
  location: string;
  locale: string;
  datacenter: string;
};

export const KEY = 'register_location';

export const loadRegisterLocation = (): Region => {
  const json: string = storage.getItem(KEY);
  return JSON.parse(json) || {};
};

export const saveRegisterLocation = (payload: Region): void => {
  storage.setItem(KEY, JSON.stringify(payload));
};
