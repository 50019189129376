import Big from 'big.js';
import {
  FETCH_BALANCE_SUCCESS,
  FETCH_STATEMENT_SUCCESS,
  FETCH_WALLET_HISTORY_SUCCESS,
  FETCH_STATEMENT_REQUEST,
  FETCH_CHARGELIST_SUCCESS,
  FETCH_COUPONLIST_SUCCESS,
  FETCH_CHARGELIST_FAILURE,
  RECHARGE_FAILURE,
  RECHARGE_SUCCESS,
  TOPUP_INIT,
} from './actions';

export const initStatementState = {
  startDate: '',
  endDate: '',
  xls: '',
  pdf: '',
  expiredAt: '',
};
export const initTopUpState = {
  chargeList: [],
  errorCode: 0,
  source: null,
};
export const initCouponState = {
  couponNum: 0,
  couponList: [],
};
export const initState = {
  creditBalance: Big(0),
  history: [],
  historyCount: 0,
  statement: initStatementState,
  statementTypes: [], // keep track statement file types being genereated
  topUpAmount: 0, // tracking
  initTopUpInfo: initTopUpState,
  coupon: initCouponState,
};

export function initTopUpInfoReducer(state = initTopUpState, action) {
  switch (action.type) {
    case TOPUP_INIT: {
      return {
        ...state,
        source: action.source,
      };
    }
    case FETCH_CHARGELIST_SUCCESS: {
      return {
        ...state,
        chargeList: action.chargeList,
      };
    }
    case FETCH_CHARGELIST_FAILURE: {
      return {
        ...state,
        chargeList: [],
        errorCode: action.errorCode,
      };
    }
    case RECHARGE_SUCCESS: {
      return {
        ...state,
        topUpModal: true,
        chargeList: [],
      };
    }
    case RECHARGE_FAILURE: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case FETCH_BALANCE_SUCCESS: {
      return {
        ...state,
        creditBalance: action.creditBalance,
      };
    }
    case FETCH_STATEMENT_SUCCESS: {
      return {
        ...state,
        statementTypes: action.statementTypes,
      };
    }
    case FETCH_WALLET_HISTORY_SUCCESS: {
      return {
        ...state,
        history: action.history,
        historyCount: action.total,
      };
    }
    case FETCH_COUPONLIST_SUCCESS: {
      return {
        ...state,
        coupon: action.coupon,
      };
    }
    case FETCH_STATEMENT_REQUEST: {
      return {
        ...state,
        statementTypes: state.statementTypes.includes(action.fileType)
          ? state.statementTypes
          : [...state.statementTypes, action.fileType],
      };
    }
    case TOPUP_INIT:
    case FETCH_CHARGELIST_SUCCESS:
    case RECHARGE_SUCCESS:
    case FETCH_CHARGELIST_FAILURE:
    case RECHARGE_FAILURE:
      return {
        ...state,
        initTopUpInfo: initTopUpInfoReducer(state.initTopUpInfo, action),
      };
    default:
      return state;
  }
}
