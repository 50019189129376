import { toggleOptimize } from 'interfaces/global/store/modules/routing/actions';
import {
  getFilledWaypts,
  getShouldOptimize,
} from 'interfaces/global/store/modules/routing/selectors';
import { getSelectedService } from 'interfaces/global/store/modules/services/selectors';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { useDispatch, useSelector } from 'react-redux';

interface OptimizeRoute {
  allowOptimize: boolean;
  shouldOptimize: boolean;
  onToggle: () => void;
}

const useOptimizeRoute = (): OptimizeRoute => {
  const shouldOptimize = useSelector(getShouldOptimize);
  const totalStops = useSelector(state => getFilledWaypts(state).length);
  const allowOptimize = useSelector(
    state => getFilledWaypts(state).length >= 3
  );
  const selectedVehicleName = useSelector(
    state => getSelectedService(state)?.name
  );

  const dispatch = useDispatch();
  const onToggle = () => {
    if (!shouldOptimize) {
      dispatch(
        track('optimize_route_tapped', {
          vehicle_type: selectedVehicleName,
          stop_total: totalStops,
        })
      );
    }
    dispatch(toggleOptimize(!shouldOptimize));
  };

  return { allowOptimize, shouldOptimize, onToggle };
};

export default useOptimizeRoute;
