import { toLLMLower } from 'utils/locale';
import FetcherFactory from 'api/FetcherFactory';
import UAPIFetcher from 'api/UAPIFetcher';
import log from 'api/log';
import { UApiError } from 'utils/helpers';

const getPlaceDetails = async placeId => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      place_id: placeId,
    }),
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
  };

  const { ret, data, msg } = await fetcher.get('?_m=map_poi_detail2', params);

  if (ret !== 0) {
    log.info('PlacesService.getDetails', {
      category: 'google-maps',
      ret,
      response: `JSON: ${JSON.stringify(data)}`, // prevent log service parsing JSON string as object
    });
    throw new UApiError(msg, ret);
  }

  return data;
};
export default getPlaceDetails;
