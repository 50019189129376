import { toLLMUpper } from 'utils/locale';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Translation {
//   id: String; // e.g. en_HK
//   value: String;
// }
// interface Response {
//   city: {
//     id: string;
//     advancedOrderMinute: number;
//     cacheVersion: string;
//     csAddress: Translation[]; // e.g. CS_CITY_ADDRESS_UPDATE
//     csAddressLine1: Translation[]; // e.g. CS_CITY_ADDRESS_LINE1_UPDATE
//     csAddressLine2: Translation[];
//     csEmail: string;
//     csPhone: string;
//     csRejectPhone: String;
//     csWorkingHours?: Translation[];
//     duplicatedOrderBlockingSecond?: number;
//     immediateOrderMinute: number;
//     insuranceForm?: Translation[];
//     insurancePhone?: string;
//     insuranceTerms?: Translation[];
//     lat: number;
//     lng: number;
//     orderEditEnabled: boolean;
//     orderTimeEstimationBeforeMatchShow: boolean;
//     orderTimeEstimationShow: boolean;
//     orderTimeEstimationShowBusyMsg: boolean;
//     priceUrl?: Translation[];
//     searchRadius: number;
//     timeToCompleteOrder: number;
//     translations: Translation[];
//     welcomeInfoVersion: number;
//     zendeskChatAccountKey: string;
//     zendeskChatEnabled: boolean;
//   };
//   country: {
//     id: string;
//     adminPanelOrderUrl: string;
//     adminPanelProfileUrl: string;
//     androidQualarooSurveyAlias?: Translation[];
//     androidQualarooSurveyNpsAlias?: String;;
//     areaCode: string;
//     cctld: string;
//     cities: any[], // ??
//     companyName: Translation[],
//     csDriverPhone: string,
//     currency: Translation[],
//     currencyExponent?: number,
//     currencySymbol: string,
//     defaultLanguage: string, // en_HK
//     facebookUrl: string,
//     flag: string, // img src
//     iosQualarooSurveyAlias: Translation[];
//     iosQualarooSurveyNpsAlias: string;
//     isDistrictOrderEnabled: boolean;
//     isEmailRequiredForSignUp: boolean;
//     isPhonePrecedingZeroReq: boolean;
//     isoCurrencyCode: string;
//     languages: any[]; // ??
//     lat: number;
//     lng: number;
//     latestAndroidVersion: number;
//     latestIosVersion: number;
//     minAndroidVersion: number;
//     minIosVersion: number;
//     samplePhone: string;
//     shareMessage: Translation[];
//     showDecimal: boolean;
//     translations: Translation[];
//     webappLoginBackground?: String;
//     websiteUrl: string;
//   };
//   footprint: String;
// }

const mapLLMUpper = ar => ar.map(x => ({ ...x, id: toLLMUpper(x.id) }));

export default async function fetchUserLocationSettings(city) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  const data = await fetcher.get(
    'userlocationsettings',
    {},
    {
      headers: {
        'X-LLM-LOCATION': city,
      },
    }
  );
  return {
    ...data,
    city: {
      ...data.city,
      translations: mapLLMUpper(data.city.translations),
      ...(data.city.csWorkingHours && {
        csWorkingHours: mapLLMUpper(data.city.csWorkingHours),
      }),
      ...(data.city.priceUrl && { priceUrl: mapLLMUpper(data.city.priceUrl) }),
      ...(data.city.insuranceForm && {
        insuranceForm: mapLLMUpper(data.city.insuranceForm),
      }),
      ...(data.city.insuranceTerms && {
        insuranceTerms: mapLLMUpper(data.city.insuranceTerms),
      }),
    },
    country: {
      ...data.country,
      currency: mapLLMUpper(data.country.currency),
      translations: mapLLMUpper(data.country.translations),
    },
  };
}
