import { useEffect } from 'react';
import { useDispatch, batch } from 'react-redux';
import { updatePaymentMethod } from 'interfaces/global/store/modules/checkout/actions';
import { PaymentMethods } from 'interfaces/global/store/modules/checkout/types';
import { setSelectedCoupon } from 'interfaces/global/store/modules/pricing/actions';

const useCashOnly = (): void => {
  const dispatch = useDispatch();
  useEffect(() => {
    const cashId = PaymentMethods.CASH.id;
    batch(() => {
      dispatch(updatePaymentMethod(cashId));
      dispatch(
        setSelectedCoupon({ selectedPaymentMethodId: cashId, coupon: null })
      );
    });
  }, [dispatch]);
};

export default useCashOnly;
