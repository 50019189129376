import md5 from 'md5';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { sessionService } from 'redux-react-session';
import { replace } from 'connected-react-router';

import { vanauthlogin } from 'api/mobileAPI';
import { login } from 'api/authAPI';
import validator from 'utils/validator';

// action-types
import {
  REQUEST_LOGOUT,
  FB_LOGIN_REQUEST,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FB_LOGIN_SUCCESS,
  FB_LOGIN_FAILURE,
  REFRESH_LOGIN_SESSION_REQUEST,
  fbUserDataReceived,
  refreshLoginSessionSuccess,
  refreshLoginSessionFailure,
} from './actions';
import { getUser } from './selectors';

// side effects
export function* onLogin({ username, password, areaCode }) {
  try {
    const { valid, message } = validator.email(username);
    if (!/^\d+$/.test(username) && !valid) {
      throw new Error(message);
    }

    const data = yield call(authenticate, {
      username,
      password,
      areaCode,
    });

    yield put({ type: LOGIN_SUCCESS });
    yield call([sessionService, 'saveSession'], data.access_token);
    yield call([sessionService, 'saveUser'], data);
  } catch (err) {
    yield put({
      type: LOGIN_FAILURE,
      meta: {
        type: 'error',
        message: err.message,
        data: 'llm.vanauthlogin',
      },
    });
  }
}

export function* onFBLogin({ areaCode, user }) {
  const password = md5(user.id);

  try {
    const data = yield call(authenticate, {
      username: user.id,
      password,
      areaCode,
    });

    yield put({ type: FB_LOGIN_SUCCESS });
    yield call([sessionService, 'saveSession'], data.access_token);
    yield call([sessionService, 'saveUser'], data);
    yield put(replace('/'));
  } catch (err) {
    yield put({ type: FB_LOGIN_FAILURE });
    if (user) {
      // no llm account is associated with this fb account
      yield put(fbUserDataReceived(user));
      yield put(replace('/account'));
    }
  }
}

export function* authenticate({ username, password, areaCode }) {
  const data = yield call(login, username, password, areaCode);
  const { access_token: accessToken, client_id: clientId } = data;
  yield call(vanauthlogin, accessToken, clientId);
  return data;
}

export function* onRefreshLoginSession() {
  const user = yield select(getUser);
  const { access_token: accessToken = '', client_id: clientId = '' } =
    user || {};
  if (!accessToken) {
    yield put(refreshLoginSessionFailure());
    return;
  }

  try {
    yield call(vanauthlogin, accessToken, clientId); // stateful city
    yield put(refreshLoginSessionSuccess());
  } catch (error) {
    yield put(refreshLoginSessionFailure());
    console.error('Refresh Login Session: ', error); // eslint-disable-line no-console
  }
}

function* onLogout() {
  yield call([sessionService, 'deleteSession']);
  yield call([sessionService, 'deleteUser']);
}

export function* loginSaga() {
  yield takeLatest(LOGIN_REQUEST, onLogin);
  yield takeLatest(FB_LOGIN_REQUEST, onFBLogin);
  yield takeLatest(REFRESH_LOGIN_SESSION_REQUEST, onRefreshLoginSession);
  yield takeLatest([REQUEST_LOGOUT, LOGIN_FAILURE], onLogout);
}
