import { convertLocaleInKey } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Request {
//   access_token: String;
//   client_id: Number | String;
//   country: String;
//   args: {
//     redeem: String,
//     latlong: String, // a|b,c,d
//     normal_req: { service_type: string }[],
//     special_req: { option: string }[],
//     timestamp?: Number,
//   };
// }
// interface Response {
//   basic: Number;
//   calculate_mode: String;
//   discount_total: Number;
//   favorite_count: Number;
//   fleetSelectable: Boolean;
//   idPromoCode: null | Number;
//   orderTimeEstimation: {
//     adjustment: Number,
//     baseArrivalTime: Number, // unix time
//     baseDuration: Number,
//     clientOrderId: String,
//     estimationType: String,
//     range: Number,
//   };
//   order_type: String;
//   payment_server: {
//     amount_due: Number,
//     free_credit_used: Number,
//     prepayment_possible: Boolean,
//     rewards_remain: Number,
//     user_prepaid_credits: Number,
//   };
//   promo_action: null;
//   promo_easyvan: Number;
//   promo_partner: Number;
//   service_type: String;
//   special_req: {
//     id: Number,
//     name: String,
//     is_quote: Boolean,
//     price: Number,
//   };
//   special_req_total: Number;
//   specialday_type: null;
//   surcharge: {
//     [key: String]: {
//       charge: Number,
//       id: Number,
//       order: Number,
//       [name_lang: String]: String,
//       [remarks_lang: String]: String,
//     }[],
//   };
//   surcharge_total: Number;
//   timeslot_charge: Number;
//   total: Number;
//   total_distance: Number;
//   use_fleet_enable: Boolean;
// }
// MobileAPIResponse<Response>
/**
 * Fetches price
 * @param {string} promoCode promo code
 * @param {string} latlong latitude|longitude of stops, e.g. '22.31|113.95,22.28|114.17'
 * @param {array<object>} normalReq service type, e.g. [{ service_type: 'VAN' }]
 * @param {array<object>} specialReq additional services, e.g. [{ option: 'ENGLISH', sub_option: 'BRITISH' }]
 * @param {number} timestamp order date & time represented in unix time
 * @return {promise} resolve to a price object, e.g. { basic: 50, surchage_total: 20, ..., total: 70 }
 * @throws ERROR_INVALID_SERVICETYPE, ERROR_NOT_SERVICEAREA,
 */
export default async function priceCal({
  promoCode,
  latlong,
  normalReq,
  specialReq,
  timestamp,
}) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, city } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    country: city,
    args: JSON.stringify({
      redeem: promoCode,
      latlong,
      normal_req: normalReq, // service type
      special_req: specialReq, // additional services
      ...(timestamp && { timestamp }),
    }),
  };
  const { success, data, code } = await fetcher.get('vanpricecal', params);

  if (!success) throw code;

  return convertLocaleInKey(data);
}
