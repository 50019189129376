import styled from 'styled-components';
import { List } from '@lalamove/karang';

import { DROPDOWN } from 'styles/zIndex';

export const PickerButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  border: none;
  background-color: #f1f1f1;
  border-radius: 25px;
  color: #585858;
  outline: 0;
  box-shadow: none !important;
  cursor: pointer;
  transition: opacity 800ms ease-in-out;

  &:disabled {
    opacity: 0.5;
  }
`;

export const Label = styled.span`
  margin-right: 12px;
`;

export const ListWrapper = styled.div`
  padding: 8px 0;

  & + & {
    border-top: 1px solid #d8d8d8;
  }
`;

export const ListContainer = styled.div`
  position: absolute;
  z-index: ${DROPDOWN};
  top: 100%;
  right: 0;
  display: inline-block;
  box-sizing: border-box;
  min-width: 200px;
  background-color: #fff;
  color: #303030;
  text-align: left;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.65, 0, 0.35, 1);

  &.enter-active {
    opacity: 1;
  }

  &.enter-done {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
  }

  &.exit-done {
    opacity: 0;
  }
`;

export const StyledList = styled(List)`
  width: 100%;
  min-width: initial;
  box-shadow: none;
  background-color: transparent;
`;

export const ItemWrapper = styled.li`
  display: flex;
  align-items: center;
  padding: 12px 8px 12px 10px;
  border-left: 2px solid transparent;
  cursor: pointer;
  outline: 0;

  &:hover {
    border-left: 2px solid #f16622;
    background-color: #f9f9f9;
  }
`;

export const Content = styled.div`
  flex: 1;
  color: #303030;
  font-size: 14px;
`;

export const Icon = styled.div`
  margin-right: 4px;
`;
