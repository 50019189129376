import React from 'react';
import styled from 'styled-components';
import { Props as ModalProps } from 'react-modal';
import { RadioGroup, List } from '@lalamove/karang';
import { noop } from 'utils/helpers';
import BaseModal from 'components/BaseModal';

export const StyledModal = styled(BaseModal)`
  & .ReactModal__Content {
    overflow: hidden;
    overflow-y: auto;
    width: 90%;
    max-width: 400px;
    max-height: 80vh;
  }
`;

const StyledList = styled(List)`
  width: 100%;

  > li > div {
    padding: 0;
    label {
      position: relative;
      display: block;
      padding: 12px 20px 12px 0;

      > span {
        pointer-events: none;

        &:last-child {
          width: calc(100% - 16px - 0.5em);
        }
      }
    }
  }
`;

type KarangComponent = React.FC<any>;

interface Render<T> {
  data: T;
  Item: KarangComponent;
  getProps: () => any;
}

export interface Option {
  value: string;
  label?: string;
  options?: Option[];
}

// deprecated
export type Value = Option;

interface Props extends ModalProps {
  value: Option;
  options: Option[];
  onChange?: (value: Option) => void;
  onInput?: (value: Option) => void;
  name: string;
}

const SelectModal: React.FC<Props> = ({
  onChange = noop,
  onInput = noop,
  options,
  value,
  name,
  ...props
}) => {
  const onRadioGroupChange = (val: string) => {
    const foundOption = options.find(opt => opt.value === val);
    if (foundOption) {
      onChange(foundOption);
      onInput(foundOption);
    }
  };

  const onItemClick = (e: React.MouseEvent, itemValue: string) => {
    if (e.target === e.currentTarget && value.value === itemValue) {
      const foundOption = options.find(opt => opt.value === itemValue);
      if (foundOption) {
        onInput(foundOption);
      }
    }
  };

  return (
    <StyledModal {...props}>
      <RadioGroup value={value.value} name={name} onChange={onRadioGroupChange}>
        {(Radio: KarangComponent) => (
          <StyledList
            unique="value"
            items={options}
            hoverable
            render={({ data, Item, getProps }: Render<Option>) => (
              <Item {...getProps()}>
                <Radio
                  value={data.value}
                  onClick={(e: React.MouseEvent) => onItemClick(e, data.value)}
                >
                  {data.label || data.value}
                </Radio>
              </Item>
            )}
          />
        )}
      </RadioGroup>
    </StyledModal>
  );
};

export default SelectModal;
