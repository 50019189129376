import { useEffect, useState } from 'react';

export const SCROLL_TO_TOP_VISIBLE_THRESHOLD = 1792;

interface Result {
  isScrollToTopVisible: boolean;
}

export default function useIsScrollToTopVisible(): Result {
  const [isScrollToTopVisible, setIsScrollToTopVisible] = useState(false);

  useEffect(() => {
    const scrollingListener = () => {
      setIsScrollToTopVisible(
        window.pageYOffset > SCROLL_TO_TOP_VISIBLE_THRESHOLD
      );
    };

    document.addEventListener('scroll', scrollingListener);

    return () => {
      document.removeEventListener('scroll', scrollingListener);
    };
  }, [setIsScrollToTopVisible]);

  return { isScrollToTopVisible };
}
