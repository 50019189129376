import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';

import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

const saveAddressHistory = async (poiId, addrType, addrInfo) => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      poiid: poiId,
      addr_type: addrType,
      addr_info: addrInfo,
    }),
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
    os: 'web',
  };

  const { ret, msg } = await fetcher.get('?_m=add_search_history', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }
};

export default saveAddressHistory;
