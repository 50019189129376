import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styled from 'styled-components';

import { CloseButton } from 'components/Button';
import { MODAL } from 'styles/zIndex';
import { black } from 'styles/colors';
import { primaryFonts, fontWeight } from 'styles/fonts';
import { noop } from 'utils/helpers';
import CloseSVG from 'assets/svg/close.svg';

Modal.setAppElement(document.querySelector('#🚐'));
Modal.defaultStyles.overlay = {
  ...Modal.defaultStyles.overlay,
  backgroundColor: 'rgba(0,0,0,0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: MODAL,
};

Modal.defaultStyles.content = {
  position: 'relative',
  border: '1px solid #ccc',
  background: '#fff',
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  outline: 'none',
  padding: '20px',
  borderRadius: '2px',
  width: '32rem',
  maxWidth: '32rem',
  marginTop: '-14rem',
};

const Container = styled.div`
  height: 100%;
`;

const Content = styled.div`
  height: 100%;
`;

const Close = styled(CloseButton)`
  ${/* sc-selector */ Container} & {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

const CloseOutside = styled.img`
  ${/* sc-selector */ Container} & {
    position: absolute;
    top: -30px;
    right: -40px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
`;

const Title = styled.h3`
  color: ${black};
  font-family: ${primaryFonts};
  font-size: 1.286em;
  font-weight: ${fontWeight.bold};
  text-align: center;
`;

// eslint-disable-next-line react/prop-types
const SelectClose = ({ closeType, onClose }) => {
  if (closeType === 'outside') {
    return <CloseOutside onClick={onClose} src={CloseSVG} />;
  }

  return <Close onClick={onClose}>Close</Close>;
};

const AppModal = ({ title, children, onClose, closeType, ...props }) => (
  <Modal {...props} onRequestClose={onClose}>
    <Container>
      {title && <Title>{title}</Title>}
      <Content>{children}</Content>
      <SelectClose onClose={onClose} closeType={closeType} />
    </Container>
  </Modal>
);

AppModal.defaultProps = {
  title: '',
  name: 'untitled',
  isOpen: false,
  onClose: noop,
  children: null,
  closeType: '',
};

AppModal.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  closeType: PropTypes.string,
};

export default AppModal;
