export const ROUTE_REARRANGE = 'ROUTE_REARRANGE';
export const WAYPOINT_NEW = 'WAYPOINT_NEW';
export const WAYPOINT_REMOVE = 'WAYPOINT_REMOVE';
export const WAYPOINT_UPDATE = 'WAYPOINT_UPDATE';
export const DELIVERY_INFO_UPDATE = 'DELIVERY_INFO_UPDATE';
export const ROUTE_OPTIMIZE_TOGGLE = 'ROUTE_OPTIMIZE_TOGGLE';
export const ROUTE_WAYPOINTS_SET = 'ROUTE_WAYPOINTS_SET';
export const ROUTE_WAYPOINTS_TRIM = 'ROUTE_WAYPOINTS_TRIM';
export const GOOGLE_DIRECTION_RESULT_RECEIVED =
  'GOOGLE_DIRECTION_RESULT_RECEIVED';
export const IMPORT_REQUEST = 'IMPORT_REQUEST';
export const IMPORT_SUCCESS = 'IMPORT_SUCCESS';
export const IMPORT_FAILURE = 'IMPORT_FAILURE';
export const WAYPOINT_ERROR_TOGGLE = 'WAYPOINT_ERROR_TOGGLE';
export const SESSION_TOKEN_UPDATE = 'SESSION_TOKEN_UPDATE';
