import { useSelector, shallowEqual } from 'react-redux';
import { getWelcomeMessage } from 'interfaces/global/store/modules/services/selectors';
import { getUser } from 'interfaces/global/store/modules/auth/selectors';

interface WelcomeMessage {
  firstName: string;
  message: string;
  banner: string;
}

const useWelcomeMessage = (): WelcomeMessage => {
  const firstName = useSelector(state => getUser(state).profile.first_name);
  const { message, banner } = useSelector(getWelcomeMessage, shallowEqual);

  return { firstName, message, banner };
};

export default useWelcomeMessage;
