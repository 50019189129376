import { REQUEST_LOGOUT } from 'interfaces/global/store/modules/auth/actions';
import { CHANGE_LOCATION_REQUEST } from 'store/modules/region/actions';
import { FETCH_SAVED_DRIVERS_SUCCESS, REMOVE_DRIVER_SUCCESS } from './actions';

export const initState = {
  drivers: {},
  favoriteIds: [],
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case CHANGE_LOCATION_REQUEST:
    case REQUEST_LOGOUT:
      return initState;
    case FETCH_SAVED_DRIVERS_SUCCESS: {
      const { drivers } = action;
      return {
        ...state,
        drivers: {
          ...state.drivers,
          ...drivers,
        },
        favoriteIds: Object.keys(drivers),
      };
    }
    case REMOVE_DRIVER_SUCCESS: {
      const { [action.driverId]: __, ...rest } = state.drivers;
      return {
        ...state,
        drivers: rest,
        favoriteIds: state.favoriteIds.filter(id => id !== action.driverId),
      };
    }
    default: {
      return state;
    }
  }
}
