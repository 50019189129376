import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Heading, HeadingGroup } from '@lalamove/karang';
import _isEqual from 'lodash/isEqual';
import _invert from 'lodash/invert';
import _mapKeys from 'lodash/mapKeys';
import _pick from 'lodash/pick';
import _pickBy from 'lodash/pickBy';

import { getPrice } from 'store/modules/pricing';
import {
  getFilledWaypts,
  toggleWayptError,
  getErrorByStopItemId,
  getOrderIdByWayptId,
} from 'store/modules/routing';
import { DRAFT_ORDER_ID } from 'store/modules/routing/config';
import {
  getDeliveryInfoByWayptId,
  updateDeliveryInfo,
} from 'store/modules/routing/deliveryInfo';
import { ERROR_INVALID_PHONE_NUMBER_WITH_PREFIX } from 'store/modules/routing/errorTypes';
import { SEGMENT_DELIVERY_INFO_UPDATE } from 'store/modules/placeOrder/tracking';
import { getSelectedServiceAndSubServiceIds } from 'store/modules/services';

import { noop, compose } from 'utils/helpers';
import Segment from 'utils/segment';
import validator from 'utils/validator';

import { white, gray } from 'styles/colors';
import Popover from 'components/Popover';
import Form from './Form'; // eslint-disable-line import/no-named-as-default

const Container = styled.div`
  width: 24rem;
  padding: 4px;
`;

const Circle = styled.span`
  width: 18px;
  height: 18px;
  margin-right: 1em;
  background: ${({ color }) => color};
  border-radius: 50%;
  color: ${white};
  font-size: 0.714em;
  line-height: 18px;
  text-align: center;
`;

const { string, bool, func, number, instanceOf, shape } = PropTypes;

export class DeliveryInfo extends PureComponent {
  static defaultProps = {
    orderId: DRAFT_ORDER_ID,
    isOpen: true,
    children: noop,
    parent: null,
    index: 0,
    indexColor: gray,
    info: {},
    onClose: noop,
    updateDeliveryInfo: noop,
    t: noop,
    errors: {},
    toggleWayptError: noop,
    // for tracking purposes:
    price: { total: 0 },
    serviceIds: { serviceId: '', subServiceId: '' },
  };

  static propTypes = {
    waypointId: string.isRequired,
    orderId: string,
    isOpen: bool,
    children: func,
    parent: instanceOf(Element), // has to be a DOM element
    index: number,
    indexColor: string,
    info: shape({
      name: string,
      phone: string,
      addressDetails: string,
    }),
    onClose: func,
    updateDeliveryInfo: func,
    t: func,
    errors: shape({ phone: string }),
    toggleWayptError: func,
    // for tracking purposes:
    price: shape({ total: number }),
    serviceIds: shape({ serviceId: string, subServiceId: string }),
  };

  state = {
    name: this.props.info.name || '',
    phone: this.props.info.phone || '',
    addressDetails: this.props.info.addressDetails || '',
    isDirty: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const diff = _pickBy(this.state, (val, key) => prevState[key] !== val);

    if (Object.keys(diff).length > 0) {
      const keys = ['name', 'phone', 'addressDetails'];
      const formData = _pick(this.state, keys);
      const formDataFromProps = _pick(this.props.info, keys);

      const isDirty = !_isEqual(formData, formDataFromProps);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isDirty });
    }

    if (!_isEqual(prevProps.info, this.props.info)) {
      const { info } = this.props;
      const { phone, ...infoWithoutPhone } = info;
      // validate phone
      const { valid, message } = validator.phone(phone, true);
      const nextState = phone ? info : infoWithoutPhone;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(nextState, () => {
        // eslint-disable-next-line no-shadow
        const { updateDeliveryInfo, waypointId } = this.props;

        if (phone && !valid) {
          updateDeliveryInfo(waypointId, { phone: '' });
          this.handleError(message);
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.toggleWayptError(
      this.props.waypointId,
      ERROR_INVALID_PHONE_NUMBER_WITH_PREFIX,
      false
    );
  }

  handleSave = ({ name, phone, addressDetails }) => {
    const {
      onClose,
      updateDeliveryInfo, // eslint-disable-line no-shadow
      waypointId,
      orderId,
      index,
      price,
      serviceIds,
    } = this.props;
    updateDeliveryInfo(waypointId, {
      name,
      phone: phone.trim(),
      addressDetails,
    });

    // tracking:
    if (orderId === DRAFT_ORDER_ID) {
      Segment.createTrack(SEGMENT_DELIVERY_INFO_UPDATE, {
        source: index === 1 ? 'pick up' : 'drop off',
        total_price_shown: price.total,
        vehicle_type: serviceIds.serviceId,
        vehicle_subtype: serviceIds.subServiceId,
      });
    }

    onClose();
    this.setState({ isDirty: false });
  };

  handleChange = ({ target: { name, value } }) => {
    const { waypointId } = this.props;
    let newValue = value;
    // reset error
    if (name === 'phone') {
      this.props.toggleWayptError(
        waypointId,
        ERROR_INVALID_PHONE_NUMBER_WITH_PREFIX,
        false
      );
      newValue = value.trim();
    }
    this.setState(state => ({
      ...state,
      [name]: newValue,
    }));
  };

  handleCancel = () => {
    this.props.onClose();
    this.setState({ ...this.props.info, isDirty: false });
    this.props.toggleWayptError(
      this.props.waypointId,
      ERROR_INVALID_PHONE_NUMBER_WITH_PREFIX,
      false
    );
  };

  handleError = message => {
    const { waypointId } = this.props;
    this.props.toggleWayptError(waypointId, message, true);
  };

  render() {
    const {
      t,
      isOpen,
      children,
      index,
      indexColor,
      errors,
      parent,
      onClose,
    } = this.props;
    const { isDirty, name, phone, addressDetails } = this.state;

    const content = (
      <Container>
        <HeadingGroup>
          {index > 0 && <Circle color={indexColor}>{index}</Circle>}
          <Heading htmlTag="h4">
            {t('PlaceOrder.heading_delivery_info')}
          </Heading>
        </HeadingGroup>
        <Form
          name={name}
          phone={phone}
          addressDetails={addressDetails}
          errors={errors}
          onSave={this.handleSave}
          onChange={this.handleChange}
          onCancel={this.handleCancel}
          onError={this.handleError}
        />
      </Container>
    );
    const childrenToRender = children({ isDirty });

    return (
      <Popover
        isOpen={isOpen}
        preferPlace="right"
        target={childrenToRender}
        onOuterAction={onClose}
        body={content}
        style={{
          position: 'fixed',
        }}
        {...(parent && {
          appendTarget: parent,
        })}
      >
        {childrenToRender}
      </Popover>
    );
  }
}

const keysMap = {
  [ERROR_INVALID_PHONE_NUMBER_WITH_PREFIX]: 'phone',
};

const mapState = (state, { waypointId }) => {
  const orderId = getOrderIdByWayptId(state, waypointId);
  return {
    info: getDeliveryInfoByWayptId(state, waypointId),
    errors: _mapKeys(
      _invert(
        _pick(
          _pickBy(getErrorByStopItemId(state, waypointId)),
          Object.keys(keysMap)
        )
      ),
      (val, key) => keysMap[val]
    ),
    index:
      getFilledWaypts(state, orderId)
        .map(wp => wp.id)
        .indexOf(waypointId) + 1,
    // for tracking purposes:
    orderId,
    price: getPrice(state),
    serviceIds: getSelectedServiceAndSubServiceIds(state),
  };
};

export default compose(
  withTranslation(),
  connect(mapState, { updateDeliveryInfo, toggleWayptError })
)(DeliveryInfo);
