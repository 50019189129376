import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';

export const WaypointShape = shape({
  name: string,
  lat: number,
  lng: number,
  addressLanguage: string,
});

export const DeliveryInfoShape = shape({
  name: string,
  phone: string,
  addressDetails: string,
});

export const RouteShape = shape({
  waypoints: arrayOf(WaypointShape),
  deliveryInfo: arrayOf(DeliveryInfoShape),
});

export const PriceShape = shape({
  fee: number,
  services: number,
  surcharge: number,
  discount: number,
  tips: number,
  rewards: number,
  total: number,
});

export const DriverShape = shape({
  id: number,
  name: string,
  phone: string,
  vehicleTypeId: string,
  licensePlate: string,
  avgRating: number,
  photoUri: string,
  canTrackDriver: bool,
  favorited: bool,
  banned: bool,
});

export const OrderShape = shape({
  id: string,
  refId: string,
  shareId: string,
  status: oneOf(Array.from({ length: 7 }, (v, i) => i)), // [0, ..., 6]
  deliveryDatetime: string, // ISO: YYYY-MM-DDTHH:mm:ss
  creationDatetime: string, // ISO: YYYY-MM-DDTHH:mm:ss
  driverId: number,
  canShowDriverInfo: bool,
  creatorName: string,
  creatorPhone: string,
  noteToDriver: string,
  serviceTypeId: string,
  paymentMethod: oneOf(['CASH', 'CREDITS']), // will have others in the future
  podSignedBy: string,
  podDatetime: string,
  userRating: number,
  editable: bool,
  edited: bool,
  specialRequests: arrayOf(string),
  subRequests: shape({ [string]: string }),
  waypoints: arrayOf(WaypointShape),
  price: PriceShape,
  driver: DriverShape,
});
