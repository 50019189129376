// jest not yet support mutationObserver testing
export function elementExistPromise(selectorFunc, parent = document.body) {
  const exists = selectorFunc(parent);
  if (exists && exists.length !== 0) {
    return Promise.resolve(exists);
  }
  return new Promise(resolve => {
    const mu = new MutationObserver((list, me) => {
      const record = list.find(({ target }) => {
        const x = selectorFunc(target);
        return x && x.length !== 0;
      });
      if (record) {
        me.disconnect();
        resolve(selectorFunc(record.target));
      }
    });
    mu.observe(parent, {
      childList: true,
      subTree: true,
      attributes: true,
    });
  });
}

export function elementNotExistsPromise(selectorFunc, parent = document.body) {
  const exists = selectorFunc(parent);
  if (!exists || (exists && exists.length === 0)) {
    return Promise.resolve();
  }
  return new Promise(resolve => {
    const mu = new MutationObserver((list, me) => {
      const x = selectorFunc(parent);
      if (!x || (x && x.length === 0)) {
        me.disconnect();
        resolve();
      }
    });
    mu.observe(parent, {
      childList: true,
      subTree: true,
      attributes: true,
    });
  });
}

export const findNode = (fn, node) => {
  if (fn(node)) {
    return node;
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < node.children.length; i++) {
    const child = findNode(fn, node.children.item(i));
    if (child) {
      return child;
    }
  }
  return null;
};
