import { LalamoveApiError } from 'utils/helpers';

import AuthAPIFetcher from 'api/AuthAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Request {
//   phone_verification_code: String | Number;
//   client_id: String | Number;
//   secret: String;
//   country: String;
// }
// interface Response {
//   access_token: String;
//   account_type: String; // REGISTRATION
//   client_id: Number;
//   contact_info: {
//     contact_email: String,
//     contact_name: String,
//     contact_phone: String,
//     oauth_client: Number,
//   }[];
//   corporate_code: null;
//   corporate_id: null;
//   corporate_invitations: any[];
//   corporation: null;
//   country: String; // lower case
//   country_code: Number;
//   email: String;
//   email_verified_status: Boolean;
//   expires_in: Number; // second
//   is_kiosk: Boolean;
//   is_marketing_opt_in: null;
//   is_pod_enabled: Boolean;
//   last_name: String;
//   login_status: Boolean;
//   name: String;
//   partner_id: null;
//   password_set: Boolean;
//   phone_number: String;
//   phone_verified_status: Boolean;
//   social_login_id: String | null;
//   subscriptions: {
//     client_id: Number,
//     contact_email: String,
//     contact_name: String,
//     contact_phone: String,
//     country: String, // lower case
//     country_code: Number,
//     date_subscribed: Number, // unix time
//     email_pref_language: String,
//     subscription: Number,
//     type: String, // 'DAILY_E_ERECEIPT' | 'WEEKLY_E_ERECEIPT'
//   }[];
// }
// pin verification for register
export default async function verifyCode(pin, clientId, secret) {
  const fetcher = FetcherFactory.make(AuthAPIFetcher);
  const { country, city } = await fetcher.getState();
  const params = {
    phone_verification_code: pin,
    client_id: clientId,
    secret,
    // auth-service only take two-characters country code
    country,
    city,
  };
  const res = await fetcher.post('verifycode', params);
  const { success, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return {
    ...res,
    data: {
      ...res.data,
      client_id: String(res.data.client_id),
      corporate_id: res.data.corporate_id
        ? String(res.data.corporate_id)
        : null,
    },
  };
}
