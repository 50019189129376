import { REFRESH_LOGIN_SESSION_SUCCESS } from 'store/modules/auth/actions';

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'VERIFY_FAILURE';

export const RESEND_REQUEST = 'RESEND_REQUEST';
export const RESEND_SUCCESS = 'RESEND_SUCCESS';
export const RESEND_FAILURE = 'RESEND_FAILURE';
export const RESEND_LOCKING = 'RESEND_LOCKING'; // lock resend for 60 secs
export const RESEND_RELEASE = 'RESEND_RELEASE'; // release the 60 secs resend lock

export const VERIFY_PIN_REQUEST = 'VERIFY_PIN_REQUEST';
export const VERIFY_PIN_SUCCESS = 'VERIFY_PIN_SUCCESS';
export const VERIFY_PIN_FAILURE = 'VERIFY_PIN_FAILURE';

export const RESEND_PIN_REQUEST = 'RESEND_PIN_REQUEST';
export const RESEND_PIN_SUCCESS = 'RESEND_PIN_SUCCESS';
export const RESEND_PIN_FAILURE = 'RESEND_PIN_FAILURE';
export const RESEND_PIN_RELEASE = 'RESEND_PIN_RELEASE';

export const RESET_PW_REQUEST = 'RESET_PW_REQUEST';
export const RESET_PW_SUCCESS = 'RESET_PW_SUCCESS';
export const RESET_PW_FAILURE = 'RESET_PW_FAILURE';

export const FORGOT_PW_VERIFY_PIN_INIT = 'FORGOT_PW_VERIFY_PIN_INIT';
export const FORGOT_PW_RESEND_PIN_INIT = 'FORGOT_PW_RESEND_PIN_INIT';
export const FORGOT_PW_RESET_PW_INIT = 'FORGOT_PW_RESET_PW_INIT';

export const CHANGE_PW_VERIFY_PIN_INIT = 'CHANGE_PW_VERIFY_PIN_INIT';
export const CHANGE_PW_RESEND_PIN_INIT = 'CHANGE_PW_RESEND_PIN_INIT';
export const CHANGE_PW_RESET_PW_INIT = 'CHANGE_PW_RESET_PW_INIT';

export const FB_LOGIN_REQUEST = 'FB_LOGIN_REQUEST';
export const FB_LOGIN_SUCCESS = 'FB_LOGIN_SUCCESS';
export const FB_LOGIN_CANCEL = 'FB_LOGIN_CANCEL';
export const FB_LOGIN_FAILURE = 'FB_LOGIN_FAILURE';
export const FB_USER_DATA_RECEIVED = 'FB_USER_DATA_RECEIVED';
export const STORE_SIGNED_PROFILE = 'STORE_SIGNED_PROFILE';

export const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAILURE = 'GOOGLE_LOGIN_FAILURE';
export const GOOGLE_LOGIN_PROFILE_RECEIVED = 'GOOGLE_LOGIN_PROFILE_RECEIVED';

export const SET_CHANGE_PW_STEP = 'SET_CHANGE_PW_STEP';
export const RESET_CHANGE_PW_STEPS = 'RESET_CHANGE_PW_STEPS';

export const ENABLE_ACTIVE_FETCH_PROFILE = 'ENABLE_ACTIVE_FETCH_PROFILE';
export const DISABLE_ACTIVE_FETCH_PROFILE = 'DISABLE_ACTIVE_FETCH_PROFILE';
export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';
export const SET_PROFILE_TYPE_REQUEST = 'SET_PROFILE_TYPE_REQUEST';
export const SET_PROFILE_TYPE_SUCCESS = 'SET_PROFILE_TYPE_SUCCESS';
export const SET_PROFILE_TYPE_FAILURE = 'SET_PROFILE_TYPE_FAILURE';

export const UPDATE_PROFILE_FLAG_REQUEST = 'UPDATE_PROFILE_FLAG_REQUEST';
export const UPDATE_PROFILE_FLAG_SUCCESS = 'UPDATE_PROFILE_FLAG_SUCCESS';
export const UPDATE_PROFILE_FLAG_FAILURE = 'UPDATE_PROFILE_FLAG_FAILURE';
export const PROMPT_ADD_BUSINESS_PROFILE = 'PROMPT_ADD_BUSINESS_PROFILE';
export const PROMPT_REMOVE_BUSINESS_PROFILE = 'PROMPT_REMOVE_BUSINESS_PROFILE';
export const ADD_BUSINESS_PROFILE = 'ADD_BUSINESS_PROFILE';
export const REMOVE_BUSINESS_PROFILE = 'REMOVE_BUSINESS_PROFILE';
export const PROFILE_SWITCHED = 'PROFILE_SWITCHED';

/**
 * Common pattern matcher for redux saga
 * Matches on login, register & refresh session success
 * @param {Action} action redux action
 */
export const AUTH_SUCCESS = action =>
  action.type.endsWith('LOGIN_SUCCESS') ||
  action.type === REFRESH_LOGIN_SESSION_SUCCESS;

export const registerSubmit = ({
  firstName,
  lastName,
  email,
  country,
  countryCode,
  mobile,
  password,
  lang,
  socialLoginId,
  socialSource,
  signedProfile,
  marketingOptIn,
}) => ({
  type: REGISTER_REQUEST,
  firstName,
  lastName,
  email,
  country,
  countryCode,
  mobile,
  password,
  lang,
  socialLoginId,
  socialSource,
  signedProfile,
  marketingOptIn,
});

export const facebookLogin = ({ areaCode, accessToken, user }) => ({
  type: FB_LOGIN_REQUEST,
  areaCode,
  accessToken,
  user,
});

export const cancelFacebookLogin = () => ({
  type: FB_LOGIN_CANCEL,
});

export const fbUserDataReceived = fbUser => ({
  type: FB_USER_DATA_RECEIVED,
  fbUser,
});

export const verifyRequest = ({ pin }) => ({
  type: VERIFY_REQUEST,
  pin,
});

export const resendRequest = ({ blockDuration }) => ({
  type: RESEND_REQUEST,
  blockDuration,
});

export const resendLocking = ({ blockDuration }) => ({
  type: RESEND_LOCKING,
  blockDuration,
});

export const verifyPinRequest = ({
  phoneNumber,
  username,
  areaCode,
  smsCode,
}) => ({
  type: VERIFY_PIN_REQUEST,
  phoneNumber,
  username,
  areaCode,
  smsCode,
});

export const resendPinRequest = ({
  phoneNumber,
  username,
  areaCode,
  waitDuration,
}) => ({
  type: RESEND_PIN_REQUEST,
  phoneNumber,
  username,
  areaCode,
  waitDuration,
});

export const resetPwRequest = ({
  verifyToken,
  phoneNumber,
  username,
  password,
  areaCode,
}) => ({
  type: RESET_PW_REQUEST,
  verifyToken,
  phoneNumber,
  username,
  password,
  areaCode,
});

export const changePasswordResendPinRequest = ({
  phoneNumber,
  waitDuration,
}) => ({
  type: CHANGE_PW_RESEND_PIN_INIT,
  phoneNumber,
  waitDuration,
});

export const changePasswordVerifyPinRequest = ({ phoneNumber, smsCode }) => ({
  type: CHANGE_PW_VERIFY_PIN_INIT,
  phoneNumber,
  smsCode,
});

export const changePasswordResetPwRequest = ({
  verifyToken,
  phoneNumber,
  password,
}) => ({
  type: CHANGE_PW_RESET_PW_INIT,
  verifyToken,
  phoneNumber,
  password,
});

export const forgotPasswordResendPinRequest = ({
  username,
  areaCode,
  waitDuration,
}) => ({
  type: FORGOT_PW_RESEND_PIN_INIT,
  username,
  areaCode,
  waitDuration,
});

export const forgotPasswordVerifyPinRequest = ({
  smsCode,
  username,
  areaCode,
}) => ({
  type: FORGOT_PW_VERIFY_PIN_INIT,
  smsCode,
  username,
  areaCode,
});

export const forgotPasswordResetPwRequest = ({
  verifyToken,
  username,
  password,
  areaCode,
}) => ({
  type: RESET_PW_REQUEST,
  verifyToken,
  username,
  password,
  areaCode,
});

export const googleLoginRequest = ({ areaCode, authInstance }) => ({
  type: GOOGLE_LOGIN_REQUEST,
  areaCode,
  authInstance,
});

export const googleLoginProfileReceived = profile => ({
  type: GOOGLE_LOGIN_PROFILE_RECEIVED,
  profile,
});

export const storeSignedProfile = ({ signedProfile }) => ({
  type: STORE_SIGNED_PROFILE,
  signedProfile,
});

export const setChangePwStep = ({ step }) => ({
  type: SET_CHANGE_PW_STEP,
  step,
});

export const requestLogout = () => ({
  type: REQUEST_LOGOUT,
});

export const fetchfprofile = ({ profileType }) => ({
  type: FETCH_PROFILE_REQUEST,
  profileType,
});

export const enableActiveFetchProfile = () => ({
  type: ENABLE_ACTIVE_FETCH_PROFILE,
});

export const disableActiveFetchProfile = () => ({
  type: DISABLE_ACTIVE_FETCH_PROFILE,
});

/**
 * SET_PROFILE_TYPE action creator
 * @param {Object} params
 * @param {number} [params.profileType]
 * @param {number} [params.isEp]
 */
export const setProfileType = ({ profileType, isEp }) => ({
  type: SET_PROFILE_TYPE_REQUEST,
  profileType,
  isEp,
});

export const updateProfileFlag = ({ key }) => ({
  type: UPDATE_PROFILE_FLAG_REQUEST,
  key,
});

export const promptRemoveBusinessProfile = () => ({
  type: PROMPT_REMOVE_BUSINESS_PROFILE,
});

export const promptAddBusinessProfile = () => ({
  type: PROMPT_ADD_BUSINESS_PROFILE,
});

/**
 * ADD_BUSINESS_PROFILE action creator
 * @param {Object} params
 * @param {boolean} [params.isSwitch]
 */
export const addBusinessProfile = ({ isSwitch } = {}) => ({
  type: ADD_BUSINESS_PROFILE,
  isSwitch,
});

export const removeBusinessProfile = () => ({
  type: REMOVE_BUSINESS_PROFILE,
});
