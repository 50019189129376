import { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';

import { redeemCheckoutCouponRequest } from 'interfaces/global/store/modules/pricing/actions';
import { getAvailableRedeemedCoupons } from 'interfaces/global/store/modules/pricing/selectors';

import { Coupon } from 'interfaces/global/store/modules/pricing/types';

export interface UseRedeemCheckoutCoupon {
  redeemCode: string;
  setRedeemCode: (redeemCode: string) => void;
  redeemedCoupons: Coupon[];
  handleRedeem: () => void;
  isLoading: boolean;
  response: {
    type?: 'success' | 'error' | 'warning';
    message?: string;
    id?: string;
  };
}

const useRedeemCheckoutCoupon = (
  onRedeemSuccess: (redeemedCouponId: string) => void
): UseRedeemCheckoutCoupon => {
  const [redeemCode, setRedeemCode] = useState('');
  const redeemedCoupons = useSelector(getAvailableRedeemedCoupons);
  const isLoading = useSelector(
    createLoadingSelector(['REDEEM_CHECKOUT_COUPON'])
  );
  const response = useSelector(
    makeMessageSelector(['REDEEM_CHECKOUT_COUPON']),
    shallowEqual // https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
  );

  const dispatch = useDispatch();
  const handleRedeem = () => dispatch(redeemCheckoutCouponRequest(redeemCode));

  useEffect(() => {
    if (response?.type === 'success' && redeemedCoupons.length > 0) {
      const [{ coupon_id: redeemedCouponId }] = redeemedCoupons;
      onRedeemSuccess(redeemedCouponId);
    }
  }, [redeemedCoupons, response, onRedeemSuccess]);

  return {
    redeemCode,
    setRedeemCode,
    redeemedCoupons,
    handleRedeem,
    isLoading: isLoading ?? false,
    response: response ?? {},
  };
};

export default useRedeemCheckoutCoupon;
