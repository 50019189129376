import { put, select, take, call, all } from 'redux-saga/effects';
import {
  getSpecialRequest,
  getSelectedService,
  getSpecialRequests,
  getSubSpecialRequest,
} from 'interfaces/global/store/modules/services/selectors';
import {
  getFilledWaypts,
  getAreaValidationStatus,
} from 'interfaces/global/store/modules/routing/selectors';
import { getPrice } from 'interfaces/global/store/modules/pricing/selectors';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { FETCH_PRICE_SUCCESS } from 'interfaces/global/store/modules/pricing/actions';
import { VEHICLE_STD_PREFIX } from 'interfaces/global/store/modules/services/helpers';
import { showPlaceOrderAgainImportErrorMessage } from 'interfaces/global/store/modules/routing/saga';
import onChangeVehicleStandard from './vehicleStandard';
import {
  ADD_SPECIAL_REQUEST,
  REMOVE_SPECIAL_REQUEST,
  addSpecialRequest,
  setSubRequest,
} from '../actions';

/**
 * Imports additional services, including vehicle standards, for placing the order again
 * @param {string} vehicleId - Vehicle service ID
 * @param {string[]} additionalServicesToImport - Additional services to import
 * @param {string[]} namesOfVehicleStandardsToImport - A list of names of vehicle standards to import
 */
export function* importAdditionalServices(
  vehicleId,
  additionalServicesToImport,
  namesOfVehicleStandardsToImport
) {
  const allAdditionalServices = yield select(getSpecialRequests, vehicleId);

  // Extract additional service checkboxes that are still valid (in case they changed the services
  // in OPS2 and there's no longer a service that matches this cloned order's)
  const validAdditionalServicesToImport = allAdditionalServices.filter(item =>
    additionalServicesToImport.includes(item.displayName)
  );

  // Get the "Vehicle Standard" additional service if its checkbox exists and was selected...
  const vehicleStandardAdditionalService = getVehicleStandardAdditionalServiceToImport(
    allAdditionalServices,
    namesOfVehicleStandardsToImport
  );

  // ...and add this checkbox to the list of valid additional services
  if (vehicleStandardAdditionalService)
    validAdditionalServicesToImport.push(vehicleStandardAdditionalService);

  // If after getting all "valid" additional services that can be imported (checkboxes that can be selected),
  // and the number of these valid services are less than what was specified in the old order, that means
  // the services have been changed by Lalamove and that we must inform the user about it.
  if (
    validAdditionalServicesToImport.length <
    namesOfVehicleStandardsToImport.length + additionalServicesToImport.length
  )
    yield showPlaceOrderAgainImportErrorMessage();

  // Get the vehicle standard radio button to select (if one should be selected and exists)
  const vehicleStandard = yield getVehicleStandardToImport(
    namesOfVehicleStandardsToImport,
    vehicleStandardAdditionalService
  );

  // Same logic as above for additional services but for the vehicle standard. If a vehicle standard is to
  // be imported but is not found in the updated list of vehicle standards, inform the user something has changed.
  if (
    namesOfVehicleStandardsToImport.length > 0 &&
    vehicleStandard === undefined
  )
    yield showPlaceOrderAgainImportErrorMessage();

  // Use the "all" effect and mapping to loop through and select all additional services
  // and a vehicle standard (if there's a vehicle standard)
  yield all(
    validAdditionalServicesToImport.map(validService =>
      call(additionalServicesImportSelector, validService, vehicleStandard)
    )
  );
}

/**
 * Gets the "Vehicle Standard" additional service checkbox (if a vehicle standard must be imported)
 * @param {object[]} allAdditionalServices - all additional services that are available for a specific vehicle type
 * @param {string[]} namesOfVehicleStandardsToImport - an array with possibly one item (the name of the selected vehicle standard)
 */
const getVehicleStandardAdditionalServiceToImport = (
  allAdditionalServices,
  namesOfVehicleStandardsToImport
) => {
  if (namesOfVehicleStandardsToImport.length > 0) {
    return allAdditionalServices.find(service =>
      service.id.startsWith(VEHICLE_STD_PREFIX)
    );
  }

  return undefined;
};

/**
 * Get the vehicle standard object from redux if the order being imported had a vehicle standard
 * @param {string[]} namesOfVehicleStandardsToImport - an array with possibly one item (the name of the selected vehicle standard)
 * @param {object} vehicleStandardAdditionalService - the vehicle standard additional service checkbox object
 */
function* getVehicleStandardToImport(
  namesOfVehicleStandardsToImport,
  vehicleStandardAdditionalService
) {
  if (vehicleStandardAdditionalService) {
    const vehicleStandardDataArray = yield all(
      vehicleStandardAdditionalService.subSpecialRequests.map(vehicleStd =>
        call(
          vehicleStandardImportSelector,
          vehicleStd,
          namesOfVehicleStandardsToImport
        )
      )
    );

    return vehicleStandardDataArray.find(Boolean);
  }

  return undefined;
}

/**
 *
 * @param {string} vehicleStd - An std ID used to look up a specific vehicle standard
 * @param {string[]} namesOfVehicleStandardsToImport - an array with possibly one item (the name of the selected vehicle standard)
 */
function* vehicleStandardImportSelector(
  vehicleStd,
  namesOfVehicleStandardsToImport
) {
  const vehicleStandard = yield select(getSubSpecialRequest, vehicleStd);

  if (vehicleStandard.name === namesOfVehicleStandardsToImport[0])
    return vehicleStandard;

  return undefined;
}

/**
 * Select the additional service to import's checkbox or select the imported vehicle standard's radio button
 * @param {object} validService - The additional service for which we need to select a checkbox for
 * @param {object} vehicleStandard - The vehicle standard for which we need to select a radio button for
 */
function* additionalServicesImportSelector(validService, vehicleStandard) {
  yield put(addSpecialRequest(validService.id));

  if (vehicleStandard && validService.id.startsWith(VEHICLE_STD_PREFIX)) {
    yield put(setSubRequest(validService.id, null, vehicleStandard.id));
  }
}

export function* onChangeNonVehicleStandardAdditionalServices(
  id,
  type,
  vehicle
) {
  const { name: specialRequestName } = yield select(getSpecialRequest, id);
  const payload = {
    addon_additional_service: specialRequestName,
    vehicle_type: vehicle,
    order_amount: 0,
  };

  const isMinWaypoints = (yield select(getFilledWaypts)).length >= 2;
  const isValidServiceArea = yield select(getAreaValidationStatus);

  const shouldWaitForPriceCalc = isMinWaypoints && isValidServiceArea;

  if (shouldWaitForPriceCalc) {
    yield take(FETCH_PRICE_SUCCESS);
    const { total } = yield select(getPrice);
    payload.order_amount = total.toNumber();
  }

  if (type === ADD_SPECIAL_REQUEST) {
    yield put(track('additional_service_added', payload));
  }

  if (type === REMOVE_SPECIAL_REQUEST) {
    yield put(track('additional_service_removed', payload));
  }
}

export function* onChangeAdditionalServices({
  type,
  id,
  prevSubId,
  nextSubId,
}) {
  const { name: vehicle } = yield select(getSelectedService);

  // changes to vehicle standard are handled separately
  id.startsWith(VEHICLE_STD_PREFIX)
    ? yield call(onChangeVehicleStandard, vehicle, prevSubId, nextSubId)
    : yield call(
        onChangeNonVehicleStandardAdditionalServices,
        id,
        type,
        vehicle
      );
}
