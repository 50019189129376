import React from 'react';
import { func, string } from 'prop-types';
import DateRangePicker from 'components/DateRangePicker';
import styled from 'styled-components';
import moment from 'moment';

const Container = styled.div`
  > div {
    margin-right: 12px;
  }
  div.DateRangePickerInput {
    min-height: 34px;
  }
`;

const DateFilter = ({ onDatesChange, start, end, min, max }) => (
  <Container>
    <DateRangePicker
      onDatesChange={onDatesChange}
      startDate={moment(start)}
      endDate={moment(end)}
      minDate={moment(min)}
      maxDate={moment(max)}
    />
  </Container>
);

DateFilter.propTypes = {
  onDatesChange: func.isRequired,
  start: string.isRequired,
  end: string.isRequired,
  min: string.isRequired,
  max: string.isRequired,
};

export default DateFilter;
