// fetch actions:
export const FETCH_BALANCE_REQUEST = 'FETCH_BALANCE_REQUEST';
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS';
export const FETCH_BALANCE_FAILURE = 'FETCH_BALANCE_FAILURE';

export const FETCH_WALLET_HISTORY_REQUEST = 'FETCH_WALLET_HISTORY_REQUEST';
export const FETCH_WALLET_HISTORY_SUCCESS = 'FETCH_WALLET_HISTORY_SUCCESS';
export const FETCH_WALLET_HISTORY_FAILURE = 'FETCH_WALLET_HISTORY_FAILURE';

export const FETCH_STATEMENT_REQUEST = 'FETCH_STATEMENT_REQUEST';
export const FETCH_STATEMENT_SUCCESS = 'FETCH_STATEMENT_SUCCESS';
export const FETCH_STATEMENT_FAILURE = 'FETCH_STATEMENT_FAILURE';

export const ADD_STATEMENT_TYPE = 'ADD_STATEMENT_TYPE';

// topup actions:
export const TOPUP_INIT = 'TOPUP_INIT';
export const TOPUP_DONE = 'TOPUP_DONE';
export const SET_TOPUP_AMOUNT = 'SET_TOPUP_AMOUNT';
