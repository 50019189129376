import React from 'react';
import createSVGIcon from '@lalamove/karang/dist/components/Icon/icons/utils/createSVGIcon';

const personal = createSVGIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.08341 7.16667C7.08341 6.04427 7.30554 5.07739 7.77024 4.40311C8.21883 3.75222 8.91992 3.33333 10.0001 3.33333C11.0802 3.33333 11.7813 3.75222 12.2299 4.40311C12.6946 5.07739 12.9167 6.04427 12.9167 7.16667C12.9167 8.28879 12.6948 9.32556 12.2217 10.0677C11.7632 10.7869 11.0592 11.25 10.0001 11.25C8.94093 11.25 8.237 10.7869 7.77851 10.0677C7.30541 9.32556 7.08341 8.28879 7.08341 7.16667ZM10.0001 2.5C8.66357 2.5 7.698 3.03944 7.08408 3.93022C6.48629 4.79761 6.25008 5.95574 6.25008 7.16667C6.25008 8.37788 6.48642 9.5911 7.07582 10.5156C7.32706 10.9098 7.64037 11.2472 8.01835 11.507C6.97984 11.801 6.15666 12.3341 5.54067 13.0381C4.58269 14.133 4.16675 15.594 4.16675 17.0833C4.16675 17.3135 4.3533 17.5 4.58341 17.5H15.4167C15.6469 17.5 15.8334 17.3135 15.8334 17.0833C15.8334 15.594 15.4175 14.133 14.4595 13.0381C13.8435 12.3341 13.0203 11.801 11.9818 11.507C12.3598 11.2472 12.6731 10.9098 12.9243 10.5156C13.5137 9.5911 13.7501 8.37788 13.7501 7.16667C13.7501 5.95574 13.5139 4.79761 12.9161 3.93022C12.3022 3.03944 11.3366 2.5 10.0001 2.5ZM10.0001 12.0833C8.18389 12.0833 6.9516 12.6911 6.16782 13.5869C5.45833 14.3977 5.08289 15.4831 5.01236 16.6667H14.9878C14.9173 15.4831 14.5418 14.3977 13.8323 13.5869C13.0486 12.6911 11.8163 12.0833 10.0001 12.0833Z"
      fill="#585858"
    />
  </g>,
  'personalProfile',
  '0 0 20 20'
);

export default personal;
