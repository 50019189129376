import React from 'react';
import { node, number, string } from 'prop-types';
import styled from 'styled-components';
import { Heading, HeadingGroup, Pill } from '@lalamove/karang';

import { gray } from 'styles/colors';
import { fontSize } from 'styles/fonts';

const Container = styled.div`
  margin: 1em 0 2em 0;

  h3 {
    margin: 0.1em 0;
  }
`;

const Content = styled.div`
  margin: 0 3em;
`;

const StyledPill = styled(Pill)`
  width: 1em;
  margin: 0 1.5em 0 0;
  font-size: ${fontSize.small};
`;

const Subtitle = styled.p`
  margin: 0 0 1.5em 0;
  color: ${gray};
  font-size: ${fontSize.small};
`;

const NumberedGroup = ({ numbering, title, subtitle, children, ...props }) => (
  <Container {...props}>
    <HeadingGroup>
      <StyledPill solid>{numbering}</StyledPill>
      <Heading size="large" htmlTag="h3">
        {title}
      </Heading>
    </HeadingGroup>
    <Content>
      <Subtitle>{subtitle}</Subtitle>
      {children}
    </Content>
  </Container>
);

NumberedGroup.defaultProps = {
  subtitle: '',
  children: null,
};

NumberedGroup.propTypes = {
  numbering: number.isRequired,
  title: string.isRequired,
  subtitle: string,
  children: node,
};

export default NumberedGroup;
