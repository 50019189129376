import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { bool, func, shape, string, number } from 'prop-types';
import styled from 'styled-components';
import { Button, Input, Form, Checkbox } from '@lalamove/karang';
import { ResponsiveCard } from 'components/Container';

import {
  googleLoginRequest as googleLogin,
  facebookLogin as fbLogin,
  cancelFacebookLogin as cancelFBLogin,
  registerSubmit,
} from 'interfaces/global/store/modules/auth/actions';

import loadFacebookSDK from 'utils/facebookApi';
import {
  createClient as loadGoogleSDK,
  getAuth2Instance as getGoogleAuthInstance,
} from 'utils/googleApi';

import { track as sensorsTracking } from 'interfaces/global/store/modules/tracking/actions';

import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';
import {
  selectors as regionSelector,
  actions as regionAction,
} from 'store/modules/region';

import PhoneInput from 'components/PhoneInput';
import { FacebookButton, GoogleButton } from 'components/Button';
import HtmlTitle from 'components/HtmlTitle';

import logoPNG from 'assets/lalamove-logo.png';
import logoSVG from 'assets/svg/logo.svg';
import { silver, lightSilver, red } from 'styles/colors';
import { fontSize } from 'styles/fonts';

const Logo = styled.div`
  width: 198px;
  height: 55px;
  margin: 0 auto 28px;
  background-image: url(${logoPNG});
  background-image: url(${logoSVG});
  background-position: center;
  background-size: 198px 55px;
`;

const StyledInput = styled(Input)`
  flex: 1;
`;

StyledInput.displayName = 'StyledInput';

const FormItem = styled(Form.Item)`
  align-items: center;
  & ${/* sc-selector */ StyledInput}:nth-child(2) {
    margin-left: 4px;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

const FromActionSeparator = styled.div`
  padding: 1rem 0;
  color: ${lightSilver};
  font-size: ${fontSize.small};
  text-align: center;
`;

const ButtonsSeparator = styled.div`
  margin: 1rem 0;
`;

const CardFooter = styled.div`
  padding-top: 2rem;
  color: ${silver};
  text-align: center;
`;

const ErrorMessage = styled.span`
  display: block;
  margin-left: 2em;
  color: ${red};
  font-size: ${fontSize.small};
`;

const StyledCheckbox = styled(Checkbox)`
  display: block;
  margin: 0.5em 0;
  font-size: ${fontSize.regular};
  & input {
    position: absolute;
  }
  & span:last-child {
    float: right;
  }
`;

export class Register extends Component {
  static defaultProps = {
    loading: false,
    apiError: null,
  };

  static propTypes = {
    registerSubmit: func.isRequired,
    googleLoginRequest: func.isRequired,
    facebookLogin: func.isRequired,
    cancelFacebookLogin: func.isRequired,
    loading: bool,
    t: func.isRequired,
    apiError: shape({ message: string, errorCode: number }),
    currentCountry: shape({
      id: string,
      areaCode: string,
      isEmailRequiredForSignUp: bool,
    }).isRequired,
    currentCity: shape({
      id: string,
      urls: shape({
        privacy: string,
        faq: string,
        terms: string,
        pricing: string,
      }),
    }).isRequired,
    countryDict: shape({}).isRequired,
    changeLocation: func.isRequired,
    track: func.isRequired,
    language: string.isRequired,
  };

  state = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    password: '',
    terms: true,
    marketingOptIn: true,
    showError: false,
    showErrorTerms: false,
    loadingFacebookLoginButton: true,
    loadingGoogleLoginButton: true,
  };

  componentDidMount = async () => {
    // We can only use Google login on one origin domain (lalamove.com). This is to prevent
    // web.lalamove.net.in from throwing errors related to loading the Google login script.
    if (GoogleButton.componentDisabled) await this.loadFacebookLoginScript();
    else
      await Promise.all([
        this.loadFacebookLoginScript(),
        this.loadGoogleLoginScript(),
      ]);
  };

  loadFacebookLoginScript = async () => {
    const { currentCountry, language } = this.props;
    this.FB = await loadFacebookSDK(currentCountry.id, language);
    this.facebookLoginResponse = await this.FB.getLoginStatus();

    this.setState({
      loadingFacebookLoginButton: false,
    });
  };

  loadGoogleLoginScript = async () => {
    try {
      await loadGoogleSDK();
      this.googleAuthInstance = getGoogleAuthInstance();
      await this.googleAuthInstance.then();
    } catch (error) {
      // Ignore it for cases like Chrome's incognito mode that blocks cookies necessary to load the script
    }

    this.setState({
      loadingGoogleLoginButton: false,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    // eslint-disable-next-line no-shadow
    const { currentCountry, registerSubmit } = this.props;
    const {
      firstName,
      lastName,
      email,
      mobile,
      password,
      terms,
      marketingOptIn,
    } = this.state;

    this.setState({ showErrorTerms: !terms });
    if (!terms) {
      return;
    }

    this.setState({ showError: true });
    registerSubmit({
      firstName,
      lastName,
      email,
      countryCode: currentCountry.areaCode,
      mobile,
      password,
      marketingOptIn,
    });
  };

  handleToggle = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleSubmitFB = async () => {
    const { facebookLogin, currentCountry, cancelFacebookLogin } = this.props;

    if (
      !this.facebookLoginResponse.status ||
      this.facebookLoginResponse.status !== 'connected'
    ) {
      this.facebookLoginResponse = await this.FB.login({
        scope: 'email,public_profile',
        return_scopes: true,
      });

      // User cancelled login or did not fully authorize.
      if (
        !this.facebookLoginResponse.status ||
        this.facebookLoginResponse.status !== 'connected'
      ) {
        cancelFacebookLogin();
        return;
      }
    }

    const {
      authResponse: { accessToken },
    } = this.facebookLoginResponse;

    const user = await this.FB.api('/me', {
      fields: 'first_name, last_name, name, email',
    });

    facebookLogin({
      areaCode: currentCountry.areaCode,
      accessToken,
      user,
    });
  };

  handleGoogleLogin = () => {
    const { currentCountry, googleLoginRequest } = this.props;
    googleLoginRequest({
      areaCode: currentCountry,
      authInstance: this.googleAuthInstance,
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value, showError: false });
  };

  handleBlur = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value.trim() });
  };

  handleCountryChange = country => {
    const { currentCountry, changeLocation } = this.props;
    if (country === currentCountry.id) {
      return;
    }

    changeLocation({ country });
  };

  handleTermsClick = () => {
    this.props.track('tc_tapped', { source: 'create_account' });
  };

  isFormReady() {
    const {
      currentCountry: { isEmailRequiredForSignUp },
    } = this.props;
    const { firstName, lastName, email, mobile, password } = this.state;
    return (
      !(firstName.trim() && lastName.trim() && mobile && password) ||
      (isEmailRequiredForSignUp && !email)
    );
  }

  renderError(name) {
    const { apiError } = this.props;

    if (!apiError) return null;

    // Only handling known errors here
    // And classify where to display this error
    const errors = {
      firstName: [{ code: 30006, trans: 'Register.invalid_first_name' }],
      lastName: [{ code: 30007, trans: 'Register.invalid_last_name' }],
      email: [
        { code: 30003, trans: 'Register.invalid_email' },
        { code: 30004, trans: 'Register.email_exists' },
      ],
      mobile: [
        { code: 30001, trans: 'Register.invalid_phone' },
        { code: 30002, trans: 'Register.phone_exists' },
        { code: 30008, trans: apiError.message },
        { code: 30010, trans: apiError.message },
        { code: 30009, trans: 'Register.invalid_phone' },
        { code: 10015, trans: apiError.message },
      ],
      password: [
        { code: 30005, trans: 'Register.invalid_password' },
        { code: 30011, trans: 'Register.already_sent_sms' },
      ],
    };
    const errorObject = errors[name].find(
      errorObj => errorObj.code === apiError.errorCode
    );

    return errorObject ? errorObject.trans : null;
  }

  render() {
    const { loading, t, currentCountry, currentCity, countryDict } = this.props;
    const {
      firstName,
      lastName,
      email,
      mobile,
      password,
      terms,
      marketingOptIn,
      showError,
      showErrorTerms,
      loadingFacebookLoginButton,
      loadingGoogleLoginButton,
    } = this.state;

    return (
      <Fragment>
        <HtmlTitle>{t('Title.sign_up')}</HtmlTitle>
        <ResponsiveCard>
          <Logo />
          <form onSubmit={this.handleSubmit} noValidate>
            <FormItem>
              <StyledInput
                type="text"
                label={t('Register.first_name')}
                name="firstName"
                value={firstName}
                error={
                  showError && !loading
                    ? t(this.renderError('firstName'))
                    : null
                }
                onChange={this.handleChange}
              />
              <StyledInput
                type="text"
                label={t('Register.last_name')}
                name="lastName"
                value={lastName}
                error={
                  showError && !loading ? t(this.renderError('lastName')) : null
                }
                onChange={this.handleChange}
              />
            </FormItem>
            <FormItem>
              <StyledInput
                type="email"
                label={t('Register.email')}
                name="email"
                value={email}
                error={
                  showError && !loading ? t(this.renderError('email')) : null
                }
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </FormItem>
            <FormItem>
              <PhoneInput
                label={t('Register.mobile')}
                name="mobile"
                value={mobile}
                error={
                  showError && !loading ? t(this.renderError('mobile')) : null
                }
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                currentCountry={currentCountry}
                countries={Object.values(countryDict)}
                onCountryChange={this.handleCountryChange}
                showAreaCode
              />
            </FormItem>
            <FormItem>
              <StyledInput
                type="password"
                label={t('Register.password_req')}
                name="password"
                value={password}
                error={
                  showError && !loading ? t(this.renderError('password')) : null
                }
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </FormItem>
            <FormItem>
              <SubmitButton
                type="submit"
                variant="primary"
                size="large"
                block
                isLoading={loading}
                disabled={loading || this.isFormReady()}
                solid
              >
                {t('Register.sign_up')}
              </SubmitButton>
            </FormItem>

            <StyledCheckbox
              name="terms"
              label={
                <Trans i18nKey="Register.checkbox_terms">
                  I have read, understood and accept the
                  <a
                    href={currentCity.urls.terms}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.handleTermsClick}
                  >
                    Terms & Conditions
                  </a>
                  and
                  <a
                    href={currentCity.urls.privacy}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Trans>
              }
              checked={terms}
              value={terms}
              onChange={this.handleToggle}
            />
            {showErrorTerms && (
              <ErrorMessage>{t('Register.checkbox_terms_error')}</ErrorMessage>
            )}

            <StyledCheckbox
              name="marketingOptIn"
              label={
                <Trans i18nKey="Register.checkbox_marketing">
                  I agree the use of my personal data for direct marketing in
                  accordance with the stated
                  <a
                    href={currentCity.urls.privacy}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Trans>
              }
              value={marketingOptIn}
              checked={marketingOptIn}
              onChange={this.handleToggle}
            />
          </form>
          <>
            <FromActionSeparator>{t('Register.or')}</FromActionSeparator>
            <FacebookButton
              onClick={this.handleSubmitFB}
              solid
              disabled={loadingFacebookLoginButton || loading}
            >
              {t('Register.sign_up_fb')}
            </FacebookButton>
            {!GoogleButton.componentDisabled && (
              <>
                <ButtonsSeparator />
                <GoogleButton
                  onClick={this.handleGoogleLogin}
                  disabled={loadingGoogleLoginButton || loading}
                >
                  {t('Register.google_sign_in')}
                </GoogleButton>
              </>
            )}
          </>
          <CardFooter>
            <Trans i18nKey="Register.login">
              Already have an account?
              <Link
                to="/login"
                onClick={() => this.props.track('log_in_clicked')}
                replace
              >
                Log in
              </Link>
            </Trans>
          </CardFooter>
        </ResponsiveCard>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: createLoadingSelector(['REGISTER', 'FB_LOGIN', 'GOOGLE_LOGIN'])(
    state
  ),
  apiError: makeMessageSelector(['REGISTER', 'FB_LOGIN', 'GOOGLE_LOGIN'])(
    state
  ),
  currentCountry: regionSelector.getCurrentCountry(state),
  currentCity: regionSelector.getCurrentCity(state),
  countryDict: regionSelector.getCountryDict(state),
  language: regionSelector.getCurrentLocale(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    registerSubmit,
    googleLoginRequest: googleLogin,
    facebookLogin: fbLogin,
    cancelFacebookLogin: cancelFBLogin,
    changeLocation: regionAction.changeLocation,
    track: sensorsTracking,
  })
)(Register);
