import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

const { REACT_APP_CITY_LIST_DEVICE_ID } = process.env;

export default async function getCityList(domain, hcountry, locale) {
  const fetcher = FetcherFactory.make(UAPIFetcher);

  const params = {
    os: 'web',
    args: '{}',
    hcountry,
    hlang: toLLMLower(locale),
  };

  const options = { baseUrl: domain, skipStartUpLock: true };

  if (REACT_APP_CITY_LIST_DEVICE_ID) {
    params.device_id = REACT_APP_CITY_LIST_DEVICE_ID;
  }

  const res = await fetcher.get('?_m=city_list', params, options);
  const { ret, msg, data } = res;

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data.city_item.map(city => ({
    cityCodeMap: city.city_code_map,
    enableOverseas: city.enable_overseas,
    cityId: city.city_id,
    countryId: city.country_id,
    cityName: city.name,
    cityZone: city.timezone,
    latLon: city.lat_lon,
    translations: [
      { id: locale, value: city.name },
      { id: 'en', value: city.name_en },
    ],
  }));
}
