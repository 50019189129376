import { UApiError } from 'utils/helpers';
import { toLLMLower } from 'utils/locale';

import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

export default async function getUserInfo(accessToken, profileType) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const { countryId, locale } = await fetcher.getState();

  const params = {
    device_id: '0',
    device_type: 'web',
    os: 'android',
    hcountry: countryId,
    hlang: toLLMLower(locale),
    args: JSON.stringify({}),
    _t: new Date().getTime() / 1000,
    token: accessToken,
    is_ep: profileType,
  };

  const res = await fetcher.get('?_m=get_user_info', params);
  const { ret, msg, data } = res;

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return {
    ...data,
    enable_my_fleet_only: !!data.enable_my_fleet_only,
    first_name: data.nickname,
    last_name: data.real_name,
  };
}
