import styled from 'styled-components';
import { nobel, mountainMeadow, mineShaft } from 'styles/colors';
import CouponTickSVG from 'assets/svg/coupon-tick.svg';
import CouponTagActiveSVG from 'assets/svg/coupon-tag-active.svg';
import CouponTagInactiveSVG from 'assets/svg/coupon-tag-inactive.svg';

interface CouponItemProps {
  active: boolean;
}

interface DetailProps {
  show: boolean;
  height?: string;
}

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;

  min-width: 405px;
  min-height: 132px;
  padding: 8px 8px 0 16px;

  border: solid 1px
    ${(props: CouponItemProps) =>
      props.active ? mountainMeadow[300] : nobel[100]};
  box-shadow: 0 0 0 1px
    ${(props: CouponItemProps) =>
      props.active ? mountainMeadow[300] : 'transparent'};
  border-radius: 9px;
  background-color: ${(props: CouponItemProps) =>
    props.active ? 'white' : nobel['050']};
  cursor: pointer;

  transition: border 0.2s, box-shadow 0.2s, background-color 0.2s;
  user-select: none;
`;

export const Header = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin-bottom: 12px;
`;

export const Text = styled.span`
  color: ${mineShaft[600]};
  font-size: 12px;
`;

export const Main = styled.div`
  display: flex;
`;

export const Tick = styled.img.attrs({
  src: CouponTickSVG,
  alt: 'selected',
})`
  opacity: ${(props: CouponItemProps) => (props.active ? 1 : 0)};
  transition: opacity 0.2s;
`;

export const Tag = styled.img.attrs((props: CouponItemProps) => ({
  src: props.active ? CouponTagActiveSVG : CouponTagInactiveSVG,
  alt: 'coupon',
}))`
  margin-right: 8px;
`;

export const DiscountInfo = styled.div`
  align-self: center;
  color: ${mineShaft[900]};
`;

export const Discount = styled.h3`
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.28px;
`;

export const DiscountAmountDetail = styled.span`
  font-size: 12;
  line-height: 16px;
`;

export const Footer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 24px;
  padding: 0 16px;
  border-radius: 0 0 5px 5px;
  background-color: ${(props: CouponItemProps) =>
    props.active ? 'rgba(25, 188, 155, 0.05)' : nobel[100]};

  transition: background-color 0.2s;
`;

export const ShowDetailText = styled(Text)`
  display: inline-flex;
  font-weight: bold;
`;

export const Icon = styled.img`
  margin-left: 4px;
`;

export const Details = styled.div`
  height: ${(props: DetailProps) =>
    props.show ? props.height || 'auto' : '0'};
  padding: ${(props: DetailProps) => (props.show ? '20px 0 18px 0' : '0')};
  border-top: 1px solid ${nobel[200]};
  margin: ${(props: DetailProps) => (props.show ? '16px 0 24px 0' : '0')};
  opacity: ${(props: DetailProps) => (props.show ? '1' : '0')};
  transition-property: height, padding, margin, opacity;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: ${(props: DetailProps) =>
    props.show ? '0s, 0s, 0s, 0.2s' : '0.2s, 0.2s, 0.2s, 0s'};
`;

export const DetailHeader = styled.div`
  margin-bottom: 8px;
  color: ${mineShaft[600]};
  font-size: 12px;
  font-weight: bold;
`;

export const DetailListItem = styled.div`
  margin: 4px 0;
  color: ${mineShaft[900]};
  font-size: 12px;
`;

export const Badge = styled.div`
  position: absolute;
  top: 12px;
  right: 16px;
  padding: 2px 4px;
  background-color: ${nobel[200]};
  color: ${mineShaft[600]};
  font-size: 10px;
  text-transform: uppercase;
`;
