import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { func, string, bool, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';

import { resetPwRequest } from 'store/modules/auth/actions';
import { createLoadingSelector } from 'store/modules/loading';
import { makeMessageSelector } from 'store/modules/message';
import { getCurrentCountry } from 'store/modules/region/selectors';

import { checkPwrCode } from 'api/authAPI';
import HtmlTitle from 'components/HtmlTitle';

import ResetForm from './ResetForm';
import ResetComplete from './ResetComplete';

const CODE_PASSWORD_RESET = 'CODE_PASSWORD_RESET';
const ERROR_INVALID_PWRCODE = 'ERROR_INVALID_PWRCODE';

export class ResetPasswordContainer extends Component {
  static mounted = false;

  static defaultProps = {
    match: {},
    apiError: {},
    smsCode: null,
  };

  static propTypes = {
    match: shape({
      params: shape({
        emailCode: string,
      }),
    }),
    resetPwRequest: func.isRequired,
    t: func.isRequired,
    username: string.isRequired,
    uuid: string.isRequired,
    smsCode: string,
    apiError: shape({ message: string }),
    loading: bool.isRequired,
    status: string.isRequired,
    country: shape({ areaCode: string }).isRequired,
  };

  state = {
    invalidCode: false,
  };

  componentDidMount() {
    this.mounted = true;
    const { match } = this.props;
    if (match.params.emailCode) {
      this.checkPwCode(match.params.emailCode);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSubmit = password => {
    const { uuid, username, smsCode, match, country } = this.props;
    this.props.resetPwRequest({
      uuid,
      username,
      password,
      areaCode: country.areaCode,
      smsCode,
      emailCode: match.params.emailCode, // eslint-disable-line react/prop-types
    });
  };

  async checkPwCode(code) {
    try {
      await checkPwrCode(code);
    } catch ({ message }) {
      if (message === ERROR_INVALID_PWRCODE) {
        this.mounted && this.setState({ invalidCode: true });
      }
    }
  }

  render() {
    const { loading, apiError, status, t, uuid, match } = this.props;
    const { invalidCode } = this.state;

    if (!uuid && !match.params.emailCode) {
      return <Redirect to="/forgot-password" />;
    }

    let content;
    if (invalidCode) {
      content = (
        <ResetComplete
          title={t('ForgotPassword.title_invalid_link')}
          text={t('ForgotPassword.text_invalid_link')}
          buttonLabel={t('ForgotPassword.back_to_login')}
        />
      );
    } else if (status === CODE_PASSWORD_RESET) {
      content = (
        <ResetComplete
          title={t('ForgotPassword.password_updated')}
          text={t('ForgotPassword.password_changed')}
          buttonLabel={t('ForgotPassword.back_to_login')}
        />
      );
    } else {
      content = (
        <ResetForm
          onSubmit={this.handleSubmit}
          isSubmitting={loading}
          error={!loading ? apiError.message : ''}
        />
      );
    }
    return (
      <Fragment>
        <HtmlTitle>{t('Title.reset_password')}</HtmlTitle>
        {content}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  username: state.auth.forgotPassword.username,
  uuid: state.auth.forgotPassword.uuid,
  status: state.auth.forgotPassword.status,
  smsCode: state.auth.forgotPassword.smsCode,
  loading: createLoadingSelector(['RESET_PW'])(state),
  apiError: makeMessageSelector(['RESET_PW'])(state),
  country: getCurrentCountry(state),
});

export default connect(mapStateToProps, { resetPwRequest })(
  withTranslation()(ResetPasswordContainer)
);
