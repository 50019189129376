const {
  REACT_APP_WEB_CHAT_DATA_URL: WEB_CHAT_DATA_URL,
  REACT_APP_WEB_CHAT_DEPLOYMENT_KEY: WEB_CHAT_DEPLOYMENT_KEY,
  REACT_APP_WEB_CHAT_ORG_GUID: WEB_CHAT_ORG_GUID,
} = process.env;
export const WEB_CHAT_AVAILABLE_LANGUAGES = process.env.REACT_APP_WEB_CHAT_AVAILABLE_LANGUAGES.split(
  ','
);
export const genesysConfig = {
  widgets: {
    main: {
      debug: true,
      theme: 'light',
      i18n: '',
      lang: 'en',
      preload: [],
    },
    webchat: {
      ajaxTimeout: 9000,
      transport: {
        dataURL: WEB_CHAT_DATA_URL,
        type: 'purecloud-v2-sockets',
        deploymentKey: WEB_CHAT_DEPLOYMENT_KEY,
        orgGuid: WEB_CHAT_ORG_GUID,
        interactionData: {
          routing: {
            targetType: 'QUEUE',
          },
        },
      },
      emojis: true,
      markDown: true,
      autoInvite: {
        enabled: true,
        timeToInviteSeconds: 10,
        inviteTimeoutSeconds: 30,
      },
      userData: {},
    },
  },
};

export const formField = {
  form: {
    autoSubmit: true,
    firstname: '',
    lastname: '',
    email: '',
    subject: 'Populated from data',
  },
  formJSON: {
    wrapper: '<table></table>',
  },
};
