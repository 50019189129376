import React, { PureComponent } from 'react';
import { remark as RemarkIcon } from '@lalamove/karang/dist/components/Icon/icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Popover from 'components/Popover';

const PopoverContent = styled.div`
  max-width: 22rem;
  overflow-wrap: break-word;
`;

const CursorIcon = styled(RemarkIcon)`
  cursor: pointer;
`;

CursorIcon.displayName = 'CursorIcon';

// interface Props {
//   text?: string;
// }
// interface States {
//   isOpen: boolean;
// }
export default class NotesToDriverCell extends PureComponent {
  static propTypes = {
    text: PropTypes.string,
  };

  static defaultProps = {
    text: '',
  };

  state = {
    isOpen: false,
  };

  handleClick = e => {
    // Prevent trigger click on row
    // not to trigger order details
    e.stopPropagation();
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  handleOnOuterAction = () => {
    this.setState({ isOpen: false });
  };

  preventBubble = e => {
    // Prevent trigger click on row
    // not to trigger order details
    e.stopPropagation();
  };

  render() {
    const { text } = this.props;
    const { isOpen } = this.state;
    if (text.length === 0) {
      return null;
    }
    const Child = <CursorIcon type="textMessage" onClick={this.handleClick} />;
    return (
      <Popover
        isOpen={isOpen}
        body={
          <PopoverContent onClick={this.preventBubble}>{text}</PopoverContent>
        }
        preferPlace="below"
        onOuterAction={this.handleOnOuterAction}
      >
        {Child}
      </Popover>
    );
  }
}
