import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';

import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

const getLocationSearchHistory = async type => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const {
    accessToken,
    profileType,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      addr_type: type, // 1-发货地址  2-收货地址
    }),
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
  };

  const { ret, data, msg } = await fetcher.get(
    '?_m=search_history_list',
    params
  );

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  // Example response data
  // {
  //   "ret": 0,
  //   "msg": "",
  //   "data": {
  //       "search_history": [
  //           {
  //               "addr_info": {
  //                   "lat_lon": {
  //                       "lat": 23.036603915917,
  //                       "lon": 72.589756138623
  //                   },
  //                   "name": "1170,Old City",
  //                   "addr": "1170, Old City, Delhi Chakla, Dariyapur, Ahmedabad, Gujarat 380001, India",
  //                   "lat_lon_baidu": {
  //                       "lat": 23.036603915917,
  //                       "lon": 72.589756138623
  //                   },
  //                   "lat_lon_gcj": {
  //                       "lat": 23.036603915917,
  //                       "lon": 72.589756138623
  //                   },
  //                   "city_id": 11014,
  //                   "city_name": "Ahmedabad",
  //                   "district_name": "Dariyapur",
  //                   "house_number": "",
  //                   "contacts_name": "",
  //                   "contacts_phone_no": "",
  //                   "poi_id": "ChIJ4XlOxkCEXjkR6iLcKvw_Abo",
  //                   "place_type": "3"
  //               },
  //               "poiid": "ChIJ4XlOxkCEXjkR6iLcKvw_Abo"
  //           },
  //           ...
  //         ]
  //       }
  //   }

  // eslint-disable-next-line camelcase
  const { search_history } = data;

  const toReturn = search_history.map(historyItem => {
    const {
      // eslint-disable-next-line camelcase
      house_number,
      addr,
      // eslint-disable-next-line camelcase
      contacts_name,
      // eslint-disable-next-line camelcase
      contacts_phone_no,
      // eslint-disable-next-line camelcase
      poi_id,
      // eslint-disable-next-line camelcase
      lat_lon,
      name,
      // eslint-disable-next-line camelcase
      city_id,
    } = historyItem.addr_info;
    // eslint-disable-next-line camelcase
    const { lat, lon } = lat_lon;

    // Need to return the data mapped as the old data structure below:
    // interface Response {
    //   data: {
    //     addressDetails: { block: String, floor: String, room: String },
    //     addressStr: String,
    //     contactPerson: { name: String, phone: String },
    //     id: String,
    //     lat: Number,
    //     lng: Number,
    //     pathId: Number,
    //     placeId: String,
    //     txUpdate: Number, // unix time
    //   }[];
    //   success: Boolean;
    //   footprint: String;
    // }
    return {
      addressDetails: {
        block: '',
        floor: '',
        room: house_number,
      },
      addressStr: addr,
      contactPerson: {
        name: contacts_name,
        phone: contacts_phone_no,
      },
      lat: parseFloat(lat),
      lng: parseFloat(lon),
      pathId: 1,
      placeId: poi_id,
      name,
      cityId: city_id,
    };
  });
  return toReturn;
};
export default getLocationSearchHistory;
