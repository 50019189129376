import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  bool,
  func,
  instanceOf,
  node,
  number,
  shape,
  string,
  arrayOf,
} from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Heading,
  HeadingGroup,
  Input,
  RadioGroup,
} from '@lalamove/karang';
import { edit as EditIcon } from '@lalamove/karang/dist/components/Icon/icons';
import _some from 'lodash/some';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  DUPLICATE,
  TRIPLICATE,
  DONATION,
  DEFAULT_INVOICE_TYPE,
} from 'store/modules/checkout/uniformInvoice';
import Popover from 'components/Popover';
import Tag from 'components/Tag';
import { noop } from 'utils/helpers';
import validator from 'utils/validator';
import { black, white } from 'styles/colors';
import { getDonationList } from 'store/modules/region/selectors';

const Container = styled.div`
  position: relative;
  width: 24rem;
  padding: 4px;
`;

const StyledTag = styled(Tag)`
  position: absolute;
  top: 0;
  right: 0;
`;

const NotSavedLabel = styled.span`
  margin-left: 0.5em;
  vertical-align: middle;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  color: ${black};
`;

const ListItem = styled.li`
  margin: 1.5em 0;
`;

const StyledForm = styled.form`
  margin-top: 1rem;
`;

const FormItem = styled.div`
  margin: 0.75em 0;
`;

const StyledInput = styled(Input)`
  ${/* sc-selector */ FormItem} & {
    display: block;
  }
`;

const FormActionContainer = styled.div`
  margin-top: 1rem;
  text-align: right;
`;

const CancelButton = styled(Button)`
  margin-right: 1rem;
`;

const infoDataInit = {
  name: '',
  taxID: '',
  email: '',
  address: '',
  donationIndex: 0,
};

const errorInit = { name: '', email: '', address: '', taxID: '' };

export class InvoiceInfo extends Component {
  static defaultProps = {
    isOpen: true,
    children: null,
    parent: document.body,
    userEmail: '',
    type: DEFAULT_INVOICE_TYPE,
    infoData: infoDataInit,
    onSave: noop,
    onCancel: noop,
    onError: noop,
    onNotSaved: noop,
    t: noop,
  };

  static propTypes = {
    isOpen: bool,
    children: node,
    parent: instanceOf(Element),
    userEmail: string,
    type: string,
    infoData: shape({
      name: string,
      taxID: string,
      email: string,
      address: string,
      donationIndex: number,
    }),
    onSave: func,
    onCancel: func,
    onError: func,
    onNotSaved: func,
    t: func,
    donationList: arrayOf(shape({ code: number, name: string })).isRequired,
  };

  state = {
    infoData: infoDataInit,
    error: errorInit,
    isDirty: false,
  };

  componentDidMount() {
    this.setStateWithProps();
  }

  componentDidUpdate({ type, infoData }) {
    if (type !== this.props.type || infoData !== this.props.infoData) {
      this.setStateWithProps();
    }
  }

  setStateWithProps = () => {
    const { infoData, userEmail } = this.props;
    this.setState(state => ({
      ...state,
      infoData: {
        ...state.infoData,
        ...infoData,
        email: infoData.email || userEmail,
      },
      error: errorInit,
      isDirty: false,
    }));
  };

  getFormRef = form => {
    this.form = form;
  };

  setTitle = type => {
    if (type === DUPLICATE) return 'radio_duplicate_invoice';
    if (type === TRIPLICATE) return 'radio_triplicate_invoice';
    return 'radio_donation_invoice';
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState(state => ({
      infoData: { ...state.infoData, [name]: value },
      error: { ...state.error, [name]: '' },
      isDirty: value !== this.props.infoData[name],
    }));
  };

  handleBlur = ({ target: { name, value } }) => {
    let valid = true;
    let message = '';
    if (name === 'taxID') {
      ({ valid, message } = validator.taxID(value.trim()));
    } else if (name === 'email') {
      ({ valid, message } = validator.email(value.trim()));
    } else {
      valid = Boolean(value.trim());
      message = valid ? '' : 'Checkout.error_msg_required';
    }
    if (!valid) {
      this.setState(state => ({
        error: { ...state.error, [name]: message },
      }));
    }
  };

  handleRadio = value => {
    const index = parseInt(value, 10);
    this.setState(state => ({
      infoData: {
        ...state.infoData,
        donationIndex: index,
      },
      isDirty: index !== this.props.infoData.donationIndex,
    }));
  };

  handleCancel = () => {
    const { infoData, userEmail } = this.props;
    this.setState(state => ({
      infoData: {
        ...state.infoData,
        ...infoData,
        email: infoData.email || userEmail,
      },
      isDirty: false,
      error: errorInit,
    }));
    this.props.onCancel();
  };

  handleSave = e => {
    const { onSave, type, onError } = this.props;
    e.preventDefault();
    let data;
    let allValid = true;
    let error = errorInit;
    if (type === DONATION) {
      data = {
        donationIndex: this.state.infoData.donationIndex,
      };
    } else {
      data = [...e.target.elements].reduce((aggregate, el) => {
        if (el.name) aggregate[el.name] = el.value.trim(); // eslint-disable-line no-param-reassign
        return aggregate;
      }, {});
      ({ allValid, error } = this.validateForm(type, data));
    }

    if (allValid) {
      this.setState(
        {
          isDirty: false,
        },
        () => onSave(data)
      );
    } else {
      this.setState(
        {
          error,
        },
        onError('TW_Invoice.error_msg_incomplete_info')
      );
    }
  };

  handleClickOutside = () => {
    if (this.state.isDirty) this.props.onNotSaved();
    else this.props.onCancel();
  };

  validateForm = (type, data) => {
    const { valid: emailValid, message: emailMessage } = validator.email(
      data.email.trim()
    );
    let allValid = emailValid && data.name && data.address;
    const error = {
      name: data.name ? '' : 'Checkout.error_msg_required',
      email: emailValid ? '' : emailMessage,
      address: data.address ? '' : 'Checkout.error_msg_required',
    };

    if (type === TRIPLICATE) {
      const { valid: numberValid, message: numberMessage } = validator.taxID(
        data.taxID.trim()
      );
      allValid = allValid && numberValid;
      error.taxID = numberValid ? '' : numberMessage;
    }
    return { allValid, error };
  };

  hasError = () => {
    const { type } = this.props;
    const {
      infoData: { name, taxID, email, address },
      error,
    } = this.state;
    let anyError = _some(error);
    if (type !== DONATION) {
      anyError = anyError || !name || !email || !address;
      if (type === TRIPLICATE) {
        anyError = anyError || !taxID;
      }
    }
    return anyError;
  };

  render() {
    const { t, isOpen, children, type, parent, donationList } = this.props;
    const {
      infoData: { name, taxID, email, address, donationIndex },
      error,
      isDirty,
    } = this.state;
    const isDonation = type === DONATION;

    const popoverBody = (
      <Container>
        {isDirty && (
          <StyledTag type="yellow">
            <EditIcon color={white} size={12} />
            <NotSavedLabel>{t('TW_Invoice.label_not_saved')}</NotSavedLabel>
          </StyledTag>
        )}
        <HeadingGroup>
          <Heading htmlTag="h4">
            {t(`TW_Invoice.${this.setTitle(type)}`)}
          </Heading>
        </HeadingGroup>
        <StyledForm ref={this.getFormRef} onSubmit={this.handleSave}>
          {isDonation ? (
            <RadioGroup
              name="donation"
              value={donationIndex.toString()}
              onChange={this.handleRadio}
            >
              {Radio => (
                <List>
                  {donationList.map((item, index) => (
                    <ListItem key={item.code}>
                      <Radio value={index.toString()}>{item.name}</Radio>
                    </ListItem>
                  ))}
                </List>
              )}
            </RadioGroup>
          ) : (
            <div>
              <FormItem>
                <StyledInput
                  name="name"
                  value={name}
                  error={t(error.name)}
                  label={t(
                    type === DUPLICATE
                      ? 'TW_Invoice.placeholder_name'
                      : 'TW_Invoice.placeholder_company_name'
                  )}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
              </FormItem>
              {type === TRIPLICATE && (
                <FormItem>
                  <StyledInput
                    name="taxID"
                    value={taxID}
                    error={t(error.taxID)}
                    label={t('TW_Invoice.placeholder_uniform_number')}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                </FormItem>
              )}
              <FormItem>
                <StyledInput
                  name="email"
                  value={email}
                  error={t(error.email)}
                  label={t('TW_Invoice.placeholder_email_address')}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
              </FormItem>
              <FormItem>
                <StyledInput
                  name="address"
                  value={address}
                  error={t(error.address)}
                  label={t('TW_Invoice.placeholder_mail_address')}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
              </FormItem>
            </div>
          )}
          <FormActionContainer>
            <CancelButton
              variant="link"
              type="button"
              onClick={this.handleCancel}
            >
              {t('TW_Invoice.button_cancel')}
            </CancelButton>
            <Button
              variant="primary"
              type="submit"
              disabled={this.hasError()}
              solid
            >
              {t('TW_Invoice.button_ok')}
            </Button>
          </FormActionContainer>
        </StyledForm>
      </Container>
    );
    return (
      <Popover
        appendTarget={parent}
        isOpen={isOpen}
        preferPlace="right"
        target={children}
        body={popoverBody}
        onOuterAction={this.handleClickOutside}
        style={{
          position: 'fixed',
        }}
      >
        {children}
      </Popover>
    );
  }
}

const mapStateToProps = state => ({
  donationList: getDonationList(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(InvoiceInfo);
