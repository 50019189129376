import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { func, string, arrayOf, shape, number } from 'prop-types';
import styled from 'styled-components';
import { Button, Heading, HeadingGroup, RadioGroup } from '@lalamove/karang';
import {
  edit as EditIcon,
  warning as WarningIcon,
  receipt as ReceiptIcon,
} from '@lalamove/karang/dist/components/Icon/icons';
import _every from 'lodash/every';

import { getCheckoutErrors, setCheckoutError } from 'store/modules/checkout';
import {
  DUPLICATE,
  TRIPLICATE,
  DONATION,
  DEFAULT_INVOICE_TYPE,
  DEFAULT_INVOICE_INFO,
  loadSelectedInvoice,
  saveSelectedInvoice,
  loadInvoiceInfo,
  saveInvoiceInfo,
} from 'interfaces/global/store/modules/checkout/uniformInvoice';
import Tag from 'components/Tag';
import { noop } from 'utils/helpers';
import { black, darkGray, orange, red, silver, white } from 'styles/colors';
import { getUser } from 'interfaces/global/store/modules/auth/selectors';
import InvoiceInfo from './InvoiceInfo';

const HeadingWrapper = styled.div`
  position: relative;
`;

const StyledTag = styled(Tag)`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const NotSavedLabel = styled.span`
  margin-left: 0.5em;
  vertical-align: middle;
`;

const HeadingSubtitle = styled.span`
  overflow: hidden;
  max-width: 25rem;
  color: ${silver};
  font-size: 0.75em;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledHeadingGroup = styled(HeadingGroup)`
  justify-content: flex-start;
`;

const StyledHeading = styled(Heading)`
  &&& {
    flex: 0 0 auto;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
`;

// eslint-disable-next-line react/prop-types
const CheckoutHeading = ({ children, title, subtitle }) => (
  <StyledHeadingGroup>
    {children}
    <StyledHeading size="large" htmlTag="h2">
      {title}
    </StyledHeading>
    <HeadingSubtitle>{subtitle}</HeadingSubtitle>
  </StyledHeadingGroup>
);

const StyledRadioGroup = styled(RadioGroup)`
  margin: 0;
  color: ${black};
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5em 1em;
  border: 1px solid ${darkGray};
`;

const FormItem = styled.div`
  flex-grow: 1;
  margin: auto;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ErrorContainer = styled.div`
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  line-height: 1.4;
`;

const ErrorIcon = styled.span`
  float: right;
  margin-left: 1em;
`;

const ErrorMessage = styled.span`
  color: ${red};
  font-size: 0.75em;
`;

const infoInitState = {
  [DUPLICATE]: DEFAULT_INVOICE_INFO,
  [TRIPLICATE]: { ...DEFAULT_INVOICE_INFO, taxID: '' },
  [DONATION]: { donationIndex: 0 },
};

export class UniformInvoice extends Component {
  static defaultProps = {
    t: noop,
    setCheckoutError: noop,
    error: '',
    userEmail: '',
  };

  static propTypes = {
    t: func,
    setCheckoutError: func,
    error: string,
    userEmail: string,
    userFid: string.isRequired,
    donationList: arrayOf(shape({ code: number, name: string })).isRequired,
  };

  constructor(props) {
    super(props);
    this.invoiceInfoRef = React.createRef();
  }

  state = {
    selectedType: DEFAULT_INVOICE_TYPE,
    info: infoInitState,
    invoiceInfoIsOpen: false,
    invoiceInfoIsDirty: false,
  };

  componentDidMount() {
    const { userFid } = this.props;
    this.setState({
      selectedType: loadSelectedInvoice(userFid),
      info: loadInvoiceInfo(userFid),
    });
  }

  componentWillUnmount() {
    this.props.setCheckoutError('uniformInvoice', '');
  }

  getRef = node => {
    this.node = node;
  };

  getSummary = () => {
    const { donationList } = this.props;
    const { selectedType: type, info } = this.state;
    if (type === DUPLICATE) return info[type].address;
    if (type === TRIPLICATE) return info[type].taxID;
    return donationList[info[type].donationIndex].name;
  };

  handleInvoiceChange = type => {
    this.setState({
      selectedType: type,
      invoiceInfoIsOpen: false,
      invoiceInfoIsDirty: false,
    });
    this.props.setCheckoutError('uniformInvoice', '');
    const isValid = type === DONATION || _every(this.state.info[type]);
    if (isValid) {
      saveSelectedInvoice(this.props.userFid, type);
    } else {
      this.handleOpenInvoiceInfo();
      this.props.setCheckoutError(
        'uniformInvoice',
        'TW_Invoice.error_msg_incomplete_info'
      );
    }
  };

  handleOpenInvoiceInfo = () => {
    this.setState({
      invoiceInfoIsOpen: true,
    });
  };

  handleSaveInvoiceInfo = info => {
    this.setState(
      state => ({
        invoiceInfoIsOpen: false,
        info: {
          ...state.info,
          [state.selectedType]: info,
        },
        invoiceInfoIsDirty: false,
      }),
      () => {
        const type = this.state.selectedType;
        saveInvoiceInfo(this.props.userFid, type, this.state.info[type]);
      }
    );
    this.props.setCheckoutError('uniformInvoice', '');
  };

  handleCancelInvoiceInfo = () => {
    this.setState({
      invoiceInfoIsOpen: false,
      selectedType: loadSelectedInvoice(this.props.userFid),
      invoiceInfoIsDirty: false,
    });
    this.props.setCheckoutError('uniformInvoice', '');
  };

  handleInvoiceInfoError = error => {
    this.props.setCheckoutError('uniformInvoice', error);
  };

  handleInvoiceInfoNotSaved = () => {
    this.setState({
      invoiceInfoIsOpen: false,
      invoiceInfoIsDirty: true,
    });
    this.props.setCheckoutError('uniformInvoice', 'TW_Invoice.label_not_saved');
  };

  render() {
    const {
      selectedType,
      info,
      invoiceInfoIsOpen,
      invoiceInfoIsDirty,
    } = this.state;
    const { t, error, userEmail, donationList } = this.props;
    return (
      <div>
        <HeadingWrapper>
          <CheckoutHeading
            title={t('TW_Invoice.heading_invoice')}
            subtitle={this.getSummary()}
          >
            <ReceiptIcon color={orange} size={24} />
          </CheckoutHeading>
          {invoiceInfoIsDirty && (
            <StyledTag type="yellow">
              <EditIcon color={white} size={12} />
              <NotSavedLabel>{t('TW_Invoice.label_not_saved')}</NotSavedLabel>
            </StyledTag>
          )}
        </HeadingWrapper>

        <div ref={this.getRef}>
          <StyledRadioGroup
            name="invoice"
            value={selectedType}
            onChange={this.handleInvoiceChange}
          >
            {RadioButton => (
              <InvoiceInfo
                parent={this.node}
                isOpen={invoiceInfoIsOpen}
                type={selectedType}
                infoData={info[selectedType]}
                userEmail={userEmail}
                onSave={this.handleSaveInvoiceInfo}
                onCancel={this.handleCancelInvoiceInfo}
                onError={this.handleInvoiceInfoError}
                onNotSaved={this.handleInvoiceInfoNotSaved}
                donationList={donationList}
              >
                <FormGroup>
                  <FormItem>
                    <RadioButton value={DUPLICATE}>
                      {t('TW_Invoice.radio_duplicate_invoice')}
                    </RadioButton>
                  </FormItem>
                  <FormItem>
                    <RadioButton value={TRIPLICATE}>
                      {t('TW_Invoice.radio_triplicate_invoice')}
                    </RadioButton>
                  </FormItem>
                  <FormItem>
                    <RadioButton value={DONATION}>
                      {t('TW_Invoice.radio_donation_invoice')}
                    </RadioButton>
                  </FormItem>
                  <FormItem>
                    <StyledButton onClick={this.handleOpenInvoiceInfo}>
                      {t('TW_Invoice.button_edit')}
                    </StyledButton>
                  </FormItem>
                </FormGroup>
              </InvoiceInfo>
            )}
          </StyledRadioGroup>
          {error && (
            <ErrorContainer>
              <ErrorIcon>
                <WarningIcon color={red} size={13} />
              </ErrorIcon>
              <ErrorMessage>{t(error)}</ErrorMessage>
            </ErrorContainer>
          )}
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  userEmail: getUser(state).profile.email,
  userFid: getUser(state).user_fid,
  error: getCheckoutErrors(state).uniformInvoice,
});

export default compose(
  withTranslation(),
  connect(mapState, { setCheckoutError })
)(UniformInvoice);
