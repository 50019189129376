import React, { Component } from 'react';
import Big from 'big.js';
import { bool, func, shape, string, instanceOf } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fetchRecord } from 'interfaces/global/store/modules/records/actions';
import { closeOrderDetailPanel } from 'interfaces/global/store/modules/localStorage/actions';
import {
  getOrderDetails,
  getDriverById,
} from 'interfaces/global/store/modules/records/selectors';
import { createLoadingSelector } from 'store/modules/loading';
import { openPanel } from 'interfaces/global/store/modules/ui/actions';
import { closeOrderDetailPanelAfterCheckout } from 'interfaces/global/store/modules/localStorage/closeOrderDetailPanel';
import { noop, globalOrderStatusMap as statusMap } from 'utils/helpers';
import notFoundSVG from 'assets/svg/notfound_404.svg';
import {
  OrderDetailShape,
  RouteShape,
  DriverShape,
  PriceBreakDownShape,
} from 'interfaces/global/views/Records/propTypes';
import EmptyState from 'components/EmptyState';
import Spinner from 'components/Spinner';
import Alert from 'containers/Alert';
import ClockIcon from 'assets/svg/clock.svg';
import { withResponsiveMedia } from 'components/MediaQuery';
import MobileHeader from 'components/MobileHeader';
import StatusBar from './components/StatusBar';
import DriverInfo from './components/DriverInfo';
import AddPriorityFee from './AddPriorityFee';
import Cancel from './Cancel';
import OrderDetails from './Details';
import Rating from './Rating';
import Tracking from './Tracking';
import Appeal from './Appeal';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`;

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`;

const NoticeContainer = styled.div`
  padding: 0 1.25em 0 2.25em;
  margin-bottom: 15px;
`;
const NoticeText = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 8px;
  margin: 21px 0 24px;
  background: rgba(234, 245, 253, 0.5);
  color: #303030;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
`;
const NoticeIcon = styled.img`
  vertical-align: middle;
  align-self: baseline;
  margin-right: 4px;
`;

export class Order extends Component {
  static defaultProps = {
    t: noop,
    isLoading: false,
    currentType: null,
    tips: Big(0),
    order: {},
    route: {},
    driver: {},
    price: {},
    openPanel: noop,
    onClose: noop,
    appeal: null,
    closeOrderDetailPanel: noop,
  };

  static propTypes = {
    t: func,
    isLoading: bool,
    orderId: string.isRequired,
    currentType: string,
    tips: instanceOf(Big),
    order: OrderDetailShape,
    route: RouteShape,
    driver: DriverShape,
    price: PriceBreakDownShape,
    openPanel: func,
    onClose: func,
    fetchRecord: func.isRequired,
    appeal: shape({}),
    isMobile: bool.isRequired,
    closeOrderDetailPanel: func,
  };

  state = {
    showDetailsOverlay: false,
  };

  componentDidMount() {
    const { orderId } = this.props;
    this.props.fetchRecord(orderId);
  }

  toggleDetailsOverlay = show => {
    this.setState({
      showDetailsOverlay: show,
    });
  };

  onAddPriorityFee = () => {
    const { orderId } = this.props;
    this.props.openPanel('ORDER', { orderId, currentType: 'addPriorityFee' });
  };

  onCancel = () => {
    const { orderId } = this.props;
    this.props.openPanel('ORDER', { orderId, currentType: 'cancel' });
  };

  onTrack = () => {
    const { orderId } = this.props;
    this.props.openPanel('ORDER', { orderId, currentType: 'tracking' });
  };

  onRate = () => {
    const { orderId } = this.props;
    this.props.openPanel('ORDER', { orderId, currentType: 'rating' });
  };

  onBack = () => {
    const { orderId } = this.props;
    this.props.openPanel('ORDER', { orderId, currentType: null });
  };

  onClose = () => {
    // eslint-disable-next-line no-shadow
    const { onClose, order, closeOrderDetailPanel } = this.props;
    closeOrderDetailPanel(order.status);
    onClose();
    closeOrderDetailPanelAfterCheckout();
  };

  renderComp(currentType) {
    const { orderId, tips, order, route, driver, price } = this.props;

    switch (currentType) {
      case 'addPriorityFee':
        return (
          <AddPriorityFee
            id={orderId}
            refId={order.refId}
            status={order.status}
            paymentMethodId={order.paymentMethodId}
            tips={tips}
            onBack={this.onBack}
            onClose={this.onClose}
            price={price}
          />
        );
      case 'cancel':
        return (
          <Cancel
            id={orderId}
            refId={order.refId}
            status={order.status}
            onBack={this.onBack}
            onClose={this.onClose}
          />
        );
      case 'rating':
        return (
          <Rating
            id={orderId}
            status={order.status}
            driverId={driver.id}
            rated={Boolean(order.userRating)}
            onBack={this.onBack}
            onClose={this.onClose}
            previouslyFavorited={driver.favorited}
            previouslyBanned={driver.banned}
          />
        );
      case 'tracking':
        return (
          <Tracking
            status={order.status}
            id={orderId}
            shareId={order.shareId}
            serviceTypeId={order.serviceTypeId}
            vehicleTypeId={driver.vehicleTypeId}
            waypoints={route.waypoints}
            onBack={this.onBack}
            onClose={this.onClose}
          />
        );
      default:
        return (
          <OrderDetails
            id={orderId}
            refId={order.refId}
            status={order.status}
            editable={order.editable}
            edited={order.edited}
            noteToDriver={order.noteToDriver}
            paymentMethodId={order.paymentMethodId}
            specialRequests={order.specialRequests}
            collectionFen={order.collectionFen}
            subRequests={order.subRequests}
            route={route}
            price={price}
            onAddPriorityFee={this.onAddPriorityFee}
            onCancel={this.onCancel}
            onClose={this.onClose}
            showOverlay={this.state.showDetailsOverlay}
            podEnabled={order.podEnabled}
            currentNode={order.currentNode}
          />
        );
    }
  }

  render() {
    const {
      t,
      currentType,
      order,
      driver,
      isLoading,
      orderId,
      appeal,
      isMobile,
    } = this.props;
    const { DRIVER_COMPLETED, SEND_BILL, COMPLAIN_BILL, MATCHING } = statusMap;

    const appealList = appeal ? [...appeal.unpaid, ...appeal.appeal] : [];

    const MobileHeaderWithSetUp = () =>
      isMobile && (
        <MobileHeader
          center={order.refId ? `#${order.refId}` : undefined}
          onClickLeft={this.onClose}
        />
      );

    if (isLoading && !Object.keys(order).length) {
      return (
        <>
          <MobileHeaderWithSetUp />
          <Wrapper>
            <Center>
              <Spinner big />
            </Center>
          </Wrapper>
        </>
      );
    }

    if (!isLoading && !Object.keys(order).length) {
      const imgNotFound = (
        <img
          src={notFoundSVG}
          alt={t('NotFound.title')}
          width={300}
          height={300}
        />
      );
      return (
        <>
          <MobileHeaderWithSetUp />
          <EmptyState
            image={imgNotFound}
            title={t('NotFound.title')}
            text={t('NotFound.text')}
            actionText={t('NotFound.button_close')}
            action={this.onClose}
          />
        </>
      );
    }

    return (
      <>
        <MobileHeaderWithSetUp />
        <Wrapper data-cy="panel-order">
          <Alert messageSelector={['EDIT_ORDER']} persist />
          {(!currentType || currentType === 'tracking') && (
            <StatusBar
              status={order.status}
              refId={isMobile ? undefined : order.refId}
              dateTime={order.deliveryDatetime}
              solid={
                order.status === DRIVER_COMPLETED.id ||
                order.status === SEND_BILL.id ||
                order.status === COMPLAIN_BILL.id
              }
            />
          )}
          {order.status === DRIVER_COMPLETED.id && (
            <NoticeContainer>
              <NoticeText>
                <NoticeIcon src={ClockIcon} alt="" />
                <div>{t('RecordPanel.waiting_for_driver_confirm_fee')}</div>
              </NoticeText>
            </NoticeContainer>
          )}
          {appealList.length > 0 &&
            (order.status === SEND_BILL.id ||
              order.status === COMPLAIN_BILL.id) && (
              <Appeal
                refId={order.refId}
                orderId={orderId}
                appealList={appealList}
                status={order.status}
              />
            )}
          {(!currentType || currentType === 'tracking') &&
            (order.status === MATCHING.id ||
              Object.keys(driver).length > 0) && (
              <DriverInfo
                id={driver.id}
                name={driver.name}
                phone={driver.phone}
                licensePlate={driver.licensePlate}
                avgRating={driver.avgRating}
                vehicleTypeId={driver.vehicleTypeId}
                photoUri={driver.photoUri}
                favorited={driver.favorited}
                banned={driver.banned}
                serviceTypeId={order.serviceTypeId}
                status={order.status}
                userRating={order.userRating}
                showTrackBtn={currentType !== 'tracking'}
                isVIPOrder={order.noteToDriver.indexOf('#1234') > -1}
                onRate={this.onRate}
                onTrack={this.onTrack}
                onToggleDialog={this.toggleDetailsOverlay}
                orderCompletionDatetime={order.completionDatetime}
              />
            )}
          {Object.keys(order).length && this.renderComp(currentType)}
        </Wrapper>
      </>
    );
  }
}

const mapState = state => {
  const { tips, order, route, driver, price, appeal } = getOrderDetails(state);
  return {
    tips: tips ?? Big(0),
    order: order ?? {},
    route: route ?? {},
    driver: driver ? getDriverById(state, driver.id) : {},
    price: price ?? {},
    appeal,
    isLoading: createLoadingSelector(['FETCH_RECORD'])(state),
  };
};

export default compose(
  withTranslation(),
  withResponsiveMedia,
  connect(mapState, {
    fetchRecord,
    openPanel,
    closeOrderDetailPanel,
  })
)(Order);
