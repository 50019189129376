import {
  DATE_CHANGE,
  FAVOURITE_DRIVER_TOGGLE,
  PAYMENT_METHOD_CHANGE,
  PLACE_ORDER_REQUEST,
  getDeliveryDatetime,
  getPaymentMethod,
} from 'store/modules/checkout';
import {
  SEGMENT_SELECT_ADDRESS,
  SEGMENT_CLICK_OPTIMIZE_ROUTE,
  SEGMENT_SET_SERVICE,
  SEGMENT_ADD_SPECIAL_REQUEST,
  SEGMENT_REMOVE_SPECIAL_REQUEST,
} from 'store/modules/placeOrder/tracking';
import {
  FETCH_PRICE_SUCCESS,
  getSelectedSpecialRequests,
  getPrice,
} from 'store/modules/pricing';
import { getCurrentCountry } from 'store/modules/region/selectors';
import { getFilledWaypts } from 'store/modules/routing';
import {
  WAYPOINT_UPDATE,
  ROUTE_WAYPOINTS_SET,
} from 'store/modules/routing/actionTypes';
import {
  getSelectedServiceAndSubServiceIds,
  getSpecialRequest,
  getSelectedSubRequestIdByRequestId,
} from 'store/modules/services';
import {
  SET_SERVICE,
  ADD_SPECIAL_REQUEST,
  SET_SUB_REQUEST,
  REMOVE_SPECIAL_REQUEST,
} from 'store/modules/services/actionTypes';
import Segment from 'utils/segment';

let tempPromoCode;

export const SEGMENT_APPLY_PROMO_CODE = 'Promo Code Submitted';
export const SEGMENT_CLICK_PLACE_ORDER = 'Place Order Tapped';
export const SEGMENT_COMPLETE_FIRST_PAGE = 'First Page Completed';
export const SEGMENT_TOGGLE_FAVORITE_DRIVER = 'Assign Favorite Driver Updated';
export const SEGMENT_UPDATE_CONTACT_NAME = 'Name Updated';
export const SEGMENT_UPDATE_CONTACT_NUMBER = 'Contact Number Updated';
export const SEGMENT_UPDATE_PAYMENT_METHOD = 'Payment Method Updated';
export const SEGMENT_UPDATE_PICKUP_TIME = 'Pick Up Time Updated';
export const SEGMENT_UPDATE_QUOTATION = 'Check Price Update';

const checkoutTracking = (prevState, action, nextState) => {
  switch (action.type) {
    case FETCH_PRICE_SUCCESS: {
      const { promo, price, trigger } = action;
      const { total, rewards } = price;
      const { serviceId, subServiceId } = getSelectedServiceAndSubServiceIds(
        prevState
      );
      const selectedSpecialRequests = getSelectedSpecialRequests(prevState);
      const { isoCurrencyCode } = getCurrentCountry(prevState);
      const numberOfStops = getFilledWaypts(prevState).length;
      Segment.createTrack(SEGMENT_UPDATE_QUOTATION, {
        vehicle_type: serviceId,
        vehicle_subtype: subServiceId,
        has_additional_services: Boolean(selectedSpecialRequests.length),
        currency_code: isoCurrencyCode,
        order_amount_local: (total + Math.abs(rewards)).toString(),
        stop_total: numberOfStops,
      });
      //
      if (trigger === WAYPOINT_UPDATE) {
        const isEditOrder = window.location.pathname.match(
          /\/orders\/\d+\/edit/
        );
        const { method } = action;
        if (!isEditOrder && method) {
          const methodMap = {
            suggestion: 'input',
            history: 'recent',
            import: 'import_address',
          };
          Segment.createTrack(SEGMENT_SELECT_ADDRESS, {
            method: methodMap[method] || method,
            total_price_shown: total,
            vehicle_type: serviceId,
            vehicle_subtype: subServiceId,
          });
        }
      } else if (trigger === ROUTE_WAYPOINTS_SET) {
        Segment.createTrack(SEGMENT_CLICK_OPTIMIZE_ROUTE, {
          total_price_shown: price.total,
          vehicle_type: serviceId,
          vehicle_subtype: subServiceId,
        });
      } else if (trigger === SET_SERVICE) {
        const { source } = action;
        if (!source) return;
        Segment.createTrack(SEGMENT_SET_SERVICE, {
          total_price_shown: total,
          vehicle_type: serviceId,
          vehicle_subtype: subServiceId,
          source,
        });
      } else if (
        trigger === ADD_SPECIAL_REQUEST ||
        trigger === SET_SUB_REQUEST
      ) {
        const isCloneOrder = window.location.pathname.match(/\/orders/);
        const { id } = action;
        if (isCloneOrder) return;
        Segment.createTrack(SEGMENT_ADD_SPECIAL_REQUEST, {
          addon_service_type: getSpecialRequest(prevState, id).trimId,
          addon_subservice_type: getSelectedSubRequestIdByRequestId(
            nextState,
            id
          ),
          vehicle_type: serviceId,
          vehicle_subtype: subServiceId,
          total_price_shown: total,
        });
      } else if (trigger === REMOVE_SPECIAL_REQUEST) {
        const { id } = action;
        Segment.createTrack(SEGMENT_REMOVE_SPECIAL_REQUEST, {
          addon_service_type: getSpecialRequest(prevState, id).trimId,
          addon_subservice_type: getSelectedSubRequestIdByRequestId(
            prevState,
            id
          ),
          vehicle_type: serviceId,
          vehicle_subtype: subServiceId,
          total_price_shown: total,
        });
      }

      if (promo.promoCode && tempPromoCode !== promo.promoCode) {
        tempPromoCode = promo.promoCode;
        Segment.createTrack(SEGMENT_APPLY_PROMO_CODE, {
          promo_code: promo.promoCode,
          promo_code_applied: !!promo.valid,
          ...(promo.error && { error_key: promo.error }),
        });
      }
      break;
    }
    case '@@router/LOCATION_CHANGE': {
      const { payload } = action;
      if (payload.location.pathname !== '/confirmation') return;
      // Tracking of landing confirmation page before place order
      const { serviceId, subServiceId } = getSelectedServiceAndSubServiceIds(
        prevState
      );
      const numberOfStops = getFilledWaypts(prevState).length;
      const selectedSpecialRequests = getSelectedSpecialRequests(prevState);
      const { total, rewards } = getPrice(prevState);
      const { isoCurrencyCode } = getCurrentCountry(prevState);

      const isScheduled = payload.location.search.includes('scheduled=true');
      Segment.createTrack(SEGMENT_COMPLETE_FIRST_PAGE, {
        pickup_type: isScheduled ? 'scheduled' : 'immediate',
        vehicle_type: serviceId,
        vehicle_subtype: subServiceId,
        total_price_shown: total,
        has_additional_services: Boolean(selectedSpecialRequests.length),
        currency_code: isoCurrencyCode,
        order_amount_local: (total + Math.abs(rewards)).toString(),
        rewards_amount_local: Math.abs(rewards).toString(),
        paid_order_amount_local: total.toString(),
        stop_total: numberOfStops,
        days_in_advance: isScheduled ? 1 : 0,
      });
      break;
    }
    case DATE_CHANGE: {
      const { date: newDateTime, isDirty } = action;
      if (!isDirty) return; // Scheduled Order only
      const oldDateTime = getDeliveryDatetime(prevState);
      Segment.createTrack(SEGMENT_UPDATE_PICKUP_TIME, {
        original_pickuptime: oldDateTime,
        new_pickuptime: newDateTime,
      });
      break;
    }
    case FAVOURITE_DRIVER_TOGGLE: {
      const { toggle } = action;
      Segment.createTrack(SEGMENT_TOGGLE_FAVORITE_DRIVER, {
        is_favorite_driver: toggle,
      });
      break;
    }
    case PAYMENT_METHOD_CHANGE: {
      const oldMethod = getPaymentMethod(prevState);
      const { method } = action;
      Segment.createTrack(SEGMENT_UPDATE_PAYMENT_METHOD, {
        original_payment_method: oldMethod,
        new_payment_method: method,
      });
      break;
    }
    case PLACE_ORDER_REQUEST: {
      Segment.createTrack(SEGMENT_CLICK_PLACE_ORDER);
      break;
    }
    default:
      break;
  }
};

export default checkoutTracking;
