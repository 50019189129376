import _mapKeys from 'lodash/mapKeys';
import { convertLocaleInKey } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

// interface Request {
//   country: String; // e.g. HK_HKG
//   entity: String;
// }

// interface ReasonTranslation {
//   id: String; // key
//   [reason_lang: String]: String; // en_hk
// }
// interface URLTransalation {
//   [lang: String]: String; // en_hk
// // }
// interface Data {
//   ERROR_MSG: {
//     [key: string]: {
//       [lang: string]: string; // en_hk: "123"
//     };
//   };
//   REASON: {
//     USER_CANCELLATION_ASSIGNING: ReasonTranslation[];
//     USER_CANCELLATION_MATCHED: ReasonTranslation[];
//     USER_1_RATING?: ReasonTranslation[];
//     USER_2_RATING?: ReasonTranslation[];
//     USER_3_RATING?: ReasonTranslation[];
//     USER_4_RATING?: ReasonTranslation[];
//     USER_5_RATING?: ReasonTranslation[];
//     // CANCELLATION: ReasonTranslation[],
//     // PICKUP_FAIL: ReasonTranslation[],
//   };
//   REG_EXPR: {
//     PHONE: string,
//     [key: string]: string, // EMAIL | TAX_ID
//   };
//   TIPS: {
//     amount: Number[],
//     max_amount: Number,
//     min_amount: Number,
//   };
//   UNIFORM_INVOICE: {
//     enable: Boolean,
//     donation_list: {
//       code: Number,
//       name: String,
//     }[],
//   };
//   URL: {
//     ETIQUTTE_URL: URLTransalation;
//     PRIVACY_URL: URLTransalation;
//     USER_FAQ: URLTransalation;
//     USER_TOPUP_URL?: URLTransalation;
//     [string: key]: URLTransalation;
//   };
// }
// MobileAPIResponse<Response>
// convert REASON from /vanlookup to key-value pairs which same as ERROR_MSG format
export function convertCancelReason(reasons) {
  const resources = {};
  for (const category in reasons) {
    if (Object.prototype.hasOwnProperty.call(reasons, category)) {
      resources[category] = reasons[category].reduce((result, reason) => {
        const { id, ...remainData } = reason;
        // eslint-disable-next-line no-param-reassign
        result[id] = _mapKeys(remainData, (_, key) =>
          key.replace('reason_', '')
        );
        return result;
      }, {});
    }
  }
  return resources;
}

export default async function vanLookup(city) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  const params = {
    country: city,
    entity: 'ERROR_MSG,REG_EXPR,URL,UNIFORM_INVOICE,REASON,TIPS',
  };
  const { success, data, code } = await fetcher.get('vanlookup', params, {
    headers: { 'X-LLM-LOCATION': city },
  });
  if (!success) throw code;

  data.REASON = convertCancelReason(data.REASON);
  return convertLocaleInKey(data);
}
