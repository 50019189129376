import { Region } from 'interfaces/global/containers/CitySelector/types';

interface CountryPickerOption {
  label: string;
  value: string;
  name?: string;
  options?: CountryPickerOption[];
}

const createCountryPickerOptions = (
  countries: Region['countries'],
  cityDict: Region['cityDict'],
  locale: string
): CountryPickerOption[] =>
  countries
    .filter(({ cities, countryId }) => (cities && cities.length) || countryId)
    .sort((a, b) => {
      // sort by English name:
      const aTrans = a.translations.find(trans => trans.id.startsWith('en'));
      const bTrans = b.translations.find(trans => trans.id.startsWith('en'));
      const aName = aTrans ? aTrans.value : a.id;
      const bName = bTrans ? bTrans.value : b.id;
      return aName.localeCompare(bName);
    })
    .map(({ id, defaultLanguage, translations, cities }) => {
      const trans = translations.find(lang => lang.id === defaultLanguage);
      if (!cities || !cities.length) {
        let name = id;
        if (translations && defaultLanguage) {
          const translation = translations.find(
            lang => lang.id === defaultLanguage
          );
          if (translation) {
            name = translation.value;
          }
        }
        return {
          label: name,
          name,
          value: id,
        };
      }

      let option: CountryPickerOption = {
        value: cities[0],
        label: trans?.value ?? cities[0],
      };

      if (cities.length > 1) {
        const cityOptions = cities.map(cityCode => {
          const isCityInCurrentCountry =
            cityCode.split('_')[0] === locale.split('_')[1];

          const cityTrans = cityDict[cityCode].translations.find(
            lang =>
              lang.id === (isCityInCurrentCountry ? locale : defaultLanguage)
          );

          const cityName = cityDict[cityCode].translations.find(lang =>
            lang.id.startsWith('en')
          );
          return {
            label: (cityTrans || cityName || {}).value || cityCode,
            name: cityName ? cityName.value : cityCode,
            value: cityCode,
          };
        });
        option = {
          ...option,
          value: id,
          options: cityOptions.sort((a, b) => a.name.localeCompare(b.name)),
        };
      }
      return option;
    });

export default createCountryPickerOptions;
