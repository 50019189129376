import storage from 'utils/storage';
import { Region } from './registerLocation';

export const KEY = 'last_used_location';

export const loadLastUsedLocation = (): Region => {
  const json: string = storage.getItem(KEY);
  return JSON.parse(json) || {};
};

export const saveLastUsedLocation = (payload: Region): void => {
  storage.setItem(KEY, JSON.stringify(payload));
};
