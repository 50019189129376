/* eslint-disable lines-between-class-members */
// import Delivery from './Delivery';
import { PaymentBreakdown, PaymentSummary } from './Payment';
import { PriceBreakdown, PriceSummary } from './Pricing';
import MonetaryAmount from './MonetaryAmount';

// interface QuotePromoCode {
//   name: string;
// }

// interface QuotesSpecialRequest {
//   id: string;
// }

// interface QuotesAdditionalFee {
//   amount: number; // int
// }

// interface QuotationStruct {
//   id: string;
//   cityCode: string;
//   serviceType?: string;
//   clientId?: string;
//   isImmediate?: boolean;
//   deliveries: Delivery[];
//   scheduledAt: string; // ISO Z
//   baseClientOrderId?: string;
//   promocode?: QuotePromoCode;
//   specialRequests?: QuotesSpecialRequest[]; // get from client Order
//   priorityFee?: QuotesAdditionalFee;
//   adjustment?: QuotesAdditionalFee;
//   totalPayment?: PaymentSummary;
//   totalPrice?: PriceSummary;
//   paymentBreakdown?: PaymentBreakdown[];
//   priceBreakdown?: PriceBreakdown[];
// }

export default class Quotation {
  id = '';
  // cityCode = '';
  // serviceType = null;
  // clientId = '';
  // isImmediate = null;
  // deliveries = [];
  // scheduledAt = ''; // ISO Z
  baseClientOrderId = null;
  // promocode = null;
  // specialRequests = null; // get from client Order
  priorityFee = null;
  adjustment = null;

  _totalPayment = null;
  _totalPrice = null;
  _paymentBreakdown = null;
  _priceBreakdown = null;

  constructor(q = {}) {
    Object.keys(this).forEach(k => {
      const key = k.startsWith('_') ? k.substring(1, k.length) : k;
      if (Object.prototype.hasOwnProperty.call(q, key)) this[key] = q[key];
    });
  }

  get totalPayment() {
    return this._totalPayment;
  }
  set totalPayment(tp) {
    this._totalPayment = new PaymentSummary(tp);
  }

  get totalPrice() {
    return this._totalPrice;
  }
  set totalPrice(tp) {
    this._totalPrice = new PriceSummary(tp);
  }

  get paymentBreakdown() {
    return this._paymentBreakdown;
  }
  set paymentBreakdown(pb) {
    this._paymentBreakdown = pb.map(PaymentBreakdown.New);
  }

  get priceBreakdown() {
    return this._priceBreakdown;
  }
  set priceBreakdown(pb) {
    this._priceBreakdown = pb.map(PriceBreakdown.New);
  }

  get fee() {
    return (this._priceBreakdown || [])
      .filter(fee => fee.type === 'BASE')
      .reduce((memo, fee) => fee.add(memo), new MonetaryAmount()).exactAmount;
  }

  get services() {
    return (this._priceBreakdown || [])
      .filter(fee => fee.type === 'SPECIAL_REQUEST')
      .reduce((memo, fee) => fee.add(memo), new MonetaryAmount()).exactAmount;
  }

  get surcharge() {
    return (this._priceBreakdown || [])
      .filter(
        fee =>
          fee.type === 'SURCHARGE' ||
          (fee.type === 'ADJUSTMENT' && fee.amount > 0)
      )
      .reduce((memo, fee) => fee.add(memo), new MonetaryAmount()).exactAmount;
  }

  get discount() {
    return (this._priceBreakdown || [])
      .filter(
        fee =>
          ['PROMOCODE', 'DISCOUNT'].includes(fee.type) ||
          (fee.type === 'ADJUSTMENT' && fee.amount < 0)
      )
      .reduce((memo, fee) => fee.add(memo), new MonetaryAmount()).exactAmount;
  }

  get tips() {
    return (this._priceBreakdown || [])
      .filter(fee => fee.type === 'PRIORITY_FEE')
      .reduce((memo, fee) => fee.add(memo), new MonetaryAmount()).exactAmount;
  }

  get rewards() {
    return (this._paymentBreakdown || [])
      .filter(fee => fee.type === 'REWARD')
      .reduce((memo, fee) => fee.add(memo), new MonetaryAmount()).exactAmount;
  }

  get total() {
    return (this._paymentBreakdown || []).reduce(
      (memo, fee) => fee.add(memo),
      new MonetaryAmount()
    ).exactAmount;
  }

  get price() {
    return {
      fee: this.fee,
      services: this.services,
      surcharge: this.surcharge,
      discount: this.discount,
      tips: this.tips,
      rewards: this.rewards,
      total: this.total,
    };
  }

  // for api request (try to fit backend interface)
  toJSON() {
    return Object.keys(this).reduce((memo, k) => {
      if (this[k] === null) return memo; // filter null value

      const key = k.startsWith('_') ? k.substring(1, k.length) : k;
      return {
        ...memo,
        [key]: this[key],
      };
    }, {});
  }

  debug() {
    return Object.entries(this).reduce(
      (memo, [key, val]) => ({
        ...memo,
        [key]: val,
      }),
      { price: this.price }
    );
  }
}
