import { sessionService } from 'redux-react-session';
import { getUser } from 'store/modules/auth/selectors';
import { toLLMUpper } from 'utils/locale';
import storage from 'utils/storage';
import {
  DONATION,
  DUPLICATE,
  TRIPLICATE,
  saveSelectedInvoice,
  saveInvoiceInfo,
} from 'interfaces/global/store/modules/checkout/uniformInvoice';

const STORAGE_VERSION_KEY = 'LLM_STORAGE_VERSION';
const LATEST_STORAGE_VERSION = 0;

const patchUserLang = () => {
  const locale = storage.getItem('userLang');
  // convert legacy lang code used in webapp for old users
  if (locale && locale.includes('_')) {
    storage.setItem('userLang', toLLMUpper(locale));
  }
};

const patches = [patchUserLang];
export function runLocalStorageMigration() {
  const version = storage.getItem(STORAGE_VERSION_KEY) || -1;
  if (version < LATEST_STORAGE_VERSION) {
    try {
      patches.filter((_, idx) => idx > version).forEach(x => x());
    } catch {
      storage.clear();
    }
    storage.setItem(STORAGE_VERSION_KEY, LATEST_STORAGE_VERSION);
  }
}

const SESSION_VERSION_KEY = 'LLM_SESSION_STORAGE_VERSION';
const LATEST_SESSION_VERSION = 2;

const patchClient = async store => {
  const state = await store.getState();
  const user = getUser(state);

  if (Object.keys(user).length) {
    await sessionService.saveUser({
      ...user,
      client_id: String(user.client_id),
      corporate_id: String(user.corporate_id),
    });
  }
};

// eslint-disable-next-line no-unused-vars
async function patchUniformInvoice(store) {
  const state = await store.getState();
  const { user_fid: userFid } = getUser(state);

  // Only global system has this userFid, it is feature flag for this migration
  if (userFid) {
    // Migrate invoice_selected & invoice info
    const INVOICE_SELECTED_KEY = 'invoice_selected';
    const selectedType = storage.getItem(INVOICE_SELECTED_KEY);

    // Migrate if not yet migrate before
    if (selectedType) {
      const types = [DUPLICATE, TRIPLICATE, DONATION];
      types.forEach(type => {
        const infoString = storage.getItem(`invoice_${type}`);
        if (infoString) {
          saveInvoiceInfo(userFid, type, JSON.parse(infoString));
          storage.removeItem(`invoice_${type}`);
        }
      });

      saveSelectedInvoice(userFid, selectedType);
      storage.removeItem(INVOICE_SELECTED_KEY);
    }
  }
}

const patchProfileType = async store => {
  const state = await store.getState();
  const user = getUser(state);

  if (Object.keys(user).length) {
    await sessionService.saveUser({
      ...user,
      profile_type: user.is_ep || 2, // 1: corporate, 2: personal
    });
  }
};

const sessionPatches = [patchClient, patchUniformInvoice, patchProfileType];
export function runSessionMigration(store) {
  // Run according to version
  const version = storage.getItem(SESSION_VERSION_KEY) || -1;
  if (version < LATEST_SESSION_VERSION) {
    try {
      sessionPatches.filter((_, idx) => idx > version).forEach(x => x(store));
    } catch {
      storage.clear();
    }
    storage.setItem(SESSION_VERSION_KEY, LATEST_SESSION_VERSION);
  }
}
