import React from 'react';
import styled from 'styled-components';
import { openPanel } from 'store/modules/ui';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { batch, useDispatch, useSelector } from 'react-redux';
import StatusBar from 'interfaces/global/containers/Panel/Order/components/StatusBar';
import { globalOrderStatusMap, trackingStatusMap } from 'utils/helpers';
import RouteList from 'interfaces/global/views/Records/components/RouteList';
import _curry from 'lodash/curry';
import { useTranslation } from 'react-i18next';
import { Icon } from 'interfaces/global/containers/SelectVehicle/styles';
import formatter from 'utils/formatter';
import { getServiceIconByOrderId } from 'interfaces/global/store/modules/services/selectors';
import { RecordsPageOrderListItem } from 'interfaces/global/views/Records/types';
import { WayPoint } from 'interfaces/global/store/modules/routing/types';
import { nobel } from 'styles/colors';

export const Container = styled.div`
  margin: 8px 16px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const Content = styled.div`
  margin: 12px 16px;
`;

const DividingLine = styled.div`
  border: 1px solid ${nobel[100]};
`;

const VehicleAndPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
`;

const VehicleIconAndName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const VehicleOrDriverName = styled.span`
  padding: 0px 8px;
  margin-bottom: -3px;
  font-weight: bold;
`;

const PriceText = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

interface Props {
  order: RecordsPageOrderListItem;
}

const MobileRecordThumbnail: React.FC<Props> = ({ order }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const vehicleIcon = useSelector(state =>
    getServiceIconByOrderId(state, order.serviceTypeId, 'background')
  );

  const { DRIVER_COMPLETED, SEND_BILL, COMPLAIN_BILL } = globalOrderStatusMap;
  const {
    id,
    status: { id: statusId, parentID },
    refId,
    deliveryDatetime,
    waypoints,
    serviceTypeId,
    driver,
    price: { total: totalPrice },
  } = order;

  const onClick = () =>
    batch(() => {
      dispatch(
        track('orders_detail_tapped', {
          order_id: refId,
          order_status:
            trackingStatusMap[parentID as keyof typeof trackingStatusMap],
        })
      );
      dispatch(openPanel('ORDER', { orderId: id }));
    });

  return (
    <Container onClick={onClick}>
      <StatusBar
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        status={statusId}
        refId={refId}
        dateTime={deliveryDatetime}
        solid={
          statusId === DRIVER_COMPLETED.id ||
          statusId === SEND_BILL.id ||
          statusId === COMPLAIN_BILL.id
        }
        isMobileThumbnail
      />
      <Content>
        <RouteList
          t={_curry(t, 2)('Records.number_of_stops')}
          list={waypoints.map((stop: WayPoint) => stop.name)}
        />
      </Content>
      <DividingLine />
      <VehicleAndPriceContainer>
        <VehicleIconAndName>
          {vehicleIcon && <Icon src={vehicleIcon} />}
          <VehicleOrDriverName>
            {driver?.name || serviceTypeId}
          </VehicleOrDriverName>
        </VehicleIconAndName>
        <PriceText>{formatter.currency(totalPrice)}</PriceText>
      </VehicleAndPriceContainer>
    </Container>
  );
};
export default MobileRecordThumbnail;
