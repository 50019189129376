import React from 'react';
import Big from 'big.js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { func, instanceOf } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@lalamove/karang';
import { walletAdd as WalletAddIcon } from '@lalamove/karang/dist/components/Icon/icons';

import { offWhite, silver } from 'styles/colors';
import formatter from 'utils/formatter';
import { noop } from 'utils/helpers';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25em 2em;
  margin-left: auto;
  border-radius: 2em 0 0 2em;
  background-color: ${offWhite};
`;

export const Label = styled.span`
  margin-right: 1em;
  color: ${silver};
  font-size: 0.75em;
`;

export const Value = styled.span`
  margin-right: 0.5em;
  font-size: 1.25em;
`;

export const WalletBanner = ({
  t,
  rewardBalance,
  creditBalance,
  openTopUp,
}) => (
  <Container>
    <Label>{t('Wallet.label_credits')}</Label>
    <Value>{formatter.currency(creditBalance)}</Value>
    <Button
      variant="primary"
      icon={<WalletAddIcon />}
      onClick={openTopUp}
      solid
    >
      {t('Wallet.button_topup')}
    </Button>
  </Container>
);

WalletBanner.propTypes = {
  t: func,
  rewardBalance: instanceOf(Big),
  creditBalance: instanceOf(Big),
  openTopUp: func,
};

WalletBanner.defaultProps = {
  t: noop,
  rewardBalance: Big(0),
  creditBalance: Big(0),
  openTopUp: noop,
};

export default compose(withTranslation(), connect(null, {}))(WalletBanner);
