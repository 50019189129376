import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bool, func, string, number, shape, arrayOf } from 'prop-types';
import styled from 'styled-components';
import { RadioGroup, Input } from '@lalamove/karang';
import { addstop as AddStopIcon } from '@lalamove/karang/dist/components/Icon/icons';

import { updatePriorityFee } from 'store/modules/records';
import { createLoadingSelector } from 'store/modules/loading';
import { getPriorityFee } from 'store/modules/region/selectors';
import Heading from 'components/SliderPanel/Heading';
import Footer from 'components/SliderPanelFooter';

import formatter from 'utils/formatter';
import { noop, statusMap } from 'utils/helpers';
import { silver } from 'styles/colors';

import Price from './components/Price';
import { Container, Content, Wrapper } from './style';

const SCRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-wrap: wrap;
  > label {
    flex: 0 calc(50% - 1em);
    box-sizing: border-box;
  }
`;

const Separator = styled.div`
  color: ${silver};
  font-size: 0.8em;
  text-align: center;
`;

const StyledInput = styled(Input)`
  display: block;
  margin: 1em 0;
`;

const StyledPrice = styled(Price)`
  padding: 0 1.2em;
`;

class AddPriorityFee extends Component {
  static defaultProps = {
    id: null,
    status: null,
    paymentMethod: null,
    isLoading: false,
    updatePriorityFee: noop,
    onBack: noop,
    onClose: noop,
  };

  static propTypes = {
    t: func.isRequired,
    id: string,
    status: number,
    paymentMethod: string,
    current: number.isRequired,
    priorityFee: number.isRequired,
    isLoading: bool,
    updatePriorityFee: func,
    onBack: func,
    onClose: func,
    priorityFeeConfig: shape({
      min: number,
      manx: number,
      options: arrayOf(number),
    }).isRequired,
  };

  state = {
    selectedAmount: null,
    customAmount: '',
    error: '',
  };

  componentDidMount() {
    if (this.props.status !== statusMap.ASSIGNING) {
      this.props.onClose();
    }

    const {
      priorityFeeConfig: {
        options: [preSelected],
      },
    } = this.props;
    this.setState({ selectedAmount: preSelected.toString() });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.props.onClose();
    }
  }

  handleOptionSelected = amount => {
    this.setState({ selectedAmount: amount, customAmount: '', error: '' });
  };

  handleClick = () => {
    this.setState({ selectedAmount: null });
  };

  handleChange = ({ target: { value } }) => {
    const regex = /^[0-9]+$/;
    if (value && !regex.test(value)) return;
    this.setState({ customAmount: value, error: '' });
  };

  handleBlur = ({ target: { value } }) => {
    const {
      t,
      priorityFee: existingPriorityFee,
      priorityFeeConfig: { min, max },
    } = this.props;
    const total = existingPriorityFee + parseInt(value, 10);
    if (total < min || total > max) {
      this.setState({
        error: t('RecordPanel.error_msg_invalid_custom_fee', {
          min: formatter.currency(min),
          max: formatter.currency(max),
        }),
      });
    }
  };

  getPriorityFee = () => {
    const { customAmount, selectedAmount } = this.state;
    const amount = customAmount || selectedAmount || 0;
    return parseInt(amount, 10);
  };

  updatePriorityFee = () => {
    const { priorityFee: existingPriorityFee } = this.props;
    const amount = existingPriorityFee + this.getPriorityFee();
    this.props.updatePriorityFee(this.props.id, amount);
  };

  render() {
    const { selectedAmount, customAmount, error } = this.state;
    const {
      t,
      id,
      current,
      paymentMethod,
      onBack,
      onClose,
      isLoading,
      priorityFeeConfig: { options },
    } = this.props;
    if (!id) return null;

    const price = {
      current,
      tips: this.getPriorityFee(),
    };
    price.total = Object.values(price).reduce((sum, item) => sum + item);

    return (
      <Container>
        <Content>
          <Heading
            icon={<AddStopIcon size={24} />}
            title={t('RecordPanel.title_add_priority_fee')}
            description={t('RecordPanel.msg_add_priority_fee')}
          />
          <Wrapper>
            <SCRadioGroup
              name="amount"
              value={selectedAmount}
              onChange={this.handleOptionSelected}
              variant="toggle"
            >
              {Radio =>
                options.map(option => (
                  <Radio key={option} value={option.toString()}>
                    + {formatter.currency(option)}
                  </Radio>
                ))
              }
            </SCRadioGroup>
            <Separator>{t('RecordPanel.msg_or')}</Separator>
            <StyledInput
              label={t('RecordPanel.input_label_custom_fee')}
              value={customAmount}
              onClick={this.handleClick}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={error}
            />
          </Wrapper>
        </Content>
        <StyledPrice price={price} paymentMethod={paymentMethod} />
        <Footer
          primaryAction={this.updatePriorityFee}
          primaryButtonText={t('RecordPanel.button_add')}
          isLoadingPrimaryButton={isLoading}
          disablePrimaryButton={isLoading || !!error || !price.tips}
          secondaryAction={onBack}
          secondaryButtonText={t('RecordPanel.button_back')}
          closeAction={onClose}
          closeButtonText={t('RecordPanel.button_close_panel')}
        />
      </Container>
    );
  }
}

const mapState = state => ({
  isLoading: createLoadingSelector(['UPDATE_PRIORITY_FEE'])(state),
  priorityFeeConfig: getPriorityFee(state),
});

export default compose(
  withTranslation(),
  connect(mapState, { updatePriorityFee })
)(AddPriorityFee);
