import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import { makeWaypointDescription } from 'interfaces/global/store/modules/routing/helpers';
// action-types
import { WAYPOINT_NEW, WAYPOINT_UPDATE } from '../actions';

// state
export const initState = {
  name: '',
  lat: null,
  lng: null,
  placeId: '',
  description: '',
  address: '',
};

// reducer
export default function stopItemReducer(state = initState, action) {
  switch (action.type) {
    case WAYPOINT_NEW: {
      const newStopItem = { ...initState, id: action.id };
      return newStopItem;
    }
    case WAYPOINT_UPDATE: {
      const { type, ...payload } = action;
      const picked = _omitBy(payload, _isNil);
      const description = makeWaypointDescription(payload);
      return {
        ...state,
        ...picked,
        description,
      };
    }
    default:
      return state;
  }
}
