import storage from 'utils/storage';

export const KEY = 'open_order_detail_panel_after_checkout';

export const setOpenOrderDetailPanelAfterCheckout = (): void => {
  storage.setItem(KEY, 'true');
};

export const closeOrderDetailPanelAfterCheckout = (): void => {
  storage.removeItem(KEY);
};
