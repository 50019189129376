import { takeLatest, put, takeEvery } from 'redux-saga/effects';
import { elementExistPromise, elementNotExistsPromise } from 'utils/domHelper';
import {
  PANEL_OPEN,
  PANEL_CLOSE,
} from 'interfaces/global/store/modules/ui/actions';
import { GENESYS_LOGOUT, actions } from './actions';

export function logoutWidget() {
  if (typeof window.customPlugin === 'undefined') {
    return;
  }
  window.customPlugin.command('WebChatService.endChat');
  window.customPlugin.command('WebChat.close');
}

export function* handlePanelOpen() {
  // TODO: not to hardcode Panel element
  const panel = yield elementExistPromise(node =>
    node.getElementsByClassName('LLM_SliderPanel')
  );
  if (panel[0]) {
    const width = panel[0].offsetWidth;
    yield put(
      actions.movePosition({
        right: width + 16,
        bottom: 8,
      })
    );
  }
}
export function* handlePanelClose() {
  yield elementNotExistsPromise(node =>
    node.getElementsByClassName('LLM_SliderPanel')
  );
  yield put(
    actions.movePosition({
      right: 16,
      bottom: 8,
    })
  );
}

export default function* genesysSaga() {
  yield takeLatest(GENESYS_LOGOUT, logoutWidget);
  yield takeEvery(PANEL_OPEN, handlePanelOpen);
  yield takeEvery(PANEL_CLOSE, handlePanelClose);
}
