// locale (en_hk, en_HK, en-HK, th)
export const toLLMUpper = locale => {
  const [head, ...tail] = locale.replace('_', '-').split('-');
  return [head, ...tail.map(x => x.toUpperCase())].join('_');
};

// locale (en_hk, en_HK, en-HK, th)
export const toLLMLower = locale => locale.replace('-', '_').toLowerCase();

// locale (en_hk, en_HK, en-HK, th)
export const toIETF = locale => {
  const [head, ...tail] = locale.replace('_', '-').split('-');
  return [head, ...tail.map(x => x.toUpperCase())].join('-');
};

export const getParentLocale = locale => {
  // eslint-disable-next-line no-unused-vars
  const [head, ..._] = locale.replace('_', '-').split('-');
  return head;
};
