import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Heading } from '@lalamove/karang';
import Maps from 'components/Maps';
import { getServiceIcon } from 'store/modules/services';
import {
  getCurrentCountry,
  getCurrentLocale,
  getCurrentCity,
} from 'store/modules/region/selectors';
import { noop } from 'utils/helpers';
import { fetchDriverLocation } from 'api/uAPI';
import { isSafari } from 'utils/userAgentHelper';

import defaultVehicleIcon from 'assets/generic.png';
import { black, darkGray } from 'styles/colors';

const Wrapper = styled.div`
  flex-grow: 1;
  padding: ${isSafari(navigator.userAgent)
    ? `1.5em 1em 5.5em 2.25em`
    : `1.5em 1em 1.5em 2.25em`};
`;

// aspect ratio is 1:1
const OuterContainer = styled.div`
  position: relative;
  padding-top: 50%;
  margin: 0.5em 0;
`;

const InnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ShareLinkBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5em 1em;
  border: 1px solid ${darkGray};
`;

const ShareLink = styled.input`
  flex: 1 0 auto;
  border: 0;
  outline: none;
  color: ${black};
`;

export class DriverMap extends Component {
  static defaultProps = {
    t: noop,
    serviceIcon: defaultVehicleIcon,
    sharePageUrl: '',
  };

  static propTypes = {
    t: func,
    orderId: string.isRequired,
    waypoints: arrayOf(
      shape({
        id: string,
        name: string,
        lat: number,
        lng: number,
        placeId: string,
      })
    ).isRequired,
    serviceIcon: string,
    currentCountry: shape({
      id: string,
    }).isRequired,
    locale: string.isRequired,
    sharePageUrl: string,
  };

  state = {
    driver: null,
  };

  refUrl = React.createRef();

  componentDidMount() {
    this.pollDriverLocation();
    this.interval = setInterval(() => {
      this.pollDriverLocation();
    }, 5 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onCopyClick = () => {
    this.refUrl.current.select();
    document.execCommand('copy');
  };

  async pollDriverLocation() {
    const { currentCountry, orderId } = this.props;
    const shareId = `${currentCountry.id}${orderId}`;
    try {
      const { latitude, longitude, bearing } = await fetchDriverLocation(
        shareId
      );
      const location = { lat: latitude, lng: longitude, bearing };
      this.setState({ driver: location });
    } catch (e) {
      console.error('error in pollDriverLocation', e); // eslint-disable-line no-console
    }
  }

  render() {
    const { driver } = this.state;
    const {
      serviceIcon,
      t,
      waypoints,
      currentCountry,
      locale,
      orderId,
      sharePageUrl,
    } = this.props;

    // India ban workaround link
    const getSharePageURL = () => {
      if (
        currentCountry.countryId === 10000 &&
        (process.env.REACT_APP_HOST_ENV === 'production' ||
          process.env.REACT_APP_HOST_ENV === 'staging')
      )
        return sharePageUrl;

      return process.env.REACT_APP_SHARE_URL;
    };

    // Make share url
    const shareId = `${currentCountry.id}${orderId}`;
    const shareUrl = `${getSharePageURL()}?${shareId}&lang=${locale}&version=2`;

    return (
      <Wrapper>
        <Heading htmlTag="h3">
          {t('RecordPanel.heading_driver_location')}
        </Heading>
        <OuterContainer>
          <InnerContainer>
            <Maps
              locale={locale}
              region={currentCountry.id}
              zoomLevel={11}
              waypoints={waypoints}
              vehicle={
                driver && {
                  lat: driver.lat,
                  lng: driver.lng,
                  bearing: driver.bearing || 0,
                  icon: serviceIcon,
                }
              }
              gestureHandling="cooperative"
            />
          </InnerContainer>
        </OuterContainer>
        <ShareLinkBox>
          <ShareLink value={shareUrl} readOnly ref={this.refUrl} />
          <Button onClick={this.onCopyClick}>
            {t('RecordPanel.button_copy')}
          </Button>
        </ShareLinkBox>
      </Wrapper>
    );
  }
}

const mapState = (state, { serviceTypeId }) => ({
  serviceIcon: getServiceIcon(state, serviceTypeId, 'aerial'),
  currentCountry: getCurrentCountry(state),
  locale: getCurrentLocale(state),
  sharePageUrl: getCurrentCity(state).urls.sharePage,
});

export default compose(withTranslation(), connect(mapState))(DriverMap);
