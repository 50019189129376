import { LalamoveApiError } from 'utils/helpers';

import MobileAPIFetcher from 'api/MobileAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import FetcherLockService from 'api/FetcherLockService';

// interface Request {
//   access_token: String;
//   client_id: Number | String;
//   country: String; // HK_HKG
//   order: Number | String;
//   rating_to: Number | String;
//   rating: Number | String;
//   rating_reason?: String;
//   comment?: String;
// }
// interface Response {
//   isBanList: Boolean;
//   inFleet: Boolean;
//   is_commented: 0 | 1;
//   is_rated: 0 | 1;
//   rating: Number;
//   rating_count: Number;
// }
// MobileAPIResponse<Response>

/**
 * Rate Order Service by Order Id and Driver Id
 * @param {string} orderId order ID
 * @param {string} driverId driver ID
 * @param {integer} rating rating from 1 to 5, 1 is terrible, 5 is great
 * @param {string} reason rating reason key
 * @param {string} comment custom reason for others
 * @return {promise} resolve to a response object
 * @throws ERROR_INVALID_SESSION
 */
export default async function rateOrder(
  orderId,
  driverId,
  rating,
  reason,
  comment
) {
  const fetcher = FetcherFactory.make(MobileAPIFetcher);
  await FetcherLockService.accessTokenMutex.release();
  const { clientId, accessToken, city } = await fetcher.getState();
  const params = {
    access_token: accessToken,
    client_id: clientId,
    country: city,
    order: orderId,
    rating_to: driverId,
    rating,
    ...(reason && { rating_reason: reason }),
    ...(comment && { comment }),
  };
  const res = await fetcher.post('vanrating', params);
  const { success, data, code } = res;
  if (!success) throw new LalamoveApiError(code);
  return data;
}
