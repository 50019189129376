import { call, put, race, take } from 'redux-saga/effects';

import {
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_FAILURE,
  FETCH_SERVICES_SUCCESS,
} from 'interfaces/global/store/modules/services/actions';

export default function* refetchServicesAndCall(saga, ...args) {
  yield put({ type: FETCH_SERVICES_REQUEST });

  const { success } = yield race({
    success: take(FETCH_SERVICES_SUCCESS),
    fail: take(FETCH_SERVICES_FAILURE),
  });

  if (success) {
    yield call(saga, ...args);
  }
}
