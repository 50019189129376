import React from 'react';
import styled from 'styled-components';
import { lalamoveFilled as LalamoveIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { orange, white } from 'styles/colors';

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 32px 0 22px;
  background-color: ${orange};
  border-radius: 50%;
`;

const RoundLogo = () => (
  <LogoContainer>
    <LalamoveIcon color={white} size={26} />
  </LogoContainer>
);

export default RoundLogo;
