import React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { string, arrayOf, shape } from 'prop-types';
import styled from 'styled-components';
import {
  getCurrentCity,
  getCurrentLocale,
} from 'store/modules/region/selectors';

import { silver } from 'styles/colors';
import { fontSize } from 'styles/fonts';

export const Content = styled.p`
  padding: 0 0 0 28px;
  color: ${silver};
  font-size: ${fontSize.small};
  line-height: 1.4;
`;

export const Insurance = ({ terms, locale }) => {
  const url =
    terms && terms.find(item => item.id === locale)
      ? terms.find(item => item.id === locale).value
      : '';

  return (
    <Content>
      <Trans i18nKey="Checkout.msg_insurance_terms">
        By continuing, you declare that you have read, understood and accept the
        terms, conditions and exclusions of the
        <a
          name="insurance_terms"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Policy Wording & Declarations
        </a>
        .
      </Trans>
    </Content>
  );
};

const TermShape = shape({
  id: string,
  value: string,
});

Insurance.defaultProps = {
  terms: null,
};

Insurance.propTypes = {
  terms: arrayOf(TermShape),
  locale: string.isRequired,
};

const mapState = state => ({
  terms: getCurrentCity(state).insuranceTerms,
  locale: getCurrentLocale(state),
});

export default connect(mapState)(Insurance);
