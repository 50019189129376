import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@lalamove/karang';

import { mineShaft } from 'styles/colors';
import { fontSize, fontWeight } from 'styles/fonts';
import { HEADER } from 'styles/zIndex';

export const MOBILE_HEADER_HEIGHT = 56;

export const Container = styled.div`
  position: sticky;
  z-index: ${HEADER};
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: ${MOBILE_HEADER_HEIGHT}px;
  padding: 0 14px;
  background: white;

  & button,
  a {
    z-index: ${HEADER + 1};
  }
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  background-image: ${({ src }: { src: string }) => `url('${src}') !important`};
  background-position: center !important;
  border-radius: 100%;
`;

export const Button = styled(IconButton).attrs(
  ({ icon }: { icon: string }) => ({
    children: <Icon src={icon} />,
  })
)`
  padding: 4px;
`;

export const Center = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const Heading = styled.h1`
  color: ${mineShaft[900]};
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
`;
