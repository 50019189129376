import { statusMap } from 'utils/helpers';
import {
  ORDER_ADD,
  FETCH_RECORD_SUCCESS,
  FETCH_CLIENT_ORDER_SUCCESS,
  RATE_ORDER_SUCCESS,
  CANCEL_ORDER_SUCCESS,
} from './actionTypes';

export const initState = {};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case ORDER_ADD:
    case FETCH_RECORD_SUCCESS: {
      const { row } = action;
      return {
        ...state,
        [row.order.id]: {
          ...(state[row.order.id] || {}),
          ...row.order,
        },
      };
    }
    case FETCH_CLIENT_ORDER_SUCCESS: {
      const { clientOrder } = action;
      return {
        ...state,
        [clientOrder.id]: {
          ...(state[clientOrder.id] || {}),
          // EXTEND: right now we only need isProofOfDeliveryRequired from clientOrder
          isProofOfDeliveryRequired: clientOrder.isProofOfDeliveryRequired,
          editRevision: clientOrder.editRevision,
        },
      };
    }
    case RATE_ORDER_SUCCESS: {
      const { orderId, rating } = action;
      return {
        ...state,
        [orderId]: {
          ...state[orderId],
          userRating: rating,
        },
      };
    }
    case CANCEL_ORDER_SUCCESS: {
      const { orderId } = action;
      return {
        ...state,
        [orderId]: {
          ...state[orderId],
          status: statusMap.CANCELLED,
        },
      };
    }
    default: {
      return state;
    }
  }
}
