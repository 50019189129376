import { DRAFT_ORDER_ID } from 'interfaces/global/config';

export function getWaypoints(state, orderId = DRAFT_ORDER_ID) {
  const { order, waypoints } = state.routing;
  return order[orderId] ? order[orderId].sequence.map(id => waypoints[id]) : [];
}
export function getWaypointById({ waypoints }, id) {
  return waypoints[id];
}
export function getFilledWaypts(state, orderId = DRAFT_ORDER_ID) {
  const { order, waypoints } = state.routing;
  return order[orderId]
    ? order[orderId].sequence
        .filter(wpId => !!waypoints[wpId].lat && !!waypoints[wpId].lng)
        .map(id => waypoints[id])
    : [];
}
export function getFilledWayptsIds(state, orderId = DRAFT_ORDER_ID) {
  const { order, waypoints } = state.routing;
  return order[orderId]
    ? order[orderId].sequence.filter(
        wpId => !!waypoints[wpId].lat && !!waypoints[wpId].lng
      )
    : [];
}
export function getGoogleDirections(state, orderId = DRAFT_ORDER_ID) {
  const { order } = state.routing;
  return order[orderId] ? order[orderId].googleDirectionsResult : null;
}
export function getShouldOptimize(state, orderId = DRAFT_ORDER_ID) {
  const { order } = state.routing;
  return order[orderId] ? order[orderId].shouldOptimize : false;
}
export function getErrorByStopItemId(state, id) {
  return state.routing.errors[id];
}
export function getRouteErrors(state) {
  return state.routing.errors;
}
export function getServiceAreaError(state) {
  return state.routing.serviceAreaError;
}
export function getAreaValidationStatus(state) {
  return state.routing.isValidServiceArea;
}
export function getOrderIdByWayptId(state, id) {
  return state.routing.waypointsMap[id];
}

export function getSessionToken(state) {
  return state.routing.sessiontoken;
}

export const getDeliveryInfo = rootState => rootState.routing.deliveryInfo;
export const getDeliveryInfoByWayptId = (rootState, id) =>
  rootState.routing.deliveryInfo[id];
export const getDeliveryInfoByWayptIds = (rootState, ids) =>
  ids.map(id => rootState.routing.deliveryInfo[id]);
