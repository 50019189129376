import React, { useState } from 'react';
import { func } from 'prop-types';
import { PinInput } from '@lalamove/karang';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { orange, silver } from 'styles/colors';
import { fontSize, fontWeight } from 'styles/fonts';
import { whenMobile } from 'components/MediaQuery';
import { ERROR_MAX_RESET } from 'interfaces/global/containers/ForgotPassword';
import { useSelector, useDispatch } from 'react-redux';
import { makeMessageSelector, dismissMessage } from 'store/modules/message';
import { createLoadingSelector } from 'store/modules/loading';
import { PinInputContainer } from 'interfaces/global/containers/Settings/style';
import ResendContent from './ResendContent';

const Container = styled.div`
  width: 100%;
`;

const PinRemark = styled.div`
  margin: 1rem auto 2rem auto;
  color: ${silver};
  font-size: ${fontSize.regular};
`;

const CSLink = styled.div`
  color: ${orange};
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.bold};
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  text-align: right;

  ${whenMobile} {
    flex-direction: column-reverse;
  }
`;

CSLink.displayName = 'CSLink';

const VerifySMS = ({ handleSendSMS, handlePinChanged }) => {
  const { t } = useTranslation();
  const isWaiting = useSelector(state => state.auth.password.isWaiting);
  const [attempts, setAttempts] = useState(3);
  const dispatch = useDispatch();
  const apiError = useSelector(state =>
    makeMessageSelector(['RESEND_PIN', 'VERIFY_PIN'])(state)
  );
  const loading = useSelector(state =>
    createLoadingSelector(['RESEND_PIN', 'VERIFY_PIN'])(state)
  );

  const handleResendRequest = () => {
    setAttempts(attempts - 1);

    handleSendSMS();
  };

  const onPinChanged = smsCode => {
    if (smsCode.length === 4) {
      handlePinChanged(smsCode);
    } else {
      dispatch(dismissMessage('RESEND_PIN'));
      dispatch(dismissMessage('VERIFY_PIN'));
    }
  };

  const maxReset = apiError && apiError.message === ERROR_MAX_RESET;
  const error = !loading && apiError;

  return (
    <Container>
      <PinRemark>{t('Verification.enter_pin')}</PinRemark>
      <PinInputContainer>
        <PinInput
          type="tel"
          size="small"
          disabled={loading || maxReset}
          error={error && error.message ? t(error.message) : null}
          onChange={onPinChanged}
        />
      </PinInputContainer>
      <ButtonGroup>
        {attempts === 0 ? (
          <CSLink>{t('Settings.try_again')}</CSLink>
        ) : (
          <ResendContent
            isWaiting={isWaiting}
            loading={loading}
            handleResendRequest={handleResendRequest}
            t={t}
          />
        )}
      </ButtonGroup>
    </Container>
  );
};

VerifySMS.propTypes = {
  handleSendSMS: func.isRequired,
  handlePinChanged: func.isRequired,
};

export default VerifySMS;
