import React, { Component } from 'react';
import Big from 'big.js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  bool,
  func,
  string,
  number,
  shape,
  arrayOf,
  instanceOf,
} from 'prop-types';
import styled from 'styled-components';
import { RadioGroup, Input } from '@lalamove/karang';
import { addstop as AddStopIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { PriceBreakDownShape } from 'interfaces/global/views/Records/propTypes';
import { updatePriorityFee } from 'interfaces/global/store/modules/records/actions';
import { track } from 'interfaces/global/store/modules/tracking/actions';
import { createLoadingSelector } from 'store/modules/loading';
import { getPriorityFee } from 'interfaces/global/store/modules/records/selectors';
import { getCurrentIsoCurrencyCode } from 'store/modules/region/selectors';
import Heading from 'components/SliderPanel/Heading';
import Footer from 'components/SliderPanelFooter';

import formatter from 'utils/formatter';
import { noop, statusMap } from 'utils/helpers';
import { silver } from 'styles/colors';

import Price from './components/Price';
import { Container, Content, Wrapper } from './style';

const SCRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-wrap: wrap;
  > label {
    flex: 0 calc(50% - 1em);
    box-sizing: border-box;
  }
`;

const Separator = styled.div`
  color: ${silver};
  font-size: 0.8em;
  text-align: center;
`;

const StyledInput = styled(Input)`
  display: block;
  margin: 1em 0;
`;

const StyledPrice = styled(Price)`
  padding: 0 1.2em;
`;

export const totalTipsExceedsMax = (newPriorityFee, max, tips) =>
  newPriorityFee.plus(tips).gt(max);

const BigType = instanceOf(Big);
export class AddPriorityFee extends Component {
  static defaultProps = {
    id: null,
    refId: null,
    status: null,
    paymentMethodId: null,
    isLoading: false,
    updatePriorityFee: noop,
    onBack: noop,
    onClose: noop,
    price: null,
    track: noop,
    isoCurrencyCode: '',
  };

  static propTypes = {
    t: func.isRequired,
    id: string,
    refId: string,
    status: number,
    paymentMethodId: number,
    isLoading: bool,
    updatePriorityFee: func,
    onBack: func,
    onClose: func,
    priorityFeeConfig: shape({
      min: BigType,
      max: BigType,
      options: arrayOf(BigType),
    }).isRequired,
    price: PriceBreakDownShape,
    isoCurrencyCode: string,
    track: func,
    tips: BigType.isRequired,
  };

  state = {
    selectedAmount: null,
    customAmount: '',
    error: '',
  };

  componentDidMount() {
    if (this.props.status !== statusMap.ASSIGNING) {
      this.props.onClose();
    }

    const {
      priorityFeeConfig: {
        options: [preSelected],
        max,
      },
      tips,
    } = this.props;

    if (!totalTipsExceedsMax(preSelected, max, tips))
      this.setState({ selectedAmount: preSelected.toString() });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.props.onClose();
    }
  }

  handleOptionSelected = amount => {
    this.setState({ selectedAmount: amount, customAmount: '', error: '' });
  };

  handleClick = () => {
    this.setState({ selectedAmount: null });
  };

  handleChange = ({ target: { value } }) => {
    const regex = /^[0-9]+$/;
    if (value && !regex.test(value)) return;
    this.setState({ customAmount: value, error: '' });
  };

  handleBlur = ({ target: { value } }) => {
    const {
      t,
      priorityFeeConfig: { min, max },
      tips,
    } = this.props;
    const newPriorityFee = Big(value || 0);

    if (newPriorityFee.lt(min) || newPriorityFee.gt(max)) {
      this.setState({
        error: t('RecordPanel.error_msg_invalid_custom_fee', {
          min: formatter.currency(min),
          max: formatter.currency(max),
        }),
      });
    } else if (totalTipsExceedsMax(newPriorityFee, max, tips)) {
      this.setState({
        error: t('RecordPanel.tips_exceeds_max', {
          maxAmount: formatter.currency(max),
        }),
      });
    }
  };

  getPriorityFee = () => {
    const { customAmount, selectedAmount } = this.state;
    const amount = customAmount || selectedAmount || 0;
    return Big(amount);
  };

  updatePriorityFee = () => {
    const { price, id, isoCurrencyCode, refId, tips } = this.props;
    const latestPriorityFee = this.getPriorityFee();

    const newTotalPriorityFee = tips
      ? tips.plus(latestPriorityFee)
      : latestPriorityFee;
    this.props.updatePriorityFee(id, newTotalPriorityFee);
    this.props.track('priority_fee_added', {
      order_id: refId,
      order_amount: price.total.toNumber(),
      priority_amount: latestPriorityFee.toNumber(),
      currency_code: isoCurrencyCode,
    });
  };

  render() {
    const { selectedAmount, customAmount, error } = this.state;
    const {
      t,
      id,
      paymentMethodId,
      onBack,
      onClose,
      isLoading,
      priorityFeeConfig: { options, max },
      price,
      tips,
    } = this.props;
    if (!id) return null;

    const submittedPriorityFee = this.getPriorityFee();

    const priorityFeePriceItem = {
      name: t(`RecordPanel.label_tips`),
      value: submittedPriorityFee,
    };

    const updatedPriceWithPriorityFee = {
      items: [...price.items, priorityFeePriceItem],
      total: Big(price.total).plus(submittedPriorityFee),
    };

    return (
      <Container>
        <Content>
          <Heading
            icon={<AddStopIcon size={24} />}
            title={t('RecordPanel.title_add_priority_fee')}
            description={t('RecordPanel.msg_add_priority_fee_global', {
              maxAmount: formatter.currency(max),
            })}
          />
          <Wrapper>
            <SCRadioGroup
              name="amount"
              value={selectedAmount}
              onChange={this.handleOptionSelected}
              variant="toggle"
            >
              {Radio =>
                options.map(option => (
                  <Radio
                    key={option}
                    value={option.toString()}
                    disabled={totalTipsExceedsMax(option, max, tips)}
                  >
                    + {formatter.currency(option)}
                  </Radio>
                ))
              }
            </SCRadioGroup>
            <Separator>{t('RecordPanel.msg_or')}</Separator>
            <StyledInput
              label={t('RecordPanel.input_label_custom_fee')}
              value={customAmount}
              onClick={this.handleClick}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={error}
            />
          </Wrapper>
        </Content>
        <StyledPrice
          price={updatedPriceWithPriorityFee}
          paymentMethodId={paymentMethodId}
        />
        <Footer
          primaryAction={this.updatePriorityFee}
          primaryButtonText={t('RecordPanel.button_add')}
          isLoadingPrimaryButton={isLoading}
          disablePrimaryButton={isLoading || !!error || !submittedPriorityFee}
          secondaryAction={onBack}
          secondaryButtonText={t('RecordPanel.button_back')}
          closeAction={onClose}
          closeButtonText={t('RecordPanel.button_close_panel')}
        />
      </Container>
    );
  }
}

const mapState = state => ({
  isLoading: createLoadingSelector(['UPDATE_PRIORITY_FEE'])(state),
  priorityFeeConfig: getPriorityFee(state),
  isoCurrencyCode: getCurrentIsoCurrencyCode(state),
});

export default compose(
  withTranslation(),
  connect(mapState, { updatePriorityFee, track })
)(AddPriorityFee);
