import { toLLMLower } from 'utils/locale';
import { UApiError } from 'utils/helpers';
import moment from 'moment';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';

const changePassword = async ({ phoneNumber, verifyToken, password }) => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const { countryId, locale } = await fetcher.getState();

  const params = {
    args: JSON.stringify({
      phone_no: phoneNumber,
      ref: 'dev',
      device_id: '076241eddf25dc8c928d6cc7f39341bc',
      device_type: 'SM-A515F',
      type: 1,
      verify_token: verifyToken,
      new_pwd: password,
    }),
    hcountry: countryId,
    os: 'web',
    hlang: toLLMLower(locale),
    _t: moment().unix(),
  };

  const { ret, data, msg } = await fetcher.get('?_m=modify_pwd', params);

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }

  return data;
};
export default changePassword;
