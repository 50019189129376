import FetcherFactory from 'api/FetcherFactory';
import UAPIFetcher from 'api/UAPIFetcher';
import { UApiError } from 'utils/helpers';
import { toLLMLower } from 'utils/locale';

export const GeocoderStatus = Object.freeze({
  OK: 'OK',
  ERROR: 'ERROR',
  NOT_FOUND: 'NOT_FOUND',
});

const performGeocode = async args => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    countryId,
    locale,
  } = await fetcher.getState();

  const params = {
    args: JSON.stringify(args),
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
  };

  const { ret, data, msg } = await fetcher.get(
    '?_m=map_api&_a=geocode',
    params
  );
  // NOT_FOUND will be added in the next issue
  data.status = ret === 0 ? GeocoderStatus.OK : GeocoderStatus.ERROR;
  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }
  return data;
};

export async function bulkGeocode(addresses, { region }) {
  const common = {
    type: 'address',
  };
  const resolutions = addresses.map(address =>
    Promise.resolve(performGeocode({ ...common, region, address }))
      .then(response => ({ status: response.status, response }))
      .catch(error => ({
        error,
        status: GeocoderStatus.ERROR,
        response: {
          status: GeocoderStatus.ERROR,
          results: [],
        },
      }))
  );
  return Promise.all(resolutions).then(assembleBulkGeocodeResults);
}

export function assembleBulkGeocodeResults(geocodeResults) {
  const response = {
    results: [],
    status: GeocoderStatus.OK,
  };

  for (const geocodeResult of geocodeResults) {
    if (geocodeResult.status !== GeocoderStatus.OK) {
      response.status = GeocoderStatus.NOT_FOUND;
    }
    // In order to be consistent with the previous interface return value
    response.results.push({
      results: geocodeResult.response,
      status: geocodeResult.response.status,
    });
  }

  if (response.status !== GeocoderStatus.OK) {
    throw response;
  }
  return response;
}

const reverseGeocode = async ({ latlng }) => {
  const args = {
    type: 'geocode',
    lng: `${latlng.lng}`,
    lat: `${latlng.lat}`,
  };

  return performGeocode(args);
};
export default reverseGeocode;
