export const SENSORS_TRACK_EVENT = 'SENSORS_TRACK_EVENT';
export const SENSORS_SET_PROFILE = 'SENSORS_SET_PROFILE';
export const SENSORS_LOGIN = 'SENSORS_LOGIN';
export const SENSORS_LOGOUT = 'SENSORS_LOGOUT';

export const track = (eventName, args = {}) => ({
  type: SENSORS_TRACK_EVENT,
  name: eventName,
  payload: args,
});

export const setProfile = (args = {}) => ({
  type: SENSORS_SET_PROFILE,
  payload: args,
});

export const login = () => ({
  type: SENSORS_LOGIN,
});

export const logout = () => ({
  type: SENSORS_LOGOUT,
});
