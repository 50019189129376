import { select, put, takeLatest, fork, call } from 'redux-saga/effects';
import { GET_USER_SESSION_SUCCESS } from 'redux-react-session/dist/actionTypes';

import { getUser } from 'store/modules/auth/selectors';
import { CHANGE_LOCATION_SUCCESS } from 'store/modules/region/actions';
import storage from 'utils/storage';

import { setMarketingOptIn as setMarketingOptInAPI } from 'api/authAPI';
import { sessionService } from 'redux-react-session';
import {
  SET_MARKETING_OPT_IN_FAILURE,
  SET_MARKETING_OPT_IN_SUCCESS,
} from 'interfaces/global/store/modules/settings/actions';
import { forgotPasswordSaga } from './forgotPassword';
import { loginSaga } from './login';
import { registerSaga } from './register';
import { verificationSaga } from './verification';

export function* onGetUserSessionSuccess() {
  const location = storage.getItem('userLocation');
  if (!location) return;
  const { is_marketing_opt_in: optIn } = yield select(getUser);

  const PENDING = -1;
  // TODO: Temp fix as Auth API may return null value in `is_marketing_opt_in`
  if (optIn === PENDING || optIn === null) {
    try {
      const marketingOptin = storage.getItem('marketing_optin') === 'true';
      yield call(setMarketingOptInAPI, marketingOptin);

      const userProfile = yield select(getUser);
      yield call([sessionService, 'saveUser'], {
        ...userProfile,
        is_marketing_opt_in: +marketingOptin,
      });

      yield put({ type: SET_MARKETING_OPT_IN_SUCCESS, marketingOptin });
    } catch (e) {
      yield put({
        type: SET_MARKETING_OPT_IN_FAILURE,
        meta: {
          type: 'error',
          message: e.message,
          data: 'llm.setMarketingOptin',
        },
      });
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(
    [GET_USER_SESSION_SUCCESS, CHANGE_LOCATION_SUCCESS],
    onGetUserSessionSuccess
  );

  yield fork(forgotPasswordSaga);
  yield fork(loginSaga);
  yield fork(registerSaga);
  yield fork(verificationSaga);
}
