import FetcherFactory from 'api/FetcherFactory';
import UAPIFetcher from 'api/UAPIFetcher';
import { UApiError } from 'utils/helpers';
import { toLLMLower } from 'utils/locale';

const getDirections = async ({
  origin,
  destination,
  serviceType,
  stopovers,
}) => {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    countryId,
    locale,
    cityId,
  } = await fetcher.getState();

  const waypoints = stopovers.reduce(
    (concat, stop) => `${concat}${concat ? '|' : ''}${stop.lat},${stop.lng}`,
    ''
  );
  const params = {
    args: JSON.stringify({
      origin: `${origin.lat},${origin.lng}`,
      destination: `${destination.lat},${destination.lng}`,
      ...(waypoints && { waypoints: `${waypoints}` }),
      orderVehicleId: serviceType,
      cityId,
    }),
    token: accessToken,
    hcountry: countryId,
    hlang: toLLMLower(locale),
    is_ep: profileType,
  };

  const { ret, data, msg } = await fetcher.get(
    '?_m=map_api&_a=optimize_route',
    params
  );

  if (ret !== 0) {
    throw new UApiError(msg, ret);
  }
  return {
    routes: [{ waypointOrder: data.waypointOrder }],
  };
};
export default getDirections;
