import React, { Component } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { dismissMessage } from 'store/modules/message';
import { closeDialog } from 'store/modules/ui';

import Dialog from 'components/Dialog';
import { noop } from 'utils/helpers';

class PriceUnmatchedDialog extends Component {
  static defaultProps = {
    t: noop,
    onClose: noop,
    dismissMessage: noop,
  };

  static propTypes = {
    t: func,
    onClose: func,
    dismissMessage: func,
  };

  handleClose = () => {
    this.props.onClose();
    this.props.dismissMessage('PLACE_ORDER');
  };

  render() {
    // eslint-disable-next-line no-shadow
    const { t, onClose, dismissMessage, ...props } = this.props;
    return (
      <Dialog
        title={t('Checkout.dialog_title_updated_price')}
        subtitle={t('Checkout.dialog_text_updated_price')}
        primaryButtonText={t('Checkout.dialog_button_ok')}
        primaryAction={this.handleClose}
        onRequestClose={this.handleClose}
        {...props}
      />
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    onClose: closeDialog,
    dismissMessage,
  })
)(PriceUnmatchedDialog);
