import { toLLMLower } from 'utils/locale';
import UAPIFetcher from 'api/UAPIFetcher';
import FetcherFactory from 'api/FetcherFactory';
import { UApiError } from 'utils/helpers';

export default async function getOrderAppealList({ orderDisplayId }) {
  const fetcher = FetcherFactory.make(UAPIFetcher);
  const {
    accessToken,
    profileType,
    countryId,
    locale,
  } = await fetcher.getState();
  const params = {
    args: JSON.stringify({
      // order_display_id: orderDisplayId,
    }),
    token: accessToken,
    hcountry: countryId,
    is_ep: profileType,
    os: 'web',
    hlang: toLLMLower(locale),
    order_display_id: orderDisplayId,
  };

  const { data, ret, msg } = await fetcher.get(
    'index.php?_m=order_appeal_list&_a=index',
    params
  );

  if (ret !== 0) throw new UApiError(msg, ret);

  return data;
}
