import React, { Component } from 'react';
import { arrayOf, bool, func, number, string, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { add as AddIcon } from '@lalamove/karang/dist/components/Icon/icons';
import { compose } from 'redux';
import { withResponsiveMedia } from 'components/MediaQuery';

import { MAX_NUM_WAYPOINTS } from 'interfaces/global/config';
import { noop } from 'utils/helpers';
import { GhostButton } from 'components/Button';
import { List, ListItem, DragHandler } from './style';
import { LocationShape } from '../../propTypes';
import StopItem from '../StopItem';

function getPlaceholderText(t, index, last) {
  if (index === 0) return t('PlaceOrder.placeholder_start');
  if (index === last) return t('PlaceOrder.placeholder_end');
  return t('PlaceOrder.placeholder_stop');
}

const StopListContainer = styled.div`
  margin: ${({ isMobile }) => (isMobile ? '0 16px' : '1rem auto')};
`;

class StopList extends Component {
  static defaultProps = {
    t: noop,
    isEditMode: false,
    onReorder: noop,
    onCreate: noop,
    onRemove: noop,
    history: {},
  };

  static propTypes = {
    t: func,
    isEditMode: bool,
    onReorder: func,
    onCreate: func,
    onRemove: func,
    waypoints: arrayOf(LocationShape).isRequired,
    history: shape({
      receiving: arrayOf(
        shape({
          id: string,
          description: string,
          placeId: string,
          lat: number,
          lng: number,
        })
      ),
      sending: arrayOf(
        shape({
          id: string,
          description: string,
          placeId: string,
          lat: number,
          lng: number,
        })
      ),
    }),
    isMobile: bool.isRequired,
  };

  state = {
    btnText: this.props.t('PlaceOrder.button_add_destination'),
    isDropDisabled: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.t !== this.props.t) {
      this.resetBtnText();
    }
    ReactTooltip.rebuild();
  }

  componentWillUnmount() {
    ReactTooltip.hide();
  }

  // 0 起点, 1 终点, -1 无起点终点，例如常用地址
  getPlaceType = (index, waypointsLength) => {
    if (index === 0) return 0;
    if (index === waypointsLength - 1) return 1;
    return -1;
  };

  onDragStart = () => {
    this.setState({ isDropDisabled: false });
  };

  onDragUpdate = ({ destination }) => {
    const { isEditMode } = this.props;
    if (!destination) return;
    // pick-up location cannot be edited in edit order
    if (isEditMode && destination.index === 0) {
      this.setState({ isDropDisabled: true });
    }
  };

  onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    this.props.onReorder(source.index, destination.index);
  };

  resetBtnText = () =>
    new Promise(resolve => {
      const { t } = this.props;
      this.setState(
        { btnText: t('PlaceOrder.button_add_destination') },
        resolve
      );
    });

  handleClick = async () => {
    clearTimeout(this.timeout);
    await this.resetBtnText();
    const { waypoints, onCreate, t } = this.props;
    if (waypoints.length === MAX_NUM_WAYPOINTS) {
      this.setState(
        {
          btnText: t('PlaceOrder.button_reach_max_stops'),
        },
        () => {
          this.timeout = setTimeout(this.resetBtnText, 1000);
        }
      );
    }
    onCreate();
  };

  render() {
    const {
      t,
      isEditMode,
      waypoints,
      onRemove,
      history,
      isMobile,
    } = this.props;
    const { btnText, isDropDisabled } = this.state;
    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragUpdate={this.onDragUpdate}
        onDragEnd={this.onDragEnd}
      >
        <StopListContainer isMobile={isMobile}>
          <Droppable droppableId="droppable" isDropDisabled={isDropDisabled}>
            {provided => (
              <List ref={provided.innerRef}>
                {waypoints.map((waypoint, index) => {
                  // pick-up location cannot be edited in edit order
                  const disabled = isEditMode && index === 0;
                  return (
                    <Draggable
                      key={waypoint.id}
                      draggableId={waypoint.id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(
                        provided, // eslint-disable-line no-shadow
                        snapshot // eslint-disable-line no-shadow
                      ) => (
                        <ListItem
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          ref={node => {
                            this.liDOM = node;
                            return provided.innerRef(node);
                          }}
                        >
                          <DragHandler
                            {...(!disabled && {
                              'data-tip': t('PlaceOrder.tooltip_drag'),
                              'data-place': 'right',
                              'data-for': 'global',
                            })}
                          />
                          <StopItem
                            liDOM={this.liDOM}
                            id={waypoint.id}
                            onRemove={onRemove}
                            isRemovable={!disabled && waypoints.length > 2}
                            disabled={disabled}
                            placeholder={getPlaceholderText(
                              t,
                              index,
                              waypoints.length - 1
                            )}
                            history={
                              index > 0 ? history.sending : history.receiving
                            }
                            placeType={this.getPlaceType(
                              index,
                              waypoints.length
                            )}
                            index={index}
                          />
                        </ListItem>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
          <GhostButton
            icon={<AddIcon />}
            iconPosition="before"
            onClick={this.handleClick}
          >
            {btnText}
          </GhostButton>
        </StopListContainer>
      </DragDropContext>
    );
  }
}

export default compose(withResponsiveMedia, withTranslation())(StopList);
